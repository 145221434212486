import { useEffect, useState } from 'react'
import axios from 'axios'
import { Box, Card, Typography, Button, CircularProgress, Alert } from '@mui/joy'
import { CheckCircle, ErrorOutline } from '@mui/icons-material'

export default function NylasCallback() {
    const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading')
    const [errorMessage, setErrorMessage] = useState('')

    const handleClose = () => {
        window.close()
    }

    useEffect(() => {
        const code = new URLSearchParams(window.location.search).get('code')

        if (!code) {
            setStatus('error')
            setErrorMessage('No authorization code found in the URL')
            return
        }

        // Exchange code for tokens
        axios
            .post('/email/auth/nylas/exchange-token', { code })
            .then(({ data }) => {
                setStatus('success')

                // Send the tokens back to the parent window
                window.opener?.postMessage(
                    {
                        type: 'NYLAS_AUTH_SUCCESS',
                        email: data.data,
                    },
                    window.location.origin
                )

                // Auto close window after success
                setTimeout(() => {
                    window.close()
                }, 3000)
            })
            .catch((error) => {
                const errorMsg = error.response?.data?.message || 'Failed to authenticate with Nylas'
                setStatus('error')
                setErrorMessage(errorMsg)

                // Send the error back to the parent window
                window.opener?.postMessage(
                    {
                        type: 'NYLAS_AUTH_ERROR',
                        error: errorMsg,
                    },
                    window.location.origin
                )
            })
    }, [])

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                bgcolor: 'background.default',
            }}
        >
            <Card
                sx={{
                    p: 4,
                    maxWidth: 400,
                    width: '100%',
                    textAlign: 'center',
                    boxShadow: 'md',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                {status === 'loading' && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                        <CircularProgress size="lg" />
                        <Typography level="body-md">Authenticating with Nylas...</Typography>
                    </Box>
                )}

                {status === 'success' && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                        <CheckCircle color="success" sx={{ fontSize: 48 }} />
                        <Typography level="body-md">Authentication successful! This window will close automatically.</Typography>
                        <Button variant="solid" color="primary" onClick={handleClose}>
                            Close Window
                        </Button>
                    </Box>
                )}

                {status === 'error' && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                        <ErrorOutline color="error" sx={{ fontSize: 48 }} />
                        <Alert color="danger" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                        <Typography level="body-md">Please close this window and try again.</Typography>
                        <Button variant="solid" color="primary" onClick={handleClose}>
                            Close Window
                        </Button>
                    </Box>
                )}
            </Card>
        </Box>
    )
}
