import React from 'react'
import { Box, Chip, IconButton, Stack, Typography } from '@mui/joy'
import CloseIcon from '@mui/icons-material/Close'
import CallIcon from '@mui/icons-material/Call'
import { RingCentralCallSession } from 'types'

interface CallInfoProps {
    callSession: RingCentralCallSession
    callDuration: number
    onClose: () => void
}

const formatTime = (seconds: number): string => {
    const mins = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
}

const getStateColor = (state: string) => {
    switch (state.toLowerCase()) {
        case 'answered':
            return 'success'
        case 'proceeding':
            return 'warning'
        case 'connecting':
            return 'primary'
        case 'failed':
            return 'danger'
        default:
            return 'neutral'
    }
}

const CallInfo: React.FC<CallInfoProps> = ({ callSession, callDuration, onClose }) => {
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <IconButton onClick={onClose} variant="plain" sx={{ mr: 1 }}>
                    <CloseIcon />
                </IconButton>
                <Chip
                    color={getStateColor(callSession.state)}
                    size="md"
                    variant="soft"
                    sx={{
                        fontWeight: callSession.state === 'disposed' ? 'bold' : 'normal',
                        animation: callSession.state === 'disposed' ? 'none' : undefined,
                    }}
                >
                    {callSession.state === 'answered' ? formatTime(callDuration) : undefined}
                </Chip>
            </Box>

            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 2 }}>
                <Stack spacing={1} flex={1}>
                    <Typography level="body-md" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        To: <strong>{callSession.remoteNumber}</strong>
                    </Typography>
                    <Typography level="body-md" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        From: <strong>{callSession.localNumber}</strong>
                    </Typography>
                </Stack>
                <Typography level="h4" startDecorator={<CallIcon />}>
                    {callSession.state === 'disposed' ? 'Call Ended' : 'Active Call'}
                </Typography>
            </Box>
        </>
    )
}

export default CallInfo
