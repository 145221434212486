export enum CallType {
    DISPATCHER = 'dispatcher',
    CUSTOMER_SERVICE = 'customer_service',
    SALES = 'sales',
    SUPPORT = 'support',
}

export enum CallStatus {
    INITIATING = 'initiating',
    CONNECTED = 'connected',
    ENDED = 'ended',
    ERROR = 'error',
}

export interface CallTranscript {
    role: 'assistant' | 'user'
    content: string[]
}
export interface TranscriptionSegment {
    id: string
    speakerId: string
    text: string
    timestamp: Date
    confidence: number
    corrected?: boolean
    metadata?: {
        interim?: boolean
    }
    intent?: {
        name: string
        confidence: number
        metadata?: Record<string, any>
    }
    entities?: Array<{
        type: string
        value: string
        confidence: number
    }>
}

export interface CallSettings {
    transcriptionNotificationsEnabled: boolean
}

export interface RingCentralEnvelope {
    sessionId: string
    partyId: string
    recordId?: string
}

export type CallEnvelope = RingCentralEnvelope

export interface Call {
    from: string
    to: string
    status: CallStatus
    startedAt: number
    endedAt?: number
    callId: string
    transcripts: CallTranscript[]
    metadata?: Record<string, any>
    envelope?: CallEnvelope
    initiatedBy: CallInitiator
}

export interface RingCentralCall {
    loadSearchId: string
    loadId: string
    callId: string
    startedAt: number
    partyId: string
    session: any
    from: string
    to: string
}

export enum CallInitiator {
    USER = 'user',
    AI = 'ai',
}
