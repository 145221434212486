import { ELDIntegrations } from './ELDIntegrations'
import { AccountIntegrations } from './AccountIntegrations'
import { Divider, Stack } from '@mui/joy'

export const IntegrationsTab = () => {
    return (
        <Stack spacing={1}>
            <ELDIntegrations />
            <Divider />
            <AccountIntegrations />
        </Stack>
    )
}
