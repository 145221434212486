import { Application, LucidEldVehicle, LucidEldVehicleStatus, SamsaraDriver, SamsaraVehicle, SamsaraVehicleStatus, TteldUnit } from '@numeo/types'
import axios from 'axios'

class EldApi {
    // TT ELD API
    async getTteldUnit(vin: string): Promise<TteldUnit> {
        const response = await axios.get(`/trucks/tteld/unit/${vin}`)
        return response.data
    }

    async getAllTteldUnits(): Promise<TteldUnit[]> {
        const response = await axios.get(`/trucks/tteld/units`)
        return response.data
    }

    async addSelectedTteldTrucks(trucks: TteldUnit[]): Promise<Application> {
        const response = await axios.post(`/trucks/tteld/add-selected-trucks`, { trucks })
        return response.data.data
    }

    async connectTteld(usdot: string, apiKey: string): Promise<Application> {
        const response = await axios.post(`/integrations/tteld/connect`, {
            usdot,
            apiKey,
        })
        return response.data.data
    }

    async disconnectTteld(): Promise<Application> {
        const response = await axios.delete(`/integrations/tteld/disconnect`)
        return response.data.data
    }

    // Lucid ELD API
    async getLucidEldTruckStatus(eldNumber: string): Promise<LucidEldVehicleStatus> {
        const response = await axios.get(`/trucks/lucideld/status/${eldNumber}`)
        return response.data
    }

    async getAllLucidEldTrucks(): Promise<LucidEldVehicle[]> {
        const response = await axios.get(`/trucks/lucideld/vehicles`)
        return response.data
    }

    async addSelectedLucidEldTrucks(vehicles: LucidEldVehicle[]): Promise<Application> {
        const response = await axios.post(`/trucks/lucideld/add-selected-trucks`, { vehicles })
        return response.data.data
    }

    async connectLucidEld(username: string, password: string, apiKey: string): Promise<Application> {
        const response = await axios.post(`/integrations/lucideld/connect`, {
            username,
            password,
            apiKey,
        })
        return response.data.data
    }

    async disconnectLucidEld(): Promise<Application> {
        const response = await axios.delete(`/integrations/lucideld/disconnect`)
        return response.data.data
    }

    // Samsara
    async connectSamsara(code: string): Promise<Application> {
        const response = await axios.post(`/integrations/samsara/connect`, {
            code,
        })
        return response.data.data
    }

    async disconnectSamsara(): Promise<Application> {
        const response = await axios.delete(`/integrations/samsara/disconnect`)
        return response.data.data
    }

    async getAllSamsaraVehiclesDrivers(): Promise<{ vehicle: SamsaraVehicle; driver?: SamsaraDriver }[]> {
        const response = await axios.get(`/trucks/samsara/vehicles`)
        return response.data
    }

    async addSelectedSamsaraVehicles(vehicles: { vehicle: SamsaraVehicle; driver?: SamsaraDriver }[]): Promise<Application> {
        const response = await axios.post(`/trucks/samsara/add-selected-vehicles`, { vehicles })
        return response.data.data
    }

    async getSamsaraVehicleStatus(id?: string): Promise<SamsaraVehicleStatus[]> {
        let response
        if (id) {
            response = await axios.get(`/trucks/samsara/vehicles/status/${id}`)
            return response.data.data
        }
        response = await axios.get(`/trucks/samsara/vehicles/status`)
        return response.data.data
    }
}

export const eldApi = new EldApi()
