import {
    CallActionTypes,
    CallBaseMessage,
    CallEndedMessage,
    CallStartedMessage,
    CallUpdatedMessage,
    GetActiveCallsMessage,
    IAllLoadFinderResultsMessage,
    IPartialLoadUpdateMessage,
    LoadFinderActionTypes,
    LoadFinderBaseMesage,
    SearchStatus,
    WebSocketStatus,
    WsMessage,
    WsMessageCategory,
} from '@numeo/types'
import { Middleware } from '@reduxjs/toolkit'
import { SockerIoDisconnectReason } from 'context/types'
import { AUTH_TOKEN } from 'setup/SetupAxios'
import { Manager, Socket } from 'socket.io-client'
import { handleLoadsUpdate } from 'utils/load'
import { wssHostUrl } from 'utils/network'
import { CallCenterNotification, Category, LoadFinderNotification } from '../../hooks/useNotification'
import { removeCall, setCallWsStatus, setRecentlyUpdated, updateCall } from '../callCenterSlice'
import { AppDispatch, RootState } from '../index'
import { addNotification, setWsStatus, upsertNotification } from '../notificationSlice'
import { setAllLastSearchResults, setLastSearchResult } from '../spotFinderSlice'
import { connected, connecting, disconnected, error, reconnect, sendMessage } from '../webSocketSlice'

// We keep the socket instance outside of the Redux store since it's not serializable
let socket: Socket | null = null
let manager: Manager | null = null

export const webSocketMiddleware: Middleware = (store) => (next) => (action) => {
    const { dispatch, getState } = store

    // Handle the initial connection action
    if (connecting.match(action)) {
        // Clean up existing socket if it exists
        if (socket) {
            console.log('Cleaning up existing websocket connection before reconnecting')
            socket.removeAllListeners()
            socket.disconnect()
            socket = null
        }
        const token = localStorage.getItem(AUTH_TOKEN)!
        // Create a new manager for the connection
        manager = new Manager(`${wssHostUrl()}`, {
            reconnection: true,
            reconnectionDelay: 10000,
            reconnectionDelayMax: 15000,
            reconnectionAttempts: Infinity,
            timeout: 20000,
            autoConnect: true,
            transports: ['websocket'],
            forceNew: true,
        })

        // Enable manager debugging
        manager.on('error', (err) => {
            console.error('Socket.IO manager error:', err)
        })

        socket = manager.socket('/v1/client', { auth: { token } })

        socket.on('connect', () => {
            console.log('WebSocket connected')
            dispatch(connected())
            dispatch(setWsStatus(WebSocketStatus.CONNECTED))
            dispatch(setCallWsStatus('connected'))

            // Send initial messages
            const spotSearchFetch = {
                categoryType: WsMessageCategory.LoadFinder,
                data: {
                    action: LoadFinderActionTypes.LF_FetchAllSearchResults,
                    searchResultMap: {},
                },
            }

            const getActiveCallsMessage = {
                categoryType: WsMessageCategory.CallCenter,
                data: {
                    action: CallActionTypes.CC_GetActiveCalls,
                },
            }

            dispatch(sendMessage(spotSearchFetch))
            dispatch(sendMessage(getActiveCallsMessage))
        })

        socket.on('connect_error', (connectError) => {
            console.log('connect_error', connectError)
            dispatch(error(connectError.message))
            dispatch(setWsStatus(WebSocketStatus.ERROR))
            dispatch(setCallWsStatus('disconnected'))
        })

        socket.on('reconnect_attempt', (attempt) => {
            console.log(`WebSocket reconnection attempt ${attempt}`)
            dispatch(reconnect())
            dispatch(setWsStatus(WebSocketStatus.CONNECTING))
        })

        socket.on('reconnect', () => {
            console.log('WebSocket reconnected successfully')
            dispatch(connected())
            dispatch(setWsStatus(WebSocketStatus.CONNECTED))
            dispatch(setCallWsStatus('connected'))
        })

        socket.on('reconnect_error', (reconnectError) => {
            console.log('WebSocket reconnection error:', reconnectError)
            dispatch(error(reconnectError.message))
            dispatch(setWsStatus(WebSocketStatus.ERROR))
            dispatch(setCallWsStatus('disconnected'))
        })

        socket.on('reconnect_failed', () => {
            console.log('WebSocket reconnection failed after all attempts')
            dispatch(error('Reconnection failed after all attempts'))
            dispatch(setWsStatus(WebSocketStatus.ERROR))
            dispatch(setCallWsStatus('disconnected'))
        })

        socket.on('message', (event) => {
            try {
                const parsedData = JSON.parse(event) as WsMessage

                if (parsedData) {
                    const numeoMessage = parsedData

                    switch (numeoMessage.categoryType) {
                        case WsMessageCategory.CallCenter:
                            handleCallCenterMessage(numeoMessage as CallBaseMessage, dispatch)
                            break
                        case WsMessageCategory.LoadFinder:
                            handleLoadFinderMessages(numeoMessage as LoadFinderBaseMesage, dispatch, getState)
                            break
                        default:
                            console.error('WS event not handled', parsedData)
                            return
                    }
                }
            } catch (error) {
                console.error('Error handling WebSocket message:', error)
            }
        })

        socket.on('close', (reason) => {
            console.log(`Connection closed: ${reason})`)
            dispatch(disconnected(reason))
            dispatch(setWsStatus(WebSocketStatus.DISCONNECTED))
            dispatch(setCallWsStatus('disconnected'))

            // Only set socket to null on explicit force disconnect
            if (reason === SockerIoDisconnectReason.FORCE_DISCONNECT) {
                socket = null
                // Add notification about duplicate connection
                dispatch(
                    addNotification({
                        id: `duplicate_connection_${Date.now()}`,
                        category: Category.Error,
                        message: 'You are disconnected due to another active session. Refresh the page to reconnect.',
                        timestamp: Date.now(),
                        type: 'error',
                        topic: 'duplicate_connection',
                    })
                )
                // Don't attempt to reconnect on force disconnect
            } else {
                dispatch(reconnect())
                dispatch(setWsStatus(WebSocketStatus.CONNECTING))
            }
        })

        socket.on('disconnect', (reason) => {
            console.log(`WebSocket disconnected: ${reason}`)

            // Save the current state for reference
            const state = getState()
            const customDisconnectReason = state.webSocket.error

            dispatch(disconnected(reason))
            dispatch(setWsStatus(WebSocketStatus.DISCONNECTED))
            dispatch(setCallWsStatus('disconnected'))

            // If the disconnect was explicitly initiated by the app or it's a forced disconnect
            const isMasterLayoutUnmounted = customDisconnectReason === 'MasterLayout unmounted'
            if (reason === SockerIoDisconnectReason.FORCE_DISCONNECT || isMasterLayoutUnmounted) {
                // Completely clean up the socket and prevent reconnection
                if (socket) {
                    // Remove all listeners to prevent any reconnection attempts
                    socket.removeAllListeners()
                    socket.disconnect()
                    socket = null
                }

                // Add notification about duplicate connection
                dispatch(
                    addNotification({
                        id: `duplicate_connection_${Date.now()}`,
                        category: Category.Error,
                        message: 'You are disconnected due to another active session. Refresh the page to reconnect.',
                        timestamp: Date.now(),
                        type: 'warning',
                        topic: 'duplicate_connection',
                    })
                )
            } else {
                // Only attempt reconnect if not a force disconnect
                dispatch(reconnect())
                dispatch(setWsStatus(WebSocketStatus.CONNECTING)) // Delay of 2 seconds
            }
        })
    }

    // Handle send message action
    if (sendMessage.match(action)) {
        if (socket && socket.connected) {
            try {
                const payload = JSON.stringify(action.payload)
                console.log('Before sending message via WebSocket:', payload)
                socket.send(payload)
            } catch (error) {
                console.error('Error sending message via WebSocket:', error)
            }
        } else {
            console.warn('WebSocket is not connected or open')
        }
    }

    // Handle cleanup on disconnected action
    if (disconnected.match(action) && socket) {
        const reason = action.payload
        console.log(`Explicit disconnection triggered with reason: ${reason}`)

        // Handle explicit disconnection from the app
        if (reason === SockerIoDisconnectReason.FORCE_DISCONNECT || reason === 'MasterLayout unmounted') {
            console.log('Cleaning up socket connection...')
            // Remove all listeners to prevent any reconnection attempts
            socket.removeAllListeners()
            socket.disconnect()
            socket = null

            // Prevent any automatic reconnection attempts
            console.log('Explicit disconnection complete - no automatic reconnection will be attempted')
        }
    }

    // Pass the action to the next middleware
    return next(action)
}

// Helper function to handle CallCenter messages
const handleCallCenterMessage = (message: CallBaseMessage, dispatch: AppDispatch): boolean => {
    let notification: CallCenterNotification | null = new CallCenterNotification(message)

    switch (message.data.action) {
        // Add calls to the state
        case CallActionTypes.CC_GetActiveCalls: {
            const typedData = message.data as GetActiveCallsMessage
            typedData.calls.forEach((call) => {
                dispatch(updateCall({ callId: call.callId, updates: call }))
            })
            notification = null // No notification for GetActiveCalls
            break
        }
        // Add new call to the state
        case CallActionTypes.CC_CallStarted: {
            console.log('WS::handleCallCenterMessage::CC_CallStarted', message)
            const typedData = message.data as CallStartedMessage
            dispatch(updateCall({ callId: typedData.call.callId, updates: typedData.call }))
            dispatch(setRecentlyUpdated(typedData.call.callId))
            break
        }
        // Update transcription
        case CallActionTypes.CC_CallUpdated: {
            console.log('WS::handleCallCenterMessage::CC_CallUpdated', message)
            const typedData = message.data as CallUpdatedMessage
            dispatch(updateCall({ callId: typedData.call.callId, updates: typedData.call }))
            dispatch(setRecentlyUpdated(typedData.call.callId))
            break
        }
        // Remove call from the state
        case CallActionTypes.CC_CallEnded: {
            console.log('WS::handleCallCenterMessage::CC_CallEnded', message)
            const typedData = message.data as CallEndedMessage
            dispatch(removeCall(typedData.callId))
            dispatch(setRecentlyUpdated(typedData.callId))
            break
        }
        default:
            return false
    }

    if (notification) {
        console.log('WS::handleCallCenterMessage::notification', notification)
        const plainNotification = {
            id: notification.id,
            category: notification.category,
            message: notification.message,
            secondaryMessage: notification.secondaryMessage,
            timestamp: notification.timestamp,
            type: notification.type,
            path: notification.path,
            topic: notification.topic,
        }

        dispatch(upsertNotification(plainNotification))
    }

    return true
}

// Helper function to handle LoadFinder messages
const handleLoadFinderMessages = (message: LoadFinderBaseMesage, dispatch: AppDispatch, getState: () => RootState): boolean => {
    if (message.categoryType !== WsMessageCategory.LoadFinder) return false

    try {
        switch (message.data.action) {
            case LoadFinderActionTypes.LF_FetchAllSearchResults: {
                console.log('WS::handleAllLoadFinderMessages::', message)
                const subtype: IAllLoadFinderResultsMessage = message.data

                // Use Object.entries to ensure serializable structure
                const searchResultMap = Object.fromEntries(Object.entries(subtype.searchResultMap))

                dispatch(setAllLastSearchResults(searchResultMap))
                console.log('Updated state after LF_FetchAllSearchResults:', searchResultMap)
                break
            }
            case LoadFinderActionTypes.LF_PartialLoadUpdate: {
                console.log('WS::handlePartialLoadFinderMessages::', message)
                const subtype: IPartialLoadUpdateMessage = message.data
                const truckId = subtype.searchResult.truckId

                // Get information about the truck from application if available
                let truckName = ''

                // Get truck name from auth state
                const state = getState()
                if (state?.auth?.application?.temp?.spotFinder?.trucks) {
                    const truck = state.auth.application.temp.spotFinder.trucks.find((t) => t._id === truckId)
                    if (truck) {
                        truckName = truck.mainInfo || ''
                    }
                }

                // Create detailed secondary message with additional info
                let notificationSecondaryMessage = `Truck Name: ${truckName}`

                // Add search parameters if available
                const searchParams = subtype.searchResult?.searchParams

                // Add origin/destination info if available
                if (searchParams.origin) {
                    const origin = searchParams.origin
                    if (origin.city && origin.stateProv) {
                        notificationSecondaryMessage += `\nOrigin: ${origin.city}, ${origin.stateProv}`
                    }
                }

                if (searchParams.destination) {
                    const destination = searchParams.destination
                    if (destination.destinationType === 'city-state' && destination.city && destination.stateProv) {
                        notificationSecondaryMessage += `\nDestination: ${destination.city}, ${destination.stateProv}`
                    } else if (destination.destinationType === 'states' && destination.states) {
                        notificationSecondaryMessage += `\nDestination States: ${destination.states.join(', ')}`
                    } else {
                        notificationSecondaryMessage += `\nDestination: Open`
                    }
                }

                // Get current state to check for existing loads
                const currentState = getState()
                const currentStoreResult = currentState.spotFinder.lastSearchResults[truckId] || {}
                const currentLoads = currentStoreResult.loads || {}

                const { result, newLoadsCount, updatedLoadsCount } = handleLoadsUpdate(currentStoreResult, subtype.searchResult, currentLoads)

                // Check if this is a search started event
                if (subtype.searchResult.shouldNotify) {
                    if (subtype.searchResult.searchStatus !== SearchStatus.searchStarted) {
                        // Create a notification for search start
                        const startNotification = {
                            ...new LoadFinderNotification(subtype),
                            message: `Search ${subtype.searchResult.searchStatus === SearchStatus.searchInitialized ? 'started' : 'finished'}`,
                            secondaryMessage: notificationSecondaryMessage,
                            type: 'info' as const,
                        }

                        // Dispatch notification for search started
                        dispatch(addNotification(startNotification))

                        // Update state with search initialized status
                        dispatch(setLastSearchResult({ truckId, newResult: result }))
                        break
                    }

                    // Show notifications for loads:
                    if (newLoadsCount > 0 || updatedLoadsCount > 0) {
                        // Only if shouldNotify is true

                        // Create a more detailed notification message
                        let notificationMessage = ''
                        if (newLoadsCount > 0) {
                            notificationMessage = `Found ${newLoadsCount} load${newLoadsCount !== 1 ? 's' : ''}`
                        } else if (updatedLoadsCount > 0) {
                            notificationMessage = `Updated ${updatedLoadsCount} load${updatedLoadsCount !== 1 ? 's' : ''}`
                        }

                        // Create notification object with improved message content
                        const newNotification = {
                            ...new LoadFinderNotification(subtype),
                            message: notificationMessage,
                            secondaryMessage: notificationSecondaryMessage,
                        }

                        // Dispatch notification for the new loads
                        dispatch(upsertNotification(newNotification))

                        // Update the redux state with the search results
                        dispatch(setLastSearchResult({ truckId, newResult: result }))
                        break
                    }
                }

                // Update the redux state with the search results
                dispatch(setLastSearchResult({ truckId, newResult: result }))
                break
            }
            default:
                return false
        }

        return true
    } catch (error) {
        console.error('Error parsing server message:', error)
        return false
    }
}
