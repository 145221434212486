// Import the functions you need from the SDKs you need
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyAmrkCqptDQRhcwgotv93wmh9oaYhIBaVg',
    authDomain: 'numeo-prod-4fad6.firebaseapp.com',
    projectId: 'numeo-prod-4fad6',
    storageBucket: 'numeo-prod-4fad6.firebasestorage.app',
    messagingSenderId: '465194340502',
    appId: '1:465194340502:web:afa0f08096492f47afb2b7',
    measurementId: 'G-C69Z2E3DFX',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fireEvent = (eventName: string, params?: any) => {
    logEvent(analytics, eventName, params)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const registerUserForAnalytics = (userId: string, data: any) => {
    setUserId(analytics, userId)
    setUserProperties(analytics, data)
}
