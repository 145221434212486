import { Stack } from '@mui/joy'
import { EmailTemplateSettings } from './EmailTemplateSettings'
import { EmailFooterSettings } from './EmailFooterSettings'

export const EmailTemplateTab = () => {
    return (
        <>
            <Stack direction={'column'} spacing={2}>
                <EmailTemplateSettings />
                <EmailFooterSettings />
            </Stack>
        </>
    )
}
