import { Checkbox, Tooltip } from '@mui/joy'
import React from 'react'

interface SelectAllCheckboxProps {
    isAllSelected: boolean
    isIndeterminate: boolean
    onToggleSelectAll: () => void
}

const SelectAllCheckbox: React.FC<SelectAllCheckboxProps> = ({ isAllSelected, isIndeterminate, onToggleSelectAll }) => {
    return (
        <Tooltip title="Select or unselect all loads" variant="soft">
            <Checkbox checked={isAllSelected} indeterminate={isIndeterminate} onChange={onToggleSelectAll} sx={{ p: 0 }} />
        </Tooltip>
    )
}

export default SelectAllCheckbox
