/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Divider, Sheet } from '@mui/joy'
import { EmailAttachment, EmailParticipant } from '@numeo/types'
import React from 'react'
import { EmailAttachments, EmailDetails, EmailHeader } from './components'
import EmailBody from '../../../../../../../../../../components/common/EmailBody'

interface EmailCardProps {
    body: string
    subject: string
    from: EmailParticipant
    to: EmailParticipant
    subtitle: string
    attachments?: EmailAttachment[]
    isReply?: boolean
    isFromMainSender: boolean
    subtitleStyle?: Record<string, any>
    messageId: string
}

const EmailCard: React.FC<EmailCardProps> = ({ body, subject, from, to, isReply, subtitle, isFromMainSender, attachments, subtitleStyle, messageId }) => {
    if (!body) {
        body = 'No body'
    }

    if (!subject) {
        subject = 'No subject'
    }

    return (
        <Sheet
            variant="outlined"
            sx={{
                borderRadius: 'sm',
                p: 2,
                pb: 0,
                mb: 2,
                ml: isReply && !isFromMainSender ? 'auto' : 0,
                width: isReply ? '90%' : '100%',
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: isReply ? 1 : 2 }}>
                <EmailHeader from={from} subtitle={subtitle} isReply={isReply} subtitleStyle={subtitleStyle} />
            </Box>

            {!isReply && <Divider sx={{ mt: 2 }} />}

            <EmailDetails subject={subject} from={from} to={to} isReply={isReply} />
            <Divider />

            <Box sx={{ mt: 2, height: 400 }}>
                <EmailBody body={body} subject={subject} attachments={attachments} messageId={messageId} />
            </Box>

            <EmailAttachments attachments={attachments || []} />
        </Sheet>
    )
}

export default EmailCard
