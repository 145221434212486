import { useEffect } from 'react'

export default function RingCentralCallback() {
    useEffect(() => {
        const code = new URLSearchParams(window.location.search).get('code')

        window.opener?.postMessage(
            {
                type: 'RINGCENTRAL_AUTH_SUCCESS',
                code,
            },
            window.location.origin
        )
    }, [])

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <p>Authenticated... This window should be autmatically closed soon.</p>
        </div>
    )
}
