import { Box, FormControl, FormHelperText, FormLabel, Input, Stack, Typography } from '@mui/joy'
import { useEffect, useState } from 'react'
import axios from 'axios'

export const SpotFinderSettings = () => {
    const [autoStopTime, setAutoStopTime] = useState<number>(60)
    const [hasChanges, setHasChanges] = useState(false)

    useEffect(() => {
        // Fetch current settings
        const fetchSettings = async () => {
            try {
                const response = await axios.get('/application/settings')
                if (response.data?.settings?.temp?.spotFinder?.searchAutoStopTimeMinutes) {
                    setAutoStopTime(response.data.settings.temp.spotFinder.searchAutoStopTimeMinutes)
                }
            } catch (error) {
                // Properly handle the error by checking its type
                if (axios.isAxiosError(error)) {
                    console.error('Failed to fetch settings:', error.message, error.response?.data)
                } else {
                    console.error('Failed to fetch settings:', error)
                }
            }
        }

        fetchSettings()
    }, [])

    const handleAutoStopTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10)
        if (!isNaN(value) && value > 0) {
            setAutoStopTime(value)
            setHasChanges(true)
        }
    }

    const handleSave = async () => {
        if (hasChanges) {
            try {
                // Make sure we're using the correct API endpoint with the v1 prefix
                await axios.patch('/application/settings', {
                    temp: {
                        spotFinder: {
                            searchAutoStopTimeMinutes: autoStopTime,
                        },
                    },
                })
                setHasChanges(false)
            } catch (error) {
                // Properly handle the error by checking its type
                if (axios.isAxiosError(error)) {
                    console.error('Failed to save settings:', error.message, error.response?.data)
                } else {
                    console.error('Failed to save settings:', error)
                }
            }
        }
    }

    const handleBlur = () => {
        if (hasChanges) {
            handleSave()
        }
    }

    return (
        <Stack spacing={3}>
            <Typography level="h4">SpotFinder Settings</Typography>
            <Box sx={{ maxWidth: 400 }}>
                <FormControl>
                    <FormLabel>Auto-stop Search Time (minutes)</FormLabel>
                    <Input
                        type="number"
                        placeholder="60"
                        value={autoStopTime}
                        onChange={handleAutoStopTimeChange}
                        onBlur={handleBlur}
                        slotProps={{
                            input: {
                                min: 1,
                            },
                        }}
                    />
                    <FormHelperText>Searches will automatically stop after this time period (in minutes). Default is 60 minutes (1 hour).</FormHelperText>
                </FormControl>
            </Box>
        </Stack>
    )
}

export default SpotFinderSettings
