import * as yup from 'yup'

export interface CompanyInfoFormData {
    companyName: string
    companyAddress: string
    phoneNumber: string
    mcNumber: string
    details?: string
}

export interface DatCredentialsFormData {
    orgUsername: string
    orgPassword: string
    username: string
}

// This interface is no longer needed since we fixed the main interface
/* export interface DatCredentialsFormValues {
    orgUsername: string
    orgPassword: string
    username: string
    emails: string[]
} */

export const companyInfoValidationSchema = yup.object({
    companyName: yup.string().required('Company name is required'),
    companyAddress: yup.string().required('Company address is required'),
    phoneNumber: yup.string().required('Phone number is required'),
    mcNumber: yup
        .string()
        .required('MC number is required')
        .matches(/^\d{5,7}$/, 'MC number must be 5-7 digits'),
    details: yup.string(),
})

export const datCredentialsValidationSchema = yup.object().shape({
    orgUsername: yup.string().required('Organization username is required'),
    orgPassword: yup.string().required('Organization password is required'),
    username: yup.string().required('Username is required'),
})
