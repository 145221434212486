import { Box, CircularProgress, Divider, LinearProgress, List, ListItem, ListItemText, Paper, TextField, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { CityLocationData, LocationType, StateLocationData, locationDataSearchV2 } from 'utils/location-search/LocationDataSearchV2'

export const AdvancedLocationSearch = () => {
    const [query, setQuery] = useState('')
    const [results, setResults] = useState<StateLocationData[] | CityLocationData[]>([])
    const [metrics, setMetrics] = useState<Array<{ timestamp: number; durationMs: number }>>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const search = async () => {
            if (query.trim()) {
                setLoading(true)
                try {
                    if (query.length <= 2) {
                        const results = await locationDataSearchV2.searchByStateCodes(query)
                        console.log('Results:', results)
                        setResults(results)
                    } else {
                        const results = await locationDataSearchV2.search(query)
                        console.log('Results:', results)
                        setResults(results)
                    }
                } finally {
                    setLoading(false)
                }
            }
        }

        const timeoutId = setTimeout(search, 300)
        return () => clearTimeout(timeoutId)
    }, [query])

    useEffect(() => {
        const loadMetrics = async () => {
            const storedMetrics = await locationDataSearchV2.getMetrics()
            setMetrics(storedMetrics)
        }
        loadMetrics()
        const interval = setInterval(loadMetrics, 5000)
        return () => clearInterval(interval)
    }, [])

    const metricsSummary = useMemo(() => {
        if (metrics.length === 0) return null

        const durations = metrics.map((m) => m.durationMs)
        const average = durations.reduce((a, b) => a + b, 0) / durations.length

        return (
            <Box sx={{ display: 'flex', gap: 3, mb: 2 }}>
                <Typography variant="body2">Avg. Search: {average.toFixed(1)}ms</Typography>
                <Typography variant="body2">Total Searches: {metrics.length}</Typography>
                <Typography variant="body2">
                    Min/Max: {Math.min(...durations).toFixed(1)}ms / {Math.max(...durations).toFixed(1)}ms
                </Typography>
            </Box>
        )
    }, [metrics])

    return (
        <Paper elevation={3} sx={{ p: 3, width: '100%', maxWidth: 800 }}>
            <TextField
                fullWidth
                variant="outlined"
                label="Search Locations"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                InputProps={{
                    endAdornment: loading && <CircularProgress size={20} />,
                }}
            />

            {loading && <LinearProgress sx={{ mt: 1 }} />}

            <Divider sx={{ my: 3 }} />

            <Typography variant="h6" gutterBottom>
                Search Results ({results.length})
            </Typography>

            <List sx={{ maxHeight: 300, overflow: 'auto' }}>
                {results.map((location) => (
                    <ListItem key={location.id}>
                        {location.type === LocationType.STATE ? (
                            <ListItemText primary={`${location.stateName} (${location.stateCode})`} />
                        ) : (
                            <ListItemText
                                primary={`${location.city}, ${location.stateCode}`}
                                secondary={`Lat: ${location.latitude}, Lng: ${location.longitude}`}
                            />
                        )}
                    </ListItem>
                ))}
            </List>

            <Divider sx={{ my: 3 }} />

            <Typography variant="h6" gutterBottom>
                Performance Metrics
            </Typography>

            {metricsSummary}

            <ResponsiveContainer width="100%" height={200}>
                <LineChart data={metrics}>
                    <XAxis dataKey="timestamp" tickFormatter={(ts) => new Date(ts).toLocaleTimeString()} />
                    <YAxis />
                    <Tooltip labelFormatter={(label) => new Date(label).toLocaleTimeString()} />
                    <Line type="monotone" dataKey="durationMs" stroke="#8884d8" name="Search Duration (ms)" />
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    )
}
