import { FormControl, FormLabel, Input, Stack, Box, useTheme, Switch } from '@mui/joy'
import { FormControlLabel } from '@mui/material'
import { Truck } from '@numeo/types'
import { FormikProps, useFormik } from 'formik'
import { useEffect } from 'react'
import * as Yup from 'yup'

interface PreferencesProps {
    truckData: Truck
    onUpdate: (field: string, value: string | number | null | boolean) => void
    formRef: React.MutableRefObject<FormikProps<TruckPreferences>>
}

export interface TruckPreferences {
    deadHeadOrigin?: string
    deadHeadDestination?: string
    maxTravelHoursPerDay?: string
    weeklyGrossTarget?: number | string
    minTravelDistance?: number | string
    maxTravelDistance?: number | string
}

const validationSchema = Yup.object().shape({
    deadHeadOrigin: Yup.string().nullable().matches(/^\d*$/, 'Must be a number'),
    deadHeadDestination: Yup.string().nullable().matches(/^\d*$/, 'Must be a number'),
    maxTravelHoursPerDay: Yup.string().nullable(),
    weeklyGrossTarget: Yup.number().nullable(),
    minTravelDistance: Yup.number().nullable(),
    maxTravelDistance: Yup.number().nullable(),
})

const Preferences: React.FC<PreferencesProps> = ({ truckData, onUpdate, formRef }) => {
    const theme = useTheme()

    const formik = useFormik<TruckPreferences>({
        initialValues: {
            deadHeadOrigin: truckData.deadHeadOrigin || '',
            deadHeadDestination: truckData.deadHeadDestination || '',
            maxTravelHoursPerDay: truckData.maxTravelHoursPerDay || '',
            weeklyGrossTarget: truckData.weeklyGrossTarget || '',
            minTravelDistance: truckData.minTravelDistance || '',
            maxTravelDistance: truckData.maxTravelDistance || '',
        },
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: () => {},
    })

    // Assign formik instance to ref
    useEffect(() => {
        if (formRef) {
            formRef.current = formik
        }
    }, [formik, formRef])

    return (
        <Box
            p={3}
            sx={{
                color: theme.palette.text.primary,
                borderRadius: 'md',
            }}
        >
            <Stack spacing={2}>
                <Stack direction={{ sm: 'column', md: 'row' }} gap={2}>
                    <FormControl sx={{ width: '100%' }} error={!!formik.errors.deadHeadOrigin}>
                        <FormLabel>Max Dead Head Origin</FormLabel>
                        <Input
                            value={formik.values.deadHeadOrigin}
                            onChange={(e) => {
                                formik.handleChange(e)
                                onUpdate('deadHeadOrigin', e.target.value)
                            }}
                            onBlur={formik.handleBlur}
                            name="deadHeadOrigin"
                        />
                        {formik.touched.deadHeadOrigin && formik.errors.deadHeadOrigin && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.deadHeadOrigin}</Box>
                        )}
                    </FormControl>

                    <FormControl sx={{ width: '100%' }} error={!!formik.errors.deadHeadDestination}>
                        <FormLabel>Max Dead Head Destination</FormLabel>
                        <Input
                            value={formik.values.deadHeadDestination}
                            onChange={(e) => {
                                formik.handleChange(e)
                                onUpdate('deadHeadDestination', e.target.value)
                            }}
                            onBlur={formik.handleBlur}
                            name="deadHeadDestination"
                        />
                        {formik.touched.deadHeadDestination && formik.errors.deadHeadDestination && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.deadHeadDestination}</Box>
                        )}
                    </FormControl>
                </Stack>

                <Stack direction={{ sm: 'column', md: 'row' }} gap={2}>
                    <FormControl sx={{ width: '100%' }} error={!!formik.errors.maxTravelHoursPerDay}>
                        <FormLabel>Max Travel Hours Per Day</FormLabel>
                        <Input
                            type="number"
                            value={formik.values.maxTravelHoursPerDay}
                            onChange={(e) => {
                                formik.handleChange(e)
                                onUpdate('maxTravelHoursPerDay', e.target.value)
                            }}
                            onBlur={formik.handleBlur}
                            name="maxTravelHoursPerDay"
                        />
                        {formik.touched.maxTravelHoursPerDay && formik.errors.maxTravelHoursPerDay && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.maxTravelHoursPerDay}</Box>
                        )}
                    </FormControl>

                    <FormControl sx={{ width: '100%' }} error={!!formik.errors.weeklyGrossTarget}>
                        <FormLabel>Weekly Gross Target ($)</FormLabel>
                        <Input
                            type="number"
                            value={formik.values.weeklyGrossTarget}
                            onChange={(e) => {
                                formik.handleChange(e)
                                onUpdate('weeklyGrossTarget', Number(e.target.value))
                            }}
                            onBlur={formik.handleBlur}
                            name="weeklyGrossTarget"
                        />
                        {formik.touched.weeklyGrossTarget && formik.errors.weeklyGrossTarget && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.weeklyGrossTarget}</Box>
                        )}
                    </FormControl>
                </Stack>

                <Stack direction={{ sm: 'column', md: 'row' }} gap={2}>
                    <FormControl sx={{ width: '100%' }} error={!!formik.errors.minTravelDistance}>
                        <FormLabel>Min Travel Distance Preferences</FormLabel>
                        <Input
                            type="number"
                            value={formik.values.minTravelDistance}
                            onChange={(e) => {
                                formik.handleChange(e)
                                onUpdate('minTravelDistance', Number(e.target.value))
                            }}
                            onBlur={formik.handleBlur}
                            name="minTravelDistance"
                        />
                        {formik.touched.minTravelDistance && formik.errors.minTravelDistance && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.minTravelDistance}</Box>
                        )}
                    </FormControl>

                    <FormControl sx={{ width: '100%' }} error={!!formik.errors.maxTravelDistance}>
                        <FormLabel>Max Travel Distance Preferences</FormLabel>
                        <Input
                            type="number"
                            value={formik.values.maxTravelDistance}
                            onChange={(e) => {
                                formik.handleChange(e)
                                onUpdate('maxTravelDistance', Number(e.target.value))
                            }}
                            onBlur={formik.handleBlur}
                            name="maxTravelDistance"
                        />
                        {formik.touched.maxTravelDistance && formik.errors.maxTravelDistance && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.maxTravelDistance}</Box>
                        )}
                    </FormControl>
                </Stack>

                <Stack direction="row" spacing={2}>
                    <FormControlLabel
                        control={
                            <Switch
                                sx={{ mr: 1 }}
                                checked={truckData.avoidWinterRoads || false}
                                onChange={(e) => onUpdate('avoidWinterRoads', e.target.checked)}
                            />
                        }
                        label="Avoid Winter Roads"
                    />
                </Stack>
            </Stack>
        </Box>
    )
}

export default Preferences
