import { Box, Typography } from '@mui/joy'

export const UpdaterAgentDashboardPage = () => {
    return (
        <Box>
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="95vh">
                <Typography level="body-lg" fontSize={35} color="primary">
                    Welcome, Updater Agent!
                </Typography>
            </Box>
        </Box>
    )
}
