import { configureStore } from '@reduxjs/toolkit'
import spotFinderReducer from './spotFinderSlice'
import callCenterReducer from './callCenterSlice'
import notificationReducer from './notificationSlice'
import webSocketReducer from './webSocketSlice'
import authReducer from './authSlice'
import { webSocketMiddleware } from './middleware/webSocketMiddleware'

const rootReducer = {
    spotFinder: spotFinderReducer,
    callCenter: callCenterReducer,
    notification: notificationReducer,
    webSocket: webSocketReducer,
    auth: authReducer,
}

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredPaths: ['callCenter.activeRingCentralCall.session', 'notification.notifications'],

                // Ignore non-serializable values in WebSocket actions
                ignoredActions: [
                    'webSocket/connecting',
                    'webSocket/connected',
                    'webSocket/disconnected',
                    'callCenter/upsertRingCentralCallSession',
                    'notification/addNotification',
                ],
            },
        }).concat(webSocketMiddleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
