import { Box, Button, Typography } from '@mui/joy'
import { Popover } from '@mui/material'
import React from 'react'
import { PopoverBookConfirmationProps } from './types'

export const PopoverBookConfirmation: React.FC<PopoverBookConfirmationProps> = ({ anchorEl, handleClose, handleConfirm }) => {
    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box sx={{ p: 2 }}>
                <Typography sx={{ mb: 2 }}>Are you sure you want to book this load?</Typography>
                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                    <Button variant="outlined" color="neutral" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="solid" onClick={handleConfirm}>
                        Confirm
                    </Button>
                </Box>
            </Box>
        </Popover>
    )
}
