import { Call as CallIcon } from '@mui/icons-material'
import { Badge, Box, Button, CircularProgress, Input, Sheet, Stack, Tab, Table, TabList, Tabs, Typography } from '@mui/joy'
import { Call } from '@numeo/types'
import { useAuth } from 'hooks/auth'
import { useCallCenter } from 'hooks/useCallCenter'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import callCenterApi from '../../api'
import CallListHeader from './CallListHeader'
import CallListItem from './CallListItem'

const ActiveCallList = () => {
    const { calls, recentlyUpdated } = useCallCenter()

    useEffect(() => {
        console.log('calls', calls)
    }, [calls])

    return (
        <>
            {calls.size === 0 ? (
                <tr>
                    <td colSpan={5} style={{ textAlign: 'center', padding: '2rem' }}>
                        <Typography level="body-sm" color="neutral">
                            No active calls
                        </Typography>
                    </td>
                </tr>
            ) : (
                Array.from(calls.values()).map((call) => <CallListItem key={call.callId} call={call} isUpdated={recentlyUpdated?.has(call.callId) || false} />)
            )}
        </>
    )
}

const PastCallList = ({ calls, loading }: { calls: Call[]; loading: boolean }) => {
    return (
        <>
            {!loading ? (
                calls.length === 0 ? (
                    <tr>
                        <td colSpan={5} style={{ textAlign: 'center', padding: '2rem' }}>
                            <Typography level="body-sm" color="neutral">
                                No past calls
                            </Typography>
                        </td>
                    </tr>
                ) : (
                    calls.map((call) => <CallListItem key={call.callId} call={call} isUpdated={false} />)
                )
            ) : (
                <tr>
                    <td colSpan={6} style={{ textAlign: 'center', padding: '2rem' }}>
                        <CircularProgress />
                    </td>
                </tr>
            )}
        </>
    )
}

export const CallList: React.FC = () => {
    const [pastCalls, setPastCalls] = React.useState<Call[]>([])
    const [searchParams, setSearchParams] = useSearchParams()
    const { application } = useAuth()
    const [loading, setLoading] = useState(false)

    const [phoneNumber, setPhoneNumber] = useState<string>('+16572893125')
    const [calling, setCalling] = useState(false)

    const makeCall = async (phoneNumber: string) => {
        try {
            setCalling(true)
            // Saluat to fix
            const call = await callCenterApi.makeCall(phoneNumber, 'pMC12345', 'New York', 'Chicago', '2024-01-01', 'Company 1', '', '')
            console.log('Call initiated:', call)
        } catch (error) {
            console.error('Error making call:', error)
        } finally {
            setCalling(false)
        }
    }

    const hasLoadedActiveCalls = React.useRef(false)

    // Set initial call_type to active if not present
    React.useEffect(() => {
        if (!searchParams.has('call_type')) {
            setSearchParams((params) => {
                params.set('call_type', 'active')
                return params
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [activeTab, setActiveTab] = React.useState<'active' | 'past'>(() => {
        const tab = searchParams.get('call_type')
        return tab === 'past' ? 'past' : 'active'
    })

    const handleTabChange = (value: 'active' | 'past') => {
        setActiveTab(value)
        setSearchParams((params) => {
            if (value === 'active') {
                params.delete('call_type')
            } else {
                params.set('call_type', value)
            }
            return params
        })
    }

    const getPastCallsCount = () => {
        let count = 0

        pastCalls.forEach((call) => {
            if (call.status === 'ended') {
                count++
            }
        })

        return count
    }

    React.useEffect(() => {
        const fetchCalls = async () => {
            try {
                setLoading(true)
                const fetchedCalls = await callCenterApi.getCallList('past', application?.projectName)
                setPastCalls(fetchedCalls || [])
            } catch (error) {
                console.error('Error fetching calls:', error)
                setPastCalls([])
            } finally {
                setLoading(false)
            }
        }
        if (activeTab === 'active' && !hasLoadedActiveCalls.current) {
            hasLoadedActiveCalls.current = true
            fetchCalls()
        } else if (activeTab === 'past') {
            fetchCalls()
        }
    }, [application?.projectName, activeTab])

    return (
        <Sheet
            variant="outlined"
            sx={{
                borderRadius: 'sm',
                overflowY: 'auto',
                overflowX: 'hidden',
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                    background: 'var(--joy-palette-background-level1)',
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: 'var(--joy-palette-neutral-softBg)',
                    borderRadius: '4px',
                    '&:hover': {
                        background: 'var(--joy-palette-neutral-plainHoverBg)',
                    },
                },
            }}
        >
            <CallListHeader pastCalls={pastCalls} loading={loading} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2, mb: 2 }}>
                <Box sx={{ flex: 1 }} /> {/* Spacer */}
                <Tabs value={activeTab} onChange={(_, value) => handleTabChange(value as 'active' | 'past')}>
                    <TabList disableUnderline sx={{ p: 0.5, gap: 0.5, borderRadius: 'xl', bgcolor: 'background.level1' }}>
                        <Tab disableIndicator value="active">
                            Active Calls
                        </Tab>
                        <Tab disableIndicator value="past">
                            <Badge sx={{ pr: 1.5 }} badgeContent={getPastCallsCount()}>
                                Past Calls
                            </Badge>
                        </Tab>
                    </TabList>
                </Tabs>
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    {' '}
                    {/* Right aligned content */}
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Input
                            size="sm"
                            placeholder="Phone number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            startDecorator={<CallIcon />}
                            sx={{ width: 200 }}
                        />
                        <Button size="sm" loading={calling} onClick={() => makeCall(phoneNumber)}>
                            <CallIcon />
                        </Button>
                    </Stack>
                </Box>
            </Box>
            <Box>
                <Table>
                    <thead>
                        <tr>
                            <th style={{ width: '48px' }}></th>
                            <th>Contact</th>
                            <th>Duration</th>
                            <th>Started At</th>
                            <th>Last Transcription</th>
                            <th style={{ width: '64px' }}></th>
                        </tr>
                    </thead>
                    <tbody>{activeTab === 'active' ? <ActiveCallList /> : <PastCallList calls={pastCalls} loading={loading} />}</tbody>
                </Table>
            </Box>
        </Sheet>
    )
}
