import { Call as CallIcon } from '@mui/icons-material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EmailIcon from '@mui/icons-material/Email'
import SaveIcon from '@mui/icons-material/Save'
import { Button, Input, Stack, Textarea } from '@mui/joy'
import { Box, CardContent, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { AdvancedLocationSearch } from 'components/LocationSearch/AdvancedLocationSearch'
import { LocationSearchExample } from 'components/LocationSearch/LocationSearchExample'
import { SockerIoDisconnectReason } from 'context/types'
import { useAuth } from 'hooks/auth'
import { useWebSocketRedux } from 'hooks/useWebSocketRedux'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { disconnected } from 'store/webSocketSlice'
import callCenterApi from '../../../dispatcher/pages/call-center/api'

// Local storage keys
export const LS_PHONE_KEY = 'numeo_dev_phone'
export const LS_EMAIL_KEY = 'numeo_dev_email'

export const DevelopersSettings: React.FC = () => {
    const { debugData, updateDebugData } = useAuth()
    const { reconnect: reconnectWs } = useWebSocketRedux()
    const dispatch = useDispatch()

    // Use state to track values
    const [phoneNumberTest, setPhoneNumberTest] = useState<string>(debugData.spotFinder.mockBrokerPhoneNumber || '')
    const [emailTest, setEmailTest] = useState<string>(debugData.spotFinder.mockBrokerEmail || '')
    const [storageUsage, setStorageUsage] = useState<string>('0 KB')

    // Storage usage monitoring
    useEffect(() => {
        const checkStorage = async () => {
            try {
                const estimate = await navigator.storage.estimate()
                setStorageUsage(`${Math.round((estimate.usage || 0) / 1024)} KB / ${Math.round((estimate.quota || 0) / 1024)} KB`)
            } catch (error) {
                console.error('Storage estimate failed:', error)
            }
        }

        checkStorage()
        const interval = setInterval(checkStorage, 5000)
        return () => clearInterval(interval)
    }, [])

    // Save values to localStorage and update app state
    const saveValues = () => {
        // Save phone number
        localStorage.setItem(LS_PHONE_KEY, phoneNumberTest)
        callCenterApi.overridePhoneNumber = phoneNumberTest
        updateDebugData({ spotFinder: { mockBrokerPhoneNumber: phoneNumberTest } })

        // Save email
        localStorage.setItem(LS_EMAIL_KEY, emailTest)
        updateDebugData({ spotFinder: { mockBrokerEmail: emailTest } })
    }

    // Clear all values
    const clearValues = () => {
        // Clear phone number
        setPhoneNumberTest('')
        localStorage.removeItem(LS_PHONE_KEY)
        callCenterApi.overridePhoneNumber = ''
        updateDebugData({ spotFinder: { mockBrokerPhoneNumber: '' } })

        // Clear email
        setEmailTest('')
        localStorage.removeItem(LS_EMAIL_KEY)
        updateDebugData({ spotFinder: { mockBrokerEmail: '' } })
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                    <Box sx={{ height: '100%', width: '100%', border: '1px solid #ccc', borderRadius: '8px', p: 2 }}>
                        <Typography variant="h6">Spot Finder</Typography>
                        <Typography variant="body2">
                            This section allows you to simulate calls to a mock broker. Enter a phone number in the field below to set the phone number that
                            will be used for testing call functionality without making actual calls.
                        </Typography>
                        <Stack spacing={2}>
                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                <Input
                                    size="sm"
                                    placeholder="Phone number"
                                    value={phoneNumberTest}
                                    onChange={(e) => setPhoneNumberTest(e.target.value)}
                                    startDecorator={<CallIcon />}
                                    sx={{ maxWidth: 200 }}
                                />
                                <Input
                                    size="md"
                                    placeholder="Email"
                                    value={emailTest}
                                    onChange={(e) => setEmailTest(e.target.value)}
                                    startDecorator={<EmailIcon />}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Button size="sm" variant="outlined" color="primary" onClick={saveValues} startDecorator={<SaveIcon />}>
                                    Save
                                </Button>
                                <Button size="sm" variant="outlined" color="danger" onClick={clearValues} startDecorator={<DeleteOutlineIcon />}>
                                    Clear
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    <Box sx={{ height: '100%', width: '100%', border: '1px solid #ccc', borderRadius: '8px', p: 2 }}>
                        {/* Web Socket Section */}
                        <Stack direction="row" spacing={2}>
                            <Typography sx={{ flexGrow: 1 }} variant="h6">
                                Web Socket
                            </Typography>
                            <Button
                                variant="outlined"
                                color="success"
                                onClick={() => {
                                    // First disconnect the socket
                                    dispatch(disconnected(SockerIoDisconnectReason.FORCE_DISCONNECT))

                                    // Then immediately reconnect it, but with a delay
                                    setTimeout(() => {
                                        console.log('Testing reconnection...')
                                        reconnectWs()
                                    }, 1500) // Increased delay to 1.5 seconds
                                }}
                            >
                                Test Reconnect
                            </Button>
                        </Stack>
                        <Textarea sx={{ mt: 1 }} placeholder="Web data" />
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    {/* Location Search Section */}
                    <Box sx={{ height: '100%', width: '100%', border: '1px solid #ccc', borderRadius: '8px' }}>
                        <CardContent sx={{ width: '100%' }}>
                            <LocationSearchExample />
                        </CardContent>
                    </Box>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    {/* Location Search Section */}
                    <Box sx={{ height: '100%', width: '100%', border: '1px solid #ccc', borderRadius: '8px' }}>
                        <CardContent sx={{ width: '100%' }}>
                            <AdvancedLocationSearch />
                            <div className="storage-monitor">
                                <span>IndexedDB Usage:</span>
                                <span>{storageUsage}</span>
                            </div>
                        </CardContent>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default DevelopersSettings
