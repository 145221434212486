import { ClientLoadModel, IBroker, RateConf } from '@numeo/types'
import { isMcNumberFavorite } from '../LoadsTable/LoadRow/index'

export const sortOptionsMap = {
    favorite: [{ key: 'favoriteFirst', label: 'Favorites First' }],
    age: [
        { key: 'ageNewest', label: 'Newest First' },
        { key: 'ageOldest', label: 'Oldest First' },
    ],
    rate: [
        { key: 'rateHighest', label: 'Highest First' },
        { key: 'rateLowest', label: 'Lowest First' },
        { key: 'ratePerMileHighest', label: 'Rate/Mile Highest First' },
        { key: 'ratePerMileLowest', label: 'Rate/Mile Lowest First' },
    ],
    rateConfirmations: [
        { key: 'rateConfirmationsHighest', label: 'Highest First' },
        { key: 'rateConfirmationsLowest', label: 'Lowest First' },
    ],
    destination: [
        { key: 'destinationAToZ', label: 'A to Z' },
        { key: 'destinationZToA', label: 'Z to A' },
    ],
    length: [
        { key: 'lengthShortest', label: 'Shortest First' },
        { key: 'lengthLongest', label: 'Longest First' },
    ],
    weight: [
        { key: 'weightHeaviest', label: 'Heaviest First' },
        { key: 'weightLightest', label: 'Lightest First' },
    ],
    capacity: [
        { key: 'capacityFullFirst', label: 'FULL First' },
        { key: 'capacityPartialFirst', label: 'PARTIAL First' },
    ],
    factorable: [
        { key: 'factorableFirst', label: 'Factorable First' },
        { key: 'unfactorableFirst', label: 'Unfactorable First' },
    ],
    trip: [
        { key: 'tripShortest', label: 'Shortest First' },
        { key: 'tripLongest', label: 'Longest First' },
    ],
    origin: [
        { key: 'originAToZ', label: 'A to Z' },
        { key: 'originZToA', label: 'Z to A' },
    ],
    company: [
        { key: 'companyNameAToZ', label: 'A to Z' },
        { key: 'companyNameZToA', label: 'Z to A' },
    ],
    contact: [
        { key: 'contactEmailFirst', label: 'Email First' },
        { key: 'contactPhoneFirst', label: 'Phone First' },
    ],
    csDtp: [
        { key: 'creditScoreHighest', label: 'CS - Highest First' },
        { key: 'creditScoreLowest', label: 'CS - Lowest First' },
        { key: 'daysToPayHighest', label: 'DTP - Highest First' },
        { key: 'daysToPayLowest', label: 'DTP - Lowest First' },
    ],
    bookable: [
        { key: 'bookableFirst', label: 'Bookable First' },
        { key: 'unbookableFirst', label: 'Unbookable First' },
    ],
    deadheadO: [
        { key: 'deadheadOShortest', label: 'Shortest First' },
        { key: 'deadheadOLongest', label: 'Longest First' },
    ],
    deadheadD: [
        { key: 'deadheadDShortest', label: 'Shortest First' },
        { key: 'deadheadDLongest', label: 'Longest First' },
    ],
    pickUp: [
        { key: 'pickUpEarliestFirst', label: 'Earliest First' },
        { key: 'pickUpLatestFirst', label: 'Latest First' },
    ],
    // is from favorite broker or no
    broker: [
        { key: 'brokerFavoriteFirst', label: 'Favorite First' },
        { key: 'brokerNotFavoriteFirst', label: 'Not Favorite First' },
    ],
}

export function sortLoads(
    loads: ClientLoadModel[],
    sortOption: string,
    allMyBrokers: IBroker[] = [],
    rateConfirmationData: Record<string, RateConf[]> = {}
): ClientLoadModel[] {
    switch (sortOption) {
        // Favorite sorting
        case 'favoriteFirst':
            return [...loads].sort((a, b) => {
                // First sort by favorite status
                if (a.isFavorite && !b.isFavorite) return -1
                if (!a.isFavorite && b.isFavorite) return 1

                // If both have same favorite status, sort by date (newest first)
                const dateA = new Date(a.postedAt || 0).getTime()
                const dateB = new Date(b.postedAt || 0).getTime()
                return dateB - dateA
            })

        // Age sorting - sort by hours from current time
        case 'ageNewest':
            return loads.sort((a, b) => {
                const timeA = (new Date().getTime() - new Date(a.postedAt).getTime()) / (60 * 60 * 1000)
                const timeB = (new Date().getTime() - new Date(b.postedAt).getTime()) / (60 * 60 * 1000)
                return timeA - timeB
            })
        case 'ageOldest':
            return loads.sort((a, b) => {
                const timeA = (new Date().getTime() - new Date(a.postedAt).getTime()) / (60 * 60 * 1000)
                const timeB = (new Date().getTime() - new Date(b.postedAt).getTime()) / (60 * 60 * 1000)
                return timeB - timeA
            })

        // Rate sorting - handle null/undefined values
        case 'rateHighest':
            return loads.sort((a, b) => {
                const rateA = a.rateInfo?.rateUsd ?? -Infinity
                const rateB = b.rateInfo?.rateUsd ?? -Infinity
                return rateB - rateA
            })
        case 'rateLowest':
            return loads.sort((a, b) => {
                const rateA = a.rateInfo?.rateUsd ?? Infinity
                const rateB = b.rateInfo?.rateUsd ?? Infinity
                return rateA - rateB
            })
        case 'ratePerMileHighest':
            return loads.sort((a, b) => {
                const rateA = a.rateInfo?.rateUsd && a.tripLength ? a.rateInfo.rateUsd / a.tripLength : -Infinity
                const rateB = b.rateInfo?.rateUsd && b.tripLength ? b.rateInfo.rateUsd / b.tripLength : -Infinity
                return rateB - rateA
            })
        case 'ratePerMileLowest':
            return loads.sort((a, b) => {
                const rateA = a.rateInfo?.rateUsd && a.tripLength ? a.rateInfo.rateUsd / a.tripLength : Infinity
                const rateB = b.rateInfo?.rateUsd && b.tripLength ? b.rateInfo.rateUsd / b.tripLength : Infinity
                return rateA - rateB
            })

        // Trip sorting - handle null/undefined values
        case 'tripShortest':
            return loads.sort((a, b) => {
                const tripA = a.tripLength ?? Infinity
                const tripB = b.tripLength ?? Infinity
                return tripA - tripB
            })
        case 'tripLongest':
            return loads.sort((a, b) => {
                const tripA = a.tripLength ?? -Infinity
                const tripB = b.tripLength ?? -Infinity
                return tripB - tripA
            })

        // Origin sorting - handle null/undefined values and case-insensitive
        case 'originAToZ':
            return loads.sort((a, b) => {
                const cityA = (a.origin?.city || '').toLowerCase()
                const cityB = (b.origin?.city || '').toLowerCase()
                return cityA.localeCompare(cityB)
            })
        case 'originZToA':
            return loads.sort((a, b) => {
                const cityA = (a.origin?.city || '').toLowerCase()
                const cityB = (b.origin?.city || '').toLowerCase()
                return cityB.localeCompare(cityA)
            })

        // Destination sorting - handle null/undefined values and case-insensitive
        case 'destinationAToZ':
            return loads.sort((a, b) => {
                const cityA = (a.destination?.city || '').toLowerCase()
                const cityB = (b.destination?.city || '').toLowerCase()
                return cityA.localeCompare(cityB)
            })
        case 'destinationZToA':
            return loads.sort((a, b) => {
                const cityA = (a.destination?.city || '').toLowerCase()
                const cityB = (b.destination?.city || '').toLowerCase()
                return cityB.localeCompare(cityA)
            })

        // Deadhead Origin sorting - handle null/undefined values
        case 'deadheadOShortest':
            return loads.sort((a, b) => {
                const milesA = a.originDeadheadMiles?.miles ?? Infinity
                const milesB = b.originDeadheadMiles?.miles ?? Infinity
                return milesA - milesB
            })
        case 'deadheadOLongest':
            return loads.sort((a, b) => {
                const milesA = a.originDeadheadMiles?.miles ?? -Infinity
                const milesB = b.originDeadheadMiles?.miles ?? -Infinity
                return milesB - milesA
            })

        // Deadhead Destination sorting - handle null/undefined values
        case 'deadheadDShortest':
            return loads.sort((a, b) => {
                const milesA = a.destinationDeadheadMiles?.miles ?? Infinity
                const milesB = b.destinationDeadheadMiles?.miles ?? Infinity
                return milesA - milesB
            })
        case 'deadheadDLongest':
            return loads.sort((a, b) => {
                const milesA = a.destinationDeadheadMiles?.miles ?? -Infinity
                const milesB = b.destinationDeadheadMiles?.miles ?? -Infinity
                return milesB - milesA
            })

        // Length sorting - handle null/undefined values
        case 'lengthLongest':
            return loads.sort((a, b) => {
                const lengthA = a.shipmentDetails?.maximumLengthFeet ?? -Infinity
                const lengthB = b.shipmentDetails?.maximumLengthFeet ?? -Infinity
                return lengthB - lengthA
            })
        case 'lengthShortest':
            return loads.sort((a, b) => {
                const lengthA = a.shipmentDetails?.maximumLengthFeet ?? Infinity
                const lengthB = b.shipmentDetails?.maximumLengthFeet ?? Infinity
                return lengthA - lengthB
            })

        // Pickup date sorting - sort by earliest/latest availability
        case 'pickUpEarliestFirst':
            return loads.sort((a, b) => {
                const dateA = new Date(a.earliestAvailability).getTime()
                const dateB = new Date(b.earliestAvailability).getTime()
                return dateA - dateB
            })
        case 'pickUpLatestFirst':
            return loads.sort((a, b) => {
                const dateA = new Date(a.latestAvailability).getTime()
                const dateB = new Date(b.latestAvailability).getTime()
                return dateB - dateA
            })

        // Weight sorting - handle null/undefined values
        case 'weightHeaviest':
            return loads.sort((a, b) => {
                const weightA = a.shipmentDetails?.maximumWeightPounds ?? -Infinity
                const weightB = b.shipmentDetails?.maximumWeightPounds ?? -Infinity
                return weightB - weightA
            })
        case 'weightLightest':
            return loads.sort((a, b) => {
                const weightA = a.shipmentDetails?.maximumWeightPounds ?? Infinity
                const weightB = b.shipmentDetails?.maximumWeightPounds ?? Infinity
                return weightA - weightB
            })

        // Capacity sorting - handle null/undefined values
        case 'capacityFullFirst':
            return loads.sort((a, b) => {
                const aIsFull = a.shipmentDetails?.fullPartial === 'FULL' ? 1 : 0
                const bIsFull = b.shipmentDetails?.fullPartial === 'FULL' ? 1 : 0
                return bIsFull - aIsFull
            })
        case 'capacityPartialFirst':
            return loads.sort((a, b) => {
                const aIsFull = a.shipmentDetails?.fullPartial === 'FULL' ? 0 : 1
                const bIsFull = b.shipmentDetails?.fullPartial === 'FULL' ? 0 : 1
                return bIsFull - aIsFull
            })

        case 'brokerFavoriteFirst':
            return [...loads].sort((a, b) => {
                const aIsFavorite = isMcNumberFavorite(allMyBrokers, a.posterInfo?.mcNumber)
                const bIsFavorite = isMcNumberFavorite(allMyBrokers, b.posterInfo?.mcNumber)
                return aIsFavorite === bIsFavorite ? 0 : aIsFavorite ? -1 : 1
            })

        case 'brokerNotFavoriteFirst':
            return [...loads].sort((a, b) => {
                const aIsFavorite = isMcNumberFavorite(allMyBrokers, a.posterInfo?.mcNumber)
                const bIsFavorite = isMcNumberFavorite(allMyBrokers, b.posterInfo?.mcNumber)
                return aIsFavorite === bIsFavorite ? 0 : aIsFavorite ? 1 : -1
            })

        // Rate confirmation count sorting
        case 'rateConfirmationsHighest':
            return [...loads].sort((a, b) => {
                // Function to get rate confirmation count for a load
                const getCount = (load: ClientLoadModel) => {
                    if (!load.posterInfo?.mcNumber) return -Infinity

                    // Extract only numeric part of the mcNumber
                    const numericMcNumber = load.posterInfo.mcNumber.replace(/\D/g, '')

                    // Check all MC numbers in the record for matches
                    for (const mcNumber in rateConfirmationData) {
                        // Skip the 'unknown' key
                        if (mcNumber === 'unknown') continue

                        // Extract numeric part from the mcNumber key
                        const numericKey = mcNumber.replace(/\D/g, '')

                        // If we find a match, return the count of rate confirmations
                        if (numericKey.includes(numericMcNumber) || numericMcNumber.includes(numericKey)) {
                            return rateConfirmationData[mcNumber].length || -Infinity
                        }
                    }

                    return -Infinity
                }

                // First sort by favorite status
                if (a.isFavorite && !b.isFavorite) return -1
                if (!a.isFavorite && b.isFavorite) return 1

                // Then sort by rate confirmation count
                return getCount(b) - getCount(a)
            })

        case 'rateConfirmationsLowest':
            return [...loads].sort((a, b) => {
                // Function to get rate confirmation count for a load
                const getCount = (load: ClientLoadModel) => {
                    if (!load.posterInfo?.mcNumber) return Infinity

                    // Extract only numeric part of the mcNumber
                    const numericMcNumber = load.posterInfo.mcNumber.replace(/\D/g, '')

                    // Check all MC numbers in the record for matches
                    for (const mcNumber in rateConfirmationData) {
                        // Skip the 'unknown' key
                        if (mcNumber === 'unknown') continue

                        // Extract numeric part from the mcNumber key
                        const numericKey = mcNumber.replace(/\D/g, '')

                        // If we find a match, return the count of rate confirmations
                        if (numericKey.includes(numericMcNumber) || numericMcNumber.includes(numericKey)) {
                            return rateConfirmationData[mcNumber].length || Infinity
                        }
                    }

                    return Infinity
                }

                // First sort by favorite status
                if (a.isFavorite && !b.isFavorite) return -1
                if (!a.isFavorite && b.isFavorite) return 1

                // Then sort by rate confirmation count
                return getCount(a) - getCount(b)
            })

        // Company sorting - handle null/undefined values and case-insensitive
        case 'companyNameAToZ':
            return loads.sort((a, b) => {
                const companyA = (a.posterInfo?.companyName || '').toLowerCase()
                const companyB = (b.posterInfo?.companyName || '').toLowerCase()
                return companyA.localeCompare(companyB)
            })
        case 'companyNameZToA':
            return loads.sort((a, b) => {
                const companyA = (a.posterInfo?.companyName || '').toLowerCase()
                const companyB = (b.posterInfo?.companyName || '').toLowerCase()
                return companyB.localeCompare(companyA)
            })

        // Factorable sorting
        case 'factorableFirst':
            return loads.sort((a, b) => (b.isFactorable ? 1 : 0) - (a.isFactorable ? 1 : 0))
        case 'unfactorableFirst':
            return loads.sort((a, b) => (a.isFactorable ? 1 : 0) - (b.isFactorable ? 1 : 0))

        // Contact sorting
        case 'contactEmailFirst':
            return loads.sort((a, b) => {
                const aHasEmail = a.posterInfo?.contact?.email ? 1 : 0
                const bHasEmail = b.posterInfo?.contact?.email ? 1 : 0
                return bHasEmail - aHasEmail
            })
        case 'contactPhoneFirst':
            return loads.sort((a, b) => {
                const aHasPhone = a.posterInfo?.contact?.phoneNumber ? 1 : 0
                const bHasPhone = b.posterInfo?.contact?.phoneNumber ? 1 : 0
                return bHasPhone - aHasPhone
            })

        // Credit Score and Days to Pay sorting
        case 'creditScoreHighest':
            return loads.sort((a, b) => (b.posterInfo?.credit?.creditScore || 0) - (a.posterInfo?.credit?.creditScore || 0))
        case 'creditScoreLowest':
            return loads.sort((a, b) => (a.posterInfo?.credit?.creditScore || 0) - (b.posterInfo?.credit?.creditScore || 0))
        case 'daysToPayHighest':
            return loads.sort((a, b) => (b.posterInfo?.credit?.daysToPay || 0) - (a.posterInfo?.credit?.daysToPay || 0))
        case 'daysToPayLowest':
            return loads.sort((a, b) => (a.posterInfo?.credit?.daysToPay || 0) - (b.posterInfo?.credit?.daysToPay || 0))

        // Book sorting - sort by bookable status
        case 'bookableFirst':
            return loads.sort((a, b) => {
                const bookableA = a.bookingUrl ? 1 : 0
                const bookableB = b.bookingUrl ? 1 : 0
                return bookableB - bookableA
            })
        case 'unbookableFirst':
            return loads.sort((a, b) => {
                const bookableA = a.bookingUrl ? 1 : 0
                const bookableB = b.bookingUrl ? 1 : 0
                return bookableA - bookableB
            })

        default:
            return loads
    }
}
