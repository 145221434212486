import { Button, Typography, Modal, ModalDialog, ModalClose, Stack, Sheet, Table, Checkbox } from '@mui/joy'
import { ReactNode } from 'react'

interface TruckSelectionModalProps {
    open: boolean
    onClose: () => void
    title: string
    description: string
    selectionTable: ReactNode
    onConfirm: () => void
    isLoading: boolean
    selectedCount: number
    buttonText?: string
}

export const TruckSelectionModal = ({
    open,
    onClose,
    title,
    description,
    selectionTable,
    onConfirm,
    isLoading,
    selectedCount,
    buttonText = 'Add Selected Trucks',
}: TruckSelectionModalProps) => {
    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog sx={{ maxWidth: 600, width: '100%' }}>
                <ModalClose />
                <Typography level="h4">{title}</Typography>
                <Typography level="body-md" sx={{ mb: 2 }}>
                    {description}
                </Typography>

                {selectionTable}

                <Stack direction="row" justifyContent={'space-between'} sx={{ mt: 2 }}>
                    <Button variant="outlined" color="neutral" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button loading={isLoading} onClick={onConfirm} disabled={selectedCount === 0}>
                        {buttonText} ({selectedCount})
                    </Button>
                </Stack>
            </ModalDialog>
        </Modal>
    )
}

interface DisconnectConfirmationModalProps {
    open: boolean
    onClose: () => void
    onConfirm: () => void
    isLoading: boolean
    serviceName: string
}

export const DisconnectConfirmationModal = ({ open, onClose, onConfirm, isLoading, serviceName }: DisconnectConfirmationModalProps) => {
    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog>
                <ModalClose />
                <Typography level="h4">Confirm Disconnection</Typography>
                <Typography level="body-md">
                    Disconnecting your {serviceName} account will delete all your connected trucks. Are you sure you want to proceed?
                </Typography>
                <Stack direction="row" justifyContent={'space-between'} sx={{ mt: 2 }}>
                    <Button variant="outlined" color="neutral" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button loading={isLoading} color="danger" onClick={onConfirm}>
                        Disconnect
                    </Button>
                </Stack>
            </ModalDialog>
        </Modal>
    )
}

interface VehicleData {
    id: string
    truckNumber: string
    vin: string
}

interface VehicleSelectionTableProps<T> {
    vehicles: T[]
    selectedVehicles: string[]
    onSelectVehicle: (id: string) => void
    onSelectAllVehicles: () => void
    getVehicleData: (vehicle: T) => VehicleData
}

export const VehicleSelectionTable = <T,>({
    vehicles,
    selectedVehicles,
    onSelectVehicle,
    onSelectAllVehicles,
    getVehicleData,
}: VehicleSelectionTableProps<T>) => {
    return (
        <Sheet sx={{ maxHeight: '400px', overflow: 'auto', mb: 2 }}>
            <Table stickyHeader>
                <thead>
                    <tr>
                        <th style={{ width: 60 }}>
                            <Checkbox
                                checked={selectedVehicles.length === vehicles.length && vehicles.length > 0}
                                indeterminate={selectedVehicles.length > 0 && selectedVehicles.length < vehicles.length}
                                onChange={onSelectAllVehicles}
                            />
                        </th>
                        <th>Truck Number</th>
                        <th>VIN</th>
                    </tr>
                </thead>
                <tbody>
                    {vehicles.map((vehicle) => {
                        const vehicleData = getVehicleData(vehicle)
                        return (
                            <tr key={vehicleData.id}>
                                <td>
                                    <Checkbox checked={selectedVehicles.includes(vehicleData.id)} onChange={() => onSelectVehicle(vehicleData.id)} />
                                </td>
                                <td>{vehicleData.truckNumber}</td>
                                <td>{vehicleData.vin}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Sheet>
    )
}
