import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { ForgotPasswordPage, LoginPage, RegisterPage, ResetPasswordPage } from './pages'
import { useAuth } from 'hooks/auth'
import PrivacyPolicyPage from './pages/privacy-policy/PrivacyPolicy'
import TermsPage from './pages/terms/Terms'
import OnboardingPage from './pages/onboarding/Onboarding'

export default function AuthRoutes() {
    const { isAuthenticated } = useAuth()
    const location = useLocation()

    // Allow access to onboarding page even if authenticated
    // This ensures users can complete the onboarding process after signup
    if (isAuthenticated && location.pathname !== '/auth/onboarding') {
        return <Navigate to="/" />
    }

    return (
        <Routes>
            <Route path="sign-in" element={<LoginPage />} />
            <Route path="sign-up" element={<RegisterPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="terms" element={<TermsPage />} />
            <Route path="onboarding" element={<OnboardingPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}
