import { Select, Option, Checkbox, Box } from '@mui/joy'
import { allTypes, subTypes } from 'utils/truckEquipmentTypes'
import { FormikProps } from 'formik'
import { useMemo } from 'react'
import { Equipment, EquipmentTypes } from '@numeo/types'

// Equipment Multi Select is component for selecting multi equipments based on types

export interface EquipmentMultiSelectProps<T extends { equipment: Equipment }> {
    disabled?: boolean
    formik: FormikProps<T>
    onChange?: (value: Equipment) => void
}

export const EquipmentMultiSelect = <T extends { equipment: Equipment }>({ disabled = false, formik, onChange }: EquipmentMultiSelectProps<T>) => {
    const selectedValues = useMemo(() => formik.values.equipment?.values || [], [formik.values.equipment])

    const hasAllTypeSelected = useMemo(() => selectedValues.some((value) => allTypes.some((type) => type.code === value)), [selectedValues])

    const hasOnlyTypeSelected = useMemo(() => selectedValues.some((value) => subTypes.some((type) => type.code === value)), [selectedValues])

    // Check if any equipment is selected
    const hasAnyEquipmentSelected = useMemo(() => selectedValues.length > 0, [selectedValues])

    // Check if all options in each category are selected
    const allAllTypesSelected = useMemo(() => allTypes.every((type) => selectedValues.includes(type.code)), [selectedValues])

    const allSubTypesSelected = useMemo(() => subTypes.every((type) => selectedValues.includes(type.code)), [selectedValues])

    const handleChange = (
        event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | React.FocusEvent<Element, Element> | null,
        newValue: string[]
    ) => {
        // Check if the new selection would mix ALL and ONLY types
        const newHasAllType = newValue.some((value) => allTypes.some((type) => type.code === value))
        const newHasOnlyType = newValue.some((value) => subTypes.some((type) => type.code === value))

        // If mixing, keep only the new type category
        let finalValue = newValue
        if (newHasAllType && newHasOnlyType) {
            // Find which type was just added
            const addedValue = newValue.find((v) => !selectedValues.includes(v))
            if (addedValue) {
                const isAddedValueFromAll = allTypes.some((type) => type.code === addedValue)
                finalValue = isAddedValueFromAll
                    ? newValue.filter((v) => allTypes.some((type) => type.code === v))
                    : newValue.filter((v) => subTypes.some((type) => type.code === v))
            }
        }

        // Check if "other" is selected
        if (newValue.includes('other')) {
            // Clear all selections
            handleClearSelection()
            return
        }

        const equipment: Equipment = {
            type: newHasAllType ? EquipmentTypes.CLASSES : EquipmentTypes.TYPES,
            values: finalValue,
        }

        formik.setFieldValue('equipment', equipment)
        onChange?.(equipment)
    }

    // Handle select/unselect all for a category
    const handleSelectAllCategory = (category: 'all' | 'only') => {
        const currentValues = [...selectedValues]
        const categoryTypes = category === 'all' ? allTypes : subTypes
        const otherCategoryTypes = category === 'all' ? subTypes : allTypes

        // Check if all items in this category are already selected
        const allSelected = categoryTypes.every((type) => currentValues.includes(type.code))

        let newValues: string[]

        if (allSelected) {
            // If all are selected, unselect all in this category
            newValues = currentValues.filter((value) => !categoryTypes.some((type) => type.code === value))
        } else {
            // If not all selected, select all in this category and remove any from other category
            newValues = [...currentValues.filter((value) => !otherCategoryTypes.some((type) => type.code === value)), ...categoryTypes.map((type) => type.code)]
        }

        const equipment: Equipment = {
            type: category === 'all' ? EquipmentTypes.CLASSES : EquipmentTypes.TYPES,
            values: newValues,
        }

        formik.setFieldValue('equipment', equipment)
        onChange?.(equipment)
    }

    // Handle clearing all selections
    const handleClearSelection = () => {
        const equipment: Equipment = {
            type: EquipmentTypes.TYPES,
            values: [],
        }

        formik.setFieldValue('equipment', equipment)
        onChange?.(equipment)
    }

    return (
        <Select
            multiple
            name="equipment"
            disabled={disabled}
            value={selectedValues}
            onChange={handleChange}
            aria-label="equipment-type-select"
            sx={{ width: '100%' }}
            slotProps={{
                listbox: {
                    sx: {
                        '--List-padding': '0px',
                        maxHeight: '400px',
                        overflow: 'auto',
                    },
                },
            }}
        >
            {!hasOnlyTypeSelected && (
                <>
                    <Box
                        sx={{
                            textTransform: 'uppercase',
                            fontSize: 'sm',
                            p: 1,
                            bgcolor: 'primary.softBg',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            borderTop: '1px solid',
                            borderBottom: '1px solid',
                            borderColor: 'primary.softActiveBg',
                        }}
                    >
                        All
                        <Checkbox
                            checked={allAllTypesSelected}
                            onChange={() => handleSelectAllCategory('all')}
                            disabled={disabled}
                            size="sm"
                            sx={{ ml: 1, boxShadow: 'xs' }}
                        />
                    </Box>
                    {allTypes.map((type) => (
                        <Option
                            key={type.code}
                            value={type.code}
                            sx={{
                                fontSize: {
                                    xs: '0.9rem',
                                    sm: '0.94rem',
                                    md: '0.97rem',
                                    lg: '1rem',
                                },
                            }}
                        >
                            {type.name}
                        </Option>
                    ))}
                </>
            )}
            {!hasAllTypeSelected && (
                <>
                    <Box
                        sx={{
                            textTransform: 'uppercase',
                            fontSize: 'sm',
                            p: 1,
                            bgcolor: 'primary.softBg',
                            position: 'sticky',
                            width: '100%',
                            top: 0,
                            zIndex: 1,
                            mt: hasOnlyTypeSelected ? 0 : 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderTop: '1px solid',
                            borderBottom: '1px solid',
                            borderColor: 'primary.softActiveBg',
                        }}
                    >
                        Only
                        <Checkbox
                            checked={allSubTypesSelected}
                            onChange={() => handleSelectAllCategory('only')}
                            disabled={disabled}
                            size="sm"
                            sx={{ ml: 1, boxShadow: 'xs' }}
                        />
                    </Box>
                    {subTypes.map((type) => (
                        <Option
                            key={type.code}
                            value={type.code}
                            sx={{
                                fontSize: {
                                    xs: '0.9rem',
                                    sm: '0.94rem',
                                    md: '0.97rem',
                                    lg: '1rem',
                                },
                            }}
                        >
                            {type.name}
                        </Option>
                    ))}
                </>
            )}
            {/* Only show Other option when some equipment is selected */}
            {hasAnyEquipmentSelected && (
                <Option
                    value="other"
                    sx={{
                        fontSize: {
                            xs: '0.9rem',
                            sm: '0.94rem',
                            md: '0.97rem',
                            lg: '1rem',
                        },
                        color: 'primary.600',
                        '&:hover': {
                            backgroundColor: 'primary.100',
                            color: 'primary.700',
                        },
                        fontWeight: 'md',
                        mt: 1,
                        pt: 0.5,
                        pb: 0.5,
                        borderTop: '1px dashed',
                        borderColor: 'divider',
                    }}
                >
                    Other
                </Option>
            )}
        </Select>
    )
}
