import { Box, Button, FormLabel, Input, Stack, Textarea, Typography } from '@mui/joy'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CompanyInfoFormData, companyInfoValidationSchema } from '../types'
import FormField from './FormField'

interface CompanyInfoStepProps {
    onNext: (data: CompanyInfoFormData) => void
    initialData?: Partial<CompanyInfoFormData>
}

export const CompanyInfoStep = ({ onNext, initialData = {} }: CompanyInfoStepProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<CompanyInfoFormData>({
        resolver: yupResolver(companyInfoValidationSchema),
        mode: 'onChange',
        defaultValues: initialData,
    })

    const onSubmit = (data: CompanyInfoFormData) => {
        console.log('next button', data)
        onNext(data)
    }

    return (
        <Box sx={{ width: '100%', mx: 'auto', height: '100%', display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%' }}>
                <Typography level="title-lg" sx={{ mb: 3 }}>
                    Company Information
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={3}>
                        <FormField error={errors.companyName}>
                            <FormLabel>Company Name</FormLabel>
                            <Input {...register('companyName')} />
                        </FormField>

                        <FormField error={errors.companyAddress}>
                            <FormLabel>Company Address</FormLabel>
                            <Input {...register('companyAddress')} />
                        </FormField>

                        <FormField error={errors.phoneNumber}>
                            <FormLabel>Phone Number</FormLabel>
                            <Input {...register('phoneNumber')} type="tel" />
                        </FormField>

                        <FormField error={errors.mcNumber}>
                            <FormLabel>MC Number</FormLabel>
                            <Input
                                {...register('mcNumber')}
                                placeholder="Enter 5-7 digit MC number"
                                slotProps={{
                                    input: {
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                    },
                                }}
                                onChange={(e) => {
                                    // Allow only digits
                                    const value = e.target.value.replace(/[^0-9]/g, '')

                                    // Update the form value
                                    e.target.value = value

                                    // Update the value in React Hook Form and trigger validation
                                    setValue('mcNumber', value, {
                                        shouldValidate: true, // This triggers validation
                                        shouldDirty: true,
                                    })
                                }}
                            />
                        </FormField>

                        <FormField error={errors.details}>
                            <FormLabel>Company Details</FormLabel>
                            <Textarea {...register('details')} placeholder="Enter additional company details" minRows={3} />
                        </FormField>

                        <Box sx={{ mt: 4 }}>
                            <Button type="submit" fullWidth>
                                Next
                            </Button>
                        </Box>
                    </Stack>
                </form>
            </Box>
        </Box>
    )
}

export default CompanyInfoStep
