import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Box, Typography } from '@mui/joy'
import Autocomplete from '@mui/joy/Autocomplete'
import CircularProgress from '@mui/joy/CircularProgress'
import * as React from 'react'
import { CityLocationData, locationDataSearchV2, LocationType, StateLocationData } from 'utils/location-search/LocationDataSearchV2'
import { LocationDataSearch } from '../../utils/location-search/LocationDataSearch'

// Special city codes mapping
const SPECIAL_CITY_CODES: Record<string, CityLocationData> = {
    NYC: {
        id: 'city-nyc',
        city: 'NYC',
        stateCode: 'NY',
        stateName: 'New York',
        type: LocationType.CITY,
        county: 'New York',
        latitude: 40.7128,
        longitude: -74.006,
    },
}

export interface ExcludeMultiSelectProps {
    disabled?: boolean
    values?: string[]
    onValuesChange?: (values: string[]) => void
    onBlur?: () => void
    placeholder?: string
    limit?: number
}

export default function ExcludeMultiSelect({
    disabled = false,
    values = [],
    onValuesChange,
    onBlur,
    placeholder = 'Select locations to exclude',
}: ExcludeMultiSelectProps) {
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState<CityLocationData[] | StateLocationData[]>([])
    const [loading, setLoading] = React.useState(false)
    const [selectedLocations, setSelectedLocations] = React.useState<Array<CityLocationData | StateLocationData>>([])
    const containerRef = React.useRef<HTMLDivElement>(null)

    const searchInstanceRef = React.useRef<LocationDataSearch | null>(null)

    React.useEffect(() => {
        const initializeSearch = async () => {
            searchInstanceRef.current = await LocationDataSearch.getInstance()

            // Cleanup
            return () => {
                if (searchInstanceRef.current) {
                    searchInstanceRef.current.close()
                }
            }
        }
        initializeSearch()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getInitialState = async () => {
        if (!searchInstanceRef.current) return
        setLoading(true)
        setOptions([])
        setLoading(false)
    }

    React.useEffect(() => {
        getInitialState()
    }, [open])

    // Convert string values to location objects
    React.useEffect(() => {
        const convertToLocationObjects = async () => {
            if (!values.length) {
                setSelectedLocations([])
                return
            }

            const locationObjects: Array<CityLocationData | StateLocationData> = []

            for (const value of values) {
                if (value.includes(',')) {
                    // This is a city
                    const [city, stateCode] = value.split(',').map((part) => part.trim())
                    locationObjects.push({
                        id: `city-${city}-${stateCode}`,
                        city,
                        stateCode,
                        stateName: '',
                        type: LocationType.CITY,
                        county: '',
                        latitude: 0,
                        longitude: 0,
                    } as CityLocationData)
                } else {
                    // This is a state
                    locationObjects.push({
                        id: `state-${value}`,
                        stateCode: value,
                        stateName: '',
                        type: LocationType.STATE,
                    } as StateLocationData)
                }
            }

            setSelectedLocations(locationObjects)
        }

        convertToLocationObjects()
    }, [values])

    // Handle location change - allow multiple states and cities
    const handleLocationChange = (event: React.SyntheticEvent<Element, Event>, newLocations: Array<CityLocationData | StateLocationData>) => {
        if (newLocations.length === 0) {
            setSelectedLocations([])
            onBlur?.()
            onValuesChange?.([])
            return
        }

        setSelectedLocations(newLocations)

        // Convert location objects to string values in the format requested
        const stringValues = newLocations.map((location) => {
            if (location.type === LocationType.CITY && 'city' in location) {
                return `${location.city}, ${location.stateCode}`
            } else {
                return location.stateCode
            }
        })

        onValuesChange?.(stringValues)
    }

    // searching from indexDB
    const handleSearch = async (query: string) => {
        setLoading(true)
        try {
            let results: CityLocationData[] | StateLocationData[] = []

            // Check for special city codes like NYC
            const normalizedQuery = query.trim().toUpperCase()
            const specialCityMatches = Object.keys(SPECIAL_CITY_CODES)
                .filter((code) => code.includes(normalizedQuery))
                .map((code) => SPECIAL_CITY_CODES[code])

            if (!query.trim() || query.length <= 2) {
                results = await locationDataSearchV2.searchByStateCodes(query)
            } else {
                results = (await locationDataSearchV2.search(query)) || []
            }

            // Add special city matches to the results
            if (specialCityMatches.length > 0) {
                // Add at the beginning to prioritize special cities
                results = [...specialCityMatches, ...results]
            }

            // Add a label to differentiate states from cities in the dropdown
            const processedResults = results.map((item) => {
                if (item.type === LocationType.STATE) {
                    return {
                        ...item,
                        displayLabel: `${item.stateCode}`,
                    }
                } else {
                    return {
                        ...item,
                        displayLabel: `${(item as CityLocationData).city}, ${item.stateCode}`,
                    }
                }
            })

            setOptions(processedResults || [])
        } catch (error) {
            console.error('Search failed:', error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Box ref={containerRef} sx={{ width: '100%' }}>
            <Autocomplete
                name="excluded-locations"
                multiple
                value={selectedLocations}
                onChange={handleLocationChange}
                disabled={disabled}
                sx={{ width: '100%' }}
                placeholder={placeholder}
                open={open}
                onOpen={() => {
                    setOpen(true)
                }}
                onClose={() => {
                    setOpen(false)
                }}
                onInputChange={(event, value) => {
                    if (typeof value === 'string') {
                        handleSearch(value)
                    }
                }}
                isOptionEqualToValue={(option, value) => {
                    if ('city' in option && 'city' in value) {
                        return option.city === value.city && option.stateCode === value.stateCode
                    }
                    return option.stateCode === value.stateCode
                }}
                getOptionLabel={(option) => {
                    if ('displayLabel' in option) {
                        return option.displayLabel as string
                    }
                    return 'city' in option ? `${option.city}, ${option.stateCode}` : `${option.stateCode}`
                }}
                renderOption={(props, option) => (
                    <li {...props} style={{ cursor: 'pointer' }} key={option.id}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <LocationOnIcon color="primary" />
                            <Typography>
                                {option.type === LocationType.CITY && 'city' in option ? `${option.city}, ${option.stateCode}` : `${option.stateCode}`}
                            </Typography>
                        </Box>
                    </li>
                )}
                options={options}
                noOptionsText="No locations found"
                loading={loading}
                limitTags={3}
                slotProps={{
                    limitTag: {
                        sx: {
                            position: 'absolute',
                            right: 60,
                            mb: 0.5,
                        },
                    },
                }}
                endDecorator={loading ? <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} /> : null}
            />
        </Box>
    )
}
