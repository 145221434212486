import { Box, CssVarsProvider, Typography } from '@mui/joy'
import { ClientLoadModel, IBroker } from '@numeo/types'
import { HorizontalDirectionIcon } from 'assets/icons/DatDirectionIcons'
import React from 'react'
import { CompanyColumn } from './CompanyColumn'
import { RateColumn } from './RateColumn'
import { TripColumn } from './TripColumn'
import { deepEqual } from '../../../../../../../../utils'
import TableCell from '@mui/material/TableCell'

/**
 * LoadDetails component displays expanded information about a load.
 * This includes:
 * - Pickup and delivery details
 * - Trip Column
 * - Rate Column
 * - Company Column
 */
export const LoadDetails: React.FC<LoadDetailsProps> = React.memo(
    ({ load, loadSearchId, handleEmailSent, broker, expanded }) => {
        if (!expanded) {
            // Add a hidden TR with a minimal height to avoid zero-sized element issues
            return <TableCell colSpan={25} style={{ padding: '0', height: '0.001px' }} />
        }
        const renderLocationDetails = () => {
            return (
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', p: 1, width: '100%' }}>
                    <Typography
                        level="title-lg"
                        sx={{
                            fontSize: 24,
                            fontWeight: 'bold',
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                        }}
                    >
                        {load.origin.city}
                        <HorizontalDirectionIcon />
                        {load.destination.city}
                    </Typography>
                    <Typography
                        level="body-sm"
                        sx={{
                            fontSize: '1.3rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {' '}
                        {load.tripLength && `${load.tripLength} ml`}
                    </Typography>
                </Box>
            )
        }

        return (
            <td colSpan={25} key={load._id}>
                <CssVarsProvider disableTransitionOnChange>
                    {renderLocationDetails()}
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            gap: 3,
                            justifyContent: 'space-between',
                            flexWrap: { sm: 'wrap', lg: 'nowrap' },
                        }}
                    >
                        <TripColumn load={load} loadSearchId={loadSearchId} />
                        <RateColumn
                            load={{
                                ...load,
                                posterInfo: {
                                    ...load?.posterInfo,
                                    contact: {
                                        ...load?.posterInfo?.contact,
                                        email: load?.posterInfo?.contact?.email || broker?.email || '',
                                    },
                                },
                            }}
                            handleEmailSent={handleEmailSent}
                        />
                        <CompanyColumn load={load} />
                    </Box>
                </CssVarsProvider>
            </td>
        )
    },
    (prevProps, nextProps) => {
        return (
            deepEqual(prevProps.load, nextProps.load) &&
            prevProps.loadSearchId === nextProps.loadSearchId &&
            deepEqual(prevProps.broker, nextProps.broker) &&
            prevProps.expanded === nextProps.expanded
        )
    }
)

/**
 * Props for the LoadDetails component that shows expanded load information
 */
export interface LoadDetailsProps {
    load: ClientLoadModel
    loadSearchId: string
    handleEmailSent: (threadId: string) => void
    broker?: IBroker
    expanded: boolean
}
