import { object, ref, string } from 'yup'

// Define the new SignUpData type that doesn't include mcNumber
export interface SignUpData {
    email: string
    password: string
    projectName: string
}

// Create a validation schema that matches our SignUpData type plus confirmation
export const signUpValidationSchema = object({
    email: string().email('Must be a valid email').required('Email is required'),
    password: string().required('Password is required').min(6, 'Password must be at least 6 characters'),
    projectName: string().required('Project name is required'),
    confirm: string()
        .required('Please confirm your password')
        .oneOf([ref('password')], 'Passwords must match'),
})

// Define the form data type explicitly to match our validation schema
export interface RegisterFormData extends SignUpData {
    confirm: string
}
