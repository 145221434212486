import { KeyboardArrowDown } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, Button, Checkbox, CircularProgress, FormControl, FormLabel, IconButton, Option, Select, Sheet, Table, Tooltip, Typography } from '@mui/joy'
import { visuallyHidden } from '@mui/utils'
import { IBroker, RateConf } from '@numeo/types'
import * as React from 'react'
import { useState } from 'react'
import BrokerRateConfDetails from './BrokerRateConfDetails'
import { MyBrokersApi } from '../api'

export interface EnhancedTableProps {
    brokers: IBroker[]
    selected: string[]
    onSort: (key: keyof IBroker, direction: 'asc' | 'desc') => void
    onSelectAll: (checked: boolean) => void
    onSelect: (id: string, checked: boolean) => void
    onEdit: (broker: IBroker) => void
    onDelete: (ids: string[]) => void
    sortConfig: { key: keyof IBroker; direction: 'asc' | 'desc' }
    page: number
    total: number
    rowsPerPage: number
    onPageChange: (newPage: number) => void
    onRowsPerPageChange: (newValue: number) => void
    onNewBtnClick: () => void
    showNewButton?: boolean
}

interface EnhancedTableToolbarProps {
    numSelected: number
    onDelete: () => void
    onNewBtnClick: () => void
    showNewButton?: boolean
}

const columns: {
    id: keyof IBroker
    label: string
    numeric: boolean
    width?: string
}[] = [
    { id: 'fullName', label: 'Broker Contact', numeric: false, width: '20%' },
    { id: 'email', label: 'Email', numeric: false, width: '25%' },
    { id: 'phone', label: 'Phone', numeric: false, width: '15%' },
    { id: 'mcNumber', label: 'MC Number', numeric: false, width: '10%' },
    { id: 'carrierMcNumber', label: 'Carrier MC Number', numeric: false, width: '10%' },
    { id: 'companyName', label: 'Company', numeric: false, width: '25%' },
    { id: 'createdAt', label: 'Created At', numeric: true, width: '10%' },
    { id: 'comment', label: 'Comment', numeric: true, width: '30%' },
]

const EnhancedTableToolbar = ({ numSelected, onDelete, onNewBtnClick, showNewButton = true }: EnhancedTableToolbarProps) => {
    return (
        <Box sx={{ p: 1, display: 'flex', alignItems: 'center', borderBottom: '1px solid', borderColor: 'divider' }}>
            {numSelected > 0 ? (
                <>
                    <Typography sx={{ flex: 1 }}>{numSelected} selected</Typography>
                    <Tooltip title="Delete">
                        <IconButton color="danger" onClick={onDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : (
                <>
                    <Typography level="h2" sx={{ flex: 1 }}>
                        Brokers
                    </Typography>
                    {showNewButton && (
                        <Tooltip title="Create a new broker">
                            <Button startDecorator={<AddIcon />} onClick={onNewBtnClick} size="sm">
                                New
                            </Button>
                        </Tooltip>
                    )}
                </>
            )}
        </Box>
    )
}

export function EnhancedBrokersTable({
    brokers,
    selected,
    onSort,
    onSelectAll,
    onSelect,
    onEdit,
    onDelete,
    sortConfig,
    page,
    total,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    onNewBtnClick,
    showNewButton = true,
}: EnhancedTableProps) {
    const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({})
    const [rateConfData, setRateConfData] = useState<Record<string, RateConf[]>>({})
    const [loadingRateConfs, setLoadingRateConfs] = useState<Record<string, boolean>>({})
    const [error, setError] = useState<Record<string, string>>({})

    const handleSort = (property: keyof IBroker) => {
        const direction = sortConfig.key === property && sortConfig.direction === 'asc' ? 'desc' : 'asc'
        onSort(property, direction)
    }

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSelectAll(event.target.checked)
    }

    const toggleRowExpansion = async (broker: IBroker) => {
        const brokerId = broker._id
        const isCurrentlyExpanded = expandedRows[brokerId] || false

        setExpandedRows((prev) => ({
            ...prev,
            [brokerId]: !isCurrentlyExpanded,
        }))

        if (!isCurrentlyExpanded && !rateConfData[brokerId] && !loadingRateConfs[brokerId]) {
            try {
                setLoadingRateConfs((prev) => ({ ...prev, [brokerId]: true }))
                setError((prev) => ({ ...prev, [brokerId]: '' }))

                const myBrokersApi = new MyBrokersApi()
                const rateConfs = await myBrokersApi.getRateConfsByMcNumber(broker.mcNumber)

                setRateConfData((prev) => ({ ...prev, [brokerId]: rateConfs }))
            } catch (err) {
                setError((prev) => ({ ...prev, [brokerId]: err instanceof Error ? err.message : 'Failed to load rate confirmations' }))
            } finally {
                setLoadingRateConfs((prev) => ({ ...prev, [brokerId]: false }))
            }
        }
    }

    return (
        <Sheet variant="outlined" sx={{ borderRadius: 'sm', overflow: 'auto' }}>
            <EnhancedTableToolbar
                numSelected={selected.length}
                onDelete={() => onDelete(selected)}
                onNewBtnClick={onNewBtnClick}
                showNewButton={showNewButton}
            />

            <Table
                sx={{
                    '--TableCell-headBackground': 'transparent',
                    '--TableCell-selectedBackground': (theme) => theme.vars.palette.success.softBg,
                    '& tr > *:first-child': { position: 'sticky', left: 0, bgcolor: 'background.surface' },
                }}
            >
                <thead>
                    <tr>
                        <th style={{ width: '40px' }}>
                            <Checkbox
                                indeterminate={selected.length > 0 && selected.length < brokers.length}
                                checked={brokers.length > 0 && selected.length === brokers.length}
                                onChange={handleSelectAll}
                                sx={{ verticalAlign: 'sub' }}
                            />
                        </th>
                        {columns.map((column) => {
                            const active = sortConfig.key === column.id
                            return (
                                <th key={column.id} style={{ width: column.width, cursor: 'pointer' }}>
                                    <Box
                                        component="button"
                                        onClick={() => handleSort(column.id)}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 'lg',
                                            color: active ? 'primary.plainColor' : 'neutral.plainColor',
                                            border: 'none',
                                            background: 'none',
                                            padding: 0,
                                            cursor: 'pointer',
                                            '&:hover': { color: 'primary.plainColor' },
                                        }}
                                    >
                                        {column.label}
                                        {active && (
                                            <KeyboardArrowDown
                                                sx={{
                                                    ml: 1,
                                                    transform: sortConfig.direction === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                                                    transition: 'transform 0.2s',
                                                }}
                                            />
                                        )}
                                        <Box component="span" sx={visuallyHidden}>
                                            {sortConfig.direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    </Box>
                                </th>
                            )
                        })}
                        <th style={{ width: '50px' }}></th>
                        <th style={{ width: '50px' }}></th>
                    </tr>
                </thead>

                <tbody>
                    {brokers.map((broker) => {
                        const isExpanded = expandedRows[broker._id] || false
                        const isLoading = loadingRateConfs[broker._id] || false

                        return (
                            <React.Fragment key={broker._id}>
                                <tr
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: isExpanded ? 'var(--joy-palette-primary-softBg)' : undefined,
                                    }}
                                    onClick={() => toggleRowExpansion(broker)}
                                >
                                    <td onClick={(e) => e.stopPropagation()}>
                                        <Checkbox checked={selected.includes(broker._id)} onChange={(e) => onSelect(broker._id, e.target.checked)} />
                                    </td>
                                    {columns.map((column) => (
                                        <td key={column.id}>
                                            {column.id === 'createdAt'
                                                ? new Date(broker[column.id]).toLocaleDateString()
                                                : broker[column.id]?.toString() || '-'}
                                        </td>
                                    ))}
                                    <td onClick={(e) => e.stopPropagation()}>
                                        <IconButton size="sm" onClick={() => onEdit(broker)}>
                                            <EditIcon />
                                        </IconButton>
                                    </td>
                                    <td>
                                        {isLoading ? (
                                            <CircularProgress size="sm" />
                                        ) : (
                                            <IconButton size="sm" color="primary">
                                                {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDown />}
                                            </IconButton>
                                        )}
                                    </td>
                                </tr>

                                {/* Expanded row with rate confirmation details */}
                                {isExpanded && (
                                    <tr>
                                        <td colSpan={columns.length + 3}>
                                            {isLoading ? (
                                                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                                                    <CircularProgress />
                                                </Box>
                                            ) : error[broker._id] ? (
                                                <Box sx={{ p: 2, color: 'danger.500' }}>
                                                    <Typography level="body-sm">Error: {error[broker._id]}</Typography>
                                                </Box>
                                            ) : (
                                                <BrokerRateConfDetails broker={broker} />
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        )
                    })}
                </tbody>

                <tfoot>
                    <tr>
                        <td colSpan={columns.length + 3}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'flex-end', p: 2 }}>
                                <FormControl orientation="horizontal" size="sm">
                                    <FormLabel>Rows per page:</FormLabel>
                                    <Select value={rowsPerPage} onChange={(_, value) => onRowsPerPageChange(value || 10)}>
                                        <Option value={3}>3</Option>
                                        <Option value={10}>10</Option>
                                        <Option value={25}>25</Option>
                                        <Option value={50}>50</Option>
                                    </Select>
                                </FormControl>

                                <Typography>{`${(page - 1) * rowsPerPage + 1}-${Math.min(page * rowsPerPage, total)} of ${total}`}</Typography>

                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <IconButton
                                        size="sm"
                                        variant="outlined"
                                        disabled={page === 1}
                                        onClick={() => onPageChange(Math.max(1, page - 1))}
                                        aria-label="previous page"
                                    >
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>

                                    <IconButton
                                        size="sm"
                                        variant="outlined"
                                        disabled={page * rowsPerPage >= total}
                                        onClick={() => onPageChange(page + 1)}
                                        aria-label="next page"
                                    >
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </td>
                    </tr>
                </tfoot>
            </Table>
        </Sheet>
    )
}
