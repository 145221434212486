import { Call } from '@numeo/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import { updateCall, removeCall, setSelectedCall, handleCorrectTranscription, handleFlagTranscription, setCallWsStatus } from '../store/callCenterSlice'

export interface CallCenterHook {
    // Active calls
    calls: Map<string, Call>
    // The call that the translation is getting streamed on
    selectedCallId: string | null
    stats: {
        total: number
        byStatus: Record<string, number>
        byType: Record<string, number>
        byPriority: Record<string, number>
    }
    wsStatus: 'disconnected' | 'connecting' | 'connected'

    recentlyUpdated: Set<string>
    updateCall: (callId: string, updates: Partial<Call>) => void
    removeCall: (callId: string) => void
    setSelectedCall: (callId: string | null) => void
    handleCorrectTranscription: (segmentId: string, text: string) => void
    handleFlagTranscription: (segmentId: string, reason: string) => void
    setWsStatus: (status: 'disconnected' | 'connecting' | 'connected') => void
}

export const useCallCenter = (): CallCenterHook => {
    const dispatch = useDispatch()
    const callsRecord = useSelector((state: RootState) => state.callCenter.calls)
    const selectedCallId = useSelector((state: RootState) => state.callCenter.selectedCallId)
    const stats = useSelector((state: RootState) => state.callCenter.stats)
    const recentlyUpdated = useSelector((state: RootState) => state.callCenter.recentlyUpdated)
    const wsStatus = useSelector((state: RootState) => state.callCenter.wsStatus)

    // Convert Record to Map
    const calls = new Map<string, Call>(Object.entries(callsRecord))

    return {
        calls,
        selectedCallId,
        stats,
        wsStatus,
        recentlyUpdated: new Set(recentlyUpdated),
        updateCall: (callId, updates) => dispatch(updateCall({ callId, updates })),
        removeCall: (callId) => dispatch(removeCall(callId)),
        setSelectedCall: (callId) => dispatch(setSelectedCall(callId)),
        handleCorrectTranscription: (segmentId, text) => dispatch(handleCorrectTranscription({ segmentId, text })),
        handleFlagTranscription: (segmentId, reason) => dispatch(handleFlagTranscription({ segmentId, reason })),
        setWsStatus: (status) => dispatch(setCallWsStatus(status)),
    }
}
