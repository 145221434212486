import { FormControl, FormLabel, Stack, Box, useTheme, Switch } from '@mui/joy'
import { FormControlLabel } from '@mui/material'
import { Equipment, Truck } from '@numeo/types'
import ExcludeMultiSelect from 'components/LocationSearch/ExcludeMultiSelect'

interface PermitsRestrictionsProps {
    truckData: Truck
    onUpdate: (field: string, value: string | number | null | boolean | Equipment | string[]) => void
}

const PermitsRestrictions: React.FC<PermitsRestrictionsProps> = ({ truckData, onUpdate }) => {
    const theme = useTheme()

    // Handle excluded locations change
    const handleExcludedLocationsChange = (values: string[]) => {
        onUpdate('excludedStates', values)
    }

    return (
        <Box
            p={3}
            sx={{
                color: theme.palette.text.primary,
            }}
        >
            <Stack direction={{ sm: 'column', md: 'row' }} alignItems={'center'} gap={10} justifyContent={'space-between'}>
                <Stack direction={'column'} gap={4} width={'calc(100% + 10px)'}>
                    <FormControl sx={{ width: 350, maxWidth: 350 }}>
                        <FormLabel>Excluded States and Cities</FormLabel>
                        <ExcludeMultiSelect
                            values={truckData.excludedStates || []}
                            onValuesChange={handleExcludedLocationsChange}
                            placeholder="Select states or cities to exclude"
                        />
                    </FormControl>

                    <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                        <Box sx={{ width: '100%' }}>
                            <FormLabel>Restrictions and Warnings</FormLabel>
                            <Stack spacing={1}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            sx={{ mr: 1 }}
                                            checked={truckData.isRestricted?.alcohol || false}
                                            onChange={(e) => onUpdate('isRestricted.alcohol', e.target.checked)}
                                        />
                                    }
                                    label="Alcohol"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            sx={{ mr: 1 }}
                                            checked={truckData.isRestricted?.baledPaper || false}
                                            onChange={(e) => onUpdate('isRestricted.baledPaper', e.target.checked)}
                                        />
                                    }
                                    label="Baled Paper"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            sx={{ mr: 1 }}
                                            checked={truckData.isRestricted?.emptyBottles || false}
                                            onChange={(e) => onUpdate('isRestricted.emptyBottles', e.target.checked)}
                                        />
                                    }
                                    label="Empty Bottles"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            sx={{ mr: 1 }}
                                            checked={truckData.isRestricted?.aluminiumCan || false}
                                            onChange={(e) => onUpdate('isRestricted.aluminiumCan', e.target.checked)}
                                        />
                                    }
                                    label="Aluminium Can"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            sx={{ mr: 1 }}
                                            checked={truckData.isRestricted?.scrap || false}
                                            onChange={(e) => onUpdate('isRestricted.scrap', e.target.checked)}
                                        />
                                    }
                                    label="Scrap"
                                />
                            </Stack>
                        </Box>

                        <Box sx={{ width: '100%' }}>
                            <FormLabel>Security</FormLabel>
                            <Stack spacing={1}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            sx={{ mr: 1 }}
                                            checked={truckData.security?.tsa || false}
                                            onChange={(e) => onUpdate('security.tsa', e.target.checked)}
                                        />
                                    }
                                    label="TSA"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            sx={{ mr: 1 }}
                                            checked={truckData.security?.twic || false}
                                            onChange={(e) => onUpdate('security.twic', e.target.checked)}
                                        />
                                    }
                                    label="TWIC"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            sx={{ mr: 1 }}
                                            checked={truckData.security?.heavyDutyLock || false}
                                            onChange={(e) => onUpdate('security.heavyDutyLock', e.target.checked)}
                                        />
                                    }
                                    label="Heavy Duty Lock"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            sx={{ mr: 1 }}
                                            checked={truckData.security?.escortDrivingOK || false}
                                            onChange={(e) => onUpdate('security.escortDrivingOK', e.target.checked)}
                                        />
                                    }
                                    label="Escort Driving OK"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            sx={{ mr: 1 }}
                                            checked={truckData.security?.crossBorderLoads || false}
                                            onChange={(e) => onUpdate('security.crossBorderLoads', e.target.checked)}
                                        />
                                    }
                                    label="Cross Border Loads"
                                />
                            </Stack>
                        </Box>
                    </Stack>
                </Stack>

                <Box sx={{ width: '100%' }}>
                    <FormLabel>Permits & Endorsements</FormLabel>
                    <Stack spacing={1}>
                        <FormControlLabel
                            control={
                                <Switch
                                    sx={{ mr: 1 }}
                                    checked={truckData.isPermitted?.hazmat || false}
                                    onChange={(e) => onUpdate('isPermitted.hazmat', e.target.checked)}
                                />
                            }
                            label="Hazmat"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    sx={{ mr: 1 }}
                                    checked={truckData.isPermitted?.tanker || false}
                                    onChange={(e) => onUpdate('isPermitted.tanker', e.target.checked)}
                                />
                            }
                            label="Tanker"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    sx={{ mr: 1 }}
                                    checked={truckData.isPermitted?.doubleTripleTrailers || false}
                                    onChange={(e) => onUpdate('isPermitted.doubleTripleTrailers', e.target.checked)}
                                />
                            }
                            label="Double/Triple Trailers"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    sx={{ mr: 1 }}
                                    checked={truckData.isPermitted?.combinationEndorsements || false}
                                    onChange={(e) => onUpdate('isPermitted.combinationEndorsements', e.target.checked)}
                                />
                            }
                            label="Combination Endorsements"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    sx={{ mr: 1 }}
                                    checked={truckData.isPermitted?.oversizeOverweight || false}
                                    onChange={(e) => onUpdate('isPermitted.oversizeOverweight', e.target.checked)}
                                />
                            }
                            label="Oversize/Overweight"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    sx={{ mr: 1 }}
                                    checked={truckData.isPermitted?.hazardousWasteRadiological || false}
                                    onChange={(e) => onUpdate('isPermitted.hazardousWasteRadiological', e.target.checked)}
                                />
                            }
                            label="Hazardous Waste/Radiological"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    sx={{ mr: 1 }}
                                    checked={truckData.isPermitted?.canadaOperatingAuthority || false}
                                    onChange={(e) => onUpdate('isPermitted.canadaOperatingAuthority', e.target.checked)}
                                />
                            }
                            label="Canada Operating Authority"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    sx={{ mr: 1 }}
                                    checked={truckData.isPermitted?.mexicoOperatingAuthority || false}
                                    onChange={(e) => onUpdate('isPermitted.mexicoOperatingAuthority', e.target.checked)}
                                />
                            }
                            label="Mexico Operating Authority"
                        />
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}

export default PermitsRestrictions
