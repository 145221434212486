import { memo, useMemo, useRef } from 'react'
import JoditEditor from 'jodit-react'
import { Sheet, useColorScheme, useTheme } from '@mui/joy'
import 'jodit/es2021/jodit.min.css'
import { Mode } from '@mui/system/cssVars/useCurrentColorScheme'

interface RichTextEditorProps {
    value: string
    onChange: (value: string) => void
    placeholder?: string
    minHeight?: number | string
    readOnly?: boolean
    showToolbar?: boolean
    disabled?: boolean
}

const useEditorStyles = (mode: Mode | undefined) => {
    return useMemo(
        () => ({
            '& .jodit-container': {
                '--jd-color-background-default': mode === 'dark' ? '#282828' : '#FFFFFF',
                '--jd-color-panel': mode === 'dark' ? '#1E1E1E' : '#F8F8F8',
                '--jd-color-border': mode === 'dark' ? '#383838' : '#E5E5E5',
                '--jd-color-icon': mode === 'dark' ? '#B0B0B0' : '#333333',
                border: 'none',
                borderRadius: '4px',
                overflow: 'hidden',
            },
            '& .jodit-toolbar': {
                backgroundColor: 'var(--jd-color-panel)',
                border: 'none',
                borderBottom: '1px solid var(--jd-color-border)',
                padding: '4px 8px',
            },
            '& .jodit-toolbar__box': {
                background: 'transparent',
            },
            '& .jodit-toolbar-button': {
                margin: '0 2px',
                padding: '4px',
                borderRadius: '4px',
                '&:hover': {
                    backgroundColor: mode === 'dark' ? '#383838' : '#EAEAEA',
                },
                '&:active': {
                    backgroundColor: mode === 'dark' ? '#404040' : '#E0E0E0',
                },
            },
            '& .jodit-toolbar-button__icon': {
                fill: 'var(--jd-color-icon)',
                width: '18px',
                height: '18px',
            },
            '& .jodit-wysiwyg': {
                backgroundColor: 'var(--jd-color-background-default)',
                color: mode === 'dark' ? '#E0E0E0' : '#333333',
                padding: '16px',
                minHeight: '200px',
                fontSize: '14px',
                lineHeight: '1.5',
            },
            '& .jodit-workplace': {
                border: 'none',
            },
            '& table': {
                borderCollapse: 'collapse',
                width: '100%',
                margin: '8px 0',
            },
            '& td, & th': {
                border: '1px solid var(--jd-color-border)',
                padding: '8px',
                backgroundColor: mode === 'dark' ? '#323232' : '#FFFFFF',
            },
            '& th': {
                backgroundColor: mode === 'dark' ? '#383838' : '#F5F5F5',
                fontWeight: '500',
            },
            '& .jodit-status-bar': {
                backgroundColor: 'var(--jd-color-panel)',
                border: 'none',
                borderTop: '1px solid var(--jd-color-border)',
                color: mode === 'dark' ? '#B0B0B0' : '#666666',
            },
            '& a': {
                color: mode === 'dark' ? '#4B9BFF' : '#0366D6',
            },
            '& .jodit-placeholder': {
                color: mode === 'dark' ? '#666666' : '#999999',
            },
            // Focus state
            '&:focus-within': {
                '& .jodit-container': {
                    boxShadow: mode === 'dark' ? '0 0 0 2px rgba(75, 155, 255, 0.3)' : '0 0 0 2px rgba(3, 102, 214, 0.3)',
                },
            },
            // Selection styles
            '& ::selection': {
                backgroundColor: mode === 'dark' ? '#264F78' : '#B3D7FF',
                color: mode === 'dark' ? '#FFFFFF' : '#000000',
            },
        }),
        [mode]
    )
}

const TextEditor = ({
    value,
    onChange,
    placeholder = 'Start typing...',
    minHeight = 500,
    readOnly = false,
    showToolbar = true,
    disabled = false,
}: RichTextEditorProps) => {
    const editor = useRef(null)
    const { mode } = useColorScheme()
    const theme = useTheme()
    const editorStyles = useEditorStyles(mode)

    const config = useMemo(
        () => ({
            readonly: disabled || readOnly,
            height: minHeight,
            placeholder: placeholder,
            style: {
                ...editorStyles,
                backgroundColor: mode === 'dark' ? '#0b0d0e' : '#FFFFFF',
                '& .jodit-container': {
                    '--jd-color-background-default': mode === 'dark' ? '#282828' : '#FFFFFF',
                    '--jd-color-panel': mode === 'dark' ? '#1E1E1E' : '#F8F8F8',
                    '--jd-color-border': mode === 'dark' ? '#383838' : '#E5E5E5',
                    '--jd-color-icon': mode === 'dark' ? '#B0B0B0' : '#333333',
                    border: 'none',
                    borderRadius: '4px',
                    overflow: 'hidden',
                },
                '&.jodit-toolbar': {
                    backgroundColor: 'var(--jd-color-panel)',
                    border: 'none',
                    borderBottom: '1px solid var(--jd-color-border)',
                    padding: '4px 8px',
                },
                '& .jodit-toolbar__box': {
                    background: 'transparent',
                },
                '& .jodit-toolbar-button': {
                    margin: '0 2px',
                    padding: '4px',
                    borderRadius: '4px',
                    '&:hover': {
                        backgroundColor: mode === 'dark' ? '#383838' : '#EAEAEA',
                    },
                    '&:active': {
                        backgroundColor: mode === 'dark' ? '#404040' : '#E0E0E0',
                    },
                },
                '& .jodit-toolbar-button__icon': {
                    fill: 'var(--jd-color-icon)',
                    width: '18px',
                    height: '18px',
                },
                '& .jodit-wysiwyg': {
                    backgroundColor: 'var(--jd-color-background-default)',
                    color: mode === 'dark' ? '#E0E0E0' : '#333333',
                    padding: '16px',
                    minHeight: '200px',
                    fontSize: '14px',
                    lineHeight: '1.5',
                },
                '& .jodit-workplace': {
                    border: 'none',
                },
                '& table': {
                    borderCollapse: 'collapse',
                    width: '100%',
                    margin: '8px 0',
                },
                '& td, & th': {
                    border: '1px solid var(--jd-color-border)',
                    padding: '8px',
                    backgroundColor: mode === 'dark' ? '#323232' : '#FFFFFF',
                },
                '& th': {
                    backgroundColor: mode === 'dark' ? '#383838' : '#F5F5F5',
                    fontWeight: '500',
                },
                '& .jodit-status-bar': {
                    backgroundColor: 'var(--jd-color-panel)',
                    border: 'none',
                    borderTop: '1px solid var(--jd-color-border)',
                    color: mode === 'dark' ? '#B0B0B0' : '#666666',
                },
                '& a': {
                    color: mode === 'dark' ? '#4B9BFF' : '#0366D6',
                },
                '& .jodit-placeholder': {
                    color: mode === 'dark' ? '#666666' : '#999999',
                },
                // Focus state
                '&:focus-within': {
                    '& .jodit-container': {
                        boxShadow: mode === 'dark' ? '0 0 0 2px rgba(75, 155, 255, 0.3)' : '0 0 0 2px rgba(3, 102, 214, 0.3)',
                    },
                },
                // Selection styles
                '& ::selection': {
                    backgroundColor: mode === 'dark' ? '#264F78' : '#B3D7FF',
                    color: mode === 'dark' ? '#FFFFFF' : '#000000',
                },
            },
            showToolbar,
            showPlaceholder: true,
            toolbarAdaptive: false,
            statusbar: false,
            buttons: [
                'undo',
                'redo',
                '|',
                'font',
                '|',
                'paragraph',
                '|',
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'brush',
                '|',
                'align',
                'ul',
                'ol',
                '|',
                'outdent',
                'indent',
                'table',
                'link',
                '|',
                'hr',
                '|',
                {
                    name: 'Clear Format',
                    tooltip: 'Clear Format',
                    exec: (editor) => {
                        // Store current selection
                        const hadSelection = !editor.selection.isCollapsed()

                        if (!hadSelection) {
                            // If no text is selected, select all content
                            editor.execCommand('selectall')
                        }

                        // Remove formatting
                        editor.execCommand('removeFormat')

                        if (!hadSelection) {
                            // Restore cursor to original position
                            editor.selection.restore()
                        }
                    },
                },
            ],
            theme: mode,
            uploader: {
                insertImageAsBase64URI: true,
            },
            toolbar: true,
            toolbarInline: false,
            toolbarBottom: false,
            showCharsCounter: false,
            showWordsCounter: false,
            showXPathInStatusbar: false,
            askBeforePasteHTML: false,
            askBeforePasteFromWord: false,
        }),
        [mode, minHeight, readOnly, showToolbar, disabled, placeholder, editorStyles]
    )

    return (
        <Sheet
            variant="outlined"
            sx={{
                borderRadius: '8px',
                overflow: 'hidden',
                border: '1px solid',
                Shadow: `0 0 0 1px ${theme.vars.palette.primary[500]}`,
                ...editorStyles,
            }}
        >
            <JoditEditor ref={editor} value={value} config={config} onBlur={onChange} />
        </Sheet>
    )
}

export const RichTextEditor = memo(TextEditor)
