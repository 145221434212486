export interface INumeoRestError {
    message: string // Human-readable error message
    code: number // HTTP status code
    serviceName: string // Always 'main-service'
    timestamp: string // ISO timestamp
}

export class NumeoRestError extends Error implements INumeoRestError {
    public serviceName: string = 'main-service'
    public timestamp: string

    constructor(
        public message: string,
        public code: number = 500
    ) {
        super(message)
        this.name = 'NumeoRestError'
        this.timestamp = new Date().toISOString()
    }

    toJSON() {
        return {
            name: this.name,
            message: this.message,
            code: this.code,
            serviceName: this.serviceName,
            timestamp: this.timestamp,
        }
    }
}
