import {
    Box,
    Button,
    Card,
    FormControl,
    FormLabel,
    Stack,
    Typography,
    IconButton,
    Alert,
    Chip,
    Modal,
    ModalDialog,
    ModalClose,
    DialogTitle,
    DialogContent,
    DialogActions,
    Input,
} from '@mui/joy'
import { useAuth } from 'hooks/auth'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Category } from 'hooks/useNotification'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import { CheckCircle, DeleteRounded } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'

export const DATAccount = () => {
    const { application, setApplication } = useAuth()
    const dispatch = useDispatch()

    const emailBlocked = application?.integrations.dat?.blocked || false

    const isAccountConnected = !!application?.integrations.dat?.username

    const [orgUsername, setOrgUsername] = useState<string>(application?.integrations.dat?.orgUsername || '')
    const [orgPassword, setOrgPassword] = useState<string>('')
    const [username, setUsername] = useState<string>(application?.integrations.dat?.username || '')

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)

    useEffect(() => {
        setUsername(application?.integrations.dat?.username || '')
    }, [application])

    const handleClear = () => {
        setOrgUsername('')
        setOrgPassword('')
        setUsername('')
    }

    const handleSubmit = async () => {
        try {
            setIsLoading(true)

            const response = await axios.post('/integrations/dat', {
                orgUsername: orgUsername,
                orgPassword: orgPassword,
                username: username,
            })

            // Don't reset username as we want to show the connected account UI
            // Just reset password
            setOrgPassword('')

            if (application) {
                setApplication({
                    ...application,
                    integrations: {
                        ...application.integrations,
                        dat: response.data,
                    },
                })
            }

            dispatch(
                addNotification({
                    id: '',
                    category: Category.Email,
                    message: 'DAT account created successfully',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'info',
                })
            )
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            console.error('Failed to create DAT account')
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: err?.response?.data?.message || 'Failed to create DAT account',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'error',
                })
            )
        } finally {
            setIsLoading(false)
            handleClear()
        }
    }

    const handleRemoveAccount = async () => {
        try {
            setIsLoading(true)
            await axios.delete('/integrations/dat')
            setOrgUsername('')
            setOrgPassword('')
            setUsername('')

            if (application) {
                //eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { dat, ...remainingIntegrations } = application.integrations

                setApplication({
                    ...application,
                    integrations: remainingIntegrations,
                })
            }

            dispatch(
                addNotification({
                    id: '',
                    category: Category.Email,
                    message: 'DAT account removed successfully',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'info',
                })
            )
        } catch (err) {
            console.error('Failed to remove DAT account')
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: 'Failed to remove DAT account',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'error',
                })
            )
        } finally {
            setIsLoading(false)
            setDeleteConfirmOpen(false)
        }
    }

    return (
        <Card variant="outlined" sx={{ p: 2, gap: 1, width: '100%' }}>
            <Stack direction="row" spacing={1} alignItems="center">
                <img src="/dat-logo.jpg" alt="DAT Logo" style={{ width: 24, height: 24 }} />
                <Box sx={{ flex: 1 }}>
                    <Typography level="title-md">DAT Account</Typography>
                </Box>
                {isAccountConnected && (
                    <IconButton
                        variant="soft"
                        color="danger"
                        onClick={() => setDeleteConfirmOpen(true)}
                        loading={isLoading}
                        disabled={isLoading}
                        title="Remove DAT Account"
                    >
                        <DeleteRounded />
                    </IconButton>
                )}
            </Stack>

            <Stack spacing={1}>
                {isAccountConnected ? (
                    <>
                        {emailBlocked && (
                            <Alert variant="soft" color="danger">
                                Your DAT account is blocked or disconnected. Please remove it and connect again.
                            </Alert>
                        )}
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography level="body-md" fontWeight="bold">
                                Connected Account:
                            </Typography>
                            <Chip variant="soft" color="primary" startDecorator={<EmailRoundedIcon />}>
                                {username}
                            </Chip>
                            {!emailBlocked && <CheckCircle color="success" />}
                        </Stack>
                    </>
                ) : (
                    <>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <FormControl sx={{ mb: 2, width: '100%' }}>
                                <FormLabel>Service Account Username</FormLabel>
                                <Input placeholder="Enter your service account username" value={orgUsername} onChange={(e) => setOrgUsername(e.target.value)} />
                            </FormControl>

                            <FormControl sx={{ mb: 2, width: '100%' }}>
                                <FormLabel>Service Account Password</FormLabel>
                                <Input
                                    type="password"
                                    placeholder="Enter your service account password"
                                    value={orgPassword}
                                    onChange={(e) => setOrgPassword(e.target.value)}
                                />
                            </FormControl>
                        </Stack>

                        <FormControl sx={{ mb: 2, width: '100%' }}>
                            <FormLabel>Account Username</FormLabel>
                            <Input placeholder="Enter your account username" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </FormControl>

                        <Button variant="solid" color="primary" loading={isLoading} disabled={!orgUsername || !orgPassword || !username} onClick={handleSubmit}>
                            Connect Account
                        </Button>
                    </>
                )}
            </Stack>

            {/* Delete Confirmation Dialog */}
            <Modal open={deleteConfirmOpen} onClose={() => !isLoading && setDeleteConfirmOpen(false)}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <DialogTitle>
                        <Typography level="title-lg">Confirm Removal</Typography>
                        <ModalClose disabled={isLoading} />
                    </DialogTitle>
                    <DialogContent>
                        <Typography level="body-md">Are you sure you want to remove your DAT account? This action cannot be undone.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="plain" color="neutral" onClick={() => setDeleteConfirmOpen(false)} disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button variant="solid" color="danger" onClick={handleRemoveAccount} loading={isLoading}>
                            Remove Account
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </Card>
    )
}
