import { LoadFindResultState } from '@numeo/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { handleLoadsUpdate } from 'utils/load'

export interface SpotFinderState {
    lastSearchResults: Record<string, LoadFindResultState>
}

const initialState: SpotFinderState = {
    lastSearchResults: {},
}

const spotFinderSlice = createSlice({
    name: 'spotFinder',
    initialState,
    reducers: {
        setAllLastSearchResults(state, action: PayloadAction<Record<string, LoadFindResultState>>) {
            Object.entries(action.payload).forEach(([truckId, newResult]) => {
                const lastSearchResult = state.lastSearchResults[truckId]
                let currentLoads = {}

                if (!lastSearchResult) {
                    state.lastSearchResults[truckId] = newResult
                    currentLoads = newResult.loads
                    return
                }

                const { result } = handleLoadsUpdate(lastSearchResult, newResult, currentLoads)
                state.lastSearchResults[truckId] = result
            })
        },
        setLastSearchResult(
            state,
            action: PayloadAction<{
                truckId: string
                newResult: LoadFindResultState
            }>
        ) {
            const { truckId, newResult } = action.payload
            state.lastSearchResults[truckId] = newResult
        },
        clearTruckLoads(state, action: PayloadAction<string>) {
            const truckId = action.payload
            if (state.lastSearchResults[truckId]) {
                // Create a copy of the existing search result but with empty loads
                const clearedResult = {
                    ...state.lastSearchResults[truckId],
                    loads: {},
                }
                state.lastSearchResults[truckId] = clearedResult
            }
        },
    },
})

export const { setAllLastSearchResults, setLastSearchResult, clearTruckLoads } = spotFinderSlice.actions

export default spotFinderSlice.reducer
