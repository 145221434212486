import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import { WebSocketStatus, WsMessage } from '@numeo/types'
import { sendMessage, connecting, reconnect } from '../store/webSocketSlice'
import { useEffect } from 'react'

export const useWebSocketRedux = () => {
    const dispatch = useDispatch()
    const status = useSelector((state: RootState) => state.webSocket.status)
    const error = useSelector((state: RootState) => state.webSocket.error)

    // Connect to WebSocket when the hook is first used
    useEffect(() => {
        if (status === WebSocketStatus.DISCONNECTED) {
            dispatch(connecting())
        }
    }, [dispatch, status])

    return {
        status,
        error,
        isConnected: status === WebSocketStatus.CONNECTED,
        sendMessageToService: (message: WsMessage) => dispatch(sendMessage(message)),
        reconnect: () => dispatch(reconnect()),
    }
}
