import { Box, Button, FormControl, FormHelperText, FormLabel, Grid, Input, Textarea } from '@mui/joy'
import { BrokerType, IBroker } from '@numeo/types'
import { useFormik } from 'formik'
import { FC, useEffect } from 'react'
import * as yup from 'yup'

export type BrokerFormData = Pick<IBroker, 'fullName' | 'email' | 'phone' | 'mcNumber' | 'carrierMcNumber' | 'companyName' | 'website' | 'comment' | 'type'>

// Update default values to match IBroker
const defaultValues: BrokerFormData = {
    fullName: '',
    email: '',
    phone: '',
    mcNumber: '',
    carrierMcNumber: '',
    companyName: '',
    website: '',
    comment: '',
    type: BrokerType.FAVORITE,
}

// Update validation schema to match
const validationSchema = yup.object({
    companyName: yup.string().required('Company name is required'),
    email: yup.string().email('Invalid email format'),
    phone: yup.string(),
    mcNumber: yup
        .string()
        .matches(/^[0-9]{4,}$/, 'MC Number must be more than 3 digits')
        .required('MC Number is required'),
    carrierMcNumber: yup.string(),
    fullName: yup.string(),
    website: yup.string(),
    comment: yup.string(),
    type: yup.string(),
})

interface Props {
    onSubmit: (values: BrokerFormData) => void
    onClose: () => void
    initialValues?: BrokerFormData
    mode?: 'add' | 'edit'
    activeTab: BrokerType
}

export const BrokerForm: FC<Props> = ({ onSubmit, onClose, initialValues, mode = 'add', activeTab }) => {
    const formik = useFormik({
        initialValues: initialValues || { ...defaultValues, type: activeTab },
        validationSchema,
        onSubmit: (values) => {
            onSubmit({ ...values, type: activeTab })
        },
    })

    useEffect(() => {
        if (initialValues) {
            formik.setValues({ ...initialValues, type: activeTab })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues, activeTab])

    const isEditMode = mode === 'edit'

    return (
        <Box component="form" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                {/* Company name | required */}
                <Grid xs={12} sm={6}>
                    <FormControl error={formik.touched.companyName && Boolean(formik.errors.companyName)}>
                        <FormLabel>Company Name</FormLabel>
                        <Input
                            name="companyName"
                            value={formik.values.companyName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Numeo AI"
                        />
                        {formik.touched.companyName && formik.errors.companyName && <FormHelperText>{formik.errors.companyName}</FormHelperText>}
                    </FormControl>
                </Grid>

                {/* Mc number of the broker | required */}
                <Grid xs={12} sm={6}>
                    <FormControl error={formik.touched.mcNumber && Boolean(formik.errors.mcNumber)}>
                        <FormLabel>MC Number</FormLabel>
                        <Input name="mcNumber" value={formik.values.mcNumber} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="123456" />
                        {formik.touched.mcNumber && formik.errors.mcNumber && <FormHelperText>{formik.errors.mcNumber}</FormHelperText>}
                    </FormControl>
                </Grid>

                {/* Full name of the broker */}
                <Grid xs={12} sm={6}>
                    <FormControl>
                        <FormLabel>Broker Contact</FormLabel>
                        <Input
                            name="fullName"
                            value={formik.values.fullName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="John Doe"
                        />
                        {formik.touched.fullName && formik.errors.fullName && <FormHelperText>{formik.errors.fullName}</FormHelperText>}
                    </FormControl>
                </Grid>

                {/* Email of the broker */}
                <Grid xs={12} sm={6}>
                    <FormControl error={formik.touched.email && Boolean(formik.errors.email)}>
                        <FormLabel>Email</FormLabel>
                        <Input
                            name="email"
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="hello@numeo.ai"
                        />
                        {formik.touched.email && formik.errors.email && <FormHelperText>{formik.errors.email}</FormHelperText>}
                    </FormControl>
                </Grid>

                {/* Phone number of the broker */}
                <Grid xs={12} sm={6}>
                    <FormControl>
                        <FormLabel>Phone</FormLabel>
                        <Input name="phone" value={formik.values.phone} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="+15551234567" />
                    </FormControl>
                </Grid>

                {/* Website uri of the broker */}
                <Grid xs={12} sm={6}>
                    <FormControl>
                        <FormLabel>Website</FormLabel>
                        <Input
                            name="website"
                            value={formik.values.website}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="https://numeo.ai"
                        />
                    </FormControl>
                </Grid>
                {/* Carrier Mc number of the broker */}
                <Grid xs={12} sm={12}>
                    <FormControl error={formik.touched.carrierMcNumber && Boolean(formik.errors.carrierMcNumber)}>
                        <FormLabel>Carrier MC Number</FormLabel>
                        <Input
                            name="carrierMcNumber"
                            value={formik.values.carrierMcNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Carrier MC Number"
                        />
                        {formik.touched.carrierMcNumber && formik.errors.carrierMcNumber && <FormHelperText>{formik.errors.carrierMcNumber}</FormHelperText>}
                    </FormControl>
                </Grid>

                {/* Comment about the broker */}
                <Grid xs={12} sm={12}>
                    <FormControl>
                        <FormLabel>Comment</FormLabel>
                        <Textarea name="comment" minRows={3} value={formik.values.comment} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                    </FormControl>
                </Grid>
                <Grid xs={12} sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', mt: 2 }}>
                    <Button variant="plain" color="neutral" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                        {isEditMode ? 'Save Changes' : 'Add Broker'}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default BrokerForm
