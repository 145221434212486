import { Box, Typography, Button, Chip, Stack, Tooltip } from '@mui/joy'
import { RichTextEditor } from 'components/common'
import { useEffect, useState } from 'react'
import { Category } from 'hooks/useNotification'
import { saveFooterSettings } from '../../../api/EmailSettings'
import { useAuth } from 'hooks/auth'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'

export const EmailFooterSettings = () => {
    const dispatch = useDispatch()
    const { application, setApplication } = useAuth()
    const [emailClosingData, setEmailClosingData] = useState<string>('')
    const [initialEmailClosingData, setInitialEmailClosingData] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // Default footer template
    const defaultFooterTemplate = `<p>Thanks, {{company_name}} </p><p>MC# {{mc_number}}<br/> {{phone_number}}</p>`

    useEffect(() => {
        const fetchData = async () => {
            try {
                const footerResponse = application?.temp?.emailFooter
                if (footerResponse) {
                    const { closing } = footerResponse
                    // If there's existing content, use it; otherwise, use the default template
                    const footerContent = closing || defaultFooterTemplate
                    setEmailClosingData(footerContent)
                    setInitialEmailClosingData(footerContent)
                } else {
                    // No footer data at all, use the default template
                    setEmailClosingData(defaultFooterTemplate)
                    setInitialEmailClosingData(defaultFooterTemplate)
                }
            } catch (error) {
                console.error('Error fetching footer settings:', error)
                // Fallback to default template on error
                setEmailClosingData(defaultFooterTemplate)
                setInitialEmailClosingData(defaultFooterTemplate)
            }
        }
        fetchData()
        // eslint-disable-next-line
    }, [])

    const handleEmailClosingChange = (value: string) => {
        setEmailClosingData(value)
    }

    const handleCancel = () => {
        setEmailClosingData(initialEmailClosingData)
    }

    const upsertEmailFooterData = async () => {
        try {
            setIsLoading(true)
            const response = await saveFooterSettings({ closing: emailClosingData })

            if (response?.status === 200) {
                setInitialEmailClosingData(emailClosingData)
                setApplication(response?.data)
                dispatch(
                    addNotification({
                        id: '',
                        category: Category.Email,
                        message: 'Email footer updated successfully',
                        timestamp: Date.now(),
                        topic: 'temp',
                        type: 'info',
                    })
                )
            } else {
                dispatch(
                    addNotification({
                        id: '',
                        category: Category.Error,
                        message: 'Failed to update email footer settings',
                        timestamp: Date.now(),
                        topic: 'temp',
                        type: 'error',
                    })
                )
            }
        } catch (error) {
            console.error('Error saving footer settings:', error)
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: 'Error updating email footer settings',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'error',
                })
            )
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                p: 2,
                bgcolor: 'background.surface',
                borderRadius: 'sm',
                boxShadow: 'sm',
            }}
        >
            <Box sx={{ mb: 1 }}>
                <Typography level="h4" component="h2">
                    Email Footer Settings
                </Typography>
                <Typography level="body-sm" sx={{ mb: 1 }}>
                    Customize the footer that will appear at the bottom of all outgoing emails. You can use the editor below to format your text.
                </Typography>
                <Typography level="body-sm" sx={{ mb: 1 }}>
                    You can use the following template variables to include dynamic company information in your footer:
                </Typography>
                <Stack direction="row" spacing={1} sx={{ mb: 2, flexWrap: 'wrap', gap: 1 }}>
                    <Tooltip title="Will be replaced with your company name">
                        <Chip
                            color="primary"
                            variant="soft"
                            size="sm"
                            onClick={() => {
                                const newValue = emailClosingData + '{{company_name}}'
                                setEmailClosingData(newValue)
                            }}
                        >
                            {'{{company_name}}'}
                        </Chip>
                    </Tooltip>
                    <Tooltip title="Will be replaced with your MC number">
                        <Chip
                            color="primary"
                            variant="soft"
                            size="sm"
                            onClick={() => {
                                const newValue = emailClosingData + '{{mc_number}}'
                                setEmailClosingData(newValue)
                            }}
                        >
                            {'{{mc_number}}'}
                        </Chip>
                    </Tooltip>
                    <Tooltip title="Will be replaced with your company phone number">
                        <Chip
                            color="primary"
                            variant="soft"
                            size="sm"
                            onClick={() => {
                                const newValue = emailClosingData + '{{phone_number}}'
                                setEmailClosingData(newValue)
                            }}
                        >
                            {'{{phone_number}}'}
                        </Chip>
                    </Tooltip>
                </Stack>
                <Typography level="body-xs" color="neutral">
                    Click on any template variable to add it to your footer. Example: "Thanks, {'{{company_name}}'} (MC: {'{{mc_number}}'})"
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        bgcolor: 'background.level1',
                    }}
                >
                    <RichTextEditor
                        value={emailClosingData}
                        onChange={handleEmailClosingChange}
                        placeholder="Enter your email footer content..."
                        minHeight="auto"
                        showToolbar={true}
                    />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 1,
                    }}
                >
                    <Button variant="outlined" color="neutral" size="sm" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button size="sm" onClick={upsertEmailFooterData} loading={isLoading}>
                        Save Changes
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
