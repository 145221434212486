import axios from 'axios'
import { Category } from 'hooks/useNotification'
import { addNotification } from 'store/notificationSlice'

interface SaveCallInfoBody {
    loadSearchId: string
    loadId: string
    callId: string
    startedAt: number
    partyId: string
    sessionId: string
    from: string
    to: string
}

class RingCentralAPIService {
    async getAuthURL() {
        try {
            const response = await axios.get('/integrations/ringcentral')
            return response.data.data
        } catch (ex) {
            console.error('Failed to get RingCentral authentication URL: ', ex)
            addNotification({
                id: '',
                message: 'Failed to authenticate with RingCentral, please try again later',
                type: 'error',
                category: Category.LoadFinderTruck,
                timestamp: Date.now(),
                topic: 'temp',
            })
        }
    }

    async authenticateWithCode(code: string) {
        try {
            const response = await axios.post('/integrations/ringcentral', { code })
            return response.data.data
        } catch (ex) {
            console.error('Failed to get RingCentral access token: ', ex)
            addNotification({
                id: '',
                message: 'Failed to authenticate with RingCentral, please try again later',
                type: 'error',
                category: Category.LoadFinderTruck,
                timestamp: Date.now(),
                topic: 'temp',
            })
        }
    }

    async createSIPCredentials() {
        try {
            const response = await axios.post('/voip/ringcentral/sip-credentials')
            return response.data.data
        } catch (ex) {
            console.error('Failed to create SIP credentials: ', ex)
            addNotification({
                id: '',
                message: 'Failed to create SIP credentials, please try again later',
                type: 'error',
                category: Category.LoadFinderTruck,
                timestamp: Date.now(),
                topic: 'temp',
            })
        }
    }

    async saveCallInfo(body: SaveCallInfoBody) {
        try {
            await axios.post('/voip/ringcentral/call-info', body)
        } catch (ex) {
            console.error('Failed to save call info: ', ex)
            addNotification({
                id: '',
                message: 'Failed to save call info',
                type: 'error',
                category: Category.LoadFinderTruck,
                timestamp: Date.now(),
                topic: 'temp',
            })
        }
    }

    async updateCallStatus(callId: string) {
        try {
            await axios.post('/voip/ringcentral/call-status', { callId })
        } catch (ex) {
            console.error('Failed to update call status: ', ex)
            addNotification({
                id: '',
                message: 'Failed to update call status',
                type: 'error',
                category: Category.LoadFinderTruck,
                timestamp: Date.now(),
                topic: 'temp',
            })
        }
    }
}

export const ringCentralApiService = new RingCentralAPIService()
