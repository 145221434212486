import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import DeleteForever from '@mui/icons-material/DeleteForever'
import Edit from '@mui/icons-material/Edit'
import MoreVert from '@mui/icons-material/MoreVert'
import { CircularProgress } from '@mui/joy'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Dropdown from '@mui/joy/Dropdown'
import IconButton from '@mui/joy/IconButton'
import ListDivider from '@mui/joy/ListDivider'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import Menu from '@mui/joy/Menu'
import MenuButton from '@mui/joy/MenuButton'
import MenuItem from '@mui/joy/MenuItem'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import Typography from '@mui/joy/Typography'
import { Truck } from '@numeo/types'
import * as React from 'react'
import { useCallback, useMemo, useRef, useState } from 'react'
import spotApi from '../api/api'
import { useAuth } from 'hooks/auth'

interface IMoreActions {
    truckId: string
    onEdit: (truck: Truck) => void
    truckList: Truck[] | null
    setActiveActionsTruckId?: (truckId: string | null) => void
    onDeleteSuccess?: (truckId: string) => void
    infoModalTruckId: string | null
    setInfoModalTruckId: (id: string | null) => void
    activeActionsTruckId: string | null
    onTruckActivated: (id: string | null) => void
}

export default function MoreActions({ truckId, onEdit, truckList, setActiveActionsTruckId = () => {}, onDeleteSuccess, onTruckActivated }: IMoreActions) {
    const { setApplication } = useAuth()
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [editLoading, setEditLoading] = useState(false)
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [open, setOpen] = useState(false)
    const componentRef = useRef<HTMLDivElement>(null)

    // Memoize truck finding operation
    const currentTruck = useMemo(() => truckList?.find((t) => t._id === truckId), [truckList, truckId])

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setOpen(true)
    }, [])

    const handleClose = useCallback((event?: React.MouseEvent) => {
        if (event) {
            event.stopPropagation()
        }
        setOpen(false)
        setError(null)
    }, [])

    const handleDelete = useCallback(
        async (event?: React.MouseEvent) => {
            if (event) {
                event.stopPropagation()
            }
            setDeleteLoading(true)
            setError(null)
            try {
                const response = await spotApi.delete(`/trucks/${truckId}`)
                setApplication(response.application)
                onDeleteSuccess?.(truckId)
                setShowDeleteConfirm(false)
                handleClose()
                setActiveActionsTruckId(null)
                // Find the next latest truck to activate
                if (truckList && truckList.length > 1) {
                    const currentIndex = truckList.findIndex((t) => t._id === truckId)
                    // Get the next truck in the list (which would be the next latest since the list is sorted by creation date)
                    const nextTruck = currentIndex > 0 ? truckList[currentIndex - 1] : truckList[currentIndex + 1]
                    if (nextTruck) {
                        onTruckActivated(nextTruck._id)
                    } else {
                        onTruckActivated(null)
                    }
                } else {
                    onTruckActivated(null)
                }
            } catch (error) {
                setError('Failed to delete. Please try again.')
            } finally {
                setDeleteLoading(false)
            }
        },
        // eslint-disable-next-line
        [truckId, handleClose, setActiveActionsTruckId, onDeleteSuccess, truckList, onTruckActivated]
    )

    const handleEdit = useCallback(
        async (event: React.MouseEvent) => {
            event.stopPropagation()
            if (!currentTruck) return
            setEditLoading(true)
            try {
                onEdit(currentTruck)
                // Set the truck ID for editing mode
                setActiveActionsTruckId(truckId)
                handleClose()
            } catch (error) {
                setError('Failed to edit. Please try again.')
            } finally {
                setEditLoading(false)
            }
        },
        [currentTruck, onEdit, setActiveActionsTruckId, truckId, handleClose]
    )

    return (
        <Box
            ref={componentRef}
            onClick={(e) => e.stopPropagation()}
            sx={{
                position: 'relative',
                display: 'inline-block',
            }}
        >
            <Dropdown open={open}>
                <MenuButton
                    slots={{ root: IconButton }}
                    slotProps={{
                        root: {
                            variant: 'plain',
                            color: 'neutral',
                            onClick: handleClick,
                            'aria-label': 'More actions',
                        },
                    }}
                >
                    <MoreVert />
                </MenuButton>
                <ClickAwayListener
                    onClickAway={() => {
                        handleClose()
                    }}
                >
                    <Menu
                        placement="bottom-end"
                        sx={{
                            maxHeight: '80vh',
                            overflowY: 'auto',
                            typography: 'body-sm',
                        }}
                    >
                        {error && (
                            <MenuItem disabled sx={{ color: 'danger.500' }}>
                                <Typography level="body-sm" color="danger">
                                    {error}
                                </Typography>
                            </MenuItem>
                        )}
                        <MenuItem onClick={handleEdit} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            Edit
                            <ListItemDecorator>{editLoading ? <CircularProgress thickness={2} size="sm" /> : <Edit />}</ListItemDecorator>
                        </MenuItem>
                        <ListDivider />
                        <MenuItem
                            variant="soft"
                            color="danger"
                            onClick={(e) => {
                                e.stopPropagation()
                                setShowDeleteConfirm(true)
                                handleClose()
                            }}
                            sx={{ display: 'flex', justifyContent: 'space-between' }}
                        >
                            Delete
                            <ListItemDecorator sx={{ color: 'inherit' }}>
                                {deleteLoading ? <CircularProgress thickness={2} size="sm" /> : <DeleteForever />}
                            </ListItemDecorator>
                        </MenuItem>
                    </Menu>
                </ClickAwayListener>
            </Dropdown>

            <Modal open={showDeleteConfirm} onClose={() => !deleteLoading && setShowDeleteConfirm(false)}>
                <ModalDialog variant="outlined" role="alertdialog" onClick={(e) => e.stopPropagation()}>
                    <Typography level="h2" fontSize="lg" mb={2}>
                        Confirm Delete
                    </Typography>
                    <Typography level="body-md" mb={3}>
                        Are you sure you want to delete this item? This action cannot be undone.
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                        <Button variant="plain" color="neutral" onClick={() => setShowDeleteConfirm(false)} disabled={deleteLoading}>
                            Cancel
                        </Button>
                        <Button variant="solid" color="danger" onClick={handleDelete} loading={deleteLoading}>
                            Delete
                        </Button>
                    </Box>
                </ModalDialog>
            </Modal>
        </Box>
    )
}
