import { Navigate, Route, Routes } from 'react-router-dom'
import { DispatcherDashboardPage, DispatcherEmailsPage } from './pages'
import CallCenterPage from './pages/call-center'
import MyBrokersPage from './pages/my-brokers'
import TruckManagementUI from './pages/spot-finder/components/TruckManagementUI'

export default function DispatcherRoutes() {
    return (
        <Routes>
            <Route index element={<DispatcherDashboardPage />} />
            <Route path="emails" element={<DispatcherEmailsPage />} />
            <Route path="spot-finder" element={<TruckManagementUI />} />
            <Route path="call-center" element={<CallCenterPage />} />
            <Route path="my-brokers" element={<MyBrokersPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}
