// components/TwilloCaller.tsx
import { Box, Stack } from '@mui/joy'
import { useEffect, useRef, useState } from 'react'

export default function HomePage() {
    // eslint-disable-next-line
    const [isFullscreen, setIsFullscreen] = useState(false)
    const iframeRef = useRef<HTMLIFrameElement>(null)

    // Listen for fullscreen change events
    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement)
        }

        document.addEventListener('fullscreenchange', handleFullscreenChange)
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange)
        document.addEventListener('msfullscreenchange', handleFullscreenChange)

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange)
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange)
            document.removeEventListener('msfullscreenchange', handleFullscreenChange)
        }
    }, [])

    return (
        <Stack sx={{ width: 450 }}>
            <Box
                sx={{
                    width: '100%',
                    mx: 'auto',
                    borderRadius: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    p: 1,
                }}
            >
                <Box>
                    <iframe
                        ref={iframeRef}
                        width="450"
                        height="250"
                        src="https://drive.google.com/file/d/1Wm-naWCEH9eUcdzY7MpuXyM-N1dNM7bM/preview"
                        title="Introduction to Numeo AI Agent"
                        style={{ border: '1px solid #ccc', borderRadius: '8px' }}
                        allowFullScreen
                    />
                </Box>
            </Box>
        </Stack>
    )
}
