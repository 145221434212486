import { Box, Button, CircularProgress, Input, Option, Select, Stack, Typography, useColorScheme } from '@mui/joy'
import { ClientLoadModel, EmailTemplateType } from '@numeo/types'
import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { RichTextEditor } from '../../../../../../../components/common'
import { useAuth } from 'hooks/auth'
import { createBidMessage, createInfoMessage } from './assets/helpers'
import dayjs from 'dayjs'
import { useGetQuery } from 'hooks/useGetQuery'
import { useFormik } from 'formik'
import * as Yup from 'yup'

interface EmailFormProps {
    load?: ClientLoadModel
    onEmailSent?: (success: boolean) => void
    handleEmailSent?: (threadId: string) => void
}

interface RateInputProps {
    label: string
    value: string | number
    onChange: (value: string) => void
    variant: 'dollar' | 'ratePerMile'
}

const RateInput: React.FC<RateInputProps> = ({ label, value, onChange, variant }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = variant === 'dollar' ? e.target.value.replace(/\D/g, '') : e.target.value.replace(/[^\d.]/g, '')
        onChange(val)
    }

    // Shared styling according to project conventions
    const inputSx = {
        width: '100%',
        fontSize: '0.8rem',
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
        },
        '& input[type=number]': {
            MozAppearance: 'textfield',
        },
    }

    return (
        <Box sx={{ flex: 1 }}>
            <Typography level="body-md" sx={{ fontSize: '0.8rem', mb: 0.5 }}>
                {label}
            </Typography>
            <Input
                value={value}
                onChange={handleChange}
                slotProps={{
                    input: {
                        type: 'number',
                        step: variant === 'ratePerMile' ? '0.01' : '1',
                        min: '0',
                        inputMode: 'decimal',
                    },
                }}
                sx={inputSx}
            />
        </Box>
    )
}

const EmailForm: React.FC<EmailFormProps> = ({ load, onEmailSent, handleEmailSent }) => {
    const { debugData, application } = useAuth()
    const truckId = useGetQuery('truckId')
    const { mode } = useColorScheme()

    // State variables
    const [emailType, setEmailType] = useState<'bid' | 'info'>('info')
    const [bidMessage, setBidMessage] = useState<string>('')
    const [infoMessage, setInfoMessage] = useState<string>('')
    const [sendingEmail, setSendingEmail] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [emailFooter, setEmailFooter] = useState('')

    // Validation schema for rate inputs
    const validationSchema = Yup.object({
        totalRate: Yup.number().min(0, 'Rate must be positive'),
        maxRate: Yup.number().min(0, 'Rate must be positive'),
        ratePerMile: Yup.string().test('is-decimal', 'Must be a valid decimal', (value) => {
            if (!value) return true
            return /^\d*\.?\d{0,2}$/.test(value)
        }),
        maxRatePerMile: Yup.string()
            .test('is-decimal', 'Must be a valid decimal', (value) => {
                if (!value) return true
                return /^\d*\.?\d{0,2}$/.test(value)
            })
            .test('is-greater-than-min', 'Maximum rate must be greater than minimum rate', function (value) {
                const { ratePerMile } = this.parent
                if (!value || !ratePerMile) return true
                return parseFloat(value) > parseFloat(ratePerMile)
            }),
    })

    // Initialize formik
    const formik = useFormik({
        initialValues: {
            totalRate: 0,
            maxRate: 0,
            ratePerMile: '0.00',
            maxRatePerMile: '0.00',
        },
        validationSchema,
        onSubmit: () => {
            handleSend()
        },
        validateOnChange: true,
        validateOnBlur: true,
    })

    // We're using formik.values directly instead of destructuring to avoid unused variables

    // Refs to store previous values for comparison
    const prevTotalRateRef = useRef<number>(0)

    // Memoized subject based on load and email type
    const [subject, setSubject] = useState(
        load
            ? `Request Info for ${load.origin!.city}, ${load.origin!.stateProv} to ${load.destination.city}, ${load.destination.stateProv} (${dayjs(load.earliestAvailability).format('MM/DD/YYYY')})`
            : ''
    )

    // Memoize the initial rate calculation to avoid recalculating
    const initialRateCalculation = useCallback(() => {
        if (!load?.tripLength) return { rate: 0, ratePerMile: '0.00', maxRate: 0, maxRatePerMile: '0.00' }

        if (load.rateInfo?.rateUsd) {
            const ratePerMile = (load.rateInfo.rateUsd / load.tripLength).toFixed(2)
            const rate = Math.round(parseFloat(ratePerMile) * load.tripLength)
            // Calculate max rate as 20% more than min rate
            const maxRatePerMile = (parseFloat(ratePerMile) * 1.2).toFixed(2)
            const maxRate = Math.round(parseFloat(maxRatePerMile) * load.tripLength)
            return { rate, ratePerMile, maxRate, maxRatePerMile }
        } else {
            const ratePerMile = '2.50'
            const rate = Math.round(2.5 * load.tripLength)
            // Calculate max rate as 20% more than min rate
            const maxRatePerMile = (2.5 * 1.2).toFixed(2) // Exactly 20% more
            const maxRate = Math.round(parseFloat(maxRatePerMile) * load.tripLength)
            return { rate, ratePerMile, maxRate, maxRatePerMile }
        }
    }, [load?.tripLength, load?.rateInfo?.rateUsd])

    // Function to efficiently update bid message with new rate
    const updateBidMessageRate = useCallback((message: string, newRate: number): string => {
        if (!message) return message

        // Use regex to replace only the rate part without regenerating the entire message
        return message.replace(/\$\d+(?:,\d+)?/g, `$${newRate.toLocaleString()}`)
    }, [])

    // Generate email templates and initial messages
    const generateOfferEmail = async () => {
        try {
            setIsLoading(true)
            // Get email templates and footer from application object with proper null checks
            const emailTemplateArray = application?.temp?.emailTemplate || []
            const footerText = application?.temp?.emailFooter?.closing || ''

            // Set email footer regardless of whether we have templates
            setEmailFooter(footerText)

            // Only proceed with template processing if we have a load
            if (load) {
                // Calculate initial rate
                const { rate, ratePerMile: initialRatePerMile, maxRate: initialMaxRate, maxRatePerMile: initialMaxRatePerMile } = initialRateCalculation()
                formik.setValues({
                    totalRate: rate,
                    ratePerMile: initialRatePerMile,
                    maxRate: initialMaxRate,
                    maxRatePerMile: initialMaxRatePerMile,
                })
                prevTotalRateRef.current = initialMaxRate

                // Check if emailTemplateArray is actually an array
                if (Array.isArray(emailTemplateArray)) {
                    // Find saved templates by type
                    const savedBidTemplate = emailTemplateArray.find((t) => t.type === EmailTemplateType.BID)
                    const savedInfoTemplate = emailTemplateArray.find((t) => t.type === EmailTemplateType.INFO)

                    const driver = application?.temp.spotFinder?.trucks.find((t) => t._id === truckId)?.firstDriver

                    // Generate messages with the calculated max rate instead of min rate
                    const bidMsg = await createBidMessage(load, initialMaxRate, savedBidTemplate?.body, driver)
                    const infoMsg = await createInfoMessage(load, savedInfoTemplate?.body, driver)

                    // Combine message with footer
                    setBidMessage(bidMsg + footerText)
                    setInfoMessage(infoMsg + footerText)
                } else {
                    // Handle case where emailTemplateArray is not an array
                    console.warn('Email templates are not in expected array format')

                    // Generate default messages
                    const bidMsg = await createBidMessage(load, initialMaxRate)
                    const infoMsg = await createInfoMessage(load)

                    setBidMessage(bidMsg + footerText)
                    setInfoMessage(infoMsg + footerText)
                }
            }
        } catch (error) {
            console.error('Error processing email templates:', error)
            // Use default templates if there's an error
            if (load) {
                const { maxRate } = initialRateCalculation()
                const bidMsg = await createBidMessage(load, maxRate)
                const infoMsg = await createInfoMessage(load)
                setBidMessage(bidMsg + emailFooter)
                setInfoMessage(infoMsg + emailFooter)
            }
        } finally {
            setIsLoading(false)
        }
    }

    // Initialize email on mount
    useEffect(() => {
        generateOfferEmail()
        // eslint-disable-next-line
    }, []) // Only run once on mount

    // Handle rate per mile change with optimized logic
    const handleRatePerMileChange = useCallback(
        (val: string) => {
            if (!load?.tripLength) return

            if (val === '' || val === '.') {
                // Set both values at once to avoid flicker
                formik.setValues({
                    ...formik.values,
                    ratePerMile: '',
                    totalRate: 0,
                })
                return
            }

            // Validate input
            const parts = val.split('.')
            if (parts.length > 2) return // More than one decimal point
            if (parts[1] && parts[1].length > 2) return // More than 2 decimal places

            const rate = parseFloat(val)
            if (!isNaN(rate)) {
                const newTotalRate = Math.round(rate * load.tripLength)
                // Update only min rate values
                formik.setValues({
                    ...formik.values,
                    ratePerMile: val,
                    totalRate: newTotalRate,
                })
            }
        },
        [load?.tripLength, formik]
    )

    const handleMinRateChange = useCallback(
        (val: string) => {
            if (val === '') {
                formik.setValues({
                    ...formik.values,
                    totalRate: 0,
                    ratePerMile: '0.00',
                })
            } else {
                const num = parseInt(val)
                if (load?.tripLength) {
                    formik.setValues({
                        ...formik.values,
                        totalRate: num,
                        ratePerMile: (num / load.tripLength).toFixed(2),
                    })
                }
            }
        },
        [load?.tripLength, formik]
    )

    // Efficiently update bid message when max rate changes
    useEffect(() => {
        // Skip if not in bid mode or if loading
        if (emailType !== 'bid' || isLoading || !load) return

        // Only update if max rate has changed
        if (formik.values.maxRate !== prevTotalRateRef.current) {
            // Update the reference
            prevTotalRateRef.current = formik.values.maxRate

            // Directly update the rate in the message without regenerating the entire message
            setBidMessage((prev) => updateBidMessageRate(prev, formik.values.maxRate))
        }
    }, [formik.values.maxRate, emailType, isLoading, load, updateBidMessageRate])

    const handleMaxRateChange = useCallback(
        (val: string) => {
            if (val === '') {
                formik.setValues({
                    ...formik.values,
                    maxRate: 0,
                    maxRatePerMile: '0.00',
                })
            } else {
                const num = parseInt(val)
                if (load?.tripLength) {
                    formik.setValues({
                        ...formik.values,
                        maxRate: num,
                        maxRatePerMile: (num / load.tripLength).toFixed(2),
                    })
                }
            }
        },
        [load?.tripLength, formik]
    )

    // Update the existing handleMaxRatePerMileChange to match
    const handleMaxRatePerMileChange = useCallback(
        (val: string) => {
            if (!load?.tripLength) return

            if (val === '' || val === '.') {
                formik.setValues({
                    ...formik.values,
                    maxRatePerMile: '',
                    maxRate: 0,
                })
                return
            }

            const parts = val.split('.')
            if (parts.length > 2) return
            if (parts[1] && parts[1].length > 2) return

            const rate = parseFloat(val)
            if (!isNaN(rate)) {
                const newMaxRate = Math.round(rate * load.tripLength)
                formik.setValues({
                    ...formik.values,
                    maxRatePerMile: val,
                    maxRate: newMaxRate,
                })
            }
        },
        [load?.tripLength, formik]
    )

    // This duplicate useEffect has been removed to fix the issue

    // Handle email type change
    const handleEmailTypeChange = useCallback(
        async (value: 'bid' | 'info') => {
            setEmailType(value)

            // Update subject based on email type
            if (load) {
                setSubject(
                    value === 'bid'
                        ? `Bid for ${load.origin!.city}, ${load.origin!.stateProv} to ${load.destination.city}, ${load.destination.stateProv} (${dayjs(load.earliestAvailability).format('MM/DD/YYYY')})`
                        : `Request Info for ${load.origin!.city}, ${load.origin!.stateProv} to ${load.destination.city}, ${load.destination.stateProv} (${dayjs(load.earliestAvailability).format('MM/DD/YYYY')})`
                )
            }
        },
        [load]
    )

    // Handle sending email
    const handleSend = useCallback(async () => {
        if (!load) return

        // Validate form before sending
        const errors = await formik.validateForm()
        if (Object.keys(errors).length > 0) {
            // Form has validation errors
            formik.setTouched({
                totalRate: true,
                maxRate: true,
                ratePerMile: true,
                maxRatePerMile: true,
            })
            return
        }

        setSendingEmail(true)
        try {
            const emailTo = debugData.spotFinder.mockBrokerEmail || load.posterInfo.contact.email
            const currentMessage = emailType === 'bid' ? bidMessage : infoMessage
            const response = await axios.post(`/trucks/send-email/${load._id}`, {
                to: { email: emailTo },
                subject: subject,
                htmlBody: currentMessage,
                minRate: formik.values.totalRate,
                maxRate: formik.values.maxRate,
            })

            if (response.status === 200 && handleEmailSent) {
                const emailThreadId = response.data.data.emailThreadId
                onEmailSent?.(true)
                handleEmailSent(emailThreadId)
            } else {
                onEmailSent?.(false)
            }
        } catch (error) {
            onEmailSent?.(false)
        } finally {
            setSendingEmail(false)
        }
    }, [load, emailType, bidMessage, infoMessage, debugData.spotFinder.mockBrokerEmail, subject, onEmailSent, handleEmailSent, formik])

    // Memoize the form content to prevent unnecessary re-renders
    const content = useMemo(
        () => (
            <Box
                sx={{
                    p: 2,
                    border: '1px solid',
                    borderColor: mode === 'dark' ? '#666' : '#e0e0e0',
                    borderRadius: '8px',
                    padding: '1rem',
                }}
            >
                <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
                    <Typography level="body-md" sx={{ fontSize: '0.8rem' }}>
                        Email Type:
                    </Typography>
                    <Select
                        sx={{ fontSize: '0.8rem', width: '80%' }}
                        value={emailType}
                        onChange={(_, newValue) => {
                            if (newValue) {
                                handleEmailTypeChange(newValue as 'bid' | 'info')
                            }
                        }}
                        aria-label="Email Type"
                    >
                        <Option sx={{ fontSize: '0.8rem' }} value="bid">
                            Bid
                        </Option>
                        <Option sx={{ fontSize: '0.8rem' }} value="info">
                            Request Info
                        </Option>
                    </Select>
                </Stack>

                <Stack direction="column" gap={1}>
                    <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} gap={1} mt={1}>
                        <Typography level="body-md" sx={{ fontSize: '0.8rem' }}>
                            Subject:
                        </Typography>
                        <Input value={subject} onChange={(e) => setSubject(e.target.value)} sx={{ width: '80%', fontSize: '0.8rem' }} />
                    </Stack>
                    {/* {emailType === 'bid' && (
                        <Box>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography level="body-md" sx={{ fontSize: '0.8rem' }}>
                                        Total Rate ($)
                                    </Typography>
                                    <Input
                                        slotProps={{
                                            input: {
                                                type: 'number',
                                                pattern: '[0-9]*',
                                                inputMode: 'numeric',
                                                onKeyDown: (e) => {
                                                    if (!/[\d\b]/.test(e.key) && !['Delete', 'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                                                        e.preventDefault()
                                                    }
                                                },
                                            },
                                        }}
                                        value={totalRate === 0 ? '' : totalRate}
                                        onChange={(e) => {
                                            const val = e.target.value.replace(/\D/g, '')
                                            if (val === '') {
                                                setTotalRate(0)
                                                setRatePerMile('0.00')
                                            } else {
                                                const num = parseInt(val)
                                                setTotalRate(num)
                                                if (load?.tripLength) {
                                                    const newRatePerMile = (num / load.tripLength).toFixed(2)
                                                    setRatePerMile(newRatePerMile)
                                                }
                                            }
                                        }}
                                        sx={{
                                            width: '100%',
                                            fontSize: '0.8rem',
                                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                                display: 'none',
                                            },
                                            '& input[type=number]': {
                                                MozAppearance: 'textfield',
                                            },
                                        }}
                                        aria-label="Total Rate"
                                    />
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Typography level="body-md" sx={{ fontSize: '0.8rem' }}>
                                        Rate Per Mile ($)
                                    </Typography>
                                    <Input
                                        value={ratePerMile}
                                        onChange={(e) => {
                                            const val = e.target.value.replace(/[^\d.]/g, '')
                                            handleRatePerMileChange(val)
                                        }}
                                        onBlur={(e) => {
                                            // Format to exactly 2 decimal places when leaving the input
                                            const val = e.target.value
                                            if (val && val !== '.') {
                                                const rate = parseFloat(val)
                                                if (!isNaN(rate)) {
                                                    handleRatePerMileChange(rate.toFixed(2))
                                                }
                                            }
                                        }}
                                        slotProps={{
                                            input: {
                                                type: 'number',
                                                step: '0.01',
                                                min: '0',
                                                onKeyDown: (e) => {
                                                    if (!/[\d.\b]/.test(e.key) && !['Delete', 'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                                                        e.preventDefault()
                                                    }
                                                    // Prevent multiple decimal points
                                                    if (e.key === '.' && e.currentTarget.value.includes('.')) {
                                                        e.preventDefault()
                                                    }
                                                },
                                            },
                                        }}
                                        sx={{
                                            width: '100%',
                                            fontSize: '0.8rem',
                                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                                display: 'none',
                                            },
                                            '& input[type=number]': {
                                                MozAppearance: 'textfield',
                                            },
                                        }}
                                        aria-label="Rate Per Mile"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    )} */}

                    {emailType === 'bid' && (
                        <Stack direction={'column'} justifyContent={'space-between'} gap={1}>
                            <Box>
                                <Stack spacing={1}>
                                    <Stack direction="row" spacing={2}>
                                        <RateInput
                                            label="Minimum Rate ($)"
                                            value={formik.values.totalRate || ''}
                                            onChange={handleMinRateChange}
                                            variant="dollar"
                                        />
                                        <RateInput
                                            label="Minimum Rate Per Mile ($)"
                                            value={formik.values.ratePerMile}
                                            onChange={handleRatePerMileChange}
                                            variant="ratePerMile"
                                        />
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                        <RateInput
                                            label="Maximum Rate ($)"
                                            value={formik.values.maxRate || ''}
                                            onChange={handleMaxRateChange}
                                            variant="dollar"
                                        />
                                        <Box sx={{ flex: 1, position: 'relative' }}>
                                            <Typography level="body-md" sx={{ fontSize: '0.8rem', mb: 0.5 }}>
                                                Maximum Rate Per Mile ($)
                                            </Typography>
                                            <Input
                                                value={formik.values.maxRatePerMile}
                                                onChange={(e) => {
                                                    const val = e.target.value.replace(/[^\d.]/g, '')
                                                    handleMaxRatePerMileChange(val)
                                                }}
                                                onBlur={formik.handleBlur}
                                                name="maxRatePerMile"
                                                slotProps={{
                                                    input: {
                                                        type: 'number',
                                                        step: '0.01',
                                                        min: '0',
                                                        inputMode: 'decimal',
                                                    },
                                                }}
                                                sx={{
                                                    width: '100%',
                                                    fontSize: '0.8rem',
                                                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                                        display: 'none',
                                                    },
                                                    '& input[type=number]': {
                                                        MozAppearance: 'textfield',
                                                    },
                                                    borderColor: formik.touched.maxRatePerMile && formik.errors.maxRatePerMile ? 'red' : undefined,
                                                }}
                                                aria-label="Maximum Rate Per Mile"
                                            />
                                            {formik.touched.maxRatePerMile && formik.errors.maxRatePerMile && (
                                                <Typography
                                                    level="body-sm"
                                                    sx={{
                                                        color: 'red',
                                                        fontSize: '0.7rem',
                                                        mt: 0.5,
                                                        position: 'absolute',
                                                    }}
                                                >
                                                    {formik.errors.maxRatePerMile}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Stack>
                    )}
                    <Box position={'relative'}>
                        <Typography level="body-md" sx={{ fontSize: '0.8rem' }}>
                            Message
                        </Typography>
                        <Box sx={{ position: 'relative' }}>
                            {isLoading && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        bgcolor: 'rgba(255, 255, 255, 0.7)',
                                        zIndex: 1,
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                            <RichTextEditor
                                value={emailType === 'bid' ? bidMessage : infoMessage}
                                onChange={emailType === 'bid' ? setBidMessage : setInfoMessage}
                                minHeight={300}
                                placeholder={' '}
                                disabled={isLoading}
                            />
                        </Box>
                        <Button
                            variant="solid"
                            color="primary"
                            onClick={() => formik.handleSubmit()}
                            loading={sendingEmail}
                            disabled={!load?.posterInfo?.contact?.email || isLoading}
                            sx={{
                                position: 'absolute',
                                right: 30,
                                mt: -5,
                            }}
                        >
                            Send
                        </Button>
                    </Box>
                </Stack>
            </Box>
        ),
        [
            mode,
            emailType,
            subject,
            bidMessage,
            infoMessage,
            isLoading,
            sendingEmail,
            load,
            handleEmailTypeChange,
            handleRatePerMileChange,
            handleMaxRateChange,
            handleMaxRatePerMileChange,
            handleMinRateChange,
            formik,
            // Removed unnecessary dependencies: totalRate, ratePerMile, maxRate, maxRatePerMile, handleSend
            // as they're now part of formik or no longer directly referenced in the content
        ]
    )

    return content
}
export default EmailForm
