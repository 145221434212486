import React, { useState } from 'react'
import { Box, Card, Table, IconButton, Chip, Tooltip, Typography, Stack } from '@mui/joy'
import { ExpandMore, ExpandLess, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { SearchBrokerResult } from '@numeo/types'
import BrokerEmailPopover from './BrokerEmailPopover'

interface SearchBrokersTableProps {
    brokers: SearchBrokerResult[]
}

type ExpandedBrokers = Record<string, boolean>

export const SearchBrokersTable: React.FC<SearchBrokersTableProps> = ({ brokers }) => {
    // UI state
    const [expanded, setExpanded] = useState<boolean>(false)
    const [expandedBrokers, setExpandedBrokers] = useState<ExpandedBrokers>({})

    const formatCurrency = (amount: number) =>
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
        }).format(amount)

    const calculateRatePerMile = (distance: number, rate: number): string => {
        return `$${parseFloat((rate / distance).toFixed(2))}`
    }

    const toggleExpanded = () => setExpanded(!expanded)

    const toggleBrokerExpanded = (brokerId: string) => {
        setExpandedBrokers((prev) => ({
            ...prev,
            [brokerId]: !prev[brokerId],
        }))
    }

    // Render content based on state
    const renderContent = () => {
        // Filter brokers based on search term if needed
        const visibleBrokers = expanded ? brokers : brokers.slice(0, 1)
        const hasMultipleBrokers = brokers.length > 1

        // Calculate row height (approximately 60px per row)
        const maxHeight = 300 // Approximately 5 rows

        return (
            <Box>
                {/* Table layout for rate confirmations */}
                <Card
                    variant="outlined"
                    sx={{
                        p: 0,
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        sx={{
                            maxHeight: maxHeight,
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {
                                width: '8px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'neutral.outlinedBorder',
                                borderRadius: '4px',
                            },
                        }}
                    >
                        <Table
                            size="sm"
                            sx={{
                                '& th': { textAlign: 'center', p: 0, m: 0 },
                                '& td': { textAlign: 'center', p: 0, m: 0 },
                                tableLayout: 'fixed',
                                '& tr': { p: 0, m: 0 },
                                '& tr:hover': { bgcolor: 'action.hover' },
                                '& tr.multiple-rate-confs': {
                                    bgcolor: 'primary.softBg',
                                    '&:hover': { bgcolor: 'primary.softHoverBg' },
                                },
                                '& tr.expanded-rate-conf': {
                                    bgcolor: 'primary.softBg',
                                    opacity: 0.8,
                                    '&:hover': { bgcolor: 'primary.softHoverBg' },
                                },
                                '& tr.table-header': {
                                    cursor: hasMultipleBrokers ? 'pointer' : 'default',
                                },
                            }}
                        >
                            <thead>
                                <tr className="table-header" onClick={hasMultipleBrokers ? toggleExpanded : undefined}>
                                    <th style={{ width: '3%', padding: 0, margin: 0 }}>
                                        {hasMultipleBrokers && (
                                            <IconButton size="sm" variant="plain" color="neutral" sx={{ p: 0, m: 0 }}>
                                                {expanded ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>
                                        )}
                                    </th>
                                    <th style={{ width: '17%', padding: 0, margin: 0 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0, justifyContent: 'center', p: 0, m: 0 }}>
                                            <Typography fontSize={14}>Broker</Typography>
                                            {hasMultipleBrokers && (
                                                <Tooltip title={`${brokers.length} brokers found`}>
                                                    <Chip size="sm" variant="soft" color="primary">
                                                        {brokers.length}
                                                    </Chip>
                                                </Tooltip>
                                            )}
                                        </Box>
                                    </th>
                                    <th style={{ width: '15%', padding: 0, margin: 0 }}>Contact</th>
                                    <th style={{ width: '15%', padding: 0, margin: 0 }}>Origin</th>
                                    <th style={{ width: '15%', padding: 0, margin: 0 }}>Destination</th>
                                    <th style={{ width: '5%', padding: 0, margin: 0 }}>RPM</th>
                                    <th style={{ width: '5%', padding: 0, margin: 0 }}>Date</th>
                                    <th style={{ width: '5%', padding: 0, margin: 0 }}>Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                {visibleBrokers.map((brokerResult) => {
                                    const hasMultipleRateConfs = brokerResult.rateConfs.length > 1
                                    const isBrokerExpanded = expandedBrokers[brokerResult.broker.companyName] || false
                                    const brokerId = brokerResult.broker.companyName

                                    // Skip brokers with no rate confirmations
                                    if (brokerResult.rateConfs.length === 0) return null

                                    // Main row for each broker (showing the latest rate confirmation)
                                    const latestRateCon = brokerResult.rateConfs[0]
                                    const ratePerMile = calculateRatePerMile(latestRateCon.totalDistance, latestRateCon.rate.amount)
                                    const pickupLocation = latestRateCon.route[0]?.location
                                    const deliveryLocation = latestRateCon.route[latestRateCon.route.length - 1]?.location

                                    return (
                                        <React.Fragment key={brokerResult.broker.mcNumber || brokerId}>
                                            <tr
                                                onClick={hasMultipleRateConfs ? () => toggleBrokerExpanded(brokerId) : undefined}
                                                className={hasMultipleRateConfs ? 'multiple-rate-confs' : ''}
                                                style={{
                                                    cursor: hasMultipleRateConfs ? 'pointer' : 'default',
                                                }}
                                            >
                                                <td>
                                                    {hasMultipleRateConfs && (
                                                        <IconButton size="sm" variant="plain" color="neutral">
                                                            {isBrokerExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                        </IconButton>
                                                    )}
                                                </td>
                                                <td>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'flex-start' }}>
                                                        <Typography fontSize={14} noWrap>
                                                            {brokerResult.broker.companyName || `MC-${brokerResult.broker.mcNumber}`}
                                                        </Typography>
                                                        {hasMultipleRateConfs && (
                                                            <Tooltip title={`${brokerResult.rateConfs.length} rate confirmations`}>
                                                                <Chip size="sm" variant="soft" color="primary">
                                                                    {brokerResult.rateConfs.length}
                                                                </Chip>
                                                            </Tooltip>
                                                        )}
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Stack direction="row" gap={0.5}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {brokerResult.broker.contact?.email && latestRateCon.id && (
                                                                <BrokerEmailPopover broker={brokerResult.broker} rateConf={latestRateCon} />
                                                            )}
                                                        </Box>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Tooltip title={brokerResult.broker.contact?.email || 'N/A'}>
                                                                <Typography
                                                                    fontSize={14}
                                                                    sx={{
                                                                        maxWidth: 300,
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                    }}
                                                                >
                                                                    {brokerResult.broker.contact?.email || brokerResult.broker.contact?.phone || 'N/A'}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Box>
                                                    </Stack>
                                                </td>
                                                <td>
                                                    <Typography fontSize={14} noWrap>
                                                        {pickupLocation ? `${pickupLocation.city}, ${pickupLocation.state}` : 'N/A'}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography fontSize={14} noWrap>
                                                        {deliveryLocation ? `${deliveryLocation.city}, ${deliveryLocation.state}` : 'N/A'}
                                                    </Typography>
                                                </td>
                                                <td>
                                                    <Typography fontSize={14}>{ratePerMile}</Typography>
                                                </td>
                                                <td>
                                                    <Typography fontSize={14}>{new Date(latestRateCon.emailDate * 1000).toLocaleDateString()}</Typography>
                                                </td>
                                                <td>
                                                    <Typography fontSize={14} sx={{ color: 'success.600' }}>
                                                        {formatCurrency(latestRateCon.rate.amount)}
                                                    </Typography>
                                                </td>
                                            </tr>

                                            {/* Expanded rows for additional rate confirmations */}
                                            {isBrokerExpanded &&
                                                hasMultipleRateConfs &&
                                                brokerResult.rateConfs.slice(1).map((rateCon, index) => {
                                                    const ratePerMile = calculateRatePerMile(rateCon.totalDistance, rateCon.rate.amount)
                                                    const pickupLocation = rateCon.route[0]?.location
                                                    const deliveryLocation = rateCon.route[rateCon.route.length - 1]?.location

                                                    return (
                                                        <tr key={`${brokerResult.broker.mcNumber}-${index}-${rateCon.id}`} className="expanded-rate-conf">
                                                            <td></td>
                                                            <td>
                                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'flex-start' }}>
                                                                    <Typography fontSize={14} noWrap>
                                                                        {brokerResult.broker.companyName || `MC-${brokerResult.broker.mcNumber}`}
                                                                    </Typography>
                                                                </Box>
                                                            </td>
                                                            <td>
                                                                <Stack direction="row" gap={0.5}>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                        {brokerResult.broker.contact?.email && rateCon.id && (
                                                                            <BrokerEmailPopover broker={brokerResult.broker} rateConf={rateCon} />
                                                                        )}
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                        <Typography fontSize={14} noWrap>
                                                                            {brokerResult.broker.contact?.email || brokerResult.broker.contact?.phone || 'N/A'}
                                                                        </Typography>
                                                                    </Box>
                                                                </Stack>
                                                            </td>
                                                            <td>
                                                                <Typography fontSize={14} noWrap>
                                                                    {pickupLocation ? `${pickupLocation.city}, ${pickupLocation.state}` : 'N/A'}
                                                                </Typography>
                                                            </td>
                                                            <td>
                                                                <Typography fontSize={14} noWrap>
                                                                    {deliveryLocation ? `${deliveryLocation.city}, ${deliveryLocation.state}` : 'N/A'}
                                                                </Typography>
                                                            </td>
                                                            <td>
                                                                <Typography fontSize={14}>{ratePerMile}</Typography>
                                                            </td>
                                                            <td>
                                                                <Typography fontSize={14}>{new Date(rateCon.emailDate * 1000).toLocaleDateString()}</Typography>
                                                            </td>
                                                            <td>
                                                                <Typography fontSize={14} sx={{ color: 'success.600' }}>
                                                                    {formatCurrency(rateCon.rate.amount)}
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Box>
                </Card>
            </Box>
        )
    }

    // Show nothing if brokers are not found
    if (!brokers || brokers.length === 0) return null

    // Return a table row with a single cell containing all content
    return (
        <td colSpan={25} style={{ padding: 0 }}>
            {renderContent()}
        </td>
    )
}
