import {
    ClientLoadModel,
    IBroker,
    NumeoRestError,
    ApplicationEmailTemplateSettings,
    Truck,
    Application,
    BrokerDetails,
    SearchBrokerRateConfResult,
} from '@numeo/types'
import axios, { isAxiosError } from 'axios'
import dayjs from 'dayjs'
import { brokersApi } from '../../../my-brokers/api'

export const sendInfoEmail = async (
    load: ClientLoadModel,
    mockEmail?: string,
    broker?: IBroker,
    footer?: string,
    template?: ApplicationEmailTemplateSettings,
    driver?: Truck['firstDriver']
): Promise<{ emailThreadId: string }> => {
    try {
        const email = load.posterInfo.contact.email || broker?.email

        let emailTemplate = ''
        if (template) {
            if (template.body) {
                emailTemplate = template.body
            }
        }

        const message = await (emailTemplate ? createInfoMessage(load, emailTemplate, driver) : createInfoMessage(load))
        const emailBody = message + `<p>${footer}</p>`

        const response = await axios.post(`/trucks/send-email/${load._id}`, {
            to: { email: mockEmail || email },
            subject: generateSubject(load),
            htmlBody: emailBody,
        })

        return { emailThreadId: response.data.data.emailThreadId }
    } catch (error) {
        if (isAxiosError(error)) {
            throw new NumeoRestError(error.response?.data.message || 'Unknown error', error.response?.data.code || 500)
        } else {
            throw new NumeoRestError('Unknown error', 500)
        }
    }
}

export const timeDisplay = (date: string): string => {
    const diff: number = (new Date().getTime() - new Date(date).getTime()) / 60000
    return diff >= 60 ? `${Math.floor(diff / 60)}h` : `${Math.floor(diff)}m`
}

export const calculateRatePerMile = (miles: number, rate: number): string => {
    const ratePerMile = rate / miles
    if (isNaN(ratePerMile)) {
        return ''
    }

    return `$${ratePerMile.toFixed(2)}*/ml`
}

export function formatPhoneNumber(input: string): string {
    const extensionMatch = input.match(/ext\. (\d+)$/)
    const extension = extensionMatch ? extensionMatch[1] : undefined
    const phone = extensionMatch ? input.slice(0, extensionMatch.index).trim() : input.trim()

    const digits = phone.replace(/\D/g, '')

    let formattedNumber = ''
    if (digits.length === 11) {
        formattedNumber = `(${digits.substring(1, 4)}) ${digits.substring(4, 7)}-${digits.substring(7)}`
    } else if (digits.length === 10) {
        formattedNumber = `(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(6)}`
    }

    if (extension) {
        formattedNumber += ` ext. ${extension}`
    }

    return formattedNumber
}

// Email template helpers
const getBrokerName = async (mcNumber: string) => {
    try {
        const broker = await brokersApi.getBrokerByMcNumber(mcNumber)
        if (broker) {
            return broker.fullName
        }
        return 'team'
    } catch (error) {
        console.error('Error fetching broker:', error)
        return 'team'
    }
}

export const generateSubject = (load: ClientLoadModel): string => {
    return `Load info  - ${load.origin!.city}, ${load.origin!.stateProv} -> ${load.destination.city}, ${load.destination.stateProv} (${dayjs(load.earliestAvailability).format('MM/DD/YYYY')})`
}

export const createBidMessage = async (load: ClientLoadModel, totalRate: number = 0, template?: string, driver?: Truck['firstDriver']) => {
    if (!load) return ''

    const brokerName = await getBrokerName(load.posterInfo.mcNumber)

    if (template) {
        const message = template
            .replace(/{{origin_city}}/g, load.origin!.city)
            .replace(/{{origin_state}}/g, load.origin!.stateProv)
            .replace(/{{destination_city}}/g, load.destination.city)
            .replace(/{{destination_state}}/g, load.destination.stateProv)
            .replace(/\$\d+(?:,\d+)?|\{\{total_rate\}\}/g, `$${totalRate.toLocaleString()}`)
            .replace(/{{date}}/g, dayjs(load.earliestAvailability).format('MM/DD/YYYY'))
            .replace(/{{load_id}}/g, load.postersReferenceId || 'N/A')
            .replace(/{{broker_contact_name}}/g, brokerName || 'team')
            .replace(/{{driver_name}}/g, driver?.name || 'N/A')
            .replace(/{{driver_phone}}/g, formatPhoneNumber(driver?.phone || 'N/A'))
        return message
    }

    return `<p>Hello, ${brokerName}</p>

<p>I'm reaching out regarding your order from ${load.origin!.city}, ${load.origin!.stateProv}, to ${load.destination.city}, ${load.destination.stateProv}. I'd like to propose a rate of <b>$${totalRate.toLocaleString()}</b>.</p>

<p>Could you let us know if this proposal works for you or if you'd like to discuss further?</p>

<p>Thanks,<br>
 Numeo </p>

<p>SFG Logistics<br>`
}

export const createInfoMessage = async (load: ClientLoadModel, template?: string, driver?: Truck['firstDriver']) => {
    const brokerName = await getBrokerName(load.posterInfo.mcNumber)
    if (template) {
        return template
            .replace(/{{origin_city}}/g, load.origin.city)
            .replace(/{{origin_state}}/g, load.origin.stateProv)
            .replace(/{{destination_city}}/g, load.destination.city)
            .replace(/{{destination_state}}/g, load.destination.stateProv)
            .replace(/{{date}}/g, dayjs(load.earliestAvailability).format('MM/DD/YYYY'))
            .replace(/{{load_id}}/g, load.postersReferenceId || 'N/A')
            .replace(/{{broker_contact_name}}/g, brokerName || 'team')
            .replace(/{{driver_name}}/g, driver?.name || 'N/A')
            .replace(/{{driver_phone}}/g, formatPhoneNumber(driver?.phone || 'N/A'))
    }

    return `<p>Hello, ${brokerName || 'team'}!</p>
<p>Need details on the <strong>${load.origin.city}, ${load.origin.stateProv}</strong> to <strong>${load.destination.city}, ${load.destination.stateProv}</strong>, <strong>${dayjs(load.earliestAvailability).format('MM/DD/YYYY')}</strong></p>

`
}

export const createBrokerMessage = (broker: BrokerDetails, rateCon: SearchBrokerRateConfResult, application?: Application, template?: string) => {
    if (template) {
        return template
            .replace(/{{origin_city}}/g, rateCon.route[0].location.city || 'N/A')
            .replace(/{{origin_state}}/g, rateCon.route[0].location.state || 'N/A')
            .replace(/{{destination_city}}/g, rateCon.route[rateCon.route.length - 1].location.city || 'N/A')
            .replace(/{{destination_state}}/g, rateCon.route[rateCon.route.length - 1].location.state || 'N/A')
            .replace(/{{broker_contact_name}}/g, broker.contact.name || broker.companyName || 'team')
            .replace(/{{project_name}}/g, application?.projectName || 'team')
    }

    return `<p>Hello ${broker.contact.name || broker.companyName || 'team'},</p>

<p>This is ${application?.projectName || 'our team'} reaching out. I noticed that we previously worked together on a load out of <strong>${broker.address.city}, ${broker.address.state}</strong>, and you had sent us a rate confirmation for that origin.</p>

<p>I'm currently looking for another load out of <strong>${broker.address.city}, ${broker.address.state}</strong>. Do you happen to have anything available from that area at the moment?</p>

<p>Please let me know if there's anything you can offer. Looking forward to working with you again!</p>`
}

export const formatTimestamp = (timestamp: string) => {
    const date = new Date(timestamp)
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).format(date)
}
