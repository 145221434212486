import { Box, Link, Stack, Typography } from '@mui/joy'
import React from 'react'
import { formatPhoneNumber } from '../assets/helpers'
import { CompanyColumnProps } from './types'

/**
 * CompanyColumn component displays company information when the load row is expanded.
 * This includes:
 * - Company Name
 * - Contact Info
 * - MC Number
 * - CS/DTP info
 * - Company Location
 */

export const CompanyColumn: React.FC<CompanyColumnProps> = ({ load }) => {
    const renderCompanyInfo = () => {
        const { posterInfo } = load
        if (!posterInfo?.contact) return null

        return (
            <>
                <Stack direction={'row'} flexWrap={'wrap'} px={1} justifyContent={'space-between'}>
                    <Stack direction={'column'} gap={0.5}>
                        <Box>
                            {posterInfo.companyName && (
                                <Typography level="body-lg" fontWeight={'bold'}>
                                    {posterInfo.companyName}
                                </Typography>
                            )}
                        </Box>

                        <Box>
                            {posterInfo.contact.email && (
                                <>
                                    <Typography level="body-sm">
                                        <Link href={`mailto:${posterInfo.contact.email}`}>{posterInfo.contact.email}</Link>
                                    </Typography>
                                </>
                            )}
                            {posterInfo.contact.phoneNumber && (
                                <Typography level="body-sm">
                                    <Link href={`tel:${posterInfo.contact.phoneNumber}`}>{formatPhoneNumber(posterInfo.contact.phoneNumber)}</Link>
                                </Typography>
                            )}
                        </Box>
                    </Stack>
                    <Stack direction={'column'} gap={0.5}>
                        {posterInfo.mcNumber && <Typography level="body-sm">MC#{posterInfo.mcNumber}</Typography>}
                        {posterInfo.city && (
                            <Typography level="body-sm">
                                {posterInfo.city}, {posterInfo.carrierHomeState}{' '}
                            </Typography>
                        )}
                    </Stack>
                    <Stack direction={'column'} gap={0.5}>
                        {posterInfo.credit && <Typography level="body-sm">Credit score: {posterInfo.credit.creditScore}</Typography>}
                        {posterInfo.credit.daysToPay && <Typography level="body-sm">Days to pay: {posterInfo.credit.daysToPay}</Typography>}
                    </Stack>
                </Stack>
            </>
        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%',
            }}
        >
            {/* Header */}
            <Typography
                sx={{
                    color: 'text.secondary',
                    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#2b2b2b' : '#f0f0f0'),
                    px: 1,
                    fontWeight: 'bold',
                }}
            >
                Company
            </Typography>

            {renderCompanyInfo()}
        </Box>
    )
}
