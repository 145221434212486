/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { Box, Button, Card, Typography, ToggleButtonGroup, Sheet } from '@mui/joy'

import { DemoLivekitAgent } from './DemoLivekitAgent'

type TalkToType = 'broker' | 'carrier' | null
type ScenarioType = 'rate' | 'negotiation' | 'update' | null

export const VoiceDemoTab = () => {
    const [talkTo, setTalkTo] = useState<TalkToType>('broker')
    const [scenario, setScenario] = useState<ScenarioType>('rate')

    const handleTalkToChange = (event: React.MouseEvent<HTMLElement>, newValue: TalkToType) => {
        setTalkTo(newValue)
    }

    const handleScenarioChange = (event: React.MouseEvent<HTMLElement>, newValue: ScenarioType) => {
        setScenario(newValue)
    }

    return (
        <Sheet variant="outlined" sx={{ height: '100%', overflow: 'auto' }}>
            <Card
                variant="outlined"
                sx={{
                    p: 2,
                    height: 'calc(100% - 20px)',
                    display: 'flex',
                    flexDirection: 'column',
                    m: 2,
                    border: 0,
                }}
            >
                <Box sx={{ width: '100%', mb: 5 }}>
                    <Typography level="h4" textAlign="center" sx={{ mb: 1, fontSize: '1rem' }}>
                        Select who you want to talk to:
                    </Typography>
                    <ToggleButtonGroup
                        value={talkTo}
                        onChange={handleTalkToChange}
                        sx={{
                            width: '100%',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            value="broker"
                            color={talkTo === 'broker' ? 'primary' : 'neutral'}
                            sx={{
                                width: '300px',
                                fontSize: '1rem',
                                py: 1,
                                borderRadius: '8px',
                                m: 0.5,
                            }}
                        >
                            Talk to Broker
                        </Button>
                        <Button
                            value="carrier"
                            color={talkTo === 'carrier' ? 'primary' : 'neutral'}
                            sx={{
                                width: '300px',
                                fontSize: '1rem',
                                py: 1,
                                borderRadius: '8px',
                                m: 0.5,
                            }}
                        >
                            Talk to Carrier
                        </Button>
                    </ToggleButtonGroup>
                </Box>

                <Box sx={{ width: '100%' }}>
                    <Typography level="h4" textAlign="center" sx={{ mb: 1, fontSize: '1rem' }}>
                        Scenarios:
                    </Typography>
                    <ToggleButtonGroup
                        value={scenario}
                        onChange={handleScenarioChange}
                        sx={{
                            width: '100%',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            value="rate"
                            color={scenario === 'rate' ? 'primary' : 'neutral'}
                            sx={{
                                width: '200px',
                                fontSize: '0.9rem',
                                py: 1,
                                borderRadius: '8px',
                                m: 0.5,
                            }}
                        >
                            Ask for rate
                        </Button>
                        <Button
                            value="negotiation"
                            color={scenario === 'negotiation' ? 'primary' : 'neutral'}
                            sx={{
                                width: '200px',
                                fontSize: '0.9rem',
                                py: 1,
                                borderRadius: '8px !important',
                                m: 0.5,
                            }}
                        >
                            Negotiation
                        </Button>
                        <Button
                            value="update"
                            color={scenario === 'update' ? 'primary' : 'neutral'}
                            sx={{
                                width: '200px',
                                fontSize: '0.9rem',
                                py: 1,
                                borderRadius: '8px',
                                m: 0.5,
                            }}
                        >
                            Load update
                        </Button>
                    </ToggleButtonGroup>
                </Box>

                <Box sx={{ flexGrow: 1, mt: 3 }}>
                    <DemoLivekitAgent talkTo={talkTo!} scenario={scenario!} disabled={!talkTo || !scenario} />
                </Box>
            </Card>
        </Sheet>
    )
}
