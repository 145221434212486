import { CalendarMonth, MonetizationOn } from '@mui/icons-material'
import { Grid, Card, Stack, Box, Typography, Chip } from '@mui/joy'
import { OngoingLoad } from '@numeo/types'

interface LoadColumnProps {
    load: OngoingLoad
    formatDateTime: (dateString: string | Date) => string
    formatCurrency: (amount: number) => string
}

export const LoadColumn = ({ load, formatDateTime, formatCurrency }: LoadColumnProps) => {
    const formatDateTimeRange = (start: Date | string, end?: Date | string) => {
        // If end date doesn't exist, only display one date
        if (!end) {
            return formatDateTime(start)
        }

        // Convert to strings for comparison
        const startStr = formatDateTime(start)
        const endStr = formatDateTime(end)

        // If dates are the same, only display one
        if (startStr === endStr) {
            return startStr
        }

        // Otherwise display date range
        return `${startStr} - ${endStr}`
    }

    return (
        <Grid xs={12} md={4}>
            <Card variant="outlined" sx={{ p: 1.5, height: '100%' }}>
                <Stack spacing={1.5}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CalendarMonth />
                        <Typography level="title-sm">Load Details</Typography>
                    </Box>

                    <Stack direction="column">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography level="body-xs" sx={{ color: 'text.secondary' }}>
                                Pickup:
                            </Typography>
                            <Typography level="body-sm">{formatDateTimeRange(load.pickupTime1, load.pickupTime2)}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography level="body-xs" sx={{ color: 'text.secondary' }}>
                                Delivery:
                            </Typography>
                            <Typography level="body-sm">{formatDateTimeRange(load.deliveryTime1, load.deliveryTime2)}</Typography>
                        </Stack>
                    </Stack>

                    <Stack direction="row" spacing={2} alignItems="center">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography level="body-xs" sx={{ color: 'text.secondary' }}>
                                Equipment:
                            </Typography>
                            <Chip size="sm" variant="soft">
                                {load.equipmentType}
                            </Chip>
                        </Stack>

                        {load.weight > 0 && (
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography level="body-xs" sx={{ color: 'text.secondary' }}>
                                    Weight:
                                </Typography>
                                <Typography level="body-sm">{load.weight.toLocaleString()} lbs</Typography>
                            </Stack>
                        )}

                        {load.length > 0 && (
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography level="body-xs" sx={{ color: 'text.secondary' }}>
                                    Length:
                                </Typography>
                                <Typography level="body-sm">{load.length} ft</Typography>
                            </Stack>
                        )}
                    </Stack>

                    <Stack direction="row" spacing={1} alignItems="center">
                        <MonetizationOn fontSize="small" sx={{ color: 'success.600' }} />
                        <Typography level="body-md" fontWeight="md" sx={{ color: 'success.600' }}>
                            {formatCurrency(load.totalRate)}
                        </Typography>
                    </Stack>
                </Stack>
            </Card>
        </Grid>
    )
}
