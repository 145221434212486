import { Box, Button, Card, FormControl, FormLabel, Input, Stack, Typography, IconButton } from '@mui/joy'
import { useState } from 'react'
import { useAuth } from 'hooks/auth'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'
import { DeleteRounded, AddCircleOutline } from '@mui/icons-material'
import { LucidEldVehicle } from '@numeo/types'
import { eldApi } from 'pages/dashboard/pages/dispatcher/pages/spot-finder/api/eld-api'
import { TruckSelectionModal, DisconnectConfirmationModal, VehicleSelectionTable } from './SharedComponents'
import { Category } from 'hooks/useNotification'

interface LucidEldFormData {
    username: string
    password: string
    apiKey: string
}

export const LucidEld = () => {
    const { application, setApplication } = useAuth()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [vehicles, setVehicles] = useState<LucidEldVehicle[]>([])
    const [selectedVehicles, setSelectedVehicles] = useState<string[]>([])
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
    const [showAddVehiclesModal, setShowAddVehiclesModal] = useState<boolean>(false)
    const [showDisconnectConfirmation, setShowDisconnectConfirmation] = useState<boolean>(false)
    const [formData, setFormData] = useState<LucidEldFormData>({
        username: '',
        password: '',
        apiKey: '',
    })

    const isLucidEldConnected: boolean = application?.integrations?.lucidEld?.username ? true : false

    const fetchVehicles = async (showModal: 'confirmation' | 'addVehicles'): Promise<void> => {
        try {
            setIsLoading(true)
            const vehicles = await eldApi.getAllLucidEldTrucks()
            setVehicles(vehicles)
            setSelectedVehicles([])

            if (showModal === 'confirmation') {
                setShowConfirmation(true)
            } else {
                setShowAddVehiclesModal(true)
            }
        } catch (error) {
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Email,
                    message: 'Failed to fetch LucidELD vehicles',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'error',
                })
            )
        } finally {
            setIsLoading(false)
        }
    }

    const handleAddSelectedTrucks = async (): Promise<void> => {
        try {
            if (selectedVehicles.length === 0) {
                dispatch(
                    addNotification({
                        id: '',
                        category: Category.Email,
                        message: 'Please select at least one truck',
                        timestamp: Date.now(),
                        topic: 'temp',
                        type: 'error',
                    })
                )
                return
            }

            setIsLoading(true)
            // Filter vehicles to only include selected ones
            const trucksToAdd = vehicles.filter((vehicle) => selectedVehicles.includes(vehicle.number))
            const response = await eldApi.addSelectedLucidEldTrucks(trucksToAdd)
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Email,
                    message: `${selectedVehicles.length} trucks added successfully`,
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'info',
                })
            )
            setApplication(response)
            setShowConfirmation(false)
            setSelectedVehicles([])
        } catch (error) {
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Email,
                    message: 'Failed to add trucks',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'error',
                })
            )
        } finally {
            setIsLoading(false)
        }
    }

    const handleSelectAllVehicles = () => {
        if (selectedVehicles.length === vehicles.length) {
            setSelectedVehicles([])
        } else {
            setSelectedVehicles(vehicles.map((vehicle) => vehicle.number))
        }
    }

    const handleSelectVehicle = (number: string) => {
        if (selectedVehicles.includes(number)) {
            setSelectedVehicles(selectedVehicles.filter((id) => id !== number))
        } else {
            setSelectedVehicles([...selectedVehicles, number])
        }
    }

    const handleConnect = async (): Promise<void> => {
        try {
            setIsLoading(true)
            const applicationResponse = await eldApi.connectLucidEld(formData.username, formData.password, formData.apiKey)
            setApplication(applicationResponse)
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Email,
                    message: 'LucidELD connected successfully',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'info',
                })
            )
            // Reset form
            setFormData({ username: '', password: '', apiKey: '' })
        } catch (error) {
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Email,
                    message: 'Failed to connect LucidELD',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'error',
                })
            )
        } finally {
            setIsLoading(false)
        }
    }

    const handleDisconnect = async (): Promise<void> => {
        try {
            setIsLoading(true)
            const applicationResponse = await eldApi.disconnectLucidEld()
            setApplication(applicationResponse)
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Email,
                    message: 'LucidELD disconnected successfully',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'info',
                })
            )
            setShowDisconnectConfirmation(false)
        } catch (error) {
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Email,
                    message: 'Failed to disconnect LucidELD',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'error',
                })
            )
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Card variant="outlined" sx={{ p: 2, gap: 1, width: '100%' }}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <img src="/lucideld.png" alt="LucidELD Logo" style={{ width: 24, height: 24 }} />
                    <Box sx={{ flex: 1 }}>
                        <Typography level="title-md">Lucid ELD</Typography>
                    </Box>
                    {isLucidEldConnected && (
                        <IconButton
                            variant="soft"
                            color="danger"
                            onClick={() => setShowDisconnectConfirmation(true)}
                            loading={isLoading}
                            disabled={isLoading}
                            title="Disconnect LucidELD"
                        >
                            <DeleteRounded />
                        </IconButton>
                    )}
                </Stack>

                {!isLucidEldConnected ? (
                    <Stack spacing={1} direction={'column'}>
                        <Stack direction={'row'} spacing={1}>
                            <FormControl sx={{ width: '100%' }}>
                                <FormLabel>Username</FormLabel>
                                <Input
                                    placeholder="Enter your username"
                                    value={formData.username}
                                    onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                                    disabled={isLoading}
                                />
                            </FormControl>
                            <FormControl sx={{ width: '100%' }}>
                                <FormLabel>Password</FormLabel>
                                <Input
                                    type="password"
                                    placeholder="Enter your password"
                                    value={formData.password}
                                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                    disabled={isLoading}
                                />
                            </FormControl>
                        </Stack>

                        <FormControl>
                            <FormLabel>API Key</FormLabel>
                            <Input
                                type="password"
                                placeholder="Enter your LucidELD API key"
                                value={formData.apiKey}
                                onChange={(e) => setFormData({ ...formData, apiKey: e.target.value })}
                                disabled={isLoading}
                            />
                        </FormControl>
                        <Button loading={isLoading} disabled={!formData.username || !formData.password || !formData.apiKey} onClick={handleConnect} fullWidth>
                            Connect LucidELD
                        </Button>
                    </Stack>
                ) : (
                    <Stack spacing={2}>
                        <Typography level="body-sm" startDecorator={<Box sx={{ width: 8, height: 8, borderRadius: 'sm', bgcolor: 'success.500' }} />}>
                            Connected with username: {application?.integrations?.lucidEld?.username}
                        </Typography>
                        <Button startDecorator={<AddCircleOutline />} variant="outlined" onClick={() => fetchVehicles('addVehicles')} loading={isLoading}>
                            Add Connected Trucks
                        </Button>
                    </Stack>
                )}
            </Card>

            <TruckSelectionModal
                open={showConfirmation}
                onClose={() => setShowConfirmation(false)}
                title="Select Trucks to Add"
                description={`We have found ${vehicles.length} trucks connected to this ELD. Select the trucks you want to add:`}
                selectionTable={
                    <VehicleSelectionTable
                        vehicles={vehicles}
                        selectedVehicles={selectedVehicles}
                        onSelectVehicle={handleSelectVehicle}
                        onSelectAllVehicles={handleSelectAllVehicles}
                        getVehicleData={(vehicle) => ({
                            id: vehicle.number,
                            truckNumber: vehicle.number,
                            vin: vehicle.VIN,
                        })}
                    />
                }
                onConfirm={handleAddSelectedTrucks}
                isLoading={isLoading}
                selectedCount={selectedVehicles.length}
            />

            <TruckSelectionModal
                open={showAddVehiclesModal}
                onClose={() => setShowAddVehiclesModal(false)}
                title="Add Connected Trucks"
                description={`Select connected trucks from your LucidELD account to add to your trucks list:`}
                selectionTable={
                    <VehicleSelectionTable
                        vehicles={vehicles}
                        selectedVehicles={selectedVehicles}
                        onSelectVehicle={handleSelectVehicle}
                        onSelectAllVehicles={handleSelectAllVehicles}
                        getVehicleData={(vehicle) => ({
                            id: vehicle.number,
                            truckNumber: vehicle.number,
                            vin: vehicle.VIN,
                        })}
                    />
                }
                onConfirm={() => {
                    handleAddSelectedTrucks()
                    setShowAddVehiclesModal(false)
                }}
                isLoading={isLoading}
                selectedCount={selectedVehicles.length}
            />

            <DisconnectConfirmationModal
                open={showDisconnectConfirmation}
                onClose={() => setShowDisconnectConfirmation(false)}
                onConfirm={handleDisconnect}
                isLoading={isLoading}
                serviceName="LucidELD"
            />
        </>
    )
}
