import { Box, Grid, Typography, Chip, Card, Divider } from '@mui/joy'
import { OngoingLoad } from '@numeo/types'
import { DirectionsRounded } from '@mui/icons-material'
import { TruckColumn } from './TruckColumn'
import { LoadColumn } from './LoadColumn'
import { BrokerColumn } from './BrokerColumn'

interface OngoingLoadDetailsProps {
    load: OngoingLoad
    formatDate: (dateString: string | Date) => string
    formatCurrency: (amount: number) => string
    formatUpdatedAt: (dateString: string | Date) => string
}

export const OngoingLoadDetails = ({ load, formatCurrency, formatUpdatedAt }: OngoingLoadDetailsProps) => {
    return (
        <Box sx={{ p: 1 }}>
            {/* Title with route and distance */}
            <Typography level="title-lg" sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                <DirectionsRounded />
                {load.origin.city}, {load.origin.state}
                <Typography sx={{ fontSize: 'inherit' }}>&rarr;</Typography>
                {load.destination.city}, {load.destination.state}
                <Chip color="primary" size="sm">
                    {load.distance} miles
                </Chip>
            </Typography>

            <Divider sx={{ mb: 1 }} />

            <Grid container spacing={1}>
                {/* Column 1: Truck & Driver */}
                <TruckColumn load={load} />

                {/* Column 2: Load Details */}
                <LoadColumn load={load} formatDateTime={formatUpdatedAt} formatCurrency={formatCurrency} />

                {/* Column 3: Broker Information */}
                <BrokerColumn load={load} formatUpdatedAt={formatUpdatedAt} />
            </Grid>

            {/* Comments section if present */}
            {load.comment && (
                <Card variant="outlined" sx={{ p: 1, mt: 1 }}>
                    <Typography level="title-sm">Comments</Typography>
                    <Typography sx={{ whiteSpace: 'pre-wrap' }}>{load.comment}</Typography>
                </Card>
            )}
        </Box>
    )
}
