import { Box, CircularProgress } from '@mui/joy'
import { useEffect, useState } from 'react'
import { verifyAuth, loadDebugData, selectIsLoading } from '../store/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../store'

interface AuthInitializerProps {
    children: React.ReactNode
}

export const AuthInitializer: React.FC<AuthInitializerProps> = ({ children }) => {
    const dispatch = useDispatch<AppDispatch>()
    const isLoading = useSelector(selectIsLoading)
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        const initialize = async () => {
            await dispatch(verifyAuth())
            dispatch(loadDebugData())
            setInitialized(true)
        }

        initialize()
    }, [dispatch])

    if (isLoading && !initialized) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        )
    }

    return <>{children}</>
}

export default AuthInitializer
