import { CalendarMonth, DocumentScanner, FindInPage } from '@mui/icons-material'
import {
    Card,
    CardContent,
    Stack,
    FormControl,
    FormLabel,
    Select,
    Input,
    Box,
    Button,
    CircularProgress,
    Sheet,
    Typography,
    Chip,
    Option,
    Tooltip,
} from '@mui/joy'
import { Application } from '@numeo/types'

interface RequestCardProps {
    applications: Application[]
    isLoading: boolean
    selectedProject: string
    setSelectedProject: (project: string) => void
    emailLimit: string
    setEmailLimit: (limit: string) => void
    receivedBefore: string
    setReceivedBefore: (date: string) => void
    processEmails: () => Promise<void>
    isProcessing: boolean
    processingStats: {
        processed: number
        rateConfs: number
        errors: number
    } | null
    fetchRateConfirmations: () => void
}

export const RequestCard = ({
    applications,
    isLoading,
    selectedProject,
    setSelectedProject,
    emailLimit,
    setEmailLimit,
    receivedBefore,
    setReceivedBefore,
    processEmails,
    isProcessing,
    processingStats,
    fetchRateConfirmations,
}: RequestCardProps) => {
    // Check if the selected application has email integration
    const selectedApp = applications.find((app) => app.projectName === selectedProject)
    const hasEmailIntegration = selectedApp?.integrations?.nylas?.emails && selectedApp.integrations.nylas.emails.length > 0
    const emailTooltip = "Selected project doesn't have email connected"

    return (
        <Card sx={{ mb: 3 }}>
            <CardContent>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ mb: 2 }}>
                    <FormControl sx={{ minWidth: 200 }}>
                        <FormLabel>Select Project</FormLabel>
                        <Select
                            value={selectedProject}
                            onChange={(_, value) => setSelectedProject(value as string)}
                            placeholder="Select a project"
                            disabled={isLoading || applications.length === 0}
                        >
                            {applications.map((app) => (
                                <Option key={app.projectName} value={app.projectName}>
                                    {app.projectName}
                                </Option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Email Limit</FormLabel>
                        <Input
                            type="number"
                            value={emailLimit}
                            onChange={(e) => setEmailLimit(e.target.value)}
                            slotProps={{
                                input: {
                                    min: 1,
                                    max: 200,
                                },
                            }}
                            sx={{ width: 100 }}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Received Before</FormLabel>
                        <Input
                            type="date"
                            value={receivedBefore}
                            onChange={(e) => setReceivedBefore(e.target.value)}
                            startDecorator={<CalendarMonth />}
                            slotProps={{
                                input: {
                                    max: new Date().toISOString().split('T')[0], // Today's date
                                },
                            }}
                        />
                    </FormControl>

                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Tooltip title={!hasEmailIntegration && selectedProject ? emailTooltip : ''} placement="top">
                            <span>
                                <Button
                                    variant="solid"
                                    color="primary"
                                    startDecorator={isProcessing ? <CircularProgress size="sm" /> : <DocumentScanner />}
                                    onClick={processEmails}
                                    disabled={!selectedProject || isProcessing || !hasEmailIntegration}
                                    sx={{ mt: { xs: 0, sm: 2 } }}
                                >
                                    {isProcessing ? 'Processing...' : 'Process Emails'}
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title={!hasEmailIntegration && selectedProject ? emailTooltip : ''} placement="top">
                            <span>
                                <Button
                                    variant="solid"
                                    color="warning"
                                    startDecorator={isLoading ? <CircularProgress size="sm" /> : <FindInPage />}
                                    onClick={fetchRateConfirmations}
                                    disabled={!selectedProject || isLoading || !hasEmailIntegration}
                                    sx={{ mt: { xs: 0, sm: 2 }, ml: 1 }}
                                >
                                    {isLoading ? 'Loading...' : 'Get RateConfs'}
                                </Button>
                            </span>
                        </Tooltip>
                    </Box>
                </Stack>

                {processingStats && (
                    <Sheet variant="outlined" sx={{ p: 2, borderRadius: 'sm' }}>
                        <Typography level="body-sm" sx={{ mb: 1 }}>
                            Last Processing Results:
                        </Typography>
                        <Stack direction="row" spacing={2}>
                            <Chip color="neutral" variant="soft">
                                Processed: {processingStats.processed}
                            </Chip>
                            <Chip color="success" variant="soft">
                                Rate Confirmations: {processingStats.rateConfs}
                            </Chip>
                            {processingStats.errors > 0 && (
                                <Chip color="danger" variant="soft">
                                    Errors: {processingStats.errors}
                                </Chip>
                            )}
                        </Stack>
                    </Sheet>
                )}
            </CardContent>
        </Card>
    )
}
