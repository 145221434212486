import { Box, Button, Divider, FormControl, FormLabel, Input, Textarea, Tooltip } from '@mui/joy'
import { RateNegotiationSettings } from '@numeo/types'
import axios from 'axios'
import { useAuth } from 'hooks/auth'
import { Category } from 'hooks/useNotification'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'
import RateNegotiationTemp from './RateNegotiationTemp'

export const AccountDetailsTab = () => {
    const dispatch = useDispatch()
    const { application, user } = useAuth()

    // State for form data
    const [companyData, setCompanyData] = useState({
        name: '',
        mcNumber: '',
        details: '',
        companyAddress: '',
        phoneNumber: '',
    })

    useEffect(() => {
        console.log('Company data:', companyData)
    }, [companyData])

    // State for rate negotiation settings
    const [rateSettings, setRateSettings] = useState<RateNegotiationSettings | null>(null)

    // Memoize the onValueChange callback to prevent infinite loops
    const handleRateSettingsChange = useCallback((newSettings: RateNegotiationSettings) => {
        setRateSettings(newSettings)
    }, [])

    // State for email
    const [email, setEmail] = useState('')

    // State for form submission status
    const [status, setStatus] = useState({
        loading: false,
    })

    // Load company data from application when available
    useEffect(() => {
        try {
            // Check if application settings and company data exist
            if (application?.settings?.company) {
                const { company } = application.settings
                setCompanyData({
                    name: company.name || '',
                    mcNumber: company.mcNumber || '',
                    details: company.details || '',
                    companyAddress: company.companyAddress || '',
                    phoneNumber: company.phoneNumber || '',
                })

                // Load rate negotiation settings if available
                if (company.rateNegotiation) {
                    console.log('Loaded rate settings from DB:', company.rateNegotiation)
                    setRateSettings(company.rateNegotiation)
                } else {
                    // Default settings if not available
                    console.log('Using default rate settings')
                    setRateSettings({
                        firstBidThreshold: 80,
                        secondBidThreshold: 30,
                        minGap: 10,
                    })
                }
            } else {
                // Default settings if company data is not available
                setRateSettings({
                    firstBidThreshold: 80,
                    secondBidThreshold: 30,
                    minGap: 10,
                })
            }

            // Set email from user data if available
            if (user?.email) {
                setEmail(user.email)
            }
        } catch (error) {
            console.error('Error loading company data:', error)
        }
    }, [application, user])

    // Handle company data save
    const handleSaveCompany = async () => {
        try {
            setStatus({ loading: true })

            // Use the current rate settings from state
            // This is updated whenever the slider values change via the onValueChange callback

            // Save company data including rate negotiation settings
            const companyResponse = await axios.post('/application/company', {
                name: companyData.name,
                mcNumber: companyData.mcNumber,
                details: companyData.details,
                companyAddress: companyData.companyAddress,
                phoneNumber: companyData.phoneNumber,
                rateNegotiation: rateSettings, // This will be stored in the company object
            })

            if (companyResponse.status === 200) {
                dispatch(
                    addNotification({
                        id: '',
                        category: Category.Settings,
                        message: 'Company details saved successfully',
                        timestamp: Date.now(),
                        topic: 'temp',
                        type: 'info',
                    })
                )
            }

            setStatus({ loading: false })
            //eslint-disable-next-line
        } catch (error: any) {
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: error.message || 'Failed to save company details',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'error',
                })
            )

            setStatus({ loading: false })
        }
    }

    // Handle cancel button click
    const handleCancel = () => {
        // Reset to original data from application
        if (application?.settings?.company) {
            const { company } = application.settings
            setCompanyData({
                name: company.name || '',
                mcNumber: company.mcNumber || '',
                details: company.details || '',
                companyAddress: company.companyAddress || '',
                phoneNumber: company.phoneNumber || '',
            })
        } else {
            // If no data in application, reset to empty
            setCompanyData({
                name: '',
                mcNumber: '',
                details: '',
                companyAddress: '',
                phoneNumber: '',
            })
        }
    }

    // Common form section styles
    const sectionStyles = {
        display: { xs: 'contents', sm: 'flex' },
        gap: 2,
        width: '100%',
        mt: 1,
    }

    // Common form control styles
    const formControlStyles = {
        flex: 1,
        mb: 1,
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                width: '100%',
                px: 3,
            }}
        >
            {/* Account Login Section */}
            <Box>
                <FormLabel
                    sx={{
                        display: 'block',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                    }}
                >
                    Account Login
                </FormLabel>
                <Box sx={sectionStyles}>
                    <FormControl sx={formControlStyles}>
                        <FormLabel>Email Address</FormLabel>
                        <Input placeholder="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} disabled={true} />
                    </FormControl>
                    <FormControl sx={formControlStyles}></FormControl>
                </Box>
            </Box>

            <Divider />

            {/* Password Section */}
            <Box>
                <FormLabel
                    sx={{
                        display: 'block',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                    }}
                >
                    Update Password
                </FormLabel>
                <Box sx={sectionStyles}>
                    <Tooltip title="Password updates are currently disabled" placement="top">
                        <FormControl sx={formControlStyles} disabled>
                            <FormLabel>New Password</FormLabel>
                            <Input placeholder="Password" type="password" />
                        </FormControl>
                    </Tooltip>
                    <Tooltip title="Password updates are currently disabled" placement="top">
                        <FormControl sx={formControlStyles} disabled>
                            <FormLabel>Confirm Password</FormLabel>
                            <Input placeholder="Confirm" type="password" />
                        </FormControl>
                    </Tooltip>
                </Box>
            </Box>

            <Divider />

            {/* Company Details Section */}
            <Box>
                <FormLabel
                    sx={{
                        display: 'block',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                    }}
                >
                    Company Details
                </FormLabel>
                <Box sx={sectionStyles}>
                    <FormControl sx={formControlStyles}>
                        <FormLabel>Company Name</FormLabel>
                        <Input
                            placeholder="Enter company name"
                            value={companyData.name}
                            onChange={(e) => setCompanyData((prev) => ({ ...prev, name: e.target.value }))}
                        />
                    </FormControl>
                    <FormControl sx={formControlStyles}>
                        <FormLabel>MC Number</FormLabel>
                        <Input
                            placeholder="Enter MC number"
                            value={companyData.mcNumber}
                            onChange={(e) => setCompanyData((prev) => ({ ...prev, mcNumber: e.target.value }))}
                        />
                    </FormControl>
                </Box>

                <Box sx={sectionStyles}>
                    <FormControl sx={formControlStyles}>
                        <FormLabel>Company Address</FormLabel>
                        <Input
                            placeholder="Enter company address"
                            value={companyData.companyAddress}
                            onChange={(e) => setCompanyData((prev) => ({ ...prev, companyAddress: e.target.value }))}
                        />
                    </FormControl>
                    <FormControl sx={formControlStyles}>
                        <FormLabel>Phone Number</FormLabel>
                        <Input
                            placeholder="Enter phone number"
                            type="tel"
                            value={companyData.phoneNumber}
                            onChange={(e) => setCompanyData((prev) => ({ ...prev, phoneNumber: e.target.value }))}
                        />
                    </FormControl>
                </Box>

                <FormControl sx={{ width: '100%', mt: 2 }}>
                    <FormLabel>Additional Information</FormLabel>
                    <Textarea
                        minRows={6}
                        placeholder="Enter your additional information..."
                        value={companyData.details}
                        onChange={(e) => setCompanyData((prev) => ({ ...prev, details: e.target.value }))}
                        sx={{
                            width: '100%',
                            fontFamily: 'inherit',
                            minHeight: '200px',
                            borderRadius: '8px',
                        }}
                    />
                </FormControl>
            </Box>

            <Divider />

            {/* Rate Negotiation Section */}
            {rateSettings && <RateNegotiationTemp initialValues={rateSettings} onValueChange={handleRateSettingsChange} />}

            {/* Save Buttons Section */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2,
                }}
            >
                <Button variant="outlined" color="neutral" size="md" disabled={status.loading} onClick={handleCancel}>
                    Cancel
                </Button>
                <Button size="md" onClick={handleSaveCompany} loading={status.loading}>
                    Save Changes
                </Button>
            </Box>
        </Box>
    )
}
