/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Divider, Stack, Tooltip, Typography } from '@mui/joy'
import { Call, CallActionTypes, CallStatus, NumeoRestError, WsMessageCategory } from '@numeo/types'
import axios, { isAxiosError } from 'axios'
import { useWebSocketRedux } from 'hooks/useWebSocketRedux'
import { useCallCenter } from 'hooks/useCallCenter'
import { useEffect, useRef, useState } from 'react'

export const TranscriptionTooltip: React.FC<Props> = ({ callIds, children, isActive }) => {
    const [openTooltip, setOpenTooltip] = useState(false)
    const [callsModels, setCallsModels] = useState<Call[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const { calls } = useCallCenter()

    const getCalls = async (): Promise<void> => {
        try {
            setIsLoading(true)
            const response = await axios.post('trucks/load/calls', {
                callIds,
            })
            setCallsModels(response.data.data)
        } catch (error) {
            console.log('Error getting data ', error)
            if (isAxiosError(error)) {
                // Saluat to show a toast
                const numeoError = { message: error.message } as NumeoRestError
                setMessage(numeoError.message)
                // SHOW THE ERROR
            } else {
                // SHOW THE ERROR
                setMessage('Failed to get calls')
            }
        } finally {
            setTimeout(() => {
                setIsLoading(false)
            }, 2000)
        }
    }

    useEffect(() => {
        if (openTooltip) {
            if (!isActive) {
                getCalls()
            } else {
                setIsLoading(false)
                const call = calls.get(callIds[callIds.length - 1])
                if (call) {
                    setCallsModels((prev) => {
                        const updatedCalls = [...prev]
                        const index = updatedCalls.findIndex((c) => c.callId === call.callId)
                        if (index !== -1) {
                            updatedCalls[index] = call
                        } else {
                            updatedCalls.push(call)
                        }

                        return updatedCalls
                    })
                }
            }
        }
    }, [callIds, openTooltip, isActive])

    return (
        <Tooltip
            sx={{
                padding: 0.4,
                backgroundColor: 'gray.400',
                '& .MuiTooltip-arrow': {
                    color: 'var(--variant-solidBg, #0b6bcb);',
                },
            }}
            title={isLoading ? <CircularProgress /> : <TooltipContent callList={callsModels} isActive={isActive} />}
            placement="right-start"
            open={openTooltip}
            onOpen={() => setOpenTooltip(true)}
            onClose={() => setOpenTooltip(false)}
            arrow
        >
            <Box sx={{ cursor: 'pointer' }}>
                {message && <Typography>{message}</Typography>}
                {children}
            </Box>
        </Tooltip>
    )
}

const TooltipContent: React.FC<ToolTipContentProps> = ({ maxHeight = 500, callList, isActive }) => {
    const { sendMessageToService } = useWebSocketRedux()
    const transcriptsRef = useRef<HTMLDivElement>(null) // Create a ref for the transcripts container
    const [transcriptsEnabled, setTranscriptsEnabled] = useState(false)

    const updateTranscriptionNotificationSettings = (callId: string, enabled: boolean) => {
        if (isActive) {
            sendMessageToService({
                categoryType: WsMessageCategory.CallCenter,
                data: {
                    action: CallActionTypes.CC_UpdateCallSettings,
                    callId: callId,
                    settings: {
                        transcriptionNotificationsEnabled: enabled,
                    },
                },
            })
        }
    }

    useEffect(() => {
        // Identify the active call. The rest of the call should be in accordion and folded
        const lastCall = callList[callList.length - 1]
        if (lastCall?.status === CallStatus.INITIATING || lastCall?.status === CallStatus.CONNECTED) {
            // We found an active call. We request a live transcription
            setTranscriptsEnabled(true)
            // Scroll to the bottom of the container
            const container = transcriptsRef.current
            if (container) {
                // Scroll to the bottom of the container
                container.scrollTop = container.scrollHeight
            }
        }
    }, [callList])

    useEffect(() => {
        return () => {
            const lastCall = callList[callList.length - 1]
            updateTranscriptionNotificationSettings(lastCall?.callId, false)
        }
    }, [])

    useEffect(() => {
        updateTranscriptionNotificationSettings(callList[callList.length - 1]?.callId, transcriptsEnabled)
    }, [transcriptsEnabled])

    return (
        <Box
            ref={transcriptsRef}
            sx={{
                maxHeight: `${maxHeight}px`,
                overflowY: 'auto',
                width: '500px',
                backgroundColor: 'background.level1',
                borderRadius: '6px',
                // scrollbar styles...
            }}
        >
            {callList?.length ? (
                callList.map((call, index) => {
                    return (
                        <Stack key={index} direction={'column'}>
                            {call?.transcripts?.length ? (
                                call.transcripts.map((transcript, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            mb: 1,
                                            p: 1,
                                            borderRadius: 'md',
                                            display: 'flex',
                                            justifyContent: transcript.role === 'assistant' ? 'right' : 'left',
                                        }}
                                    >
                                        <Box sx={{ bgcolor: 'background.surface', px: 2, py: 1, borderRadius: 'md', width: '80%', boxShadow: 'sm' }}>
                                            <Typography level="body-sm">
                                                {Array.isArray(transcript.content) ? transcript.content.join(' ') : transcript.content}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))
                            ) : (
                                <Box
                                    sx={{
                                        mb: 1,
                                        p: 1,
                                        borderRadius: 'md',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box sx={{ bgcolor: 'background.surface', px: 2, py: 1, borderRadius: 'md', width: '80%', boxShadow: 'sm' }}>
                                        <Typography level="body-sm">Transcript is not available yet...</Typography>
                                    </Box>
                                </Box>
                            )}
                            {index < callList.length - 1 && <Divider sx={{ my: 1 }} />}
                        </Stack>
                    )
                })
            ) : (
                <Box
                    sx={{
                        mb: 1,
                        p: 1,
                        borderRadius: 'md',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Box sx={{ bgcolor: 'background.surface', px: 2, py: 1, borderRadius: 'md', width: '80%', boxShadow: 'sm' }}>
                        <Typography level="body-sm">Transcript is not available yet...</Typography>
                    </Box>
                </Box>
            )}
        </Box>
    )
}
interface ToolTipContentProps {
    maxHeight?: number
    callList: Call[]
    isActive: boolean
}

interface Props {
    callIds: string[]
    children: React.ReactNode
    isActive: boolean
}
