import { Button } from '@mui/joy'
import React from 'react'
import { HideSelectedLoadsButtonProps } from './types'

export const HideSelectedLoadsButton: React.FC<HideSelectedLoadsButtonProps> = ({ selectedLoads, onHideLoads }) => {
    return (
        <Button onClick={onHideLoads} variant="soft" color="neutral" disabled={selectedLoads.size === 0} sx={{ ml: 1 }}>
            Hide Selected Loads ({selectedLoads.size})
        </Button>
    )
}
