import React from 'react'
import { Box, Button } from '@mui/joy'
import CallEndIcon from '@mui/icons-material/CallEnd'
import MicOffIcon from '@mui/icons-material/MicOff'
import MicIcon from '@mui/icons-material/Mic'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { RingCentralCallSession } from 'types'

interface CallControlsProps {
    callSession: RingCentralCallSession
    isMuted: boolean
    isOnHold: boolean
    onToggleMute: () => void
    onToggleHold: () => void
    onHangup: () => void
}

const CallControls: React.FC<CallControlsProps> = ({ callSession, isMuted, isOnHold, onToggleMute, onToggleHold, onHangup }) => {
    return (
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', gap: 1 }}>
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-between' }}>
                <Button
                    disabled={callSession.state !== 'answered'}
                    color={isOnHold ? 'warning' : 'primary'}
                    variant="soft"
                    fullWidth
                    size="sm"
                    onClick={onToggleHold}
                    startDecorator={isOnHold ? <PlayArrowIcon /> : <PauseIcon />}
                >
                    {isOnHold ? 'Resume' : 'Hold'}
                </Button>
                <Button
                    disabled={callSession.state !== 'answered'}
                    color={isMuted ? 'warning' : 'primary'}
                    variant="soft"
                    fullWidth
                    size="sm"
                    onClick={onToggleMute}
                    startDecorator={isMuted ? <MicOffIcon /> : <MicIcon />}
                >
                    {isMuted ? 'Unmute' : 'Mute'}
                </Button>
            </Box>

            <Button disabled={callSession.state === 'disposed'} color="danger" variant="soft" size="sm" onClick={onHangup} startDecorator={<CallEndIcon />}>
                End Call
            </Button>
        </Box>
    )
}

export default CallControls
