import { Box, CircularProgress, Typography, Stack } from '@mui/joy'
import { useEffect, useState, useRef } from 'react'
// useTheme removed as it's not currently used
import { eldApi } from '../../spot-finder/api/eld-api'
import { useAuth } from 'hooks/auth'
import { TteldUnit, LucidEldVehicle, LucidEldVehicleStatus, SamsaraVehicleStatus } from '@numeo/types'
import { Wrapper } from '@googlemaps/react-wrapper'

// Combined truck type for the map
interface MapTruck {
    id: string
    type: 'tteld' | 'lucideld' | 'samsara'
    name: string
    vin: string
    latitude: string
    longitude: string
    timestamp: number
    fuelLevelPercent?: number
    speed?: number
    heading?: number
    connected: boolean
}

// Google Maps component that displays multiple truck markers
// Add types definitions we need for Google Maps
// We need to use a type assertion instead of interface extension since the open method signatures don't match

const TruckMapComponent = ({ trucks, selectedTruck }: { trucks: MapTruck[]; selectedTruck: MapTruck | null }) => {
    const mapRef = useRef<HTMLDivElement>(null)
    const markersRef = useRef<google.maps.Marker[]>([])
    const mapInstanceRef = useRef<google.maps.Map | null>(null)

    // Function to get marker icon color based on truck type
    const getTruckColor = (type: string) => {
        switch (type) {
            case 'tteld':
                return '#0b4ea2' // Blue
            case 'lucideld':
                return '#34A853' // Green
            case 'samsara':
                return '#F5B400' // Yellow
            default:
                return '#1976d2' // Default blue
        }
    }

    useEffect(() => {
        if (!mapRef.current || !window.google) return

        // Get access to Google Maps API
        const google = window.google

        // Create map if it doesn't exist
        if (!mapInstanceRef.current) {
            // Explicit type casting to avoid TypeScript errors
            // Some properties might not be in the TypeScript definitions but are valid in the Google Maps API
            const mapOptions = {
                zoom: 5,
                center: { lat: 39.8283, lng: -98.5795 }, // Default center (US)
                // Use valid control options
                disableDefaultUI: false,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                fullscreenControl: true,
                styles: [
                    {
                        featureType: 'poi',
                        elementType: 'labels',
                        stylers: [{ visibility: 'on' }],
                    },
                ],
                //eslint-disable-next-line
            } as any

            // Set center based on selected truck or first truck
            if (selectedTruck) {
                mapOptions.center = {
                    lat: parseFloat(selectedTruck.latitude),
                    lng: parseFloat(selectedTruck.longitude),
                }
            } else if (trucks.length > 0) {
                mapOptions.center = {
                    lat: parseFloat(trucks[0].latitude),
                    lng: parseFloat(trucks[0].longitude),
                }
            } else {
                // Default center if no trucks available
                mapOptions.center = { lat: 39.8283, lng: -98.5795 } // Center of US
            }

            // Create new map instance
            mapInstanceRef.current = new google.maps.Map(mapRef.current, mapOptions)
        }

        // Clear existing markers
        markersRef.current.forEach((marker) => marker.setMap(null))
        markersRef.current = []

        // Set bounds to include all trucks
        const bounds = new google.maps.LatLngBounds()
        let hasValidCoordinates = false

        // Add markers for all trucks
        trucks.forEach((truck) => {
            const lat = parseFloat(truck.latitude)
            const lng = parseFloat(truck.longitude)

            // Skip invalid coordinates
            if (isNaN(lat) || isNaN(lng) || (lat === 0 && lng === 0)) return

            const position = { lat, lng }
            bounds.extend(position)
            hasValidCoordinates = true

            // Create marker with truck-specific styling
            // We need to use type assertion because some properties might not be in the TypeScript definitions
            const marker = new google.maps.Marker({
                position,
                map: mapInstanceRef.current || undefined,
                title: truck.name,
                icon: {
                    path: 0,
                    zoomControl: true,
                    fillColor: getTruckColor(truck.type),
                    fillOpacity: 1,
                    scale: truck.id === selectedTruck?.id ? 10 : 8,
                    strokeColor: '#ffffff',
                    strokeWeight: 2,
                },
                zIndex: truck.id === selectedTruck?.id ? 1000 : 1,
                //eslint-disable-next-line
            } as any)

            // Create info window with truck details
            const infoContent = `
                <div style="min-width: 150px; padding: 5px;">
                    <strong>${truck.name}</strong><br>
                    Type: ${truck.type}<br>
                    ${truck.speed ? `Speed: ${truck.speed} mph<br>` : ''}
                    ${truck.fuelLevelPercent ? `Fuel: ${truck.fuelLevelPercent}%<br>` : ''}
                </div>
            `

            const infoWindow = new google.maps.InfoWindow({
                content: infoContent,
            })

            // Add click event to open info window
            marker.addListener('click', () => {
                // Close all other info windows (implementation omitted for brevity)
                // Use traditional open method instead of options object to avoid type errors
                infoWindow.open(mapInstanceRef.current || undefined, marker)
            })

            // Store marker reference
            markersRef.current.push(marker)
        })

        // Fit bounds if we have valid coordinates
        if (hasValidCoordinates && mapInstanceRef.current) {
            mapInstanceRef.current.fitBounds(bounds)

            // Don't zoom in too far on small areas
            // Using proper TypeScript approach with the Maps API
            const map = mapInstanceRef.current
            if (map) {
                // Google Maps API types might not match exactly with @types/googlemaps
                // Using any to bypass strict type checking for this specific call
                //eslint-disable-next-line
                const listener = (map as any).addListener('idle', function () {
                    if (mapInstanceRef.current) {
                        //eslint-disable-next-line
                        const zoom = (mapInstanceRef.current as any).getZoom()
                        if (zoom !== undefined && zoom > 15) {
                            mapInstanceRef.current.setZoom(15)
                        }
                        // Remove listener after first execution
                        //eslint-disable-next-line
                        ;(window.google.maps as any).event.removeListener(listener)
                    }
                })
            }
        }

        // If selected truck exists, center on it
        if (selectedTruck && mapInstanceRef.current) {
            const selectedLat = parseFloat(selectedTruck.latitude)
            const selectedLng = parseFloat(selectedTruck.longitude)

            if (!isNaN(selectedLat) && !isNaN(selectedLng) && !(selectedLat === 0 && selectedLng === 0)) {
                mapInstanceRef.current.setCenter({ lat: selectedLat, lng: selectedLng })
                mapInstanceRef.current.setZoom(12) // Set zoom level when focusing on selected truck
            }
        }
    }, [trucks, selectedTruck])

    return <div ref={mapRef} style={{ width: '100%', height: '100%' }} />
}

// Error component to display when the map fails to load
const MapError = () => (
    <Box
        sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'background.level1',
            color: 'text.secondary',
            flexDirection: 'column',
            gap: 1,
        }}
    >
        <Box>Map unavailable</Box>
    </Box>
)

// Main TruckMap component
export default function TruckMap() {
    const { application } = useAuth()
    const [trucks, setTrucks] = useState<MapTruck[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string | null>(null)
    const [connectedCount, setConnectedCount] = useState<number>(0)
    const [tteldConnectedCount, setTteldConnectedCount] = useState<number>(0)
    const [lucidEldConnectedCount, setLucidEldConnectedCount] = useState<number>(0)
    const [samsaraConnectedCount, setSamsaraConnectedCount] = useState<number>(0)
    const [selectedTruck, setSelectedTruck] = useState<MapTruck | null>(null)

    useEffect(() => {
        const fetchAllTrucks = async () => {
            try {
                setLoading(true)

                // Prepare fetch promises based on which integrations are enabled
                //eslint-disable-next-line
                const fetchPromises: Promise<any>[] = []
                let tteldData: TteldUnit[] = []
                let lucidEldData: LucidEldVehicle[] = []
                let samsaraData: SamsaraVehicleStatus[] = []

                // Only fetch TT-ELD data if the integration exists
                if (application?.integrations?.tteld) {
                    fetchPromises.push(
                        eldApi.getAllTteldUnits().then((data) => {
                            tteldData = data
                            return data
                        })
                    )
                }

                // Only fetch Lucid ELD data if the integration exists
                if (application?.integrations?.lucidEld) {
                    fetchPromises.push(
                        eldApi.getAllLucidEldTrucks().then((data) => {
                            lucidEldData = data
                            return data
                        })
                    )
                }

                // Only fetch Samsara data if the integration exists
                if (application?.integrations?.samsara) {
                    fetchPromises.push(
                        eldApi.getSamsaraVehicleStatus().then((data) => {
                            samsaraData = data
                            return data
                        })
                    )
                }

                // Wait for all enabled fetch operations to complete
                await Promise.all(fetchPromises)

                // Fetch status for each Lucid ELD truck to get location data
                const lucidEldStatusPromises = lucidEldData.map((vehicle) => eldApi.getLucidEldTruckStatus(vehicle.number))
                const lucidEldStatusResults = await Promise.all(lucidEldStatusPromises)

                // Create a map of truck number to status for quick lookup
                const lucidEldStatusMap = new Map<string, LucidEldVehicleStatus>()
                lucidEldStatusResults.forEach((status) => {
                    if (status) {
                        lucidEldStatusMap.set(status.number, status)
                    }
                })

                // Process TT-ELD trucks
                const tteldTrucks: MapTruck[] = tteldData.map((truck) => ({
                    id: `tteld-${truck.vin}`,
                    type: 'tteld' as const,
                    name: `Truck #${truck.truck_number}`,
                    vin: truck.vin,
                    latitude: truck.coordinates ? String(truck.coordinates.lat) : '0',
                    longitude: truck.coordinates ? String(truck.coordinates.lng) : '0',
                    timestamp: new Date(truck.timestamp).getTime(),
                    fuelLevelPercent: truck.fuelLevelPercent,
                    connected: !!truck.coordinates,
                }))

                // Process Lucid ELD trucks
                const lucidEldTrucks: MapTruck[] = lucidEldData.map((vehicle) => {
                    // Get status for this vehicle if available
                    const status = lucidEldStatusMap.get(vehicle.number)
                    const hasValidCoordinates = status && status.lat && status.lon

                    return {
                        id: `lucideld-${vehicle.VIN}`,
                        type: 'lucideld' as const,
                        name: vehicle.number || `Truck ${vehicle.VIN.slice(-6)}`,
                        vin: vehicle.VIN,
                        latitude: hasValidCoordinates ? status.lat : '0',
                        longitude: hasValidCoordinates ? status.lon : '0',
                        timestamp: status ? new Date(status.timestamp).getTime() : Date.now(),
                        speed: status?.speed || undefined,
                        connected: !!hasValidCoordinates,
                    }
                })

                // Process Samsara trucks
                const samsaraTrucks: MapTruck[] = (samsaraData || []).map((vehicle) => {
                    // Add proper null checks for all properties
                    const hasGpsData = vehicle && vehicle.gps && Array.isArray(vehicle.gps) && vehicle.gps.length > 0
                    const hasValidCoordinates = hasGpsData && typeof vehicle.gps[0].latitude === 'number' && typeof vehicle.gps[0].longitude === 'number'

                    // Get safe values with fallbacks
                    const vehicleId = vehicle?.id || 'unknown'
                    const vehicleName = vehicle?.name || `Vehicle ${vehicleId}`
                    const vin = (vehicle?.externalIds && vehicle.externalIds['samsara.vin']) || vehicleId

                    // Safely access GPS data
                    const latitude = hasValidCoordinates ? String(vehicle.gps[0].latitude) : '0'
                    const longitude = hasValidCoordinates ? String(vehicle.gps[0].longitude) : '0'
                    const timestamp = hasGpsData && vehicle.gps[0].time ? new Date(vehicle.gps[0].time).getTime() : Date.now()
                    const speed = hasGpsData ? vehicle.gps[0].speedMilesPerHour : undefined

                    return {
                        id: `samsara-${vehicleId}`,
                        type: 'samsara' as const,
                        name: vehicleName,
                        vin: vin,
                        latitude: latitude,
                        longitude: longitude,
                        timestamp: timestamp,
                        speed: speed,
                        connected: !!hasValidCoordinates,
                    }
                })

                // Combine all trucks and filter only connected ones
                const allTrucks = [...tteldTrucks, ...lucidEldTrucks, ...samsaraTrucks].filter((truck) => truck.connected)

                // Count connected trucks by type
                const connectedTteldTrucks = tteldTrucks.filter((truck) => truck.connected)
                const connectedLucidEldTrucks = lucidEldTrucks.filter((truck) => truck.connected)
                const connectedSamsaraTrucks = samsaraTrucks.filter((truck) => truck.connected)

                setTrucks(allTrucks)
                setConnectedCount(allTrucks.length)
                setTteldConnectedCount(connectedTteldTrucks.length)
                setLucidEldConnectedCount(connectedLucidEldTrucks.length)
                setSamsaraConnectedCount(connectedSamsaraTrucks.length)
                setError(null)

                // Set first truck as selected by default if available
                if (allTrucks.length > 0 && !selectedTruck) {
                    setSelectedTruck(allTrucks[0])
                }

                // If no trucks were selected but we had one before, and it's still in the new list, keep it selected
                if (selectedTruck && !allTrucks.find((t) => t.id === selectedTruck.id)) {
                    setSelectedTruck(allTrucks.length > 0 ? allTrucks[0] : null)
                }
            } catch (err) {
                setError(err instanceof Error ? err.message : 'An error occurred while fetching truck data')
            } finally {
                setLoading(false)
            }
        }

        fetchAllTrucks()

        const interval = setInterval(
            () => {
                fetchAllTrucks()
            },
            1000 * 60 * 5
        ) // Refresh every 5 minutes

        return () => clearInterval(interval)
        // eslint-disable-next-line
    }, [])

    // Only show the truck map if at least one ELD integration is enabled
    if (!application?.integrations?.tteld && !application?.integrations?.lucidEld && !application?.integrations?.samsara) {
        return null
    }

    return (
        <Box
            sx={{
                width: '100%',
                mx: 'auto',
                mb: 1,
                p: 1,
                borderRadius: '20px',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 3 }}>
                <Typography level="h4" component="h2">
                    Truck Locations
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 0.5 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: 10, height: 10, borderRadius: '50%', bgcolor: '#0b4ea2', mr: 0.5 }} />
                            <Typography level="body-xs">TT-ELD: {tteldConnectedCount}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: 10, height: 10, borderRadius: '50%', bgcolor: '#34A853', mr: 0.5 }} />
                            <Typography level="body-xs">Lucid ELD: {lucidEldConnectedCount}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: 10, height: 10, borderRadius: '50%', bgcolor: '#F5B400', mr: 0.5 }} />
                            <Typography level="body-xs">Samsara: {samsaraConnectedCount}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: 10, height: 10, borderRadius: '50%', mr: 0.5 }} />
                            <Typography level="body-xs">{connectedCount} trucks connected</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '650px' }}>
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '650px' }}>
                    <Typography color="danger">{error}</Typography>
                </Box>
            ) : (
                <Stack direction="row" spacing={2} sx={{ height: '93vh', width: '100%' }}>
                    {/* Map area */}
                    <Box sx={{ flexGrow: 1, height: '100%', borderRadius: '10px', overflow: 'hidden' }}>
                        {trucks.length > 0 ? (
                            <Box sx={{ width: '100%', height: '100%' }}>
                                <WrappedMap trucks={trucks} selectedTruck={selectedTruck} />
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <Typography>No trucks available to display on map</Typography>
                            </Box>
                        )}
                    </Box>
                </Stack>
            )}
        </Box>
    )
}

// Wrapped map component with Google Maps API loader
const WrappedMap = ({ trucks, selectedTruck }: { trucks: MapTruck[]; selectedTruck: MapTruck | null }) => {
    return (
        <Wrapper
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
            render={(status) => {
                return status === 'SUCCESS' ? <TruckMapComponent trucks={trucks} selectedTruck={selectedTruck} /> : <MapError />
            }}
        />
    )
}
