import { FormControl, FormLabel, Input, Stack, Box, useTheme, Typography, Select, Option } from '@mui/joy'
import { Truck } from '@numeo/types'
import { FormikProps, useFormik } from 'formik'
import { useEffect } from 'react'
import * as Yup from 'yup'

interface DriverInformationProps {
    truckData: Truck
    onUpdate: (field: string, value: string | number | null | boolean) => void
    formRef: React.MutableRefObject<FormikProps<TruckDriverInfo>>
}

export interface TruckDriverInfo {
    firstDriver: {
        name?: string
        phone?: string
        isUSCitizen: boolean
        hometown?: string
        driverEmail?: string
    }
    secondDriver: {
        name?: string
        phone?: string
        isUSCitizen: boolean
        hometown?: string
        driverEmail?: string
    }
    teamSolo?: 'TEAM' | 'SOLO'
}

const validationSchema = Yup.object().shape({
    firstDriver: Yup.object().shape({
        name: Yup.string().nullable(),
        phone: Yup.string()
            .nullable()
            .test('phone', 'Phone number must be 10 digits', (value) => {
                if (!value) return true
                return value.replace(/\D/g, '').length === 10
            }),
        isUSCitizen: Yup.boolean(),
        hometown: Yup.string().nullable(),
        driverEmail: Yup.string().nullable(),
    }),
    secondDriver: Yup.object().shape({
        name: Yup.string().nullable(),
        phone: Yup.string()
            .nullable()
            .test('phone', 'Phone number must be 10 digits', (value) => {
                if (!value) return true
                return value.replace(/\D/g, '').length === 10
            }),
        isUSCitizen: Yup.boolean(),
        hometown: Yup.string().nullable(),
        driverEmail: Yup.string().nullable(),
    }),
})

const DriverInformation: React.FC<DriverInformationProps> = ({ truckData, onUpdate, formRef }) => {
    const theme = useTheme()

    const formik = useFormik<TruckDriverInfo>({
        initialValues: {
            firstDriver: {
                name: truckData.firstDriver?.name || '',
                phone: truckData.firstDriver?.phone || '',
                isUSCitizen: truckData.firstDriver?.isUSCitizen || false,
                hometown: truckData.firstDriver?.hometown || '',
                driverEmail: truckData.firstDriver?.driverEmail || '',
            },
            secondDriver: {
                name: truckData.secondDriver?.name || '',
                phone: truckData.secondDriver?.phone || '',
                isUSCitizen: truckData.secondDriver?.isUSCitizen || false,
                hometown: truckData.secondDriver?.hometown || '',
                driverEmail: truckData.secondDriver?.driverEmail || '',
            },
        },
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: () => {},
    })

    // Assign formik instance to ref
    useEffect(() => {
        if (formRef) {
            formRef.current = formik
        }
    }, [formik, formRef])

    const formatPhoneNumber = (value: string) => {
        const numbers = value.replace(/\D/g, '')
        if (numbers.length === 0) return ''
        const areaCode = numbers.slice(0, 3)
        const middle = numbers.slice(3, 6)
        const last = numbers.slice(6, 10)
        if (numbers.length < 4) return numbers
        if (numbers.length < 7) return `(${areaCode}) ${middle}`
        return `(${areaCode}) ${middle}-${last}`
    }

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        let value = e.target.value.replace(/\D/g, '')
        value = value.slice(0, 10)
        formik.setFieldValue(field, value)
        onUpdate(field, value)
    }

    const handlePhoneFocus = (e: React.FocusEvent<HTMLInputElement>, field: string) => {
        const value = e.target.value.replace(/\D/g, '')
        formik.setFieldValue(field, value)
        onUpdate(field, value)
    }

    const handlePhoneBlur = (e: React.FocusEvent<HTMLInputElement>, field: string) => {
        const value = e.target.value.replace(/\D/g, '')
        if (value.length > 0) {
            const formattedValue = formatPhoneNumber(value)
            formik.setFieldValue(field, formattedValue)
            onUpdate(field, formattedValue)
        }
        formik.handleBlur(e)
    }

    return (
        <Box
            p={3}
            sx={{
                color: theme.palette.text.primary,
                borderRadius: 'md',
            }}
        >
            <Stack spacing={2} direction={{ sm: 'column', md: 'row' }}>
                {/* First Driver Section */}
                <Stack spacing={2} direction={'column'} width={'100%'}>
                    <FormLabel>
                        <Typography fontWeight={'lg'}>First Driver</Typography>
                    </FormLabel>

                    <FormControl sx={{ width: '100%' }} error={!!formik.errors.firstDriver?.name}>
                        <FormLabel>Name</FormLabel>
                        <Input
                            value={formik.values.firstDriver?.name}
                            onChange={(e) => {
                                formik.handleChange(e)
                                onUpdate('firstDriver.name', e.target.value)
                            }}
                            onBlur={formik.handleBlur}
                            name="firstDriver.name"
                        />
                        {formik.touched.firstDriver?.name && formik.errors.firstDriver?.name && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.firstDriver.name}</Box>
                        )}
                    </FormControl>

                    <FormControl sx={{ width: '100%' }} error={!!formik.errors.firstDriver?.phone}>
                        <FormLabel>Phone</FormLabel>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                sx={{
                                    mr: 0.5,
                                    color: 'text.secondary',
                                    userSelect: 'none',
                                    position: 'absolute',
                                    left: '12px',
                                    zIndex: 1,
                                }}
                            >
                                +1
                            </Typography>
                            <Input
                                value={formik.values.firstDriver.phone}
                                onChange={(e) => handlePhoneChange(e, 'firstDriver.phone')}
                                onFocus={(e) => handlePhoneFocus(e, 'firstDriver.phone')}
                                onBlur={(e) => handlePhoneBlur(e, 'firstDriver.phone')}
                                name="firstDriver.phone"
                                sx={{
                                    '& input': {
                                        paddingLeft: '28px',
                                    },
                                    width: '100%',
                                }}
                            />
                        </Box>
                        {formik.touched.firstDriver?.phone && formik.errors.firstDriver?.phone && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.firstDriver.phone}</Box>
                        )}
                    </FormControl>

                    <FormControl sx={{ flex: 1 }} error={!!formik.errors.firstDriver?.hometown}>
                        <FormLabel>Hometown</FormLabel>
                        <Input
                            value={formik.values.firstDriver?.hometown}
                            onChange={(e) => {
                                formik.handleChange(e)
                                onUpdate('firstDriver.hometown', e.target.value)
                            }}
                            onBlur={formik.handleBlur}
                            name="firstDriver.hometown"
                        />
                        {formik.touched.firstDriver?.hometown && formik.errors.firstDriver?.hometown && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.firstDriver.hometown}</Box>
                        )}
                    </FormControl>

                    <FormControl sx={{ flex: 1 }} error={!!formik.errors.firstDriver?.driverEmail}>
                        <FormLabel>Driver Email</FormLabel>
                        <Input
                            value={formik.values.firstDriver?.driverEmail}
                            onChange={(e) => {
                                formik.handleChange(e)
                                onUpdate('firstDriver.driverEmail', e.target.value)
                            }}
                            onBlur={formik.handleBlur}
                            name="firstDriver.driverEmail"
                        />
                        {formik.touched.firstDriver?.driverEmail && formik.errors.firstDriver?.driverEmail && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.firstDriver.driverEmail}</Box>
                        )}
                    </FormControl>

                    {/* now we don't use this code, but leave it for future */}

                    {/* <Stack direction="row" alignItems="center" gap={1} sx={{ whiteSpace: 'nowrap' }}>
                        <FormLabel sx={{ display: 'flex', alignItems: 'center' }}>US Citizen</FormLabel>
                        <Switch checked={truckData.firstDriver?.isUSCitizen || false} onChange={(e) => onUpdate('firstDriver.isUSCitizen', e.target.checked)} />
                    </Stack> */}
                </Stack>

                {/* Second Driver Section */}
                <Stack spacing={2} direction={'column'} width={'100%'}>
                    <FormLabel>
                        <Typography fontWeight={'lg'}>Second Driver</Typography>
                    </FormLabel>

                    <FormControl sx={{ width: '100%' }} error={!!formik.errors.secondDriver?.name}>
                        <FormLabel>Name</FormLabel>
                        <Input
                            value={formik.values.secondDriver?.name}
                            onChange={(e) => {
                                formik.handleChange(e)
                                onUpdate('secondDriver.name', e.target.value)
                            }}
                            onBlur={formik.handleBlur}
                            name="secondDriver.name"
                        />
                        {formik.touched.secondDriver?.name && formik.errors.secondDriver?.name && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.secondDriver.name}</Box>
                        )}
                    </FormControl>

                    <FormControl sx={{ width: '100%' }} error={!!formik.errors.secondDriver?.phone}>
                        <FormLabel>Phone</FormLabel>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                sx={{
                                    mr: 0.5,
                                    color: 'text.secondary',
                                    userSelect: 'none',
                                    position: 'absolute',
                                    left: '12px',
                                    zIndex: 1,
                                }}
                            >
                                +1
                            </Typography>
                            <Input
                                value={formik.values.secondDriver?.phone}
                                onChange={(e) => handlePhoneChange(e, 'secondDriver.phone')}
                                onFocus={(e) => handlePhoneFocus(e, 'secondDriver.phone')}
                                onBlur={(e) => handlePhoneBlur(e, 'secondDriver.phone')}
                                name="secondDriver.phone"
                                sx={{
                                    '& input': {
                                        paddingLeft: '28px',
                                    },
                                    width: '100%',
                                }}
                            />
                        </Box>
                        {formik.touched.secondDriver?.phone && formik.errors.secondDriver?.phone && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.secondDriver.phone}</Box>
                        )}
                    </FormControl>

                    <FormControl sx={{ flex: 1 }} error={!!formik.errors.secondDriver?.hometown}>
                        <FormLabel>Hometown</FormLabel>
                        <Input
                            value={formik.values.secondDriver?.hometown}
                            onChange={(e) => {
                                formik.handleChange(e)
                                onUpdate('secondDriver.hometown', e.target.value)
                            }}
                            onBlur={formik.handleBlur}
                            name="secondDriver.hometown"
                        />
                        {formik.touched.secondDriver?.hometown && formik.errors.secondDriver?.hometown && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.secondDriver.hometown}</Box>
                        )}
                    </FormControl>

                    <FormControl sx={{ flex: 1 }} error={!!formik.errors.secondDriver?.driverEmail}>
                        <FormLabel>Driver Email</FormLabel>
                        <Input
                            value={formik.values.secondDriver?.driverEmail}
                            onChange={(e) => {
                                formik.handleChange(e)
                                onUpdate('secondDriver.driverEmail', e.target.value)
                            }}
                            onBlur={formik.handleBlur}
                            name="secondDriver.driverEmail"
                        />
                        {formik.touched.secondDriver?.driverEmail && formik.errors.secondDriver?.driverEmail && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.secondDriver.driverEmail}</Box>
                        )}
                    </FormControl>

                    {/* now we don't use this code, but leave it for future */}

                    {/* <Stack direction="row" alignItems="center" gap={1} sx={{ whiteSpace: 'nowrap' }}>
                        <FormLabel sx={{ display: 'flex', alignItems: 'center' }}>US Citizen</FormLabel>
                        <Switch
                            checked={truckData.secondDriver?.isUSCitizen || false}
                            onChange={(e) => onUpdate('secondDriver.isUSCitizen', e.target.checked)}
                        />
                    </Stack> */}
                </Stack>
            </Stack>
            <FormControl sx={{ width: '100%', mt: 2 }}>
                <FormLabel>Team/Solo</FormLabel>
                <Select value={truckData.teamSolo || ''} onChange={(_, value) => onUpdate('teamSolo', value)}>
                    <Option value="TEAM">Team</Option>
                    <Option value="SOLO">Solo</Option>
                </Select>
            </FormControl>
        </Box>
    )
}

export default DriverInformation
