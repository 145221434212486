import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip } from '@mui/joy'
import React, { memo, useEffect, useState } from 'react'
import SelectAllCheckbox from './SelectAllCheckbox'
import { SortingPopover } from './SortingPopover'
import { SortState, TableHeaderProps } from './types'

/**
 * TableHeader component manages the table column headers and sorting functionality.
 * Features:
 * - Column sorting
 * - Bulk selection
 * - Tooltips for column descriptions
 */
export const TableHeader = memo<TableHeaderProps>(
    ({ selectedLoads, totalLoads, onSelectAll, onSortChange }) => {
        const [screenLg, setScreenLg] = useState(window.innerWidth > 1400)
        const [sorting, setSorting] = useState<SortState>({
            activeColumn: null,
            configs: {},
        })
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

        useEffect(() => {
            const handleResize = () => {
                setScreenLg(window.innerWidth > 1400)
            }

            // Add event listener
            window.addEventListener('resize', handleResize)

            // Clean up event listener
            return () => {
                window.removeEventListener('resize', handleResize)
            }
        }, [])

        const getIconStyle = (option: string, column?: string) => ({
            transform: sorting.configs[column || '']?.key === option ? 'rotate(180deg)' : 'rotate(0deg)',
            color: sorting.configs[column || '']?.key === option ? '#1976d2' : '#757575',
            verticalAlign: 'middle',
            marginLeft: '-5px',
        })

        const handleHeaderClick = (event: React.MouseEvent<HTMLElement>, columnName: string) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setSorting((prev) => ({
                ...prev,
                activeColumn: columnName,
            }))
        }

        const handleSortOptionChange = (sortOption: string) => {
            if (sorting.activeColumn) {
                setSorting((prev) => ({
                    ...prev,
                    configs: {
                        ...prev.configs,
                        [sorting.activeColumn!]: {
                            key: sortOption,
                            direction: prev.configs[sorting.activeColumn!]?.direction === 'asc' ? 'desc' : 'asc',
                        },
                    },
                }))
                onSortChange(sortOption) // Signal parent
            }
            setSorting((prev) => ({
                ...prev,
                activeColumn: null,
            }))
            setAnchorEl(null) // Close the popover
        }

        // Define an array of column configurations
        const columns = [
            { title: 'Source', tooltip: 'Source of the load', width: { xs: 100, lg: 50 }, align: 'center' },
            { title: 'Age', tooltip: 'Age of the load posting', width: { xs: 100, lg: 50 }, align: 'center', sortKey: 'age' },
            { title: 'Favorites', tooltip: 'Load is favorited by the user', width: { xs: 100, lg: 30 }, align: 'center' },
            { title: 'Rate', tooltip: 'Trip rate', width: { xs: 100, lg: 60 }, align: 'center', sortKey: 'rate' },
            { title: 'Engage', tooltip: 'Engage with either email or call', width: { xs: 100, lg: 80 }, align: 'center' },
            { title: 'Warnings', tooltip: 'Warnings from email', width: { xs: 100, lg: 50 }, align: 'center' },
            {
                title: 'Factorable',
                tooltip: 'Load is eligible for factoring',
                width: { xs: 100, lg: 30 },
                sortKey: 'factorable',
                icon: <InfoOutlinedIcon sx={{ fontSize: 20 }} />,
            },
            { title: 'Trip', tooltip: 'Trip distance', width: { xs: 100, lg: 50 }, align: 'center', sortKey: 'trip' },
            { title: 'Origin', tooltip: 'Origin city and state', width: { xs: 100, lg: 110 }, align: 'center', sortKey: 'origin' },
            { title: 'DH-O', tooltip: 'Origin deadhead miles', width: { xs: 100, lg: 60 }, align: 'center', sortKey: 'deadheadO' },
            { title: '', tooltip: 'Direction', width: { xs: 100, lg: 30 }, align: 'center' },
            { title: 'Destination', tooltip: 'Destination city and state', width: { xs: 100, lg: 110 }, align: 'center', sortKey: 'destination' },
            { title: 'DH-D', tooltip: 'Destination deadhead miles', width: { xs: 100, lg: 60 }, align: 'center', sortKey: 'deadheadD' },
            { title: 'Pick up', tooltip: 'Pick up dates', width: { xs: 100, lg: 70 }, align: 'center', sortKey: 'pickUp' },
            { title: 'EQ', tooltip: 'Equipment required', width: { xs: 100, lg: 40 }, align: 'center' },
            { title: 'Length', tooltip: 'Trip length', width: { xs: 100, lg: 70 }, align: 'center', sortKey: 'length' },
            { title: 'Weight', tooltip: 'Load weight', width: { xs: 100, lg: 80 }, align: 'center', sortKey: 'weight' },
            { title: 'Capacity', tooltip: 'Truck capacity required', width: { xs: 100, lg: 80 }, align: 'center', sortKey: 'capacity' },
            { title: 'RC', tooltip: 'Rate confirmations made with broker', width: { xs: 50, lg: 30 }, align: 'center', sortKey: 'rateConfirmations' }, // ADD RATE CONFIRMATION COLUMN
            { title: 'Broker', tooltip: 'Favorite broker', width: { xs: 50, lg: 30 }, align: 'center', sortKey: 'broker' },
            { title: 'Company', tooltip: 'Company information', width: { xs: 100, lg: 120 }, align: 'center', sortKey: 'company' },
            { title: 'Contact', tooltip: 'Contact information', width: { xs: 100, lg: 140 }, align: 'center', sortKey: 'contact' },
            { title: 'CS/DTP', tooltip: 'Credit score and days to pay', width: { xs: 100, lg: 75 }, align: 'center', sortKey: 'csDtp' },
            { title: 'Book', tooltip: 'Bookable status', width: { xs: 100, lg: 75 }, align: 'center', sortKey: 'bookable' },
        ]
        // Function to generate a table header cell
        const renderTableHeaderCell = (column, index) => (
            <th
                key={index}
                onClick={(e) => column.sortKey && handleHeaderClick(e, column.sortKey)}
                style={{
                    width: screenLg ? column.width.lg : column.width.xs,
                    textAlign: column.align || 'left',
                    cursor: column.sortKey ? 'pointer' : 'default',
                }}
            >
                <Tooltip title={column.tooltip} variant="soft">
                    <span>
                        {column.title}
                        {column.sortKey && !column.icon && <ArrowDropDownIcon style={getIconStyle(column.sortKey, column.sortKey)} fontSize="medium" />}
                    </span>
                </Tooltip>
                {sorting.activeColumn === column.sortKey && (
                    <SortingPopover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        activeColumn={sorting.activeColumn}
                        onClose={() => setAnchorEl(null)}
                        onSortOptionChange={handleSortOptionChange}
                        selectedSortKey={sorting.configs[sorting.activeColumn!]?.key}
                    />
                )}
            </th>
        )

        return (
            <tr>
                <th style={{ width: 30, textAlign: 'center', padding: '12px 6px' }}>
                    <SelectAllCheckbox
                        isAllSelected={selectedLoads.size === totalLoads}
                        isIndeterminate={selectedLoads.size > 0 && selectedLoads.size < totalLoads}
                        onToggleSelectAll={onSelectAll}
                    />
                </th>
                {columns.map((column, index) => renderTableHeaderCell(column, index))}
            </tr>
        )
    },
    (prevProps, nextProps) => {
        // Custom comparison function for memoization
        return (
            prevProps.selectedLoads === nextProps.selectedLoads &&
            prevProps.totalLoads === nextProps.totalLoads &&
            prevProps.onSelectAll === nextProps.onSelectAll &&
            prevProps.onSortChange === nextProps.onSortChange
        )
    }
)
