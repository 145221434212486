interface StorageOptions {
    dbName: string
    storeName: string
    version?: number
}

export class IndexedDBStorage {
    private dbPromise: Promise<IDBDatabase>

    constructor(private options: StorageOptions) {
        this.dbPromise = this.initDB()
    }

    private async initDB(): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.options.dbName, this.options.version)

            request.onupgradeneeded = () => {
                const db = request.result
                if (!db.objectStoreNames.contains(this.options.storeName)) {
                    db.createObjectStore(this.options.storeName, { keyPath: 'id' })
                }
            }

            request.onsuccess = () => resolve(request.result)
            request.onerror = () => reject(new Error(`DB open failed: ${request.error}`))
        })
    }

    async get<T>(key: string): Promise<T | undefined> {
        try {
            const db = await this.dbPromise
            return new Promise((resolve, reject) => {
                const transaction = db.transaction(this.options.storeName, 'readonly')
                const store = transaction.objectStore(this.options.storeName)
                const request = store.get(key)
                request.onsuccess = () => resolve(request.result?.value)
                request.onerror = () => reject(new Error(`Get operation failed: ${request.error}`))
            })
        } catch (error) {
            console.error('IndexedDB get error:', error)
            throw new Error('Failed to retrieve data from storage')
        }
    }

    async set(key: string, value: unknown): Promise<void> {
        try {
            const db = await this.dbPromise
            return new Promise((resolve, reject) => {
                const transaction = db.transaction(this.options.storeName, 'readwrite')
                const store = transaction.objectStore(this.options.storeName)
                const request = store.put({ id: key, value })
                request.onsuccess = () => resolve()
                request.onerror = () => reject(new Error(`Set operation failed: ${request.error}`))
            })
        } catch (error) {
            console.error('IndexedDB set error:', error)
            throw new Error('Failed to store data')
        }
    }

    async clear(): Promise<void> {
        try {
            const db = await this.dbPromise
            return new Promise((resolve, reject) => {
                const transaction = db.transaction(this.options.storeName, 'readwrite')
                const store = transaction.objectStore(this.options.storeName)
                const request = store.clear()
                request.onsuccess = () => resolve()
                request.onerror = () => reject(new Error(`Clear operation failed: ${request.error}`))
            })
        } catch (error) {
            console.error('IndexedDB clear error:', error)
            throw new Error('Failed to clear storage')
        }
    }
}
