import { WebSocketStatus } from '@numeo/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INotification } from '../hooks/useNotification'

export interface NotificationState {
    notifications: INotification[]
    wsStatus: WebSocketStatus
}

const initialState: NotificationState = {
    notifications: [],
    wsStatus: WebSocketStatus.DISCONNECTED,
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setWsStatus(state, action: PayloadAction<WebSocketStatus>) {
            state.wsStatus = action.payload
        },
        addNotification(state, action: PayloadAction<INotification>) {
            state.notifications.push(action.payload)
        },
        upsertNotification(state, action: PayloadAction<INotification>) {
            const index = state.notifications.findIndex((notification) => notification.id === action.payload.id)
            if (index !== -1) {
                state.notifications[index] = action.payload
            } else {
                state.notifications.push(action.payload)
            }
        },
        deleteNotification(state, action: PayloadAction<string>) {
            state.notifications = state.notifications.filter((notification) => notification.id !== action.payload)
        },
        clearAll(state) {
            state.notifications = []
        },
    },
})

export const { addNotification, upsertNotification, deleteNotification, clearAll, setWsStatus } = notificationSlice.actions
export default notificationSlice.reducer
