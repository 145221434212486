import { Search, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Button, Stack, Typography, CircularProgress, Card, CardContent, Box, Chip, Divider } from '@mui/joy'
import { LocationSearch } from 'components/LocationSearch/LocationSearch'
import { CityLocationData } from 'utils/location-search/LocationDataSearchV2'
import axios from 'axios'
import React, { useState } from 'react'
import { SearchBrokerResult } from '@numeo/types'
import { calculateRatePerMile } from '../../spot-finder/components/assets/helpers'

export const SearchTab = () => {
    const [selectedLocation, setSelectedLocation] = useState<CityLocationData | null>(null)
    const [searchResults, setSearchResults] = useState<SearchBrokerResult[] | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [expandedBrokers, setExpandedBrokers] = useState<Record<string, boolean>>({})

    // Preserve the selected location when it changes
    const handleLocationSelect = (location: CityLocationData) => {
        setSelectedLocation(location)
    }

    // Toggle expanded state for a broker
    const toggleBrokerExpanded = (brokerId: string) => {
        setExpandedBrokers((prev) => ({
            ...prev,
            [brokerId]: !prev[brokerId],
        }))
    }

    const handleSearch = async () => {
        if (!selectedLocation) {
            setError('Please select a location first')
            return
        }

        setLoading(true)
        setError(null)

        try {
            // Build the search parameters
            const params: Record<string, string | number> = {
                city: selectedLocation.city,
                state: selectedLocation.stateCode,
                latitude: selectedLocation.latitude,
                longitude: selectedLocation.longitude,
            }

            const response = await axios.get('/my-brokers/search', { params })

            if (response.data.success) {
                setSearchResults(response.data.data)
            } else {
                setError('Search failed: ' + (response.data.message || 'Unknown error'))
                setSearchResults([])
            }
        } catch (err) {
            setError('Failed to search brokers. Please try again.')
            setSearchResults([])
        } finally {
            setLoading(false)
        }
    }

    const kmToMiles = (km: number): number => km * 0.621371

    // Format distance for display
    const formatDistance = (distance?: number | null): string => {
        if (!distance) return ''

        // Round to 1 decimal place
        const roundedDistance = Math.round(distance * 10) / 10

        if (roundedDistance < 1) {
            return 'Less than 1 mile'
        } else if (roundedDistance >= 1000) {
            return '1000+ miles'
        } else {
            return `${roundedDistance} miles`
        }
    }

    // Calculate average rate per mile across all rate confirmations for a broker
    const calculateAverageRatePerMile = (rateConfs: SearchBrokerResult['rateConfs']): string => {
        // Filter out rate confirmations with invalid distance
        const validRateConfs = rateConfs.filter((conf) => conf.totalDistance && conf.totalDistance > 0 && conf.rate && conf.rate.amount)

        if (validRateConfs.length === 0) return 'N/A'

        // Calculate total rate and total distance
        const totalRate = validRateConfs.reduce((sum, conf) => sum + conf.rate.amount, 0)
        const totalDistance = validRateConfs.reduce((sum, conf) => sum + conf.totalDistance, 0)

        // Calculate and format average rate per mile
        return calculateRatePerMile(totalRate, totalDistance)
    }

    // Sort results by distance if available
    const sortedResults = searchResults
        ? [...searchResults].sort((a, b) => {
              // If both have distance, sort by distance
              if (a.distance && b.distance) {
                  return a.distance - b.distance
              }
              // If only one has distance, prioritize the one with distance
              if (a.distance) return -1
              if (b.distance) return 1
              // If neither has distance, maintain original order
              return 0
          })
        : null

    return (
        <Stack direction="column" gap={2} sx={{ width: '100%' }}>
            <Typography level="h4">Search Brokers by Location</Typography>

            <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                <LocationSearch
                    onLocationSelect={handleLocationSelect}
                    defaultLocation={
                        selectedLocation
                            ? {
                                  city: selectedLocation.city,
                                  stateProv: selectedLocation.stateCode,
                                  longitude: selectedLocation.longitude,
                                  latitude: selectedLocation.latitude,
                              }
                            : undefined
                    }
                />
                <Button
                    variant="solid"
                    color="primary"
                    startDecorator={loading ? <CircularProgress size="sm" /> : <Search />}
                    onClick={handleSearch}
                    disabled={loading || !selectedLocation}
                >
                    {loading ? 'Searching...' : 'Search'}
                </Button>
            </Stack>

            {error && (
                <Typography color="danger" sx={{ mt: 2 }}>
                    {error}
                </Typography>
            )}

            {sortedResults && sortedResults.length > 0 && (
                <Box>
                    <Typography level="h3" sx={{ mb: 2 }}>
                        Found {sortedResults.length} broker{sortedResults.length !== 1 ? 's' : ''}
                    </Typography>

                    <Stack spacing={2}>
                        {sortedResults.map((result, index) => (
                            <Card
                                key={index}
                                variant="outlined"
                                sx={{
                                    boxShadow: 'sm',
                                    transition: 'transform 0.2s, box-shadow 0.2s',
                                    '&:hover': {
                                        boxShadow: 'md',
                                        transform: 'translateY(-2px)',
                                    },
                                }}
                            >
                                <CardContent sx={{ p: 3 }}>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="space-between">
                                        <Stack spacing={1} sx={{ flex: 1 }}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography level="h3" color="primary">
                                                    {result.broker.companyName}
                                                </Typography>

                                                {result.distance && (
                                                    <Chip color="primary" variant="soft" size="sm" sx={{ ml: 'auto' }}>
                                                        {formatDistance(result.distance)}
                                                    </Chip>
                                                )}
                                            </Stack>

                                            <Stack direction="row" spacing={1} alignItems="center">
                                                {result.broker.contact.name && (
                                                    <Typography level="body-md" fontWeight="md">
                                                        {result.broker.contact.name}
                                                    </Typography>
                                                )}
                                                {result.broker.contact.phone && <Typography level="body-md">{result.broker.contact.phone}</Typography>}
                                            </Stack>

                                            <Typography
                                                level="body-md"
                                                sx={{
                                                    color: 'primary.600',
                                                    textDecoration: 'none',
                                                    '&:hover': { textDecoration: 'underline' },
                                                }}
                                                component="a"
                                                href={`mailto:${result.broker.contact.email}`}
                                            >
                                                {result.broker.contact.email}
                                            </Typography>

                                            <Box sx={{ mt: 1 }}>
                                                {result.broker.address && (
                                                    <Typography level="body-sm" sx={{ color: 'neutral.600' }}>
                                                        {result.broker.address.street && `${result.broker.address.street}, `}
                                                        {result.broker.address.city && `${result.broker.address.city}, `}
                                                        {result.broker.address.state} {result.broker.address.zip}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Stack>

                                        <Stack
                                            alignItems={{ xs: 'flex-start', sm: 'flex-end' }}
                                            justifyContent="space-between"
                                            sx={{ minWidth: { xs: '100%', sm: '180px' } }}
                                        >
                                            <Box
                                                sx={{
                                                    bgcolor: 'primary.100',
                                                    color: 'primary.700',
                                                    px: 2,
                                                    py: 1,
                                                    borderRadius: 'sm',
                                                    display: 'inline-block',
                                                    fontWeight: 'md',
                                                }}
                                            >
                                                {result.rateConfs.length} rate confirmation{result.rateConfs.length !== 1 ? 's' : ''}
                                            </Box>

                                            {result.rateConfs.length > 0 && result.rateConfs[0].route && result.rateConfs[0].route[0] && (
                                                <Box
                                                    sx={{
                                                        mt: { xs: 2, sm: 'auto' },
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: { xs: 'flex-start', sm: 'flex-end' },
                                                    }}
                                                >
                                                    <Typography level="body-sm">Average RPM:</Typography>
                                                    <Typography level="body-sm" sx={{ color: 'success.600', fontWeight: 'bold' }}>
                                                        {calculateAverageRatePerMile(result.rateConfs)}
                                                    </Typography>
                                                    <Typography level="body-sm">Route:</Typography>
                                                    <Typography level="body-sm">
                                                        {result.rateConfs[0].route[0].location.city}, {result.rateConfs[0].route[0].location.state}
                                                        {result.rateConfs[0].route.length > 1
                                                            ? ` → ${result.rateConfs[0].route[result.rateConfs[0].route.length - 1].location.city}, ${result.rateConfs[0].route[result.rateConfs[0].route.length - 1].location.state}`
                                                            : ''}
                                                    </Typography>

                                                    {result.rateConfs[0].distance && (
                                                        <Typography level="body-sm" sx={{ color: 'primary.600', mt: 1 }}>
                                                            {formatDistance(kmToMiles(result.rateConfs[0].distance))} from search location
                                                        </Typography>
                                                    )}
                                                </Box>
                                            )}
                                        </Stack>
                                    </Stack>

                                    {/* View details button */}
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                        <Button
                                            variant="soft"
                                            color="neutral"
                                            size="sm"
                                            endDecorator={expandedBrokers[index] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            onClick={() => toggleBrokerExpanded(index.toString())}
                                        >
                                            {expandedBrokers[index] ? 'Hide Details' : 'View Details'}
                                        </Button>
                                    </Box>

                                    {/* Expanded details section */}
                                    {expandedBrokers[index] && (
                                        <Box sx={{ mt: 2 }}>
                                            <Divider />
                                            <Typography level="title-md" sx={{ mt: 2, mb: 1 }}>
                                                Rate Confirmations
                                            </Typography>

                                            <Stack spacing={2}>
                                                {result.rateConfs.map((rateConf) => (
                                                    <Card key={rateConf.id} variant="outlined" sx={{ bgcolor: 'neutral.50' }}>
                                                        <CardContent>
                                                            <Stack
                                                                direction={{ xs: 'column', sm: 'row' }}
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                                sx={{ mb: 1 }}
                                                            >
                                                                <Typography level="title-sm">Load #{rateConf.documentId}</Typography>

                                                                <Stack direction="row" spacing={1} alignItems="center">
                                                                    <Chip size="md" color="neutral">
                                                                        {rateConf.totalDistance} miles
                                                                    </Chip>
                                                                    {rateConf.rate && (
                                                                        <>
                                                                            <Chip size="md" color="success">
                                                                                Rate: ${rateConf.rate.amount}
                                                                            </Chip>
                                                                        </>
                                                                    )}

                                                                    <>
                                                                        <Chip size="md" color="success">
                                                                            {calculateRatePerMile(rateConf.rate.amount, rateConf.totalDistance)}
                                                                        </Chip>
                                                                    </>
                                                                </Stack>
                                                            </Stack>

                                                            {/* Route summary - Horizontal layout */}
                                                            {rateConf.route && rateConf.route.length > 0 && (
                                                                <Box sx={{ mt: 2 }}>
                                                                    <Typography level="body-sm" fontWeight="md">
                                                                        Route:
                                                                    </Typography>
                                                                    <Stack
                                                                        direction="row"
                                                                        spacing={2}
                                                                        sx={{ flexWrap: 'wrap', rowGap: 1, alignItems: 'center' }}
                                                                    >
                                                                        {rateConf.route.map((stop, stopIndex) => (
                                                                            <React.Fragment key={stopIndex}>
                                                                                {/* Stop */}
                                                                                <Box
                                                                                    sx={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'column',
                                                                                        alignItems: 'center',

                                                                                        minWidth: '120px',
                                                                                        p: 1,
                                                                                        border: '1px solid',
                                                                                        borderColor:
                                                                                            stopIndex === 0
                                                                                                ? 'success.300'
                                                                                                : stopIndex === rateConf.route.length - 1
                                                                                                  ? 'primary.300'
                                                                                                  : 'neutral.300',
                                                                                        borderRadius: 'sm',
                                                                                        bgcolor:
                                                                                            stopIndex === 0
                                                                                                ? 'success.50'
                                                                                                : stopIndex === rateConf.route.length - 1
                                                                                                  ? 'primary.50'
                                                                                                  : 'neutral.50',
                                                                                    }}
                                                                                >
                                                                                    {/* Label for each stop */}
                                                                                    <Typography
                                                                                        level="body-xs"
                                                                                        sx={{
                                                                                            color:
                                                                                                stopIndex === 0
                                                                                                    ? 'success.600'
                                                                                                    : stopIndex === rateConf.route.length - 1
                                                                                                      ? 'primary.600'
                                                                                                      : 'neutral.600',
                                                                                            fontWeight: 'md',
                                                                                            mb: 0.5,
                                                                                        }}
                                                                                    >
                                                                                        {stopIndex === 0
                                                                                            ? 'Pickup'
                                                                                            : stopIndex === rateConf.route.length - 1
                                                                                              ? 'Delivery'
                                                                                              : `Stop #${stopIndex}`}
                                                                                    </Typography>
                                                                                    <Typography level="body-sm" textAlign="center" fontWeight="md">
                                                                                        {stop.location.city}, {stop.location.state}
                                                                                    </Typography>
                                                                                </Box>

                                                                                {/* Arrow between stops */}
                                                                                {stopIndex < rateConf.route.length - 1 && (
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: 'flex',
                                                                                            flexDirection: 'column',
                                                                                            alignItems: 'center',
                                                                                            gap: 0.5,
                                                                                            width: 'fit-content',
                                                                                        }}
                                                                                    >
                                                                                        <Typography
                                                                                            sx={{
                                                                                                color: 'neutral.500',
                                                                                                fontWeight: 'lg',
                                                                                                fontFamily: 'monospace',
                                                                                                fontSize: '2.2rem',
                                                                                                lineHeight: '0.5',
                                                                                            }}
                                                                                        >
                                                                                            &rarr;
                                                                                        </Typography>
                                                                                    </Box>
                                                                                )}
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </Stack>
                                                                </Box>
                                                            )}

                                                            {/* Freight details and Distance */}
                                                            <Stack direction="row" alignItems="center" gap={4} sx={{ mt: 1 }}>
                                                                <Stack direction="row" alignItems="center" gap={1}>
                                                                    <Typography level="body-sm" fontWeight="md">
                                                                        Freight Details:
                                                                    </Typography>
                                                                    <Chip size="md" color="neutral">
                                                                        {rateConf.freightDetails.type}
                                                                    </Chip>
                                                                    {rateConf.freightDetails.weight && (
                                                                        <Chip size="md" color="neutral">
                                                                            {rateConf.freightDetails.weight.value} {rateConf.freightDetails.weight.unit}
                                                                        </Chip>
                                                                    )}
                                                                </Stack>
                                                            </Stack>
                                                        </CardContent>
                                                    </Card>
                                                ))}
                                            </Stack>
                                        </Box>
                                    )}
                                </CardContent>
                            </Card>
                        ))}
                    </Stack>
                </Box>
            )}

            {searchResults && searchResults.length === 0 && !loading && !error && selectedLocation && (
                <Typography sx={{ mt: 2 }}>No brokers found for this location. Try a different search or expand your search area.</Typography>
            )}
        </Stack>
    )
}
