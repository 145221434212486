import { Button, CircularProgress, Tooltip } from '@mui/joy'
import React from 'react'
import { EmailRequestButtonProps } from './types'

export const EmailRequestButton: React.FC<EmailRequestButtonProps> = ({ eligibleLoadsCount, isLoading, onRequest }) => {
    const tooltipTitle =
        eligibleLoadsCount === 0
            ? 'No eligible loads (must have email and no email sent)'
            : `Will send info emails to ${eligibleLoadsCount} selected load${eligibleLoadsCount > 1 ? 's' : ''} (this doesn't include already sent emails)`

    return (
        <Tooltip title={tooltipTitle} variant="solid">
            <span>
                <Button
                    variant="soft"
                    color="primary"
                    disabled={isLoading || eligibleLoadsCount === 0}
                    onClick={onRequest}
                    startDecorator={isLoading ? <CircularProgress size="sm" /> : null}
                >
                    {isLoading ? 'Sending...' : `Request Info (${eligibleLoadsCount})`}
                </Button>
            </span>
        </Tooltip>
    )
}
