import { Download } from '@mui/icons-material'
import { Table, Typography, IconButton } from '@mui/joy'
import { getOrigin, getDestination } from '../utils/utils'
import { RateConf } from '@numeo/types'
import { calculateRatePerMile } from '../../spot-finder/components/assets/helpers'

interface RateConfsTableProps {
    rateConfirmations: RateConf[]
}

export const RateConfsTable = ({ rateConfirmations }: RateConfsTableProps) => {
    return (
        <Table
            hoverRow
            sx={{
                '& thead th:first-child': { width: '5%' },
                '& thead th:nth-child(2)': { width: '15%' },
                '& thead th:nth-child(3)': { width: '15%' },
                '& thead th:nth-child(4)': { width: '15%' },
                '& thead th:nth-child(5)': { width: '15%' },
                '& thead th:nth-child(6)': { width: '10%' },
                '& thead th:nth-child(7)': { width: '10%' },
                '& thead th:last-child': { width: '5%' },
            }}
        >
            <thead>
                <tr>
                    <th>#</th>
                    <th>Broker</th>
                    <th>Carrier</th>
                    <th>Origin</th>
                    <th>Destination</th>
                    <th>Rate</th>
                    <th>Rate/Mile</th>
                    <th>PDF</th>
                </tr>
            </thead>
            <tbody>
                {rateConfirmations.map((rc, index) => (
                    <tr key={rc._id || index}>
                        <td>{index + 1}</td>
                        <td>
                            <Typography noWrap sx={{ maxWidth: 150 }}>
                                {rc.rateConf.broker?.companyName || 'N/A'}
                            </Typography>
                        </td>
                        <td>
                            <Typography noWrap sx={{ maxWidth: 150 }}>
                                {rc.rateConf.carrier?.companyName || 'N/A'}
                            </Typography>
                        </td>
                        <td>
                            <Typography noWrap sx={{ maxWidth: 150 }}>
                                {getOrigin(rc.rateConf.route || [])}
                            </Typography>
                        </td>
                        <td>
                            <Typography noWrap sx={{ maxWidth: 150 }}>
                                {getDestination(rc.rateConf.route || [])}
                            </Typography>
                        </td>
                        <td>{rc.rateConf.rate ? `$${rc.rateConf.rate.amount}` : 'N/A'}</td>
                        <td>{calculateRatePerMile(rc.rateConf.totalDistance, rc.rateConf.rate.amount)}</td>
                        <td>
                            <IconButton variant="soft" color="primary" size="sm" onClick={() => window.open(rc.fileUrl, '_blank')} disabled={!rc.fileUrl}>
                                <Download />
                            </IconButton>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}
