import InfoIcon from '@mui/icons-material/Info'
import { Box, IconButton, Divider, Stack } from '@mui/joy'
import { Popper, ClickAwayListener } from '@mui/base'
import { Truck } from '@numeo/types'
import { useState, useRef } from 'react'
import { TTELDSection } from './TTELDSection'
import { useAuth } from 'hooks/auth'
import { InfoSection } from './InfoSection'
import { LucidEldSection } from './LucidEldSection'
import { SamsaraSection } from './SamsaraSection'

interface TruckInfoPopperProps {
    truckData: Truck
}

export default function TruckInfoPopper({ truckData }: TruckInfoPopperProps) {
    const { application } = useAuth()
    const [isOpen, setIsOpen] = useState(false)
    const buttonRef = useRef<HTMLButtonElement>(null)

    const handleClose = () => {
        setIsOpen(false)
    }

    return (
        <div>
            <IconButton ref={buttonRef} variant="plain" size="sm" onClick={() => setIsOpen(!isOpen)}>
                <InfoIcon sx={{ fontSize: 20, color: 'primary.500' }} />
            </IconButton>

            <Popper open={isOpen} anchorEl={buttonRef.current} placement="bottom" style={{ zIndex: 1000 }}>
                <ClickAwayListener onClickAway={handleClose}>
                    <Box
                        onMouseLeave={handleClose}
                        sx={{
                            p: 1.5,
                            bgcolor: 'background.surface',
                            boxShadow: 'lg',
                            borderRadius: 'md',
                            maxHeight: 400,
                            overflow: 'auto',
                        }}
                    >
                        <Stack direction="row" spacing={1}>
                            <InfoSection truckData={truckData} />
                            {application?.integrations?.tteld && truckData?.tteldVin && (
                                <>
                                    <Divider orientation="vertical" />
                                    <TTELDSection vin={truckData.tteldVin} isOpen={isOpen} />
                                </>
                            )}
                            {application?.integrations?.lucidEld && truckData?.lucidEldNumber && (
                                <>
                                    <Divider orientation="vertical" />
                                    <LucidEldSection eldNumber={truckData.lucidEldNumber} isOpen={isOpen} />
                                </>
                            )}
                            {application?.integrations?.samsara && truckData?.samsaraId && (
                                <>
                                    <Divider orientation="vertical" />
                                    <SamsaraSection vehicleId={truckData.samsaraId} isOpen={isOpen} />
                                </>
                            )}
                        </Stack>
                    </Box>
                </ClickAwayListener>
            </Popper>
        </div>
    )
}
