import { DEFAULT_SEARCH_OPTIONS, LocationData, SearchOptions, SearchResult } from './types'

/**
 * A class for searching locations using an indexed database.
 */
export class LocationDataSearch {
    private static instance: LocationDataSearch | null = null
    private dbName: string
    private storeName: string
    private version: number
    private db: IDBDatabase | null
    private isInitialized: boolean

    /**
     * Creates a new instance of LocationDataSearch.
     * @param dbName - The name of the IndexedDB database.
     * @param storeName - The name of the object store.
     * @param version - The version of the database.
     */
    private constructor(dbName, storeName, version) {
        this.dbName = dbName
        this.storeName = storeName
        this.version = version
        this.db = null
        this.isInitialized = false
        this.initialize()
    }

    /**
     * Returns the singleton instance of LocationDataSearch.
     * If the instance is not initialized, it will be created and the database will be loaded.
     * If the CSV file has changed, the database will be re-populated.
     */
    public static async getInstance(): Promise<LocationDataSearch> {
        const dbVersion = 4
        if (!LocationDataSearch.instance) {
            LocationDataSearch.instance = new LocationDataSearch('LocationsDB', 'locations', dbVersion)
            await LocationDataSearch.instance.initialize()

            // Check if the CSV file has changed
            const csvPath = '/locations.csv'
            const response = await fetch(csvPath)

            // Use Last-Modified header if available; otherwise, fail open and fetch the file all the time
            const fileStats = response.headers.get('Last-Modified') ?? new Date().toDateString()
            const storedStats = await LocationDataSearch.instance.getFileMetadata(csvPath)
            // Re-populate the database only if the file has changed
            if (fileStats !== storedStats?.lastModified) {
                await LocationDataSearch.instance.loadCSVData(csvPath, () => {})
                await LocationDataSearch.instance.setFileMetadata(csvPath, fileStats)
            }
        }
        return LocationDataSearch.instance
    }

    /**
     * Initializes the IndexedDB database and creates object stores if they do not exist.
     * This method should be called only once during the lifecycle of the application.
     */
    private async initialize(): Promise<void> {
        if (this.isInitialized) return

        try {
            this.db = await new Promise((resolve, reject) => {
                const request = indexedDB.open(this.dbName, this.version)

                request.onerror = () => reject(new Error('Failed to initialize database'))

                request.onupgradeneeded = (event) => {
                    const db = (event.target as IDBOpenDBRequest).result
                    if (!db.objectStoreNames.contains(this.storeName)) {
                        const store = db.createObjectStore(this.storeName, { autoIncrement: true })
                        store.createIndex('by-state-code', 'stateCode', { unique: false })
                        store.createIndex('by-state-name', 'stateName', { unique: false })
                        store.createIndex('by-city', 'city', { unique: false })
                    }

                    // Create dedicated states metadata store
                    if (!db.objectStoreNames.contains('states')) {
                        const statesStore = db.createObjectStore('states', { keyPath: 'stateCode' })
                        statesStore.createIndex('by-state-code', 'stateCode', { unique: true })
                    }
                    // Always attempt to create metadata store
                    if (!db.objectStoreNames.contains('metadata')) {
                        db.createObjectStore('metadata', { keyPath: 'csvPath' })
                    }
                }

                request.onsuccess = (event) => {
                    resolve((event.target as IDBOpenDBRequest).result)
                }
            })
            this.isInitialized = true
        } catch (error) {
            console.error('Failed to initialize LocationDataSearch:', error)
            throw error
        }
    }

    /**
     * Loads CSV data into the database in chunks.
     * This method is CPU-intensive and should not be called more than once throughout the app.
     * @param csvPath - The path to the CSV file to be loaded.
     * @param progressCallback - An optional callback to report progress.
     */
    private async loadCSVData(csvPath: string, progressCallback: (progress: number) => void = () => {}): Promise<void> {
        if (!this.isInitialized) {
            throw new Error('Database not initialized')
        }

        const CHUNK_SIZE = 1000

        try {
            const response = await fetch(csvPath)
            if (!response.ok) throw new Error('Failed to fetch CSV file')

            const text = await response.text()
            const lines = text.split('\n')
            const headers = lines[0].split(',').map((h) => h.trim())

            // Clear existing data
            await this.clearData()

            // Process CSV in chunks
            const chunks: string[][] = []
            for (let i = 1; i < lines.length; i += CHUNK_SIZE) {
                chunks.push(lines.slice(i, i + CHUNK_SIZE))
            }

            for (let i = 0; i < chunks.length; i++) {
                const chunk = chunks[i]
                await this.processChunk(chunk, headers)
                const progress = ((i + 1) / chunks.length) * 100
                progressCallback(progress)
                // Allow UI to update
                await new Promise((resolve) => setTimeout(resolve, 0))
            }
        } catch (error) {
            console.error('Failed to load CSV data:', error)
            throw error
        }
    }

    /**
     * Clears all data from the database.
     * This method should be used with caution as it will remove all stored records.
     */
    private async clearData(): Promise<void> {
        const dbRef = this.db
        if (!dbRef) {
            throw new Error('Database not initialized')
        }
        return new Promise((resolve, reject) => {
            const transaction = dbRef.transaction(this.storeName, 'readwrite')
            const store = transaction.objectStore(this.storeName)
            const request = store.clear()

            request.onsuccess = () => resolve()
            request.onerror = () => reject(new Error('Failed to clear existing data'))
        })
    }

    /**
     * Processes a chunk of CSV data and inserts it into the database.
     * @param chunk - An array of strings representing the CSV lines to be processed.
     * @param headers - An array of strings representing the headers of the CSV file.
     */
    private async processChunk(chunk: string[], headers: string[]): Promise<void> {
        const dbRef = this.db
        if (!dbRef) {
            throw new Error('Database not initialized')
        }

        return new Promise((resolve, reject) => {
            const transaction = dbRef.transaction(this.storeName, 'readwrite')
            const store = transaction.objectStore(this.storeName)
            // Add/update states store
            const statesTransaction = dbRef.transaction('states', 'readwrite')
            const statesStore = statesTransaction.objectStore('states')

            // remove quotes in city name
            Promise.all(
                chunk
                    .filter((line) => line.trim())
                    .map((line) => {
                        const values = line.split(',').map((v) => v.trim())
                        if (values.length === headers.length) {
                            const record: LocationData = {
                                id: values[0],
                                stateCode: values[1].replace(/"/g, ''),
                                stateName: values[2].replace(/"/g, ''),
                                city: values[3].replace(/"/g, ''),
                                latitude: parseFloat(values[5]),
                                longitude: parseFloat(values[6]),
                            }

                            // Add to main location store
                            const mainRequest = store.add(record)

                            statesStore.put({
                                stateCode: record.stateCode,
                                stateName: record.stateName,
                            })

                            return new Promise<void>((resolve, reject) => {
                                const request = store.add(record)
                                console.log(`Processing field: ${headers[0]}`)
                                request.onsuccess = () => resolve()
                                request.onerror = () => {
                                    console.error('Error adding record to database:', request.error)
                                    reject()
                                }

                                mainRequest.onsuccess = () => resolve()
                                mainRequest.onerror = (event) => {
                                    console.error('Transaction error:', (event.target as IDBRequest).error)
                                    reject((event.target as IDBRequest).error)
                                }
                            })
                        }
                        return Promise.resolve()
                    })
            )
                .then(() => resolve())
                .catch((error) => reject(error))
        })
    }

    /**
     * Retrieves metadata for a specified CSV file.
     * @param csvPath - The path to the CSV file for which metadata is requested.
     * @returns The last modified date of the file or null if not found.
     */
    private async getFileMetadata(csvPath: string): Promise<{ lastModified: string } | null> {
        const dbRef = this.db
        if (!dbRef) {
            throw new Error('Database not initialized')
        }
        const transaction = dbRef.transaction('metadata', 'readonly')
        const store = transaction.objectStore('metadata')
        const request = store.get(csvPath)
        return new Promise((resolve, reject) => {
            request.onsuccess = () => resolve(request.result)
            request.onerror = () => reject(request.error)
        })
    }

    /**
     * Updates the metadata for a specified CSV file.
     * @param csvPath - The path to the CSV file.
     * @param lastModified - The last modified date of the file.
     */
    private async setFileMetadata(csvPath: string, lastModified: string): Promise<void> {
        const dbRef = this.db
        if (!dbRef) {
            throw new Error('Database not initialized')
        }
        const transaction = dbRef.transaction('metadata', 'readwrite')
        const store = transaction.objectStore('metadata')
        const request = store.put({ csvPath, lastModified })
        return new Promise((resolve, reject) => {
            request.onsuccess = () => resolve()
            request.onerror = () => reject(request.error)
        })
    }

    /**
     * Performs fuzzy matching for a given text against a pattern.
     * @param text - The text to be matched.
     * @param pattern - The pattern to match against.
     * @returns A score representing the match quality.
     */
    private fuzzyMatch(text: string, pattern: string): number {
        const text_lower = text.toLowerCase()
        const pattern_lower = pattern.toLowerCase()

        // Exact substring match gets highest score
        if (text_lower.includes(pattern_lower)) {
            return text_lower.startsWith(pattern_lower) ? 1 : 0.9
        }

        let score = 0
        let patternIdx = 0
        let consecutiveMatches = 0
        let positionPenalty = 0

        // Calculate fuzzy match score with position and consecutive matches bonuses
        for (let i = 0; i < text_lower.length && patternIdx < pattern_lower.length; i++) {
            if (text_lower[i] === pattern_lower[patternIdx]) {
                const positionScore = 1 - i / text_lower.length
                score += (consecutiveMatches + 1) * positionScore
                consecutiveMatches++
                patternIdx++
                positionPenalty += i
            } else {
                consecutiveMatches = 0
            }
        }

        // Normalize score based on string lengths and position penalty
        if (patternIdx === pattern_lower.length) {
            const lengthPenalty = Math.abs(text.length - pattern.length) / Math.max(text.length, pattern.length)
            const normalizedScore = score / (pattern.length * (1 + lengthPenalty + positionPenalty / text.length))
            return Math.min(normalizedScore, 0.89) // Cap fuzzy matches below exact substring matches
        }

        return 0
    }

    // we need to search by state code if it is empty we need to return all states
    public async getStates(query: string): Promise<SearchResult[]> {
        return new Promise((resolve, reject) => {
            if (!this.db) {
                reject('Database not initialized')
                return
            }

            const transaction = this.db.transaction(['states'], 'readonly')
            const store = transaction.objectStore('states')
            const index = store.index('by-state-code')

            const request = query ? index.openCursor(IDBKeyRange.bound(query, query + '\uffff')) : index.openCursor()

            const states: SearchResult[] = []

            request.onsuccess = (event) => {
                const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result

                if (cursor) {
                    states.push(cursor.value)
                    cursor.continue()
                } else {
                    resolve(states)
                }
            }

            request.onerror = () => reject(request.error)
        })
    }
    /**
     * Searches for results based on a search term, supporting both exact and fuzzy matching.
     * @param searchTerm - The term to search for.
     * @param options - Optional search options.
     * @returns An array of search results.
     */
    async search(searchTerm: string, options: SearchOptions = DEFAULT_SEARCH_OPTIONS): Promise<SearchResult[]> {
        const dbRef = this.db
        if (!dbRef) {
            return []
        }

        const { limit = 10, searchFields = ['city', 'stateName', 'stateCode'], fuzzyThreshold = 0.4 } = options
        const searchLower = searchTerm.toLowerCase()
        const results = new Map<string, SearchResult>()

        // If search term is empty, return the first 10 items
        if (!searchTerm.trim()) {
            const transaction = dbRef.transaction(this.storeName, 'readonly')
            const store = transaction.objectStore(this.storeName)

            await new Promise<void>((resolve) => {
                const request = store.openCursor()
                let count = 0

                request.onsuccess = (event) => {
                    const cursor = (event.target as IDBRequest).result
                    if (cursor && count < 10) {
                        const data = cursor.value as LocationData
                        results.set(data.id, {
                            ...data,
                            city: data.city.replace(/"/g, ''),
                            score: 1,
                            matchType: 'exact',
                        })
                        count++
                        cursor.continue()
                    } else {
                        resolve()
                    }
                }
            })

            return Array.from(results.values())
        }

        // Convert field names to index names
        const getIndexName = (field: string) => {
            return `by-${field.toLowerCase()}`
        }

        // Phase 1: Exact prefix matching using indexes
        await Promise.all(
            searchFields.map(async (field) => {
                console.log(`Processing field: ${field}`)
                const transaction = dbRef.transaction(this.storeName, 'readonly')
                const store = transaction.objectStore(this.storeName)
                try {
                    const index = store.index(getIndexName(field))

                    return new Promise<void>((resolve) => {
                        const request = index.openCursor(IDBKeyRange.bound(searchLower, searchLower + '\uffff'))

                        request.onsuccess = (event) => {
                            const cursor = (event.target as IDBRequest).result
                            if (cursor) {
                                const data = cursor.value as LocationData
                                if (!results.has(data.id)) {
                                    results.set(data.id, {
                                        ...data,
                                        score: 1,
                                        matchType: 'exact',
                                    })
                                }
                                cursor.continue()
                            } else {
                                resolve()
                            }
                        }
                    })
                } catch (error) {
                    console.warn(`Index not found for field: ${field}`)
                    return Promise.resolve()
                }
            })
        )

        // Phase 2: Fuzzy search if needed
        if (results.size < limit) {
            const transaction = dbRef.transaction(this.storeName, 'readonly')
            const store = transaction.objectStore(this.storeName)

            await new Promise<void>((resolve) => {
                const request = store.openCursor()

                request.onsuccess = (event) => {
                    const cursor = (event.target as IDBRequest).result
                    if (cursor) {
                        const data = cursor.value as LocationData
                        if (!results.has(data.id)) {
                            const maxScore = Math.max(...searchFields.map((field) => this.fuzzyMatch(String(data[field]), searchTerm)))

                            if (maxScore > fuzzyThreshold) {
                                results.set(data.id, {
                                    ...data,
                                    score: maxScore,
                                    matchType: 'fuzzy',
                                })
                            }
                        }
                        cursor.continue()
                    } else {
                        resolve()
                    }
                }
            })
        }

        // Sort results by score and limit
        const sortedResults = Array.from(results.values())
            .sort((a, b) => {
                // First sort by score
                const scoreDiff = b.score - a.score
                if (scoreDiff !== 0) return scoreDiff

                // Then by match type (exact before fuzzy)
                if (a.matchType !== b.matchType) {
                    return a.matchType === 'exact' ? -1 : 1
                }

                // Finally by city name length (shorter names first)
                return a.city.length - b.city.length
            })
            .slice(0, limit)

        return sortedResults
    }

    /**
     * Closes the database connection.
     */
    async close(): Promise<void> {
        if (this.db) {
            this.db.close()
            this.db = null
            this.isInitialized = false
        }
    }
}

export default LocationDataSearch
