import { DeleteRounded, AddCircleOutline, CheckCircle } from '@mui/icons-material'
import { Card, Stack, Typography, IconButton, Button, Box, Chip } from '@mui/joy'
import { useAuth } from 'hooks/auth'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'
import { Category } from 'hooks/useNotification'
import { eldApi } from 'pages/dashboard/pages/dispatcher/pages/spot-finder/api/eld-api'
import { Application, SamsaraDriver, SamsaraVehicle } from '@numeo/types'
import { TruckSelectionModal, DisconnectConfirmationModal, VehicleSelectionTable } from './SharedComponents'
import { getBaseUrl } from 'components/common/utils/getBaseUrl'

export const handleSamsaraConnect = async (onSuccess?: (responseApplication: Application) => void, onError?: (error: string) => void) => {
    try {
        // Generate a state parameter to prevent CSRF attacks
        const state = Array.from(window.crypto.getRandomValues(new Uint8Array(16)))
            .map((b) => b.toString(16).padStart(2, '0'))
            .join('')

        // Store state in sessionStorage for verification when the user returns
        sessionStorage.setItem('samsaraAuthState', state)

        const params = new URLSearchParams({
            client_id: process.env.REACT_APP_SAMSARA_CLIENT_ID!,
            response_type: 'code',
            state,
            redirect_uri: `${getBaseUrl()}/auth/samsara/callback`,
        })
        const oauthUrl = process.env.REACT_APP_SAMSARA_OAUTH_URL!
        const authUrl = `${oauthUrl}?${params.toString()}`
        const authWindow = window.open(authUrl, 'Samsara Auth', 'width=500,height=600,scrollbars=yes')

        window.addEventListener('message', async function handleAuthMessage(event) {
            try {
                if (event.origin !== window.location.origin) return

                if (event.data.type === 'SAMSARA_AUTH_SUCCESS') {
                    window.removeEventListener('message', handleAuthMessage)
                    authWindow?.close()
                    // Use the application data received from the callback
                    if (event.data.application) {
                        onSuccess?.(event.data.application)
                    }
                } else if (event.data.type === 'SAMSARA_AUTH_ERROR') {
                    window.removeEventListener('message', handleAuthMessage)
                    authWindow?.close()
                    const errorMessage = event.data.error || 'Failed to connect Samsara'
                    onError?.(errorMessage)
                }
            } catch (error) {
                const errorMessage = 'Failed to connect Samsara'
                onError?.(errorMessage)
            }
        })
    } catch (error) {
        console.error('Error connecting to Samsara:', error)
        const errorMessage = (error as Error).message || 'Failed to connect Samsara'
        onError?.(errorMessage)
    }
}

export const Samsara = () => {
    const { application, setApplication } = useAuth()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [vehicles, setVehicles] = useState<{ vehicle: SamsaraVehicle; driver?: SamsaraDriver }[]>([])
    const [selectedVehicles, setSelectedVehicles] = useState<string[]>([])
    const [showAddTrucksModal, setShowAddTrucksModal] = useState<boolean>(false)
    const [showDisconnectConfirmation, setShowDisconnectConfirmation] = useState<boolean>(false)

    const isSamsaraConnected: boolean = application?.integrations?.samsara?.accessToken ? true : false

    const handleConnect = async () => {
        setIsLoading(true)
        try {
            await handleSamsaraConnect(
                (responseApplication) => {
                    setApplication(responseApplication)
                    dispatch(
                        addNotification({
                            id: '',
                            category: Category.Settings,
                            message: 'Samsara connected successfully',
                            timestamp: Date.now(),
                            type: 'info',
                            topic: 'temp',
                        })
                    )
                    setIsLoading(false)
                },
                (error) => {
                    dispatch(
                        addNotification({
                            id: '',
                            category: Category.Error,
                            message: error,
                            timestamp: Date.now(),
                            type: 'error',
                            topic: 'temp',
                        })
                    )
                    setIsLoading(false)
                }
            )
        } catch (error) {
            console.error('Error connecting to Samsara:', error)
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: 'Failed to connect Samsara',
                    timestamp: Date.now(),
                    type: 'error',
                    topic: 'temp',
                })
            )
            setIsLoading(false)
        }
    }

    const handleAddSelectedTrucks = async (): Promise<void> => {
        try {
            if (selectedVehicles.length === 0) {
                dispatch(
                    addNotification({
                        id: '',
                        category: Category.Error,
                        message: 'Please select at least one vehicle',
                        timestamp: Date.now(),
                        topic: 'temp',
                        type: 'error',
                    })
                )
                return
            }

            setIsLoading(true)
            // Filter vehicles to only include selected ones
            const trucksToAdd = vehicles.filter((item) => selectedVehicles.includes(item.vehicle.id))
            const response = await eldApi.addSelectedSamsaraVehicles(trucksToAdd)
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Settings,
                    message: `${selectedVehicles.length} vehicles added successfully`,
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'info',
                })
            )
            setApplication(response)
            setShowAddTrucksModal(false)
            setSelectedVehicles([])
        } catch (error) {
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: 'Failed to add vehicles',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'error',
                })
            )
        } finally {
            setIsLoading(false)
        }
    }

    const handleSelectAllVehicles = () => {
        if (selectedVehicles.length === vehicles.length) {
            setSelectedVehicles([])
        } else {
            setSelectedVehicles(vehicles.map((item) => item.vehicle.id))
        }
    }

    const handleSelectVehicle = (id: string) => {
        if (selectedVehicles.includes(id)) {
            setSelectedVehicles(selectedVehicles.filter((vehicleId) => vehicleId !== id))
        } else {
            setSelectedVehicles([...selectedVehicles, id])
        }
    }

    const fetchSamsaraVehicles = async (): Promise<void> => {
        try {
            setIsLoading(true)
            const fetchedVehicles = await eldApi.getAllSamsaraVehiclesDrivers()
            setVehicles(fetchedVehicles)
            setSelectedVehicles([])
            setShowAddTrucksModal(true)
        } catch (error) {
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: 'Failed to fetch vehicles',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'error',
                })
            )
        } finally {
            setIsLoading(false)
        }
    }

    const handleDisconnect = async () => {
        setIsLoading(true)
        try {
            const application = await eldApi.disconnectSamsara()
            setApplication(application)
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Settings,
                    message: 'Samsara disconnected successfully',
                    timestamp: Date.now(),
                    type: 'info',
                    topic: 'temp',
                })
            )
            setShowDisconnectConfirmation(false)
        } catch (error) {
            console.error('Error disconnecting Samsara:', error)
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: 'Failed to disconnect Samsara',
                    timestamp: Date.now(),
                    type: 'error',
                    topic: 'temp',
                })
            )
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Card variant="outlined" sx={{ p: 2, gap: 1, width: '49%' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <img src="/samsara-logo.png" alt="Samsara Logo" style={{ height: 24 }} />
                        <Box sx={{ flex: 1 }}>
                            <Typography level="title-md">Samsara</Typography>
                        </Box>
                        {isSamsaraConnected && (
                            <Chip variant="soft" color="success" startDecorator={<CheckCircle />}>
                                Connected
                            </Chip>
                        )}
                    </Stack>
                    {isSamsaraConnected ? (
                        <IconButton color="danger" variant="soft" onClick={() => setShowDisconnectConfirmation(true)} disabled={isLoading} loading={isLoading}>
                            <DeleteRounded />
                        </IconButton>
                    ) : (
                        <Button color="primary" variant="outlined" startDecorator={<AddCircleOutline />} onClick={handleConnect} loading={isLoading}>
                            Connect
                        </Button>
                    )}
                </Stack>

                {isSamsaraConnected && (
                    <Stack spacing={2}>
                        <Button startDecorator={<AddCircleOutline />} variant="outlined" onClick={fetchSamsaraVehicles} loading={isLoading}>
                            Add Connected Vehicles
                        </Button>
                    </Stack>
                )}
            </Card>

            <TruckSelectionModal
                open={showAddTrucksModal}
                onClose={() => setShowAddTrucksModal(false)}
                title="Add Connected Vehicles"
                description={`Select connected vehicles from your Samsara account to add to your trucks list:`}
                selectionTable={
                    <VehicleSelectionTable
                        vehicles={vehicles}
                        selectedVehicles={selectedVehicles}
                        onSelectVehicle={handleSelectVehicle}
                        onSelectAllVehicles={handleSelectAllVehicles}
                        getVehicleData={(item) => ({
                            id: item.vehicle.id,
                            truckNumber: item.vehicle.name,
                            vin: item.vehicle.vin,
                        })}
                    />
                }
                onConfirm={handleAddSelectedTrucks}
                isLoading={isLoading}
                selectedCount={selectedVehicles.length}
            />

            <DisconnectConfirmationModal
                open={showDisconnectConfirmation}
                onClose={() => setShowDisconnectConfirmation(false)}
                onConfirm={handleDisconnect}
                isLoading={isLoading}
                serviceName="Samsara"
            />
        </>
    )
}
