/* eslint-disable @typescript-eslint/no-explicit-any */
import { AccessTime, AttachMoney, DirectionsCar, Edit, ExpandMore, LocationOn, NorthEast, Route, Save } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, CircularProgress, Divider, IconButton, Input, Tooltip, Typography } from '@mui/joy'
import { OrderSchema } from '@numeo/types'
import { useAuth } from 'hooks/auth'
import { useMemo, useRef, useState } from 'react'
import OrderRouteMap from './OrderRouteMap'
import axios from 'axios'

interface OrderAccordionProps {
    order: OrderSchema
    isExpanded: boolean
    processingResultId: string
    onOpenChange: (orderId: string) => void
    refetch: () => Promise<void>
}
export default function OrderAccordion({ order, processingResultId, isExpanded, onOpenChange, refetch }: OrderAccordionProps) {
    const { application } = useAuth()
    const [editing, setEditing] = useState(false)
    const [ratePerMile, setRatePerMile] = useState<string>(String(order.ratePerMile || application?.temp?.emailSettings?.ratePerMile))
    const [editingPrice, setEditingPrice] = useState(false)
    const priceInputRef = useRef<HTMLInputElement>(null)
    const totalCost = useMemo(() => {
        let total = 0

        if (!isNaN(Number(application?.temp?.emailSettings?.driverCost))) {
            total += order.length * Number(application?.temp?.emailSettings?.driverCost)
        }

        if (!isNaN(Number(application?.temp?.emailSettings?.pricePerGallon))) {
            total += order.length * (Number(application?.temp?.emailSettings?.pricePerGallon) / 7)
        }

        if (order.costs.toll.total) {
            total += order.costs.toll.total
        }

        return total
    }, [application?.temp?.emailSettings?.driverCost, application?.temp?.emailSettings?.pricePerGallon, order.costs.toll.total, order.length])

    const onOpenRateEditInput = () => {
        setEditing(true)
        setTimeout(() => {
            if (priceInputRef.current) {
                priceInputRef.current.focus()
            }
        }, 0)
    }

    const onRatePerMileSave = async () => {
        if (!ratePerMile || isNaN(Number(ratePerMile))) return

        setEditingPrice(true)

        try {
            await axios.put(`/n8n/processing-results/${processingResultId}`, {
                ordersRate: [
                    {
                        routeId: order.routeId,
                        ratePerMile: Number(ratePerMile),
                    },
                ],
            })
            refetch()
        } catch (error) {
            console.log(error)
        }

        setEditing(false)
        setEditingPrice(false)
    }

    return (
        <Accordion expanded={isExpanded} onChange={() => onOpenChange((order as any)._id)}>
            <AccordionSummary
                indicator={<ExpandMore />}
                sx={{
                    py: 1.5,
                    '& .MuiAccordionSummary-expandIconWrapper': {
                        transform: 'rotate(0deg)',
                        transition: '0.2s',
                        '&.Mui-expanded': {
                            transform: 'rotate(180deg)',
                        },
                    },
                    '&:hover': {
                        bgcolor: 'background.level1',
                    },
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, width: '100%', overflow: 'hidden' }}>
                    <LocationOn color="primary" sx={{ flexShrink: 0 }} />
                    <Tooltip title={`${order.origin.city ? order.origin.city + ', ' : ''} ${order.origin.state}`} variant="soft">
                        <Typography
                            level="title-sm"
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                minWidth: 0,
                                flex: 1,
                            }}
                        >
                            {order.origin.city ? order.origin.city + ', ' : ''} {order.origin.state || ''}
                        </Typography>
                    </Tooltip>
                    <NorthEast sx={{ transform: 'rotate(45deg)', color: 'neutral.500', flexShrink: 0 }} />
                    <Tooltip title={`${order.destination.city ? order.destination.city + ', ' : ''} ${order.destination.state || ''}`} variant="soft">
                        <Typography
                            level="title-sm"
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                minWidth: 0,
                                flex: 1,
                            }}
                        >
                            {order.destination.city ? order.destination.city + ', ' : ''} {order.destination.state || ''}
                        </Typography>
                    </Tooltip>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                {isExpanded && (
                    <Box sx={{ mt: 2 }}>
                        <Typography level="title-sm" startDecorator={<AttachMoney />} sx={{ mb: 2 }}>
                            Financial Details
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1, mb: 2, flexWrap: 'wrap' }}>
                            {/* <Chip size="md" variant="soft" color="neutral" startDecorator={<Timeline />}>
                                            {order.} miles
                                        </Chip>
                                        <Chip size="md" variant="soft" color="neutral" startDecorator={<Timeline />}>
                                            {dayjs.duration(order.duration, 'hours').humanize()}
                                        </Chip> */}
                            {order.length ? (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                    <Chip size="md" variant="soft" color="primary" startDecorator={<AttachMoney />}>
                                        {editing ? (
                                            <Input
                                                size="sm"
                                                type="number"
                                                ref={priceInputRef}
                                                value={ratePerMile}
                                                onChange={(e) => setRatePerMile(e.target.value)}
                                                onBlur={onRatePerMileSave}
                                                sx={{
                                                    width: '80px',
                                                    '--Input-paddingInline': '0.4rem',
                                                }}
                                            />
                                        ) : (
                                            `$${ratePerMile}/mile`
                                        )}
                                    </Chip>
                                    {editingPrice ? (
                                        <CircularProgress size="sm" />
                                    ) : editing ? (
                                        <IconButton size="sm" variant="plain" color="neutral" onClick={onRatePerMileSave}>
                                            <Save />
                                        </IconButton>
                                    ) : (
                                        <IconButton size="sm" variant="plain" color="neutral" onClick={onOpenRateEditInput}>
                                            <Edit />
                                        </IconButton>
                                    )}
                                </Box>
                            ) : (
                                ''
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            {!isNaN(Number(application?.temp?.emailSettings?.driverCost)) && (
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography level="body-sm">Driver Cost:</Typography>
                                    <Typography level="body-sm">
                                        ${(order.length * Number(application?.temp?.emailSettings?.driverCost))?.toFixed(2)}
                                    </Typography>
                                </Box>
                            )}
                            {!isNaN(Number(application?.temp?.emailSettings?.pricePerGallon)) && (
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography level="body-sm">Fuel Cost:</Typography>
                                    <Typography level="body-sm">
                                        ${(order.length * (Number(application?.temp?.emailSettings?.pricePerGallon) / 7))?.toFixed(2)}
                                    </Typography>
                                </Box>
                            )}
                            {order.costs.toll.total ? (
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography level="body-sm">Toll Cost:</Typography>
                                    <Typography level="body-sm">${order.costs.toll.total?.toFixed(2)}</Typography>
                                </Box>
                            ) : (
                                ''
                            )}
                            <Divider />
                            {totalCost ? (
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography level="title-sm">Total Cost:</Typography>
                                    <Typography level="title-sm">${totalCost?.toFixed(2)}</Typography>
                                </Box>
                            ) : (
                                ''
                            )}
                            {!isNaN(Number(ratePerMile)) && (
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography level="title-sm">Charge Amount:</Typography>
                                    <Typography level="title-sm" color="success">
                                        ${(order.length * Number(ratePerMile)).toFixed(2)}
                                    </Typography>
                                </Box>
                            )}
                            {!isNaN(+order.offeringRate) && (
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography level="title-sm">Offering Rate:</Typography>
                                    <Typography level="title-sm" color="success">
                                        ${order.offeringRate}
                                    </Typography>
                                </Box>
                            )}
                            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography level="title-sm">Profit Margin:</Typography>
                                            <Typography level="title-sm" color="success">
                                                ${order.revenue.profitMargin?.toFixed(2)}
                                            </Typography>
                                        </Box> */}
                        </Box>

                        <Divider sx={{ my: 2 }} />

                        <Typography level="title-sm" startDecorator={<Route />} sx={{ mb: 2 }}>
                            Route Details
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Chip
                                    sx={{
                                        backgroundColor: 'background.default',
                                        px: 1.5,
                                        fontSize: '0.8rem',
                                        '& .MuiChip-label': { display: 'flex', alignItems: 'center', gap: 1 },
                                    }}
                                >
                                    <>
                                        <DirectionsCar fontSize="small" color="primary" />
                                        <Box component="span">
                                            {order.length.toLocaleString()}
                                            <Box component="span" sx={{ typography: 'caption2', ml: 0.5 }}>
                                                miles
                                            </Box>
                                        </Box>
                                    </>
                                </Chip>
                                <Chip
                                    sx={{
                                        backgroundColor: 'background.default',
                                        px: 1.5,
                                        fontSize: '0.8rem',
                                        '& .MuiChip-label': { display: 'flex', alignItems: 'center', gap: 1 },
                                    }}
                                >
                                    <>
                                        <AccessTime fontSize="small" color="primary" />
                                        <Box component="span">
                                            {order.duration.toFixed(1)}
                                            <Box component="span" sx={{ typography: 'caption2', ml: 0.5 }}>
                                                hrs
                                            </Box>
                                        </Box>
                                    </>
                                </Chip>
                            </Box>
                            <OrderRouteMap polyline={order.polyline} />
                        </Box>
                    </Box>
                )}
            </AccordionDetails>
        </Accordion>
    )
}
