export enum LoadStatus {
    ACTIVE = 'ACTIVE',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
}

export enum DeliveryStatus {
    ON_TRACK = 'ON_TRACK',
    DELAYED = 'DELAYED',
}

export interface ICoordinates {
    latitude: number
    longitude: number
}

export interface IOriginDestination {
    city: string
    state: string
    coordinates?: ICoordinates
}

export interface IContact {
    email?: string
    phone?: string
}

export enum EmailRecipient {
    BROKER = 'BROKER',
    DRIVER = 'DRIVER',
}

export interface OngoingLoad {
    _id: string
    status: LoadStatus
    origin: IOriginDestination
    destination: IOriginDestination
    truckId?: string
    distance: number
    length: number
    weight: number
    pickupTime1: Date
    pickupTime2?: Date
    deliveryTime1: Date
    deliveryTime2?: Date
    deliveryStatus?: DeliveryStatus
    equipmentType: string
    companyName: string
    mcNumber: number
    dotNumber?: number
    contact: IContact
    totalRate: number
    brokerEmailThreadId?: string
    driverEmailThreadId?: string
    comment?: string
    createdAt?: string
    updatedAt?: string
    lastBrokerUpdated?: Date
}
