import CloseIcon from '@mui/icons-material/Close'
import { Box, Divider, IconButton, Sheet, Typography } from '@mui/joy'
import { Call } from '@numeo/types'
import React, { useEffect, useRef } from 'react'

interface TranscriptSidebarProps {
    call: Call
    onClose: () => void
}

export const TranscriptSidebar: React.FC<TranscriptSidebarProps> = ({ call, onClose }) => {
    const [isLoading, setIsLoading] = React.useState(true)
    const transcriptEndRef = useRef<HTMLDivElement>(null)

    // Auto-scroll to bottom when new transcripts arrive
    useEffect(() => {
        if (transcriptEndRef.current) {
            transcriptEndRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [call])

    useEffect(() => {
        setIsLoading(false)
    }, [])

    return (
        <>
            <Sheet
                sx={{
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: '400px',
                    bgcolor: 'background.surface',
                    borderLeft: '1px solid',
                    borderColor: 'divider',
                    p: 2,
                    boxShadow: 'lg',
                    zIndex: 1000,
                    overflowY: 'auto',
                    animation: 'slideIn 0.3s ease-out',
                    '@keyframes slideIn': {
                        from: { transform: 'translateX(100%)' },
                        to: { transform: 'translateX(0)' },
                    },
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography level="body-md">Call Transcript</Typography>
                    <IconButton onClick={onClose} variant="plain">
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Box sx={{ mt: 2 }}>
                    {isLoading ? (
                        <Typography level="body-sm" color="neutral">
                            Loading transcripts...
                        </Typography>
                    ) : call.transcripts.length === 0 ? (
                        <Typography level="body-sm" color="neutral">
                            No transcripts available
                        </Typography>
                    ) : (
                        call.transcripts.map((transcript, index) => (
                            <Box
                                key={index}
                                sx={{ mb: 2, p: 1, borderRadius: 'sm', display: 'flex', justifyContent: transcript.role === 'assistant' ? 'right' : 'left' }}
                            >
                                <Box sx={{ bgcolor: 'background.level1', p: 1, borderRadius: 'sm', width: '80%' }}>
                                    <Typography level="body-sm">
                                        {Array.isArray(transcript.content) ? transcript.content.join(' ') : transcript.content}
                                    </Typography>
                                </Box>
                            </Box>
                        ))
                    )}
                    <div ref={transcriptEndRef} />
                </Box>
            </Sheet>
            <Box
                sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}
                onClick={onClose}
            />
        </>
    )
}

export default TranscriptSidebar
