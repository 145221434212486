export * from './account.details'
export * from './call'
export * from './load.search'

// Add WebSocket close codes
export enum WsErrorCodes {
    // 4xxx- Connection Errors
    DUPLICATE_CONNECTION = 4090,
    AUTHENTICATION_FAILED = 4010,
    INVALID_ACTION = 4000,
    GENERIC_SERVER_ERROR = 5000,
    // 6xxx - Search error codes
    SEARCH_FAILED_TASK_ERROR = 6010,
}
/**
 * Standardized error payload structure for WebSocket messages
 */
export interface WsErrorPayload {
    code: WsErrorCodes
    message: string
    details?: unknown
}

/**
 * WebSocket message envelope with type-safe payload
 */
export interface WsMessage {
    categoryType: WsMessageCategory
    error?: WsErrorPayload // This is not null if there was an error during action processing
    data?: Record<string, any>
}

/**
 * Categories of WebSocket messages
 */
export enum WsMessageCategory {
    LoadFinder = 'LoadFinder',
    CallCenter = 'CallCenter',
    AccountDetails = 'AccountDetails',
}

export enum WebSocketStatus {
    CONNECTING = 'connecting',
    CONNECTED = 'connected',
    DISCONNECTED = 'disconnected',
    ERROR = 'error',
}
