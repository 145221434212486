import { Theme, extendTheme } from '@mui/joy/styles'

// Helper function to create a CSS transform string
const createTransform = (scale: number) => `scale(${scale})`

// Media query breakpoints
const mediaQueries = {
    xs: '@media (max-width: 600px)',
    sm: '@media (min-width: 601px) and (max-width: 900px)',
    md: '@media (min-width: 901px) and (max-width: 1200px)',
    lg: '@media (min-width: 1201px)',
}

// Common density styles for components
const commonDensityStyles = {
    [mediaQueries.xs]: {
        transform: createTransform(0.85),
    },
    [mediaQueries.sm]: {
        transform: createTransform(0.9),
    },
    [mediaQueries.md]: {
        transform: createTransform(0.95),
    },
    [mediaQueries.lg]: {
        transform: createTransform(1),
    },
}

export const getDensityScale = (theme: Theme) => ({
    transform: createTransform(
        theme.breakpoints.values.xs < 600 ? 0.9 : theme.breakpoints.values.sm < 900 ? 0.94 : theme.breakpoints.values.md < 1200 ? 0.97 : 1
    ),
    transformOrigin: 'left center',
})

export const getDensityScaleCenter = (theme: Theme) => ({
    ...getDensityScale(theme),
    transformOrigin: 'center center',
})

// Global density styles using CSS media queries
export const globalDensityStyles = {
    '& .MuiInput-root, & .MuiSelect-root, & .MuiAutocomplete-root, & .MuiButton-root, & .DateRangePicker, & .MuiIconButton-root, & .MuiCheckbox-root, & .MuiTooltip-root, & .MuiSnackbar-root':
        {
            ...commonDensityStyles,
            transformOrigin: 'left center',
        },
    '& .MuiButton-root, & .MuiIconButton-root': {
        transformOrigin: 'center center',
    },
    // Table specific styles
    '& .MuiTable-root': {
        '& td, & th': {
            padding: {
                xs: '6px',
                sm: '8px',
                md: '10px',
                lg: '12px',
            },
        },
    },
    // Typography specific styles for global classes
    '& .MuiTypography-root': {
        [mediaQueries.xs]: {
            '--Typography-fontSize': '0.85rem',
        },
        [mediaQueries.sm]: {
            '--Typography-fontSize': '0.9rem',
        },
        [mediaQueries.md]: {
            '--Typography-fontSize': '0.95rem',
        },
        [mediaQueries.lg]: {
            '--Typography-fontSize': '1rem',
        },
    },
}

// Theme extension for density using CSS media queries
export const densityTheme = extendTheme({
    components: {
        JoyInput: {
            styleOverrides: {
                root: {
                    ...commonDensityStyles,
                    transformOrigin: 'left center',
                },
            },
        },
        JoySelect: {
            styleOverrides: {
                root: {
                    ...commonDensityStyles,
                    transformOrigin: 'left center',
                },
            },
        },
        JoyAutocomplete: {
            styleOverrides: {
                root: {
                    ...commonDensityStyles,
                    transformOrigin: 'left center',
                },
            },
        },
        JoyButton: {
            styleOverrides: {
                root: {
                    ...commonDensityStyles,
                    transformOrigin: 'center center',
                },
            },
        },
        JoyIconButton: {
            styleOverrides: {
                root: {
                    ...commonDensityStyles,
                    transformOrigin: 'center center',
                },
            },
        },
        JoyCheckbox: {
            styleOverrides: {
                root: {
                    ...commonDensityStyles,
                    transformOrigin: 'center center',
                },
            },
        },
        JoyTooltip: {
            styleOverrides: {
                root: {
                    ...commonDensityStyles,
                    transformOrigin: 'left center',
                },
            },
        },
        JoyTypography: {
            styleOverrides: {
                root: ({ theme }) => ({
                    ...commonDensityStyles,
                    transformOrigin: 'left center',
                    '--Typography-fontSize':
                        theme.breakpoints.values.xs < 600
                            ? '0.85rem'
                            : theme.breakpoints.values.sm < 900
                              ? '0.9rem'
                              : theme.breakpoints.values.md < 1200
                                ? '0.95rem'
                                : '1rem',
                }),
            },
        },
        JoySnackbar: {
            styleOverrides: {
                root: {
                    ...commonDensityStyles,
                    transformOrigin: 'center center',
                },
            },
        },
    },
})
