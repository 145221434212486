/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fullscreen, FullscreenExit } from '@mui/icons-material'
import { Box, CircularProgress, IconButton, Typography } from '@mui/joy'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { loadScript, loadStylesheet } from 'utils/script'
import { OrderRouteMapClient } from './api'
import { WeatherDetailsModal } from './components'
import { reduceCoordinatesByMiles } from './utils'

declare global {
    interface Window {
        H: any
    }
}

interface OrderRouteMapProps {
    polyline?: string
}

async function getPolyline(url: string) {
    const response = await fetch(url)
    const data = await response.text()

    return data
}

const OrderRouteMap: React.FC<OrderRouteMapProps> = ({ polyline }) => {
    const mapRef = useRef<HTMLDivElement>(null)
    const [mapState, setMapState] = useState({
        isLoaded: false,
        isLoading: true,
        error: null as string | null,
    })
    const [isFullscreen, setIsFullscreen] = useState(false)
    const [selectedWeather, setSelectedWeather] = useState<any>(null)
    const [selectedDate, setSelectedDate] = useState<string>(dayjs().format('YYYY-MM-DD'))
    const [isWeatherModalOpen, setIsWeatherModalOpen] = useState(false)
    const [polylineData, setPolylineData] = useState<string | null>(null)
    const [weatherWarnings, setWeatherWarnings] = useState<
        Array<{
            condition: string
            location: string
            temp?: number
            icon: string
        }>
    >([])

    const fetchPolyline = useCallback(async () => {
        if (!polyline) return

        try {
            setMapState((prev) => ({ ...prev, isLoading: true, error: null }))
            const fetchedPolylineData = await getPolyline(polyline)

            if (fetchedPolylineData === '') {
                setMapState((prev) => ({ ...prev, error: 'No route data available' }))
                return
            }

            if (!fetchedPolylineData) {
                setMapState((prev) => ({ ...prev, error: 'Invalid route data format' }))
                return
            }

            setPolylineData(fetchedPolylineData)
        } catch (err) {
            console.error('Failed to fetch polyline:', err)
            setMapState((prev) => ({ ...prev, error: 'Failed to load route data' }))
        } finally {
            setMapState((prev) => ({ ...prev, isLoading: false }))
        }
    }, [polyline])

    const loadHereMaps = useCallback(async () => {
        try {
            setMapState((prev) => ({ ...prev, isLoading: true, error: null }))

            // Load core script first
            await loadScript('https://js.api.here.com/v3/3.1/mapsjs-core.js')

            // Wait for H to be defined with timeout
            await new Promise<void>((resolve, reject) => {
                const timeout = setTimeout(() => {
                    reject(new Error('HERE Maps initialization timeout'))
                }, 10000)

                const checkH = () => {
                    if (window.H) {
                        clearTimeout(timeout)
                        resolve()
                    } else {
                        setTimeout(checkH, 100)
                    }
                }
                checkH()
            })

            // Load remaining scripts in sequence
            await loadScript('https://js.api.here.com/v3/3.1/mapsjs-service.js')
            await loadScript('https://js.api.here.com/v3/3.1/mapsjs-mapevents.js')
            await loadScript('https://js.api.here.com/v3/3.1/mapsjs-ui.js')
            await loadStylesheet('https://js.api.here.com/v3/3.1/mapsjs-ui.css')

            setMapState((prev) => ({ ...prev, isLoaded: true }))
        } catch (error) {
            console.error('Error loading HERE Maps:', error)
            setMapState((prev) => ({ ...prev, error: 'Failed to load map components' }))
        } finally {
            setMapState((prev) => ({ ...prev, isLoading: false }))
        }
    }, [])

    const addWeatherMarkers = useCallback(async (map: any, points: Array<[number, number]>) => {
        const warnings: Array<{ condition: string; location: string; temp?: number; icon: string }> = []
        for (const [lat, lng] of points) {
            try {
                const weatherData = await OrderRouteMapClient.getWeatherByCoordinates(lat, lng)

                // Check for adverse weather conditions
                const temp = weatherData.main.temp
                const weather = weatherData.weather[0]
                const location = weatherData.name

                if (temp < 1) {
                    warnings.push({ condition: 'freezing', location, temp, icon: weather.icon })
                }
                if (weather.main.toLowerCase().includes('rain')) {
                    warnings.push({ condition: 'rain', location, icon: weather.icon })
                }
                if (weather.main.toLowerCase().includes('storm') || weather.main.toLowerCase().includes('thunder')) {
                    warnings.push({ condition: 'storm', location, icon: weather.icon })
                }
                if (weather.main.toLowerCase().includes('snow')) {
                    warnings.push({ condition: 'snow', location, icon: weather.icon })
                }

                const icon = new window.H.map.Icon(`http://openweathermap.org/img/wn/${weatherData.weather[0].icon}@2x.png`, {
                    size: { w: 30, h: 30 },
                    anchor: { x: 15, y: 15 },
                })

                const marker = new window.H.map.Marker({ lat, lng }, { icon, zIndex: 10000 })

                marker.addEventListener('tap', () => {
                    setSelectedWeather({
                        ...weatherData,
                        coord: { lat, lon: lng },
                    })
                    setIsWeatherModalOpen(true)
                })

                map.addObject(marker)
            } catch (error) {
                console.error('Error fetching weather data:', error)
            }
        }
        setWeatherWarnings(warnings)
    }, [])

    const setupMap = useCallback(async () => {
        if (!mapState.isLoaded || !mapRef.current || !window.H || !polylineData) return

        let map: any = null
        let behavior: any = null
        let ui: any = null

        try {
            setMapState((prev) => ({ ...prev, isLoading: true, error: null }))

            const platform = new window.H.service.Platform({
                apikey: process.env.REACT_APP_HERE_API_KEY,
            })

            const defaultLayers = platform.createDefaultLayers()

            map = new window.H.Map(mapRef.current, defaultLayers.vector.normal.map, {
                center: { lat: 40.7128, lng: -74.006 },
                zoom: 10,
                pixelRatio: window.devicePixelRatio || 1,
            })

            behavior = new window.H.mapevents.Behavior(new window.H.mapevents.MapEvents(map))
            ui = window.H.ui.UI.createDefault(map, defaultLayers)

            // Use HERE's built-in flexible polyline decoder
            const routeLineString = window.H.geo.LineString.fromFlexiblePolyline(polylineData)
            const routePolyline = new window.H.map.Polyline(routeLineString, {
                style: { lineWidth: 5, strokeColor: 'blue' },
            })

            map.addObject(routePolyline)

            const bounds = routePolyline.getBoundingBox()
            if (bounds) {
                map.getViewModel().setLookAtData({
                    bounds,
                    animation: 0,
                })
            }

            const reducedCoordinates = reduceCoordinatesByMiles(window.H.util.flexiblePolyline.decode(polylineData).polyline, 50)

            await addWeatherMarkers(map, reducedCoordinates)

            // Handle resize
            const handleResize = () => {
                map?.getViewPort()?.resize()
            }

            const resizeObserver = new ResizeObserver(handleResize)
            if (mapRef.current) {
                resizeObserver.observe(mapRef.current)
            }

            return () => {
                resizeObserver.disconnect()
                behavior?.disable()
                ui?.dispose()
                map?.dispose()
            }
        } catch (error) {
            console.error('Error initializing map:', error)
            setMapState((prev) => ({ ...prev, error: 'Failed to initialize map' }))
        } finally {
            setMapState((prev) => ({ ...prev, isLoading: false }))
        }
    }, [mapState.isLoaded, polylineData, addWeatherMarkers])

    const toggleFullscreen = () => {
        setIsFullscreen((prev) => !prev)
    }

    // Initial setup
    useEffect(() => {
        if (!window.H) {
            loadHereMaps()
        } else {
            setMapState((prev) => ({ ...prev, isLoaded: true, isLoading: false }))
        }

        return () => {
            const scripts = document.querySelectorAll('script[src*="js.api.here.com"]')
            const styles = document.querySelectorAll('link[href*="js.api.here.com"]')
            scripts.forEach((script) => script.remove())
            styles.forEach((style) => style.remove())
        }
    }, [loadHereMaps])

    // Fetch polyline when prop changes
    useEffect(() => {
        if (polyline) {
            fetchPolyline()
        }
    }, [polyline, fetchPolyline])

    // Setup map when all dependencies are ready
    useEffect(() => {
        if (mapState.isLoaded && polyline && polylineData) {
            setupMap()
        }
    }, [mapState.isLoaded, polyline, polylineData, setupMap])

    return (
        <Box>
            <div
                style={{
                    position: isFullscreen ? 'fixed' : 'relative',
                    width: isFullscreen ? '100vw' : '100%',
                    height: isFullscreen ? '100vh' : '200px',
                    backgroundColor: '#F4F7F9',
                    zIndex: isFullscreen ? 9999 : 'auto',
                    top: 0,
                    left: 0,
                }}
            >
                {mapState.isLoading && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <CircularProgress size="sm" />
                        <Typography level="body-sm">Loading map...</Typography>
                    </Box>
                )}

                {mapState.error && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1,
                            textAlign: 'center',
                        }}
                    >
                        <Typography level="body-sm" color="danger">
                            {mapState.error}
                        </Typography>
                    </Box>
                )}

                <div ref={mapRef} style={{ width: '100%', height: '100%' }} />

                <IconButton
                    onClick={toggleFullscreen}
                    sx={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                        zIndex: 1,
                        backgroundColor: 'white',
                        boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                        },
                    }}
                >
                    {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
                </IconButton>

                {selectedWeather && (
                    <WeatherDetailsModal
                        open={isWeatherModalOpen}
                        onClose={() => setIsWeatherModalOpen(false)}
                        weatherData={selectedWeather}
                        selectedDate={selectedDate}
                        onDateChange={(date) => setSelectedDate(date)}
                    />
                )}
            </div>

            {weatherWarnings.length > 0 && (
                <Box sx={{ padding: '10px' }}>
                    <Typography level="body-sm" sx={{ fontWeight: 'bold', color: 'warning.main', mb: 1 }}>
                        Weather Warnings:
                    </Typography>
                    {weatherWarnings.map((warning, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 0.5,
                            }}
                        >
                            <img
                                src={`http://openweathermap.org/img/wn/${warning.icon}@2x.png`}
                                alt={warning.condition}
                                style={{
                                    width: 24,
                                    height: 24,
                                    marginLeft: -6,
                                    marginRight: -6,
                                }}
                            />
                            <Typography level="body-sm" sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center' }}>
                                {warning.condition === 'freezing'
                                    ? `Freezing conditions at ${warning.location} (${warning.temp}°C)`
                                    : `${warning.condition.charAt(0).toUpperCase() + warning.condition.slice(1)} at ${warning.location}`}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    )
}

export default OrderRouteMap
