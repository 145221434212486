import { Box } from '@mui/joy'
import { UpdaterScheduler } from './EmailTemplateTab/UpdaterScheduler'

export const UpdaterSettings = () => {
    return (
        <Box>
            <UpdaterScheduler />
        </Box>
    )
}
