import React from 'react'
import { Box, Chip, Tooltip, Typography } from '@mui/joy'
import { useAuth } from 'hooks/auth'
import { BrokerType } from '@numeo/types'
import { useNavigate } from 'react-router-dom'

interface RateConfirmationCellProps {
    mcNumber: string
}

// Calculate rate per mile
const calculateRatePerMile = (rate: number, distance: number) => {
    return rate / distance
}

/**
 * Component to display the count of rate confirmations for a specific broker
 */
export const RateConfirmationCell: React.FC<RateConfirmationCellProps> = ({ mcNumber }) => {
    const { myBrokers } = useAuth()
    const navigate = useNavigate()

    // If no MC number, display a dash
    if (!mcNumber) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '14px' }}>—</Typography>
            </Box>
        )
    }

    // Extract only numeric part of the mcNumber prop
    const numericMcNumber = String(mcNumber).replace(/\D/g, '')

    // Find matched broker from myBrokers
    const matchedBroker = myBrokers.find((broker) => {
        // Compare numeric parts of MC numbers for more flexible matching
        const brokerNumericMC = String(broker.mcNumber).replace(/\D/g, '')
        return brokerNumericMC === numericMcNumber || brokerNumericMC.includes(numericMcNumber) || numericMcNumber.includes(brokerNumericMC)
    })

    // Find rate confirmations for this MC number
    const rateConfs = matchedBroker?.rateConfs || []

    // If no rate confirmations found, display a dash
    if (!rateConfs.length) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '14px' }}>—</Typography>
            </Box>
        )
    }

    const handleNavigate = () => {
        // Navigate to My Brokers page with the appropriate tab
        if (matchedBroker) {
            // Use the broker's type as the tab parameter
            navigate(`/dispatcher/my-brokers?tab=${matchedBroker.type}`)
        } else {
            // If no matching broker found, navigate to the unclassified tab
            navigate(`/dispatcher/my-brokers?tab=${BrokerType.UNCLASSIFIED}`)
        }
    }

    const count = rateConfs.length

    // Generate list of individual RPM values
    const rpmList = rateConfs
        .map((rc, index) => {
            const rpm = calculateRatePerMile(rc.rate || 0, rc.totalDistance || 0)
            return `${index + 1}. RPM: $${rpm.toFixed(2)}`
        })
        .join('\n')

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {count > 0 ? (
                <Tooltip
                    title={
                        <div style={{ whiteSpace: 'pre-line' }}>
                            {`You have ${count} rate confirmation${count === 1 ? '' : 's'} from this broker\n${rpmList}`}
                        </div>
                    }
                >
                    <Chip size="sm" variant="solid" color="primary" onClick={handleNavigate}>
                        {count}
                    </Chip>
                </Tooltip>
            ) : (
                <Typography sx={{ fontSize: '14px' }}>—</Typography>
            )}
        </Box>
    )
}

export default RateConfirmationCell
