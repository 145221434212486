import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { DispatcherRoutes, SettingsPage, UpdaterAgentRoutes } from './pages'
import { useAuth } from 'hooks/auth'
import { RateConfAnalyticsPage } from './pages/dispatcher/pages/rateconf-analytics'

export default function DashboardRoutes() {
    const { isAuthenticated, application } = useAuth()
    const location = useLocation()

    if (!isAuthenticated) {
        // Save the attempted URL for redirecting after login
        return <Navigate to="/auth/sign-in" state={{ from: location }} replace />
    }

    const allowedRateConfProjects = ['Allstate', 'foziljonm']

    return (
        <Routes>
            <Route index element={<Navigate to="dispatcher" replace />} />
            <Route path="settings" element={<SettingsPage />} />
            {allowedRateConfProjects.includes(application?.projectName || '') && <Route path="rateconf-analytics" element={<RateConfAnalyticsPage />} />}
            <Route path="dispatcher/*" element={<DispatcherRoutes />} />
            <Route path="updater-agent/*" element={<UpdaterAgentRoutes />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}
