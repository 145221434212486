import { useEffect, useState } from 'react'
import axios from 'axios'
import { Box, Typography, CircularProgress, Card, Alert, Button } from '@mui/joy'
import { CheckCircle, ErrorOutline } from '@mui/icons-material'

type AuthStatus = 'loading' | 'success' | 'error'

export default function TruckStopCallback() {
    const [status, setStatus] = useState<AuthStatus>('loading')
    const [errorMessage, setErrorMessage] = useState<string>('')

    useEffect(() => {
        const code = new URLSearchParams(window.location.search).get('code')

        if (!code) {
            setStatus('error')
            setErrorMessage('No authorization code found in the URL')
            return
        }

        // Exchange code for tokens
        axios
            .post('/integrations/truck-stop', { code })
            .then(({ data }) => {
                // Send the tokens back to the parent window
                window.opener?.postMessage(
                    {
                        type: 'TRUCKSTOP_AUTH_SUCCESS',
                        integration: data,
                    },
                    window.location.origin
                )
                setStatus('success')
                // Auto close after success (optional)
                setTimeout(() => {
                    window.close()
                }, 3000)
            })
            .catch((error) => {
                const errorMsg = error.response?.data?.message || 'Failed to authenticate with TruckStop'
                setStatus('error')
                setErrorMessage(errorMsg)

                // Send the error back to the parent window
                window.opener?.postMessage(
                    {
                        type: 'TRUCKSTOP_AUTH_ERROR',
                        error: errorMsg,
                    },
                    window.location.origin
                )
            })
    }, [])

    const handleClose = () => {
        window.close()
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                bgcolor: '#f5f5f5',
            }}
        >
            <Card
                variant="outlined"
                sx={{
                    maxWidth: 400,
                    width: '100%',
                    p: 3,
                    boxShadow: 'sm',
                    textAlign: 'center',
                }}
            >
                <Box sx={{ mb: 2 }}>
                    <img src="/truckstop-logo.webp" alt="TruckStop Logo" style={{ width: 60, height: 60 }} />
                </Box>

                <Typography level="h4" sx={{ mb: 2 }}>
                    TruckStop Authentication
                </Typography>

                {status === 'loading' && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                        <CircularProgress size="lg" />
                        <Typography level="body-md">Authenticating with TruckStop...</Typography>
                    </Box>
                )}

                {status === 'success' && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                        <CheckCircle color="success" sx={{ fontSize: 48 }} />
                        <Typography level="body-md">Authentication successful! This window will close automatically.</Typography>
                        <Button variant="solid" color="primary" onClick={handleClose}>
                            Close Window
                        </Button>
                    </Box>
                )}

                {status === 'error' && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                        <ErrorOutline color="error" sx={{ fontSize: 48 }} />
                        <Alert color="danger" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                        <Typography level="body-md">Please close this window and try again.</Typography>
                        <Button variant="solid" color="primary" onClick={handleClose}>
                            Close Window
                        </Button>
                    </Box>
                )}
            </Card>
        </Box>
    )
}
