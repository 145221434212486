import { Application, ForgotPasswordData, IBroker, ResetPasswordData, SignInData, SignUpData, User } from '@numeo/types'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch } from '../../store'
import {
    DebugData,
    forgotPassword as forgotPasswordAction,
    login,
    logout as logoutAction,
    resetPassword as resetPasswordAction,
    selectApplication,
    selectDebugData,
    selectIsAuthenticated,
    selectMyBrokers,
    selectUser,
    setApplication as setApplicationAction,
    setMyBrokers as setMyBrokersAction,
    setUser as setUserAction,
    signup,
    updateDebugData as updateDebugDataAction,
    verifyAuth,
} from '../../store/authSlice'

export const useAuthRedux = () => {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const user = useSelector(selectUser)
    const application = useSelector(selectApplication)
    const myBrokers = useSelector(selectMyBrokers)
    const isAuthenticated = useSelector(selectIsAuthenticated)
    const debugData = useSelector(selectDebugData)

    const setUser = (userValue: User | null | ((prev: User | null) => User | null)) => {
        if (typeof userValue === 'function') {
            const newValue = userValue(user)
            dispatch(setUserAction(newValue))
        } else {
            dispatch(setUserAction(userValue))
        }
    }

    const setApplication = (applicationValue: Application | null | ((prev: Application | null) => Application | null)) => {
        if (typeof applicationValue === 'function') {
            const newValue = applicationValue(application)
            dispatch(setApplicationAction(newValue))
        } else {
            dispatch(setApplicationAction(applicationValue))
        }
    }

    const setMyBrokers = (myBrokersValue: IBroker[] | ((prev: IBroker[]) => IBroker[])) => {
        if (typeof myBrokersValue === 'function') {
            const newValue = myBrokersValue(myBrokers)
            dispatch(setMyBrokersAction(newValue))
        } else {
            dispatch(setMyBrokersAction(myBrokersValue))
        }
    }

    const updateDebugData = (data: DebugData) => {
        dispatch(updateDebugDataAction(data))
    }

    const loginWithRedux = async (formData: SignInData) => {
        const result = await dispatch(login(formData))

        // Check if the action was rejected and throw the error to be caught by the component
        if (login.rejected.match(result)) {
            throw result.payload
        }

        return result.payload
    }

    const signupWithRedux = async (formData: SignUpData) => {
        const result = await dispatch(signup(formData))

        // Check if the action was rejected and throw the error to be caught by the component
        if (signup.rejected.match(result)) {
            throw result.payload
        }

        return result.payload
    }

    const logoutWithRedux = () => {
        dispatch(logoutAction(undefined))
        navigate('/auth/sign-in')
    }

    const forgotPasswordWithRedux = async (formData: ForgotPasswordData) => {
        await forgotPasswordAction(formData)
    }

    const resetPasswordWithRedux = async (token: string, formData: ResetPasswordData) => {
        await dispatch(resetPasswordAction({ token, formData })).unwrap()
    }

    const verifyAuthWithRedux = async () => {
        await dispatch(verifyAuth()).unwrap()
    }

    return {
        user,
        application,
        myBrokers,
        debugData,
        updateDebugData,
        setUser,
        setApplication,
        setMyBrokers,
        verifyAuth: verifyAuthWithRedux,
        isAuthenticated,
        login: loginWithRedux,
        signup: signupWithRedux,
        logout: logoutWithRedux,
        forgotPassword: forgotPasswordWithRedux,
        resetPassword: resetPasswordWithRedux,
    }
}
