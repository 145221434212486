import { Box, CircularProgress, Typography, Stack } from '@mui/joy'
import { useState, useEffect } from 'react'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { LocalGasStation, DirectionsCar, LocationOn, Speed } from '@mui/icons-material'
import { SamsaraVehicleStatus } from '@numeo/types'
import { eldApi } from '../../api/eld-api'
import { ELDMap } from './ELDMap'

interface SamsaraSectionProps {
    vehicleId: string
    isOpen: boolean
}

// Helper function to format date
const formatTimestamp = (timestamp: Date): string => {
    const date = new Date(timestamp)
    return date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    })
}

export const SamsaraSection = ({ vehicleId, isOpen }: SamsaraSectionProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [vehicleStatus, setVehicleStatus] = useState<SamsaraVehicleStatus | null>(null)

    useEffect(() => {
        if (isOpen && !vehicleStatus) {
            fetchVehicleStatus()
        }
        // eslint-disable-next-line
    }, [isOpen])

    const fetchVehicleStatus = async (): Promise<void> => {
        try {
            setIsLoading(true)
            const status = await eldApi.getSamsaraVehicleStatus(vehicleId)
            setVehicleStatus(status[0] as SamsaraVehicleStatus)
        } catch (error) {
            console.error('Error fetching Samsara vehicle status:', error)
            setVehicleStatus(null)
        } finally {
            setIsLoading(false)
        }
    }

    // Get the latest GPS data
    const getLatestGps = () => {
        if (!vehicleStatus?.gps || vehicleStatus.gps.length === 0) return null
        return vehicleStatus.gps.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime())[0]
    }

    // Get the latest engine state
    const getLatestEngineState = () => {
        if (!vehicleStatus?.engineStates || vehicleStatus.engineStates.length === 0) return null
        return vehicleStatus.engineStates.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime())[0]
    }

    // Get the latest fuel percentage
    const getLatestFuelPercent = () => {
        if (!vehicleStatus?.fuelPercents || vehicleStatus.fuelPercents.length === 0) return null
        return vehicleStatus.fuelPercents.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime())[0]
    }

    const latestGps = getLatestGps()
    const latestEngineState = getLatestEngineState()
    const latestFuelPercent = getLatestFuelPercent()

    return (
        <Box width="300px">
            {isLoading ? (
                <Box display="flex" justifyContent="center" p={2}>
                    <CircularProgress size="sm" />
                </Box>
            ) : vehicleStatus ? (
                <>
                    <Typography level="title-sm">Samsara</Typography>
                    <Stack spacing={2}>
                        {/* ELD Map */}
                        {latestGps && <ELDMap latitude={String(latestGps.latitude)} longitude={String(latestGps.longitude)} />}
                        <Stack spacing={1.5} sx={{ px: 1 }}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <LocalShippingIcon sx={{ fontSize: 20, color: 'primary.500' }} />
                                <Typography level="body-sm">{vehicleStatus.name}</Typography>
                            </Stack>
                            {latestGps && (
                                <>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <AccessTimeIcon sx={{ fontSize: 20, color: 'primary.500' }} />
                                        <Typography level="body-sm">{formatTimestamp(latestGps.time)}</Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <LocationOn sx={{ fontSize: 20, color: 'primary.500' }} />
                                        <Typography level="body-sm" noWrap>
                                            {latestGps.reverseGeo?.formattedLocation || 'Location unavailable'}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Speed sx={{ fontSize: 20, color: 'primary.500' }} />
                                        <Typography level="body-sm">{latestGps.speedMilesPerHour.toFixed(1)} mph</Typography>
                                    </Stack>
                                </>
                            )}
                            {latestEngineState && (
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <DirectionsCar sx={{ fontSize: 20, color: 'primary.500' }} />
                                    <Typography level="body-sm">
                                        Engine: {latestEngineState.value.charAt(0).toUpperCase() + latestEngineState.value.slice(1)}
                                    </Typography>
                                </Stack>
                            )}
                            {latestFuelPercent && (
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <LocalGasStation sx={{ fontSize: 20, color: 'primary.500' }} />
                                    <Typography level="body-sm">{latestFuelPercent.value.toFixed(1)}% Fuel</Typography>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </>
            ) : (
                <Box>
                    <Typography level="title-sm" mb={1}>
                        Samsara
                    </Typography>
                    <Typography level="body-sm">No Samsara data available</Typography>
                </Box>
            )}
        </Box>
    )
}

export default SamsaraSection
