import { Box, Slider, Typography } from '@mui/joy'
import { memo, useCallback, useEffect, useState } from 'react'

interface RateNegotiationSettings {
    firstBidThreshold: number
    secondBidThreshold: number
    minGap: number
}

interface RateNegotiationTempProps {
    onValueChange?: (settings: RateNegotiationSettings) => void
    initialValues?: RateNegotiationSettings
}

const RateNegotiationTemp = ({ onValueChange, initialValues }: RateNegotiationTempProps) => {
    const defaultMinGap = initialValues?.minGap || 10
    const minFirstThumb = 20
    const minSecondThumb = 60

    const [sliderValue, setSliderValue] = useState<number[]>(() => [initialValues?.secondBidThreshold || 30, initialValues?.firstBidThreshold || 80])
    const [isUpdatingFromInitialValues, setIsUpdatingFromInitialValues] = useState(false)

    useEffect(() => {
        if (!initialValues) return

        if (Math.abs(sliderValue[1] - initialValues.firstBidThreshold) > 5 || Math.abs(sliderValue[0] - initialValues.secondBidThreshold) > 5) {
            console.log('Updating slider values:', initialValues)
            setIsUpdatingFromInitialValues(true)
            setSliderValue([initialValues.secondBidThreshold, initialValues.firstBidThreshold])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues])

    useEffect(() => {
        if (isUpdatingFromInitialValues) {
            console.log('Skipping parent notification during initialValues update')
            setIsUpdatingFromInitialValues(false)
            return
        }

        if (onValueChange) {
            const settings = {
                firstBidThreshold: sliderValue[1],
                secondBidThreshold: sliderValue[0],
                minGap: defaultMinGap,
            }
            console.log('Notifying parent:', settings)
            onValueChange(settings)
        }
    }, [sliderValue, onValueChange, defaultMinGap, isUpdatingFromInitialValues])

    const handleSliderChange = useCallback(
        (_event: Event, newValue: number | number[]) => {
            const values = [...(newValue as number[])]

            values[0] = Math.max(values[0], minFirstThumb)
            values[1] = Math.max(values[1], minSecondThumb)

            if (values[1] - values[0] < defaultMinGap) {
                if (values[0] > sliderValue[0]) {
                    values[1] = values[0] + defaultMinGap
                } else {
                    values[0] = values[1] - defaultMinGap
                }
            }

            if (values[0] !== sliderValue[0] || values[1] !== sliderValue[1]) {
                setSliderValue(values)
            }
        },
        [defaultMinGap, minFirstThumb, minSecondThumb, sliderValue]
    )

    return (
        <Box sx={{ width: '100%', mt: 1, mb: 1 }}>
            <Box sx={{ p: 3, borderRadius: 'md' }}>
                <Typography level="title-md" sx={{ mb: 3 }}>
                    Rate Negotiation
                </Typography>

                <Box sx={{ width: '80%', mx: 'auto', mt: 2, position: 'relative' }}>
                    <Slider
                        track={false}
                        value={sliderValue}
                        onChange={handleSliderChange}
                        min={10}
                        max={100}
                        disableSwap
                        valueLabelFormat={(value, index) => `${value}% / ${index === 0 ? '2-bid' : '1-bid'}`}
                        marks={[
                            { value: 0, label: '0%' },
                            { value: 30, label: '30%' },
                            { value: 80, label: '80%' },
                            { value: 100, label: '' },
                        ]}
                        valueLabelDisplay="on"
                        sx={{
                            color: '#1976d2',
                            '& .MuiSlider-rail': {
                                opacity: 0.5,
                                height: 3,
                                backgroundColor: '#555',
                            },
                            '& .MuiSlider-valueLabel': {
                                backgroundColor: '#444',
                                color: '#fff',
                                fontSize: '0.75rem',
                                fontWeight: 'bold',
                                padding: '2px 6px',
                                borderRadius: '4px',
                                whiteSpace: 'nowrap',
                                minWidth: '80px',
                                textAlign: 'center',
                            },
                            '& .MuiSlider-thumb': {
                                width: 20,
                                height: 20,
                                backgroundColor: '#fff',
                                border: '2px solid currentColor',
                                '&:focus, &:hover, &.Mui-active': {
                                    boxShadow: '0 0 0 8px rgba(25, 118, 210, 0.16)',
                                },
                            },
                            '& .MuiSlider-mark': {
                                width: 6,
                                height: 6,
                                borderRadius: '50%',
                                backgroundColor: 'currentColor',
                            },
                        }}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                        <Typography level="body-sm">Min</Typography>
                        <Typography level="body-sm">Max</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default memo(RateNegotiationTemp)
