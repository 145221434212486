import React from 'react'
import { Box, Typography } from '@mui/joy'
import { ClientLoadModel } from '@numeo/types'
import { HorizontalDirectionIcon } from 'assets/icons/DatDirectionIcons'
import { TripColumn } from '../../../LoadsTable/TripColumn'
import { RateColumn } from '../../../LoadsTable/RateColumn'
import { CompanyColumn } from '../../../LoadsTable/CompanyColumn'

interface LoadDetailsProps {
    load: ClientLoadModel
}

const LoadDetails: React.FC<LoadDetailsProps> = ({ load }) => {
    const renderLocationDetails = () => {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', p: 1 }}>
                <Typography
                    level="title-lg"
                    sx={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                    }}
                >
                    {load.origin.city}
                    <HorizontalDirectionIcon />
                    {load.destination.city}
                </Typography>
                <Typography
                    level="body-sm"
                    sx={{
                        fontSize: '1.3rem',
                        fontWeight: 'bold',
                    }}
                >
                    {' '}
                    {load.tripLength && `${load.tripLength} ml`}
                </Typography>
            </Box>
        )
    }

    return (
        <Box>
            <Typography level="title-md">Load Details</Typography>

            {/* Pickup and Delivery Details */}
            {renderLocationDetails()}

            {/* Trip Column - First Row */}
            <Box sx={{ mt: 1, width: '100%' }}>
                <TripColumn load={load} loadSearchId={load._id} />
            </Box>

            {/* Rate Column - Second Row */}
            <Box sx={{ mt: 3, width: '100%' }}>
                <RateColumn
                    load={{
                        ...load,
                        posterInfo: {
                            ...load?.posterInfo,
                            contact: {
                                ...load?.posterInfo?.contact,
                                email: load?.posterInfo?.contact?.email || '',
                            },
                        },
                    }}
                />
            </Box>

            {/* Company Column - Third Row */}
            <Box sx={{ mt: 3, width: '100%' }}>
                <CompanyColumn load={load} />
            </Box>
        </Box>
    )
}

export default LoadDetails
