import { Email, MarkEmailRead } from '@mui/icons-material'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import { IconButton, Tooltip } from '@mui/joy'
import { Badge, CircularProgress } from '@mui/material'
import { useAuth } from 'hooks/auth'
import React, { memo, useCallback, useState, createContext, useContext } from 'react'
import { sendInfoEmail } from '../assets/helpers'
import { EmailHistoryPopover } from '../EmailHistoryPopover'
import { EmailButtonProps } from './types'
import { Category } from 'hooks/useNotification'
import { EmailTemplateType } from '@numeo/types'
import { useGetQuery } from 'hooks/useGetQuery'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'

// Create a context to manage which email popover is currently open
interface EmailPopoverContextType {
    openEmailPopover: string | null
    setOpenEmailPopover: (id: string | null) => void
}

export const EmailPopoverContext = createContext<EmailPopoverContextType>({
    openEmailPopover: null,
    setOpenEmailPopover: () => {},
})

// Provider component to wrap around the table or parent component
export const EmailPopoverProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [openEmailPopover, setOpenEmailPopover] = useState<string | null>(null)

    return <EmailPopoverContext.Provider value={{ openEmailPopover, setOpenEmailPopover }}>{children}</EmailPopoverContext.Provider>
}

export const EmailButton = memo<EmailButtonProps>(
    ({ load, emailThreadId, handleEmailSent, favorite, disabled = false, broker }) => {
        const [loading, setLoading] = useState(false)
        const { application, debugData } = useAuth()
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
        const dispatch = useDispatch()
        const truckId = useGetQuery('truckId')
        const { openEmailPopover, setOpenEmailPopover } = useContext(EmailPopoverContext)

        const emailUnreadCount: number = load.emailHistory?.unreadCount || 0
        const draftAi = load.email && load.email?.draft ? load.email?.draft : null

        // Generate a unique ID for this email button
        const emailButtonId = `email-button-${load._id}`

        // Utility function to prevent event propagation
        const stopPropagation = useCallback(<T extends (e: React.MouseEvent) => void>(handler: T) => {
            return (e: React.MouseEvent) => {
                e.stopPropagation()
                handler(e)
            }
        }, [])

        const handleEmailClick = useCallback(
            async (e: React.MouseEvent) => {
                e.stopPropagation()
                setLoading(true)
                try {
                    const template = application?.temp.emailTemplate?.find((t) => t.type === EmailTemplateType.INFO)
                    const footer = application?.temp.emailFooter?.closing
                    const driver = application?.temp.spotFinder?.trucks.find((t) => t._id === truckId)?.firstDriver
                    const response = await sendInfoEmail(load, debugData.spotFinder.mockBrokerEmail, broker, footer, template, driver)
                    if (!response) {
                        throw new Error()
                    }

                    favorite()
                    dispatch(
                        addNotification({
                            id: '',
                            message: 'Email sent successfully',
                            type: 'info',
                            category: Category.Email,
                            timestamp: Date.now(),
                            topic: 'temp',
                        })
                    )

                    handleEmailSent(response.emailThreadId)
                } catch (error) {
                    dispatch(
                        addNotification({
                            id: '',
                            message: 'Failed to send email.',
                            type: 'error',
                            category: Category.Error,
                            timestamp: Date.now(),
                            topic: 'temp',
                        })
                    )
                } finally {
                    setLoading(false)
                }
            },
            // eslint-disable-next-line
            [load, debugData.spotFinder.mockBrokerEmail, handleEmailSent, broker]
        )

        const hasConnectedEmails = useCallback(() => {
            return Array.isArray(application?.integrations?.nylas?.emails) && application.integrations.nylas.emails.length > 0
        }, [application?.integrations?.nylas?.emails])

        return (
            <>
                {emailThreadId ? (
                    <>
                        <Tooltip title={!disabled && 'Click to open/close email history'}>
                            <span onClick={stopPropagation(() => {})}>
                                <IconButton
                                    onClick={(event) => {
                                        event.stopPropagation()

                                        if (openEmailPopover === emailButtonId) {
                                            // If this popover is already open, close it
                                            event.currentTarget.blur()
                                            setOpenEmailPopover(null)
                                            setAnchorEl(null)
                                        } else {
                                            // Close any other open popover first
                                            setOpenEmailPopover(emailButtonId)
                                            setAnchorEl(event.currentTarget)
                                        }
                                    }}
                                    sx={{ width: '100%' }}
                                    disabled={disabled}
                                >
                                    <Badge
                                        badgeContent={emailUnreadCount}
                                        color="error"
                                        sx={{
                                            '& .MuiBadge-badge': {
                                                fontSize: '10px',
                                                height: '16px',
                                                minWidth: '16px',
                                                padding: '0 4px',
                                            },
                                        }}
                                        invisible={disabled || emailUnreadCount === 0}
                                    >
                                        <MarkEmailRead color={disabled ? 'disabled' : 'success'} />
                                    </Badge>
                                </IconButton>
                            </span>
                        </Tooltip>

                        <EmailHistoryPopover
                            emailThreadId={emailThreadId}
                            draftAi={draftAi}
                            loadId={load._id}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl) && openEmailPopover === emailButtonId}
                            setAnchorEl={(el) => {
                                if (el === null) {
                                    setOpenEmailPopover(null)
                                }
                                setAnchorEl(el)
                            }}
                        />
                    </>
                ) : (
                    <Tooltip title={hasConnectedEmails() ? 'Click to send email for info' : 'Connect your email first'} disableInteractive>
                        <span>
                            <IconButton
                                onClick={stopPropagation(handleEmailClick)}
                                disabled={loading || !hasConnectedEmails() || disabled}
                                sx={{ width: '100%' }}
                            >
                                {loading ? (
                                    <CircularProgress size={24} />
                                ) : load.posterInfo.contact.email ? (
                                    <Email sx={{ color: hasConnectedEmails() && !disabled ? 'primary.main' : 'grey.500', opacity: 0.8 }} />
                                ) : (
                                    <ContactMailIcon
                                        sx={{ fontSize: 20, color: hasConnectedEmails() && !disabled ? 'primary.main' : 'grey.500', opacity: 0.8 }}
                                    />
                                )}
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
            </>
        )
    },
    (prevProps, nextProps) => {
        return (
            prevProps.emailThreadId === nextProps.emailThreadId &&
            prevProps.load.emailHistory?.unreadCount === nextProps.load.emailHistory?.unreadCount &&
            prevProps.handleEmailSent === nextProps.handleEmailSent &&
            prevProps.load.email?.draft === nextProps.load.email?.draft
        )
    }
)
