import { LucidEld } from './LucidEld'
import { Samsara } from './Samsara'
import { TTELD } from './TTELD'
import { Stack, Typography } from '@mui/joy'

export const ELDIntegrations = () => {
    return (
        <>
            <Typography level={'h4'} color="primary" sx={{ mb: 1 }}>
                ELD Integrations
            </Typography>
            <Typography level={'body-sm'} sx={{ mb: 1 }}>
                Easily connect and manage your ELD (Electronic Logging Device) accounts to ensure compliance and streamline your operations. This integration
                allows you to sync your truck and driver information seamlessly. Please enter your credentials to get started.
            </Typography>
            <Stack direction={'row'} width={'100%'} gap={2} justifyContent={'space-between'}>
                <TTELD />
                <LucidEld />
            </Stack>
            <Samsara />
        </>
    )
}
