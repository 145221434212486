import { useEffect, useState } from 'react'
import { eldApi } from 'pages/dashboard/pages/dispatcher/pages/spot-finder/api/eld-api'
import { Box, Typography, CircularProgress, Alert } from '@mui/joy'
import { Paper } from '@mui/material'

export default function SamsaraCallback() {
    const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading')
    const [message, setMessage] = useState<string>('Authenticating with Samsara...')
    const [details, setDetails] = useState<string>('')

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const code = urlParams.get('code')
        const state = urlParams.get('state')
        const error = urlParams.get('error')
        const storedState = sessionStorage.getItem('samsaraAuthState')

        // Check if there's an error or if state doesn't match
        if (error) {
            const errorMsg = error || 'Failed to authenticate with Samsara'
            setStatus('error')
            setMessage('Authentication Failed')
            setDetails(errorMsg)

            window.opener?.postMessage(
                {
                    type: 'SAMSARA_AUTH_ERROR',
                    error: errorMsg,
                },
                window.location.origin
            )
            return
        }

        // Verify state to prevent CSRF attacks
        if (state !== storedState) {
            const errorMsg = 'Invalid state parameter. Authentication failed.'
            setStatus('error')
            setMessage('Security Verification Failed')
            setDetails(errorMsg)

            window.opener?.postMessage(
                {
                    type: 'SAMSARA_AUTH_ERROR',
                    error: errorMsg,
                },
                window.location.origin
            )
            return
        }

        if (code) {
            // Exchange code for tokens
            setMessage('Exchanging authorization code for tokens...')

            eldApi
                .connectSamsara(code)
                .then((application) => {
                    // Success state
                    setStatus('success')
                    setMessage('Authentication Successful!')
                    setDetails('Your Samsara account has been connected successfully. You can close this window now.')

                    // Send the account data back to the parent window
                    window.opener?.postMessage(
                        {
                            type: 'SAMSARA_AUTH_SUCCESS',
                            application,
                        },
                        window.location.origin
                    )

                    // Auto-close after 3 seconds
                    setTimeout(() => {
                        window.close()
                    }, 3000)
                })
                .catch((error) => {
                    // Error state
                    const errorMsg = error.response?.data?.message || 'Failed to authenticate with Samsara'
                    setStatus('error')
                    setMessage('Authentication Failed')
                    setDetails(errorMsg)

                    window.opener?.postMessage(
                        {
                            type: 'SAMSARA_AUTH_ERROR',
                            error: errorMsg,
                        },
                        window.location.origin
                    )
                })
        }
    }, [])

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                bgcolor: '#f5f5f5',
                padding: 2,
            }}
        >
            <Paper
                sx={{
                    width: '100%',
                    maxWidth: 500,
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    boxShadow: 'md',
                }}
            >
                <img src="/samsara-logo.png" alt="Samsara Logo" style={{ height: 40, marginBottom: 16 }} />

                <Typography level="h4" textAlign="center">
                    {message}
                </Typography>

                {status === 'loading' && <CircularProgress size="lg" />}

                {status === 'success' && (
                    <Alert color="success" sx={{ width: '100%' }}>
                        {details}
                    </Alert>
                )}

                {status === 'error' && (
                    <Alert color="danger" sx={{ width: '100%' }}>
                        {details}
                    </Alert>
                )}

                {status === 'loading' && (
                    <Typography level="body-md" textAlign="center" color="neutral">
                        Please wait while we complete the authentication process...
                    </Typography>
                )}

                {status !== 'loading' && (
                    <Typography level="body-sm" textAlign="center" color="neutral">
                        {status === 'success' ? 'This window will close automatically in a few seconds.' : 'You can close this window and try again.'}
                    </Typography>
                )}
            </Paper>
        </Box>
    )
}
