import { Box, Sheet, Stack, Tabs, TabList, Tab } from '@mui/joy'
import React, { useState } from 'react'
import { CallList } from './components/CallList'
import { VoiceDemoTab } from './components/VoiceDemoTab'

const CallCenterPage: React.FC = () => {
    const [activeTab, setActiveTab] = useState<string>('calls')

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                p: 2,
                overflow: 'auto',
                height: '100%',
            }}
        >
            <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value as string)} sx={{ backgroundColor: 'background.surface' }}>
                <TabList>
                    <Tab value="calls">Call Center</Tab>
                    <Tab value="demo">Demo Voice Agent</Tab>
                </TabList>
            </Tabs>

            <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                {activeTab === 'calls' && (
                    <Sheet variant="outlined" sx={{ borderRadius: 'sm', overflow: 'hidden', height: '100%' }}>
                        <Stack spacing={2} sx={{ height: '100%' }}>
                            <CallList />
                        </Stack>
                    </Sheet>
                )}
                {activeTab === 'demo' && <VoiceDemoTab />}
            </Box>
        </Box>
    )
}

export default CallCenterPage
