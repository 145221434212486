import { Box, Typography, Button, Input, FormControl, FormLabel, FormHelperText } from '@mui/joy'
import { useEffect, useState } from 'react'
import { Category } from 'hooks/useNotification'
import { useAuth } from 'hooks/auth'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'
import { updateSchedulerSettings } from '../../../api/SchedulerSettings'

export const UpdaterScheduler = () => {
    const dispatch = useDispatch()
    const { application, setApplication } = useAuth()
    const [schedulerTime, setSchedulerTime] = useState<string>('')
    const [initialSchedulerTime, setInitialSchedulerTime] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    useEffect(() => {
        if (!application?.settings.scheduler) return
        setSchedulerTime(application?.settings.scheduler.toString() || '')
        setInitialSchedulerTime(application?.settings.scheduler.toString() || '')
    }, [application])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value

        // Allow empty string
        if (inputValue === '') {
            setSchedulerTime('')
            setError('')
            return
        }

        const value = Number(inputValue)
        if (!isNaN(value) && value >= 0) {
            setSchedulerTime(inputValue)
            setError('')
        } else {
            setError('Please enter a valid positive number')
        }
    }

    const handleCancel = () => {
        setSchedulerTime(initialSchedulerTime)
        setError('')
    }

    const saveSchedulerSettings = async () => {
        try {
            setIsLoading(true)
            // Convert empty string to 0 or use the numeric value
            const timeValue = schedulerTime === '' ? 0 : Number(schedulerTime)
            const response = await updateSchedulerSettings(timeValue)

            if (response?.status === 200) {
                setInitialSchedulerTime(schedulerTime)
                // Only update application if it exists
                if (application) {
                    setApplication({
                        ...application,
                        settings: {
                            ...application.settings,
                            scheduler: timeValue,
                        },
                    })
                }
                dispatch(
                    addNotification({
                        id: '',
                        category: Category.Settings,
                        message: 'Scheduler settings updated successfully',
                        timestamp: Date.now(),
                        topic: 'temp',
                        type: 'info',
                    })
                )
            } else {
                dispatch(
                    addNotification({
                        id: '',
                        category: Category.Error,
                        message: 'Failed to update scheduler settings',
                        timestamp: Date.now(),
                        topic: 'temp',
                        type: 'error',
                    })
                )
            }
        } catch (error) {
            console.error('Error saving scheduler settings:', error)
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: 'Error updating scheduler settings',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'error',
                })
            )
        } finally {
            setIsLoading(false)
        }
    }

    const hasChanges = schedulerTime !== initialSchedulerTime

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                bgcolor: 'background.surface',
                borderRadius: 'sm',
                p: 2,
                boxShadow: 'sm',
                width: '50%',
            }}
        >
            <Box sx={{ mb: 2 }}>
                <Typography level="h4" component="h2">
                    Updater Scheduler Settings
                </Typography>
                <Typography level="body-sm">Configure how often the system should check for updates (in minutes).</Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <FormControl error={!!error}>
                    <FormLabel>Scheduler Interval (minutes)</FormLabel>
                    <Input type="text" value={schedulerTime} onChange={handleInputChange} placeholder="Enter time in minutes" />
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 1,
                        mt: 1,
                    }}
                >
                    <Button variant="outlined" color="neutral" size="sm" onClick={handleCancel} disabled={!hasChanges}>
                        Cancel
                    </Button>
                    <Button size="sm" onClick={saveSchedulerSettings} loading={isLoading} disabled={!hasChanges || !!error}>
                        Save Changes
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
