import { StarBorder } from '@mui/icons-material'
import { Box, Divider, Link, Stack, Typography } from '@mui/joy'
import { ClientLoadModel } from '@numeo/types'
import { VerticalDirectionIcon } from 'assets/icons/DatDirectionIcons'
import React from 'react'
import { formatPhoneNumber } from '../assets/helpers'

/**
 * TripColumn component displays trip information when the load row is expanded.
 * This includes:
 * - Pickup and delivery details
 * - Contact information
 * - Equipment requirements
 * - Additional comments
 */

export const TripColumn: React.FC<TripColumnProps> = ({ load }) => {
    const renderAvailabilityDates = () => {
        return new Date(load.earliestAvailability).toDateString().split(' ').slice(1, 3).join(' ') ===
            new Date(load.latestAvailability).toDateString().split(' ').slice(1, 3).join(' ')
            ? new Date(load.earliestAvailability).toDateString().split(' ').slice(1, 3).join(' ')
            : `${new Date(load.earliestAvailability).toDateString().split(' ').slice(1, 3).join(' ')} - ${new Date(load.latestAvailability).toDateString().split(' ').slice(1, 3).join(' ')}`
    }

    const renderTripDetails = () => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                {/* Icon Section */}
                <VerticalDirectionIcon />

                {/* Origin and Destination Details */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: 1,
                    }}
                >
                    {/* Origin */}
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            level="body-md"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.1rem',
                            }}
                        >
                            {`${load.origin.city}, ${load.origin.stateProv} ${load.originDeadheadMiles?.miles ? '(' + load.originDeadheadMiles?.miles + ')' : ''}`}
                        </Typography>
                        <Typography>{renderAvailabilityDates()}</Typography>
                    </Box>

                    {/* Destination */}
                    <Typography
                        level="body-md"
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '1.1rem',
                        }}
                    >
                        {`${load.destination.city}, ${load.destination.stateProv}`}
                    </Typography>
                </Box>
            </Box>
        )
    }

    const renderContactInfo = () => {
        const { posterInfo } = load
        if (!posterInfo?.contact) return null

        const phoneNumber = posterInfo.contact.phoneNumber
        return (
            <Box>
                <Typography level="body-md" fontWeight="bold">
                    Contact Information
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    {posterInfo.contact.email && (
                        <Typography level="body-sm">
                            <Link href={`mailto:${posterInfo.contact.email}`}>{posterInfo.contact.email}</Link>
                        </Typography>
                    )}
                    {phoneNumber && (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography level="body-sm">
                                <Link href={`tel:${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</Link>
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        )
    }

    const renderEquipmentDetails = () => {
        if (!load.equipmentType) return null

        return (
            <Box sx={{ width: '10rem' }}>
                {/* Section Title */}
                <Typography level="body-md" fontWeight="bold" mb={1}>
                    Equipment
                </Typography>
                {/* Equipment Details */}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                    {/* Truck */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                            level="body-sm"
                            sx={{
                                fontSize: '0.8rem',
                                fontWeight: 'bold',
                            }}
                        >
                            Truck
                        </Typography>
                        <Typography
                            level="body-sm"
                            sx={{
                                fontSize: '0.8rem',
                            }}
                        >
                            {load.equipmentType || '—'}
                        </Typography>
                    </Box>
                    {/* Load */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                            level="body-sm"
                            sx={{
                                fontSize: '0.8rem',
                                fontWeight: 'bold',
                            }}
                        >
                            Load
                        </Typography>
                        <Typography
                            level="body-sm"
                            sx={{
                                fontSize: '0.8rem',
                            }}
                        >
                            {load.shipmentDetails?.fullPartial || '—'}
                        </Typography>
                    </Box>
                    {/* Length */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                            level="body-sm"
                            sx={{
                                fontSize: '0.8rem',
                                fontWeight: 'bold',
                            }}
                        >
                            Length
                        </Typography>
                        <Typography
                            level="body-sm"
                            sx={{
                                fontSize: '0.8rem',
                            }}
                        >
                            {load.shipmentDetails?.maximumLengthFeet ? `${load.shipmentDetails?.maximumLengthFeet} ft` : '—'}
                        </Typography>
                    </Box>
                    {/* Weight */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                            level="body-sm"
                            sx={{
                                fontSize: '0.8rem',
                                fontWeight: 'bold',
                            }}
                        >
                            Weight
                        </Typography>
                        <Typography
                            level="body-sm"
                            sx={{
                                fontSize: '0.8rem',
                            }}
                        >
                            {load.shipmentDetails?.maximumWeightPounds ? `${load.shipmentDetails?.maximumWeightPounds.toLocaleString()} lbs` : '—'}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        )
    }

    const renderCommentsDetails = () => {
        return (
            <Stack direction={'column'}>
                <Typography level="body-md" fontWeight="bold">
                    Comments
                </Typography>
                <Typography level="body-sm">{load.comments || '—'}</Typography>
                {load.emailHistory?.details && (
                    <>
                        <Divider />
                        <StarBorder />
                        {Object.entries(load.emailHistory.details)
                            .filter(
                                ([key, value]) =>
                                    !['_id', 'id', '__v'].includes(key) && // Exclude system fields
                                    value !== null && // Remove nulls
                                    value !== undefined && // Remove undefined
                                    value !== '' // Remove empty strings
                            )
                            .map(([key, value]) => (
                                <Box key={key} sx={{ display: 'flex', gap: 0.5 }}>
                                    <Typography sx={{ textTransform: 'capitalize' }} level="body-sm" fontWeight="lg">
                                        {key}:
                                    </Typography>
                                    <Typography level="body-sm">{value || '—'}</Typography>
                                </Box>
                            ))}
                    </>
                )}
            </Stack>
        )
    }

    const renderPostersReferenceId = () => {
        return (
            <Box>
                <Typography level="body-md" fontWeight="bold">
                    Posters Reference ID:
                    <Typography level="body-sm">{' ' + load.postersReferenceId || '—'}</Typography>
                </Typography>
            </Box>
        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%',
            }}
        >
            {/* Header */}
            <Typography
                sx={{
                    color: 'text.secondary',
                    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#2b2b2b' : '#f0f0f0'),
                    px: 1,
                    fontWeight: 'bold',
                }}
            >
                Trip
            </Typography>
            <Stack direction={{ sm: 'column', md: 'row' }} justifyContent={'space-between'} px={1} alignItems={'center'}>
                {renderTripDetails()}
                {renderEquipmentDetails()}
            </Stack>

            {renderContactInfo()}

            {load.comments ? renderCommentsDetails() : null}
            {load.postersReferenceId ? renderPostersReferenceId() : null}
        </Box>
    )
}
/**
 * Props for the TripColumn component that shows expanded load information
 */
export interface TripColumnProps {
    load: ClientLoadModel
    loadSearchId: string
}
