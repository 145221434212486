// components/NotificationCenter.tsx
import { createPortal } from 'react-dom'
import NotificationsIcon from '@mui/icons-material/Notifications'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, IconButton, Sheet, Stack, Typography, Tooltip } from '@mui/joy'
import { useEffect, useRef, useState, useMemo } from 'react'
import { keyframes } from '@emotion/react'
import CloseIcon from '@mui/icons-material/Close'
import { useNotification, INotification, Category } from 'hooks/useNotification'
import { useNavigate } from 'react-router-dom'
import { Chip } from '@mui/material'
import { WebSocketStatus } from '@numeo/types'
import React from 'react'

// Pulse animation for the border
const pulse = keyframes`
  0% { border-color: transparent; }
  50% { border-color: currentColor; }
  100% { border-color: transparent; }
`

// Types for notification status and special notification topics
type NotificationStatus = 'opening' | 'showing' | 'closing' | 'none'
type SpecialTopic = 'temp' | 'duplicate_connection' | 'permanent'

// Constants for notification appearance
const NOTIFICATION_COLORS = {
    info: {
        background: 'success.softBg',
        border: 'success.500',
        text: 'success.600',
    },
    warning: {
        background: 'warning.softBg',
        border: 'warning.500',
        text: 'warning.700',
    },
    error: {
        background: 'danger.softBg',
        border: 'danger.500',
        text: 'danger.600',
    },
}

// Constants for animation timing
const ANIMATION_DURATION = 300 // ms
const DISPLAY_TIMES = {
    temp: 2000, // ms
    duplicate_connection: 8000, // ms
    permanent: Infinity, // permanent notifications stay until explicitly removed
}

export const NotificationComponent = () => {
    const navigate = useNavigate()
    const { wsStatus, notifications, clearAll, clearNotification } = useNotification()

    // UI state
    const [isHovered, setIsHovered] = useState(false)
    const [isExpanded, setIsExpanded] = useState(false)
    const timeoutRef = useRef<NodeJS.Timeout>()

    // Notification state
    const [newNotifications, setNewNotifications] = useState<INotification[]>([])
    const [tempNotification, setTempNotification] = useState<INotification | null>(null)
    const [tempNotificationStatus, setTempNotificationStatus] = useState<NotificationStatus>('none')
    const [specialNotificationTopic, setSpecialNotificationTopic] = useState<SpecialTopic>('temp')

    /**
     * Handle special notifications display and animation
     */
    const handleSpecialNotification = (specialNotif: INotification) => {
        // Start opening animation
        setTempNotificationStatus('opening')
        setTempNotification(specialNotif)
        setSpecialNotificationTopic(specialNotif.topic as SpecialTopic)
        setIsExpanded(true)

        // After opening animation completes, show the notification
        setTimeout(() => {
            setTempNotificationStatus('showing')
        }, ANIMATION_DURATION)

        // Get display time based on notification type
        const displayTime = DISPLAY_TIMES[specialNotif.topic as SpecialTopic] || DISPLAY_TIMES.temp

        // For permanent notifications, don't set up auto-closing
        if (specialNotif.topic === 'permanent' || specialNotif.topic === 'duplicate_connection') {
            return // Exit early, don't set up closing timers
        }

        // Start closing animation after display time
        setTimeout(() => {
            setTempNotificationStatus('closing')

            // Remove notification after closing animation completes
            setTimeout(() => {
                setTempNotificationStatus('none')
                setTempNotification(null)
                setIsExpanded(false)
                clearNotification(specialNotif)
            }, ANIMATION_DURATION)
        }, displayTime)
    }

    // Process new notifications
    useEffect(() => {
        if (notifications.length === 0) return

        // Identify new notifications
        const previouslyHighlighted = new Set(newNotifications)
        const newlyAdded = notifications.filter((n) => !previouslyHighlighted.has(n))

        if (newlyAdded.length === 0) return

        setNewNotifications(newlyAdded)

        // Find special notifications with priority order
        const duplicateConnectionNotif = newlyAdded.find((n) => n.topic === 'duplicate_connection')
        const permanentNotif = newlyAdded.find((n) => n.topic === 'permanent')
        const tempNotif = newlyAdded.find((n) => n.topic === 'temp')
        const specialNotif = duplicateConnectionNotif || permanentNotif || tempNotif

        // Handle special notification if found
        if (specialNotif) {
            handleSpecialNotification(specialNotif)
        }

        // Play notification sound (commented out for now)
        // try {
        //     const ding = new Audio('/sound-ding.mp3');
        //     ding.play().catch(err => console.error('Error playing notification sound:', err));
        // } catch (error) {
        //     console.error('Error loading notification sound:', error);
        // }
        // eslint-disable-next-line
    }, [notifications])

    // Clear highlight from new notifications after a delay
    useEffect(() => {
        if (!isExpanded && !isHovered) return

        const timer = setTimeout(() => {
            setNewNotifications([]) // Clear highlighted notifications
        }, 1000)

        return () => clearTimeout(timer)
    }, [isExpanded, isHovered])

    // Effect to handle removal of permanent notifications
    useEffect(() => {
        // If we have a permanent notification displayed but it's no longer in the notifications list
        if (tempNotification && tempNotification.topic === 'permanent') {
            const stillExists = notifications.some((n) => n.id === tempNotification.id)
            if (!stillExists) {
                // The permanent notification was removed, close it
                setTempNotificationStatus('closing')

                // Remove notification after closing animation completes
                setTimeout(() => {
                    setTempNotificationStatus('none')
                    setTempNotification(null)
                    setIsExpanded(false)
                }, ANIMATION_DURATION)
            }
        }
    }, [notifications, tempNotification])

    // Event handlers
    const handleMouseEnter = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            setIsHovered(false)
            setIsExpanded(false)
        }, 200) // Small delay to prevent accidental closing
    }

    const clearNotifications = (e: React.MouseEvent) => {
        e.stopPropagation()
        clearAll()
        setNewNotifications([])
    }

    // Determine dot color and animation based on notification state
    const { dotColor, animation } = useMemo(() => {
        if (tempNotification) {
            // Set color based on notification type
            let color
            switch (tempNotification.type) {
                case 'info':
                    color = 'success.500'
                    break
                case 'warning':
                    color = 'warning.500'
                    break
                case 'error':
                    color = 'danger.500'
                    break
                default:
                    color = 'success.500'
            }
            return { dotColor: color, animation: `${pulse} 2s infinite` }
        }

        // Set color based on WebSocket status
        switch (wsStatus) {
            case WebSocketStatus.CONNECTED:
                return { dotColor: 'success.500', animation: 'unset' }
            case WebSocketStatus.CONNECTING:
                return { dotColor: 'warning.400', animation: `${pulse} 2s infinite` }
            case WebSocketStatus.DISCONNECTED:
                return { dotColor: 'warning.500', animation: `${pulse} 2s infinite` }
            default:
                return { dotColor: 'danger.500', animation: `${pulse} 2s infinite` }
        }
    }, [tempNotification, wsStatus])

    /**
     * Format timestamp as relative time
     */
    const formatTimeAgo = (timestamp: number): string => {
        const now = new Date()
        const date = new Date(timestamp)
        const seconds = Math.floor((now.getTime() - date.getTime()) / 1000)

        if (seconds < 60) return 'just now'

        const minutes = Math.floor(seconds / 60)
        if (minutes < 60) return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`

        const hours = Math.floor(minutes / 60)
        if (hours < 24) return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`

        const days = Math.floor(hours / 24)
        if (days < 7) return `${days} ${days === 1 ? 'day' : 'days'} ago`

        return date.toLocaleDateString()
    }

    // Filter out special notifications and sort by timestamp (newest first)
    const displayedNotifications = useMemo(() => {
        return [...notifications].filter((n) => !['temp', 'duplicate_connection', 'permanent'].includes(n.topic)).sort((a, b) => b.timestamp - a.timestamp)
    }, [notifications])

    const notificationContent = (
        <Box
            sx={{
                position: 'fixed',
                top: 8,
                right: 8,
                zIndex: 1400,
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    bottom: -8,
                    left: -8,
                    zIndex: -1,
                },
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Sheet
                id="notification-slug"
                variant="soft"
                sx={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 'xl',
                    border: '2px solid',
                    borderColor: dotColor,
                    cursor: 'pointer',
                    boxShadow: 'sm',

                    '&:hover': {
                        boxShadow: 'lg',
                    },
                    width: tempNotification
                        ? tempNotificationStatus === 'closing' || tempNotificationStatus === 'opening'
                            ? '40px'
                            : specialNotificationTopic === 'duplicate_connection'
                              ? '650px'
                              : '300px'
                        : isHovered
                          ? 'auto'
                          : 'fit-content',
                    padding: isHovered ? '6px 48px 6px 12px' : '6px',
                    animation,
                    animationTimingFunction: 'ease-in-out',
                    color: dotColor,
                    transform: tempNotification ? 'scale(0.98)' : 'scale(1)',
                    opacity: 1,
                    backdropFilter: 'blur(8px)',
                    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1), width 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                }}
                onClick={() => {
                    const ringCentralNotification = notifications.find((n) => n.category === Category.RingCentral)
                    if (ringCentralNotification?.onClick) {
                        ringCentralNotification.onClick()
                    }
                }}
            >
                <NotificationsIcon
                    sx={{
                        fontSize: 20,
                        mr: tempNotification ? (tempNotificationStatus === 'closing' || tempNotificationStatus === 'opening' ? 0 : 1) : isHovered ? 1 : 0,
                        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                    }}
                />

                {/* Show temp notification message in icon */}
                {tempNotification ? (
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{
                            opacity: tempNotificationStatus === 'closing' || tempNotificationStatus === 'opening' ? 0 : 1,
                            width: tempNotificationStatus === 'closing' || tempNotificationStatus === 'opening' ? 0 : '100%',
                            overflow: 'hidden',
                            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                            transform:
                                tempNotification && (tempNotificationStatus === 'closing' || tempNotificationStatus === 'opening')
                                    ? 'scale(0.95) translateX(-10px)'
                                    : 'scale(1) translateX(0)',
                        }}
                    >
                        <Typography
                            level="body-sm"
                            sx={{
                                fontWeight: 'bold',
                                color: NOTIFICATION_COLORS[tempNotification.type]?.text || 'text.primary',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                flexGrow: 1,
                            }}
                        >
                            {tempNotification.message}
                        </Typography>

                        {/* Add refresh button for duplicate connection notifications */}
                        {tempNotification.topic === 'duplicate_connection' && (
                            <Tooltip title="Refresh page" placement="top">
                                <IconButton
                                    size="sm"
                                    variant="soft"
                                    color="primary"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        window.location.reload()
                                    }}
                                >
                                    <RefreshIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>
                ) : (
                    <>
                        {/* Show new notification count badge */}
                        {newNotifications.filter((n) => !['temp', 'duplicate_connection'].includes(n.topic)).length > 0 && !isHovered && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '-6px',
                                    right: '-6px',
                                    backgroundColor: 'danger.500',
                                    borderRadius: '50%',
                                    color: 'white',
                                    padding: '2px 6px',
                                    fontSize: '10px',
                                    fontWeight: 'bold',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                                    border: '1px solid white',
                                    minWidth: '18px',
                                    height: '18px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {newNotifications.filter((n) => !['temp', 'duplicate_connection'].includes(n.topic)).length}
                            </Box>
                        )}

                        {/* Regular notification count text */}
                        <Typography
                            level="body-sm"
                            sx={{
                                opacity: isHovered ? 1 : 0,
                                width: isHovered ? 'auto' : 0,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                                transform: isHovered ? 'scale(1)' : 'scale(0.95)',
                            }}
                        >
                            {displayedNotifications.length > 0 ? displayedNotifications.length : 'No'} new notifications
                        </Typography>
                    </>
                )}

                {/* Close button */}
                {isHovered && notifications.length > 0 && (
                    <Tooltip title="Clear all" placement="bottom">
                        <IconButton
                            size="sm"
                            variant="plain"
                            sx={{
                                borderRadius: 'xl',
                                border: '2px solid',
                                borderColor: 'neutral.100',
                                position: 'absolute',
                                right: 4,
                                '&:hover': { bgcolor: 'background.level2' },
                            }}
                            onClick={clearNotifications}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
            </Sheet>

            {/* Dropdown menu */}
            {!tempNotification && (
                <Sheet
                    id="notification-dropdown"
                    variant="outlined"
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: '100%',
                        mt: 1,
                        width: 360,
                        borderRadius: 'lg',
                        boxShadow: 'md',
                        transition: 'all 0.3s ease',
                        transform: (isHovered || isExpanded) && notifications.length > 0 ? 'scale(1) translateY(0)' : 'scale(0.95) translateY(-8px)',
                        opacity: (isHovered || isExpanded) && notifications.length > 0 ? 1 : 0,
                        pointerEvents: (isHovered || isExpanded) && notifications.length > 0 ? 'auto' : 'none',
                        transformOrigin: 'top',
                    }}
                >
                    <Box sx={{ p: 1.5, borderBottom: '1px solid', borderColor: 'divider' }}>
                        <Typography level="title-md" sx={{ fontWeight: 'bold' }}>
                            Notifications
                        </Typography>
                    </Box>
                    <Stack
                        spacing={1.5}
                        p={1.5}
                        sx={{
                            maxHeight: '600px',
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': { width: '6px' },
                            '&::-webkit-scrollbar-track': { background: 'transparent' },
                            '&::-webkit-scrollbar-thumb': { background: 'text.secondary', borderRadius: '3px' },
                        }}
                    >
                        {displayedNotifications.length > 0 ? (
                            displayedNotifications.map((notification, index) => (
                                <Sheet
                                    key={`${notification.timestamp}-${index}`}
                                    variant="soft"
                                    onClick={
                                        notification.onClick ||
                                        (() => {
                                            clearNotification(notification)
                                            notification.path && navigate(notification.path)
                                        })
                                    }
                                    sx={{
                                        p: 1,
                                        borderRadius: 'md',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            bgcolor: 'background.level2',
                                            transform: 'translateY(-2px)',
                                            boxShadow: 'sm',
                                        },
                                        transition: 'all 0.2s ease',
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        bgcolor: NOTIFICATION_COLORS[notification.type]?.background || 'background.surface',
                                        borderLeft: '4px solid',
                                        borderLeftColor: NOTIFICATION_COLORS[notification.type]?.border || 'primary.500',
                                    }}
                                >
                                    {/* Category indicator */}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            width: '20px',
                                            height: '20px',
                                            bgcolor: NOTIFICATION_COLORS[notification.type]?.border || 'primary.500',
                                            clipPath: 'polygon(100% 0, 0 0, 100% 100%)',
                                            opacity: 0.7,
                                        }}
                                    />

                                    <Stack>
                                        <Stack direction="row" alignItems="center" width={'100%'} justifyContent={'space-between'}>
                                            <Typography
                                                level="title-md"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: NOTIFICATION_COLORS[notification.type]?.text || 'text.primary',
                                                }}
                                            >
                                                {notification.message}
                                            </Typography>
                                            <Chip
                                                label={notification.topic}
                                                size="small"
                                                sx={{
                                                    borderRadius: 'xl',
                                                    fontSize: '0.8rem',
                                                    height: '20px',
                                                }}
                                                color="primary"
                                            />
                                            <Typography
                                                level="body-xs"
                                                sx={{
                                                    color: 'text.tertiary',
                                                    fontStyle: 'italic',
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                {formatTimeAgo(notification.timestamp)}
                                            </Typography>
                                        </Stack>

                                        {notification.secondaryMessage && (
                                            <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                                                {notification.secondaryMessage.split('\n').map((line, index) => (
                                                    <React.Fragment key={index}>
                                                        {line}
                                                        {notification.secondaryMessage && index !== notification.secondaryMessage.split('\n').length - 1 && (
                                                            <br />
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </Typography>
                                        )}
                                    </Stack>
                                </Sheet>
                            ))
                        ) : (
                            <Typography level="body-sm" sx={{ textAlign: 'center', color: 'text.secondary', py: 2 }}>
                                No new notifications
                            </Typography>
                        )}
                    </Stack>
                </Sheet>
            )}
        </Box>
    )

    return createPortal(notificationContent, document.body)
}
