import { Warning } from '@mui/icons-material'
import { Tooltip } from '@mui/joy'
import { ClientLoadModel } from '@numeo/types'

export interface WarningsCellProps {
    emailHistory?: ClientLoadModel['emailHistory']
}

export const WarningsCell: React.FC<WarningsCellProps> = ({ emailHistory }) => {
    if (!emailHistory?.warnings?.length) return '—'

    return (
        <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{emailHistory.warnings.map((warning) => `- ${warning}`).join('\n')}</div>}>
            <Warning color="warning" />
        </Tooltip>
    )
}
