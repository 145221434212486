import React, { useState, useEffect, useMemo } from 'react'
import { Box, Card, IconButton, Stack, Typography, Tooltip } from '@mui/joy'
import { Popover, CircularProgress } from '@mui/material'
import Close from '@mui/icons-material/Close'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Email as EmailIcon } from '@mui/icons-material'
import { useAuth } from 'hooks/auth'
import axios from 'axios'
import { BrokerDetails, Email, EmailTemplateType, SearchBrokerRateConfResult } from '@numeo/types'
import { EmailHeader } from 'pages/dashboard/pages/dispatcher/pages/dispatcher-emails/components/EmailContent/components/EmailCard/components'
import {
    EmailEditor,
    EmailEditorProps,
} from 'pages/dashboard/pages/dispatcher/pages/dispatcher-emails/components/EmailContent/components/EmailCard/components/EmailEditor'
import EmailBody from 'components/common/EmailBody'
import { useDispatch } from 'react-redux'
import { Category } from 'hooks/useNotification'
import { addNotification } from 'store/notificationSlice'
import { createBrokerMessage } from '../assets/helpers'

interface BrokerEmailPopoverProps {
    broker: BrokerDetails
    rateConf: SearchBrokerRateConfResult
}

const BrokerEmailPopover: React.FC<BrokerEmailPopoverProps> = ({ broker, rateConf }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [emails, setEmails] = useState<Email[]>([])
    const [expandedThreads, setExpandedThreads] = useState(new Set())
    const [refreshTrigger, setRefreshTrigger] = useState<number>(0)
    const [loading, setLoading] = useState(false)
    const [editedSubject, setEditedSubject] = useState('')
    const [editedBody, setEditedBody] = useState('')
    const [emailSignature, setEmailSignature] = useState('')
    const [emailThreadId, setEmailThreadId] = useState<string | null>(rateConf.emailThreadId || null)
    const dispatch = useDispatch()

    const { user, application } = useAuth()

    // Reset state when unmounting
    useEffect(() => {
        return () => {
            setEditedBody('')
            setEditedSubject('')
            setExpandedThreads(new Set())
        }
    }, [])

    const handleEditorChange = (newContent: string) => {
        setEditedBody(newContent)
    }

    const fullContent = useMemo(() => {
        const currentBody = editedBody || ''
        // Only append signature if it's not already in the body
        if (currentBody.includes(emailSignature)) {
            return currentBody
        }

        const newBody = `${currentBody}<br>${emailSignature || ''}`
        setEditedBody(newBody)

        return `${currentBody}<br>${emailSignature || ''}`
        // eslint-disable-next-line
    }, [emailSignature, editedBody])

    const fetchFooter = async () => {
        try {
            const emailFooter = application?.temp?.emailFooter?.closing || ''
            setEmailSignature(emailFooter)
        } catch (error) {
            console.error('Error fetching footer:', error)
        }
    }

    useEffect(() => {
        if (anchorEl) {
            fetchFooter()

            if (emails.length >= 1) {
                setEditedSubject(`RE: ${emails[emails.length - 1].emailData.object.subject}`)
                setEditedBody('')
            } else {
                setEditedSubject(`Inquiry about load from ${rateConf.route[0]?.location?.city || 'N/A'}, ${rateConf.route[0]?.location?.state || 'N/A'}`)
                // Create a default email template with correct newlines
                const savedTemplate = application?.temp?.emailTemplate?.find((temp) => temp.type === EmailTemplateType.BROKER)
                const template = application && savedTemplate?.body ? createBrokerMessage(broker, rateConf, application, savedTemplate?.body) : null
                const defaultTemplate = `Hi ,
    
    <br>This is ${user?.name || 'a dispatcher'} from ${application?.projectName}. I noticed that we previously worked together on a load out of ${rateConf.route[0]?.location?.city}, ${rateConf.route[0]?.location?.state}, and you had sent us a rate confirmation for that origin.
    I’m currently looking for another load out of ${rateConf.route[1]?.location?.city}, ${rateConf.route[1]?.location?.state}. Do you happen to have anything available from that area at the moment?
    Please let me know if there’s anything you can offer. Looking forward to working with you again!`
                setEditedBody(template || defaultTemplate)
            }
        }
        // eslint-disable-next-line
    }, [anchorEl, application, rateConf, emails])

    useEffect(() => {
        const fetchEmails = async () => {
            if (emailThreadId && anchorEl) {
                setLoading(true)
                try {
                    const response = await axios.get(`/email/threadId/${emailThreadId}`)
                    const fetchedEmails = response.data
                    const sortedEmails = [...fetchedEmails].sort((a, b) => (a.emailData?.object?.date || 0) - (b.emailData?.object?.date || 0))
                    setEmails(sortedEmails)
                    if (sortedEmails.length > 0) {
                        const lastTwoIndices = new Set([sortedEmails.length - 2, sortedEmails.length - 1].filter((i) => i >= 0))
                        setExpandedThreads(lastTwoIndices)
                    }
                } catch (error) {
                    console.error('Error fetching emails:', error)
                } finally {
                    setLoading(false)
                }
            } else {
                setEmails([])
            }
        }

        fetchEmails()
    }, [emailThreadId, anchorEl, refreshTrigger])

    const toggleThread = (index: number) => {
        setExpandedThreads((prev) => {
            const newSet = new Set(prev)
            if (newSet.has(index)) {
                newSet.delete(index)
            } else {
                newSet.add(index)
            }
            return newSet
        })
    }

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        if (anchorEl) {
            anchorEl.blur()
            setTimeout(() => setAnchorEl(null), 0)
        }
    }

    const handleSendEmail = async () => {
        if (!editedSubject || !editedBody) {
            return
        }

        setLoading(true)
        try {
            // Call the broker analytics service endpoint to send the email
            const response = await axios.post('/broker-analytics/rate-conf/send-email', {
                subject: editedSubject,
                htmlBody: editedBody,
                rateConfId: rateConf.id,
                projectName: application?.projectName,
            })

            dispatch(
                addNotification({
                    id: '',
                    message: 'Your email has been sent successfully',
                    topic: 'temp',
                    type: 'info',
                    category: Category.Broker,
                    timestamp: Date.now(),
                })
            )

            // Reset fields
            setEditedBody('')
            setEditedSubject('')

            // Update thread ID first if needed, then trigger refresh only once
            if (response.data.threadId) {
                setEmailThreadId(response.data.threadId)
            } else {
                // Only trigger refresh if threadId didn't change
                setRefreshTrigger((prev) => prev + 1)
            }
        } catch (err) {
            dispatch(
                addNotification({
                    id: '',
                    message: 'Failed to send email. Please try again.',
                    topic: 'temp',
                    type: 'error',
                    category: Category.Broker,
                    timestamp: Date.now(),
                })
            )
        } finally {
            setLoading(false)
        }
    }

    const isEmailFromYou = (from?: string) => {
        if (!from || !application?.integrations?.nylas?.emails) return false
        return application.integrations.nylas.emails.find((email) => email.email === from)
    }

    useEffect(() => {
        if (emails.length > 0 && emails[0].emailData?.object?.subject) {
            setEditedSubject(`RE: ${emails[0].emailData.object.subject}`)
        }
    }, [emails])

    const open = Boolean(anchorEl)

    const emailEditorProps: EmailEditorProps = {
        subject: {
            value: editedSubject,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setEditedSubject(e.target.value),
        },
        textEditor: {
            value: fullContent,
            onChange: handleEditorChange,
        },
        onSendClick: handleSendEmail,
    }

    return (
        <>
            <Tooltip title="Send Email to Broker">
                <IconButton size="sm" variant="soft" color="primary" onClick={handleOpen} disabled={!broker.contact?.email}>
                    <EmailIcon />
                </IconButton>
            </Tooltip>

            <Popover
                open={open}
                anchorEl={anchorEl}
                disableRestoreFocus
                disableAutoFocus
                disableEnforceFocus
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                slotProps={{
                    paper: {
                        onClick: (e) => e.stopPropagation(),
                    },
                }}
                sx={{
                    pointerEvents: 'none',
                    '& .MuiPopover-paper': {
                        pointerEvents: 'auto',
                    },
                }}
            >
                <Box
                    sx={{
                        maxHeight: 500,
                        overflowY: 'auto',
                        width: 700,
                        minHeight: 300,
                        backgroundColor: 'background.level1',
                        borderRadius: '6px',
                        p: 1,
                    }}
                >
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 10,
                            zIndex: 1,
                        }}
                    >
                        <Close />
                    </IconButton>
                    <Stack spacing={2} sx={{ height: '100%' }}>
                        {loading ? (
                            <Box sx={{ display: 'grid', placeContent: 'center', height: '100%' }}>
                                <Typography
                                    level="body-sm"
                                    sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}
                                >
                                    <CircularProgress size={16} /> Loading emails...
                                </Typography>
                            </Box>
                        ) : (
                            <Stack spacing={1} sx={{ display: 'flex', flexDirection: 'column' }}>
                                {emails?.length > 0 ? (
                                    emails
                                        .sort((a, b) => (a.emailData?.object?.date || 0) - (b.emailData?.object?.date || 0))
                                        .map((email, index) => {
                                            const isFromMe = isEmailFromYou(email.emailData?.object?.from?.[0]?.email)
                                            return (
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: isFromMe ? 'flex-end' : 'flex-start',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Card
                                                        variant="outlined"
                                                        sx={{
                                                            p: 1.5,
                                                            borderRadius: 'md',
                                                            width: '90%',
                                                        }}
                                                    >
                                                        <Stack spacing={1}>
                                                            <EmailHeader
                                                                from={{
                                                                    name: email.emailData?.object?.from?.[0]?.name,
                                                                    email: email.emailData?.object?.from?.[0]?.email,
                                                                }}
                                                                subtitle={new Date(email.emailData?.object?.date * 1000).toLocaleString()}
                                                                isReply={!isFromMe}
                                                            />
                                                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                                <Typography level="body-md" sx={{ fontWeight: 'bold' }}>
                                                                    {email.emailData?.object?.subject || 'No Subject'}
                                                                </Typography>
                                                                <IconButton
                                                                    onClick={(e: React.MouseEvent) => {
                                                                        e.stopPropagation()
                                                                        toggleThread(index)
                                                                    }}
                                                                >
                                                                    {expandedThreads.has(index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                </IconButton>
                                                            </Stack>
                                                        </Stack>
                                                        {expandedThreads.has(index) ? (
                                                            <Stack spacing={1}>
                                                                <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                                                                    To: {email.emailData?.object?.to?.map((t) => t.email).join(', ') || 'Unknown Recipients'}
                                                                </Typography>
                                                                <Box sx={{ mt: 0.5, height: 'auto' }}>
                                                                    <EmailBody
                                                                        body={email.emailData?.object?.body || ''}
                                                                        subject={email.emailData?.object?.subject || 'No Subject'}
                                                                        height="auto"
                                                                        attachments={email.emailData?.object?.attachments}
                                                                        messageId={email.emailData.object.id}
                                                                    />
                                                                </Box>
                                                            </Stack>
                                                        ) : null}
                                                    </Card>
                                                </Box>
                                            )
                                        })
                                ) : (
                                    <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography level="body-md">Send an email...</Typography>
                                    </Box>
                                )}
                                <EmailEditor {...emailEditorProps} />
                            </Stack>
                        )}
                    </Stack>
                </Box>
            </Popover>
        </>
    )
}

export default BrokerEmailPopover
