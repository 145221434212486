import { Box, Stack, Typography } from '@mui/joy'
import React from 'react'
import { EmailRequestButton } from './EmailRequestButton'
import { FilterSection } from './FilterSection'
import { HideSelectedLoadsButton } from './HideSelectedLoads'
import { ActionBarProps } from './types'

export const ActionBar: React.FC<ActionBarProps> = ({ self: { resultCount }, filterSectionProps, emailRequestButtonProps, hideSelectedLoadsButtonProps }) => {
    return (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', gap: 1 }} alignItems={'center'}>
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <Typography
                        sx={{
                            fontSize: '1rem',
                            color: 'text.secondary',
                            backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#2b2b2b' : '#f0f0f0'),
                            px: 1,
                            py: 0.5,
                            borderRadius: '0.25rem',
                            fontWeight: 500,
                        }}
                    >
                        {resultCount} Results
                    </Typography>
                </Stack>
                <EmailRequestButton {...emailRequestButtonProps} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <FilterSection {...filterSectionProps} />
                <HideSelectedLoadsButton {...hideSelectedLoadsButtonProps} />
            </Box>
        </Box>
    )
}
