import { StyledEngineProvider } from '@mui/joy/styles'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import App from './App'
import setupAxios from './setup/SetupAxios'
import store from './store'
import AuthInitializer from './components/AuthInitializer'
import * as Sentry from '@sentry/react'
import '@livekit/components-styles'

setupAxios(() => {
    // Do something when the user is not authorized
    console.error('User is not authorized')
})

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.captureConsoleIntegration({
            levels: ['error', 'warn'], // Capture console.error and console.warn
        }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
})

ReactDOM.createRoot(document.querySelector('#root')!).render(
    <StyledEngineProvider injectFirst>
        <Provider store={store}>
            <BrowserRouter>
                <AuthInitializer>
                    <App />
                </AuthInitializer>
            </BrowserRouter>
        </Provider>
    </StyledEngineProvider>
)
