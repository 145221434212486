import { Box, Button, Chip, FormLabel, Stack, Tooltip, Typography } from '@mui/joy'
import { EmailTemplateType } from '@numeo/types'
import { ApplicationEmailTemplateSettings } from '@numeo/types'
import { useEffect, useState } from 'react'
import { saveEmailTemplate } from '../../../api/EmailSettings'
import { Category } from 'hooks/useNotification'
import { RichTextEditor } from 'components/common'
import { CircularProgress } from '@mui/joy'
import { useAuth } from 'hooks/auth'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'

// Define template variables as constants to avoid TypeScript object shorthand issues
const TEMPLATE_VARS = {
    ORIGIN_CITY: '{{origin_city}}',
    ORIGIN_STATE: '{{origin_state}}',
    DESTINATION_CITY: '{{destination_city}}',
    DESTINATION_STATE: '{{destination_state}}',
    TOTAL_RATE: '{{total_rate}}',
    DATE: '{{date}}',
    LOAD_ID: '{{load_id}}',
    BROKER_CONTACT_NAME: '{{broker_contact_name}}',
    DRIVER_NAME: '{{driver_name}}',
    DRIVER_PHONE: '{{driver_phone}}',
} as const

const defaultTemplates = {
    [EmailTemplateType.BID]: `<p>Hello, ${TEMPLATE_VARS.BROKER_CONTACT_NAME}</p>

<p>I'm reaching out regarding your order from ${TEMPLATE_VARS.ORIGIN_CITY}, ${TEMPLATE_VARS.ORIGIN_STATE}, to ${TEMPLATE_VARS.DESTINATION_CITY}, ${TEMPLATE_VARS.DESTINATION_STATE}. I'd like to propose a rate of <b>${TEMPLATE_VARS.TOTAL_RATE}</b>.</p>

<p>Could you let us know if this proposal works for you or if you'd like to discuss further?</p>

<p>Thanks,<br>
 Numeo </p>

<p>SFG Logistics<br>`,
    [EmailTemplateType.INFO]: `<p>Hello, ${TEMPLATE_VARS.BROKER_CONTACT_NAME}</p>
<p>Need details on the <strong>${TEMPLATE_VARS.ORIGIN_CITY}, ${TEMPLATE_VARS.ORIGIN_STATE}</strong> to <strong>${TEMPLATE_VARS.DESTINATION_CITY}, ${TEMPLATE_VARS.DESTINATION_STATE}</strong>, <strong>${TEMPLATE_VARS.DATE}</strong></p>
`,
    [EmailTemplateType.BROKER]: `<p>Hello ${TEMPLATE_VARS.BROKER_CONTACT_NAME},</p>

<p>I noticed that we previously worked together on a load out of <strong>${TEMPLATE_VARS.ORIGIN_CITY}, ${TEMPLATE_VARS.ORIGIN_STATE}</strong>, and you had sent us a rate confirmation for that origin.</p>

<p>I'm currently looking for another load out of <strong>${TEMPLATE_VARS.ORIGIN_CITY}, ${TEMPLATE_VARS.ORIGIN_STATE}</strong>. Do you happen to have anything available from that area at the moment?</p>

<p>Please let me know if there's anything you can offer. Looking forward to working with you again!</p>

`,
}

const variableDescriptions = {
    [TEMPLATE_VARS.BROKER_CONTACT_NAME]: 'Broker Contact Name',
    [TEMPLATE_VARS.LOAD_ID]: 'Load poster reference id',
    [TEMPLATE_VARS.ORIGIN_CITY]: 'Origin city',
    [TEMPLATE_VARS.ORIGIN_STATE]: 'Origin state',
    [TEMPLATE_VARS.DESTINATION_CITY]: 'Destination city',
    [TEMPLATE_VARS.DESTINATION_STATE]: 'Destination state',
    [TEMPLATE_VARS.TOTAL_RATE]: 'Total rate (only for bid template, will be calculated based on rate/mile)',
    [TEMPLATE_VARS.DATE]: 'Earliest availability date',
    [TEMPLATE_VARS.DRIVER_NAME]: 'Driver name',
    [TEMPLATE_VARS.DRIVER_PHONE]: 'Driver phone number',
}

export const EmailTemplateSettings = () => {
    const dispatch = useDispatch()
    const { application, setApplication } = useAuth()
    const [emailTemplates, setEmailTemplates] = useState<Record<EmailTemplateType, ApplicationEmailTemplateSettings>>({
        [EmailTemplateType.INFO]: {
            type: EmailTemplateType.INFO,
            body: defaultTemplates[EmailTemplateType.INFO],
        },
        [EmailTemplateType.BID]: {
            type: EmailTemplateType.BID,
            body: defaultTemplates[EmailTemplateType.BID],
        },
        [EmailTemplateType.BROKER]: {
            type: EmailTemplateType.BROKER,
            body: defaultTemplates[EmailTemplateType.BROKER],
        },
    })
    const [emailTemplate, setEmailTemplate] = useState<ApplicationEmailTemplateSettings>({
        type: EmailTemplateType.INFO,
        body: defaultTemplates[EmailTemplateType.INFO],
    })
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                const templateResponse = application?.temp.emailTemplate || []
                if (templateResponse) {
                    const templates = templateResponse
                    if (Array.isArray(templates)) {
                        // Find saved templates by type
                        const savedInfoTemplate = templates.find((t) => t.type === EmailTemplateType.INFO)
                        const savedBidTemplate = templates.find((t) => t.type === EmailTemplateType.BID)
                        const savedBrokerTemplate = templates.find((t) => t.type === EmailTemplateType.BROKER)

                        // Create template record using saved templates or defaults
                        const templateRecord: Record<EmailTemplateType, ApplicationEmailTemplateSettings> = {
                            [EmailTemplateType.INFO]: savedInfoTemplate || {
                                type: EmailTemplateType.INFO,
                                body: defaultTemplates[EmailTemplateType.INFO],
                            },
                            [EmailTemplateType.BID]: savedBidTemplate || {
                                type: EmailTemplateType.BID,
                                body: defaultTemplates[EmailTemplateType.BID],
                            },
                            [EmailTemplateType.BROKER]: savedBrokerTemplate || {
                                type: EmailTemplateType.BROKER,
                                body: defaultTemplates[EmailTemplateType.BROKER],
                            },
                        }

                        setEmailTemplates(templateRecord)
                        // Set current template to INFO
                        setEmailTemplate(templateRecord[EmailTemplateType.INFO])
                    }
                }
            } catch (error) {
                console.error('Error fetching email template settings:', error)
            } finally {
                setIsLoading(false)
            }
        }
        fetchData()
        // eslint-disable-next-line
    }, [])

    const handleEmailTemplateTypeChange = (value: EmailTemplateType) => {
        setEmailTemplate(emailTemplates[value])
    }

    const handleEmailTemplateBodyChange = (value: string) => {
        const updatedTemplate = {
            ...emailTemplate,
            body: value,
        }
        setEmailTemplate(updatedTemplate)
        setEmailTemplates({
            ...emailTemplates,
            [updatedTemplate.type]: updatedTemplate,
        })
    }

    const handleCancel = () => {
        setEmailTemplate(emailTemplates[emailTemplate.type])
    }

    const upsertEmailTemplateData = async () => {
        try {
            setIsLoading(true)
            const response = await saveEmailTemplate(emailTemplate)
            if (response.status === 200) {
                setApplication(response.data)
                setEmailTemplates({
                    ...emailTemplates,
                    [emailTemplate.type]: emailTemplate,
                })
                dispatch(
                    addNotification({
                        id: '',
                        category: Category.Email,
                        message: 'Email template updated successfully',
                        timestamp: Date.now(),
                        topic: 'temp',
                        type: 'info',
                    })
                )
            } else {
                dispatch(
                    addNotification({
                        id: '',
                        category: Category.Error,
                        message: 'Failed to update email template',
                        timestamp: Date.now(),
                        topic: 'temp',
                        type: 'error',
                    })
                )
            }
        } catch (error) {
            console.error('Error saving email template:', error)
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: 'Error updating email template',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'error',
                })
            )
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                p: 2,
                bgcolor: 'background.surface',
                borderRadius: 'sm',
                boxShadow: 'sm',
            }}
        >
            <Box sx={{ mb: 1 }}>
                <Typography level="h4" component="h2" sx={{ mb: 1 }}>
                    Email Template Settings
                </Typography>
                <Typography fontSize={14} sx={{ mb: 1 }}>
                    Customize your email templates for different types. Click on any variable to add it to your template.
                </Typography>
                <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                    {Object.entries(variableDescriptions).map(([variable, description]) => (
                        <Tooltip key={variable} title={description}>
                            <Chip
                                color="primary"
                                variant="soft"
                                size="sm"
                                onClick={() => {
                                    // Since we can't reliably access the Jodit instance, we'll use a different approach
                                    // Insert the variable directly without adding extra formatting
                                    const valueToAdd = variable

                                    // Update the template with the new variable
                                    const updatedTemplate = {
                                        ...emailTemplate,
                                        body: emailTemplate.body + valueToAdd,
                                    }

                                    // Update state with the new template body
                                    setEmailTemplate(updatedTemplate)
                                    setEmailTemplates({
                                        ...emailTemplates,
                                        [updatedTemplate.type]: updatedTemplate,
                                    })
                                }}
                            >
                                {variable}
                            </Chip>
                        </Tooltip>
                    ))}
                </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
                <Stack direction="row" spacing={2}>
                    <FormLabel>Email Type:</FormLabel>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button
                            variant="outlined"
                            color={emailTemplate.type === EmailTemplateType.INFO ? 'primary' : 'neutral'}
                            onClick={() => handleEmailTemplateTypeChange(EmailTemplateType.INFO)}
                        >
                            Request Info
                        </Button>
                        <Button
                            variant="outlined"
                            color={emailTemplate.type === EmailTemplateType.BID ? 'primary' : 'neutral'}
                            onClick={() => handleEmailTemplateTypeChange(EmailTemplateType.BID)}
                        >
                            Bid
                        </Button>
                        <Button
                            variant="outlined"
                            color={emailTemplate.type === EmailTemplateType.BROKER ? 'primary' : 'neutral'}
                            onClick={() => handleEmailTemplateTypeChange(EmailTemplateType.BROKER)}
                        >
                            Broker
                        </Button>
                    </Box>
                </Stack>

                <Box
                    sx={{
                        position: 'relative',
                        minHeight: 250,
                    }}
                >
                    {isLoading ? (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: 'rgba(255, 255, 255, 0.7)',
                                zIndex: 1,
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : null}
                    <RichTextEditor
                        value={emailTemplate.body}
                        onChange={handleEmailTemplateBodyChange}
                        placeholder="Enter your email template content..."
                        showToolbar={true}
                        disabled={isLoading}
                        minHeight={250}
                    />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 1,
                    }}
                >
                    <Button variant="outlined" color="neutral" size="sm" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button size="sm" onClick={upsertEmailTemplateData} loading={isLoading}>
                        Save Changes
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
