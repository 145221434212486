import { Modal, ModalDialog, Box, Typography, ModalClose } from '@mui/joy'
import { DATAccount } from 'pages/dashboard/pages/settings/components/Tabs/IntegrationsTab/AccountIntegrations/DATAccount'

interface DATAccountModalProps {
    open: boolean
    onClose: () => void
}

export const DATAccountModal = ({ open, onClose }: DATAccountModalProps) => {
    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog sx={{ minWidth: 600 }}>
                <ModalClose />
                <Typography level="h4" component="h2" sx={{ mb: 2 }}>
                    DAT Account
                </Typography>
                <Box>
                    <DATAccount />
                </Box>
            </ModalDialog>
        </Modal>
    )
}
