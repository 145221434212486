import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Box, Typography } from '@mui/joy'
import Autocomplete from '@mui/joy/Autocomplete'
import CircularProgress from '@mui/joy/CircularProgress'
import { SearchParams } from '@numeo/types'
import * as React from 'react'
import { CityLocationData, locationDataSearchV2, LocationType, StateLocationData } from 'utils/location-search/LocationDataSearchV2'
import { LocationDataSearch } from '../../utils/location-search/LocationDataSearch'

interface MultiLocationSelectProps {
    disabled?: boolean
    defaultLocation: SearchParams['destination']
    onLocationSelect?: (location: CityLocationData) => void
    onStateSelect?: (states: StateLocationData[]) => void
    onBlur?: () => void
    placeholder?: string
    limit?: number
}

export default function MultiLocationSelect({
    disabled = false,
    defaultLocation,
    onLocationSelect,
    onStateSelect,
    onBlur,
    placeholder = 'Destination',
}: MultiLocationSelectProps) {
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState<CityLocationData[] | StateLocationData[]>([])
    const [loading, setLoading] = React.useState(false)
    const [value, setValue] = React.useState<Array<CityLocationData | StateLocationData>>([])
    const [containerWidth, setContainerWidth] = React.useState(0)
    const containerRef = React.useRef<HTMLDivElement>(null)

    const searchInstanceRef = React.useRef<LocationDataSearch | null>(null)

    React.useEffect(() => {
        const initializeSearch = async () => {
            searchInstanceRef.current = await LocationDataSearch.getInstance()

            // Cleanup
            return () => {
                if (searchInstanceRef.current) {
                    searchInstanceRef.current.close()
                }
            }
        }
        initializeSearch()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth)
            }
        }

        updateWidth()
        window.addEventListener('resize', updateWidth)
        return () => window.removeEventListener('resize', updateWidth)
    }, [])

    const getInitialState = async () => {
        if (!searchInstanceRef.current) return
        setLoading(true)
        // const results = await searchInstanceRef.current.getStates('')
        setOptions([])
        setLoading(false)
    }

    React.useEffect(() => {
        getInitialState()
    }, [open])

    React.useEffect(() => {
        if (defaultLocation.destinationType === 'city-state') {
            const { city, stateProv } = defaultLocation
            setValue([{ city, stateCode: stateProv, stateName: '', type: LocationType.CITY } as CityLocationData])
        } else if (defaultLocation.destinationType === 'states' && defaultLocation.states) {
            setValue(
                defaultLocation.states.map(
                    (state) =>
                        ({
                            stateCode: state,
                            id: `state-${state}`,
                            type: LocationType.STATE,
                        }) as StateLocationData
                )
            )
        } else {
            setValue([])
        }
    }, [defaultLocation])

    // Handle location change
    const handleLocationChange = (event: React.SyntheticEvent<Element, Event>, newValue: Array<CityLocationData | StateLocationData>) => {
        console.log('NEW VALUE:', newValue)

        if (newValue.length === 0) {
            setValue([])
            onBlur?.()
            return
        }

        const lastItem = newValue[newValue.length - 1]

        if ('city' in lastItem) {
            // If a city is selected, clear previous selections (only allow 1 city)
            setValue([lastItem])
            onLocationSelect?.(lastItem)
        } else if (lastItem.type === LocationType.STATE && 'stateCode' in lastItem) {
            // If selecting a state, allow multiple selections
            const stateSelections = newValue.filter((item) => item.type === LocationType.STATE)
            setValue(stateSelections)
            onStateSelect?.(stateSelections as StateLocationData[])
        }
    }

    // searching from indexDB
    const handleSearch = async (query: string) => {
        if (defaultLocation.destinationType === 'city-state' && query === `${defaultLocation.city}, ${defaultLocation.stateProv}`) {
            return
        }

        setLoading(true)
        try {
            let results: CityLocationData[] | StateLocationData[] = []

            if (!query.trim() || query.length <= 2) {
                results = await locationDataSearchV2.searchByStateCodes(query)
            } else {
                results = (await locationDataSearchV2.search(query)) || []
            }

            console.log('CITY RESULT:', results[0])

            setOptions(results || [])

            // In MultiLocationSelect.tsx's handleSearch function
        } catch (error) {
            console.error('Search failed:', error)
        } finally {
            setLoading(false)
        }
    }

    const calculateLimitTags = React.useMemo(() => {
        if (containerWidth < 220) return 1
        if (containerWidth < 280) return 2
        if (containerWidth < 350) return 3
        if (containerWidth < 400) return 4
        return 5
    }, [containerWidth])

    return (
        <Box ref={containerRef} sx={{ width: '100%' }}>
            <Autocomplete
                name="destination"
                multiple
                value={value}
                onChange={handleLocationChange}
                disabled={disabled}
                sx={{ width: '100%' }}
                placeholder={placeholder}
                open={open}
                onOpen={() => {
                    setOpen(true)
                }}
                onClose={() => {
                    setOpen(false)
                }}
                onInputChange={(event, value) => {
                    if (typeof value === 'string') {
                        handleSearch(value)
                    }
                }}
                isOptionEqualToValue={(option, value) => option.stateCode === value.stateCode}
                getOptionLabel={(option) => ('city' in option ? `${option.city}, ${option.stateCode}` : option.stateCode)}
                renderOption={(props, option) => (
                    <li {...props} style={{ cursor: 'pointer' }} key={option.id}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <LocationOnIcon color="primary" />
                            <Typography>{'city' in option ? `${option.city}, ${option.stateCode}` : option.stateCode}</Typography>
                        </Box>
                    </li>
                )}
                options={options}
                noOptionsText="No locations found"
                loading={loading}
                limitTags={calculateLimitTags}
                slotProps={{
                    limitTag: {
                        sx: {
                            position: 'absolute',
                            right: 60,
                            mb: 0.5,
                        },
                    },
                }}
                endDecorator={loading ? <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} /> : null}
            />
        </Box>
    )
}
