/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ArrowDownward,
    HelpOutline,
    KeyboardArrowDown,
    KeyboardDoubleArrowDown,
    LocationOff,
    MoneyOff,
    PinDrop,
    PriceCheck,
    SouthEast,
} from '@mui/icons-material'
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    ListDivider,
    ListItem,
    ListItemButton,
    ListItemDecorator,
    Modal,
    ModalDialog,
    Option,
    Select,
    Tooltip,
    Typography,
    useColorScheme,
} from '@mui/joy'
import { Email, OrderSchema } from '@numeo/types'
import axios from 'axios'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Category } from 'hooks/useNotification'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'

type OrderType = 'spot' | 'lane' | 'lane-spot' | 'unclassified' | 'error' | 'processing'

interface EmailCardProps {
    executionId: string
    execution: {
        email: Email
        emailData: Email['emailData']
        orderType?: OrderType
        processingResult?: any
    }
    isSelected: boolean
    onClick?: () => void
    selectedExecutionId: string | null
    onTypeChange?: (type: OrderType) => void
    refetchEmails?: (resetList?: boolean) => void
}

const orderTypes = [
    { value: 'spot', label: 'Spot' },
    { value: 'lane', label: 'Lane' },
    { value: 'lane-spot', label: 'Lane & Spot' },
    { value: 'unclassified', label: 'Unclassified' },
]

const getUnderratedIcon = (level: string) => {
    switch (level) {
        case 'high':
            return <KeyboardDoubleArrowDown sx={{ color: 'error.main' }} />
        case 'medium':
            return <ArrowDownward sx={{ color: 'warning.main' }} />
        case 'low':
            return <SouthEast sx={{ color: 'success.main' }} />
        case 'overrated':
            return <PriceCheck sx={{ color: 'success.main' }} />
        default:
            return <MoneyOff sx={{ color: 'neutral.400' }} />
    }
}

const getInitialOrderType = (type: OrderType): OrderType => {
    switch (type) {
        case 'lane':
        case 'spot':
        case 'lane-spot':
        case 'unclassified':
        case 'processing':
        case 'error':
            return type
        default:
            return 'unclassified'
    }
}

export default function EmailCard({ execution, isSelected, onClick, selectedExecutionId, onTypeChange, refetchEmails }: EmailCardProps) {
    const dispatch = useDispatch()
    const from = execution.email.emailData?.object.from[0]
    const senderName = from?.name || from?.email || ''
    const initial = senderName[0]?.toUpperCase() || ''
    const { mode } = useColorScheme()

    const [orderType, setOrderType] = React.useState<OrderType>(getInitialOrderType(execution.processingResult?.orderType))
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false)
    const [newOrderType, setNewOrderType] = React.useState<OrderType>(execution.processingResult?.orderType || 'unclassified')
    const [isProcessing, setIsProcessing] = React.useState(false)
    const rateTooltipTitle = useMemo(() => {
        const orders = execution?.processingResult.orders
        const ordersOnlyWithRates = orders?.filter((order: OrderSchema) => order.offeringRate)

        return orders?.some((order: OrderSchema) => order.offeringRate)
            ? ordersOnlyWithRates.length === 1
                ? `Rate offered: $${ordersOnlyWithRates[0].offeringRate}`
                : `Offering rates: $${ordersOnlyWithRates.map((order: OrderSchema) => order.offeringRate).join(', ')}`
            : 'No rate offered in email'
    }, [execution])

    const handleOrderTypeChange = (event: React.SyntheticEvent | null, value: OrderType | null) => {
        if (value) {
            setNewOrderType(value)
            setOpenConfirmDialog(true)
        }
    }

    const [isSelectOpen, setIsSelectOpen] = useState(false)
    const selectRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setIsSelectOpen(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const getChipColor = (orderType: OrderType) => {
        switch (orderType) {
            case 'spot':
                return 'success'
            case 'lane':
                return 'primary'
            case 'lane-spot':
                return 'warning'
            default:
                return 'neutral'
        }
    }

    const renderOrderTypeControl = (orderType: OrderType, handleOrderTypeChange: any) => {
        switch (orderType) {
            case 'processing':
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CircularProgress size="sm" />
                        <Typography level="body-sm" color="neutral">
                            In Progress
                        </Typography>
                    </Box>
                )
            case 'error':
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography level="body-sm" color="danger">
                            Failed
                        </Typography>
                        {/* <Box
                            onClick={(e) => {
                                e.stopPropagation()
                                handleRetry()
                            }}
                            sx={{ display: 'flex', alignItems: 'center' }}
                        >
                            <Refresh sx={{ width: 16, height: 16 }} />
                        </Box> */}
                    </Box>
                )
            default:
                return (
                    <Select
                        ref={selectRef}
                        variant="soft"
                        color={getChipColor(orderType)}
                        value={orderType}
                        onChange={handleOrderTypeChange}
                        size="sm"
                        indicator={<KeyboardArrowDown />}
                        sx={{
                            minWidth: 110,
                            '--Select-decoratorChildHeight': '20px',
                            fontSize: 'xs',
                            '& .Select-indicator': { fontSize: '1rem' },
                        }}
                        slotProps={{
                            button: {
                                onClick: (e: React.MouseEvent) => {
                                    e.stopPropagation()
                                    setIsSelectOpen(!isSelectOpen)
                                },
                                onBlur: (e: React.FocusEvent) => e.stopPropagation(),
                            },
                        }}
                    >
                        {orderTypes.map((type) => (
                            <Option key={type.value} value={type.value}>
                                {type.label}
                            </Option>
                        ))}
                    </Select>
                )
        }
    }

    const handleConfirmChange = async () => {
        setIsProcessing(true)
        try {
            await axios.put(`/n8n/processing-results/${execution.processingResult._id}`, {
                orderType: newOrderType,
            })

            setOrderType(newOrderType)
            setOpenConfirmDialog(false)
            dispatch(
                addNotification({
                    id: '',
                    message: 'Email reprocessing completed successfully',
                    type: 'error',
                    category: Category.Email,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
            if (onTypeChange) {
                onTypeChange(newOrderType)
            }

            await refetchEmails?.(true)
        } catch (error) {
            console.error('Error reprocessing email:', error)
            dispatch(
                addNotification({
                    id: '',
                    message: 'Failed to complete reprocessing email ',
                    type: 'error',
                    category: Category.Error,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
        } finally {
            setIsProcessing(false)
        }
    }

    return (
        <React.Fragment>
            <Tooltip
                arrow
                sx={{
                    '& .MuiTooltip-tooltip': {
                        fontSize: '0.8rem',
                    },
                    borderRadius: 'sm',
                    bgcolor: mode === 'dark' ? 'primary.700' : 'primary.100',
                }}
                title={
                    selectedExecutionId && (
                        <Box>
                            <Typography>{execution.email.emailData.object.from[0].email}</Typography>
                            <Typography sx={{ fontSize: '0.8rem' }}>{execution.email.emailData.object.subject}</Typography>
                        </Box>
                    )
                }
                placement="left-start"
            >
                <ListItem>
                    <ListItemButton
                        selected={isSelected}
                        onClick={onClick}
                        sx={{
                            maxHeight: 120,
                            p: 1,
                            pb: 0,
                            transition: 'height 0.3s ease-in-out, padding 0.3s ease-in-out, background-color 0.3s ease-in-out',
                            bgcolor: isSelected ? 'primary.50' : 'background.surface',
                        }}
                    >
                        <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
                            <Avatar
                                variant="soft"
                                color="neutral"
                                sx={{
                                    '--Avatar-size': '32px',
                                }}
                            >
                                {initial}
                            </Avatar>
                        </ListItemDecorator>
                        <Box sx={{ pl: 2, width: '100%' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mb: 0.5,
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography
                                        level="title-sm"
                                        sx={{
                                            fontWeight: 600,
                                            color: 'text.secondary',
                                            width: selectedExecutionId ? 200 : 'auto',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 1,
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        {senderName}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Typography
                                        level="body-xs"
                                        sx={{
                                            color: 'text.tertiary',
                                        }}
                                    >
                                        {selectedExecutionId ? null : new Date(execution.email.emailData.object.date * 1000).toLocaleDateString()}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography
                                level="body-sm"
                                sx={{
                                    fontWeight: 500,
                                    color: 'text.secondary',
                                    mb: 0.5,
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                }}
                            >
                                {selectedExecutionId ? null : execution.email.emailData.object.subject}
                            </Typography>
                            <Typography
                                level="body-sm"
                                sx={{
                                    color: 'text.secondary',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                }}
                            >
                                {selectedExecutionId ? null : execution.email.emailData.object.snippet}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                                {!['unclassified', 'processing', 'error'].includes(orderType) &&
                                    (execution?.processingResult?.locationMismatch ? (
                                        <Tooltip title="Location mismatch - Pickup/Delivery locations do not match your requirements">
                                            <LocationOff color="warning" sx={{ fontSize: '1.2rem' }} />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Location match -  Pickup/Delivery locations matches">
                                            <PinDrop color="success" sx={{ fontSize: '1.2rem' }} />
                                        </Tooltip>
                                    ))}
                                {orderType === 'unclassified' && (
                                    <Tooltip
                                        title={
                                            execution?.processingResult?.unclassificationReason
                                                ? `Unclassified: ${execution.processingResult.unclassificationReason}`
                                                : 'Unclassified: Unable to determine email type'
                                        }
                                    >
                                        <HelpOutline sx={{ color: 'warning.main' }} />
                                    </Tooltip>
                                )}
                                {!['unclassified', 'processing', 'error'].includes(orderType) && (
                                    <Tooltip title={rateTooltipTitle}>{getUnderratedIcon(execution?.processingResult?.underratingLevel)}</Tooltip>
                                )}
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginLeft: 'auto' }}>
                                    {orderType && (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={(e) => e.stopPropagation()}>
                                            {renderOrderTypeControl(orderType, handleOrderTypeChange)}
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </ListItemButton>
                </ListItem>
            </Tooltip>
            <ListDivider />

            <Modal open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
                <ModalDialog variant="outlined" role="alertdialog" aria-labelledby="alert-dialog-modal-title" aria-describedby="alert-dialog-modal-description">
                    <Typography id="alert-dialog-modal-title" component="h2">
                        Confirm Type Change
                    </Typography>
                    <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
                        This action will reprocess the email. Are you sure you want to continue?
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
                        <Button variant="plain" color="neutral" onClick={() => setOpenConfirmDialog(false)}>
                            Cancel
                        </Button>
                        <Button variant="solid" color="primary" onClick={handleConfirmChange} loading={isProcessing}>
                            Confirm
                        </Button>
                    </Box>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    )
}
