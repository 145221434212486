import { Report } from '@mui/icons-material'
import { Alert, Box, Button, Typography } from '@mui/joy'
import { useAuth } from 'hooks/auth'
import { handleNylasEmailAdd } from 'utils/nylas'
import { Category } from 'hooks/useNotification'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'
import { useState } from 'react'
import { DATAccountModal } from 'pages/dashboard/pages/dispatcher/pages/spot-finder/components/Integrations/DATAccountModal'

// Define the structure of a warning item
export type WarningItem = {
    id: string
    message: string
    fixButtonText: string
    fixAction: () => void
}

export const Warnings = () => {
    const dispatch = useDispatch()
    const { application, setApplication } = useAuth()
    const warnings: WarningItem[] = []
    const [datModalOpen, setDatModalOpen] = useState(false)

    const expiredEmail = application?.integrations.nylas?.emails.find((email) => email.isExpired)
    const datBlocked = application?.integrations.dat?.blocked

    // Add warning for expired email
    if (expiredEmail) {
        warnings.push({
            id: `expired-email-${expiredEmail.email}`,
            message: `Your email account ${expiredEmail.email} has expired. Please reconnect.`,
            fixButtonText: 'Reconnect Email',
            fixAction: () => {
                handleNylasEmailAdd(
                    (accounts) => {
                        if (application && accounts.length > 0) {
                            // Create a complete deep copy of the entire application object
                            const updatedApplication = JSON.parse(JSON.stringify(application))

                            // Make sure nylas integration exists
                            if (updatedApplication.integrations && updatedApplication.integrations.nylas && updatedApplication.integrations.nylas.emails) {
                                // Find the expired email and update it in the copied structure
                                const emailIndex = updatedApplication.integrations.nylas.emails.findIndex((email) => email.email === expiredEmail.email)

                                if (emailIndex !== -1) {
                                    updatedApplication.integrations.nylas.emails[emailIndex].isExpired = false
                                }

                                setApplication(updatedApplication)
                            }
                        }

                        dispatch(
                            addNotification({
                                id: '',
                                category: Category.Email,
                                message: 'Email account reconnected successfully',
                                timestamp: Date.now(),
                                type: 'info',
                                topic: 'temp',
                            })
                        )
                    },
                    (error) => {
                        dispatch(
                            addNotification({
                                id: '',
                                category: Category.Error,
                                message: `Failed to reconnect email: ${error}`,
                                timestamp: Date.now(),
                                type: 'error',
                                topic: 'temp',
                            })
                        )
                    },
                    (message, type) => {
                        dispatch(
                            addNotification({
                                id: '',
                                category: type === 'danger' ? Category.Error : Category.Email,
                                message,
                                timestamp: Date.now(),
                                type: type === 'danger' ? 'error' : 'info',
                                topic: 'temp',
                            })
                        )
                    }
                )
            },
        })
    }

    // Add warning for DAT blocked
    if (datBlocked) {
        warnings.push({
            id: `dat-blocked`,
            message: `Your account has been blocked by DAT. Please reconnect.`,
            fixButtonText: 'Reconnect DAT',
            fixAction: () => {
                setDatModalOpen(true)
            },
        })
    }

    if (warnings.length === 0) return null

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    mb: 7,
                }}
            >
                {warnings.map((warning) => (
                    <Alert
                        key={warning.id}
                        color="danger"
                        startDecorator={<Report color="error" />}
                        variant="soft"
                        sx={{
                            alignItems: 'center',
                            width: '100%',
                            position: 'absolute',
                        }}
                    >
                        <Typography>{warning.message}</Typography>
                        <Button variant="solid" color="danger" size="sm" onClick={warning.fixAction}>
                            {warning.fixButtonText}
                        </Button>
                    </Alert>
                ))}
            </Box>

            {/* DAT Account Modal */}
            <DATAccountModal open={datModalOpen} onClose={() => setDatModalOpen(false)} />
        </>
    )
}
