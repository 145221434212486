import { useState, useEffect } from 'react'
import { Box, Button, FormControl, FormLabel, Input, Textarea, Grid, FormHelperText, Typography, Select, Option } from '@mui/joy'
import { OngoingLoad, IOriginDestination, IContact, Truck } from '@numeo/types'
import dayjs from 'dayjs'
import { LocationSearch } from 'components/LocationSearch/LocationSearch'
import { CityLocationData } from 'utils/location-search/LocationDataSearchV2'
import { allTypes, subTypes } from 'utils/truckEquipmentTypes'
import { useAuth } from 'hooks/auth'

interface LoadFormProps {
    initialData?: OngoingLoad | null
    onSubmit: (data: Partial<OngoingLoad>) => void
    onCancel: () => void
    loading?: boolean
}

export const LoadForm = ({ initialData, onSubmit, onCancel, loading = false }: LoadFormProps) => {
    // Get trucks from application auth
    const { application } = useAuth()
    const trucks: Truck[] = application?.temp.spotFinder?.trucks || []
    // Define a type that matches OngoingLoad but with all fields optional
    type PartialOngoingLoad = Partial<Omit<OngoingLoad, 'origin' | 'destination' | 'contact'>> & {
        origin?: IOriginDestination
        destination?: IOriginDestination
        contact?: IContact
    }

    const [formData, setFormData] = useState<PartialOngoingLoad>({
        origin: { city: '', state: '' },
        destination: { city: '', state: '' },
        length: 0,
        weight: 0,
        pickupTime1: new Date(),
        pickupTime2: new Date(),
        deliveryTime1: new Date(),
        deliveryTime2: new Date(),
        equipmentType: '',
        companyName: '',
        mcNumber: 0,
        dotNumber: undefined,
        contact: { email: '', phone: '' },
        totalRate: 0,
        comment: '',
        truckId: undefined,
    })

    // Helper functions for date handling
    const formatDateForInput = (date: Date | null | undefined): string => {
        if (!date) return ''
        return dayjs(date).format('YYYY-MM-DD')
    }

    const formatTimeForInput = (date: Date | null | undefined): string => {
        if (!date) return ''
        return dayjs(date).format('HH:mm')
    }

    const handleDateChange = (field: string, value: string) => {
        if (!value) {
            handleChange(field, null)
            return
        }

        const currentDate = formData[field as keyof typeof formData] as Date | undefined

        // Ensure currentDate is a Date object
        const currentTime = currentDate instanceof Date ? { hours: currentDate.getHours(), minutes: currentDate.getMinutes() } : { hours: 0, minutes: 0 }

        const newDate = new Date(value)
        newDate.setHours(currentTime.hours, currentTime.minutes)

        handleChange(field, newDate)
    }

    const handleTimeChange = (field: string, value: string) => {
        if (!value || !formData[field as keyof typeof formData]) {
            return
        }

        const currentDate = new Date(formData[field as keyof typeof formData] as Date)
        const [hours, minutes] = value.split(':').map(Number)

        currentDate.setHours(hours, minutes)
        handleChange(field, currentDate)
    }

    const [errors, setErrors] = useState<Record<string, string>>({})

    useEffect(() => {
        if (initialData) {
            setFormData({
                ...initialData,
                pickupTime1: initialData.pickupTime1,
                pickupTime2: initialData.pickupTime2,
                deliveryTime1: initialData.deliveryTime1,
                deliveryTime2: initialData.deliveryTime2,
            })
        }
    }, [initialData])

    const handleChange = (field: string, value?: string | Date | number | null) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }))

        // Clear error when field is edited
        if (errors[field]) {
            setErrors((prev) => {
                const newErrors = { ...prev }
                delete newErrors[field]
                return newErrors
            })
        }
    }

    const handleNestedChange = (parent: string, field: string, value?: string | Date | number | null) => {
        setFormData((prev) => ({
            ...prev,
            [parent]: {
                ...(prev[parent as keyof typeof prev] as Record<string, unknown>),
                [field]: value,
            },
        }))

        // Clear error when field is edited
        const errorKey = `${parent}.${field}`
        if (errors[errorKey]) {
            setErrors((prev) => {
                const newErrors = { ...prev }
                delete newErrors[errorKey]
                return newErrors
            })
        }
    }

    const validateForm = (): boolean => {
        const newErrors: Record<string, string> = {}

        // Required fields validation
        if (!formData.origin?.city) newErrors['origin.city'] = 'Origin city is required'
        if (!formData.origin?.state) newErrors['origin.state'] = 'Origin state is required'
        if (!formData.destination?.city) newErrors['destination.city'] = 'Destination city is required'
        if (!formData.destination?.state) newErrors['destination.state'] = 'Destination state is required'
        if (!formData.equipmentType) newErrors['equipmentType'] = 'Equipment type is required'
        if (!formData.companyName) newErrors['companyName'] = 'Company name is required'
        if (!formData.mcNumber) newErrors['mcNumber'] = 'MC number is required'
        if (!formData.totalRate) newErrors['totalRate'] = 'Total rate is required'
        if (!formData.distance) newErrors['distance'] = 'Distance is required'

        // Numeric value validation
        if (formData.length && formData.length < 0) newErrors['length'] = 'Must be a positive number'
        if (formData.weight && formData.weight < 0) newErrors['weight'] = 'Must be a positive number'
        if (formData.totalRate && formData.totalRate < 0) newErrors['totalRate'] = 'Must be a positive number'
        if (formData.distance && formData.distance < 0) newErrors['distance'] = 'Must be a positive number'

        // Contact validation
        if (formData.contact?.email && !/\S+@\S+\.\S+/.test(formData.contact.email)) newErrors['contact.email'] = 'Invalid email format'
        if (formData.contact?.phone && !/^\(\d{1,3}\) \d{0,3}(-\d{0,4})?$/.test(formData.contact.phone)) newErrors['contact.phone'] = 'Invalid phone format'

        // Date validation
        if (formData.pickupTime1 && formData.deliveryTime1 && new Date(formData.pickupTime1) > new Date(formData.deliveryTime1)) {
            newErrors['deliveryTime1'] = 'Delivery time cannot be before pickup time'
        }
        if (formData.pickupTime2 && formData.deliveryTime2 && new Date(formData.pickupTime2) > new Date(formData.deliveryTime2)) {
            newErrors['deliveryTime2'] = 'Delivery time cannot be before pickup time'
        }

        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        if (validateForm()) {
            // Ensure origin and destination are properly formatted as IOriginDestination objects
            const submissionData: Partial<OngoingLoad> = {
                ...formData,
                origin: formData.origin as IOriginDestination,
                destination: formData.destination as IOriginDestination,
                contact: formData.contact as IContact,
            }
            onSubmit(submissionData)
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
                {/* Company Information */}
                <Grid xs={12}>
                    <Typography level="title-lg" color="primary">
                        Company Information
                    </Typography>
                </Grid>

                <Grid xs={12} md={6}>
                    <FormControl error={!!errors['companyName']}>
                        <FormLabel>Company Name *</FormLabel>
                        <Input
                            value={formData.companyName || ''}
                            onChange={(e) => handleChange('companyName', e.target.value)}
                            placeholder="Enter company name"
                        />
                        {errors['companyName'] && <FormHelperText>{errors['companyName']}</FormHelperText>}
                    </FormControl>
                </Grid>

                <Grid xs={12} md={3}>
                    <FormControl error={!!errors['mcNumber']}>
                        <FormLabel>MC Number *</FormLabel>
                        <Input
                            value={formData.mcNumber || ''}
                            onChange={(e) => handleChange('mcNumber', Number(e.target.value))}
                            placeholder="MC#"
                            type="number"
                        />
                        {errors['mcNumber'] && <FormHelperText>{errors['mcNumber']}</FormHelperText>}
                    </FormControl>
                </Grid>

                <Grid xs={12} md={3}>
                    <FormControl error={!!errors['dotNumber']}>
                        <FormLabel>DOT Number</FormLabel>
                        <Input
                            value={formData.dotNumber || ''}
                            onChange={(e) => handleChange('dotNumber', e.target.value ? Number(e.target.value) : undefined)}
                            placeholder="DOT#"
                            type="number"
                        />
                        {errors['dotNumber'] && <FormHelperText>{errors['dotNumber']}</FormHelperText>}
                    </FormControl>
                </Grid>

                {/* Contact Information */}
                <Grid xs={12} mt={1}>
                    <Typography level="title-lg" color="primary">
                        Contact Information
                    </Typography>
                </Grid>

                <Grid xs={12} md={6}>
                    <FormControl error={!!errors['contact.email']}>
                        <FormLabel>Email</FormLabel>
                        <Input
                            value={formData.contact?.email || ''}
                            onChange={(e) => handleNestedChange('contact', 'email', e.target.value)}
                            placeholder="Enter email"
                            type="email"
                        />
                        {errors['contact.email'] && <FormHelperText>{errors['contact.email']}</FormHelperText>}
                    </FormControl>
                </Grid>

                <Grid xs={12} md={6}>
                    <FormControl error={!!errors['contact.phone']}>
                        <FormLabel>Phone</FormLabel>
                        <Input
                            value={formData.contact?.phone || ''}
                            onChange={(e) => {
                                // Format phone number as it's entered
                                let phoneValue = e.target.value.replace(/\D/g, '')
                                if (phoneValue.length > 10) {
                                    phoneValue = phoneValue.slice(0, 10)
                                }

                                // Format as (XXX) XXX-XXXX
                                if (phoneValue.length > 0) {
                                    let formattedPhone = ''
                                    if (phoneValue.length > 6) {
                                        formattedPhone = `(${phoneValue.slice(0, 3)}) ${phoneValue.slice(3, 6)}-${phoneValue.slice(6)}`
                                    } else if (phoneValue.length > 3) {
                                        formattedPhone = `(${phoneValue.slice(0, 3)}) ${phoneValue.slice(3)}`
                                    } else {
                                        formattedPhone = `(${phoneValue})`
                                    }
                                    handleNestedChange('contact', 'phone', formattedPhone)
                                } else {
                                    handleNestedChange('contact', 'phone', '')
                                }
                            }}
                            placeholder="(XXX) XXX-XXXX"
                            startDecorator="+1"
                        />
                        {errors['contact.phone'] && <FormHelperText>{errors['contact.phone']}</FormHelperText>}
                    </FormControl>
                </Grid>

                {/* Load Details */}
                <Grid xs={12} mt={1}>
                    <Typography level="title-lg" color="primary">
                        Load Details
                    </Typography>
                </Grid>

                {/* Origin */}
                <Grid xs={12} md={6}>
                    <FormControl error={!!errors['origin.city'] || !!errors['origin.state']}>
                        <FormLabel>Origin Location *</FormLabel>
                        <LocationSearch
                            placeholder="Search for origin city"
                            defaultLocation={{
                                city: formData.origin?.city || '',
                                stateProv: formData.origin?.state || '',
                                longitude: 0,
                                latitude: 0,
                            }}
                            onLocationSelect={(location: CityLocationData) => {
                                handleNestedChange('origin', 'city', location.city)
                                handleNestedChange('origin', 'state', location.stateCode)
                            }}
                            onRemove={() => {
                                handleNestedChange('origin', 'city', '')
                                handleNestedChange('origin', 'state', '')
                            }}
                        />
                        {(errors['origin.city'] || errors['origin.state']) && (
                            <FormHelperText>{errors['origin.city'] || errors['origin.state']}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                {/* Destination */}
                <Grid xs={12} md={6}>
                    <FormControl error={!!errors['destination.city'] || !!errors['destination.state']}>
                        <FormLabel>Destination Location *</FormLabel>
                        <LocationSearch
                            placeholder="Search for destination city"
                            defaultLocation={{
                                city: formData.destination?.city || '',
                                stateProv: formData.destination?.state || '',
                                longitude: 0,
                                latitude: 0,
                            }}
                            onLocationSelect={(location: CityLocationData) => {
                                handleNestedChange('destination', 'city', location.city)
                                handleNestedChange('destination', 'state', location.stateCode)
                            }}
                            onRemove={() => {
                                handleNestedChange('destination', 'city', '')
                                handleNestedChange('destination', 'state', '')
                            }}
                        />
                        {(errors['destination.city'] || errors['destination.state']) && (
                            <FormHelperText>{errors['destination.city'] || errors['destination.state']}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>

                <Grid xs={12} md={6}>
                    <FormControl error={!!errors['length']}>
                        <FormLabel>Length</FormLabel>
                        <Input
                            value={formData.length || ''}
                            onChange={(e) => handleChange('length', Number(e.target.value))}
                            placeholder="Length"
                            type="number"
                            endDecorator="ft"
                        />
                        {errors['length'] && <FormHelperText>{errors['length']}</FormHelperText>}
                    </FormControl>
                </Grid>

                <Grid xs={12} md={6}>
                    <FormControl error={!!errors['weight']}>
                        <FormLabel>Weight</FormLabel>
                        <Input
                            value={formData.weight || ''}
                            onChange={(e) => handleChange('weight', Number(e.target.value))}
                            placeholder="Weight"
                            type="number"
                            endDecorator="lbs"
                        />
                        {errors['weight'] && <FormHelperText>{errors['weight']}</FormHelperText>}
                    </FormControl>
                </Grid>

                {/* Equipment & Rate */}
                <Grid xs={12} md={6}>
                    <FormControl error={!!errors['equipmentType']}>
                        <FormLabel>Equipment Type *</FormLabel>
                        <Select
                            value={formData.equipmentType || ''}
                            onChange={(_, value) => handleChange('equipmentType', value as string)}
                            placeholder="Select equipment type"
                        >
                            {allTypes.map((type) => (
                                <Option key={type.code} value={type.code}>
                                    {type.name}
                                </Option>
                            ))}
                            {subTypes.map((type) => (
                                <Option key={type.code} value={type.code}>
                                    {type.name}
                                </Option>
                            ))}
                        </Select>
                        {errors['equipmentType'] && <FormHelperText>{errors['equipmentType']}</FormHelperText>}
                    </FormControl>
                </Grid>

                <Grid xs={12} md={6}>
                    <FormControl error={!!errors['totalRate']}>
                        <FormLabel>Total Rate *</FormLabel>
                        <Input
                            value={formData.totalRate || ''}
                            onChange={(e) => handleChange('totalRate', Number(e.target.value))}
                            placeholder="Total rate"
                            type="number"
                            startDecorator="$"
                        />
                        {errors['totalRate'] && <FormHelperText>{errors['totalRate']}</FormHelperText>}
                    </FormControl>
                </Grid>

                {/* Dates and Truck Selection */}
                <Grid xs={12} container spacing={2}>
                    {/* Pickup Date & Time */}
                    <Grid xs={12} md={3}>
                        <FormControl error={!!errors['pickupTime1']}>
                            <FormLabel>Pickup Date *</FormLabel>
                            <Input
                                type="date"
                                value={formatDateForInput(formData.pickupTime1)}
                                onChange={(e) => handleDateChange('pickupTime1', e.target.value)}
                                fullWidth
                            />
                            {errors['pickupTime1'] && <FormHelperText>{errors['pickupTime1']}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={3}>
                        <FormControl>
                            <FormLabel>Pickup Time *</FormLabel>
                            <Input
                                type="time"
                                value={formatTimeForInput(formData.pickupTime1)}
                                onChange={(e) => handleTimeChange('pickupTime1', e.target.value)}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>

                    {/* Delivery Date & Time */}
                    <Grid xs={12} md={3}>
                        <FormControl error={!!errors['deliveryTime1']}>
                            <FormLabel>Delivery Date *</FormLabel>
                            <Input
                                type="date"
                                value={formatDateForInput(formData.deliveryTime1)}
                                onChange={(e) => handleDateChange('deliveryTime1', e.target.value)}
                                fullWidth
                            />
                            {errors['deliveryTime1'] && <FormHelperText>{errors['deliveryTime1']}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={3}>
                        <FormControl>
                            <FormLabel>Delivery Time *</FormLabel>
                            <Input
                                type="time"
                                value={formatTimeForInput(formData.deliveryTime1)}
                                onChange={(e) => handleTimeChange('deliveryTime1', e.target.value)}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>

                    {/* Pickup Date & Time 2 */}
                    <Grid xs={12} md={3}>
                        <FormControl error={!!errors['pickupTime2']}>
                            <FormLabel>Pickup Date 2</FormLabel>
                            <Input
                                type="date"
                                value={formatDateForInput(formData.pickupTime2)}
                                onChange={(e) => handleDateChange('pickupTime2', e.target.value)}
                                fullWidth
                            />
                            {errors['pickupTime2'] && <FormHelperText>{errors['pickupTime2']}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={3}>
                        <FormControl>
                            <FormLabel>Pickup Time 2</FormLabel>
                            <Input
                                type="time"
                                value={formatTimeForInput(formData.pickupTime2)}
                                onChange={(e) => handleTimeChange('pickupTime2', e.target.value)}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>

                    {/* Delivery Date & Time 2 */}
                    <Grid xs={12} md={3}>
                        <FormControl error={!!errors['deliveryTime2']}>
                            <FormLabel>Delivery Date 2</FormLabel>
                            <Input
                                type="date"
                                value={formatDateForInput(formData.deliveryTime2)}
                                onChange={(e) => handleDateChange('deliveryTime2', e.target.value)}
                                fullWidth
                            />
                            {errors['deliveryTime2'] && <FormHelperText>{errors['deliveryTime2']}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={3}>
                        <FormControl>
                            <FormLabel>Delivery Time 2</FormLabel>
                            <Input
                                type="time"
                                value={formatTimeForInput(formData.deliveryTime2)}
                                onChange={(e) => handleTimeChange('deliveryTime2', e.target.value)}
                                fullWidth
                            />
                        </FormControl>
                    </Grid>

                    {/* Truck Selection and Distance */}
                    <Grid xs={12} md={6}>
                        <FormControl error={!!errors['distance']}>
                            <FormLabel>Distance</FormLabel>
                            <Input
                                value={formData.distance || ''}
                                onChange={(e) => handleChange('distance', Number(e.target.value))}
                                placeholder="Distance"
                                type="number"
                                endDecorator="miles"
                            />
                            {errors['distance'] && <FormHelperText>{errors['distance']}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <FormControl error={!!errors['truckId']} sx={{ width: '100%' }}>
                            <FormLabel>Truck</FormLabel>
                            <Select value={formData.truckId || ''} onChange={(_, value) => handleChange('truckId', value as string)} placeholder="Assign truck">
                                {trucks.map((truck: Truck) => (
                                    <Option key={truck._id} value={truck._id}>
                                        {truck.mainInfo}
                                    </Option>
                                ))}
                            </Select>
                            {errors['truckId'] && <FormHelperText>{errors['truckId']}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>

                {/* Comments */}
                <Grid xs={12}>
                    <FormControl>
                        <FormLabel>Comments</FormLabel>
                        <Textarea
                            value={formData.comment || ''}
                            onChange={(e) => handleChange('comment', e.target.value)}
                            placeholder="Add any additional comments or notes"
                            minRows={3}
                            maxRows={5}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', mt: 3 }}>
                <Button variant="plain" color="neutral" onClick={onCancel} disabled={loading}>
                    Cancel
                </Button>
                <Button type="submit" loading={loading}>
                    {initialData ? 'Update Load' : 'Create Load'}
                </Button>
            </Box>
        </form>
    )
}
