import { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/joy'
import { rateConfAnalyticsApi } from './utils/api'
import { Application, RateConf } from '@numeo/types'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'
import { Category } from 'hooks/useNotification'
import { RequestCard } from './components/RequestCard'
import { RateConfsCard } from './components/RateConfsCard'

export const RateConfAnalyticsPage = () => {
    // State management
    const [applications, setApplications] = useState<Application[]>([])
    const [selectedProject, setSelectedProject] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isProcessing, setIsProcessing] = useState<boolean>(false)
    const [rateConfirmations, setRateConfirmations] = useState<RateConf[]>([])
    const [processingStats, setProcessingStats] = useState<{
        processed: number
        rateConfs: number
        errors: number
    } | null>(null)
    const [emailLimit, setEmailLimit] = useState<string>('50')
    const [receivedBefore, setReceivedBefore] = useState<string>('')

    const dispatch = useDispatch()

    // Fetch applications on component mount
    useEffect(() => {
        fetchApplications()
        // eslint-disable-next-line
    }, [])

    // API calls
    const fetchApplications = async () => {
        try {
            setIsLoading(true)
            const apps = await rateConfAnalyticsApi.getApplications()
            setApplications(apps)
            setIsLoading(false)
        } catch (err) {
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: 'Failed to fetch applications',
                    type: 'error',
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
            setIsLoading(false)
        }
    }

    const fetchRateConfirmations = async () => {
        if (!selectedProject) return

        try {
            setIsLoading(true)
            const rateConfs = await rateConfAnalyticsApi.getRateConfirmations(selectedProject)
            setRateConfirmations(rateConfs)
            setIsLoading(false)
        } catch (err) {
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: 'Failed to fetch rate confirmations',
                    type: 'error',
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
            setIsLoading(false)
        }
    }

    const processEmails = async () => {
        if (!selectedProject) return

        try {
            setIsProcessing(true)
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Broker,
                    message: 'Processing emails',
                    type: 'info',
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )

            // Convert date string to timestamp if provided
            let timestamp: number | undefined = undefined
            if (receivedBefore) {
                const date = new Date(receivedBefore)
                if (!isNaN(date.getTime())) {
                    timestamp = Math.floor(date.getTime() / 1000) // Convert to seconds
                }
            }

            const response = await rateConfAnalyticsApi.processProjectEmails(selectedProject, Number(emailLimit), timestamp)

            if (response.success) {
                setProcessingStats(response.stats)
                if (response.results && response.results.length > 0) {
                    setRateConfirmations(response.results)
                }

                dispatch(
                    addNotification({
                        id: '',
                        category: Category.Broker,
                        message: 'Processing completed',
                        type: 'info',
                        timestamp: Date.now(),
                        topic: 'temp',
                    })
                )
            } else {
                dispatch(
                    addNotification({
                        id: '',
                        category: Category.Error,
                        message: 'Processing failed',
                        type: 'error',
                        timestamp: Date.now(),
                        topic: 'temp',
                    })
                )
            }

            setIsProcessing(false)
        } catch (err) {
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: 'Failed to process emails',
                    type: 'error',
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
            setIsProcessing(false)
        }
    }

    return (
        <Box sx={{ p: 3 }}>
            <Typography level="h2" sx={{ mb: 3 }}>
                Rate Confirmation Analytics
            </Typography>

            <RequestCard
                applications={applications}
                isLoading={isLoading}
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                emailLimit={emailLimit}
                setEmailLimit={setEmailLimit}
                receivedBefore={receivedBefore}
                setReceivedBefore={setReceivedBefore}
                processEmails={processEmails}
                isProcessing={isProcessing}
                processingStats={processingStats}
                fetchRateConfirmations={fetchRateConfirmations}
            />

            <RateConfsCard
                rateConfirmations={rateConfirmations}
                selectedProject={selectedProject}
                fetchRateConfirmations={fetchRateConfirmations}
                isLoading={isLoading}
            />
        </Box>
    )
}
