export enum BrokerType {
    FAVORITE = 'favorite',
    IN_BLACKLIST = 'in_blacklist',
    UNCLASSIFIED = 'unclassified',
}

export interface BrokerRateConf {
    documentId: string
    rate: number
    totalDistance: number
}

export interface IBroker {
    _id: string
    createdAt: string
    mcNumber: string
    fullName?: string
    companyName: string
    type: BrokerType
    email?: string
    phone?: string
    website?: string
    comment?: string
    carrierMcNumber?: string
    rateConfs: BrokerRateConf[]
}
