import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Card, Chip, CircularProgress, IconButton, Tooltip, Typography } from '@mui/joy'
import { EquipmentTypes, Truck } from '@numeo/types'
import { useGetQuery } from 'hooks/useGetQuery'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import spotApi from '../api/api'
import ActionDropdown from './Dropdown'
import TruckModal from './TruckModal'
import TruckInfoPopper from './TruckInfoPopper'
import { useAuth } from 'hooks/auth'
import { addNotification } from 'store/notificationSlice'
import { useDispatch } from 'react-redux'
import { Category } from 'hooks/useNotification'

export const tempTruckData: Omit<Truck, '_id'> = {
    mainInfo: '',
    truckId: '',
    firstDriver: {
        name: '',
        phone: '',
        isUSCitizen: false,
        hometown: '',
        driverEmail: '',
    },
    secondDriver: {
        name: '',
        phone: '',
        isUSCitizen: false,
        hometown: '',
        driverEmail: '',
    },
    samsaraId: '',
    tteldVin: '',
    lucidEldNumber: '',
    lucidEldVin: '',
    maxTravelHoursPerDay: '',
    equipment: {
        type: EquipmentTypes.CLASSES,
        values: [],
    },
    length: '',
    weight: '',
    deadHeadOrigin: '',
    deadHeadDestination: '',
    teamSolo: 'SOLO',
    weeklyGrossTarget: '',
    maxTravelDistance: '',
    minTravelDistance: '',
    fullPartial: 'BOTH',
    excludedStates: [],
    avoidWinterRoads: false,
    isRestricted: {
        alcohol: false,
        baledPaper: false,
        emptyBottles: false,
        aluminiumCan: false,
        scrap: false,
    },
    isPermitted: {
        hazmat: false,
        tanker: false,
        doubleTripleTrailers: false,
        combinationEndorsements: false,
        canadaOperatingAuthority: false,
        mexicoOperatingAuthority: false,
        oversizeOverweight: false,
        hazardousWasteRadiological: false,
    },
    security: {
        tsa: false,
        twic: false,
        heavyDutyLock: false,
        escortDrivingOK: false,
        crossBorderLoads: false,
    },
}

export const getEmptyTruckData = (): Omit<Truck, '_id'> => ({
    mainInfo: '',
    truckId: '',
    firstDriver: {
        name: '',
        phone: '',
        isUSCitizen: false,
        hometown: '',
        driverEmail: '',
    },
    secondDriver: {
        name: '',
        phone: '',
        isUSCitizen: false,
        hometown: '',
        driverEmail: '',
    },
    samsaraId: '',
    tteldVin: '',
    lucidEldNumber: '',
    lucidEldVin: '',
    maxTravelHoursPerDay: '',
    equipment: {
        type: EquipmentTypes.CLASSES,
        values: [],
    },
    length: '',
    weight: '',
    deadHeadOrigin: '',
    deadHeadDestination: '',
    teamSolo: 'SOLO',
    weeklyGrossTarget: '',
    maxTravelDistance: '',
    minTravelDistance: '',
    fullPartial: 'BOTH',
    excludedStates: [],
    avoidWinterRoads: false,
    isRestricted: {
        alcohol: false,
        baledPaper: false,
        emptyBottles: false,
        aluminiumCan: false,
        scrap: false,
    },
    isPermitted: {
        hazmat: false,
        tanker: false,
        doubleTripleTrailers: false,
        combinationEndorsements: false,
        canadaOperatingAuthority: false,
        mexicoOperatingAuthority: false,
        oversizeOverweight: false,
        hazardousWasteRadiological: false,
    },
    security: {
        tsa: false,
        twic: false,
        heavyDutyLock: false,
        escortDrivingOK: false,
        crossBorderLoads: false,
    },
})

const TruckHorizontalList = ({ onTrucksRefresh, initialTrucks, isLoading: parentIsLoading }) => {
    const dispatch = useDispatch()
    const truckId = useGetQuery('truckId')
    const { application, setApplication } = useAuth()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [truckDataTemp, setTruckDataTemp] = useState<Truck>(tempTruckData as Truck)
    const [activeActionsTruckId, setActiveActionsTruckId] = useState<string | null>(null)
    const [latestTruck, setLatestTruck] = useState<Truck[]>(initialTrucks)
    const [truckUpsertLoading, setTruckUpsertLoading] = useState(false)
    const [infoModalTruckId, setInfoModalTruckId] = useState<string | null>(null)
    const [loadingTruckId, setLoadingTruckId] = useState<string | null>(null)
    const scrollContainerRef = useRef<HTMLDivElement | null>(null)
    const [editingTruckId, setEditingTruckId] = useState<string | null>(null)
    const [showAddModal, setShowAddModal] = useState(false)

    const onTruckActivated = useCallback(
        (id: string | null) => {
            if (id) {
                try {
                    setLoadingTruckId(id)
                    const newSearchParams = new URLSearchParams(searchParams)
                    newSearchParams.set('truckId', id)
                    navigate(`?${newSearchParams.toString()}`, { replace: true })
                } catch (error) {
                    console.error('Error selecting truck:', error)
                } finally {
                    setLoadingTruckId(null)
                }
            } else {
                const newSearchParams = new URLSearchParams(searchParams)
                newSearchParams.delete('truckId')
                navigate(`?${newSearchParams.toString()}`, { replace: true })
            }
        },
        [searchParams, navigate, setLoadingTruckId]
    )

    const handleDeleteSuccess = useCallback(
        (deletedTruckId: string) => {
            const newSearchParams = new URLSearchParams(searchParams)
            newSearchParams.delete('truckId')
            setSearchParams(newSearchParams)
            dispatch(
                addNotification({
                    id: deletedTruckId || '',
                    message: 'Truck deleted successfully',
                    type: 'info',
                    category: Category.LoadFinderTruck,
                    timestamp: Date.now(),
                    secondaryMessage: 'Truck deleted successfully',
                    topic: 'temp',
                })
            )
            onTrucksRefresh?.(latestTruck.filter((truck) => truck._id !== deletedTruckId))
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [searchParams, setSearchParams, latestTruck, onTrucksRefresh]
    )

    useEffect(() => {
        setLatestTruck(initialTrucks)

        // If we have a truckId in URL but no trucks loaded yet, wait for trucks
        if (truckId && (!initialTrucks || initialTrucks.length === 0)) {
            return
        }

        // If we have trucks but no truckId selected, select the first one
        if (initialTrucks?.length > 0 && !truckId && !parentIsLoading) {
            const sortedTrucks = [...initialTrucks].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            onTruckActivated(sortedTrucks[0]._id)
        }
        // If we have no trucks but a truckId is selected, clear the selection
        else if (initialTrucks?.length === 0 && truckId) {
            onTruckActivated(null)
        }
        // If we have a truckId and trucks, validate that the truckId exists
        else if (truckId && initialTrucks?.length > 0) {
            const truckExists = initialTrucks.some((truck) => truck._id === truckId)
            if (!truckExists) {
                onTruckActivated(null)
            }
        }
    }, [initialTrucks, truckId, onTruckActivated, parentIsLoading])

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' })
        }
    }

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' })
        }
    }

    const createTruck = async () => {
        setTruckUpsertLoading(true)
        try {
            const requestData = {
                ...truckDataTemp,
                deadHeadOrigin: truckDataTemp.deadHeadOrigin === '' ? '150' : truckDataTemp.deadHeadOrigin,
                deadHeadDestination: truckDataTemp.deadHeadDestination === '' ? '150' : truckDataTemp.deadHeadDestination,
            }
            const response = await spotApi.post('/trucks', requestData)
            setApplication(response.application)
            setShowAddModal(false)
            onTrucksRefresh?.([response.data, ...latestTruck])
            onTruckActivated(response.data._id)

            dispatch(
                addNotification({
                    id: response.data._id,
                    message: 'Truck created successfully',
                    secondaryMessage: `Truck name: ${response.data.mainInfo}`,
                    type: 'info',
                    category: Category.LoadFinderTruck,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
        } catch (error) {
            console.error('Error creating truck ', error)
            dispatch(
                addNotification({
                    id: '',
                    message: 'Failed to create truck',
                    secondaryMessage: `Truck name: ${truckDataTemp.mainInfo}`,
                    type: 'error',
                    category: Category.Error,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
        } finally {
            setTruckUpsertLoading(false)
        }
    }

    useEffect(() => {
        if (editingTruckId) {
            const truckData = latestTruck?.find((truck) => truck._id === editingTruckId)
            if (truckData) {
                setTruckDataTemp({
                    ...truckData,
                })
            }
        }
    }, [editingTruckId, latestTruck])

    const editTruck = async () => {
        setTruckUpsertLoading(true)
        try {
            const response = await spotApi.put(`/trucks/${editingTruckId}`, {
                ...truckDataTemp,
                createdAt: latestTruck?.find((truck) => truck._id === editingTruckId)?.createdAt,
                updatedAt: new Date().toISOString(),
            })
            setApplication(response.application)
            setTruckDataTemp(tempTruckData as Truck)
            onTrucksRefresh?.(latestTruck.map((truck) => (truck._id === editingTruckId ? truckDataTemp : truck)))
            onTruckActivated(editingTruckId)
            setEditingTruckId(null)
            dispatch(
                addNotification({
                    id: editingTruckId || '',
                    message: 'Truck edited successfully',
                    secondaryMessage: `Truck name: ${truckDataTemp.mainInfo}`,
                    type: 'info',
                    category: Category.LoadFinderTruck,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
        } catch (error) {
            console.error('Error updating truck ', error)
            dispatch(
                addNotification({
                    id: '',
                    message: 'Failed to edit truck',
                    type: 'error',
                    category: Category.Error,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
        } finally {
            setTruckUpsertLoading(false)
        }
    }

    let chipTooltipMsg: string = ''
    const isELDIntegrated = (truck: Truck) => {
        chipTooltipMsg = ''
        let hasIntegration = false

        if (truck.tteldVin && application?.integrations?.tteld) {
            chipTooltipMsg = 'TT ELD'
            hasIntegration = true
        }

        if (truck.lucidEldNumber && application?.integrations?.lucidEld) {
            chipTooltipMsg = chipTooltipMsg ? `${chipTooltipMsg} & Lucid ELD` : 'Lucid ELD'
            hasIntegration = true
        }

        if (truck.samsaraId && application?.integrations?.samsara) {
            chipTooltipMsg = chipTooltipMsg ? `${chipTooltipMsg} & Samsara` : 'Samsara'
            hasIntegration = true
        }

        if (hasIntegration) {
            chipTooltipMsg += ' Integrated'
        }

        return hasIntegration
    }

    return (
        <Box
            sx={{
                width: '100%',
                position: 'relative',
                display: 'block',
                mt: 0.2,
                mb: { md: 1, sm: 0.5 },
            }}
        >
            {parentIsLoading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            <TruckModal
                open={showAddModal}
                onClose={() => {
                    setShowAddModal(false)
                    setTruckDataTemp({ ...getEmptyTruckData(), _id: 'temp_' + Date.now() } as Truck)
                }}
                isEditing={false}
                truckDataTemp={truckDataTemp}
                setTruckDataTemp={setTruckDataTemp}
                onSave={createTruck}
                loading={truckUpsertLoading}
            />

            <TruckModal
                open={!!editingTruckId}
                onClose={() => {
                    setEditingTruckId(null)
                    setTruckDataTemp({ ...getEmptyTruckData(), _id: 'temp_' + Date.now() } as Truck)
                }}
                isEditing={true}
                truckDataTemp={truckDataTemp}
                setTruckDataTemp={setTruckDataTemp}
                onSave={editTruck}
                loading={truckUpsertLoading}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box sx={{ maxWidth: '40px' }}>
                    <IconButton
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setTruckDataTemp({ ...getEmptyTruckData(), _id: 'temp_' + Date.now() } as Truck)
                            setShowAddModal(true)
                        }}
                    >
                        <AddCircleOutlineIcon />
                    </IconButton>
                </Box>

                <IconButton variant="outlined" onClick={scrollLeft}>
                    <ChevronLeftIcon />
                </IconButton>

                <Box
                    ref={scrollContainerRef}
                    sx={{
                        scrollBehavior: 'smooth',
                        width: '100%',
                        display: 'flex',
                        gap: 1.5,
                        overflowX: 'auto',
                        scrollbarWidth: 'none',
                    }}
                >
                    {latestTruck && latestTruck.length === 0 ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography sx={{ fontSize: '1rem', fontWeight: '500' }}>No trucks yet</Typography>
                        </Box>
                    ) : (
                        latestTruck.map((truck, index) => {
                            return (
                                <Card
                                    key={index}
                                    variant="outlined"
                                    sx={{
                                        minWidth: '200px',
                                        maxWidth: '250px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: 5,
                                        py: 0,
                                        pl: 2,
                                        pr: 0,
                                        borderRadius: '6px',
                                        backgroundColor: truck._id === truckId ? 'primary.softBg' : 'transparent',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: 'primary.softHoverBg',
                                        },
                                    }}
                                    onClick={() => {
                                        onTruckActivated(truck._id)
                                    }}
                                >
                                    {truck._id === loadingTruckId && (
                                        <CircularProgress
                                            size="sm"
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                            }}
                                        />
                                    )}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            gap: 1,
                                            width: '100%',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'start',
                                                alignItems: 'between',
                                                flexDirection: 'column',
                                                gap: 1,
                                                width: '50%',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth: '160px',
                                                }}
                                                fontSize="sm"
                                                fontWeight="md"
                                            >
                                                {truck.mainInfo}
                                            </Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'end',
                                                alignItems: 'center',
                                                width: '50%',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                {isELDIntegrated(truck) && (
                                                    <Tooltip title={chipTooltipMsg}>
                                                        <Chip variant="soft" size="sm" color="success" sx={{ mr: 1 }}>
                                                            ELD
                                                        </Chip>
                                                    </Tooltip>
                                                )}
                                                <TruckInfoPopper truckData={truck} />
                                                <ActionDropdown
                                                    truckId={truck._id}
                                                    onEdit={(editedTruck) => {
                                                        setTruckDataTemp(editedTruck)
                                                        setEditingTruckId(editedTruck._id)
                                                    }}
                                                    truckList={latestTruck}
                                                    setActiveActionsTruckId={setActiveActionsTruckId}
                                                    onDeleteSuccess={handleDeleteSuccess}
                                                    infoModalTruckId={infoModalTruckId}
                                                    setInfoModalTruckId={setInfoModalTruckId}
                                                    activeActionsTruckId={activeActionsTruckId}
                                                    onTruckActivated={onTruckActivated}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Card>
                            )
                        })
                    )}
                </Box>
                <IconButton
                    variant="outlined"
                    sx={{
                        mr: 6,
                    }}
                    onClick={scrollRight}
                >
                    <ChevronRightIcon />
                </IconButton>
            </Box>
        </Box>
    )
}
export default TruckHorizontalList
