import { Stack, Tab, TabList, TabPanel, tabClasses } from '@mui/joy'
import Box from '@mui/joy/Box'
import { chipClasses } from '@mui/joy/Chip'
import Tabs from '@mui/joy/Tabs'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
    AccountDetailsTab,
    DevelopersSettings,
    EmailSettingsTab,
    EmailTemplateTab,
    IntegrationsTab,
    PickupsDestinationsUI,
    SpotFinderSettings,
    UpdaterSettings,
} from './components/Tabs'

interface SettingsTab {
    id: number
    label: string
    tabParam: string
    component?: React.ReactNode
}

const tabs: SettingsTab[] = [
    { id: 0, label: 'Account Details', tabParam: 'account-details', component: <AccountDetailsTab /> },
    { id: 1, label: 'Email Settings', tabParam: 'email-settings', component: <EmailSettingsTab /> },
    { id: 2, label: 'Pickups Destinations', tabParam: 'pickups-destinations', component: <PickupsDestinationsUI /> },
    { id: 3, label: 'Email Template Settings', tabParam: 'email-template', component: <EmailTemplateTab /> },
    { id: 4, label: 'Developers Settings', tabParam: 'developers-settings', component: <DevelopersSettings /> },
    { id: 5, label: 'Integrations', tabParam: 'integrations', component: <IntegrationsTab /> },
    { id: 6, label: 'Updater Settings', tabParam: 'updater-settings', component: <UpdaterSettings /> },
    { id: 7, label: 'SpotFinder Settings', tabParam: 'spotfinder-settings', component: <SpotFinderSettings /> },
]
export default function SettingsPage() {
    const [searchParams, setSearchParams] = useSearchParams()

    const getInitialTab = (): SettingsTab => {
        const tabParam = searchParams.get('tab')
        return tabs.find((tab) => tab.tabParam === tabParam) || tabs[0]
    }

    const [activeTab, setActiveTab] = useState(getInitialTab())

    const handleTabChange = (event: React.SyntheticEvent<Element, Event> | null, value: string | number | null) => {
        if (value === null) return
        const numericValue: number = typeof value === 'string' ? parseInt(value, 10) : value
        if (numericValue < tabs.length) {
            const tab = tabs[numericValue]
            setActiveTab(tab)
            setSearchParams({ tab: tab.tabParam })
        }
    }

    return (
        <Stack spacing={4}>
            <Box sx={{ flex: 1, width: '100%', mx: 'auto' }}>
                <Tabs value={activeTab.id} onChange={handleTabChange} sx={{ bgcolor: 'transparent' }}>
                    <Box
                        sx={{
                            '--_shadow-height': '16px',
                            height: 0,
                            position: 'sticky',
                            top: 'calc(48px - var(--main-paddingTop, 0px) + var(--Header-height, 0px) - (var(--_shadow-height) / 2))',
                            zIndex: 1,
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'relative',
                                zIndex: 1,
                                height: 'var(--_shadow-height)',
                                background: 'radial-gradient(closest-side, rgba(0 0 0 / 0.12), transparent 100%)',
                            },
                        }}
                    />
                    <TabList
                        sticky="top"
                        variant="plain"
                        sx={(theme) => ({
                            '--Chip-minHeight': '20px',
                            '--ListItem-minHeight': '48px',
                            top: 'calc(-1 * (var(--main-paddingTop, 0px) - var(--Header-height, 0px)))',
                            zIndex: 10,
                            width: '100%',
                            overflow: 'auto hidden',
                            alignSelf: 'flex-start',
                            scrollSnapType: 'inline',
                            '&::after': {
                                pointerEvents: 'none',
                                display: { xs: 'block', sm: 'none' },
                                content: '""',
                                position: 'sticky',
                                top: 0,
                                width: 40,
                                flex: 'none',
                                zIndex: 1,
                                right: 0,
                                borderBottom: '1px solid transparent',
                                background: `linear-gradient(to left, ${theme.vars.palette.background.body}, rgb(0 0 0 / 0))`,
                                backgroundClip: 'content-box',
                            },
                            '&::-webkit-scrollbar': {
                                width: 0,
                                display: 'none',
                            },
                            [`& .${tabClasses.root}`]: {
                                '--focus-outline-offset': '-2px',
                                '&:first-of-type': {
                                    ml: 'calc(-1 * var(--ListItem-paddingX))',
                                },
                                scrollSnapAlign: 'start',
                                bgcolor: 'transparent',
                                flex: 'none',
                                '&:hover': {
                                    bgcolor: 'transparent',
                                },
                                [`&.${tabClasses.selected}`]: {
                                    color: 'primary.plainColor',
                                    bgcolor: 'transparent',
                                    [`& .${chipClasses.root}`]: theme.variants.solid.primary,
                                },
                            },
                        })}
                    >
                        {tabs.map((tab) => (
                            <Tab key={tab.id} value={tab.id} indicatorInset>
                                {tab.label}
                            </Tab>
                        ))}
                    </TabList>
                    {tabs.map((tab) => (
                        <TabPanel key={tab.id} value={tab.id}>
                            {tab.component}
                        </TabPanel>
                    ))}
                </Tabs>
            </Box>
        </Stack>
    )
}
