import { useEffect, useState } from 'react'
import axios from 'axios'
import { Box, Card, Input, Slider, Button, Typography, CircularProgress, Alert } from '@mui/joy'
import { Add, Delete, InfoOutlined, LocationCityTwoTone } from '@mui/icons-material'

export const PickupsDestinationsUI = () => {
    const [locations, setLocations] = useState([
        {
            id: 1,
            typeFrom: 'Pickup',
            typeTo: 'Destination',
            locationFrom: '',
            locationTo: '',
            radius: 10,
            addressFrom: '',
            addressTo: '',
            loadingFrom: false,
            loadingTo: false,
        },
    ])
    const [error, setError] = useState('')

    const handleAdd = () => {
        setLocations([
            ...locations,
            {
                id: Date.now(),
                typeFrom: 'Pickup',
                typeTo: 'Destination',
                locationFrom: '',
                locationTo: '',
                radius: 10,
                addressFrom: '',
                addressTo: '',
                loadingFrom: false,
                loadingTo: false,
            },
        ])
    }

    const handleRemove = (id: number) => {
        // Prevent removal of the initial pair (id === 1)
        if (id !== 1) {
            setLocations(locations.filter((loc) => loc.id !== id))
        }
    }

    const handleChange = (id: number, field: string, value) => {
        setLocations(locations.map((loc) => (loc.id === id ? { ...loc, [field]: value } : loc)))
    }

    const fetchAddress = async (id: number, zipCode: string, type: 'from' | 'to') => {
        if (!/^\d{5}$/.test(zipCode)) {
            setError('Please enter a valid 5-digit ZIP code.')
            return
        }

        setError('')
        setLocations(locations.map((loc) => (loc.id === id ? { ...loc, [`loading${type === 'from' ? 'From' : 'To'}`]: true } : loc)))

        try {
            const options = {
                method: 'GET',
                url: `https://community-zippopotamus.p.rapidapi.com/us/${zipCode}`,
                headers: {
                    'x-rapidapi-key': 'a49393feaamshc58bdf357243435p13429bjsn74b59756127e',
                    'x-rapidapi-host': 'community-zippopotamus.p.rapidapi.com',
                },
            }

            const response = await axios.request(options)
            const address = `${response.data.places[0]['place name']}, ${response.data.places[0]['state']}, ${response.data.country}`
            setLocations(
                locations.map((loc) =>
                    loc.id === id
                        ? {
                              ...loc,
                              [`address${type === 'from' ? 'From' : 'To'}`]: address,
                              [`loading${type === 'from' ? 'From' : 'To'}`]: false,
                          }
                        : loc
                )
            )
        } catch (error) {
            setError('Could not retrieve address for the given ZIP code.')
            setLocations(locations.map((loc) => (loc.id === id ? { ...loc, [`loading${type === 'from' ? 'From' : 'To'}`]: false } : loc)))
        }
    }
    useEffect(() => {
        const fetchLocationInfo = async (zipCode: string) => {
            const apiKey = process.env.REACT_APP_GOOGLE_CLOUD_API_KEY
            const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${apiKey}`

            try {
                const response = await axios.get(url)
                if (response.data.status === 'OK') {
                    const location = response.data.results[0]
                    const formattedAddress = location.formatted_address
                    console.log('Location:', formattedAddress) // Example: "New York, NY 10011, USA"
                } else {
                    console.error('Error fetching location:', response.data.status)
                }
            } catch (error) {
                console.error('Error:', error)
            }
        }
        fetchLocationInfo('10001')
    }, [])

    return (
        <Box sx={{ padding: 2 }}>
            <Card sx={{ padding: 2 }}>
                <Typography level="h4" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                    Define Pickups and Destinations
                </Typography>

                {locations.map((item) => (
                    <Box
                        key={item.id}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            alignItems: 'flex-start',
                            marginBottom: 2,
                            border: '1px solid #ddd',
                            borderRadius: 2,
                            padding: 2,
                        }}
                    >
                        {/* From Location */}
                        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                            <Input
                                placeholder={`Enter ${item.typeFrom} Location (ZIP code)`}
                                value={item.locationFrom}
                                onChange={(e) => handleChange(item.id, 'locationFrom', e.target.value)}
                                sx={{ flexGrow: 1, height: 40 }}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => fetchAddress(item.id, item.locationFrom, 'from')}
                                sx={{ height: 40 }}
                                disabled={item.loadingFrom}
                                startDecorator={<LocationCityTwoTone />}
                            >
                                {item.loadingFrom ? <CircularProgress /> : 'Get Address'}
                            </Button>
                        </Box>

                        {/* Address Display for From Location */}
                        {item.addressFrom && (
                            <Box sx={{ width: '100%', marginTop: 1 }}>
                                <Typography startDecorator={<InfoOutlined />} color="success" sx={{ mb: 2 }}>
                                    {item.addressFrom}
                                </Typography>
                            </Box>
                        )}

                        {/* To Location */}
                        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                            <Input
                                placeholder={`Enter ${item.typeTo} Location (ZIP code)`}
                                value={item.locationTo}
                                onChange={(e) => handleChange(item.id, 'locationTo', e.target.value)}
                                sx={{ flexGrow: 1, height: 40 }}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => fetchAddress(item.id, item.locationTo, 'to')}
                                sx={{ height: 40 }}
                                disabled={item.loadingTo}
                                startDecorator={<LocationCityTwoTone />}
                            >
                                {item.loadingTo ? <CircularProgress /> : 'Get Address'}
                            </Button>
                        </Box>

                        {/* Address Display for To Location */}
                        {item.addressTo && (
                            <Box sx={{ width: '100%', marginTop: 1 }}>
                                <Typography startDecorator={<InfoOutlined />} color="success" sx={{ mb: 2 }}>
                                    {item.addressTo}
                                </Typography>
                            </Box>
                        )}

                        {/* Radius Slider */}
                        <Box sx={{ width: '100%' }}>
                            <Typography>Radius (km)</Typography>
                            <Slider value={item.radius} onChange={(e, value) => handleChange(item.id, 'radius', value)} min={0} max={100} />
                            <Typography sx={{ textAlign: 'center' }}>{item.radius} km</Typography>
                        </Box>

                        {item.id === 1 ? null : (
                            <Button startDecorator={<Delete />} color="danger" onClick={() => handleRemove(item.id)} variant="outlined" sx={{ height: 40 }}>
                                Remove
                            </Button>
                        )}
                    </Box>
                ))}

                {error && <Alert sx={{ marginTop: 2, color: '#bf0000' }}>{error}</Alert>}

                <Box sx={{ display: 'flex', alignContent: 'end', justifyContent: 'end', marginTop: 2 }}>
                    <Button onClick={handleAdd} variant="solid" color="primary" sx={{ marginTop: 2, height: 50, width: 200 }} startDecorator={<Add />}>
                        Add Pair (From - To)
                    </Button>
                </Box>
            </Card>
        </Box>
    )
}
