import { Box, Stack, Typography } from '@mui/joy'
import React from 'react'
import { calculateRatePerMile } from '../assets/helpers'
import EmailForm from '../EmailForm'
import { RateColumnProps } from './types'
import { Category } from 'hooks/useNotification'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'

/**
 * RateColumn component displays rate information when the load row is expanded.
 * This includes:
 * - Total rate
 * - Trip length
 * - Rate/Mile
 * - Email Form (if email is available)
 */

export const RateColumn: React.FC<RateColumnProps> = ({ load, handleEmailSent }) => {
    const dispatch = useDispatch()
    if (!load.rateInfo?.rateUsd && !load.posterInfo.contact?.email) return null

    const renderRatePart = () => {
        if (!load.rateInfo?.rateUsd) return null

        return (
            <>
                <Typography
                    sx={{
                        color: 'text.secondary',
                        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#2b2b2b' : '#f0f0f0'),
                        px: 1,
                        fontWeight: 'bold',
                    }}
                >
                    Rate
                </Typography>
                <Stack direction={{ sm: 'column', md: 'row' }} justifyContent={'space-between'} px={1}>
                    <Stack direction={'row'} gap={1}>
                        <Typography
                            level="body-sm"
                            sx={{
                                fontSize: '0.8rem',
                            }}
                        >
                            Total Rate:
                        </Typography>
                        <Typography
                            level="body-sm"
                            sx={{
                                fontSize: '0.8rem',
                            }}
                        >
                            ${load.rateInfo.rateUsd.toLocaleString()}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} gap={1}>
                        <Typography
                            level="body-sm"
                            sx={{
                                fontSize: '0.8rem',
                            }}
                        >
                            Rate/Mile:
                        </Typography>
                        <Typography
                            level="body-sm"
                            sx={{
                                fontSize: '0.8rem',
                            }}
                        >
                            {calculateRatePerMile(load.tripLength, load.rateInfo.rateUsd)}
                        </Typography>
                    </Stack>
                </Stack>
            </>
        )
    }

    const renderEmailPart = () => {
        if (!load.posterInfo.contact?.email) return null

        return (
            <Box sx={{ width: '100%' }}>
                <EmailForm
                    load={load}
                    onEmailSent={(success) => {
                        dispatch(
                            addNotification({
                                id: '',
                                message: success ? 'Email sent successfully!' : 'Failed to send email',
                                type: success ? 'info' : 'error',
                                category: Category.LoadFinderTruck,
                                timestamp: Date.now(),
                                topic: 'temp',
                            })
                        )
                    }}
                    handleEmailSent={handleEmailSent}
                />
            </Box>
        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%',
            }}
        >
            {renderRatePart()}
            {renderEmailPart()}
        </Box>
    )
}
