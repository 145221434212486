import { Block, ThumbUp } from '@mui/icons-material'
import { Modal, ModalDialog, Typography, Box, Button } from '@mui/joy'
import { BrokerType, ClientLoadModel } from '@numeo/types'

interface BrokerActionModalProps {
    showBrokerActionModal: boolean
    setShowBrokerActionModal: (show: boolean) => void
    load: ClientLoadModel
    isBrokerActionLoading: {
        favorite: boolean
        blackList: boolean
    }
    handleAddBrokerConfirmation: (brokerType: BrokerType) => void
}

export const BrokerActionModal = ({
    showBrokerActionModal,
    setShowBrokerActionModal,
    load,
    isBrokerActionLoading,
    handleAddBrokerConfirmation,
}: BrokerActionModalProps) => {
    return (
        <Modal open={showBrokerActionModal} onClose={() => setShowBrokerActionModal(false)}>
            <ModalDialog>
                <Typography level="h4">Broker Action</Typography>
                <Typography sx={{ mt: 2 }}>
                    What would you like to do with {load.posterInfo?.companyName} ({load.posterInfo?.mcNumber})?
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <Button
                            variant="outlined"
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation()
                                setShowBrokerActionModal(false)
                            }}
                            disabled={isBrokerActionLoading.favorite || isBrokerActionLoading.blackList}
                        >
                            Cancel
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                            disabled={isBrokerActionLoading.favorite || isBrokerActionLoading.blackList}
                            variant="solid"
                            color="success"
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation()
                                handleAddBrokerConfirmation(BrokerType.FAVORITE)
                            }}
                            loading={isBrokerActionLoading.favorite}
                            startDecorator={<ThumbUp />}
                        >
                            Favorite
                        </Button>
                        <Button
                            disabled={isBrokerActionLoading.favorite || isBrokerActionLoading.blackList}
                            variant="solid"
                            color="danger"
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation()
                                handleAddBrokerConfirmation(BrokerType.IN_BLACKLIST)
                            }}
                            loading={isBrokerActionLoading.blackList}
                            startDecorator={<Block />}
                        >
                            Blacklist
                        </Button>
                    </Box>
                </Box>
            </ModalDialog>
        </Modal>
    )
}
