import { IAddress, RateConf } from '@numeo/types'

const calculateRatePerMile = (distance: number | null | undefined, rate: number | null | undefined): string => {
    // Handle edge cases
    if (!distance || !rate || distance <= 0) {
        return '-'
    }

    // Calculate and format rate per mile
    const ratePerMile = rate / distance
    return isFinite(ratePerMile) ? `$${parseFloat(ratePerMile.toFixed(2))}` : '-'
}

export const exportCSV = (rateConfirmations: RateConf[]) => {
    // Find the maximum number of stops across all rate confirmations
    const maxStops = rateConfirmations.reduce((max, rc) => {
        const stopsCount = rc.rateConf.route?.length || 0
        return stopsCount > max ? stopsCount : max
    }, 0)

    // Define the CSV headers
    const headers = [
        // Metadata and identification
        'Ratecon Number',
        'Project Name',
        'Email Date',
        'Email Address',

        // Email Body Content
        'Broker Company Name (Email Body)',
        'Broker Contact Name (Email Body)',
        'Broker MC# (Email Body)',
        'Broker Phone (Email Body)',
        'Broker Email (Email Body)',

        // Carrier details
        'Carrier Company Name',
        'Carrier MC#',
        'Carrier DOT#',
        'Carrier Contact Name',
        'Carrier Contact Phone',
        'Carrier Contact Email',
        'Carrier Address',

        // Driver details
        'First Driver Name',
        'First Driver Phone',
        'Second Driver Name',
        'Second Driver Phone',
        'Truck Number',
        'Trailer Number',
        'Trailer Type',

        // Broker details
        'Broker Company Name',
        'Broker MC#',
        'Broker Contact Name',
        'Broker Contact Phone',
        'Broker Contact Email',
        'Broker Address',

        // Shipper details
        'Shipper Company Name',
        'Shipper Contact Name',
        'Shipper Contact Phone',
        'Shipper Contact Email',
        'Shipper Address',

        // Route & Distance
        'Total Distance',
    ]

    // Add headers for each stop
    for (let i = 0; i < maxStops; i++) {
        const stopNumber = i + 1
        headers.push(`Stop ${stopNumber} - Location`, `Stop ${stopNumber} - Address`, `Stop ${stopNumber} - Date`, `Stop ${stopNumber} - Time`)
    }

    // Add the rest of the headers
    headers.push(
        // Freight details
        'Freight Type',
        'Freight Content',
        'Freight Weight',
        'Freight Quantity',

        // Rate details
        'Rate Amount',
        'Rate Per Mile',

        // File & Recognition info
        'Processing Time',
        'File URL'
    )

    // Format the data for CSV
    const csvData = rateConfirmations.map((rc) => {
        const ratePerMile = calculateRatePerMile(rc.rateConf.totalDistance, rc.rateConf.rate.amount)

        let emailDate = '-'
        if (rc.emailDate) {
            const date = new Date(rc.emailDate * 1000)
            emailDate = date.toLocaleDateString()
        }

        // Format addresses
        const formatAddress = (address?: IAddress) => {
            if (!address) return '-'

            // Filter out empty or undefined values
            const parts = [address.street, address.city, address.state, address.zip].filter((part) => part && part.trim() !== '')

            // Join the parts with commas
            return parts.length > 0 ? parts.join(', ') : '-'
        }

        // Format location
        const formatLocation = (city?: string, state?: string) => {
            if (!city && !state) return '-'
            return `${city || ''}, ${state || ''}`.replace(/^, /, '').replace(/, $/, '') || '-'
        }

        // Format date and time from stop date
        const formatStopDate = (dateStr?: string) => {
            if (!dateStr) return '-'
            try {
                const date = new Date(dateStr)
                return date.toLocaleDateString()
            } catch (e) {
                return '-'
            }
        }

        // Create base row data
        const rowData = [
            // Metadata and identification
            rc.rateConf.documentId || '-',
            rc.projectName || '-',
            emailDate,
            rc.emailAddress || '-',

            // Email Body Content
            rc.emailBodyContent?.broker?.companyName || '-',
            rc.emailBodyContent?.broker?.contact.name || '-',
            rc.emailBodyContent?.broker?.mcNumber || '-',
            rc.emailBodyContent?.broker?.contact.phone || '-',
            rc.emailBodyContent?.broker?.contact.email || '-',

            // Carrier details
            rc.rateConf.carrier?.companyName || '-',
            rc.rateConf.carrier?.mcNumber || '-',
            rc.rateConf.carrier?.dotNumber || '-',
            rc.rateConf.carrier?.contact?.name || '-',
            rc.rateConf.carrier?.contact?.phone || '-',
            rc.rateConf.carrier?.contact?.email || '-',
            formatAddress(rc.rateConf.carrier?.address),

            // Driver details
            rc.rateConf.driver?.firstDriverName || '-',
            rc.rateConf.driver?.firstDriverPhone || '-',
            rc.rateConf.driver?.secondDriverName || '-',
            rc.rateConf.driver?.secondDriverPhone || '-',
            rc.rateConf.driver?.truckNumber || '-',
            rc.rateConf.driver?.trailerNumber || '-',
            rc.rateConf.driver?.trailerType || '-',

            // Broker details
            rc.rateConf.broker?.companyName || '-',
            rc.rateConf.broker?.mcNumber || '-',
            rc.rateConf.broker?.contact?.name || '-',
            rc.rateConf.broker?.contact?.phone || '-',
            rc.rateConf.broker?.contact?.email || '-',
            formatAddress(rc.rateConf.broker?.address),

            // Shipper details
            rc.rateConf.shipper?.companyName || '-',
            rc.rateConf.shipper?.contact?.name || '-',
            rc.rateConf.shipper?.contact?.phone || '-',
            rc.rateConf.shipper?.contact?.email || '-',
            formatAddress(rc.rateConf.shipper?.address),

            // Route & Distance
            rc.rateConf.totalDistance || '-',
        ]

        // Add route stops data
        const stops = rc.rateConf.route || []
        for (let i = 0; i < maxStops; i++) {
            const stop = stops[i]
            if (stop) {
                const location = formatLocation(stop.location?.city, stop.location?.state)
                const address = formatAddress(stop.location)
                const date = formatStopDate(stop.date)
                rowData.push(location, address, date, stop.time)
            } else {
                // Add placeholders for missing stops
                rowData.push('-', '-', '-', '-')
            }
        }

        // Add the rest of the data
        rowData.push(
            // Freight details
            rc.rateConf.freightDetails?.type || '-',
            rc.rateConf.freightDetails?.content || '-',
            rc.rateConf.freightDetails?.weight && rc.rateConf.freightDetails.weight.value ? `${rc.rateConf.freightDetails.weight.value}` : '-',
            rc.rateConf.freightDetails?.quantity || '-',

            // Rate details
            rc.rateConf.rate?.amount ? `$${rc.rateConf.rate.amount}` : '-',
            ratePerMile,

            // File & Recognition info
            rc.processingTime ? `${Math.floor(rc.processingTime / 1000)} seconds` : '-',
            rc.fileUrl || '-'
        )

        return rowData
    })

    // Verify all rows have the same number of columns as headers
    csvData.forEach((row, index) => {
        if (row.length !== headers.length) {
            console.warn(`Row ${index} has ${row.length} columns but headers has ${headers.length} columns`)
            // Pad the row if needed
            while (row.length < headers.length) {
                row.push('-')
            }
            // Trim the row if needed
            if (row.length > headers.length) {
                row.length = headers.length
            }
        }
    })

    // Combine headers and data
    const csvContent = [
        headers.join(','),
        ...csvData.map((row) =>
            row
                .map((cell) =>
                    // Escape commas and quotes in cell values
                    typeof cell === 'string' && (cell.includes(',') || cell.includes('"')) ? `"${cell.replace(/"/g, '""')}"` : cell
                )
                .join(',')
        ),
    ].join('\n')

    // Create a Blob with the CSV data
    return new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
}
