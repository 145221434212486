import { RingCentral } from 'pages/dashboard/pages/dispatcher/pages/spot-finder/components/Integrations'
import { DATAccount } from './DATAccount'
import { TruckStop } from './TruckStop'
import { Box, Divider, Typography, Stack } from '@mui/joy'
import { useAuth } from 'hooks/auth'

export const AccountIntegrations = () => {
    const { application } = useAuth()
    return (
        <>
            <Typography level={'h4'} color="primary" sx={{ mb: 1 }}>
                Account Integrations
            </Typography>
            <Typography level={'body-sm'} sx={{ mb: 2 }}>
                Connect your accounts to access load boards and streamline your freight operations. This integration allows you to search for loads and manage
                your accounts directly from the platform. Please enter your credentials to get started.
            </Typography>
            <Stack spacing={2}>
                <DATAccount />
                <TruckStop />
                {/* <UberAccount /> */}
            </Stack>
            <Divider />
            <Typography level={'h4'} color="primary" sx={{ mb: 1 }}>
                RingCentral Integrations
            </Typography>
            {application?.integrations?.ringcentral && !application?.integrations?.ringcentral?.blocked ? (
                <Typography level={'h3'} color="success" sx={{ mb: 2 }}>
                    RingCentral account successfully connected
                </Typography>
            ) : null}
            <Box width="100%">
                <RingCentral />
            </Box>
        </>
    )
}
