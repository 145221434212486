import { Route, Routes } from 'react-router-dom'
import DashboardRoutes from './dashboard'
import AuthRoutes from './auth'
import { Suspense } from 'react'
import MasterLayout from 'layouts/MasterLayout'
import { AuthLayout } from 'layouts'
import { CircularProgress } from '@mui/joy'
import { NylasCallback, TruckStopCallback, RingCentralCallback, SamsaraCallback } from './auth/pages/callbacks'
import OnboardingPage from './auth/pages/onboarding/Onboarding'

export default function AppRoutes() {
    return (
        <Suspense fallback={<CircularProgress />}>
            <Routes>
                <Route path="auth/nylas/callback" element={<NylasCallback />} />
                <Route path="auth/truck-stop/callback" element={<TruckStopCallback />} />
                <Route path="auth/ringcentral/callback" element={<RingCentralCallback />} />
                <Route path="auth/samsara/callback" element={<SamsaraCallback />} />
                <Route path="auth/onboarding" element={<OnboardingPage />} />
                <Route
                    path="auth/*"
                    element={
                        <AuthLayout>
                            <AuthRoutes />
                        </AuthLayout>
                    }
                />
                <Route
                    path="*"
                    element={
                        <MasterLayout>
                            <DashboardRoutes />
                        </MasterLayout>
                    }
                />
            </Routes>
        </Suspense>
    )
}
