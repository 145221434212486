import {
    ApplicationNylasConnectedEmail,
    DatIntegration,
    LucidEldIntegration,
    RingCentralIntegration,
    SamsaraIntegration,
    TruckStopIntegration,
    TteldIntegration,
    UberIntegration,
} from '../integration'
import { Equipment } from '../spot-finder'

// Application interfaces for nested objects
export interface IntegrationConfig {
    nylas?: {
        clientId?: string
        clientSecret?: string
        webhookSecret?: string
        enabled: boolean
        emails: ApplicationNylasConnectedEmail[]
    }
    voip?: {
        provider: 'twilio' | 'other'
        accountSid?: string
        authToken?: string
        enabled: boolean
    }
    dat?: DatIntegration
    tteld?: TteldIntegration
    lucidEld?: LucidEldIntegration
    truckStop?: TruckStopIntegration
    ringcentral?: RingCentralIntegration
    uber?: UberIntegration
    samsara?: SamsaraIntegration
}

interface Toll {
    tollName: string
    cost: string
    _id: string
}

interface Route {
    distance: string
    duration: string
    from: string
    to: string
}

export interface RateNegotiationSettings {
    firstBidThreshold: number
    secondBidThreshold: number
    minGap: number
}

export interface Company {
    name: string
    mcNumber: string
    details: string
    companyAddress: string
    phoneNumber: string
    rateNegotiation?: RateNegotiationSettings
}

interface ApplicationSettings {
    theme?: {
        primaryColor?: string
        logoUrl?: string
    }
    notifications?: {
        email: boolean
        slack: boolean
    }
    features?: {
        emailEnabled: boolean
        voipEnabled: boolean
    }
    company?: Company
    scheduler?: number
}

interface MessengerConnector {
    telegram?: {
        id: string
        botId: string
    }
}

export interface GoogleDriveDocument {
    _id: string
    id: string
    email: string
    name: string
}

interface KnowledgeBaseConnector {
    googleDrives?: {
        email: string
        refreshToken: string
        scope: string
        accessToken: string
        expiresAt: Date
    }[]
    googleDocs?: GoogleDriveDocument[]
}

interface DataPipelineConnectors {
    messageners: MessengerConnector
    knowledgeBase: KnowledgeBaseConnector
}

export enum ApplicationSettingsStatesListType {
    INCLUDED = 'included',
    EXCLUDED = 'excluded',
}

export interface ApplicationEmailSettings {
    states?: {
        included: string[]
        excluded: string[]
        activeList: ApplicationSettingsStatesListType
    }
    mountainArea?: boolean
    price?: {
        min: number
        max: number
    }
}

export interface ApplicationEmailFooterSettings {
    closing: string
}

export enum EmailTemplateType {
    INFO = 'info',
    BID = 'bid',
    BROKER = 'broker',
}

export interface ApplicationEmailTemplateSettings {
    type: EmailTemplateType
    body: string
}

export interface Truck {
    _id: string
    mainInfo: string
    truckId: string
    firstDriver?: {
        name?: string
        phone?: string
        isUSCitizen: boolean
        hometown?: string
        driverEmail?: string
    }
    secondDriver?: {
        name?: string
        phone?: string
        isUSCitizen: boolean
        hometown?: string
        driverEmail?: string
    }
    tteldVin?: string
    lucidEldNumber?: string
    lucidEldVin?: string
    samsaraId?: string
    maxTravelHoursPerDay?: string
    equipment: Equipment
    length?: number | string
    weight?: number | string
    deadHeadOrigin: string
    deadHeadDestination: string
    teamSolo?: 'TEAM' | 'SOLO' | ''
    weeklyGrossTarget?: number | string
    maxTravelDistance?: number | string
    minTravelDistance?: number | string
    fullPartial?: 'BOTH' | 'FULL' | 'PARTIAL'
    excludedStates?: string[]
    avoidWinterRoads?: boolean // Avoid winter roads
    isRestricted?: {
        alcohol: boolean
        baledPaper: boolean
        emptyBottles: boolean
        aluminiumCan: boolean
        scrap: boolean
    }
    isPermitted?: {
        hazmat: boolean // Hazmat (H) Endorsement
        tanker: boolean // Tanker (N) Endorsement
        doubleTripleTrailers: boolean // Double/Triple Trailers (T) Endorsement
        combinationEndorsements: boolean // Combination Endorsements
        canadaOperatingAuthority: boolean // Canada Operating Authority
        mexicoOperatingAuthority: boolean // Mexico Operating Authority
        oversizeOverweight: boolean // Oversize/Overweight Permits
        hazardousWasteRadiological: boolean // Hazardous Waste and Radiological Permits
    }
    security?: {
        tsa: boolean // TSA Clearance
        twic: boolean // TWIC (Transportation Worker Identification Credential)
        heavyDutyLock: boolean // Alcohol Delivery Permit
        escortDrivingOK: boolean // Driver is OK with escort driving
        crossBorderLoads: boolean // Cross Border Loads
    }
    createdAt?: Date
    updatedAt?: Date
}

export interface ApplicationSpotFinder {
    trucks: Truck[]
    searchAutoStopTimeMinutes?: number
}

interface TempData {
    emailSettings?: {
        isSetParameters?: boolean
        states?: {
            included: string[]
            excluded: string[]
            activeList: ApplicationSettingsStatesListType
        }
        mountainArea?: boolean
        pricePerGallon?: number
        driverCost?: number
        tollData?: {
            tolls?: Toll[]
            route?: Route[]
        }
        elevationPrice?: number
        ratePerMile?: number
    }
    emailFooter?: ApplicationEmailFooterSettings
    emailTemplate?: ApplicationEmailTemplateSettings[]
    spotFinder?: ApplicationSpotFinder
}

// Main Application interface
export interface Application {
    projectName: string
    description?: string
    status: 'active' | 'inactive' | 'suspended'
    settings: ApplicationSettings
    integrations: IntegrationConfig
    connectors: DataPipelineConnectors
    temp: TempData
}
