import { Stack, Tab, TabList, Tabs } from '@mui/joy'
import Box from '@mui/joy/Box'
import { useAuth } from 'hooks/auth'
import { useEffect, useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { EmailsSelector } from './components/EmailsSelector'
import EmailTab from './components/EmailTab'
import { TabPanel } from './components/TabPanel'

export default function DispatcherEmailsPage() {
    const [activeTab, setActiveTab] = useState('lane')
    const [searchParams, setSearchParams] = useSearchParams()

    const { application } = useAuth()

    const handleTabChange = (_event: React.SyntheticEvent | null, newValue: string | number | null) => {
        if (typeof newValue === 'string') {
            setActiveTab(newValue)
            setSearchParams({ type: newValue })
        }
    }

    useEffect(() => {
        const type = searchParams.get('type')
        if (type) {
            setActiveTab(type)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!application?.integrations?.nylas?.emails?.length) {
        return <Navigate to="/dispatcher" />
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1, px: 1 }}>
            <Stack direction="row" sx={{ borderBottom: 1, borderColor: 'divider', pr: 2 }} justifyContent="space-between">
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="email categories" sx={{ bgcolor: 'transparent', alignSelf: 'end' }}>
                    <TabList sx={{ boxShadow: 'none' }}>
                        <Tab value="lane" aria-controls={`email-tabpanel-0`}>
                            Lane
                        </Tab>
                        <Tab value="spot" aria-controls={`email-tabpanel-1`}>
                            Spot
                        </Tab>
                        <Tab value="lane-spot" aria-controls={`email-tabpanel-2`}>
                            Lane & Spot
                        </Tab>
                        <Tab value="unclassified" aria-controls={`email-tabpanel-2`}>
                            Unclassified
                        </Tab>
                        <Tab value="all" aria-controls={`email-tabpanel-0`}>
                            All
                        </Tab>
                    </TabList>
                </Tabs>
                <Stack direction="row">
                    <EmailsSelector />
                </Stack>
            </Stack>

            <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                <TabPanel value={activeTab} index="all">
                    <EmailTab type="all" />
                </TabPanel>
                <TabPanel value={activeTab} index="lane">
                    <EmailTab type="lane" />
                </TabPanel>
                <TabPanel value={activeTab} index="spot">
                    <EmailTab type="spot" />
                </TabPanel>
                <TabPanel value={activeTab} index="lane-spot">
                    <EmailTab type="lane-spot" />
                </TabPanel>
                <TabPanel value={activeTab} index="unclassified">
                    <EmailTab type="unclassified" />
                </TabPanel>
            </Box>
        </Box>
    )
}
