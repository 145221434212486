import React, { useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/joy'

export interface RingCentralCallSession {
    callId: string
    partyId: string
    sessionId: string
    localNumber: string
    remoteNumber: string
    state: string
    sendDtmf: (digit: string) => void
}

export interface DTMFKeypadProps {
    callSession: RingCentralCallSession
}

const DTMFKeypad: React.FC<DTMFKeypadProps> = ({ callSession }) => {
    // Just track the pressed digits for display purposes
    const [dtmfHistory, setDtmfHistory] = useState<string>('')

    // Log call state when component renders
    React.useEffect(() => {
        if (callSession) {
            console.log('DTMFKeypad rendered with call state:', callSession.state)
        }
    }, [callSession])

    const handleDtmfButtonClick = (digit: string) => {
        console.log('Button clicked:', digit)

        // Only process if call is in answered state
        if (callSession.state !== 'answered') {
            console.log('Cannot send DTMF - call not in answered state')
            return
        }

        // Update display history
        setDtmfHistory((prevHistory) => prevHistory + digit)

        // Send DTMF tone
        try {
            console.log('Sending DTMF tone:', digit)
            callSession.sendDtmf(digit)
            console.log('DTMF tone sent successfully')
        } catch (error) {
            console.error('Error sending DTMF tone:', error)
        }
    }

    // No clear history function needed

    // Define the keypad layout
    const keypadDigits = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#']

    return (
        <Box>
            {/* DTMF History Display */}
            <Box
                sx={{
                    p: 1,
                    mb: 2,
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 'sm',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography
                    level="body-lg"
                    sx={{
                        fontFamily: 'monospace',
                        minHeight: '24px', // Ensure consistent height
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100px', // Prevent overflow
                    }}
                >
                    {dtmfHistory || 'No digits pressed'}
                </Typography>
            </Box>

            {/* DTMF Keypad Grid */}
            <Grid container spacing={1}>
                {keypadDigits.map((digit) => (
                    <Grid key={digit} xs={4}>
                        <Button
                            fullWidth
                            variant="soft"
                            onClick={() => handleDtmfButtonClick(digit)}
                            disabled={callSession.state !== 'answered'}
                            sx={{
                                height: '50px',
                                fontSize: '1.5rem',
                                opacity: callSession.state !== 'answered' ? 0.6 : 1,
                                '&:active': {
                                    transform: callSession.state === 'answered' ? 'scale(0.98)' : 'none',
                                    backgroundColor: callSession.state === 'answered' ? 'primary.softHoverBg' : undefined,
                                },
                            }}
                        >
                            {digit}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default DTMFKeypad
