import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Box, Typography } from '@mui/joy'
import Autocomplete from '@mui/joy/Autocomplete'
import CircularProgress from '@mui/joy/CircularProgress'
import { SearchParams } from '@numeo/types'
import React, { useEffect, useState } from 'react'
import { CityLocationData, locationDataSearchV2 } from 'utils/location-search/LocationDataSearchV2'

interface LocationSearchProps {
    onLocationSelect?: (location: CityLocationData) => void
    placeholder?: string
    defaultLocation?: SearchParams['origin']
    limit?: number
    isDisabled?: boolean
    onRemove?: () => void
}

export const LocationSearch: React.FC<LocationSearchProps> = ({
    onLocationSelect,
    defaultLocation = {} as SearchParams['origin'],
    placeholder = 'Search for a city',
    isDisabled = false,
    onRemove,
}) => {
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState<CityLocationData[]>([])
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState<CityLocationData | null>(null)

    useEffect(() => {
        if (defaultLocation.city && defaultLocation.stateProv) {
            setValue({ city: defaultLocation.city, stateCode: defaultLocation.stateProv } as CityLocationData)
        } else {
            setValue(null)
        }
    }, [defaultLocation])

    const handleSearch = async (query: string) => {
        if (defaultLocation.city && defaultLocation.stateProv && query === `${defaultLocation.city}, ${defaultLocation.stateProv}`) {
            return
        }

        setLoading(true)
        try {
            if (query.length > 2) {
                const results = await locationDataSearchV2.search(query)
                setOptions(results || [])
            }
        } catch (error) {
            console.error('Search failed:', error)
            setOptions([])
        } finally {
            setLoading(false)
        }
    }

    return (
        <Autocomplete
            name="origin"
            value={value}
            onChange={(event, newValue, reason) => {
                setValue(newValue)
                if (newValue) {
                    if (onLocationSelect) {
                        onLocationSelect(newValue)
                    }
                } else {
                    if (onRemove && (reason === 'clear' || reason === 'removeOption')) {
                        onRemove()
                    }
                }
            }}
            disabled={isDisabled}
            sx={{
                flex: 1,
                minWidth: 0,
                maxWidth: '100%',
                '& .MuiAutocomplete-root': {
                    width: '100%',
                },
                '& .MuiAutocomplete-listbox': {
                    maxWidth: '100%',
                },
                '& .MuiAutocomplete-input': {
                    width: '100%',
                },
            }}
            placeholder={placeholder}
            open={open}
            onOpen={() => {
                setOpen(true)
                handleSearch('') // Load all options when opening
            }}
            onClose={() => setOpen(false)}
            onInputChange={(event, value) => {
                handleSearch(value)
            }}
            loading={loading}
            options={options}
            isOptionEqualToValue={(option, value) => option.city === value.city && option.stateCode === value.stateCode}
            getOptionLabel={(option) => `${option.city}, ${option.stateCode}`}
            renderOption={(props, option) => (
                <li {...props} style={{ cursor: 'pointer' }} key={option.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <LocationOnIcon color="primary" />
                        <Typography>
                            {option.city}, {option.stateCode}
                        </Typography>
                    </Box>
                </li>
            )}
            endDecorator={loading ? <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} /> : null}
        />
    )
}
