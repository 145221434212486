import { PhoneEnabled } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/joy'
import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { addNotification, deleteNotification } from '../../../../../../../../store/notificationSlice'
import { upsertRingCentralCallSession, removeRingCentralCallSession, toggleRingCentralSidebar } from '../../../../../../../../store/callCenterSlice'
import { useAuth } from 'hooks/auth'
import { ringCentralApiService } from '../Integrations/ring-central/RingCentral.api'
import WebPhone from 'ringcentral-web-phone'
import { Category } from 'hooks/useNotification'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { AnimatedRingVolume, CallButtonProps } from './shared/CallButtonUtils'

interface RCCallButtonProps extends CallButtonProps {
    onLoadingChange?: (loading: boolean) => void
}

export const RCCallButton: React.FC<RCCallButtonProps> = ({ load, loadSearchId, phoneNumber, favorite, disabled = false, onLoadingChange }) => {
    // States for RingCentral call
    const { application } = useAuth()
    const isRingCentralBlocked = !application?.integrations.ringcentral || application?.integrations.ringcentral.blocked
    const [rcCallStarted, setRcCallStarted] = useState(false)
    const [rcCalling, setRcCalling] = useState(false)
    const dispatch = useDispatch()

    // Get active RingCentral call session from Redux store
    const activeCall = useSelector((state: RootState) => state.callCenter.activeRingCentralCall)
    const callSession = activeCall?.session || null
    const isActiveLoad = activeCall?.loadId === load._id
    const hasActiveCallForDifferentLoad = activeCall && !isActiveLoad && callSession && callSession.state !== 'disposed'

    // RingCentral Call handlers
    const notificationId = 'ring-central-call'

    const handleRCCallClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        if (rcCallStarted && isActiveLoad) {
            // If call is already in progress for this load, just toggle the sidebar open
            dispatch(toggleRingCentralSidebar(true))
        } else {
            setRcCalling(true)
            onLoadingChange?.(true)

            try {
                const data = await ringCentralApiService.createSIPCredentials()
                favorite()
                const webPhone = new WebPhone({
                    sipInfo: data,
                })

                await webPhone.start()

                const newCallSession = await webPhone.call(phoneNumber)

                await ringCentralApiService.saveCallInfo({
                    loadSearchId: loadSearchId,
                    loadId: load._id,
                    callId: newCallSession.callId,
                    startedAt: Date.now(),
                    partyId: newCallSession.partyId,
                    sessionId: newCallSession.sessionId,
                    from: newCallSession.localNumber,
                    to: newCallSession.remoteNumber,
                })

                // Create call object
                const call = {
                    loadSearchId: loadSearchId,
                    loadId: load._id,
                    callId: newCallSession.callId,
                    startedAt: Date.now(),
                    partyId: newCallSession.partyId,
                    session: newCallSession,
                    from: newCallSession.localNumber,
                    to: newCallSession.remoteNumber,
                }

                // Store call in Redux using the new action
                dispatch(
                    upsertRingCentralCallSession({
                        call,
                        load,
                    })
                )

                setRcCallStarted(true)
                setRcCalling(false)
                onLoadingChange?.(false)

                // Add notification for the call
                dispatch(
                    addNotification({
                        id: notificationId,
                        message: 'Call in progress... ',
                        category: Category.RingCentral,
                        timestamp: Date.now(),
                        type: 'info',
                        topic: 'permanent',
                        onClick: () => {
                            // When notification is clicked, just open the sidebar without creating a new call session
                            dispatch(toggleRingCentralSidebar(true))
                        },
                    })
                )
            } catch (error) {
                console.error('Error making call Ring Central:', error)
                setRcCalling(false)
                onLoadingChange?.(false)
            }
        }
    }

    // Update RC call state
    useEffect(() => {
        // Set call started state based on active call
        if (isActiveLoad && callSession && callSession.state !== 'disposed') {
            setRcCallStarted(true)
        } else if (!isActiveLoad || (callSession && callSession.state === 'disposed')) {
            setRcCallStarted(false)
        }
    }, [isActiveLoad, callSession])

    // Handle RC call disposal
    useEffect(() => {
        if (callSession?.state === 'disposed' && isActiveLoad) {
            // When call is disposed, remove notification and update state
            dispatch(deleteNotification(notificationId))
            dispatch(removeRingCentralCallSession())
            setRcCallStarted(false)
        }
    }, [callSession?.state, dispatch, notificationId, isActiveLoad])

    // Simple button appearance logic
    let buttonIcon: JSX.Element
    let tooltipTitle: string
    let isButtonDisabled = false

    // Loading state
    if (rcCalling) {
        buttonIcon = <CircularProgress size={20} />
        tooltipTitle = 'Processing...'
        isButtonDisabled = true
    } else if (rcCallStarted && callSession && callSession.state !== 'disposed') {
        // Active RC call
        buttonIcon = <AnimatedRingVolume color="primary" />
        tooltipTitle = 'Call in progress. Click to view details.'
    } else {
        // No RC call yet
        buttonIcon = (
            <PhoneEnabled
                sx={{
                    color: isRingCentralBlocked || hasActiveCallForDifferentLoad || disabled ? 'grey.500' : 'primary.main',
                    opacity: isRingCentralBlocked || hasActiveCallForDifferentLoad || disabled ? 0.8 : 1,
                }}
            />
        )
        tooltipTitle = disabled
            ? 'Calling not available'
            : isRingCentralBlocked
              ? 'RingCentral not connected'
              : hasActiveCallForDifferentLoad
                ? 'Another call in progress'
                : 'Call with RingCentral'
        isButtonDisabled = isRingCentralBlocked || hasActiveCallForDifferentLoad || disabled
    }

    return (
        <Box onClick={(event) => event.stopPropagation()}>
            <Tooltip title={tooltipTitle} variant="solid">
                <IconButton
                    className="main-call-button"
                    component="button"
                    onClick={handleRCCallClick}
                    disabled={isButtonDisabled}
                    sx={{
                        pointerEvents: 'auto !important',
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    {buttonIcon}
                </IconButton>
            </Tooltip>
        </Box>
    )
}
