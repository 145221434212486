// All - class
export const allTypes = [
    {
        name: 'V - Vans, Standard',
        code: 'V',
    },
    {
        name: 'F - Flatbeds',
        code: 'F',
    },
    {
        name: 'R - Reefers',
        code: 'R',
    },
    {
        name: 'N - Conestoga',
        code: 'N',
    },
    {
        name: 'C - Containers',
        code: 'C',
    },
    {
        name: 'K - Decks, Specialized',
        code: 'K',
    },
    {
        name: 'D - Decks, Standard',
        code: 'D',
    },
    {
        name: 'B - Dry Bulk',
        code: 'B',
    },
    {
        name: 'Z - Hazardous Materials',
        code: 'Z',
    },
    {
        name: 'O - Other Equipment',
        code: 'O',
    },
    {
        name: 'T - Tankers',
        code: 'T',
    },
    {
        name: 'S - Vans, Specialized',
        code: 'S',
    },
]

// Only - types
export const subTypes = [
    {
        name: 'AC - Auto Carrier',
        code: 'AC',
    },
    {
        name: 'BT - B-Train',
        code: 'BT',
    },
    {
        name: 'CN - Conestoga',
        code: 'CN',
    },
    {
        name: 'CI - Container Insulated',
        code: 'CI',
    },
    {
        name: 'CR - Container Refrigerated',
        code: 'CR',
    },
    {
        name: 'CV - Conveyor',
        code: 'CV',
    },
    {
        name: 'DD - Double Drop',
        code: 'DD',
    },
    {
        name: 'LA - Drop Deck Landoll',
        code: 'LA',
    },
    {
        name: 'DT - Dump Trailer',
        code: 'DT',
    },
    {
        name: 'FA - Flatbed Air-Ride',
        code: 'FA',
    },
    {
        name: 'FN - Flatbed Conestoga',
        code: 'FN',
    },
    {
        name: 'F2 - Flatbed Double',
        code: 'F2',
    },
    {
        name: 'FZ - Flatbed HazMat',
        code: 'FZ',
    },
    {
        name: 'FH - Flatbed Hotshot',
        code: 'FH',
    },
    {
        name: 'MX - Flatbed Maxi',
        code: 'MX',
    },
    {
        name: 'FD - Flatbed or Step Deck',
        code: 'FD',
    },
    {
        name: 'FS - Flatbed w/Sides',
        code: 'FS',
    },
    {
        name: 'FT - Flatbed w/Tarps',
        code: 'FT',
    },
    {
        name: 'FM - Flatbed w/Team',
        code: 'FM',
    },
    {
        name: 'FO - Flatbed, Over Dimension',
        code: 'FO',
    },
    {
        name: 'FC - Flatbed, w/Chains',
        code: 'FC',
    },
    {
        name: 'FR - Flatbed/Van/Reefer',
        code: 'FR',
    },
    {
        name: 'HB - Hopper Bottom',
        code: 'HB',
    },
    {
        name: 'IR - Insulated Van or Reefer',
        code: 'IR',
    },
    {
        name: 'LB - Lowboy',
        code: 'LB',
    },
    {
        name: 'LR - Lowboy or RGN',
        code: 'LR',
    },
    {
        name: 'LO - Lowboy, Over Dimension',
        code: 'LO',
    },
    {
        name: 'MV - Moving Van',
        code: 'MV',
    },
    {
        name: 'NU - Pneumatic',
        code: 'NU',
    },
    {
        name: 'PO - Power Only',
        code: 'PO',
    },
    {
        name: 'RA - Reefer Air-Ride',
        code: 'RA',
    },
    {
        name: 'R2 - Reefer Double',
        code: 'R2',
    },
    {
        name: 'RZ - Reefer HazMat',
        code: 'RZ',
    },
    {
        name: 'RN - Reefer Intermodal',
        code: 'RN',
    },
    {
        name: 'RL - Reefer Logistics',
        code: 'RL',
    },
    {
        name: 'RV - Reefer or Vented Van',
        code: 'RV',
    },
    {
        name: 'RM - Reefer w/Team',
        code: 'RM',
    },
    {
        name: 'RP - Reefer, w/Pallet Exchange',
        code: 'RP',
    },
    {
        name: 'RG - Removable Gooseneck',
        code: 'RG',
    },
    {
        name: 'SD - Step Deck',
        code: 'SD',
    },
    {
        name: 'SR - Step Deck or RGN',
        code: 'SR',
    },
    {
        name: 'SN - Stepdeck Conestoga',
        code: 'SN',
    },
    {
        name: 'SB - Straight Box Truck',
        code: 'SB',
    },
    {
        name: 'ST - Stretch Trailer',
        code: 'ST',
    },
    {
        name: 'TA - Tanker Aluminum',
        code: 'TA',
    },
    {
        name: 'TN - Tanker Intermodal',
        code: 'TN',
    },
    {
        name: 'TS - Tanker Steel',
        code: 'TS',
    },
    {
        name: 'TT - Truck and Trailer',
        code: 'TT',
    },
    {
        name: 'VA - Van Air-Ride',
        code: 'VA',
    },
    {
        name: 'VS - Van Conestoga',
        code: 'VS',
    },
    {
        name: 'V2 - Van Double',
        code: 'V2',
    },
    {
        name: 'VZ - Van HazMat',
        code: 'VZ',
    },
    {
        name: 'VH - Van Hotshot',
        code: 'VH',
    },
    {
        name: 'VI - Van Insulated',
        code: 'VI',
    },
    {
        name: 'VN - Van Intermodal',
        code: 'VN',
    },
    {
        name: 'VG - Van Lift-Gate',
        code: 'VG',
    },
    {
        name: 'VL - Van Logistics',
        code: 'VL',
    },
    {
        name: 'OT - Van Open-Top',
        code: 'OT',
    },
    {
        name: 'VT - Van or Flatbed w/Tarps',
        code: 'VT',
    },
    {
        name: 'VF - Van or Flatbed',
        code: 'VF',
    },
    {
        name: 'VR - Van or Reefer',
        code: 'VR',
    },
    {
        name: 'VB - Van Roller Bed',
        code: 'VB',
    },
    {
        name: 'V3 - Van Triple',
        code: 'V3',
    },
    {
        name: 'VV - Van Vented',
        code: 'VV',
    },
    {
        name: 'VC - Van w/Curtains',
        code: 'VC',
    },
    {
        name: 'VM - Van w/Team',
        code: 'VM',
    },
    {
        name: 'VW - Van, w/Blanket Wrap',
        code: 'VW',
    },
    {
        name: 'VP - Van, w/Pallet Exchange',
        code: 'VP',
    },
]
