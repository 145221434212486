import { Box, CircularProgress, Typography, Stack, Chip } from '@mui/joy'
import { useState, useEffect } from 'react'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import SpeedIcon from '@mui/icons-material/Speed'
import StraightenIcon from '@mui/icons-material/Straighten'
import { LucidEldVehicleStatus, LucidEldStatusCode } from '@numeo/types'
import { eldApi } from '../../api/eld-api'
import { formatTimestamp } from '../assets/helpers'
import { ELDMap } from './ELDMap'

interface LucidEldSectionProps {
    eldNumber: string
    isOpen: boolean
}

export const LucidEldSection = ({ eldNumber, isOpen }: LucidEldSectionProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [vehicleStatus, setVehicleStatus] = useState<LucidEldVehicleStatus | null>(null)

    useEffect(() => {
        if (isOpen && !vehicleStatus) {
            fetchVehicleStatus()
        }
        // eslint-disable-next-line
    }, [isOpen])

    const fetchVehicleStatus = async (): Promise<void> => {
        try {
            setIsLoading(true)
            const status = await eldApi.getLucidEldTruckStatus(eldNumber)
            setVehicleStatus(status)
        } catch (error) {
            console.error(error)
            setVehicleStatus(null)
        } finally {
            setIsLoading(false)
        }
    }

    const hasLocation = (status: LucidEldVehicleStatus) => {
        return status.lat && status.lon && status.lat.trim() !== '' && status.lon.trim() !== ''
    }

    return (
        <Box width="300px">
            {isLoading ? (
                <Box display="flex" justifyContent="center" p={2}>
                    <CircularProgress size="sm" />
                </Box>
            ) : vehicleStatus ? (
                <>
                    <Typography level="title-sm">Lucid ELD</Typography>
                    <Stack spacing={1}>
                        {hasLocation(vehicleStatus) && <ELDMap latitude={String(vehicleStatus.lat)} longitude={String(vehicleStatus.lon)} />}
                        <Stack spacing={1.5} sx={{ px: 1, mt: 2 }}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <LocalShippingIcon sx={{ fontSize: 20 }} />
                                <Typography level="body-sm">Vehicle #{vehicleStatus.number}</Typography>
                                <Chip size="sm" variant="soft" color={vehicleStatus.code === LucidEldStatusCode.ACTIVE ? 'success' : 'warning'}>
                                    {vehicleStatus.code}
                                </Chip>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <SpeedIcon sx={{ fontSize: 20, color: 'primary.500' }} />
                                <Typography level="body-sm">{vehicleStatus.speed || 0} mph</Typography>
                            </Stack>
                            {vehicleStatus.odometer && (
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <StraightenIcon sx={{ fontSize: 20, color: 'primary.500' }} />
                                    <Typography level="body-sm">{vehicleStatus.odometer} miles</Typography>
                                </Stack>
                            )}
                            {vehicleStatus.timestamp && (
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <AccessTimeIcon sx={{ fontSize: 20, color: 'primary.500' }} />
                                    <Typography level="body-sm">{formatTimestamp(vehicleStatus.timestamp)}</Typography>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </>
            ) : null}
        </Box>
    )
}
