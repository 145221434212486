import { AutoAwesome, Send } from '@mui/icons-material'
import { Card, Typography, Box, Input, Button, Stack, Chip } from '@mui/joy'
import { RichTextEditor } from 'components/common'
import { useAuth } from 'hooks/auth'
import React, { useState } from 'react'
import { Category } from 'hooks/useNotification'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'

interface SubjectProps {
    value: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

interface TextEditorProps {
    value: string
    onChange: (newContent: string) => void
}

export interface EmailEditorProps {
    title?: string
    subject: SubjectProps
    textEditor: TextEditorProps
    onSendClick: () => Promise<void>
    onError?: (error: Error) => void
    hasDraftAi?: boolean
}

export const EmailEditor: React.FC<EmailEditorProps> = ({ title = 'Send Reply', subject, textEditor, onSendClick, onError, hasDraftAi = false }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState<boolean>(false)
    const { application } = useAuth()

    const isEmailConnected = (application?.integrations.nylas?.emails?.length || 0) > 0

    const handleSendClick = async (): Promise<void> => {
        try {
            setLoading(true)
            await onSendClick()
            // Check if email is expired before attempting to send
            const isEmailExpired = application?.integrations?.nylas?.emails?.some((email) => email.isExpired)

            if (isEmailExpired) {
                throw new Error('Failed to send email')
            }
            dispatch(
                addNotification({
                    id: '',
                    message: 'Email sent successfully',
                    type: 'info',
                    category: Category.Email,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Failed to send email'
            dispatch(
                addNotification({
                    id: '',
                    message: 'Failed to send email',
                    type: 'error',
                    category: Category.Error,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
            onError?.(error instanceof Error ? error : new Error(errorMessage))
        } finally {
            setLoading(false)
        }
    }

    const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        subject.onChange?.(e)
    }

    return (
        <>
            <Card variant="outlined" sx={{ p: 2, mt: 1 }}>
                <Typography level="title-lg" startDecorator={<Send />} sx={{ mb: 1 }}>
                    {title}
                </Typography>

                <Box sx={{ mb: 1 }}>
                    <Typography level="title-sm" sx={{ mb: 1, color: 'neutral.500' }}>
                        Subject
                    </Typography>
                    <Input
                        value={subject.value}
                        onChange={handleSubjectChange}
                        variant="soft"
                        sx={{
                            mb: 1,
                            px: 2.5,
                            py: 1,
                            '--Input-minHeight': '45px',
                            '--Input-paddingInline': '1rem',
                            bgcolor: 'background.level1',
                        }}
                    />
                </Box>

                <Box>
                    <Stack direction={'row'} alignItems={'center'} spacing={1} mb={1}>
                        <Typography level="title-sm" sx={{ mb: 1, color: 'neutral.500' }}>
                            Message
                        </Typography>
                        {hasDraftAi && (
                            <Chip color="primary" size="sm" startDecorator={<AutoAwesome fontSize="small" />}>
                                Generated by AI
                            </Chip>
                        )}
                    </Stack>
                    <RichTextEditor value={textEditor.value} onChange={textEditor.onChange} minHeight="auto" showToolbar />
                </Box>

                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button loading={loading} disabled={!isEmailConnected || loading} onClick={handleSendClick} startDecorator={<Send />} size="lg">
                        {!isEmailConnected ? 'Email account not connected' : 'Send'}
                    </Button>
                </Box>
            </Card>
        </>
    )
}
