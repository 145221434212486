/* eslint-disable @typescript-eslint/no-explicit-any */
import { ClickAwayListener, useSnackbar } from '@mui/base'
import { useColorScheme } from '@mui/joy/styles'
import { Email, EmailProcessingResult } from '@numeo/types'
import { useAuth } from 'hooks/auth'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { EmailThreadClient } from '../../../../../api/EmailThreadClient'
import { EmailEditor, EmailEditorProps } from './EmailEditor'

interface Order {
    origin: {
        label: string
    }
    destination: {
        label: string
    }
    offeringRate?: number
    length: number
    ratePerMile?: number
}

interface OfferPreviewProps {
    execution: {
        email: Email
        processingResult: EmailProcessingResult
        threads: Email[]
    }
    refetch: () => Promise<void>
}

const generateTableHtml = (orders: Order[], mode: string, defaultRatePerMile = 0) => {
    // Define colors based on mode
    const colors = {
        headerBg: mode === 'dark' ? '#2D3748' : '#f2f2f2',
        headerText: mode === 'dark' ? '#9fa6ae' : '#000000',
        borderColor: mode === 'dark' ? '#4A5568' : '#ddd',
        rowBg: mode === 'dark' ? '#0b0d0e' : '#FFFFFF',
        rowText: mode === 'dark' ? '#9fa6ae' : '#000000',
    }

    return `
        <table style="width: 70%; border-collapse: collapse; font-family: Arial, sans-serif;">
            <thead style="text-align: left;">
                <tr style="background-color: ${colors.headerBg}; color: ${colors.headerText};">
                    <th style="border: 1px solid ${colors.borderColor}; padding: 8px;">Origin</th>
                    <th style="border: 1px solid ${colors.borderColor}; padding: 8px;">Destination</th>
                    <th style="border: 1px solid ${colors.borderColor}; padding: 8px;">Rate</th>
                </tr>
            </thead>
            <tbody>
                ${orders
                    ?.map(
                        (order) => `
                        <tr style="background-color: ${colors.rowBg}; color: ${colors.rowText};">
                            <td style="border: 1px solid ${colors.borderColor}; padding: 8px;">${order.origin.label || 'N/A'}</td>
                            <td style="border: 1px solid ${colors.borderColor}; padding: 8px;">${order.destination.label || 'N/A'}</td>
                            <td style="border: 1px solid ${colors.borderColor}; padding: 8px;">$${
                                (order.offeringRate && order.offeringRate > order.length * (order.ratePerMile || defaultRatePerMile)
                                    ? order.offeringRate
                                    : order.length * (order.ratePerMile || defaultRatePerMile)
                                ).toFixed(2) || 'N/A'
                            }</td>
                        </tr>
                    `
                    )
                    .join('')}
            </tbody>
        </table>
    `
}

const generateOfferEmail = (execution: any, mode = 'light', defaultRatePerMile = 0): string => {
    const orders = execution.processingResult?.orders || []
    const tableHtml = generateTableHtml(orders, mode, defaultRatePerMile)

    // Return the final email content
    return `
        <div style="font-family: Arial, sans-serif; line-height: 1.6;">
            <p>Hi!</p>
            <p>We can have truck${orders.length > 1 ? 's' : ''} for you!</p>
            ${tableHtml}
            <p>Please confirm to get this booked.</p>
            <p>Thanks!</p>
        </div>
    `.trim()
}

export default function OfferPreview({ execution, refetch }: OfferPreviewProps) {
    const { mode } = useColorScheme()
    const [editedSubject, setEditedSubject] = useState(`RE: ${execution.email.emailData.object.subject}`)
    const [editedBody, setEditedBody] = useState('')
    const [emailSignature, setEmailSignature] = useState('')
    const [open, setOpen] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [toastColor, setToastColor] = useState<'success' | 'danger'>('success')
    const [searchParams] = useSearchParams()

    const hasOrders = useMemo(() => execution?.processingResult?.orders?.length > 0, [execution?.processingResult?.orders?.length])

    const { application } = useAuth()

    const handleClose = () => {
        setOpen(false)
    }

    const { getRootProps, onClickAway } = useSnackbar({
        onClose: handleClose,
        open,
        autoHideDuration: 5000,
    })

    const showToast = (message: string, color: 'success' | 'danger') => {
        setToastMessage(message)
        setToastColor(color)
        setOpen(true)
    }

    const fetchFooter = async () => {
        try {
            // Safely access the emailFooter property with proper null checks
            const emailFooter = application?.temp?.emailFooter?.closing || ''
            setEmailSignature(emailFooter)
        } catch (error) {
            console.error('Error processing email footer:', error)
            // Set empty signature as fallback
            setEmailSignature('')
        }
    }

    useEffect(() => {
        fetchFooter()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setEditedSubject(`RE: ${execution.email.emailData.object.subject}`)
        const generatedEmail = generateOfferEmail(execution, mode, application?.temp.emailSettings?.ratePerMile)
        setEditedBody(generatedEmail)
    }, [execution, mode, application?.temp.emailSettings?.ratePerMile])

    const handleEditorChange = (newContent: string) => {
        // Remove the signature before updating the body
        const signatureIndex = newContent.indexOf(emailSignature)
        if (signatureIndex !== -1) {
            setEditedBody(newContent.substring(0, signatureIndex))
        } else {
            setEditedBody(newContent)
        }
    }

    const fullContent = useMemo(() => {
        if (hasOrders && !execution.threads.length && !execution.email.replyDraft) {
            return `${editedBody}${emailSignature}`
        }
        return emailSignature
    }, [editedBody, emailSignature, hasOrders, execution.threads.length, execution.email.replyDraft])

    const convertToLightMode = (content: string): string => {
        // Convert dark mode colors to light mode colors in tables
        return content
            .replace(/#2D3748/g, '#f2f2f2') // dark headerBg -> light headerBg
            .replace(/#9fa6ae(?=[^]*?<\/t[dh]>)/g, '#000000') // dark text -> light text (only within table cells)
            .replace(/#4A5568(?=[^]*?border)/g, '#ddd') // dark borderColor -> light borderColor
            .replace(/#0b0d0e/g, '#FFFFFF') // dark rowBg -> light rowBg
    }

    const sendOfferEmail = async () => {
        const emailId = searchParams.get('executionId')
        if (!emailId) {
            return showToast('Execution ID not found', 'danger')
        }

        try {
            // Convert any tables in the content to light mode while preserving other edits
            const lightModeContent = convertToLightMode(editedBody)

            const payload = {
                subject: editedSubject,
                body: lightModeContent + emailSignature,
                threadId: execution.email.emailData.object.thread_id,
                emailId,
                projectName: application?.projectName,
            }
            await EmailThreadClient.sendOfferEmail(payload)

            refetch()
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || error?.message || 'Failed to send to Email'
            showToast(errorMessage, 'danger')
        }
    }

    const emailEditorProps: EmailEditorProps = {
        title: hasOrders ? 'Generated Offer' : 'Reply Editor',
        subject: {
            value: editedSubject,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setEditedSubject(e.target.value),
        },
        textEditor: {
            value: fullContent,
            onChange: handleEditorChange,
        },
        onSendClick: sendOfferEmail,
    }

    return (
        <>
            <EmailEditor {...emailEditorProps} />

            {open && (
                <ClickAwayListener onClickAway={onClickAway}>
                    <div
                        {...getRootProps()}
                        style={{
                            position: 'fixed',
                            bottom: 16,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: toastColor === 'danger' ? '#d32f2f' : '#2e7d32',
                            color: 'white',
                            padding: '6px 16px',
                            borderRadius: 4,
                            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                            zIndex: 1400,
                        }}
                    >
                        {toastMessage}
                        <button
                            onClick={handleClose}
                            style={{
                                marginLeft: 8,
                                background: 'none',
                                border: 'none',
                                color: 'white',
                                cursor: 'pointer',
                                padding: '4px',
                            }}
                        >
                            ✕
                        </button>
                    </div>
                </ClickAwayListener>
            )}
        </>
    )
}
