export const BASE_STYLES = `
    body {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
        font-size: 14px;
        line-height: 1.5;
        color: #1C2025;
        margin: 0;
        padding: 0;
    }
    img {
        max-width: 100%;
        height: auto;
    }
    a {
        color: #0B6BCB;
        text-decoration: none;
    }
    blockquote {
        border-left: 3px solid #CDD7E1;
        margin: 0;
        padding-left: 1em;
        color: #4B5259;
    }
`

export const getThemeColors = (mode: string) => ({
    headerBg: mode === 'dark' ? '#36363f' : '#f2f2f2',
    headerText: mode === 'dark' ? '#FFFFFF' : '#000000',
    borderColor: mode === 'dark' ? '#9fa6ae' : '#ddd',
    rowBg: mode === 'dark' ? '#0b0d0e' : '#FFFFFF',
    rowText: mode === 'dark' ? '#9fa6ae' : '#000000',
})

export const HTML_TEMPLATE = (content: string, mode: string = 'light') => {
    const colors = getThemeColors(mode)
    return `
<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <style>
      :root {
        --theme-header-bg: ${colors.headerBg};
        --theme-header-text: ${colors.headerText};
        --theme-border-color: ${colors.borderColor};
        --theme-row-bg: ${colors.rowBg};
        --theme-row-text: ${colors.rowText};
      }

      body {
        margin: 0;
        padding: 16px;
        font-family: system-ui, -apple-system, sans-serif;
        background-color: ${mode === 'dark' ? '#0B0D0E' : '#ffffff'};
        color: ${mode === 'dark' ? '#9FA6AD' : '#000'};
      }

      /* Default text styling */
      p, h1, h2, h3, span {
        color: ${mode === 'dark' ? '#9FA6AD' : '#000'};
      }

      /* Links styling */
      a {
        color: ${mode === 'dark' ? 'var(--joy-palette-primary-400)' : 'var(--joy-palette-primary-500)'};
      }

      /* Table theme support */
      table {
        border-collapse: collapse;
        width: 100%;
      }

      /* MS Word table overrides */
      table[border="0"] td,
      table[border="0"] th {
        padding: 5.4pt;
        border: 1pt solid var(--theme-border-color) !important;
      }

      /* Handle cell backgrounds and text */
      td[style*="background"],
      th[style*="background"],
      td[style*="background-image"],
      th[style*="background-image"] {
        background: var(--theme-row-bg) !important;
        color: var(--theme-row-text) !important;
      }

      /* Force text color in cells */
      td span,
      th span,
      td p,
      th p {
        color: var(--theme-row-text) !important;
      }

      /* Handle header cells */
      tr:first-child td[style*="background"],
      tr:first-child th[style*="background"],
      tr:first-child td[style*="background-image"],
      tr:first-child th[style*="background-image"] {
        background: var(--theme-header-bg) !important;
        color: var(--theme-header-text) !important;
      }

      /* Force header text color */
      tr:first-child td span,
      tr:first-child th span,
      tr:first-child td p,
      tr:first-child th p {
        color: var(--theme-header-text) !important;
      }

      /* General text color override */
      [style*="color:black"],
      [style*="color: black"],
      [style*="color:rgb(0,0,0)"],
      [style*="color: rgb(0,0,0)"] {
        color: var(--theme-row-text) !important;
      }

      /* Preserve specific colored text */
      [style*="color:rgb(12,136,42)"],
      [style*="color: rgb(12,136,42)"],
      [style*="color:rgb(237,92,87)"],
      [style*="color: rgb(237,92,87)"],
      [style*="color:rgb(254,206,6)"],
      [style*="color: rgb(254,206,6)"] {
        color: inherit !important;
      }

      /* Handle MS Word specific styles */
      .MsoNormal {
        margin: 0 !important;
      }

      .MsoNormal[style*="background"] {
        background: none !important;
      }

      .MsoNormal span {
        color: var(--theme-row-text) !important;
      }

      /* Preserve specific colored spans in MsoNormal */
      .MsoNormal span[style*="color:rgb(12,136,42)"],
      .MsoNormal span[style*="color: rgb(12,136,42)"],
      .MsoNormal span[style*="color:rgb(237,92,87)"],
      .MsoNormal span[style*="color: rgb(237,92,87)"],
      .MsoNormal span[style*="color:rgb(254,206,6)"],
      .MsoNormal span[style*="color: rgb(254,206,6)"] {
        color: inherit !important;
      }

      /* Override Word-specific styles */
      [style*="windowtext"] {
        border-color: var(--theme-border-color) !important;
      }

      /* Preserve text alignment */
      [align="center"] {
        text-align: center !important;
      }

      /* Code blocks */
      pre, code {
        background-color: ${mode === 'dark' ? '#1A1D1E' : '#F3F6F9'};
        border-radius: 6px;
        padding: 0.2em 0.4em;
        font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
      }

      pre code {
        background-color: transparent;
        padding: 0;
      }

      blockquote {
        border-left: 3px solid ${mode === 'dark' ? '#2F3639' : '#CDD7E1'};
        margin: 0;
        padding-left: 1em;
        color: ${mode === 'dark' ? '#9FA6AD' : '#4B5259'};
      }
    </style>
  </head>
  <body>${content}</body>
</html>
`
}

export const SANITIZE_OPTIONS = {
    FORBID_TAGS: ['script', 'object', 'embed'],
    ADD_ATTR: ['target', 'style', 'background-color', 'color'],
    ADD_TAGS: ['iframe'],
    ALLOW_DATA_ATTR: true,
}
