import { Card, Box, Button, Chip, FormControl, FormHelperText, Input, Typography } from '@mui/joy'
import { Send } from '@mui/icons-material'
import Autocomplete from '@mui/joy/Autocomplete'
import { IBroker } from '@numeo/types'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addNotification } from '../../../../../../../store/notificationSlice'
import { Category } from '../../../../../../../hooks/useNotification'
import { brokersApi } from '../api'
import { RichTextEditor } from 'components/common'

interface NotifyFormProps {
    brokers: IBroker[]
}

interface EmailOption {
    email: string
    label: string
    brokerId?: string
    isValid: boolean
}

interface ValidatedEmail {
    email: string
    isValid: boolean
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

const parseAndValidateEmails = (emailsString: string): ValidatedEmail[] => {
    return emailsString
        .split(/[,\s]+/)
        .filter(Boolean)
        .map((email) => ({
            email: email.trim(),
            isValid: emailRegex.test(email.trim()),
        }))
}

const validateEmail = (email: string): boolean => {
    return emailRegex.test(email.trim())
}

export const NotifyForm: React.FC<NotifyFormProps> = ({ brokers }) => {
    const dispatch = useDispatch()
    const [selectedEmails, setSelectedEmails] = useState<EmailOption[]>([])
    const [subject, setSubject] = useState<string>('Available free trucks notification')
    const [message, setMessage] = useState<string>('<p>Hello, I would like to inform you that I have available trucks.</p>')
    const [isSubmitting, setIsSubmitting] = useState(false)

    // Create email options from brokers
    const brokerEmailOptions = useCallback(() => {
        return brokers
            .filter((broker): broker is IBroker & { email: string } => broker.email !== undefined && broker.email.trim() !== '')
            .map((broker) => ({
                email: broker.email,
                label: broker.companyName ? `${broker.email} (${broker.companyName})` : broker.email,
                brokerId: broker._id,
                isValid: validateEmail(broker.email),
            }))
    }, [brokers])

    useEffect(() => {
        // Auto-populate emails from selected brokers that are favorites
        const favoriteEmails = brokers
            .filter((broker): broker is IBroker & { email: string } => broker.email !== undefined && broker.email.trim() !== '' && broker.type === 'favorite')
            .map((broker) => ({
                email: broker.email,
                label: broker.companyName ? `${broker.email} (${broker.companyName})` : broker.email,
                brokerId: broker._id,
                isValid: validateEmail(broker.email),
            }))

        if (favoriteEmails.length > 0) {
            setSelectedEmails(favoriteEmails)
        }
    }, [brokers])

    const hasInvalidEmails = selectedEmails.some((email) => !email.isValid)
    const emailErrorMessage = hasInvalidEmails ? 'One or more email addresses are invalid' : ''

    const handleSubmit = useCallback(
        async (e: React.FormEvent) => {
            e.preventDefault()
            if (selectedEmails.every((e) => e.isValid)) {
                try {
                    setIsSubmitting(true)
                    console.log('MESSAGE', message)
                    await brokersApi.notifyBrokers({
                        emails: selectedEmails.map((e) => e.email),
                        subject,
                        message,
                    })

                    dispatch(
                        addNotification({
                            id: `broker-notify-${Date.now()}`,
                            message: 'Notification sent successfully',
                            category: Category.Broker,
                            type: 'info',
                            topic: 'broker-notification',
                            timestamp: Date.now(),
                        })
                    )
                } catch (error) {
                    console.error('Failed to send notification:', error)
                    dispatch(
                        addNotification({
                            id: `broker-notify-error-${Date.now()}`,
                            message: 'Failed to send notification',
                            category: Category.Error,
                            type: 'error',
                            topic: 'broker-notification',
                            timestamp: Date.now(),
                        })
                    )
                } finally {
                    setIsSubmitting(false)
                }
            }
        },
        [selectedEmails, subject, message, dispatch]
    )

    return (
        <Card
            component="form"
            onSubmit={handleSubmit}
            variant="outlined"
            sx={{
                p: { xs: 1.5, sm: 2 },
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                flex: 1,
            }}
        >
            <Typography level="h4" startDecorator={<Send />}>
                Send Notification
            </Typography>
            <Typography level="body-md" sx={{ mb: 4, color: 'text.secondary' }}>
                Send notifications to your favorite brokers. You can select multiple brokers and send a notification to all of them at once.
            </Typography>
            <FormControl error={hasInvalidEmails}>
                <Typography level="title-sm" sx={{ mb: 1, color: 'neutral.500' }}>
                    Broker Emails
                </Typography>
                <Autocomplete
                    multiple
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={brokerEmailOptions()}
                    value={selectedEmails}
                    getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                            return option
                        }
                        return option.label || option.email
                    }}
                    isOptionEqualToValue={(option, value) => option.email === value.email}
                    onChange={(_event, newValue) => {
                        const processedValues: EmailOption[] = []

                        const stringValues = newValue.filter((value) => typeof value === 'string').join(', ')
                        if (stringValues) {
                            const validatedEmails = parseAndValidateEmails(stringValues)
                            validatedEmails.forEach((email) => {
                                processedValues.push({
                                    email: email.email,
                                    label: email.email,
                                    isValid: email.isValid,
                                })
                            })
                        }

                        newValue.forEach((value) => {
                            if (typeof value !== 'string') {
                                processedValues.push(value)
                            }
                        })

                        setSelectedEmails(processedValues)
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                            const { ...otherTagProps } = getTagProps({ index })
                            return (
                                <Chip
                                    variant="soft"
                                    color={option.isValid ? 'primary' : 'danger'}
                                    endDecorator={
                                        <Box
                                            component="button"
                                            onClick={otherTagProps.onClick}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                border: 'none',
                                                background: 'none',
                                                padding: '2px',
                                                cursor: 'pointer',
                                                fontSize: '12px',
                                                '&:hover': { opacity: 0.7 },
                                            }}
                                        >
                                            ×
                                        </Box>
                                    }
                                    {...otherTagProps}
                                >
                                    {option.email}
                                </Chip>
                            )
                        })
                    }
                    placeholder="Enter or select broker emails"
                    size="lg"
                    sx={{
                        '--Input-minHeight': '45px',
                        '--Input-paddingInline': '1rem',
                        bgcolor: 'background.level2',
                    }}
                />
                {hasInvalidEmails && <FormHelperText sx={{ color: 'danger.500' }}>{emailErrorMessage}</FormHelperText>}
            </FormControl>

            <Box>
                <Typography level="title-sm" sx={{ mb: 1, color: 'neutral.500' }}>
                    Subject
                </Typography>
                <Input
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Enter subject"
                    variant="soft"
                    size="lg"
                    sx={{
                        mb: 1,
                        px: 2.5,
                        py: 1,
                        '--Input-minHeight': '45px',
                        '--Input-paddingInline': '1rem',
                        bgcolor: 'background.level2',
                    }}
                />
            </Box>

            <Box sx={{ flex: 1, minHeight: 200 }}>
                <Typography level="title-sm" sx={{ mb: 1, color: 'neutral.500' }}>
                    Message
                </Typography>
                <RichTextEditor value={message} onChange={setMessage} minHeight="200px" showToolbar />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    type="submit"
                    loading={isSubmitting}
                    disabled={hasInvalidEmails || selectedEmails.length === 0}
                    startDecorator={<Send />}
                    size="lg"
                    color="primary"
                >
                    Send Notification
                </Button>
            </Box>
        </Card>
    )
}
