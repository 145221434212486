import { Box } from '@mui/joy'
import { TruckMap, TwilioCaller } from './components'

export default function DispatcherDashboardPage() {
    return (
        <Box sx={{ pt: 1, width: '100%', position: 'relative', height: '100vh' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', position: 'absolute', zIndex: 1, bottom: 0, left: 0 }}>
                <TwilioCaller />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <TruckMap />
            </Box>
        </Box>
    )
}
