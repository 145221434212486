import { Application, ForgotPasswordData, IBroker, ResetPasswordData, SignInData, SignUpData, User } from '@numeo/types'
import { createAsyncThunk, createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import axios from 'axios'
import { LS_EMAIL_KEY, LS_PHONE_KEY } from 'pages/dashboard/pages/settings/components/Tabs'
import { registerUserForAnalytics } from 'setup/Analytics'
import { AUTH_TOKEN } from 'setup/SetupAxios'
import { RootState } from './index'

export interface DebugData {
    spotFinder: {
        mockBrokerPhoneNumber?: string
        mockBrokerEmail?: string
    }
}

export interface AuthState {
    user: User | null
    application: Application | null
    myBrokers: IBroker[]
    debugData: DebugData
    isAuthenticated: boolean
    isLoading: boolean
}

const initialState: AuthState = {
    user: null,
    application: null,
    debugData: {
        spotFinder: {
            mockBrokerPhoneNumber: '',
            mockBrokerEmail: '',
        },
    },
    myBrokers: [],
    isAuthenticated: false,
    isLoading: true,
}

export const authSlice: Slice<AuthState> = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload
            state.isAuthenticated = Boolean(action.payload)
        },
        setApplication: (state, action: PayloadAction<Application | null>) => {
            state.application = action.payload
        },
        setMyBrokers: (state, action: PayloadAction<IBroker[]>) => {
            state.myBrokers = action.payload
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        updateDebugData: (state, action: PayloadAction<DebugData>) => {
            state.debugData = action.payload
        },
        logout: (state) => {
            localStorage.removeItem(AUTH_TOKEN)
            state.user = null
            state.isAuthenticated = false
        },
    },
})

// Action creators
export const { setUser, setApplication, setMyBrokers, setLoading, updateDebugData, logout } = authSlice.actions

// Type-safe reducer export
export type AuthReducer = typeof authSlice.reducer

// Thunks
export const verifyAuth = createAsyncThunk('auth/verifyAuth', async (_, { dispatch, rejectWithValue }) => {
    const token = localStorage.getItem(AUTH_TOKEN)

    if (!token) {
        dispatch(setUser(null))
        dispatch(setLoading(false))
        return null
    }

    try {
        const response = await axios.get('/auth/verify')
        dispatch(setUser(response.data.user))
        registerUserForAnalytics(response.data.user.id, response.data)

        if (response.data.application) {
            dispatch(
                setApplication({
                    ...response.data.application,
                    projectName: response.data.application.projectName,
                })
            )
        }

        if (response.data.myBrokers) {
            dispatch(setMyBrokers(response.data.myBrokers))
        }

        dispatch(setLoading(false))
        return response.data
    } catch (error) {
        console.error('Auth verification error:', error)
        dispatch(logout(undefined))
        dispatch(setLoading(false))
        return rejectWithValue(error)
    }
})

export const login = createAsyncThunk('auth/login', async (formData: SignInData, { dispatch, rejectWithValue }) => {
    try {
        const response = await axios.post('/auth/sign-in', formData)
        const { token, user, application, myBrokers } = response.data

        localStorage.setItem(AUTH_TOKEN, token)
        dispatch(setUser(user))
        dispatch(setApplication(application))
        dispatch(setMyBrokers(myBrokers))
        return response.data
    } catch (error) {
        // Ensure the error is propagated to the component's catch block
        return rejectWithValue(error)
    }
})

export const signup = createAsyncThunk('auth/signup', async (formData: SignUpData, { dispatch, rejectWithValue }) => {
    try {
        const response = await axios.post('/auth/sign-up', formData)
        const { token, user } = response.data

        localStorage.setItem(AUTH_TOKEN, token)
        dispatch(setUser(user))

        return response.data
    } catch (error) {
        return rejectWithValue(error)
    }
})

export const forgotPassword = async (formData: ForgotPasswordData) => {
    await axios.post('/auth/forgot-password', formData)
}

export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async ({ token, formData }: { token: string; formData: ResetPasswordData }, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post('/auth/reset-password', formData, {
                headers: { Authorization: `Bearer ${token}` },
            })

            const { token: newToken, user } = response.data
            localStorage.setItem(AUTH_TOKEN, newToken)
            dispatch(setUser(user))

            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const loginWithGoogle = createAsyncThunk('auth/loginWithGoogle', async (googleToken: string, { dispatch, rejectWithValue }) => {
    try {
        const response = await axios.post('/auth/google', null, {
            headers: {
                Authorization: `Bearer ${googleToken}`,
            },
        })

        const { token, user } = response.data
        localStorage.setItem(AUTH_TOKEN, token)
        dispatch(setUser(user))

        return response.data
    } catch (error) {
        return rejectWithValue(error)
    }
})

export const loadDebugData = createAsyncThunk('auth/loadDebugData', async (_, { dispatch }) => {
    const savedPhone = localStorage.getItem(LS_PHONE_KEY)
    const savedEmail = localStorage.getItem(LS_EMAIL_KEY)

    dispatch(
        updateDebugData({
            spotFinder: {
                mockBrokerPhoneNumber: savedPhone || '',
                mockBrokerEmail: savedEmail || '',
            },
        })
    )
})

// Selectors
export const selectUser = (state: RootState) => state.auth.user
export const selectApplication = (state: RootState) => state.auth.application
export const selectMyBrokers = (state: RootState) => state.auth.myBrokers
export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated
export const selectIsLoading = (state: RootState) => state.auth.isLoading
export const selectDebugData = (state: RootState) => state.auth.debugData

// Type-safe reducer export
const authReducer = authSlice.reducer
export default authReducer
