import { WsMessage } from '.'

export enum AccountDetailsAction {
    AD_GRANT_ID_EXPIRED = 'accountDetails.grantIdExpired',
}

export interface AccountDetailsBaseMessage extends WsMessage {
    action: AccountDetailsAction.AD_GRANT_ID_EXPIRED
    data: {
        message: string
    }
}
