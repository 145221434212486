import { useEffect, useRef, useState } from 'react'

// MUI Icons
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

// MUI Components
import { Box, Button, IconButton, Input, Typography } from '@mui/joy'
import { useTheme } from '@mui/joy/styles'
import type { SxProps } from '@mui/joy/styles/types'
import { Popover } from '@mui/material'

// Date utilities
import {
    addMonths,
    startOfMonth as dateStartOfMonth,
    format,
    getDay,
    getDaysInMonth,
    isBefore,
    isSameDay,
    isSameMonth,
    isWithinInterval,
    startOfDay,
    subMonths,
} from 'date-fns'

/**
 * DateRange interface representing a range with start and end dates
 */
interface DateRange {
    startDate: string
    endDate: string
}

/**
 * Props for the DateRangePicker component
 */
interface DateRangePickerProps {
    /** Current date range value */
    value: DateRange
    /** Callback when date range changes */
    onChange: (value: DateRange) => void
    /** Optional style overrides */
    sx?: SxProps
    /** Whether the picker is disabled */
    disabled?: boolean
}

/**
 * DateRangePicker component for selecting a range of dates
 */
export const DateRangePicker = ({ value, onChange, sx, disabled = false }: DateRangePickerProps) => {
    const theme = useTheme()
    const anchorRef = useRef<HTMLDivElement>(null)
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const [currentDate, setCurrentDate] = useState(new Date())
    const [viewMode, setViewMode] = useState<'date' | 'month' | 'year'>('date')
    const [yearViewStart, setYearViewStart] = useState(new Date().getFullYear() - 10)
    const [isInitialized, setIsInitialized] = useState(false)
    const [lastClickedDate, setLastClickedDate] = useState<string | null>(null)

    // Get fresh today's date on each render
    const today = startOfDay(new Date())

    // Initial setup when component mounts
    useEffect(() => {
        if (!isInitialized && !value.startDate && !value.endDate) {
            onChange({
                startDate: '',
                endDate: '',
            })
            setIsInitialized(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitialized])

    // Handle date validation and updates based on different scenarios
    useEffect(() => {
        // Skip if disabled
        if (disabled) return

        const refreshedToday = startOfDay(new Date())
        const currentDateStr = format(refreshedToday, 'MM/dd/yyyy')

        // Initialize empty dates
        if (!value.startDate) {
            onChange({
                startDate: currentDateStr,
                endDate: currentDateStr,
            })
            return
        }

        try {
            const startDate = new Date(value.startDate)
            const isStartDateOld = isBefore(startDate, refreshedToday)

            // If we have an end date
            if (value.endDate) {
                const endDate = new Date(value.endDate)
                const isEndDateOld = isBefore(endDate, refreshedToday)

                if (isStartDateOld && isEndDateOld) {
                    // Both dates are old, set both to today
                    onChange({
                        startDate: currentDateStr,
                        endDate: currentDateStr,
                    })
                } else if (isStartDateOld && !isEndDateOld) {
                    // Start date is old but end date is in the future
                    // Set start date to today but keep the future end date
                    onChange({
                        startDate: currentDateStr,
                        endDate: value.endDate,
                    })
                }
                // If both dates are in the future, do nothing
            } else if (isStartDateOld) {
                // Only start date exists and it's old
                onChange({
                    startDate: currentDateStr,
                    endDate: currentDateStr,
                })
            }
        } catch (error) {
            // Handle invalid date format
            console.error('Invalid date format, resetting to today:', error)
            onChange({
                startDate: currentDateStr,
                endDate: currentDateStr,
            })
        }
    }, [disabled, onChange, value])

    // Only disable dates in the past (before today)
    const isDateDisabled = (date: Date) => {
        const startOfDate = startOfDay(date)
        return isBefore(startOfDate, today)
    }

    const handleClick = () => {
        if (disabled) return
        if (anchorRef.current) {
            setAnchorEl(anchorRef.current)
        }
    }

    const handleClose = () => {
        setAnchorEl(null)
        setLastClickedDate(null)
    }

    /**
     * Handle date selection with improved selection behavior
     */
    const handleDateClick = (date: Date): void => {
        if (isDateDisabled(date)) return

        const dateStr = format(startOfDay(date), 'MM/dd/yyyy')

        // Check if the same date was clicked twice (double click)
        if (lastClickedDate === dateStr) {
            // Set both start and end date to the clicked date on double click
            onChange({
                startDate: dateStr,
                endDate: dateStr,
            })
            handleClose()
            return
        }

        setLastClickedDate(dateStr)

        // If no dates selected, set both start and end date to the selected date
        if (!value.startDate) {
            onChange({
                startDate: dateStr,
                endDate: dateStr,
            })
            return
        }

        // Convert existing dates to Date objects for comparison
        const selectedDate = startOfDay(date)
        const existingStartDate = new Date(value.startDate)

        // Determine if we're in "selection mode" (start and end dates are the same)
        const inSelectionMode = value.startDate === value.endDate

        if (inSelectionMode) {
            // We're in selection mode (first date was selected, waiting for second)
            if (isBefore(selectedDate, existingStartDate)) {
                // If selected date is before the current date, make it the start date
                onChange({
                    startDate: dateStr,
                    endDate: value.startDate,
                })
            } else {
                // If selected date is after the current date, make it the end date
                onChange({
                    startDate: value.startDate,
                    endDate: dateStr,
                })
            }
        } else {
            // We already have a range, start a new selection
            onChange({
                startDate: dateStr,
                endDate: dateStr,
            })
        }
    }

    const handleYearSelect = (year: number) => {
        setCurrentDate(new Date(year, currentDate.getMonth()))
        setViewMode('month')
    }

    const handleMonthClick = (month: number) => {
        setCurrentDate(new Date(currentDate.getFullYear(), month))
        setViewMode('date')
    }

    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

    const renderYearView = () => {
        const currentYear = new Date().getFullYear()
        const years = Array.from({ length: 24 }, (_, i) => yearViewStart + i)

        return (
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 2,
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    <IconButton size="sm" onClick={() => setYearViewStart((prev) => prev - 24)}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <Typography
                        level="body-md"
                        fontWeight={600}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                        }}
                    >
                        {`${yearViewStart} – ${yearViewStart + 23}`}
                        <ExpandMoreIcon sx={{ fontSize: '1.25rem' }} />
                    </Typography>
                    <IconButton size="sm" onClick={() => setYearViewStart((prev) => prev + 24)}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        gap: 1,
                        p: 2,
                    }}
                >
                    {years.map((year) => {
                        const isPastYear = year < currentYear

                        return (
                            <Button
                                key={year}
                                variant={currentDate.getFullYear() === year ? 'solid' : 'plain'}
                                color={currentDate.getFullYear() === year ? 'primary' : 'neutral'}
                                disabled={isPastYear}
                                onClick={() => handleYearSelect(year)}
                                sx={{
                                    py: 1.5,
                                    fontSize: '0.875rem',
                                    fontWeight: currentDate.getFullYear() === year ? 600 : 400,
                                    color: (theme) =>
                                        isPastYear ? (theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)') : 'inherit',
                                    '&.Mui-disabled': {
                                        opacity: 1,
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? 'rgba(255, 255, 255, 0.3)' // Lighter text in dark mode
                                                : 'rgba(0, 0, 0, 0.3)',
                                        cursor: 'not-allowed',
                                    },
                                }}
                            >
                                {year}
                            </Button>
                        )
                    })}
                </Box>
            </Box>
        )
    }

    const renderMonthView = () => (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <IconButton size="sm" onClick={() => handleYearChange('prev')} disabled={currentDate.getFullYear() <= new Date().getFullYear()}>
                    <KeyboardArrowLeftIcon />
                </IconButton>
                <Box
                    onClick={() => setViewMode('year')}
                    sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                        '&:hover': {
                            color: 'primary.main',
                        },
                    }}
                >
                    <Typography level="body-md" fontWeight={600}>
                        {currentDate.getFullYear()}
                    </Typography>
                    <ExpandMoreIcon sx={{ fontSize: '1.25rem' }} />
                </Box>
                <IconButton size="sm" onClick={() => handleYearChange('next')}>
                    <KeyboardArrowRightIcon />
                </IconButton>
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: 1,
                    p: 2,
                }}
            >
                {months.map((month, index) => {
                    const isDisabled = currentDate.getFullYear() === new Date().getFullYear() && index < new Date().getMonth()

                    return (
                        <Button
                            key={month}
                            variant={currentDate.getMonth() === index ? 'solid' : 'plain'}
                            color={currentDate.getMonth() === index ? 'primary' : 'neutral'}
                            disabled={isDisabled}
                            onClick={() => handleMonthClick(index)}
                            sx={{
                                py: 1.5,
                                fontSize: '0.875rem',
                                fontWeight: currentDate.getMonth() === index ? 600 : 400,
                                color: (theme) =>
                                    isDisabled ? (theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)') : 'inherit',
                                '&.Mui-disabled': {
                                    opacity: 1,
                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? 'rgba(255, 255, 255, 0.3)' // Lighter text in dark mode
                                            : 'rgba(0, 0, 0, 0.3)',
                                    cursor: 'not-allowed',
                                },
                            }}
                        >
                            {month}
                        </Button>
                    )
                })}
            </Box>
        </Box>
    )

    const renderDateView = () => {
        const monthStart = dateStartOfMonth(currentDate)
        const daysInMonth = getDaysInMonth(currentDate)
        const firstDayOfMonth = getDay(monthStart)

        return (
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
                    <IconButton size="sm" onClick={() => handleDateChange('prev')} disabled={isSameMonth(currentDate, today)}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <Box
                        onClick={() => setViewMode('month')}
                        sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                            '&:hover': {
                                color: 'primary.main',
                            },
                        }}
                    >
                        <Typography level="body-sm" fontWeight={600}>
                            {format(currentDate, 'MMMM yyyy')}
                        </Typography>
                        <ExpandMoreIcon sx={{ fontSize: '1.25rem' }} />
                    </Box>
                    <IconButton size="sm" onClick={() => handleDateChange('next')}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gap: 1, p: 2 }}>
                    {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
                        <Typography
                            key={day}
                            level="body-xs"
                            sx={{
                                textAlign: 'center',
                                fontWeight: 600,
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'rgba(255, 255, 255, 0.9)' // Very bright in dark mode
                                        : 'rgba(0, 0, 0, 0.7)',
                                letterSpacing: '0.02em', // Slightly improved readability
                            }}
                        >
                            {day}
                        </Typography>
                    ))}
                    {Array.from({ length: firstDayOfMonth }, (_, i) => (
                        <Box key={`empty-${i}`} />
                    ))}
                    {Array.from({ length: daysInMonth }, (_, i) => {
                        const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i + 1)
                        const isSelected =
                            value.startDate &&
                            value.endDate &&
                            isWithinInterval(date, {
                                start: new Date(value.startDate),
                                end: new Date(value.endDate),
                            })
                        const isStart = value.startDate && isSameDay(date, new Date(value.startDate))
                        const isEnd = value.endDate && isSameDay(date, new Date(value.endDate))
                        const isPast = isBefore(date, today)
                        const isInRange =
                            value.startDate &&
                            value.endDate &&
                            isWithinInterval(date, {
                                start: new Date(value.startDate),
                                end: new Date(value.endDate),
                            }) &&
                            !isStart &&
                            !isEnd

                        return (
                            <Button
                                key={i}
                                variant={isSelected ? 'solid' : 'plain'}
                                color={isSelected ? 'primary' : 'neutral'}
                                disabled={isPast}
                                onClick={() => handleDateClick(date)}
                                sx={{
                                    aspectRatio: '1',
                                    p: 0,
                                    fontWeight: isSelected ? 600 : 400,
                                    backgroundColor: (theme) => {
                                        if (isStart || isEnd) return 'primary.500'
                                        if (isInRange) {
                                            return theme.palette.mode === 'dark'
                                                ? 'rgba(66, 165, 245, 0.15)' // lighter blue in dark mode
                                                : 'rgba(66, 165, 245, 0.1)' // light blue in light mode
                                        }
                                        return 'transparent'
                                    },
                                    color: (theme) => {
                                        if (isPast) {
                                            return theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)'
                                        }
                                        return isStart || isEnd ? 'white' : 'inherit'
                                    },
                                    '&.Mui-disabled': {
                                        opacity: 1,
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? 'rgba(255, 255, 255, 0.3)' // Lighter text in dark mode
                                                : 'rgba(0, 0, 0, 0.3)',
                                        cursor: 'not-allowed',
                                    },
                                }}
                            >
                                {i + 1}
                            </Button>
                        )
                    })}
                </Box>
            </Box>
        )
    }

    /**
     * Navigate between months
     */
    const handleDateChange = (direction: 'prev' | 'next'): void => {
        setCurrentDate(direction === 'prev' ? subMonths(currentDate, 1) : addMonths(currentDate, 1))
    }

    /**
     * Navigate between years
     */
    const handleYearChange = (direction: 'prev' | 'next'): void => {
        const yearOffset = direction === 'prev' ? -1 : 1
        setCurrentDate(new Date(currentDate.getFullYear() + yearOffset, currentDate.getMonth()))
    }

    /**
     * Format the date range for display
     */
    const formatDateRange = (dateRange: DateRange): string => {
        if (dateRange.startDate && dateRange.endDate) {
            return `${format(new Date(dateRange.startDate), 'MM/dd/yyyy')} - ${format(new Date(dateRange.endDate), 'MM/dd/yyyy')}`
        }

        if (dateRange.startDate) {
            return format(new Date(dateRange.startDate), 'MM/dd/yyyy')
        }

        return ''
    }

    return (
        <Box ref={anchorRef} sx={{ position: 'relative', ...sx }}>
            <Box
                onClick={handleClick}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    cursor: disabled ? 'not-allowed' : 'pointer',
                    opacity: disabled ? 0.5 : 1,
                }}
            >
                <Input
                    startDecorator={<CalendarMonthIcon />}
                    value={formatDateRange(value)}
                    readOnly
                    placeholder="Select date range"
                    sx={{
                        width: '100%',
                        cursor: disabled ? 'not-allowed' : 'pointer',
                    }}
                />
            </Box>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            bgcolor: theme.palette.mode === 'dark' ? 'common.black' : 'common.white',
                            color: theme.palette.mode === 'dark' ? 'common.white' : 'common.black',
                            borderRadius: 'md',
                            boxShadow: 'lg',
                            border: '1px solid',
                            borderColor: (theme) => (theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'),
                            '& .MuiIconButton-root': {
                                color: 'inherit',
                                '&:hover': {
                                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'),
                                },
                            },
                            '& .MuiButton-root': {
                                color: 'inherit',
                                '&.MuiButton-solidPrimary': {
                                    bgcolor: 'primary.main',
                                    color: '#fff',
                                    '&:hover': {
                                        bgcolor: 'primary.dark',
                                    },
                                },
                            },
                            '& .MuiTypography-root': {
                                color: 'inherit',
                                '&.day-label': {
                                    color: (theme) => (theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)'),
                                },
                            },
                            '& .MuiDivider-root': {
                                borderColor: (theme) => (theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'),
                            },
                        },
                    },
                }}
            >
                <Box sx={{ width: 320 }}>
                    {viewMode === 'date' && renderDateView()}
                    {viewMode === 'month' && renderMonthView()}
                    {viewMode === 'year' && renderYearView()}
                </Box>
            </Popover>
        </Box>
    )
}
