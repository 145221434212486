import { Refresh, Search, IosShare } from '@mui/icons-material'
import { Card, CardContent, Stack, Typography, Chip, Box, Input, Button, CircularProgress, Divider } from '@mui/joy'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'
import { Category } from 'hooks/useNotification'
import { exportCSV } from '../utils/export-csv'
import { RateConf } from '@numeo/types'
import { RateConfsTable } from './RateConfsTable'

interface RateConfsCardProps {
    rateConfirmations: RateConf[]
    selectedProject: string
    fetchRateConfirmations: () => Promise<void>
    isLoading: boolean
}

export const RateConfsCard = ({ rateConfirmations, selectedProject, fetchRateConfirmations, isLoading }: RateConfsCardProps) => {
    const [isExporting, setIsExporting] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>('')

    const dispatch = useDispatch()

    // Export rate confirmations to CSV
    const exportToCSV = () => {
        if (rateConfirmations.length === 0) {
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Broker,
                    message: 'No rate confirmations to export',
                    type: 'warning',
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
            return
        }

        try {
            setIsExporting(true)

            // Create a Blob with the CSV data
            const blob = exportCSV(rateConfirmations)
            // Create a download link and trigger the download
            const link = document.createElement('a')
            const url = URL.createObjectURL(blob)

            link.setAttribute('href', url)
            link.setAttribute('download', `${selectedProject}_rate_confirmations_${Date.now()}.csv`)
            link.style.visibility = 'hidden'

            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

            setIsExporting(false)
        } catch (err) {
            console.log('error:', err)
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Broker,
                    message: 'Failed to export rate confirmations',
                    type: 'error',
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
            setIsExporting(false)
        }
    }

    // Filter rate confirmations based on search term
    const filteredRateConfirmations = rateConfirmations.filter((rc) => {
        if (!searchTerm) return true

        const searchLower = searchTerm.toLowerCase()
        return (
            rc.rateConf.documentId?.toLowerCase().includes(searchLower) ||
            rc.rateConf.broker?.companyName?.toLowerCase().includes(searchLower) ||
            rc.rateConf.carrier?.companyName?.toLowerCase().includes(searchLower) ||
            rc.rateConf.shipper?.companyName?.toLowerCase().includes(searchLower) ||
            rc.rateConf.consignee?.companyName?.toLowerCase().includes(searchLower) ||
            rc.rateConf.route?.[0]?.location?.city?.toLowerCase().includes(searchLower) ||
            rc.rateConf.route?.[0]?.location?.state?.toLowerCase().includes(searchLower)
        )
    })
    return (
        <Card>
            <CardContent>
                <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
                    <Typography level="title-lg">
                        Rate Confirmations
                        {filteredRateConfirmations.length > 0 && (
                            <Chip size="sm" variant="soft" sx={{ ml: 1 }}>
                                {filteredRateConfirmations.length}
                            </Chip>
                        )}
                    </Typography>

                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Input
                            placeholder="Search..."
                            startDecorator={<Search />}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{ width: 250 }}
                        />
                        <Button
                            variant="outlined"
                            color="neutral"
                            startDecorator={<Refresh />}
                            onClick={fetchRateConfirmations}
                            disabled={!selectedProject || isLoading}
                        >
                            Refresh
                        </Button>
                        <Button
                            variant="solid"
                            color="success"
                            startDecorator={isExporting ? <CircularProgress size="sm" /> : <IosShare />}
                            onClick={exportToCSV}
                            disabled={!selectedProject || isLoading || rateConfirmations.length === 0 || isExporting}
                        >
                            {isExporting ? 'Exporting...' : 'Export to CSV'}
                        </Button>
                    </Box>
                </Stack>

                <Divider sx={{ mb: 2 }} />

                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
                        <CircularProgress />
                    </Box>
                ) : filteredRateConfirmations.length === 0 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
                        <Typography level="body-lg" color="neutral">
                            No rate confirmations found
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <RateConfsTable rateConfirmations={filteredRateConfirmations} />
                    </>
                )}
            </CardContent>
        </Card>
    )
}
