import { Modal, ModalDialog, ModalClose, Typography, Divider } from '@mui/joy'
import { LoadForm } from './LoadForm'
import { OngoingLoad } from '@numeo/types'
import { useState } from 'react'
import { ongoingLoadsApi } from '../api/ongoing-loads.api'

export enum ModalType {
    CREATE = 'create',
    EDIT = 'edit',
    DELETE = 'delete',
    NONE = 'none',
}

type OngoingLoadDTO = Omit<OngoingLoad, '_id' | 'createdAt' | 'updatedAt'>

interface LoadActionModalProps {
    modalType: ModalType
    setModalType: (type: ModalType) => void
    currentLoad: OngoingLoad | null
    fetchLoads: () => void // Keeping for backward compatibility
    showNotificationMessage: (type: 'info' | 'error', message: string) => void
    // New props for state updates
    setLoads: React.Dispatch<React.SetStateAction<OngoingLoad[]>>
}

export const LoadActionModal = ({ modalType, setModalType, currentLoad, showNotificationMessage, setLoads }: LoadActionModalProps) => {
    const [loading, setLoading] = useState<boolean>(false)

    const handleCreate = async (loadData: OngoingLoadDTO) => {
        try {
            setLoading(true)
            const response = await ongoingLoadsApi.createOngoingLoad(loadData)

            // Update state directly with the new load
            if (response && response._id) {
                setLoads((prevLoads) => [response, ...prevLoads])
            }

            setModalType(ModalType.NONE)
            showNotificationMessage('info', 'Load created successfully')
        } catch (err) {
            console.error('Error creating ongoing load:', err)
            showNotificationMessage('error', 'Failed to create ongoing load.')
        } finally {
            setLoading(false)
        }
    }

    const handleUpdate = async (id: string, loadData: Partial<OngoingLoadDTO>) => {
        try {
            setLoading(true)
            await ongoingLoadsApi.updateOngoingLoad(id, loadData)

            // Update state directly with the updated load
            setLoads((prevLoads) => prevLoads.map((load) => (load._id === id ? { ...load, ...loadData, updatedAt: new Date().toISOString() } : load)))

            setModalType(ModalType.NONE)
            showNotificationMessage('info', 'Load updated successfully')
        } catch (err) {
            console.error('Error updating ongoing load:', err)
            showNotificationMessage('error', 'Failed to update ongoing load.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal open={modalType === ModalType.CREATE || modalType === ModalType.EDIT} onClose={() => setModalType(ModalType.NONE)}>
            <ModalDialog variant="outlined" role="alertdialog" sx={{ maxWidth: 800, width: '100%' }}>
                <ModalClose />
                <Typography level="h4" component="h2">
                    {modalType === ModalType.CREATE ? 'Create New Load' : 'Edit Load'}
                </Typography>
                <Divider />
                <LoadForm
                    initialData={modalType === ModalType.EDIT ? currentLoad : undefined}
                    onSubmit={(data) => {
                        if (modalType === ModalType.CREATE) {
                            handleCreate(data as OngoingLoadDTO)
                        } else if (modalType === ModalType.EDIT && currentLoad) {
                            handleUpdate(currentLoad._id, data)
                        }
                    }}
                    onCancel={() => setModalType(ModalType.NONE)}
                    loading={loading}
                />
            </ModalDialog>
        </Modal>
    )
}
