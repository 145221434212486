import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Call, ClientLoadModel, RingCentralCall } from '@numeo/types'

export enum CallType {
    AI = 'AI',
    RingCentral = 'RC',
}

export interface CallCenterState {
    calls: Record<string, Call>
    selectedCallId: string | null
    stats: {
        total: number
        byStatus: Record<string, number>
        byType: Record<string, number>
        byPriority: Record<string, number>
    }
    recentlyUpdated: string[]
    wsStatus: 'disconnected' | 'connecting' | 'connected'
    activeRingCentralCall: RingCentralCall | null
    openRingCentralSideBar: boolean
    activeLoad: ClientLoadModel | null
    callTypePreferences: Record<string, CallType> // Store call type preferences by loadId
}

const initialState: CallCenterState = {
    calls: {},
    selectedCallId: null,
    stats: {
        total: 0,
        byStatus: {},
        byType: {},
        byPriority: {},
    },
    recentlyUpdated: [],
    wsStatus: 'disconnected',
    activeRingCentralCall: null,
    openRingCentralSideBar: false,
    activeLoad: null,
    callTypePreferences: {},
}

const callCenterSlice = createSlice({
    name: 'callCenter',
    initialState,
    reducers: {
        updateCall(state, action: PayloadAction<{ callId: string; updates: Partial<Call> }>) {
            const { callId, updates } = action.payload
            const call = state.calls[callId]
            state.calls[callId] = { ...call, ...updates }
            if (!state.recentlyUpdated.includes(callId)) {
                state.recentlyUpdated.push(callId)
            }
        },
        removeCall(state, action: PayloadAction<string>) {
            const callId = action.payload
            delete state.calls[callId]
            state.recentlyUpdated = state.recentlyUpdated.filter((id) => id !== callId)
        },
        setSelectedCall(state, action: PayloadAction<string | null>) {
            state.selectedCallId = action.payload
        },
        handleCorrectTranscription(state, action: PayloadAction<{ segmentId: string; text: string }>) {
            console.log('Correcting transcription:', action.payload.segmentId, action.payload.text)
        },
        handleFlagTranscription(state, action: PayloadAction<{ segmentId: string; reason: string }>) {
            console.log('Flagging transcription:', action.payload.segmentId, action.payload.reason)
        },
        setCallWsStatus(state, action: PayloadAction<'disconnected' | 'connecting' | 'connected'>) {
            state.wsStatus = action.payload
        },
        setRecentlyUpdated(state, action: PayloadAction<string>) {
            if (!state.recentlyUpdated.includes(action.payload)) {
                state.recentlyUpdated = [...state.recentlyUpdated, action.payload]
            }
        },
        upsertRingCentralCallSession(state, action: PayloadAction<{ call: RingCentralCall; load: ClientLoadModel }>) {
            state.activeRingCentralCall = action.payload.call
            state.activeLoad = action.payload.load
            state.openRingCentralSideBar = true
        },
        removeRingCentralCallSession(state) {
            state.activeRingCentralCall = null
            state.activeLoad = null
            state.openRingCentralSideBar = false
        },
        toggleRingCentralSidebar(state, action: PayloadAction<boolean>) {
            state.openRingCentralSideBar = action.payload
        },
        setCallTypePreference(state, action: PayloadAction<{ loadId: string; callType: CallType }>) {
            const { loadId, callType } = action.payload
            state.callTypePreferences[loadId] = callType
        },
    },
})

export const {
    updateCall,
    removeCall,
    setSelectedCall,
    handleCorrectTranscription,
    handleFlagTranscription,
    setCallWsStatus,
    setRecentlyUpdated,
    upsertRingCentralCallSession,
    removeRingCentralCallSession,
    toggleRingCentralSidebar,
    setCallTypePreference,
} = callCenterSlice.actions
export default callCenterSlice.reducer
