import { Box, Card, Divider, Typography } from '@mui/joy'
import React, { useState } from 'react'
import { CityLocationData } from 'utils/location-search/LocationDataSearchV2'
import { LocationSearch } from './LocationSearch'

export const LocationSearchExample: React.FC = () => {
    const [selectedLocation, setSelectedLocation] = useState<CityLocationData | null>(null)

    const handleLocationSelect = (location: CityLocationData) => {
        setSelectedLocation(location)
    }

    return (
        <Box sx={{ p: 4, maxWidth: 800, mx: 'auto' }}>
            <Typography level="h2" sx={{ mb: 2 }}>
                Location Search
            </Typography>

            <Typography level="body-md" sx={{ mb: 3, color: 'neutral.500' }}>
                Search for any city, state, or county in the United States
            </Typography>

            <LocationSearch onLocationSelect={handleLocationSelect} placeholder="Try typing 'San Fra' or 'New Yor'" limit={10} />

            {selectedLocation && (
                <Card variant="outlined" sx={{ mt: 4 }}>
                    <Typography level="h4">Selected Location</Typography>
                    <Divider sx={{ my: 2 }} />
                    <Box sx={{ display: 'grid', gap: 2 }}>
                        <Box>
                            <Typography level="body-sm" color="neutral">
                                City
                            </Typography>
                            <Typography level="body-lg">{selectedLocation.city}</Typography>
                        </Box>
                        <Box>
                            <Typography level="body-sm" color="neutral">
                                State
                            </Typography>
                            <Typography level="body-lg">
                                {selectedLocation.stateName} ({selectedLocation.stateCode})
                            </Typography>
                        </Box>
                        <Box>
                            <Typography level="body-sm" color="neutral">
                                County
                            </Typography>
                        </Box>
                        <Box>
                            <Typography level="body-sm" color="neutral">
                                Coordinates
                            </Typography>
                            <Typography level="body-lg">
                                {selectedLocation.latitude.toFixed(6)}, {selectedLocation.longitude.toFixed(6)}
                            </Typography>
                        </Box>
                    </Box>
                </Card>
            )}
        </Box>
    )
}
