import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WebSocketStatus, WsMessage } from '@numeo/types'

export interface WebSocketState {
    status: WebSocketStatus
    error: string | null
}

const initialState: WebSocketState = {
    status: WebSocketStatus.DISCONNECTED,
    error: null,
}

const webSocketSlice = createSlice({
    name: 'webSocket',
    initialState,
    reducers: {
        connecting(state) {
            state.status = WebSocketStatus.CONNECTING
            state.error = null
        },
        connected(state) {
            state.status = WebSocketStatus.CONNECTED
            state.error = null
        },
        disconnected(state, action: PayloadAction<string | null>) {
            state.status = WebSocketStatus.DISCONNECTED
            state.error = action.payload
        },
        error(state, action: PayloadAction<string>) {
            state.status = WebSocketStatus.ERROR
            state.error = action.payload
        },
        sendMessage(state, action: PayloadAction<WsMessage>) {
            console.log('websocket state', state, action)
        },
        reconnect(state) {
            state.status = WebSocketStatus.CONNECTING
            state.error = null
        },
    },
})

export const { connecting, connected, disconnected, error, sendMessage, reconnect } = webSocketSlice.actions
export default webSocketSlice.reducer
