import { Box, CircularProgress, Typography, Stack } from '@mui/joy'
import { useState, useEffect } from 'react'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import VinIcon from '@mui/icons-material/QrCode'
import { LocalGasStation } from '@mui/icons-material'
import { TteldUnit } from '@numeo/types'
import { eldApi } from '../../api/eld-api'
import { formatTimestamp } from '../assets/helpers'
import { ELDMap } from './ELDMap'

interface TTELDSectionProps {
    vin: string
    isOpen: boolean
}

export const TTELDSection = ({ vin, isOpen }: TTELDSectionProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [unitData, setUnitData] = useState<TteldUnit | null>(null)

    useEffect(() => {
        if (isOpen && !unitData) {
            fetchUnitData()
        }
        // eslint-disable-next-line
    }, [isOpen])

    const fetchUnitData = async (): Promise<void> => {
        try {
            setIsLoading(true)
            const unit = await eldApi.getTteldUnit(vin)
            setUnitData(unit)
        } catch (error) {
            console.error(error)
            setUnitData(null)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Box width="300px">
            {isLoading ? (
                <Box display="flex" justifyContent="center" p={2}>
                    <CircularProgress size="sm" />
                </Box>
            ) : unitData ? (
                <>
                    <Typography level="title-sm">TT ELD</Typography>
                    <Stack spacing={2}>
                        {/* ELD Map */}
                        <ELDMap latitude={String(unitData.coordinates.lat)} longitude={String(unitData.coordinates.lng)} />
                        <Stack spacing={1.5} sx={{ px: 1 }}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <LocalShippingIcon sx={{ fontSize: 20, color: 'primary.500' }} />
                                <Typography level="body-sm">Truck #{unitData.truck_number}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <VinIcon sx={{ fontSize: 20, color: 'primary.500' }} />
                                <Typography level="body-sm" sx={{ fontFamily: 'monospace' }}>
                                    {unitData.vin}
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <AccessTimeIcon sx={{ fontSize: 20, color: 'primary.500' }} />
                                <Typography level="body-sm">{formatTimestamp(unitData.timestamp)}</Typography>
                            </Stack>
                            {unitData.fuelLevelPercent && (
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <LocalGasStation sx={{ fontSize: 20, color: 'primary.500' }} />
                                    <Typography level="body-sm">{unitData.fuelLevelPercent.toFixed(1)}% Fuel</Typography>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </>
            ) : null}
        </Box>
    )
}
