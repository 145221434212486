import { Button, Alert, Typography, IconButton, Stack } from '@mui/joy'
import { useState } from 'react'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { DATAccountModal, RingCentral } from './Integrations'
import { useAuth } from 'hooks/auth'

export const IntegrationsAlert = () => {
    const { application } = useAuth()
    const [datModalOpen, setDatModalOpen] = useState(false)
    const [isAlertVisible, setIsAlertVisible] = useState(true)

    if (!isAlertVisible) return null

    return (
        <>
            <Alert
                variant="soft"
                color="neutral"
                sx={{
                    alignItems: 'center',
                    '& .MuiAlert-message': {
                        width: '100%',
                    },
                }}
                startDecorator={
                    <IconButton variant="plain" color="neutral" onClick={() => setIsAlertVisible(false)} sx={{ '--IconButton-size': '32px' }}>
                        <CloseRoundedIcon />
                    </IconButton>
                }
            >
                <Stack direction="row" spacing={3} alignItems="center" width="100%">
                    <Stack direction="row" spacing={1} alignItems="center">
                        <AccountCircleRoundedIcon />
                        <Typography level="body-md">Connect your accounts to gain full access to the load board.</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        {!application?.integrations.dat && (
                            <Button variant="solid" color="primary" size="sm" onClick={() => setDatModalOpen(true)}>
                                DAT
                            </Button>
                        )}
                        {!application?.integrations.ringcentral && <RingCentral />}
                    </Stack>
                </Stack>
            </Alert>
            <DATAccountModal open={datModalOpen} onClose={() => setDatModalOpen(false)} />
        </>
    )
}
