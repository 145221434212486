// Dependencies
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import AppRoutes from './pages/routes'
import './setup/Analytics'

// Initialize Day.js plugins
dayjs.extend(duration)
dayjs.extend(relativeTime)

// Main App Component
export default function App() {
    return <AppRoutes />
}
