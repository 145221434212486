import { Edit, Delete } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/joy'
import { OngoingLoad } from '@numeo/types'
import { ModalType } from './LoadActionModal'

interface ActionCellProps {
    load: OngoingLoad
    setCurrentLoad: (load: OngoingLoad | null) => void
    setModalType: (modalType: ModalType) => void
}

export const ActionCell = ({ load, setCurrentLoad, setModalType }: ActionCellProps) => {
    return (
        <Box sx={{ display: 'flex', gap: 1 }}>
            <Tooltip title="Edit">
                <IconButton
                    size="sm"
                    variant="soft"
                    color="primary"
                    onClick={(e) => {
                        e.stopPropagation()
                        setCurrentLoad(load)
                        setModalType(ModalType.EDIT)
                    }}
                >
                    <Edit />
                </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
                <IconButton
                    size="sm"
                    variant="soft"
                    color="danger"
                    onClick={(e) => {
                        e.stopPropagation()
                        setCurrentLoad(load)
                        setModalType(ModalType.DELETE)
                    }}
                >
                    <Delete />
                </IconButton>
            </Tooltip>
        </Box>
    )
}
