import { Sheet, Table, Checkbox, Typography, Box, Chip, CircularProgress } from '@mui/joy'
import { ModalType } from './LoadActionModal'
import { DeliveryStatus, OngoingLoad } from '@numeo/types'
import { useMemo, useState } from 'react'
import { TablePagination } from './TablePagination'
import { OngoingLoadDetails } from './LoadDetails'
import { ActionCell } from './ActionCell'

interface OngoingLoadsTableProps {
    loads: OngoingLoad[]
    selectedLoads: string[]
    setSelectedLoads: (ids: string[]) => void
    searchQuery: string
    loading: boolean
    setCurrentLoad: (load: OngoingLoad | null) => void
    setModalType: (modalType: ModalType) => void
    total: number
    setPage: (page: number) => void
    setRowsPerPage: (rowsPerPage: number) => void
    page: number
    rowsPerPage: number
}

export const OngoingLoadsTable = ({
    loads,
    selectedLoads,
    setSelectedLoads,
    searchQuery,
    loading,
    setCurrentLoad,
    setModalType,
    total,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
}: OngoingLoadsTableProps) => {
    const [expandedLoadIds, setExpandedLoadIds] = useState<Set<string>>(new Set())

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedLoads(loads.map((load) => load._id))
        } else {
            setSelectedLoads([])
        }
    }

    const handleSelectLoad = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        if (event.target.checked) {
            setSelectedLoads([...selectedLoads, id])
        } else {
            setSelectedLoads(selectedLoads.filter((loadId) => loadId !== id))
        }
    }

    const handleRowClick = (loadId: string) => {
        if (expandedLoadIds.has(loadId)) {
            setExpandedLoadIds((prev) => new Set([...prev].filter((id) => id !== loadId)))
        } else {
            setExpandedLoadIds((prev) => new Set([...prev, loadId]))
        }
    }

    const filteredLoads = useMemo(() => {
        if (!searchQuery) return loads

        return loads.filter(
            (load) =>
                load.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                load.origin.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
                load.destination.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
                load.equipmentType.toLowerCase().includes(searchQuery.toLowerCase())
        )
    }, [loads, searchQuery])

    const formatDate = (dateString: string | Date) => {
        if (!dateString) return 'N/A'
        const date = new Date(dateString)
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        }).format(date)
    }

    const formatUpdatedAt = (dateString: string | Date) => {
        if (!dateString) return 'N/A'
        const date = new Date(dateString)
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        }).format(date)
    }

    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount)
    }

    return (
        <Sheet
            variant="outlined"
            sx={{
                borderRadius: 'sm',
                boxShadow: 'sm',
                overflow: 'auto',
                maxHeight: 'calc(100vh - 200px)',
            }}
        >
            <Table
                borderAxis="xBetween"
                color="neutral"
                size="md"
                stickyHeader
                variant="plain"
                sx={{
                    '& thead th:first-child': { width: 50 },
                    '& thead th:last-child': { width: 120 },
                    '& tbody > tr:not(.expanded)': {
                        '&:hover': {
                            backgroundColor: 'background.level1',
                            cursor: 'pointer',
                        },
                    },
                }}
            >
                <thead>
                    <tr>
                        <th>
                            <Checkbox
                                size="sm"
                                indeterminate={selectedLoads.length > 0 && selectedLoads.length < loads.length}
                                checked={selectedLoads.length > 0 && selectedLoads.length === loads.length}
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th style={{ width: 150 }}>Company</th>
                        <th style={{ width: 100 }}>MC/DOT #</th>
                        <th style={{ width: 100 }}>Origin</th>
                        <th style={{ width: 100 }}>Destination</th>
                        <th style={{ width: 70 }}>Equipment</th>
                        <th style={{ width: 100 }}>Pickup Date</th>
                        <th style={{ width: 100 }}>Delivery Date</th>
                        <th style={{ width: 60 }}>Status</th>
                        <th style={{ width: 80 }}>Rate</th>
                        <th style={{ width: 130 }}>Last Broker Updated</th>
                        <th style={{ width: 150 }}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {loading && !filteredLoads.length ? (
                        <tr>
                            <td colSpan={12} style={{ textAlign: 'center', padding: '2rem' }}>
                                <CircularProgress size="lg" />
                            </td>
                        </tr>
                    ) : filteredLoads.length === 0 ? (
                        <tr>
                            <td colSpan={12} style={{ textAlign: 'center', padding: '2rem' }}>
                                <Typography>No loads found</Typography>
                            </td>
                        </tr>
                    ) : (
                        filteredLoads.map((load) => (
                            <>
                                <tr key={load._id} onClick={() => handleRowClick(load._id)} style={{ cursor: 'pointer' }}>
                                    <td>
                                        <Checkbox
                                            size="sm"
                                            checked={selectedLoads.includes(load._id)}
                                            onChange={(e) => handleSelectLoad(e, load._id)}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </td>
                                    <td>
                                        <Typography level="body-sm" fontWeight="md">
                                            {load.companyName}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography level="body-sm">MC# {load.mcNumber}</Typography>
                                            {load.dotNumber && <Typography level="body-sm">DOT# {load.dotNumber}</Typography>}
                                        </Box>
                                    </td>
                                    <td>
                                        <Typography level="body-sm">
                                            {load.origin.city}, {load.origin.state}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography level="body-sm">
                                            {load.destination.city}, {load.destination.state}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Chip variant="soft" size="sm" color="neutral">
                                            {load.equipmentType}
                                        </Chip>
                                    </td>
                                    <td>
                                        <Typography level="body-sm">{formatDate(load.pickupTime1)}</Typography>
                                    </td>
                                    <td>
                                        <Typography level="body-sm">{formatDate(load.deliveryTime1)}</Typography>
                                    </td>
                                    <td>
                                        <Typography level="body-sm" color={load.deliveryStatus === DeliveryStatus.ON_TRACK ? 'success' : 'danger'}>
                                            {load.deliveryStatus || 'N/A'}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography level="body-sm" fontWeight="md">
                                            {formatCurrency(load.totalRate)}
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography level="body-sm">{load.lastBrokerUpdated ? formatUpdatedAt(load.lastBrokerUpdated) : 'N/A'}</Typography>
                                    </td>
                                    <td>
                                        <ActionCell load={load} setCurrentLoad={setCurrentLoad} setModalType={setModalType} />
                                    </td>
                                </tr>
                                {expandedLoadIds.has(load._id) && (
                                    <tr key={`${load._id}-details`} className="expanded">
                                        <td colSpan={12}>
                                            <OngoingLoadDetails
                                                load={load}
                                                formatDate={formatDate}
                                                formatCurrency={formatCurrency}
                                                formatUpdatedAt={formatUpdatedAt}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </>
                        ))
                    )}
                </tbody>
            </Table>

            {/* Pagination */}
            <TablePagination total={total} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
        </Sheet>
    )
}
