import { Box, CircularProgress, Typography } from '@mui/joy'
import { LoadFindResultState, SearchStatus } from '@numeo/types'

interface TableStatusOverlayProps {
    searchData?: LoadFindResultState
    searchStatus: SearchStatus
}

export const TableStatusOverlay: React.FC<TableStatusOverlayProps> = ({ searchData, searchStatus }) => {
    const renderContent = () => {
        // If search is initialized and there is no loads in the search data, show loading state
        if (searchStatus === SearchStatus.searchInitialized && (Object.values(searchData?.loads ?? {}).length ?? 0) === 0) {
            return (
                <>
                    <CircularProgress />
                    <Typography level="body-md" sx={{ ml: 2 }}>
                        We are searching for your loads...
                    </Typography>
                </>
            )
        }

        if (!searchData || !searchStatus) {
            return <Typography level="body-md">Start your first search!</Typography>
        }

        if (Object.values(searchData.loads).length === 0) {
            if (searchStatus === SearchStatus.searchStarted) {
                return (
                    <>
                        <Typography level="body-md" sx={{ mr: 2 }}>
                            No loads found, but we're still searching
                        </Typography>
                        <CircularProgress />
                    </>
                )
            }

            if (searchStatus === SearchStatus.searchFinished) {
                return <Typography level="body-md">No loads found</Typography>
            }
        }

        return null
    }

    const content = renderContent()

    if (!content) {
        return null
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
                alignItems: 'center',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
                bgcolor: 'background.surface',
            }}
        >
            {content}
        </Box>
    )
}
