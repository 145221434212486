import Close from '@mui/icons-material/Close'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, Card, IconButton, Stack, Typography } from '@mui/joy'
import { CircularProgress, Popover } from '@mui/material'
import { Email, EmailRecipient, OngoingLoad } from '@numeo/types'
import axios from 'axios'
import { useAuth } from 'hooks/auth'
import { memo, useEffect, useMemo, useState } from 'react'
import { EmailHeader } from 'pages/dashboard/pages/dispatcher/pages/dispatcher-emails/components/EmailContent/components/EmailCard/components'
import {
    EmailEditor,
    EmailEditorProps,
} from '../../../../dispatcher/pages/dispatcher-emails/components/EmailContent/components/EmailCard/components/EmailEditor'
import { ongoingLoadsApi } from '../api/ongoing-loads.api'
import EmailBody from 'components/common/EmailBody'

interface SendEmailPopoverProps {
    anchorEl: HTMLElement | null
    load: OngoingLoad
    open: boolean
    toWho: EmailRecipient
    setAnchorEl: (anchorEl: HTMLElement | null) => void
}

export const SendEmailPopover = memo<SendEmailPopoverProps>(({ toWho, anchorEl, open, setAnchorEl, load }) => {
    const { application } = useAuth()
    const [emails, setEmails] = useState<Email[]>([])
    const [expandedThreads, setExpandedThreads] = useState(new Set())
    const [refreshTrigger, setRefreshTrigger] = useState<number>(0)
    const [loading, setLoading] = useState(false)
    const [editedSubject, setEditedSubject] = useState('')
    const [editedBody, setEditedBody] = useState('')
    const [emailSignature, setEmailSignature] = useState('')
    const [emailThreadId, setEmailThreadId] = useState<string | null>(
        (toWho === EmailRecipient.BROKER ? load.brokerEmailThreadId : load.driverEmailThreadId) || null
    )

    // Reset state when unmounting
    useEffect(() => {
        return () => {
            setEditedBody('')
            setEditedSubject('')
            setExpandedThreads(new Set())
        }
    }, [])

    const handleEditorChange = (newContent: string) => {
        setEditedBody(newContent)
    }

    const fullContent = useMemo(() => {
        const currentBody = editedBody || ''
        // Only append signature if it's not already in the body
        if (currentBody.includes(emailSignature)) {
            return currentBody
        }

        const newBody = `${currentBody}<br>${emailSignature || ''}`
        setEditedBody(newBody)

        return `${currentBody}<br>${emailSignature || ''}`
        // eslint-disable-next-line
    }, [emailSignature, editedBody])

    const fetchFooter = async () => {
        try {
            const emailFooter = application?.temp?.emailFooter?.closing || ''
            setEmailSignature(emailFooter)
        } catch (error) {
            console.error('Error fetching footer:', error)
        }
    }

    useEffect(() => {
        if (open) {
            fetchFooter()
        }
        // eslint-disable-next-line
    }, [open])

    useEffect(() => {
        const fetchEmails = async () => {
            if (emailThreadId && open) {
                setLoading(true)
                try {
                    const response = await axios.get(`/email/threadId/${emailThreadId}`)
                    const fetchedEmails = response.data
                    const sortedEmails = [...fetchedEmails].sort((a, b) => (a.emailData?.object?.date || 0) - (b.emailData?.object?.date || 0))
                    setEmails(sortedEmails)
                    if (sortedEmails.length > 0) {
                        const lastTwoIndices = new Set([sortedEmails.length - 2, sortedEmails.length - 1].filter((i) => i >= 0))
                        setExpandedThreads(lastTwoIndices)
                    }
                } catch (error) {
                    console.error('Error fetching emails:', error)
                } finally {
                    setLoading(false)
                }
            } else {
                setEmails([])
            }
        }

        fetchEmails()
    }, [emailThreadId, open, refreshTrigger])

    const toggleThread = (index) => {
        setExpandedThreads((prev) => {
            const newSet = new Set(prev)
            if (newSet.has(index)) {
                newSet.delete(index)
            } else {
                newSet.add(index)
            }
            return newSet
        })
    }

    const sendEmail = async () => {
        try {
            if (!application?.projectName) {
                throw new Error('Project name not found')
            }
            const threadId = await ongoingLoadsApi.sendEmail(toWho, editedSubject, editedBody, load._id, application?.projectName)
            if (threadId) {
                setEmailThreadId(threadId)
            }

            // Wait for 1 second to show success state before closing
            setTimeout(() => {
                setEditedBody('')
                setEditedBody('')
                setEditedSubject('')
                setRefreshTrigger(refreshTrigger + 1)
            }, 1000)
        } catch (error) {
            console.error('Failed to send email:', error)
        }
    }

    const isEmailFromYou = (from?: string) => {
        if (!from || !application?.integrations?.nylas?.emails) return false
        return application.integrations.nylas.emails.find((email) => email.email === from)
    }

    useEffect(() => {
        if (emails.length > 0 && emails[0].emailData?.object?.subject) {
            setEditedSubject(`RE: ${emails[0].emailData.object.subject}`)
        }
    }, [emails])

    const handleClose = () => {
        if (anchorEl) {
            anchorEl.blur()
            setTimeout(() => setAnchorEl(null), 0)
        }
    }

    const emailEditorProps: EmailEditorProps = {
        subject: {
            value: editedSubject,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setEditedSubject(e.target.value),
        },
        textEditor: {
            value: fullContent,
            onChange: handleEditorChange,
        },
        onSendClick: sendEmail,
    }

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            disableRestoreFocus
            disableAutoFocus
            disableEnforceFocus
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            slotProps={{
                paper: {
                    onClick: (e) => e.stopPropagation(),
                },
            }}
            sx={{
                pointerEvents: 'none',
                '& .MuiPopover-paper': {
                    pointerEvents: 'auto',
                },
            }}
        >
            <Box
                sx={{
                    maxHeight: 500,
                    overflowY: 'auto',
                    width: 700,
                    minHeight: 300,
                    backgroundColor: 'background.level1',
                    borderRadius: '6px',
                    p: 1,
                }}
            >
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 10,
                        zIndex: 1,
                    }}
                >
                    <Close />
                </IconButton>
                <Stack spacing={2} sx={{ height: '100%' }}>
                    {loading ? (
                        <Box sx={{ display: 'grid', placeContent: 'center', height: '100%' }}>
                            <Typography level="body-sm" sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                                <CircularProgress size={16} /> Loading emails...
                            </Typography>
                        </Box>
                    ) : (
                        <Stack spacing={1} sx={{ display: 'flex', flexDirection: 'column' }}>
                            {emails?.length > 0 ? (
                                emails
                                    .sort((a, b) => (a.emailData?.object?.date || 0) - (b.emailData?.object?.date || 0))
                                    .map((email, index) => {
                                        const isFromMe = isEmailFromYou(email.emailData?.object?.from?.[0]?.email)
                                        return (
                                            <Box
                                                key={index}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: isFromMe ? 'flex-end' : 'flex-start',
                                                    width: '100%',
                                                }}
                                            >
                                                <Card
                                                    variant="outlined"
                                                    sx={{
                                                        p: 1.5,
                                                        borderRadius: 'md',
                                                        width: '90%',
                                                    }}
                                                >
                                                    <Stack spacing={1}>
                                                        <EmailHeader
                                                            from={{
                                                                name: email.emailData?.object?.from?.[0]?.name,
                                                                email: email.emailData?.object?.from?.[0]?.email,
                                                            }}
                                                            subtitle={new Date(email.emailData?.object?.date * 1000).toLocaleString()}
                                                            isReply={!isFromMe}
                                                        />
                                                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                            <Typography level="body-md" sx={{ fontWeight: 'bold' }}>
                                                                {email.emailData?.object?.subject || 'No Subject'}
                                                            </Typography>
                                                            <IconButton
                                                                onClick={(e: React.MouseEvent) => {
                                                                    e.stopPropagation()
                                                                    toggleThread(index)
                                                                }}
                                                            >
                                                                {expandedThreads.has(index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                            </IconButton>
                                                        </Stack>
                                                    </Stack>
                                                    {expandedThreads.has(index) ? (
                                                        <Stack spacing={1}>
                                                            <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                                                                To: {email.emailData?.object?.to?.map((t) => t.email).join(', ') || 'Unknown Recipients'}
                                                            </Typography>
                                                            <Box sx={{ mt: 0.5, height: 'auto' }}>
                                                                <EmailBody
                                                                    body={email.emailData?.object?.body || ''}
                                                                    subject={email.emailData?.object?.subject || 'No Subject'}
                                                                    height="auto"
                                                                    attachments={email.emailData?.object?.attachments}
                                                                    messageId={email.emailData.object.id}
                                                                />
                                                            </Box>
                                                        </Stack>
                                                    ) : null}
                                                </Card>
                                            </Box>
                                        )
                                    })
                            ) : (
                                <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography level="body-md">Send an email...</Typography>
                                </Box>
                            )}

                            <EmailEditor {...emailEditorProps} />
                        </Stack>
                    )}
                </Stack>
            </Box>
        </Popover>
    )
})
