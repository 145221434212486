import { Box, Button, Checkbox, Divider, FormLabel, Input, Option, Select, Stack, Typography } from '@mui/joy'
import { FormControl, FormControlLabel } from '@mui/material'
import FilterEmailTriggerUI from '../EmailTriggerUI'
import { PriceProgramSelector, StateSelector } from '../Selectors'
import { useAuth } from 'hooks/auth'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getEmailSettings, saveEmailSettings } from '../../api/EmailSettings'
import { STATES_LIST } from '../../const'
import { ApplicationSettingsStatesListType } from '@numeo/types'
import axios from 'axios'

export const EmailSettingsTab = () => {
    const { application } = useAuth()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [fuelCost, setFuelCost] = useState<number | null>(null)
    const [driverCost, setDriverCost] = useState<number | null>(null)
    const [pricesList, setPricesList] = useState<null | { value: string; label: string }[]>(null)
    const [excludedStates, setExcludedStates] = useState<string[]>(application?.temp?.emailSettings?.states?.excluded || [])
    const [includedStates, setIncludedStates] = useState<string[]>(application?.temp?.emailSettings?.states?.included || [])
    const [isMountainAreaIncluded, setIsMountainAreaIncluded] = useState(application?.temp?.emailSettings?.mountainArea || false)
    const [handleEmailTrigger, setHandleEmailTrigger] = useState(false)
    const [isExclusionList, setIsExclusionList] = useState(application?.temp?.emailSettings?.states?.activeList !== 'included')
    const [ratePerMile, setRatePerMile] = useState<number>(application?.temp?.emailSettings?.ratePerMile || 3)

    const statesList = useMemo(
        () =>
            STATES_LIST.map((state) => ({
                value: state.value,
                label:
                    state.label +
                    ((isExclusionList ? includedStates : excludedStates).includes(state.value) ? ` (${isExclusionList ? 'included' : 'excluded'})` : ''),
                disabled: (isExclusionList ? includedStates : excludedStates).includes(state.value),
            })),
        [isExclusionList, excludedStates, includedStates]
    )

    // Handle change in the input field
    const handleFuelCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value)
        if (!isNaN(value)) {
            setFuelCost(value)
        } else if (event.target.value === '') {
            setFuelCost(null)
        }
    }
    const handleDriverCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value)
        if (!isNaN(value)) {
            setDriverCost(value)
        } else if (event.target.value === '') {
            setDriverCost(null)
        }
    }

    const handleRatePerMileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value)
        if (!isNaN(value)) {
            setRatePerMile(value)
        } else if (event.target.value === '') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setRatePerMile('' as any)
        }
    }

    useEffect(() => {
        const driverCost = Number(application?.temp?.emailSettings?.driverCost) || 0.6
        setDriverCost(driverCost)
    }, [application?.temp?.emailSettings?.driverCost])

    const dispatchEmailSettings = async () => {
        const emailSettings = {
            states: {
                included: includedStates,
                excluded: excludedStates,
                activeList: isExclusionList ? ApplicationSettingsStatesListType.EXCLUDED : ApplicationSettingsStatesListType.INCLUDED,
            },
            mountainArea: isMountainAreaIncluded,
            pricePerGallon: fuelCost,
            ratePerMile,
            driverCost,
        }
        try {
            setIsLoading(true)
            const response = await saveEmailSettings(emailSettings)
            if (response) {
                console.log(response, 'response')
            }
        } catch (error) {
            console.error('Failed to disconnect Telegram:', error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getEmailSettings()

                if (data.status === 200) {
                    const { state, mountainArea, price } = data.data
                    setIncludedStates(state.included)
                    setExcludedStates(state.excluded)
                    setIsMountainAreaIncluded(mountainArea)
                    setFuelCost(price)
                }
            } catch (error) {
                console.error('Failed to fetch settings:', error)
            }
        }

        fetchData()
    }, [])

    const fetchEIAGasPrice = useCallback(async () => {
        const url = 'https://api.eia.gov/v2/petroleum/pri/gnd/data/'
        const params = {
            frequency: 'weekly',
            'data[0]': 'value',
            'sort[0][column]': 'period',
            'sort[0][direction]': 'desc',
            facets: {
                product: ['EPD2D', 'EPD2DM10', 'EPD2DXL0'],
            },
            offset: 0,
            length: 10,
            api_key: 'ezd4pHdaR9ymoqGDtWirUdxyHmH0mYkw5cExDneP',
        }
        try {
            const response = await axios.get(url, { params })
            const updatedData = response.data.response.data.map((item) => {
                return {
                    value: item.value,
                    label: item['area-name'] + ' ' + item['series-description'].split(' No 2')[0],
                }
            })
            setFuelCost(Number(application?.temp?.emailSettings?.pricePerGallon) || updatedData[0].value)
            setPricesList(updatedData)
        } catch (err) {
            console.log(err, 'error fetching EIA Gas Price')
        }
    }, [application?.temp?.emailSettings?.pricePerGallon])

    useEffect(() => {
        fetchEIAGasPrice()
    }, [fetchEIAGasPrice])

    return (
        <Box
            sx={{
                pt: 3,
                pb: 10,
                display: 'grid',
                gridTemplateColumns: {
                    xs: '100%',
                    sm: 'minmax(120px, 30%) 1fr',
                    lg: '280px 1fr minmax(120px, 208px)',
                },
                columnGap: { xs: 2, sm: 3, md: 10 },
                rowGap: { xs: 2, sm: 2.5 },
                '& > hr': {
                    gridColumn: '1/-1',
                },
            }}
        >
            <Box>
                <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>State information</FormLabel>
                <Typography level="body-xs" sx={{ color: 'text.secondary', mt: 1 }}>
                    Choose whether you want to include or exclude specific states from your service area. This helps define where your services are available.
                </Typography>
            </Box>
            <Box sx={{ display: { xs: 'contents', sm: 'flex' }, gap: 2 }}>
                <FormControl sx={{ flex: 0.5 }}>
                    <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                        <FormLabel sx={{ height: '100%', minWidth: 'max-content' }}>I prefer to have</FormLabel>
                        <Select size="sm" defaultValue="exclusion" onChange={(_, value) => setIsExclusionList(value === 'exclusion')} sx={{ minWidth: 170 }}>
                            <Option value="exclusion">excluded states list</Option>
                            <Option value="inclusion">desired states list</Option>
                        </Select>
                    </Stack>

                    <div>
                        <StateSelector
                            initialData={isExclusionList ? excludedStates : includedStates}
                            setStateData={isExclusionList ? setExcludedStates : setIncludedStates}
                            data={statesList}
                            label={isExclusionList ? 'States to exclude' : 'States to include'}
                        />
                    </div>
                </FormControl>
            </Box>
            {/* IN THE FUTURE */}
            {/* <Divider role="presentation" />
            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>Mountain Area</FormLabel>
            <Box sx={{ display: { xs: 'contents', sm: 'flex' }, gap: 2 }}>
                <FormControlLabel
                    control={<Checkbox defaultChecked={isMountainAreaIncluded} onChange={handleCheckboxChange} />}
                    label="Exclude mountain area"
                    sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                />
            </Box> */}
            <Divider role="presentation" />
            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>Select Field Type</FormLabel>
            <Box sx={{ display: { xs: 'contents', sm: 'flex', width: '100%' }, gap: 2 }}>
                <FormControl sx={{ flex: 0.5 }}>
                    <Typography gutterBottom>Set average fuel cost per gallon</Typography>
                    <PriceProgramSelector data={pricesList} initialData={fuelCost ? String(fuelCost) : ''} setStateData={setFuelCost} />
                </FormControl>
            </Box>
            <Divider role="presentation" />
            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>Average Fuel Cost (USD)</FormLabel>
            <Box sx={{ display: { xs: 'contents', sm: 'flex', width: '100%' }, gap: 2 }}>
                <FormControl sx={{ flex: 0.5 }}>
                    <Typography gutterBottom>Fuel cost per gallon</Typography>
                    <Input
                        variant="outlined"
                        defaultValue={fuelCost as number}
                        value={fuelCost as number}
                        onChange={handleFuelCostChange}
                        fullWidth
                        type="number"
                    />
                </FormControl>
            </Box>
            <Divider role="presentation" />
            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>Average Driver Cost (USD)</FormLabel>
            <Box sx={{ display: { xs: 'contents', sm: 'flex', width: '100%' }, gap: 2 }}>
                <FormControl sx={{ flex: 0.5 }}>
                    <Typography gutterBottom>Driver cost per mile </Typography>
                    <Input
                        variant="outlined"
                        defaultValue={driverCost as number}
                        value={driverCost as number}
                        onChange={handleDriverCostChange}
                        fullWidth
                        type="number"
                    />
                </FormControl>
            </Box>
            <Divider role="presentation" />
            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>Set Your Rate (USD)</FormLabel>
            <Box sx={{ display: { xs: 'contents', sm: 'flex', width: '100%' }, gap: 2 }}>
                <FormControl sx={{ flex: 0.5 }}>
                    <Typography gutterBottom>Rate per mile</Typography>
                    <Input
                        variant="outlined"
                        defaultValue={ratePerMile as number}
                        value={ratePerMile as number}
                        onChange={handleRatePerMileChange}
                        fullWidth
                        type="number"
                    />
                </FormControl>
            </Box>
            <Divider role="presentation" />
            <FormLabel sx={{ display: 'flex', alignItems: 'start' }}>Enable Filter events</FormLabel>
            <Box sx={{ display: 'flex', maxWidth: '360px', flexDirection: 'column', gap: 2 }}>
                <FormControlLabel
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    control={<Checkbox onChange={(event: React.ChangeEvent<HTMLInputElement>) => setHandleEmailTrigger(event.target.checked)} />}
                    label="Filter events on this trigger"
                />
                <Box>{handleEmailTrigger ? <FilterEmailTriggerUI /> : null}</Box>
            </Box>

            <Box
                sx={{
                    gridColumn: '1/-1',
                    justifySelf: 'flex-start',
                    display: 'flex',
                    gap: 1,
                }}
            >
                <Button variant="outlined" color="neutral" size="sm">
                    Cancel
                </Button>
                <Button size="sm" onClick={dispatchEmailSettings} loading={isLoading}>
                    Save
                </Button>
            </Box>
        </Box>
    )
}
