import { ClickAwayListener, Popper } from '@mui/base'
import { LocalShipping, Person, LocationOn, Phone, Email } from '@mui/icons-material'
import { Grid, Card, Stack, Box, Typography, IconButton, Chip, Tooltip } from '@mui/joy'
import { EmailRecipient, OngoingLoad } from '@numeo/types'
import { useAuth } from 'hooks/auth'
import { LucidEldSection } from 'pages/dashboard/pages/dispatcher/pages/spot-finder/components/TruckInfoPopper/LucidEldSection'
import SamsaraSection from 'pages/dashboard/pages/dispatcher/pages/spot-finder/components/TruckInfoPopper/SamsaraSection'
import { TTELDSection } from 'pages/dashboard/pages/dispatcher/pages/spot-finder/components/TruckInfoPopper/TTELDSection'
import { useState, useRef } from 'react'
import { SendEmailPopover } from '../SendEmailPopover'

interface TruckColumnProps {
    load: OngoingLoad
}

export const TruckColumn = ({ load }: TruckColumnProps) => {
    const { application } = useAuth()

    const assignedTruck = application?.temp.spotFinder?.trucks.find((t) => t._id === load.truckId)

    const [eldMapOpen, setEldMapOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const locationButtonRef = useRef<HTMLButtonElement | null>(null)

    return (
        <>
            <Grid xs={12} md={4}>
                <Card variant="outlined" sx={{ p: 1.5, height: '100%' }}>
                    <Stack spacing={1.5}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <LocalShipping />
                            <Typography level="title-sm">Truck Information</Typography>
                        </Box>

                        {assignedTruck ? (
                            <>
                                <Stack direction="row" spacing={3} alignItems="center">
                                    <Typography level="body-sm" fontWeight="md" color="primary">
                                        {assignedTruck.mainInfo}
                                    </Typography>
                                    <Typography level="body-xs" sx={{ color: 'text.secondary' }}>
                                        Truck ID: {assignedTruck.truckId}
                                    </Typography>
                                </Stack>

                                {(assignedTruck.firstDriver?.name || assignedTruck.firstDriver?.phone) && (
                                    <>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                                                <Person fontSize="small" />
                                                <Typography level="title-sm">Driver</Typography>
                                            </Box>
                                            {assignedTruck.firstDriver?.name && <Typography level="body-sm">{assignedTruck.firstDriver.name}</Typography>}
                                            {assignedTruck.firstDriver?.phone && (
                                                <Chip size="sm" variant="soft" color="success" startDecorator={<Phone />}>
                                                    {assignedTruck.firstDriver.phone}
                                                </Chip>
                                            )}
                                        </Stack>
                                        {assignedTruck.firstDriver?.driverEmail && (
                                            <>
                                                <Tooltip title={`Click to send email to ${assignedTruck.firstDriver.driverEmail}`}>
                                                    <Chip
                                                        size="md"
                                                        variant="soft"
                                                        color="success"
                                                        startDecorator={<Email />}
                                                        onClick={(event) => {
                                                            event.stopPropagation()

                                                            if (anchorEl) {
                                                                event.currentTarget.blur()
                                                                setTimeout(() => setAnchorEl(null), 0)
                                                            } else {
                                                                setAnchorEl(event.currentTarget)
                                                            }
                                                        }}
                                                    >
                                                        {assignedTruck.firstDriver.driverEmail}
                                                    </Chip>
                                                </Tooltip>
                                                <SendEmailPopover
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl)}
                                                    setAnchorEl={setAnchorEl}
                                                    toWho={EmailRecipient.DRIVER}
                                                    load={load}
                                                />
                                            </>
                                        )}
                                    </>
                                )}

                                {(assignedTruck.lucidEldNumber || assignedTruck.tteldVin || assignedTruck.samsaraId) && (
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography level="body-xs" sx={{ color: 'text.secondary' }}>
                                            Current Location:
                                        </Typography>
                                        <IconButton
                                            ref={locationButtonRef}
                                            variant="soft"
                                            color="primary"
                                            size="sm"
                                            onClick={() => setEldMapOpen(!eldMapOpen)}
                                            sx={{ mt: 1 }}
                                        >
                                            <LocationOn />
                                        </IconButton>
                                    </Stack>
                                )}
                            </>
                        ) : (
                            <Typography level="body-sm" sx={{ color: 'text.secondary' }}>
                                No truck assigned
                            </Typography>
                        )}
                    </Stack>
                </Card>
            </Grid>

            {/* ELD Map Popper */}
            <Popper
                open={eldMapOpen}
                anchorEl={locationButtonRef.current}
                placement="bottom-start"
                style={{
                    zIndex: 1000,
                    maxWidth: 400,
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    borderRadius: '8px',
                }}
            >
                <ClickAwayListener
                    onClickAway={() => {
                        setEldMapOpen(false)
                    }}
                >
                    <Box
                        onMouseLeave={() => setEldMapOpen(false)}
                        sx={{
                            p: 2,
                            bgcolor: 'background.surface',
                            borderRadius: '8px',
                            border: '1px solid',
                            borderColor: 'divider',
                        }}
                    >
                        {assignedTruck?.lucidEldNumber ? (
                            <LucidEldSection eldNumber={assignedTruck.lucidEldNumber} isOpen={eldMapOpen} />
                        ) : assignedTruck?.tteldVin ? (
                            <TTELDSection vin={assignedTruck.tteldVin} isOpen={eldMapOpen} />
                        ) : assignedTruck?.samsaraId ? (
                            <SamsaraSection vehicleId={assignedTruck.samsaraId} isOpen={eldMapOpen} />
                        ) : null}
                    </Box>
                </ClickAwayListener>
            </Popper>
        </>
    )
}
