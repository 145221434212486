import React from 'react'
import { Box, Card, Chip, Table, Typography, Stack } from '@mui/joy'
import { IBroker } from '@numeo/types'

interface BrokerRateConfDetailsProps {
    broker: IBroker
}

/**
 * Component to display rate confirmation details for a specific broker
 */
const BrokerRateConfDetails: React.FC<BrokerRateConfDetailsProps> = ({ broker }) => {
    // Format currency
    const formatCurrency = (amount: number): string => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        }).format(amount)
    }

    // Calculate rate per mile
    const calculateRatePerMile = (distance: number, rate: number): string => {
        if (!distance || distance === 0) return 'N/A'
        const ratePerMile = rate / distance
        return formatCurrency(ratePerMile)
    }

    // If no rate confirmations, show a message
    if (!broker.rateConfs || broker.rateConfs.length === 0) {
        return (
            <Card variant="outlined" sx={{ p: 2, overflow: 'hidden' }}>
                <Typography level="body-sm">No rate confirmations found for this broker.</Typography>
            </Card>
        )
    }

    return (
        <Card variant="outlined" sx={{ p: 0, overflow: 'hidden' }}>
            <Box sx={{ p: 2, borderBottom: '1px solid', borderColor: 'divider' }}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography level="body-md">Rate Confirmations</Typography>
                    <Chip size="sm" color="primary">
                        {broker.rateConfs.length}
                    </Chip>
                </Stack>
            </Box>
            <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
                <Table sx={{ '& thead th': { position: 'sticky', top: 0, bgcolor: 'background.surface' } }}>
                    <thead>
                        <tr>
                            <th>Document ID</th>
                            <th>Rate</th>
                            <th>Distance</th>
                            <th>Rate Per Mile</th>
                        </tr>
                    </thead>
                    <tbody>
                        {broker.rateConfs.map((rateConf, index) => {
                            const ratePerMile = calculateRatePerMile(rateConf.totalDistance, rateConf.rate)

                            return (
                                <tr key={`${index}-${rateConf.documentId}`}>
                                    <td>{rateConf.documentId || 'N/A'}</td>
                                    <td>{formatCurrency(rateConf.rate)}</td>
                                    <td>{rateConf.totalDistance ? `${rateConf.totalDistance} mi` : 'N/A'}</td>
                                    <td>{ratePerMile}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Box>
        </Card>
    )
}

export default BrokerRateConfDetails
