import { Box, Stack, Typography, Divider } from '@mui/joy'
import { Truck } from '@numeo/types'

// Info Section
export const InfoSection = ({ truckData }: { truckData: Truck }) => {
    return (
        <Box minWidth={300}>
            <Stack spacing={1}>
                <Typography level="title-sm">Truck Basic Info</Typography>
                {/* Basic Info */}
                <Stack spacing={1}>
                    <Typography level="title-sm" sx={{ color: 'primary.500' }}>
                        Basic Info
                    </Typography>
                    <Stack spacing={3} direction={'row'}>
                        <Stack spacing={0.5}>
                            <Typography level="body-xs" textColor="text.secondary">
                                Truck Name
                            </Typography>
                            <Typography level="body-sm">{truckData?.mainInfo}</Typography>
                        </Stack>
                        <Stack spacing={0.5}>
                            <Typography level="body-xs" textColor="text.secondary">
                                Plate #
                            </Typography>
                            <Typography level="body-sm">{truckData?.truckId}</Typography>
                        </Stack>
                        {truckData?.length && (
                            <Stack spacing={0.5}>
                                <Typography level="body-xs" textColor="text.secondary">
                                    Length
                                </Typography>
                                <Typography level="body-sm">{`${truckData.length} ft`}</Typography>
                            </Stack>
                        )}
                        {truckData?.weight && (
                            <Stack spacing={0.5}>
                                <Typography level="body-xs" textColor="text.secondary">
                                    Weight
                                </Typography>
                                <Typography level="body-sm">{`${truckData.weight} lbs`}</Typography>
                            </Stack>
                        )}
                    </Stack>
                </Stack>

                <Divider />

                {/* Equipment & Load */}
                <Stack spacing={1}>
                    <Typography level="title-sm" sx={{ color: 'primary.500' }}>
                        Equipment & Load
                    </Typography>
                    <Stack spacing={3} direction={'row'}>
                        {truckData?.equipment?.values?.length > 0 && (
                            <Stack spacing={0.5}>
                                <Typography level="body-xs" textColor="text.secondary">
                                    Equipment
                                </Typography>
                                <Typography level="body-sm">{truckData.equipment.values.join(', ')}</Typography>
                            </Stack>
                        )}
                        {truckData?.fullPartial && (
                            <Stack spacing={0.5}>
                                <Typography level="body-xs" textColor="text.secondary">
                                    Load Type
                                </Typography>
                                <Typography level="body-sm">{truckData.fullPartial}</Typography>
                            </Stack>
                        )}
                    </Stack>
                </Stack>

                {/* First Driver */}
                {truckData?.firstDriver?.name && (
                    <>
                        <Divider />
                        <Stack spacing={1}>
                            <Typography level="title-sm" sx={{ color: 'primary.500' }}>
                                First Driver
                            </Typography>
                            <Stack spacing={3} direction={'row'}>
                                <Stack spacing={0.5}>
                                    <Typography level="body-xs" textColor="text.secondary">
                                        Name
                                    </Typography>
                                    <Typography level="body-sm">{truckData.firstDriver.name}</Typography>
                                </Stack>
                                <Stack spacing={0.5}>
                                    <Typography level="body-xs" textColor="text.secondary">
                                        Phone
                                    </Typography>
                                    <Typography level="body-sm">{truckData.firstDriver.phone}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </>
                )}

                {/* Second Driver */}
                {truckData?.secondDriver?.name && (
                    <>
                        <Divider />
                        <Stack spacing={1}>
                            <Typography level="title-sm" sx={{ color: 'primary.500' }}>
                                Second Driver
                            </Typography>
                            <Stack spacing={3} direction={'row'}>
                                <Stack spacing={0.5}>
                                    <Typography level="body-xs" textColor="text.secondary">
                                        Name
                                    </Typography>
                                    <Typography level="body-sm">{truckData.secondDriver.name}</Typography>
                                </Stack>
                                <Stack spacing={0.5}>
                                    <Typography level="body-xs" textColor="text.secondary">
                                        Phone
                                    </Typography>
                                    <Typography level="body-sm">{truckData.secondDriver.phone}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </>
                )}
            </Stack>
        </Box>
    )
}
