import { SearchBrokerResult } from '../broker-analytics'

// Base destination interface with discriminator
interface BaseDestination {
    destinationType: 'open' | 'city-state' | 'states'
}

// Open destination type
interface OpenDestination extends BaseDestination {
    destinationType: 'open'
}

// City-state destination type
interface CityStateDestination extends BaseDestination {
    destinationType: 'city-state'
    city: string
    stateProv: string
    country?: string
    postalCode?: string
    longitude: number
    latitude: number
}

// States destination type
interface StatesDestination extends BaseDestination {
    destinationType: 'states'
    states: string[]
}

export enum EquipmentTypes {
    CLASSES = 'classes',
    TYPES = 'types',
}

export interface Equipment {
    type: EquipmentTypes.CLASSES | EquipmentTypes.TYPES
    values: string[]
}

export interface EmailParsedDetails {
    referenceId: string
    equipment: string
    pickupLocation: string
    pickupDateTime: string
    deliveryLocation: string
    deliveryDateTime: string
    commodity: string
    weight: string
    temperature: number | null
    specialNotes: string
}

export type SearchParams = {
    origin: {
        city: string
        stateProv: string
        postalCode?: string
        longitude: number
        latitude: number
    }
    destination: {
        destinationType: 'open' | 'city-state' | 'states'
        states?: string[]
        city?: string
        stateProv?: string
        postalCode?: string
        longitude?: number
        latitude?: number
    }
    earliestAvailability: string
    latestAvailability: string
    loadType: string
    equipment: Equipment
    maximumLengthFeet?: number
    maximumWeightPounds?: number
    maxOriginDeadheadMiles: number
    maxDestinationDeadheadMiles: number
}

export interface ClientLoadModel {
    _id: string
    externalId: string
    expanded?: boolean
    origin: {
        city: string
        stateProv: string
        country: string
        longitude: number
        latitude: number
        postalCode: string
    }
    destination: {
        city: string
        stateProv: string
        country: string
        longitude: number
        latitude: number
        postalCode: string
    }
    rateInfo?: {
        basis: string
        rateUsd: number
    }
    earliestAvailability: string
    latestAvailability: string
    comments?: string
    originDeadheadMiles: {
        method: string
        miles: number
    }
    destinationDeadheadMiles: {
        method: string
        miles: number
    }
    tripLength: number
    posterInfo: {
        contact: {
            phoneNumber?: string
            email?: string
        }
        companyName: string
        city: string
        carrierHomeState: string
        preferredContactMethod: string
        mcNumber: string
        credit: {
            asOf: string
            creditScore: number
            daysToPay: number
        }
    }
    shipmentDetails: {
        type: string
        fullPartial: string
        maximumLengthFeet: number
        maximumWeightPounds: number
    }
    equipmentType: string
    postedAt: string
    status: string
    isFactorable: boolean
    createdAt?: Date
    updatedAt?: Date
    source?: string
    type?: string
    bookingUrl?: string | null
    postersReferenceId?: string | null

    isEmailSent?: boolean
    emailHistory?: {
        status: string
        offeringRate: number
        details: EmailParsedDetails
        warnings: string[]
        unreadCount: number
    }
    email?: {
        draft: string
    }
    // FIXME: now only 1 call history, later will be an array of calls
    // We only deliver callId's as a ref to the call in our DB
    // Actual call history must be downloaded on demand client app when needed
    callHistory?: {
        callId: string
    }[]
    emailThreadId?: string
    isFavorite?: boolean
}

export interface IMessageTypes {
    role: string
    createdAt: number
    content: string | ClientLoadModel[]
    threadId: string
    id: string
}

export interface PromptSuggestion {
    id: number
    suggestion: string
}

export interface Document {
    id: number
    name: string
}

export interface CityStateOption {
    label: string
    id: number
}

export interface AllLoadsResponse {
    [key: string]: {
        searchParams: SearchParams
        loads: ClientLoadModel[]
        createdAt: string
    }
}

/**
 * State of the load finder. Used to communicate via Websocket
 */
export interface LoadFindResultState {
    loadSearchId: string
    truckId: string
    // key would be external load id
    loads: Record<string, ClientLoadModel> // We are making it a record of loadId to load to make it easier to access
    brokers: SearchBrokerResult[]
    shouldNotify: boolean
    searchStatus: SearchStatus
    createdAt: Date
    searchParams: SearchParams
}

export enum SearchStatus {
    // Initialized  but no result is received
    searchInitialized = 'searchInitialized',
    // A result received and search is still running
    searchStarted = 'searchStarted',
    // Search is finished
    searchFinished = 'searchFinished',
}
