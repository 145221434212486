import { Box, Typography, Select, Option, FormControl, FormLabel, IconButton } from '@mui/joy'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

interface TablePaginationProps {
    total: number
    page: number
    setPage: (page: number) => void
    rowsPerPage: number
    setRowsPerPage: (rowsPerPage: number) => void
}

export const TablePagination = ({ total, page, setPage, rowsPerPage, setRowsPerPage }: TablePaginationProps) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'flex-end', p: 2 }}>
            <FormControl orientation="horizontal" size="sm">
                <FormLabel>Rows per page:</FormLabel>
                <Select
                    value={rowsPerPage}
                    onChange={(_, value) => {
                        setRowsPerPage(Number(value))
                        setPage(1) // Reset to first page when changing rows per page
                    }}
                >
                    <Option value={5}>5</Option>
                    <Option value={10}>10</Option>
                    <Option value={25}>25</Option>
                    <Option value={50}>50</Option>
                </Select>
            </FormControl>

            <Typography>{`${(page - 1) * rowsPerPage + 1}-${Math.min(page * rowsPerPage, total)} of ${total}`}</Typography>

            <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton size="sm" variant="outlined" disabled={page === 1} onClick={() => setPage(page - 1)}>
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton size="sm" variant="outlined" disabled={page * rowsPerPage >= total} onClick={() => setPage(page + 1)}>
                    <KeyboardArrowRight />
                </IconButton>
            </Box>
        </Box>
    )
}
