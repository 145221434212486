/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Chip, Tooltip, Typography } from '@mui/joy'
import { EmailParticipant } from '@numeo/types'

interface EmailHeaderProps {
    subject: string
    from: EmailParticipant
    to: EmailParticipant
    isReply?: boolean
}

export default function EmailDetails({ subject, from, to, isReply }: EmailHeaderProps) {
    return (
        <Box
            sx={{
                pb: 2,
                pt: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
            }}
        >
            <Typography level={isReply ? 'title-sm' : 'title-lg'} textColor="text.primary">
                {subject}
            </Typography>
            {!isReply && (
                <Box
                    sx={{
                        mt: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        flexWrap: 'wrap',
                    }}
                >
                    <div>
                        <Typography component="span" level="body-sm" sx={{ mr: 1, display: 'inline-block' }}>
                            From
                        </Typography>
                        <Tooltip size="sm" title="Copy email" variant="outlined">
                            <Chip size="sm" variant="soft" color="primary">
                                {from.email}
                            </Chip>
                        </Tooltip>
                    </div>
                    <div>
                        <Typography component="span" level="body-sm" sx={{ mr: 1, display: 'inline-block' }}>
                            to
                        </Typography>
                        <Tooltip size="sm" title="Copy email" variant="outlined">
                            <Chip size="sm" variant="soft" color="primary">
                                {to?.email ? to.email : 'Unknown (Draft)'}
                            </Chip>
                        </Tooltip>
                    </div>
                </Box>
            )}
        </Box>
    )
}
