/* eslint-disable @typescript-eslint/no-explicit-any */
import { LocalShipping } from '@mui/icons-material'
import { Box, Card, Typography } from '@mui/joy'
import { Email, EmailProcessingResult, OrderSchema } from '@numeo/types'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useState } from 'react'
import OrderAccordion from './OrderAccordion'

dayjs.extend(duration)

interface EmailDetailProps {
    execution: { email: Email; processingResult: EmailProcessingResult }
    refetch: () => Promise<void>
}

export default function EmailDetail({ execution, refetch }: EmailDetailProps) {
    const [expandedOrder, setExpandedOrder] = useState<string>((execution?.processingResult?.orders?.[0] as any)?._id || '')

    const handleAccordionChange = (orderId: string) => {
        setExpandedOrder(expandedOrder === orderId ? '' : orderId)
    }

    if (!execution?.processingResult?.orders?.length) return null

    return (
        <Card variant="outlined" sx={{ p: 3, height: '100%', minWidth: 350, overflow: 'auto' }}>
            <Typography level="title-lg" startDecorator={<LocalShipping />} sx={{ mb: 2 }}>
                Orders ({execution.processingResult?.orders?.length})
            </Typography>

            <Box>
                {execution.processingResult?.orders?.map((order: OrderSchema) => (
                    <OrderAccordion
                        key={(order as any)._id}
                        order={order}
                        processingResultId={execution.processingResult?._id}
                        isExpanded={expandedOrder === (order as any)._id}
                        onOpenChange={handleAccordionChange}
                        refetch={refetch}
                    />
                ))}
            </Box>
        </Card>
    )
}
