import { Box, Button, CircularProgress, Modal, ModalClose, ModalDialog, Typography, Stepper, Step, StepIndicator } from '@mui/joy'
import { Truck, Equipment } from '@numeo/types'
import React, { useEffect, useRef } from 'react'
import BasicInformation, { TruckBasicInformation } from './truck-forms/BasicInformation'
import DriverInformation, { TruckDriverInfo } from './truck-forms/DriverInformation'
import Preferences, { TruckPreferences } from './truck-forms/Preferences'
import PermitsRestrictions from './truck-forms/PermitsRestrictions'
import { FormikProps } from 'formik'
import { getEmptyTruckData } from './TruckHorizontalList'

export interface TruckModalProps {
    open: boolean
    onClose: () => void
    isEditing: boolean
    truckDataTemp: Truck
    setTruckDataTemp: React.Dispatch<React.SetStateAction<Truck>>
    onSave: () => void
    loading: boolean
}

const TruckModal: React.FC<TruckModalProps> = ({ open, onClose, isEditing, truckDataTemp, setTruckDataTemp, onSave, loading }) => {
    const [currentStep, setCurrentStep] = React.useState(1)
    const formRefs = {
        basicInfo: useRef<FormikProps<TruckBasicInformation>>({} as FormikProps<TruckBasicInformation>),
        driverInfo: useRef<FormikProps<TruckDriverInfo>>({} as FormikProps<TruckDriverInfo>),
        routeOperation: useRef<FormikProps<TruckPreferences>>({} as FormikProps<TruckPreferences>),
    }

    useEffect(() => {
        if (open && !isEditing) {
            setTruckDataTemp(getEmptyTruckData() as Truck)
            setCurrentStep(1)
        } else {
            setCurrentStep(1)
        }
    }, [open, isEditing, setTruckDataTemp])

    const validateCurrentStep = async () => {
        // Skip validation for PermitsRestrictions step since it only contains boolean fields
        if (currentStep === 4) {
            return true
        }

        let isValid = false

        switch (currentStep) {
            case 1:
                await formRefs.basicInfo.current.validateForm()
                isValid = Object.keys(formRefs.basicInfo.current.errors).length === 0
                break
            case 2:
                await formRefs.driverInfo.current.validateForm()
                isValid = Object.keys(formRefs.driverInfo.current.errors).length === 0
                break
            case 3:
                await formRefs.routeOperation.current.validateForm()
                isValid = Object.keys(formRefs.routeOperation.current.errors).length === 0
                break
        }

        return isValid
    }

    const handleNext = async () => {
        const isValid = await validateCurrentStep()
        if (isValid) {
            setCurrentStep(currentStep + 1)
        }
    }

    const handleBack = () => {
        setCurrentStep(currentStep - 1)
    }

    const handleSave = async () => {
        const isValid = await validateCurrentStep()
        if (isValid) {
            onSave()
        }
    }

    const stepIndicatorColor = (step: number) => {
        if (step === currentStep) {
            return 'primary'
        }
    }

    const stepIndicatorVariant = (step: number) => {
        if (step === currentStep) {
            return 'solid'
        } else {
            return 'outlined'
        }
    }

    const handleTruckUpdate = (field: string, value: string | number | null | boolean | Equipment | string[]) => {
        setTruckDataTemp((prev) => {
            const newData = JSON.parse(JSON.stringify(prev)) as Truck
            const fields = field.split('.')
            let current = newData

            for (let i = 0; i < fields.length - 1; i++) {
                if (!current[fields[i]]) {
                    current[fields[i]] = {}
                }
                current = current[fields[i]]
            }
            current[fields[fields.length - 1]] = value

            return newData
        })
    }

    const renderForms = () => {
        switch (currentStep) {
            case 1:
                return <BasicInformation truckData={truckDataTemp} onUpdate={handleTruckUpdate} formRef={formRefs.basicInfo} />
            case 2:
                return <DriverInformation truckData={truckDataTemp} onUpdate={handleTruckUpdate} formRef={formRefs.driverInfo} />
            case 3:
                return <Preferences truckData={truckDataTemp} onUpdate={handleTruckUpdate} formRef={formRefs.routeOperation} />
            case 4:
                return <PermitsRestrictions truckData={truckDataTemp} onUpdate={handleTruckUpdate} />
            default:
                return null
        }
    }

    const handleStepClick = async (step: number) => {
        const isValid = await validateCurrentStep()
        if (isValid) {
            setCurrentStep(step)
        }
    }

    return (
        <Modal
            open={open}
            onClose={(e: React.MouseEvent, reason: string) => {
                e.stopPropagation()
                if (reason === 'backdropClick') return
                onClose()
            }}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <ModalDialog
                sx={{
                    maxWidth: 900,
                    width: '100%',
                    overflow: 'auto',
                }}
            >
                <ModalClose />
                <Typography level="h4" component="h1">
                    {isEditing ? 'Edit  a Truck' : 'Add a New Truck'}
                </Typography>
                <Box sx={{ width: '100%', my: 2 }}>
                    <Stepper>
                        <Step
                            indicator={
                                <StepIndicator variant={stepIndicatorVariant(1)} color={stepIndicatorColor(1)}>
                                    1
                                </StepIndicator>
                            }
                            onClick={() => {
                                handleStepClick(1)
                            }}
                            sx={{ cursor: 'pointer' }}
                        >
                            Basic Information
                        </Step>
                        <Step
                            indicator={
                                <StepIndicator variant={stepIndicatorVariant(2)} color={stepIndicatorColor(2)}>
                                    2
                                </StepIndicator>
                            }
                            onClick={() => handleStepClick(2)}
                            sx={{ cursor: 'pointer' }}
                        >
                            Driver Information
                        </Step>
                        <Step
                            indicator={
                                <StepIndicator variant={stepIndicatorVariant(3)} color={stepIndicatorColor(3)}>
                                    3
                                </StepIndicator>
                            }
                            onClick={() => handleStepClick(3)}
                            sx={{ cursor: 'pointer' }}
                        >
                            Preferences {/* Route Operations  */}
                        </Step>
                        <Step
                            indicator={
                                <StepIndicator variant={stepIndicatorVariant(4)} color={stepIndicatorColor(4)}>
                                    4
                                </StepIndicator>
                            }
                            onClick={() => handleStepClick(4)}
                            sx={{ cursor: 'pointer' }}
                        >
                            Permits & Restrictions
                        </Step>
                    </Stepper>
                </Box>

                {renderForms()}

                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', mt: 2 }}>
                    {currentStep !== 1 && (
                        <Button variant="outlined" color="neutral" onClick={handleBack} sx={{ position: 'relative' }}>
                            Back
                        </Button>
                    )}
                    {currentStep < 4 ? (
                        <Button onClick={handleNext} sx={{ justifyContent: 'flex-end' }}>
                            Next
                        </Button>
                    ) : (
                        <Button
                            onClick={handleSave}
                            loading={loading}
                            loadingPosition="end"
                            endDecorator={loading ? <CircularProgress variant="solid" /> : null}
                        >
                            {isEditing ? 'Save Changes' : 'Add Truck'}
                        </Button>
                    )}
                </Box>
            </ModalDialog>
        </Modal>
    )
}

export default TruckModal
