import { Box, Divider, Sheet } from '@mui/joy'
import { ClientLoadModel } from '@numeo/types'
import React, { useEffect, useState } from 'react'
import { ringCentralApiService } from './RingCentral.api'
import { useDispatch } from 'react-redux'
import { deleteNotification } from '../../../../../../../../../store/notificationSlice'
import { removeRingCentralCallSession } from '../../../../../../../../../store/callCenterSlice'
import DTMFKeypad from './DTMFKeypad'
import { RingCentralCallSession } from 'types'
import CallInfo from './SidebarComponents/CallInfo'
import CallControls from './SidebarComponents/CallControls'
import LoadDetails from './SidebarComponents/LoadDetails'

interface TranscriptSidebarProps {
    open: boolean
    close: () => void
    load: ClientLoadModel
    callSession: RingCentralCallSession
    notificationId: string
}

export const RingCentralCallDetailsSidebar: React.FC<TranscriptSidebarProps> = ({ open, close, load, callSession, notificationId }) => {
    const dispatch = useDispatch()
    // Get call duration from Redux store instead of local state
    const [callDuration, setCallDuration] = useState(0)
    // Track mute and hold states
    const [isMuted, setIsMuted] = useState(false)
    const [isOnHold, setIsOnHold] = useState(false)

    useEffect(() => {
        let timer: NodeJS.Timeout
        // Only count duration when call is answered AND not on hold
        if (callSession?.state === 'answered') {
            timer = setInterval(() => {
                // Update the call duration in local state
                setCallDuration((prev) => prev + 1)
            }, 1000)
        }
        return () => {
            if (timer) clearInterval(timer)
        }
    }, [callSession?.state])

    // Handle call status change to "disposed"
    useEffect(() => {
        if (callSession?.state === 'disposed') {
            // Ensure notification is removed when call is disposed
            dispatch(deleteNotification(notificationId))
            // Remove call from Redux store
            dispatch(removeRingCentralCallSession())
            // Reset mute and hold states when call ends
            setIsMuted(false)
            setIsOnHold(false)
        }
    }, [callSession?.state, dispatch, notificationId])

    const handleToggleMute = () => {
        // Don't check for answered state here, let the button's disabled prop handle that
        try {
            if (isMuted) {
                if (typeof callSession.unmute === 'function') {
                    callSession.unmute()
                    console.log('Call unmuted')
                }
                // Always update state regardless of function availability
                setIsMuted(false)
            } else {
                if (typeof callSession.mute === 'function') {
                    callSession.mute()
                    console.log('Call muted')
                }
                // Always update state regardless of function availability
                setIsMuted(true)
            }
        } catch (error) {
            console.error('Error toggling mute:', error)
        }
    }

    const handleToggleHold = () => {
        // Don't check for answered state here, let the button's disabled prop handle that
        try {
            if (isOnHold) {
                if (typeof callSession.unhold === 'function') {
                    callSession.unhold()
                    console.log('Call resumed from hold')
                }
                // Always update state regardless of function availability
                setIsOnHold(false)
            } else {
                if (typeof callSession.hold === 'function') {
                    callSession.hold()
                    console.log('Call placed on hold')
                }
                // Always update state regardless of function availability
                setIsOnHold(true)
            }
        } catch (error) {
            console.error('Error toggling hold:', error)
        }
    }

    if (!open || !callSession) {
        return null
    }

    const handleHangup = () => {
        // Add hangup logic here
        if (callSession && typeof callSession.hangup === 'function') {
            callSession.hangup()

            // Update call status to "ended" in main-service
            if (callSession.callId) {
                ringCentralApiService.updateCallStatus(callSession.callId)
                // Also remove from Redux store
                dispatch(removeRingCentralCallSession())
            }

            // Immediately remove the notification
            dispatch(deleteNotification(notificationId))

            // Close the sidebar
            close()
        }
    }

    return (
        <>
            {/* Overlay to handle clicks outside the sidebar */}
            {open && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 999,
                        cursor: 'pointer',
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    }}
                    onClick={close}
                />
            )}
            <Sheet
                sx={{
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: '600px', // Increased width to accommodate load details
                    bgcolor: 'background.surface',
                    borderLeft: '1px solid',
                    boxShadow: 'sm',
                    borderColor: 'divider',
                    p: 2,
                    zIndex: 1000,
                    overflowY: 'auto',
                    animation: 'slideIn 0.3s ease-out',
                    '@keyframes slideIn': {
                        from: { transform: 'translateX(100%)' },
                        to: { transform: 'translateX(0)' },
                    },
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%', maxWidth: '100%', mt: 1 }}>
                    <Box sx={{ width: '100%', maxWidth: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', mt: 1 }}>
                        {/* Call Information Section */}
                        <CallInfo callSession={callSession} callDuration={callDuration} onClose={close} />
                        {/* Call Controls Section */}
                        <CallControls
                            callSession={callSession}
                            isMuted={isMuted}
                            isOnHold={isOnHold}
                            onToggleMute={handleToggleMute}
                            onToggleHold={handleToggleHold}
                            onHangup={handleHangup}
                        />
                    </Box>
                    {/* DTMF Keypad Section */}
                    {callSession.state === 'answered' && (
                        <Box sx={{ mt: 2 }}>
                            <DTMFKeypad callSession={callSession} />
                        </Box>
                    )}
                </Box>
                <Divider sx={{ my: 2 }} />
                {/* Load Details Section */}
                <LoadDetails load={load} />

                <Divider sx={{ my: 2 }} />
            </Sheet>
        </>
    )
}
