import React, { useCallback, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/joy'
import { LoadFindResultState, SearchStatus, Truck } from '@numeo/types'
import { useGetQuery } from 'hooks/useGetQuery'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store'
import NewLoadsTable from './LoadsTable/NewLoadsTable'
import { TableStatusOverlay } from './LoadsTable/TableStatusOverlay'
import SearchBar from './SearchBar'
import TruckHorizontalList from './TruckHorizontalList'
import { useAuth } from 'hooks/auth'
import { IntegrationsAlert } from './IntegrationsAlert'

// If currentSearch is not initialized, we need to show progress bar saying that we are looking for loads
// If there is currentSearch and it is valid, we need to show the table

const TruckManagementUI: React.FC = () => {
    const { application } = useAuth()
    const truckId = useGetQuery('truckId')
    const { lastSearchResults } = useSelector((state: RootState) => state.spotFinder)
    const [localSearchResults, setLocalSearchResults] = useState<Record<string, LoadFindResultState>>({})
    const [trucks, setTrucks] = useState<Truck[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const getTrucks = useCallback(async () => {
        try {
            setIsLoading(true)
            const applicationTrucks = application?.temp.spotFinder?.trucks || []
            if (applicationTrucks) {
                const sortedTrucks = [...applicationTrucks].sort((a, b) => {
                    // Handle cases where createdAt might be undefined
                    const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0)
                    const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0)
                    return dateB.getTime() - dateA.getTime()
                })
                setTrucks(sortedTrucks)
            }
        } catch (error) {
            console.error('Error fetching trucks:', error)
        } finally {
            setIsLoading(false)
        }
    }, [application?.temp.spotFinder?.trucks])

    useEffect(() => {
        setLocalSearchResults(lastSearchResults)
    }, [lastSearchResults])

    useEffect(() => {
        getTrucks()
    }, [getTrucks])

    const showIntegrationsAlert = application && !application.integrations.dat
    const showTable = truckId && lastSearchResults[truckId] && Object.values(lastSearchResults[truckId].loads).length > 0

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{
                height: '100vh',
                width: '100%',
                overflow: 'hidden !important',
            }}
        >
            {showIntegrationsAlert && <IntegrationsAlert />}
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    height: '100%',
                    width: '100%',
                    padding: 1,
                }}
            >
                <TruckHorizontalList
                    onTrucksRefresh={(updatedTrucks: Truck[]) => {
                        // Book keep the latest refresh trucks list
                        // Update the current truck window includng searchBar and search results
                        setTrucks(updatedTrucks)
                    }}
                    initialTrucks={trucks}
                    isLoading={isLoading}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            zIndex: 0,
                        }}
                    >
                        <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'start' }}>
                            {truckId ? (
                                <>
                                    <SearchBar
                                        data={lastSearchResults[truckId]}
                                        truck={trucks.find((truck) => truck._id === truckId)!}
                                        searchStatus={localSearchResults[truckId!]?.searchStatus}
                                        searchReduxStatus={lastSearchResults[truckId!]?.searchStatus}
                                        setSearchStatus={(searchStatus: SearchStatus) => {
                                            setLocalSearchResults({
                                                ...localSearchResults,
                                                [truckId]: {
                                                    ...localSearchResults[truckId],
                                                    searchStatus,
                                                },
                                            })
                                        }}
                                    />
                                    {showTable ? (
                                        <NewLoadsTable searchData={lastSearchResults[truckId]} />
                                    ) : (
                                        <TableStatusOverlay searchData={lastSearchResults[truckId]} searchStatus={localSearchResults[truckId!]?.searchStatus} />
                                    )}
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                    }}
                                >
                                    <Typography level="h3" color="neutral">
                                        Please select a truck to view available loads
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default TruckManagementUI
