import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel'
import Input from '@mui/joy/Input'
import Typography from '@mui/joy/Typography'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import Alert from '@mui/joy/Alert'
import CircularProgress from '@mui/joy/CircularProgress'
import { useNavigate } from 'react-router-dom'
import { Link } from '@mui/joy'
import { AxiosError } from 'axios'
import { FormField } from 'components/common'
import { PasswordField } from '../../components'
import { RegisterFormData, signUpValidationSchema } from './types'
import { useAuth } from 'hooks/auth'
import { PrivacyPolicyModal } from '../../components/PrivacyPolicyModal'

export default function RegisterPage() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)
    const { signup, verifyAuth } = useAuth()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RegisterFormData>({
        resolver: yupResolver(signUpValidationSchema),
        mode: 'onChange',
        defaultValues: {},
    })

    const onSubmit = async (formData: RegisterFormData) => {
        setIsLoading(true)
        setError(null)

        try {
            // Extract just what we need for signup
            const { ...signupData } = formData

            // formValues now matches our SignUpData interface without mcNumber
            await signup(signupData)
            await verifyAuth().then(() => {
                // Navigate to onboarding page instead of dashboard after successful signup
                navigate('/auth/onboarding')
            })
        } catch (err) {
            setError(
                err instanceof AxiosError ? err.response?.data?.message || 'Failed to sign in' : 'Failed to create account. Email might already be in use.'
            )
        }

        setIsLoading(false)
    }

    return (
        <Box
            component="main"
            sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 400,
                maxWidth: '100%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                },
                [`& .${formLabelClasses.asterisk}`]: {
                    visibility: 'hidden',
                },
            }}
        >
            <div>
                <Typography component="h1" fontSize="xl2" fontWeight="lg">
                    Create an account
                </Typography>
                <Typography level="body-sm" sx={{ my: 1, mb: 3 }}>
                    Please enter your details
                </Typography>
            </div>

            {error && (
                <Alert color="danger" variant="soft">
                    {error}
                </Alert>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
                <FormField error={errors.projectName}>
                    <FormLabel>Project name</FormLabel>
                    <Input {...register('projectName')} />
                </FormField>
                <FormField error={errors.email}>
                    <FormLabel>Email</FormLabel>
                    <Input {...register('email')} type="email" name="email" autoComplete="new-email" />
                </FormField>

                <PasswordField registration={register('password')} formError={errors.password} autoComplete="new-password" />

                <PasswordField registration={register('confirm')} formError={errors.confirm} label="Confirm Password" autoComplete="new-password" />

                <Typography level="body-sm" sx={{ color: 'neutral.500', mb: 2 }}>
                    By signing up, you agree to our{' '}
                    <Link href="/auth/terms" sx={{ fontWeight: 500 }}>
                        Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link href="https://www.numeo.ai/privacy-policy" target="_blank" sx={{ fontWeight: 500 }}>
                        Privacy Policy
                    </Link>
                </Typography>

                <Button type="submit" fullWidth disabled={isLoading}>
                    {isLoading ? <CircularProgress size="sm" /> : 'Sign up'}
                </Button>
            </form>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Link fontSize="sm" href="/auth/sign-in" fontWeight="lg">
                    Already have an account? Sign in
                </Link>
            </Box>

            <PrivacyPolicyModal open={showPrivacyPolicy} onClose={() => setShowPrivacyPolicy(false)} />
        </Box>
    )
}
