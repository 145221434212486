import { Box, Card, LinearProgress, Typography, CssBaseline, GlobalStyles, Grid, Container, CssVarsProvider, styled } from '@mui/joy'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useAuth } from 'hooks/auth'
import CompanyInfoStep from './components/CompanyInfoStep'
import DatIntegrationStep from './components/DatIntegrationStep'
import { CompanyInfoFormData, DatCredentialsFormData } from './types'

enum Step {
    COMPANY_INFO = 0,
    DAT_INTEGRATION = 1,
}

// Styled components for image effects - hover effect moved to card level
const ImageContainer = styled(Box)(() => ({
    position: 'relative',
    flex: '1 0 50%',
    display: 'flex',
    minHeight: 0,
    overflow: 'hidden',
    '& img': {
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center top',
        display: 'block',
        flexGrow: 1,
        filter: 'blur(2px)',
        transition: 'all 0.3s ease',
        transformOrigin: 'center',
    },
}))

// Glass effect container for the DAT integration image - hover effect moved to card level
const GlassImageContainer = styled(Box)(() => ({
    position: 'relative',
    flex: '1 0 50%',
    display: 'flex',
    minHeight: 0,
    overflow: 'hidden',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(5px)',
        zIndex: 1,
        opacity: 0.7,
        transition: 'opacity 0.3s ease',
    },
    '& img': {
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center top',
        display: 'block',
        flexGrow: 1,
        transition: 'transform 0.3s ease',
        transformOrigin: 'center',
    },
}))

// Styled component for the page container with blurred background
const PageContainer = styled(Box)(() => ({
    position: 'relative',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    '&::before': {
        content: '""',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: 'url("/dashboard.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        filter: 'blur(5px)',
        opacity: 0.15,
        zIndex: -1,
    },
}))

export default function OnboardingPage() {
    const [currentStep, setCurrentStep] = useState<Step>(Step.COMPANY_INFO)
    const [companyInfo, setCompanyInfo] = useState<CompanyInfoFormData | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [progress, setProgress] = useState(50)
    const navigate = useNavigate()
    const { verifyAuth } = useAuth()

    // Debug function to track state changes
    useEffect(() => {
        updateProgress()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, companyInfo])

    const updateProgress = () => {
        const stepProgress = ((currentStep + 1) / 2) * 100
        setProgress(stepProgress)
    }

    const handleCompanyInfoNext = async (data: CompanyInfoFormData) => {
        // Save company info to state
        setCompanyInfo(data)

        // Move to the next step (DAT integration)
        setCurrentStep(Step.DAT_INTEGRATION)
    }

    const handleDatCredentialsSubmit = async (data: DatCredentialsFormData | null) => {
        if (!data) {
            // User skipped this step
            completeOnboarding(null)
            return
        }

        // Pass the data directly to completeOnboarding instead of relying on state update
        completeOnboarding(data)
    }

    const handleDatCredentialsSkip = () => {
        completeOnboarding(null)
    }

    const completeOnboarding = async (datCreds: DatCredentialsFormData | null = null) => {
        try {
            setIsLoading(true)

            // Use the directly passed credentials if available, otherwise fall back to state
            const credentials = datCreds

            // Only submit onboarding data if we have company info
            if (companyInfo) {
                // Prepare request payload
                const payload = {
                    companyInfo: {
                        companyName: companyInfo.companyName,
                        companyAddress: companyInfo.companyAddress,
                        phoneNumber: companyInfo.phoneNumber,
                        mcNumber: companyInfo.mcNumber,
                        details: companyInfo.details,
                    },
                    // Only include DAT integration if it was provided
                    ...(credentials
                        ? {
                              datIntegration: {
                                  orgUsername: credentials.orgUsername,
                                  orgPassword: credentials.orgPassword,
                                  username: credentials.username,
                              },
                          }
                        : {}),
                }

                // Send complete onboarding data to the new endpoint
                await axios.post('/auth/onboarding', payload)

                // Verify auth to ensure we have latest user data
                await verifyAuth()
            }

            // Redirect to dashboard after completing onboarding
            navigate('/')
        } catch (error) {
            console.error('Failed to save onboarding data:', error)
            // Still redirect to dashboard even if saving fails
            navigate('/')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
            <CssBaseline />
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Transition-duration': '0.4s',
                    },
                    body: {
                        overflow: 'hidden',
                    },
                }}
            />
            <PageContainer>
                <Container maxWidth="lg" sx={{ py: 3, display: 'flex', flexDirection: 'column', position: 'relative', zIndex: 1 }}>
                    <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <img alt="logo" src="/favicon.png" width="24" height="24" />
                            <Typography level="h3">Numeo.AI</Typography>
                        </Box>
                        <Typography level="body-sm" textAlign="right">
                            © Numeo {new Date().getFullYear()}
                        </Typography>
                    </Box>

                    <Typography component="h1" level="h3" sx={{ mb: 2, textAlign: 'center' }}>
                        Create your workspace
                    </Typography>

                    <Box sx={{ mb: 3, bgcolor: '#f5f7fa', p: 1, borderRadius: 8, width: '40%', mx: 'auto' }}>
                        <LinearProgress determinate value={progress} />
                    </Box>

                    <Grid container spacing={2} sx={{ display: 'flex' }}>
                        {/* Left column - Form inputs */}
                        <Grid xs={12} md={6} sx={{ display: 'flex', flex: 1 }}>
                            <Card
                                variant="outlined"
                                sx={{
                                    p: 3,
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {isLoading && <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} />}

                                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    {currentStep === Step.COMPANY_INFO && (
                                        <CompanyInfoStep onNext={handleCompanyInfoNext} initialData={companyInfo || undefined} />
                                    )}

                                    {currentStep === Step.DAT_INTEGRATION && (
                                        <DatIntegrationStep
                                            onBack={() => setCurrentStep(Step.COMPANY_INFO)}
                                            onSubmit={handleDatCredentialsSubmit}
                                            onSkip={handleDatCredentialsSkip}
                                            initialData={undefined}
                                        />
                                    )}
                                </Box>
                            </Card>
                        </Grid>

                        {/* Right column - Preview/Explanation */}
                        <Grid xs={12} md={6} sx={{ display: 'flex', flex: 1 }}>
                            <Card
                                variant="outlined"
                                sx={{
                                    p: 0,
                                    width: '100%',
                                    overflow: 'hidden',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    boxShadow: 'sm',
                                    '&:hover': {
                                        '& .image-container img': {
                                            transform: 'scale(1.05)',
                                            filter: 'blur(0)',
                                        },
                                        '& .glass-container::before': {
                                            opacity: 0.4,
                                        },
                                        '& .glass-container img': {
                                            transform: 'scale(1.05)',
                                        },
                                    },
                                }}
                            >
                                {/* Image section - Top half with fixed height */}
                                {currentStep === Step.COMPANY_INFO ? (
                                    <ImageContainer className="image-container">
                                        <img src="/info.png" alt="Company Setup" />
                                    </ImageContainer>
                                ) : (
                                    <GlassImageContainer className="glass-container">
                                        <img src="/dashboard.png" alt="DAT Integration" />
                                    </GlassImageContainer>
                                )}

                                {/* Title and description - Bottom half with fixed height */}
                                <Box
                                    sx={{
                                        p: 3,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        flex: '1 0 10%',
                                    }}
                                >
                                    <Typography level="title-md" sx={{ mb: 0.5 }}>
                                        {currentStep === Step.COMPANY_INFO ? 'Company Information' : 'DAT Integration'}
                                    </Typography>

                                    <Typography level="body-sm" sx={{ mt: 0.5, mb: 1.5 }}>
                                        {currentStep === Step.COMPANY_INFO
                                            ? 'Set up your company information to get started with Numeo.'
                                            : 'Connect your DAT account to access available loads and enhance your freight management capabilities. This integration allows Numeo to automatically import load data from DAT. This integration is optional and can be set up later if needed.'}
                                    </Typography>

                                    <Typography level="body-xs" sx={{ mb: 1.5 }} color="neutral">
                                        {currentStep === Step.COMPANY_INFO
                                            ? 'All information provided is securely stored and handled according to our privacy policy. You can update these details at any time through your account settings.'
                                            : 'Your DAT credentials are securely encrypted and only used to access information required for integration. You can disable or reconfigure this connection at any time.'}
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </PageContainer>
        </CssVarsProvider>
    )
}
