import { Modal, ModalDialog, Typography, Divider, Box, Button } from '@mui/joy'
import { ModalType } from './LoadActionModal'

interface LoadDeleteConfirmationModalProps {
    modalType: ModalType
    setModalType: (type: ModalType) => void
    selectedLoads: string[]
    loading: boolean
    handleDelete: () => void
}

export const LoadDeleteConfirmationModal = ({ modalType, setModalType, selectedLoads, loading, handleDelete }: LoadDeleteConfirmationModalProps) => {
    return (
        <Modal open={modalType === ModalType.DELETE} onClose={() => setModalType(ModalType.NONE)}>
            <ModalDialog variant="outlined" color="danger" role="alertdialog">
                <Typography level="h4" component="h2">
                    Confirm Deletion
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography>
                    {selectedLoads.length > 0
                        ? `Are you sure you want to delete ${selectedLoads.length} selected loads?`
                        : 'Are you sure you want to delete this load?'}
                </Typography>
                <Typography level="body-sm" color="danger">
                    This action cannot be undone.
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', mt: 2 }}>
                    <Button variant="plain" color="neutral" onClick={() => setModalType(ModalType.NONE)}>
                        Cancel
                    </Button>
                    <Button variant="solid" color="danger" onClick={handleDelete} loading={loading}>
                        Delete
                    </Button>
                </Box>
            </ModalDialog>
        </Modal>
    )
}
