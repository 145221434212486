import { FormControl, FormLabel, Input, Stack, Select, Option, Box, Alert, Switch, Typography } from '@mui/joy'
import { Equipment, Truck } from '@numeo/types'
import { FormikProps, useFormik } from 'formik'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { EquipmentMultiSelect } from '../EquipmentMultiSelect'
import { FormControlLabel } from '@mui/material'
import { useAuth } from 'hooks/auth'
import { WarningRounded } from '@mui/icons-material'

interface BasicInformationProps {
    truckData: Truck
    onUpdate: (field: string, value: string | number | null | boolean | Equipment) => void
    formRef: React.MutableRefObject<FormikProps<TruckBasicInformation>>
}

export interface TruckBasicInformation {
    mainInfo: string
    truckId: string
    equipment: Equipment
    length?: number | string
    weight?: number | string
    fullPartial?: 'BOTH' | 'FULL' | 'PARTIAL'
    tteldVin?: string
    lucidEldVin?: string
    lucidEldNumber?: string
    samsaraId?: string
}

const validationSchema = Yup.object().shape({
    mainInfo: Yup.string().required('Truck name is required'),
    truckId: Yup.string()
        .required('Truck plate number is required')
        .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric characters are allowed'),
    length: Yup.number().nullable(),
    weight: Yup.number().nullable(),
    tteldVin: Yup.string()
        .matches(/^[a-zA-Z0-9]{17}$/, 'VIN must be exactly 17 alphanumeric characters')
        .nullable(),
    lucidEldVin: Yup.string()
        .matches(/^[a-zA-Z0-9]{17}$/, 'VIN must be exactly 17 alphanumeric characters')
        .nullable(),
    lucidEldNumber: Yup.string().matches(/^\d+$/, 'Only numbers are allowed').nullable(),
    samsaraId: Yup.string()
        .matches(/^[0-9\s]+$/, 'Samsara ID must only contain numbers')
        .nullable(),
    equipment: Yup.object()
        .shape({
            type: Yup.string().oneOf(['types', 'classes']).required(),
            values: Yup.array().of(Yup.string()).min(1, 'Equipment is required'),
        })
        .required('Equipment is required'),
})

const BasicInformation: React.FC<BasicInformationProps> = ({ truckData, onUpdate, formRef }) => {
    const { application } = useAuth()

    const [isTteldEnabled, setIsTteldEnabled] = useState<boolean>(false)
    const [isLucidEldEnabled, setIsLucidEldEnabled] = useState<boolean>(false)
    const [isSamsaraEnabled, setIsSamsaraEnabled] = useState<boolean>(false)

    const formik = useFormik<TruckBasicInformation>({
        initialValues: {
            truckId: truckData.truckId || '',
            mainInfo: truckData.mainInfo || '',
            length: truckData.length,
            weight: truckData.weight,
            tteldVin: truckData.tteldVin || '',
            lucidEldVin: truckData.lucidEldVin || '',
            lucidEldNumber: truckData.lucidEldNumber || '',
            samsaraId: truckData.samsaraId || '',
            equipment: truckData.equipment || {
                type: 'types',
                values: [],
            },
        },
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: false,
        onSubmit: () => {},
    })

    useEffect(() => {
        if (!formRef) return
        formRef.current = formik
        if (truckData.tteldVin) {
            setIsTteldEnabled(true)
        }
        if (truckData.lucidEldNumber) {
            setIsLucidEldEnabled(true)
        }
        if (truckData.samsaraId) {
            setIsSamsaraEnabled(true)
        }
    }, [formik, formRef, truckData.tteldVin, truckData.lucidEldNumber, truckData.samsaraId])

    // Set all fields as touched when validateForm is called
    const originalValidateForm = formik.validateForm
    formik.validateForm = async () => {
        formik.setTouched({
            mainInfo: true,
            truckId: true,
            equipment: {
                type: true,
                values: true,
            },
            length: true,
            weight: true,
            tteldVin: isTteldEnabled && true,
            lucidEldVin: isLucidEldEnabled && true,
            lucidEldNumber: isLucidEldEnabled && true,
            samsaraId: isSamsaraEnabled && true,
        })
        return originalValidateForm()
    }

    return (
        <Box
            p={3}
            sx={{
                color: 'text.primary',
                borderRadius: 'md',
            }}
        >
            <Stack spacing={2}>
                <Stack direction={{ sm: 'column', md: 'row' }} gap={2}>
                    <FormControl sx={{ width: '100%' }} error={!!formik.errors.mainInfo}>
                        <FormLabel>Truck Name</FormLabel>
                        <Input
                            value={formik.values.mainInfo}
                            onChange={(e) => {
                                formik.handleChange(e)
                                onUpdate('mainInfo', e.target.value)
                            }}
                            onBlur={formik.handleBlur}
                            name="mainInfo"
                        />
                        {formik.touched.mainInfo && formik.errors.mainInfo && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.mainInfo}</Box>
                        )}
                    </FormControl>

                    <FormControl sx={{ width: '100%' }} error={!!formik.errors.truckId}>
                        <FormLabel>Truck Plate Number</FormLabel>
                        <Input
                            value={formik.values.truckId}
                            onChange={(e) => {
                                formik.handleChange(e)
                                onUpdate('truckId', e.target.value)
                            }}
                            onBlur={formik.handleBlur}
                            name="truckId"
                        />
                        {formik.touched.truckId && formik.errors.truckId && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.truckId}</Box>
                        )}
                    </FormControl>
                </Stack>

                <Stack direction={{ sm: 'column', md: 'row' }} gap={2}>
                    <FormControl sx={{ width: '100%' }} error={!!formik.errors.length}>
                        <FormLabel>Length (ft)</FormLabel>
                        <Input
                            type="number"
                            value={formik.values.length}
                            onChange={(e) => {
                                formik.handleChange(e)
                                onUpdate('length', Number(e.target.value))
                            }}
                            onBlur={formik.handleBlur}
                            name="length"
                        />
                        {formik.touched.length && formik.errors.length && <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.length}</Box>}
                    </FormControl>

                    <FormControl sx={{ width: '100%' }} error={!!formik.errors.weight}>
                        <FormLabel>Weight (lbs)</FormLabel>
                        <Input
                            type="number"
                            value={formik.values.weight}
                            onChange={(e) => {
                                formik.handleChange(e)
                                onUpdate('weight', Number(e.target.value))
                            }}
                            onBlur={formik.handleBlur}
                            name="weight"
                        />
                        {formik.touched.weight && formik.errors.weight && <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.weight}</Box>}
                    </FormControl>
                </Stack>

                <Stack direction={{ sm: 'column', md: 'row' }} gap={2}>
                    <FormControl sx={{ width: '100%', maxWidth: 'calc(50% - 10px)' }} error={!!formik.errors.equipment}>
                        <FormLabel>Equipment</FormLabel>
                        <EquipmentMultiSelect formik={formik} onChange={(value) => onUpdate('equipment', value)} />
                        {formik.touched.equipment && formik.errors.equipment && (
                            <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>Equipment is a required field</Box>
                        )}
                    </FormControl>
                    <FormControl sx={{ width: '100%' }}>
                        <FormLabel>Full/Partial Load</FormLabel>
                        <Select value={truckData.fullPartial || 'BOTH'} onChange={(_, value) => onUpdate('fullPartial', value)}>
                            <Option value="BOTH">BOTH</Option>
                            <Option value="FULL">FULL</Option>
                            <Option value="PARTIAL">PARTIAL</Option>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction="row" spacing={2} alignItems={'center'}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isTteldEnabled}
                                onChange={(e) => {
                                    // Only allow enabling if integration is connected
                                    if (e.target.checked && !application?.integrations?.tteld) {
                                        return // Prevent enabling
                                    }
                                    setIsTteldEnabled(e.target.checked)
                                    onUpdate('tteldVin', '') // Reset VIN when toggling
                                }}
                                sx={{ mr: 1 }}
                                disabled={!application?.integrations?.tteld && !isTteldEnabled}
                            />
                        }
                        label="TT ELD"
                        sx={{ width: '15%' }}
                    />
                    {isTteldEnabled && (
                        <>
                            <FormControl sx={{ width: '85%' }} error={!!formik.errors.tteldVin}>
                                <Input
                                    value={formik.values.tteldVin}
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        onUpdate('tteldVin', e.target.value)
                                    }}
                                    onBlur={formik.handleBlur}
                                    placeholder="Enter TT ELD VIN"
                                    name="tteldVin"
                                />
                                {formik.touched.tteldVin && formik.errors.tteldVin && (
                                    <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.tteldVin}</Box>
                                )}
                            </FormControl>
                        </>
                    )}
                    {!application?.integrations?.tteld && (
                        <Box sx={{ width: isTteldEnabled ? '100%' : '85%', ml: isTteldEnabled ? 0 : 0 }}>
                            <Alert variant="soft" color="warning" startDecorator={<WarningRounded />} sx={{ width: '100%' }}>
                                <Typography level="body-sm">TT ELD integration is not connected. Please connect it in Integrations Settings.</Typography>
                            </Alert>
                        </Box>
                    )}
                </Stack>
                <Stack direction="row" spacing={2} alignItems={'center'}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isLucidEldEnabled}
                                onChange={(e) => {
                                    // Only allow enabling if integration is connected
                                    if (e.target.checked && !application?.integrations?.lucidEld) {
                                        return // Prevent enabling
                                    }
                                    setIsLucidEldEnabled(e.target.checked)
                                    onUpdate('lucidEldVin', '')
                                    onUpdate('lucidEldNumber', '')
                                }}
                                sx={{ mr: 1 }}
                                disabled={!application?.integrations?.lucidEld && !isLucidEldEnabled}
                            />
                        }
                        label="Lucid ELD"
                        sx={{ width: '15%' }}
                    />
                    {isLucidEldEnabled && (
                        <>
                            <FormControl sx={{ width: '42%' }} error={!!formik.errors.lucidEldVin}>
                                <Input
                                    value={formik.values.lucidEldVin}
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        onUpdate('lucidEldVin', e.target.value)
                                    }}
                                    onBlur={formik.handleBlur}
                                    placeholder="Enter Lucid ELD VIN"
                                    name="lucidEldVin"
                                />
                                {formik.touched.lucidEldVin && formik.errors.lucidEldVin && (
                                    <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.lucidEldVin}</Box>
                                )}
                            </FormControl>
                            <FormControl sx={{ width: '42%' }} error={!!formik.errors.lucidEldNumber}>
                                <Input
                                    value={formik.values.lucidEldNumber}
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        onUpdate('lucidEldNumber', e.target.value)
                                    }}
                                    onBlur={formik.handleBlur}
                                    placeholder="Enter Lucid ELD Vehicle Number"
                                    name="lucidEldNumber"
                                />
                                {formik.touched.lucidEldNumber && formik.errors.lucidEldNumber && (
                                    <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.lucidEldNumber}</Box>
                                )}
                            </FormControl>
                        </>
                    )}
                    {!application?.integrations?.lucidEld && (
                        <Box sx={{ width: isLucidEldEnabled ? '100%' : '85%', ml: isLucidEldEnabled ? 0 : 0 }}>
                            <Alert variant="soft" color="warning" startDecorator={<WarningRounded />} sx={{ width: '100%' }}>
                                <Typography level="body-sm">Lucid ELD integration is not connected. Please connect it in Integrations Settings.</Typography>
                            </Alert>
                        </Box>
                    )}
                </Stack>
                <Stack direction="row" spacing={2} alignItems={'center'}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isSamsaraEnabled}
                                onChange={(e) => {
                                    // Only allow enabling if integration is connected
                                    if (e.target.checked && !application?.integrations?.samsara) {
                                        return // Prevent enabling
                                    }
                                    setIsSamsaraEnabled(e.target.checked)
                                    onUpdate('samsaraId', '')
                                }}
                                sx={{ mr: 1 }}
                                disabled={!application?.integrations?.samsara && !isSamsaraEnabled}
                            />
                        }
                        label="Samsara"
                        sx={{ width: '15%' }}
                    />
                    {isSamsaraEnabled && (
                        <>
                            <FormControl sx={{ width: '100%' }} error={!!formik.errors.samsaraId}>
                                <Input
                                    value={formik.values.samsaraId}
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                        onUpdate('samsaraId', e.target.value)
                                    }}
                                    onBlur={formik.handleBlur}
                                    placeholder="Enter Samsara ID"
                                    name="samsaraId"
                                />
                                {formik.touched.samsaraId && formik.errors.samsaraId && (
                                    <Box sx={{ color: 'red', mt: 0.5, fontSize: '0.75rem' }}>{formik.errors.samsaraId}</Box>
                                )}
                            </FormControl>
                        </>
                    )}
                    {!application?.integrations?.samsara && (
                        <Box sx={{ width: isSamsaraEnabled ? '100%' : '85%', ml: isSamsaraEnabled ? 0 : 0 }}>
                            <Alert variant="soft" color="warning" startDecorator={<WarningRounded />} sx={{ width: '100%' }}>
                                <Typography level="body-sm">Samsara integration is not connected. Please connect it in Integrations Settings.</Typography>
                            </Alert>
                        </Box>
                    )}
                </Stack>
            </Stack>
        </Box>
    )
}

export default BasicInformation
