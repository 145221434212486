import axios from 'axios'

/**
 * Get scheduler settings from the server
 * @param projectName The project name to get scheduler settings for
 * @returns The scheduler settings (time in minutes)
 */
export const getSchedulerSettings = async (projectName: string) => {
    try {
        const response = await axios.get(`/settings/scheduler?projectName=${projectName}`)
        return response.data
    } catch (error) {
        console.error('Error fetching scheduler settings:', error)
        throw error
    }
}

/**
 * Update scheduler settings on the server
 * @param scheduler The scheduler time in minutes
 * @returns The response from the server
 */
export const updateSchedulerSettings = async (scheduler: number) => {
    try {
        const response = await axios.put('/settings/scheduler', { scheduler })
        return response
    } catch (error) {
        console.error('Error updating scheduler settings:', error)
        throw error
    }
}
