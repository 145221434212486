import { Box, Button, FormLabel, Input, Stack, Typography } from '@mui/joy'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DatCredentialsFormData, datCredentialsValidationSchema } from '../types'
import FormField from './FormField'

interface DatIntegrationStepProps {
    onBack: () => void
    onSubmit: (data: DatCredentialsFormData | null) => void
    onSkip: () => void
    initialData?: Partial<DatCredentialsFormData>
}

const DatIntegrationStep = ({ onBack, onSubmit, onSkip, initialData = {} }: DatIntegrationStepProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DatCredentialsFormData>({
        resolver: yupResolver(datCredentialsValidationSchema),
        mode: 'onChange',
        defaultValues: {
            orgUsername: initialData.orgUsername || '',
            orgPassword: initialData.orgPassword || '',
            username: initialData.username || '',
        },
    })

    const onFormSubmit = (data: DatCredentialsFormData) => {
        // Create a clean form data object that matches our DatCredentialsFormData interface
        const formData: DatCredentialsFormData = {
            orgUsername: data.orgUsername.trim(),
            orgPassword: data.orgPassword.trim(),
            username: data.username.trim(),
        }

        // Only submit if all fields have values
        if (formData.orgUsername && formData.orgPassword && formData.username) {
            onSubmit(formData)
        } else {
            console.error('DAT credentials incomplete, some fields are empty')
            // Submit anyway, letting the parent component handle validation
            onSubmit(formData)
        }
    }

    return (
        <Box sx={{ width: '100%', mx: 'auto', height: '100%', display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%' }}>
                <Typography component="h2" fontSize="xl2" fontWeight="lg" mb={3}>
                    DAT Integration (Optional)
                </Typography>

                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <Stack spacing={3}>
                        <FormField error={errors.orgUsername}>
                            <FormLabel>Service Account Username</FormLabel>
                            <Input {...register('orgUsername')} placeholder="Enter your service account username" />
                        </FormField>

                        <FormField error={errors.orgPassword}>
                            <FormLabel>Service Account Password</FormLabel>
                            <Input {...register('orgPassword')} type="password" placeholder="Enter your service account password" />
                        </FormField>

                        <FormField error={errors.username}>
                            <FormLabel>DAT Username</FormLabel>
                            <Input {...register('username')} placeholder="Enter your DAT username" />
                        </FormField>

                        <Box sx={{ mt: 4 }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" color="neutral" onClick={onBack} sx={{ flex: 1 }}>
                                    Back
                                </Button>
                                <Button variant="solid" color="primary" type="submit" sx={{ flex: 1 }}>
                                    Complete
                                </Button>
                            </Stack>

                            <Button variant="plain" color="neutral" onClick={onSkip} sx={{ mt: 1, width: '100%' }}>
                                Skip for now
                            </Button>
                        </Box>
                    </Stack>
                </form>
            </Box>
        </Box>
    )
}

export default DatIntegrationStep
