import { Business, Email, Phone } from '@mui/icons-material'
import { Grid, Card, Stack, Box, Typography, Chip, Tooltip } from '@mui/joy'
import { EmailRecipient, OngoingLoad } from '@numeo/types'
import { SendEmailPopover } from '../SendEmailPopover'
import { useState } from 'react'

interface BrokerColumnProps {
    load: OngoingLoad
    formatUpdatedAt: (dateString: string | Date) => string
}

export const BrokerColumn = ({ load, formatUpdatedAt }: BrokerColumnProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    return (
        <Grid xs={12} md={4}>
            <Card variant="outlined" sx={{ p: 1.5, height: '100%' }}>
                <Stack spacing={1.5}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Business />
                        <Typography level="title-sm">Broker Information</Typography>
                    </Box>

                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography level="body-md" fontWeight="md">
                            {load.companyName}
                        </Typography>

                        <Stack direction="row" spacing={1}>
                            <Chip size="sm" variant="soft">
                                MC# {load.mcNumber}
                            </Chip>
                            {load.dotNumber && (
                                <Chip size="sm" variant="soft">
                                    DOT# {load.dotNumber}
                                </Chip>
                            )}
                        </Stack>
                    </Stack>

                    <Stack direction="row" spacing={1} alignItems="center">
                        {load.contact?.email && (
                            <>
                                <Tooltip title={`Click to send email to ${load.contact.email}`}>
                                    <Chip
                                        size="md"
                                        variant="soft"
                                        color="primary"
                                        startDecorator={<Email />}
                                        onClick={(event) => {
                                            event.stopPropagation()

                                            if (anchorEl) {
                                                event.currentTarget.blur()
                                                setTimeout(() => setAnchorEl(null), 0)
                                            } else {
                                                setAnchorEl(event.currentTarget)
                                            }
                                        }}
                                    >
                                        {load.contact.email}
                                    </Chip>
                                </Tooltip>
                                <SendEmailPopover
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    setAnchorEl={setAnchorEl}
                                    toWho={EmailRecipient.BROKER}
                                    load={load}
                                />
                            </>
                        )}

                        {load.contact?.phone && (
                            <Chip size="md" variant="soft" color="success" startDecorator={<Phone />}>
                                {load.contact.phone}
                            </Chip>
                        )}
                    </Stack>

                    {load.lastBrokerUpdated && (
                        <Typography level="body-xs" sx={{ mt: 1, color: 'text.tertiary' }}>
                            Last Broker Updated: {formatUpdatedAt(load.lastBrokerUpdated)}
                        </Typography>
                    )}
                </Stack>
            </Card>
        </Grid>
    )
}
