import { RingVolume } from '@mui/icons-material'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Call, ClientLoadModel } from '@numeo/types'

export enum CallButtonStatus {
    NoPhoneCallMade,
    MakingCall,
    CallInProgress,
    AllCallsEnded,
}

export enum CallType {
    AI = 'AI',
    RingCentral = 'RC',
}

export const bounceRotate = keyframes`
  0%, 100% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(20deg);
  }
`

export const AnimatedRingVolume = styled(RingVolume)`
    animation: ${bounceRotate} 1s ease-in-out infinite;
    transform-origin: center;
`

export interface CallButtonProps {
    load: ClientLoadModel
    disabled?: boolean
    disableAICall?: boolean
    loadSearchId: string
    phoneNumber: string
    favorite: (e?: React.MouseEvent) => void
}

export const callButtonState = (load: ClientLoadModel, calls: Map<string, Call>, requestingCall: Call | null, loading: boolean): CallButtonStatus => {
    if (loading) {
        return CallButtonStatus.MakingCall
    }

    // Try to find the active call. If any of the calls from call history is active, return 'Calling'
    for (const call of load.callHistory ?? []) {
        if (call.callId === calls.get(call.callId)?.callId) {
            return CallButtonStatus.CallInProgress
        }
    }
    // Check if there is an call request and that call has succseed
    if (requestingCall && calls.get(requestingCall.callId)) {
        return CallButtonStatus.CallInProgress
    }

    // if the last call is ongoing, return 'Calling'
    if (!load.callHistory || load.callHistory.length === 0) {
        return CallButtonStatus.NoPhoneCallMade
    } else {
        return CallButtonStatus.AllCallsEnded
    }
}
