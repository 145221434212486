import React, { useRef, useEffect, useCallback } from 'react'
import DOMPurify from 'dompurify'
import { useColorScheme } from '@mui/joy'
import { HTML_TEMPLATE, SANITIZE_OPTIONS } from 'pages/dashboard/pages/dispatcher/pages/dispatcher-emails/components/EmailContent/components/EmailCard/const'
import { EmailAttachment } from '@numeo/types'
import axios from 'axios'

interface EmailBodyProps {
    body: string
    subject?: string
    height?: string | number
    width?: string | number
    setupIframeContent?: (iframe: HTMLIFrameElement, body: string) => void
    attachments?: EmailAttachment[]
    messageId: string
}

/**
 * A reusable component for displaying email body content in an iframe
 */
const EmailBody: React.FC<EmailBodyProps> = ({
    body,
    subject,
    height = '100%',
    width = '100%',
    setupIframeContent: externalSetupIframeContent,
    attachments = [],
    messageId,
}) => {
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const { mode } = useColorScheme()

    const internalSetupIframeContent = useCallback(
        (iframe: HTMLIFrameElement, content: string) => {
            if (!iframe) return

            const doc = iframe.contentDocument || iframe.contentWindow?.document
            if (!doc) return

            // Clean the content first
            let cleanContent = DOMPurify.sanitize(content, SANITIZE_OPTIONS)

            // Handle embedded images with cid: references
            if (attachments && attachments.length > 0) {
                // First, create a temporary DOM element to parse the HTML
                const tempDiv = document.createElement('div')
                tempDiv.innerHTML = cleanContent

                // Find all images with cid: sources
                const images = tempDiv.querySelectorAll('img[src^="cid:"]')

                // Replace each cid: source with the corresponding attachment URL
                images.forEach((img) => {
                    const cidSrc = img.getAttribute('src')
                    if (cidSrc) {
                        // Extract the content ID from the src
                        const contentId = cidSrc.replace('cid:', '')

                        // Find matching attachment - try different matching strategies
                        const attachment = attachments.find((att) => {
                            // Try exact match first
                            if (att.content_id === contentId) return true

                            // Try without angle brackets
                            const cleanContentId = att.content_id?.replace(/[<>]/g, '')
                            if (cleanContentId === contentId) return true

                            // Try with content_id that might contain the contentId
                            return att.content_id?.includes(contentId)
                        })

                        if (attachment && attachment.is_inline) {
                            // Set a placeholder src that will be loaded via API
                            const attachmentUrl = `/api/email/attachment?attachmentId=${attachment.id}&messageId=${messageId}&grantId=${attachment.grant_id}`
                            img.setAttribute('src', attachmentUrl)
                            img.setAttribute('data-attachment-id', attachment.id)
                        }
                    }
                })

                // Get the updated HTML with replaced cid: references
                cleanContent = tempDiv.innerHTML
            }

            doc.open()
            doc.write(HTML_TEMPLATE(cleanContent, mode))
            doc.close()

            // Make external links open in new tab
            doc.querySelectorAll('a').forEach((link) => {
                if (link.host !== window.location.host) {
                    link.setAttribute('target', '_blank')
                    link.setAttribute('rel', 'noopener noreferrer')
                }
            })

            // Load attachment images after the document is ready
            if (attachments && attachments.length > 0) {
                doc.querySelectorAll('img[data-attachment-id]').forEach(async (img) => {
                    const attachmentId = img.getAttribute('data-attachment-id')
                    if (attachmentId) {
                        const attachment = attachments.find((att) => att.id === attachmentId)
                        if (attachment) {
                            try {
                                const response = await axios.get(`/email/attachment`, {
                                    params: {
                                        attachmentId: attachment.id,
                                        messageId: messageId,
                                        grantId: attachment.grant_id,
                                    },
                                })

                                if (response.data) {
                                    if (response.data.dataUrl) {
                                        img.setAttribute('src', response.data.dataUrl)
                                    } else {
                                        console.error('No data URL in response')
                                    }
                                } else {
                                    console.error('No data in response')
                                }
                            } catch (error) {
                                console.error('Error loading attachment:', error)
                            }
                        }
                    }
                })
            }

            return doc.body
        },
        [mode, attachments, messageId]
    )

    useEffect(() => {
        if (!iframeRef.current || !body) return

        const iframe = iframeRef.current

        // Use external setup if provided, otherwise use internal
        const iframeBody = externalSetupIframeContent ? externalSetupIframeContent(iframe, body) : internalSetupIframeContent(iframe, body)

        if (!iframeBody) return

        // Handle iframe resizing with debouncing
        let rafId: number
        const resizeObserver = new ResizeObserver((entries) => {
            // Cancel any pending animation frame
            if (rafId) {
                cancelAnimationFrame(rafId)
            }

            // Schedule a new resize update
            rafId = requestAnimationFrame(() => {
                if (!iframe.isConnected) return

                const contentHeight = Math.ceil(entries[0].contentRect.height)
                if (contentHeight > 0) {
                    // Only adjust height if it's set to 'auto'
                    if (height === 'auto') {
                        iframe.style.height = `${contentHeight + 40}px`
                    }
                }
            })
        })

        resizeObserver.observe(iframeBody)

        return () => {
            resizeObserver.disconnect()
            if (rafId) {
                cancelAnimationFrame(rafId)
            }
        }
    }, [body, height, externalSetupIframeContent, internalSetupIframeContent])

    return (
        <iframe
            ref={iframeRef}
            sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
            style={{
                width: width,
                border: 'none',
                height: height,
            }}
            title={`Email: ${subject || 'No subject'}`}
        />
    )
}

export default EmailBody
