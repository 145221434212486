import { Box } from '@mui/joy'
import { useTheme } from '@mui/joy/styles'
import { useEffect, useRef } from 'react'
import { Wrapper } from '@googlemaps/react-wrapper'
import { LocalShipping } from '@mui/icons-material'

interface ELDMapProps {
    latitude: string
    longitude: string
}

// Extremely simplified approach to avoid TypeScript errors
const MapComponent = ({ latitude, longitude }: ELDMapProps) => {
    const theme = useTheme()
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!ref.current || !window.google) return

        // Get access to Google Maps API
        // eslint-disable-next-line
        const google = window.google as any

        // Parse coordinates
        const lat = parseFloat(latitude)
        const lng = parseFloat(longitude)

        // Define map options first
        const mapOptions = {
            center: { lat, lng },
            zoom: 5,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
            colorScheme: theme.palette.mode === 'dark' ? 'DARK' : 'LIGHT',
        }

        // Create map
        const map = new google.maps.Map(ref.current, mapOptions)

        // Define marker options
        const markerOptions = {
            position: { lat, lng },
            map: map,
            icon: {
                path: 0, // Simple circle
                fillColor: '#1976d2',
                fillOpacity: 1,
                scale: 8,
                strokeColor: '#ffffff',
                strokeWeight: 2,
            },
            title: 'Truck Location',
        }

        // Create marker
        new google.maps.Marker(markerOptions)

        // Define map styles
        const mapStyles = [
            {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [{ visibility: 'on' }],
            },
        ]

        // Apply styles
        map.setOptions({ styles: mapStyles })
        // eslint-disable-next-line
    }, [latitude, longitude])

    return <div ref={ref} style={{ width: '100%', height: '100%' }} />
}

// Error component to display when the map fails to load
const MapError = () => (
    <Box
        sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'background.level1',
            color: 'text.secondary',
            flexDirection: 'column',
            gap: 1,
        }}
    >
        <LocalShipping sx={{ fontSize: 24 }} />
        <Box>Map unavailable</Box>
    </Box>
)

export const ELDMap = (props: ELDMapProps) => {
    return (
        <Box sx={{ position: 'relative', width: '100%', height: 200, overflow: 'hidden', borderRadius: 'sm' }}>
            <Wrapper
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
                render={(status) => {
                    return status === 'SUCCESS' ? <MapComponent {...props} /> : <MapError />
                }}
            />
        </Box>
    )
}
