export enum SockerIoDisconnectReason {
    // The server has forcefully disconnected the socket with socket.disconnect()	❌ NO
    FORCE_DISCONNECT = 'io server disconnect',
    // The server did not send a PING within the pingInterval + pingTimeout range	✅ YES
    PING_TIMEOUT = 'ping timeout',
    // The connection was closed (example: the user has lost connection, or the network was changed from WiFi to 4G)	✅ YES
    TRANSPORT_CLOSE = 'transport close',
    // The connection has encountered an error (example: the server was killed during a HTTP long-polling cycle)	✅ YES
    TRANSPORT_ERROR = 'transport error',
}
