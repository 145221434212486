import { IndexedDBStorage } from '../storage/IndexedDBStorage'

const STORAGE_CONFIG = {
    dbName: 'LocationSearchDB',
    storeName: 'locations',
    version: 1,
}

// ================= Data types =================
export enum LocationType {
    CITY = 'city',
    STATE = 'state',
}
export interface CityLocationData {
    id: string
    stateCode: string
    stateName: string
    type: LocationType
    city: string
    county: string
    latitude: number
    longitude: number
}

export interface StateLocationData {
    id: string
    stateCode: string
    stateName: string
    type: LocationType
}

const storage = new IndexedDBStorage(STORAGE_CONFIG)

interface TrieNode {
    children: Map<string, TrieNode>
    isEndOfWord: boolean
    word?: string
}

class RadixTree {
    private root: TrieNode

    constructor() {
        this.root = { children: new Map(), isEndOfWord: false }
    }

    insert(word: string): void {
        let current = this.root
        const normalizedWord = word.toLowerCase()

        for (const char of normalizedWord) {
            if (!current.children.has(char)) {
                current.children.set(char, {
                    children: new Map(),
                    isEndOfWord: false,
                })
            }
            current = current.children.get(char)!
        }
        current.isEndOfWord = true
        current.word = word
    }

    findWordsWithPrefix(prefix: string): string[] {
        const words: string[] = []
        let current = this.root
        const normalizedPrefix = prefix.toLowerCase()

        // Navigate to the node representing the prefix
        for (const char of normalizedPrefix) {
            if (!current.children.has(char)) {
                return words
            }
            current = current.children.get(char)!
        }

        // Collect all words from this node
        this.collectWords(current, words)
        return words
    }

    private collectWords(node: TrieNode, words: string[]): void {
        if (node.isEndOfWord && node.word) {
            words.push(node.word)
        }

        for (const [, child] of node.children) {
            this.collectWords(child, words)
        }
    }

    size(): number {
        let count = 0
        const traverse = (node: TrieNode) => {
            if (node.isEndOfWord) count++
            for (const child of node.children.values()) {
                traverse(child)
            }
        }
        traverse(this.root)
        return count
    }
}

interface SearchMetrics {
    query: string
    resultCount: number
    durationMs: number
    timestamp: number
}

const METRICS_KEY = 'searchMetrics'
const MAX_METRICS = 100

/**
 * The search order and techniques used in the LocationDataSearchV2 class are as follows:
 * Search Order
 * Prefix Search:
 * - Attribute: city (and potentially county if included in the search logic).
 * - Technique: Utilizes a Radix Tree for efficient prefix matching. This allows quick lookups for locations starting with a given string.
 * N-Gram Search:
 * - Attribute: city (and potentially county).
 * - Technique: Uses N-gram indexing, which breaks down the city names into smaller substrings (n-grams) to facilitate substring searches. This is particularly useful for partial matches.
 * Inverted Index Search:
 * - Attribute: stateCode and potentially county.
 * - Technique: An inverted index is used to map each unique term (like state codes) to the locations that contain them, allowing for fast lookups based on exact matches.
 * Summary of Techniques
 * - Radix Tree: For efficient prefix matching to quickly find locations that start with a specified string.
 * - N-Gram Indexing: To support substring searches, allowing for more flexible matching against city names.
 * - Inverted Index: To enable quick lookups based on exact matches to state codes or other attributes.
 * This structured approach allows the search functionality to be both efficient and flexible, catering to various user input scenarios. If you have further questions or need additional details, feel free to ask!
 */
class LocationDataSearchV2 {
    // Core data structures - Space: O(n) where n = number of locations
    private invertedIndex: Map<string, Set<string>>
    private radixTree: RadixTree
    private ngramIndex: Map<string, Set<string>>
    private locations: Map<string, CityLocationData>
    private readonly NGRAM_SIZE = 3
    private statesMap: Map<string, StateLocationData> = new Map()

    constructor() {
        this.invertedIndex = new Map()
        this.radixTree = new RadixTree()
        this.ngramIndex = new Map()
        this.locations = new Map()
        this.loadCSVData()
        this.initialize()
    }

    // Refactored initialize method
    public async initialize(): Promise<void> {
        console.log('Initialization started')
        const savedData = await storage.get<string>('locationIndex')

        if (savedData) {
            this.deserialize(savedData)
        } else {
            const locations = await this.loadLocations()
            console.log('Loaded locations:', locations.length)

            locations.forEach((location) => {
                this.addLocation(location)

                const stateCode = location.stateCode.toUpperCase()
                if (!this.statesMap.has(stateCode)) {
                    this.statesMap.set(stateCode, {
                        id: `state-${stateCode}`, // Create a unique ID for the state
                        stateCode: stateCode,
                        stateName: location.stateName,
                        type: LocationType.STATE,
                    })
                }
            })
        }
        // for (const state of this.statesMap.values()) {
        //     const stateCode = state.stateCode.toUpperCase()
        //     if (!this.statesMap.has(stateCode)) {
        //         this.statesMap.set(stateCode, {
        //             id: state.id,
        //             stateCode: state.stateCode,
        //             stateName: state.stateName,
        //             type: LocationType.STATE,
        //         })
        //     }
        // }
        console.log('Index counts:', {
            invertedIndex: this.invertedIndex.size,
            radixTree: this.radixTree.size(),
            ngramIndex: this.ngramIndex.size,
        })
    }

    // Time: O(1) amortized hash table lookups
    private getLocationString(location: CityLocationData): string {
        return `${location.city.toLowerCase()} ${location.stateCode.toLowerCase()}`
    }

    private generateNgrams(text: string): string[] {
        const normalized = text.toLowerCase()
        const ngrams: string[] = []
        for (let i = 0; i <= normalized.length - this.NGRAM_SIZE; i++) {
            ngrams.push(normalized.slice(i, i + this.NGRAM_SIZE))
        }
        return ngrams
    }

    private tokenize(text: string): string[] {
        return text
            .toLowerCase()
            .split(/[\s,.-]+/)
            .filter((word) => word.length > 0)
    }

    // Time: O(k) where k = query length (radix tree traversal)
    // Space: O(1) - constant intermediate storage
    private searchPrefix(query: string): Set<string> {
        const prefixMatches = this.radixTree.findWordsWithPrefix(query.toLowerCase())
        const results = new Set<string>()
        prefixMatches.forEach((word) => {
            const locationIds = this.invertedIndex.get(word.toLowerCase())
            if (locationIds) {
                locationIds.forEach((id) => results.add(id))
            }
        })
        return results
    }

    // Time: O(m) where m = number of n-grams in query
    // Space: O(p) where p = number of potential matches
    private searchNgrams(query: string): Set<string> {
        const queryNgrams = this.generateNgrams(query.toLowerCase())
        const ngramMatches = new Set<string>()
        let isFirst = true

        for (const ngram of queryNgrams) {
            const locationIds = this.ngramIndex.get(ngram)
            if (locationIds) {
                if (isFirst) {
                    locationIds.forEach((id) => ngramMatches.add(id))
                    isFirst = false
                } else {
                    // Intersect with previous matches
                    for (const id of ngramMatches) {
                        if (!locationIds.has(id)) {
                            ngramMatches.delete(id)
                        }
                    }
                }
            } else {
                // If any ngram is not found, no matches are possible
                ngramMatches.clear()
                break
            }
        }

        // Verify matches contain the full query string
        for (const id of ngramMatches) {
            const location = this.locations.get(id)
            if (location) {
                const locationString = this.getLocationString(location)
                if (locationString.toLowerCase().includes(query.toLowerCase())) {
                    ngramMatches.add(id)
                }
            }
        }

        return ngramMatches
    }

    // Time: O(q) where q = number of space-separated terms in query
    // Space: O(r) where r = number of matching documents per term
    private searchInvertedIndex(query: string): Set<string> {
        const words = this.tokenize(query)
        const results = new Set<string>()
        words.forEach((word) => {
            const locationIds = this.invertedIndex.get(word.toLowerCase())
            if (locationIds) {
                locationIds.forEach((id) => results.add(id))
            }
        })
        return results
    }

    // Overall search complexity:
    // Time: O(k + m + q) - combination of all search methods
    // Space: O(s) where s = total unique results from all methods
    public async search(query: string): Promise<CityLocationData[]> {
        if (!query) {
            return []
        }

        if (query.length <= 2) {
            // Search only by state codes and return state codes only.
            throw new Error('Query too short. Please use searchByStateCodes instead.')
        }

        const start = performance.now()
        const normalizedQuery = query.toLowerCase()
        const results = new Set<string>()

        console.log('Search indexes before query:', {
            invertedIndex: this.invertedIndex.size,
            radixTree: this.radixTree.size(),
            ngramIndex: this.ngramIndex.size,
        })

        // Strategy 1: Prefix matching using radix tree
        const prefixMatches = this.searchPrefix(normalizedQuery)
        console.log('Prefix results:', prefixMatches.size)
        prefixMatches.forEach((id) => results.add(id))

        // Strategy 2: Ngram matching for substring search
        if (normalizedQuery.length >= this.NGRAM_SIZE) {
            const ngramMatches = this.searchNgrams(normalizedQuery)
            console.log('Ngram results:', ngramMatches.size)
            ngramMatches.forEach((id) => results.add(id))
        }

        // Strategy 3: Inverted index search
        const invertedIndexMatches = this.searchInvertedIndex(normalizedQuery)
        console.log('Inverted index results:', invertedIndexMatches.size)
        invertedIndexMatches.forEach((id) => results.add(id))

        console.log('Final combined results:', results.size)

        // Convert results to Location objects and sort by relevance
        const resultArray = Array.from(results)
            .map((id) => this.locations.get(id)!)
            .sort((a, b) => {
                const aString = this.getLocationString(a)
                const bString = this.getLocationString(b)
                const aLower = aString.toLowerCase()
                const bLower = bString.toLowerCase()

                // Check if the city name matches its state name (e.g., New York, NY)
                // This is the highest priority - major cities that share name with state
                const aCityMatchesState = a.city.toLowerCase() === a.stateName.toLowerCase()
                const bCityMatchesState = b.city.toLowerCase() === b.stateName.toLowerCase()

                if (aCityMatchesState !== bCityMatchesState) {
                    return aCityMatchesState ? -1 : 1
                }

                // Check for exact city name match (e.g., "New York" exactly)
                const aExactCityMatch = a.city.toLowerCase() === normalizedQuery
                const bExactCityMatch = b.city.toLowerCase() === normalizedQuery

                if (aExactCityMatch !== bExactCityMatch) {
                    return aExactCityMatch ? -1 : 1
                }

                // Check if city name starts with the query
                const aStartsWithQuery = a.city.toLowerCase().startsWith(normalizedQuery)
                const bStartsWithQuery = b.city.toLowerCase().startsWith(normalizedQuery)

                // Next priority: cities that start with the query
                if (aStartsWithQuery !== bStartsWithQuery) {
                    return aStartsWithQuery ? -1 : 1
                }

                // Check if any word in the city name starts with the query
                const aWordsStartWithQuery = a.city
                    .toLowerCase()
                    .split(' ')
                    .some((word) => word.startsWith(normalizedQuery))
                const bWordsStartWithQuery = b.city
                    .toLowerCase()
                    .split(' ')
                    .some((word) => word.startsWith(normalizedQuery))

                // Next priority: cities with words that start with the query
                if (aWordsStartWithQuery !== bWordsStartWithQuery) {
                    return aWordsStartWithQuery ? -1 : 1
                }

                // Next priority: exact substring matches
                const aExact = aLower.includes(normalizedQuery)
                const bExact = bLower.includes(normalizedQuery)
                if (aExact !== bExact) return aExact ? -1 : 1

                // Default alphabetical sorting
                return aString.localeCompare(bString)
            })

        await this.recordMetric({
            query,
            resultCount: resultArray.length,
            durationMs: performance.now() - start,
        })

        return resultArray
    }

    public searchByStateCodes(query: string): StateLocationData[] {
        // if (query.length > 2) {
        //     throw new Error('Query too short. Please use "search" instead.')
        // }
        const normalizedQuery = query.toUpperCase()
        console.log('Search by state codes', this.statesMap, query)
        if (query.length === 1) {
            return Array.from(this.statesMap.values())
                .filter((location) => location.stateCode.startsWith(normalizedQuery))
                .sort((a, b) => a.stateCode.localeCompare(b.stateCode))
                .map((l) => ({
                    id: l.id,
                    stateCode: l.stateCode,
                    stateName: l.stateName,
                    type: l.type,
                }))
        } else {
            const state = this.statesMap.get(normalizedQuery)
            return state ? [state] : []
        }
    }
    // Indexing complexity per location:
    // Time: O(w * l^2) where:
    //   w = number of words in location string
    //   l = average length of words
    public addLocation(location: CityLocationData): void {
        this.locations.set(location.id, location)
        const locationString = this.getLocationString(location)
        const words = this.tokenize(locationString)

        // Add to inverted index and radix tree
        words.forEach((word) => {
            if (!this.invertedIndex.has(word)) {
                this.invertedIndex.set(word, new Set())
            }
            this.invertedIndex.get(word)!.add(location.id)
            this.radixTree.insert(word)
        })

        // Add to ngram index
        const ngrams = this.generateNgrams(locationString)
        ngrams.forEach((ngram) => {
            if (!this.ngramIndex.has(ngram)) {
                this.ngramIndex.set(ngram, new Set())
            }
            this.ngramIndex.get(ngram)!.add(location.id)
        })
    }

    // Serialization/Deserialization:
    // Time: O(n) linear traversal of all indexes
    // Space: O(n) storage of all index data in JSON format
    public serialize(): string {
        return JSON.stringify({
            locations: Array.from(this.locations.entries()),
            invertedIndex: Array.from(this.invertedIndex.entries()).map(([key, value]) => [key, Array.from(value)]),
            ngramIndex: Array.from(this.ngramIndex.entries()).map(([key, value]) => [key, Array.from(value)]),
        })
    }

    public deserialize(serialized: string): void {
        const parsed = JSON.parse(serialized)
        this.clear()

        // Restore locations
        parsed.locations.forEach(([id, location]: [string, CityLocationData]) => {
            this.locations.set(id, location)
        })

        // Rebuild indexes
        parsed.invertedIndex.forEach(([word, ids]: [string, string[]]) => {
            this.invertedIndex.set(word, new Set(ids))
        })

        parsed.ngramIndex.forEach(([ngram, ids]: [string, string[]]) => {
            this.ngramIndex.set(ngram, new Set(ids))
        })

        // Rebuild radix tree
        Array.from(this.locations.values()).forEach((location) => {
            const locationString = this.getLocationString(location)
            this.tokenize(locationString).forEach((word) => {
                this.radixTree.insert(word)
            })
        })
    }

    // CSV Processing:
    // Time: O(m) where m = number of lines in CSV
    // Space: O(m) storing all parsed locations
    private async loadLocations(): Promise<CityLocationData[]> {
        // Try to load from IndexedDB first
        const cachedData = await storage.get<CityLocationData[]>('all_locations')

        if (cachedData?.length) {
            return cachedData
        }

        // Parse CSV and store in IndexedDB
        const csvData = await this.loadCSVData()
        const parsedData = this.parseCSV(csvData)
        await storage.set('all_locations', parsedData)

        return parsedData
    }

    private async loadCSVData(): Promise<string> {
        const response = await fetch('/locations.csv')
        return await response.text()
    }

    private parseCSV(csvText: string): CityLocationData[] {
        const rows = csvText.split('\n')
        const headers = rows[0].split(',').map((header) => header.trim())

        const locations: CityLocationData[] = []
        for (let i = 1; i < rows.length; i++) {
            const row = rows[i].split(',')
            if (row.length !== headers.length) continue // Skip malformed rows

            // Remove quotes from all fields
            const cleanRow = row.map((field) => field.trim().replace(/^"|"$/g, ''))

            const locationData: CityLocationData = {
                id: cleanRow[0],
                type: LocationType.CITY,
                city: cleanRow[3],
                stateCode: cleanRow[1],
                stateName: cleanRow[2],
                county: cleanRow[4],
                latitude: parseFloat(cleanRow[5]),
                longitude: parseFloat(cleanRow[6]),
            }
            locations.push(locationData)
        }

        return locations
    }

    // Metrics Handling:
    // Time: O(1) amortized (array push + occasional shift)
    // Space: O(1) fixed-size rolling window
    private async recordMetric(metric: Omit<SearchMetrics, 'timestamp'>): Promise<void> {
        const metrics = (await storage.get<SearchMetrics[]>(METRICS_KEY)) || []
        metrics.push({
            ...metric,
            timestamp: Date.now(),
        })

        if (metrics.length > MAX_METRICS) {
            metrics.shift()
        }

        await storage.set(METRICS_KEY, metrics)
    }

    public async getMetrics(): Promise<SearchMetrics[]> {
        return (await storage.get<SearchMetrics[]>(METRICS_KEY)) || []
    }

    public clear(): void {
        this.invertedIndex.clear()
        this.radixTree = new RadixTree()
        this.ngramIndex.clear()
        this.locations.clear()
    }
}

export const locationDataSearchV2 = new LocationDataSearchV2()
