import { Button } from '@mui/joy'
import { useAuth } from 'hooks/auth'
import { Category, useNotification } from 'hooks/useNotification'
import { useState } from 'react'
import { ringCentralApiService } from './RingCentral.api'

const defaultErrorMessage = 'Failed to connect RingCentral account, please try again'
export const RingCentral = () => {
    const { application, setApplication } = useAuth()
    const [, setConnecting] = useState(false)
    const { addNotification } = useNotification()

    const handleNotification = (message?: string, type: 'error' | 'info' | 'warning' = 'error') => {
        addNotification({
            id: '',
            message: message || defaultErrorMessage,
            type,
            category: Category.RingCentral,
            timestamp: Date.now(),
            topic: 'temp',
        })
    }

    const onClick = async () => {
        try {
            setConnecting(true)
            const authUrl = await ringCentralApiService.getAuthURL()

            if (!authUrl) {
                return handleNotification()
            }

            const authWindow = window.open(authUrl, 'RingCentral Auth', 'width=500,height=600,scrollbars=yes')

            window.addEventListener('message', async function handleAuthMessage(event) {
                try {
                    if (event.origin !== window.location.origin) return

                    if (event.data.type === 'RINGCENTRAL_AUTH_SUCCESS') {
                        window.removeEventListener('message', handleAuthMessage)
                        authWindow?.close()

                        if (!event.data.code) {
                            return handleNotification()
                        }

                        const application = await ringCentralApiService.authenticateWithCode(event.data.code)

                        setApplication(application)
                        console.log('notified')

                        handleNotification('Ringcentral account connected successfully', 'info')
                    }
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (error: any) {
                    handleNotification(error?.message)
                } finally {
                    if (event.data.type === 'RINGCENTRAL_AUTH_SUCCESS') {
                        setConnecting(false)
                    }
                }
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            handleNotification(error?.message)
        }
    }

    if (application?.integrations?.ringcentral && !application?.integrations?.ringcentral?.blocked) {
        return ''
    }

    return (
        <Button variant="solid" color="primary" size="sm" onClick={onClick}>
            {application?.integrations?.ringcentral?.blocked ? 'Reconnect' : 'Connect'} RingCentral
        </Button>
    )
}
