export interface LocationData {
    id: string
    stateCode: string
    stateName: string
    city: string
    latitude: number
    longitude: number
}

export interface SearchResult extends LocationData {
    score: number
    matchType: 'exact' | 'fuzzy'
}

export interface SearchOptions {
    limit?: number
    searchFields?: Array<keyof LocationData>
    fuzzyThreshold?: number
}

export const DEFAULT_SEARCH_OPTIONS: SearchOptions = {
    limit: 10,
    searchFields: ['city', 'stateName', 'stateCode'],
    fuzzyThreshold: 0.3,
}
