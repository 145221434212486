import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { PhoneEnabled } from '@mui/icons-material'
import { Box, Menu, MenuItem, ListItemDecorator, Chip, IconButton } from '@mui/joy'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store'
import { setCallTypePreference } from '../../../../../../../../store/callCenterSlice'
import { AICallButton } from './AICallButton'
import { RCCallButton } from './RCCallButton'
import { CallButtonProps, CallType } from './shared/CallButtonUtils'

//notifiction ping,  on blur, notificaton on press, open sidebar. notificaiton closes call ended.
export const CallButton: React.FC<CallButtonProps> = ({ load, loadSearchId, phoneNumber, favorite, disabled = false }) => {
    // State for menu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    // Get callType from Redux store
    const callTypePreference = useSelector((state: RootState) => state.callCenter.callTypePreferences[load._id] || CallType.RingCentral)

    // Get active call states from Redux store
    const activeCall = useSelector((state: RootState) => state.callCenter.activeRingCentralCall)
    const callSession = activeCall?.session || null
    const isActiveLoad = activeCall?.loadId === load._id

    // Check if load is cancelled
    const isLoadCancelled = load.status === 'cancelled'

    // Track loading states
    const [rcCalling, setRcCalling] = useState(false)
    const [aiCalling, setAiCalling] = useState(false)
    const dispatch = useDispatch()

    // Check if any call is active or loading
    const isCallActive = isActiveLoad && callSession && callSession.state !== 'disposed'
    const isCallLoading = rcCalling || aiCalling

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    // Reference to track if we're hovering over the menu
    const isHoveringRef = useRef(false)

    // Hover handlers for menu
    const handleMenuHoverOpen = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        event.stopPropagation()
        // Use the main call button as the anchor element for better positioning
        const callButton = event.currentTarget.closest('.call-button-container')?.querySelector('.main-call-button')
        setAnchorEl((callButton as HTMLElement) || event.currentTarget)
    }

    const handleMenuHoverEnter = () => {
        isHoveringRef.current = true
    }

    const handleMenuHoverLeave = () => {
        isHoveringRef.current = false
        // Small delay to check if mouse moved to the other element
        setTimeout(() => {
            if (!isHoveringRef.current) {
                handleMenuClose()
            }
        }, 50)
    }

    const handleCallTypeSelect = (type: CallType) => {
        dispatch(setCallTypePreference({ loadId: load._id, callType: type }))
        handleMenuClose()
    }

    // Track mouse movement for better hover detection
    useEffect(() => {
        // Cleanup function
        return () => {
            // Reset hovering state when component unmounts
            isHoveringRef.current = false
        }
    }, [])

    return (
        <Box
            className="call-button-container"
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'relative' }}
            onClick={(event) => event.stopPropagation()}
        >
            {/* Main Call Button with Batch Indicator and MoreVert Icon */}
            <Box sx={{ position: 'relative' }}>
                {/* Batch Indicator */}
                <Chip
                    size="sm"
                    color="primary"
                    sx={{
                        position: 'absolute',
                        top: -8,
                        left: -8,
                        zIndex: 1,
                        fontSize: '0.65rem',
                        height: '18px',
                        minHeight: '18px',
                        px: 0.5,
                    }}
                >
                    {callTypePreference}
                </Chip>

                {/* Call Button based on type */}
                {callTypePreference === CallType.AI ? (
                    <AICallButton
                        load={load}
                        loadSearchId={loadSearchId}
                        phoneNumber={phoneNumber}
                        favorite={favorite}
                        disabled={disabled || isLoadCancelled}
                        onLoadingChange={setAiCalling}
                    />
                ) : (
                    <RCCallButton
                        load={load}
                        loadSearchId={loadSearchId}
                        phoneNumber={phoneNumber}
                        favorite={favorite}
                        disabled={disabled || isLoadCancelled}
                        onLoadingChange={setRcCalling}
                    />
                )}

                {/* Dropdown Icon - Only show when no call is active or loading */}
                {!isCallActive && !isCallLoading && (
                    <IconButton
                        size="md"
                        onMouseEnter={handleMenuHoverOpen}
                        onMouseLeave={handleMenuHoverLeave}
                        sx={{
                            position: 'absolute',
                            bottom: -20,
                            right: -20,
                            width: '2px',
                            height: '2px',
                            bgcolor: '#1976d2',
                            clipPath: 'polygon(0 40%, 40% 40%, 40% 0 )',
                            zIndex: 1,
                            cursor: 'pointer',
                            '&:hover': {
                                bgcolor: '#1565c0',
                            },
                        }}
                    ></IconButton>
                )}
            </Box>

            {/* Dropdown Menu */}
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                placement="right-start"
                tabIndex={0}
                variant="outlined"
                onMouseEnter={handleMenuHoverEnter}
                onMouseLeave={handleMenuHoverLeave}
                slotProps={{
                    root: {
                        sx: {
                            '--List-padding': '0.25rem',
                        },
                    },
                }}
                sx={{
                    '--Menu-radius': '8px',
                    '--Menu-gap': '0',
                    marginTop: '4px',
                    minWidth: '140px',
                }}
            >
                <MenuItem onClick={() => handleCallTypeSelect(CallType.AI)} selected={callTypePreference === CallType.AI}>
                    <ListItemDecorator>
                        <AutoAwesomeIcon fontSize="small" color="primary" />
                    </ListItemDecorator>
                    Call with AI
                </MenuItem>
                <MenuItem onClick={() => handleCallTypeSelect(CallType.RingCentral)} selected={callTypePreference === CallType.RingCentral}>
                    <ListItemDecorator>
                        <PhoneEnabled fontSize="small" color="primary" />
                    </ListItemDecorator>
                    Call with RC
                </MenuItem>
            </Menu>
        </Box>
    )
}
