import { IBroker, NumeoRestError, RateConf } from '@numeo/types'
import axios, { isAxiosError } from 'axios'
import { IBrokersResponse, ICreateBroker, IUpdateBroker } from './types'

export interface NotifyBrokersRequest {
    emails: string[]
    subject: string
    message: string
}

export class MyBrokersApi {
    private readonly BROKERS_URL = `/my-brokers`

    async getBrokers(page = 1, pageSize = 10, sortBy = 'createdAt', sortDirection = 'desc'): Promise<IBrokersResponse> {
        try {
            const params = {
                page,
                pageSize,
                sortBy,
                sortDirection,
            }
            const response = await axios.get<IBrokersResponse>(`${this.BROKERS_URL}`, { params })
            return response.data
        } catch (error) {
            if (isAxiosError(error)) {
                throw new NumeoRestError(error.response?.data.message || 'Failed to fetch brokers', error.response?.data.code || 500)
            }
            throw new NumeoRestError('Unknown error while fetching brokers', 500)
        }
    }

    async createBroker(data: ICreateBroker): Promise<IBroker> {
        try {
            const response = await axios.post<IBroker>(this.BROKERS_URL, data)
            return response.data
        } catch (error) {
            if (isAxiosError(error)) {
                throw new NumeoRestError(error.response?.data.message || 'Failed to create broker', error.response?.data.code || 500)
            }
            throw new NumeoRestError('Unknown error while creating broker', 500)
        }
    }

    async updateBroker(id: string, data: IUpdateBroker): Promise<IBroker> {
        try {
            const response = await axios.put<IBroker>(`${this.BROKERS_URL}/${id}`, data)
            return response.data
        } catch (error) {
            if (isAxiosError(error)) {
                throw new NumeoRestError(error.response?.data.message || 'Failed to update broker', error.response?.data.code || 500)
            }
            throw new NumeoRestError('Unknown error while updating broker', 500)
        }
    }

    async deleteBrokers(ids: string[]): Promise<void> {
        try {
            await axios.delete(`${this.BROKERS_URL}/delete/multiple`, { data: { ids } })
        } catch (error) {
            if (isAxiosError(error)) {
                throw new NumeoRestError(error.response?.data.message || 'Failed to delete brokers', error.response?.data.code || 500)
            }
            throw new NumeoRestError('Unknown error while deleting brokers', 500)
        }
    }

    async getAllBrokers(): Promise<IBroker[]> {
        try {
            const response = await axios.get<IBroker[]>(`${this.BROKERS_URL}/all`)
            return response.data
        } catch (error) {
            if (isAxiosError(error)) {
                throw new NumeoRestError(error.response?.data.message || 'Failed to get all brokers', error.response?.data.code || 500)
            }
            throw new NumeoRestError('Unknown error while getting all brokers', 500)
        }
    }

    async getBrokerByMcNumber(mcNumber: string): Promise<IBroker> {
        try {
            const response = await axios.get<IBroker>(`${this.BROKERS_URL}/by-mc/${mcNumber}`)
            return response.data
        } catch (error) {
            if (isAxiosError(error)) {
                throw new NumeoRestError(error.response?.data.message || 'Failed to get all brokers', error.response?.data.code || 500)
            }
            throw new NumeoRestError('Unknown error while getting all brokers', 500)
        }
    }

    async notifyBrokers(data: NotifyBrokersRequest): Promise<void> {
        try {
            await axios.post(`${this.BROKERS_URL}/notify`, data)
        } catch (error) {
            if (isAxiosError(error)) {
                throw new NumeoRestError(error.response?.data.message || 'Failed to notify brokers', error.response?.data.code || 500)
            }
            throw new NumeoRestError('Unknown error while notifying brokers', 500)
        }
    }

    async getRateConfsByMcNumber(mcNumber: string): Promise<RateConf[]> {
        try {
            const response = await axios.get(`/broker-analytics/rateconf/by-mc/${mcNumber}`)
            return response.data.results
        } catch (error) {
            if (isAxiosError(error)) {
                throw new NumeoRestError(error.response?.data.message || 'Failed to get rate confirmations by mc number', error.response?.data.code || 500)
            }
            throw new NumeoRestError('Unknown error while getting rate confirmations by mc number', 500)
        }
    }
}

// Export singleton instance
export const brokersApi = new MyBrokersApi()
