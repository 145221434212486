import { IRoute } from '@numeo/types'

// Get origin and destination from route
export const getOrigin = (route: IRoute[]) => {
    if (route && route.length > 0) {
        const location = route[0].location
        return location ? `${location.city || ''}, ${location.state || ''}` : 'N/A'
    }
    return 'N/A'
}

export const getDestination = (route: IRoute[]) => {
    if (route && route.length > 1) {
        const location = route[route.length - 1].location
        return location ? `${location.city || ''}, ${location.state || ''}` : 'N/A'
    }
    return 'N/A'
}
