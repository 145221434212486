import { NylasEmailClient } from 'pages/dashboard/pages/dispatcher/pages/dispatcher-emails/controllers'
import { EmailAccount } from 'pages/dashboard/pages/dispatcher/pages/dispatcher-emails/controllers/types'

type ShowToastFunction = (message: string, type: 'success' | 'danger') => void

export const handleNylasEmailAdd = async (onSuccess?: (accounts: EmailAccount[]) => void, onError?: (error: string) => void, showToast?: ShowToastFunction) => {
    try {
        const params = new URLSearchParams({
            client_id: process.env.REACT_APP_NYLAS_CLIENT_ID!,
            redirect_uri: process.env.REACT_APP_NYLAS_REDIRECT_URI!,
            response_type: 'code',
        })

        const authUrl = `${process.env.REACT_APP_NYLAS_API_URL}/v3/connect/auth?${params.toString()}`
        const authWindow = window.open(authUrl.toString(), 'Outlook Auth', 'width=500,height=600,scrollbars=yes')

        window.addEventListener('message', async function handleAuthMessage(event) {
            try {
                if (event.origin !== window.location.origin) return

                if (event.data.type === 'NYLAS_AUTH_SUCCESS') {
                    window.removeEventListener('message', handleAuthMessage)
                    const accounts = await NylasEmailClient.getAccounts()
                    onSuccess?.(accounts)
                    authWindow?.close()
                } else if (event.data.type === 'NYLAS_AUTH_ERROR') {
                    window.removeEventListener('message', handleAuthMessage)
                    authWindow?.close()
                    const errorMessage = event.data.error || 'Failed to add email'
                    showToast?.(errorMessage, 'danger')
                    onError?.(errorMessage)
                }
            } catch (error) {
                const errorMessage = 'Failed to add email'
                showToast?.(errorMessage, 'danger')
                onError?.(errorMessage)
            }
        })
    } catch (error: unknown) {
        console.error('Error adding email:', error)
        const errorMessage = (error as Error).message || 'Failed to add email'
        showToast?.(errorMessage, 'danger')
        onError?.(errorMessage)
    }
}
