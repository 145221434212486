import React, { useMemo, useEffect, useState } from 'react'
import { Typography } from '@mui/joy'

interface AgeDisplayProps {
    postedAt: string | null
}

/**
 * AgeDisplay component that updates the age of a load every minute
 * This prevents re-rendering the entire loads table when the age needs to update
 */
export const AgeDisplay: React.FC<AgeDisplayProps> = ({ postedAt }) => {
    // Force component to re-render every minute
    const [currentTimestamp, setCurrentTimestamp] = useState<number>(Date.now())

    useEffect(() => {
        // Set up interval to update component every minute
        const intervalId = setInterval(() => {
            setCurrentTimestamp(Date.now())
        }, 60000)

        // Clean up interval on component unmount
        return () => clearInterval(intervalId)
    }, [])

    // Calculate age using useMemo to avoid unnecessary calculations
    const age = useMemo(() => {
        if (!postedAt) {
            return '—'
        }

        const diffMinutes: number = (new Date().getTime() - new Date(postedAt).getTime()) / 60000

        // Convert to appropriate time unit
        if (diffMinutes < 60) {
            // Less than 1 hour: show minutes
            return `${Math.floor(diffMinutes)}m`
        } else if (diffMinutes < 24 * 60) {
            // Less than 24 hours: show hours
            return `${Math.floor(diffMinutes / 60)}h`
        } else if (diffMinutes < 7 * 24 * 60) {
            // Less than 7 days: show days
            return `${Math.floor(diffMinutes / (60 * 24))}d`
        } else {
            // 7 days or more: show weeks
            return `${Math.floor(diffMinutes / (60 * 24 * 7))}w`
        }
        //eslint-disable-next-line
    }, [postedAt, currentTimestamp])

    return (
        <Typography
            sx={{
                fontSize: '14px',
            }}
        >
            {age}
        </Typography>
    )
}

export default AgeDisplay
