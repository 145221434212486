// hooks/useNotification.ts
import { CallActionTypes, CallBaseMessage, IPartialLoadUpdateMessage, WebSocketStatus } from '@numeo/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import { addNotification, clearAll, deleteNotification } from '../store/notificationSlice'
export enum Category {
    LoadFinderTruck = 'loadFinderTruck',
    Email = 'email',
    CallCenter = 'callCenter',
    Error = 'error',
    Broker = 'broker',
    RingCentral = 'ringCentral',
    Settings = 'settings',
    Updater = 'updater',
}
export type INotification = {
    id: string
    category: Category
    message: string
    secondaryMessage?: string
    timestamp: number
    type: 'info' | 'warning' | 'error'
    path?: string
    topic: string
    onClick?: () => void
}

export interface NotificationHook {
    notifications: INotification[]
    wsStatus: WebSocketStatus
    addNotification: (notification: INotification) => void
    deleteNotification: (notification: INotification) => void
    clearNotification: (notification: INotification) => void
    clearAll: () => void
}

export const useNotification = (): NotificationHook => {
    const dispatch = useDispatch()
    const notifications = useSelector((state: RootState) => state.notification.notifications)
    const wsStatus = useSelector((state: RootState) => state.notification.wsStatus)

    return {
        notifications,
        wsStatus,
        addNotification: (notification) => dispatch(addNotification(notification)),
        deleteNotification: (notification) => dispatch(deleteNotification(notification.id)),
        clearNotification: (notification) => dispatch(deleteNotification(notification.id)),
        clearAll: () => dispatch(clearAll()),
    }
}

// =========== Helper classes ===========
export class LoadFinderNotification implements INotification {
    readonly id: string
    readonly category: Category
    readonly message: string
    readonly timestamp: number
    readonly type: 'info' | 'warning' | 'error'
    readonly path?: string | undefined
    readonly topic: string

    constructor(data: IPartialLoadUpdateMessage) {
        this.id = data.searchResult.loadSearchId
        this.category = Category.LoadFinderTruck
        this.message = 'Search result updated'
        this.timestamp = Date.now()
        this.type = 'info'
        this.path = `dispatcher/spot-finder?truckId=${data.searchResult.truckId}`
        this.topic = 'Spot Finder'
    }
    toJson() {
        return {
            id: this.id,
            category: this.category,
            message: this.message,
            timestamp: this.timestamp,
            type: this.type,
            path: this.path,
        }
    }
}

export class CallCenterNotification implements INotification {
    readonly id: string
    readonly category: Category
    readonly message: string
    readonly secondaryMessage: string
    readonly timestamp: number
    readonly type: 'info' | 'warning' | 'error'
    readonly path?: string | undefined
    readonly topic: string

    constructor(message: CallBaseMessage) {
        let path = 'dispatcher/call-center'
        switch (message.data.action) {
            case CallActionTypes.CC_CallStarted:
                this.message = 'Call started'
                this.secondaryMessage = `Call ID: ${message.data.call.callId}`
                break
            case CallActionTypes.CC_CallUpdated:
                this.message = 'Call updated'
                this.secondaryMessage = `Call ID: ${message.data.call.callId}`
                break
            case CallActionTypes.CC_CallEnded:
                this.message = 'Call ended'
                this.secondaryMessage = `Call ID: ${message.data.callId}`
                path = 'dispatcher/call-center?call_type=past'
                break
            default:
                this.message = 'Unknown action'
                this.secondaryMessage = `...`
                break
        }
        this.id = ''
        this.category = Category.CallCenter
        this.timestamp = Date.now()
        this.type = 'info'
        this.path = path
        this.topic = 'Call Center'
    }
    toJson() {
        return {
            id: this.id,
            category: this.category,
            message: this.message,
            secondaryMessage: this.secondaryMessage,
            timestamp: this.timestamp,
            type: this.type,
            path: this.path,
        }
    }
}
