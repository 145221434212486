import { WsMessage, WsMessageCategory } from '.'
import { Call, CallSettings } from '../call-center'

export interface CallBaseMessage extends WsMessage {
    categoryType: WsMessageCategory.CallCenter
    data: CallStartedMessage | CallEndedMessage | CallUpdatedMessage | GetActiveCallsMessage | CallResultUpdatedMessage | UpdateCallSettingsMessage
}

// These types are passed through the backend, backend responds with the same action type
export enum CallActionTypes {
    // Must be sent when new call is started
    CC_CallStarted = 'callCenter.callStarted',
    // Must be sent when the call is ended
    CC_CallEnded = 'callCenter.callEnded',
    // Must be sent when the call is updated
    CC_CallUpdated = 'callCenter.callUpdated',
    // Must be sent when request for active calls is made from frontend
    CC_GetActiveCalls = 'callCenter.getActiveCalls',
    // Must be sent when request for active calls is made from frontend
    CC_CallResultUpdated = 'callCenter.callResultUpdated',
    // Will be received when call settings are updated
    CC_UpdateCallSettings = 'callCenter.updateCallSettings',
}

export interface CallStartedMessage {
    action: CallActionTypes.CC_CallStarted
    call: Call
}

export interface CallEndedMessage {
    action: CallActionTypes.CC_CallEnded
    callId: string
    loadId?: string
}

export interface CallUpdatedMessage {
    action: CallActionTypes.CC_CallUpdated
    call: Call
}

export interface CallResultUpdatedMessage {
    action: CallActionTypes.CC_CallResultUpdated
    callId: string
    loadId?: string
    rate: number | null
}

export interface GetActiveCallsMessage {
    action: CallActionTypes.CC_GetActiveCalls
    calls: Call[]
}

export interface UpdateCallSettingsMessage {
    action: CallActionTypes.CC_UpdateCallSettings
    callId: string
    settings: CallSettings
}
