import { Call, CallType, NumeoRestError } from '@numeo/types'
import axios, { isAxiosError } from 'axios'

class CallCenterApi {
    public overridePhoneNumber: string | undefined

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async makeCall(
        phoneNumber: string,
        mcNumber: string,
        origin: string,
        destination: string,
        date: string,
        companyName: string,
        loadId: string,
        loadSearchId: string
    ): Promise<Call> {
        try {
            const response = await axios.post('/voip/twilio/browser-sdk/make-call', {
                To: phoneNumber.replace(/\s*ext\.\s*\d+$/, ''), // remove ext. part of the phone number
                metadata: {
                    load: {
                        origin,
                        destination,
                        date,
                        mcNumber,
                        companyName,
                        loadId,
                        loadSearchId,
                    },
                    callType: CallType.DISPATCHER,
                },
            })
            return response.data
        } catch (error) {
            console.error('Error fetching call list:', error)
            if (isAxiosError(error)) {
                throw new NumeoRestError(error.response?.data.message || 'Unknown error', error.response?.data.code || 500)
            } else {
                throw new NumeoRestError('Unknown error', 500)
            }
        }
    }

    async getCallList(
        status: 'active' | 'past' | undefined,
        projectName: string | undefined,
        filters?: { types?: string[]; status?: string[]; searchTerm?: string }
    ): Promise<Call[]> {
        try {
            const response = await axios.get('/calls/list', {
                params: {
                    status,
                    projectName,
                    filters: {
                        types: filters?.types || [],
                        status: filters?.status || [],
                        searchTerm: filters?.searchTerm || '',
                    },
                },
            })
            return response?.data || []
        } catch (error) {
            console.error('Error fetching call list:', error)
            if (isAxiosError(error)) {
                throw new NumeoRestError(error.response?.data.message || 'Unknown error', error.response?.data.code || 500)
            } else {
                throw new NumeoRestError('Unknown error', 500)
            }
        }
    }

    async getCallById(callId: string, projectName: string | undefined): Promise<Call> {
        try {
            const response = await axios.get(`/calls/list/${callId}`, {
                params: {
                    projectName,
                },
            })
            return response?.data || ({} as Call)
        } catch (error) {
            console.error('Error fetching call by ID:', error)
            if (isAxiosError(error)) {
                throw new NumeoRestError(error.response?.data.message || 'Unknown error', error.response?.data.code || 500)
            } else {
                throw new NumeoRestError('Unknown error', 500)
            }
        }
    }

    // TODO: specify correct
    async stopCall(callId: string): Promise<void> {
        try {
            await axios.post(`/voip/twilio/browser-sdk/stop-call`, { callId })
            return
        } catch (error) {
            console.error('Error stopping call:', error)
            if (isAxiosError(error)) {
                throw new NumeoRestError(error.response?.data.message || 'Unknown error', error.response?.data.code || 500)
            } else {
                throw new NumeoRestError('Unknown error', 500)
            }
        }
    }
}

const callCenterApi = new CallCenterApi()
export default callCenterApi
