import Box from '@mui/joy/Box'
import Breadcrumbs from '@mui/joy/Breadcrumbs'
import CssBaseline from '@mui/joy/CssBaseline'
import Link from '@mui/joy/Link'
import Typography from '@mui/joy/Typography'
import { CssVarsProvider } from '@mui/joy/styles'
import { extendTheme as materialExtendTheme, CssVarsProvider as MaterialCssVarsProvider, THEME_ID as MATERIAL_THEME_ID } from '@mui/material/styles'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import Header from './components/Header'
import Sidebar from './components/Sidebar'
import { Children } from 'types'
import MenuIcon from '@mui/icons-material/Menu'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import IconButton from '@mui/joy/IconButton'
import { useTheme } from '@mui/joy'
import { useEffect, useState } from 'react'
import LocationDataSearch from 'utils/location-search/LocationDataSearch'
import { densityTheme, globalDensityStyles } from '../theme/density'
import { NotificationComponent } from 'components/NotificationCenter'
import { Warnings } from 'components/Warnings'
import { useDispatch } from 'react-redux'
import { connecting, disconnected } from 'store/webSocketSlice'

export default function MasterLayout({ children }: Children) {
    const pathname = window.location.pathname.split('/')
    const theme = useTheme()
    const materialTheme = materialExtendTheme()
    const [sidebarOpen, setSidebarOpen] = useState(true)

    const dispatch = useDispatch()

    // Initialize WebSocket connection when MasterLayout mounts and handle disconnection on unmount
    useEffect(() => {
        // Attempt to connect when component mounts
        dispatch(connecting())

        // Disconnect when component unmounts
        return () => {
            dispatch(disconnected('MasterLayout unmounted'))
        }
    }, [dispatch])

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen)
        document.documentElement.style.setProperty('--Sidebar-width', sidebarOpen ? '0px' : '280px')
    }
    useEffect(() => {
        // Prewarm the location database
        LocationDataSearch.getInstance()
    }, [])

    return (
        <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
            <CssVarsProvider disableTransitionOnChange theme={densityTheme}>
                <CssBaseline />
                <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
                    <NotificationComponent />
                    <Header />
                    <Sidebar isOpen={sidebarOpen} />
                    <IconButton
                        sx={{
                            position: 'fixed',
                            top: 10,
                            left: sidebarOpen ? 240 : 10,
                            gap: 1,
                            borderBottom: '1px solid',
                            borderColor: 'background.level1',
                            border: `1px solid ${theme.palette.mode === 'dark' ? '#000' : '#dfdede'}`,
                            transition: 'left 0.4s',
                            zIndex: 1100,
                        }}
                        onClick={toggleSidebar}
                        variant="outlined"
                        color="neutral"
                        size="md"
                    >
                        {sidebarOpen ? <FirstPageIcon /> : <MenuIcon />}
                    </IconButton>
                    <Box
                        component="main"
                        className="MainContent"
                        sx={{
                            pl: sidebarOpen ? 0.5 : 6,
                            pt: {
                                xs: 'calc(12px + var(--Header-height))',
                                sm: 'calc(12px + var(--Header-height))',
                                md: 0,
                            },
                            pb: { xs: 2, sm: 2, md: 3 },
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 0,
                            height: '100vh',
                            gap: 1,
                            transition: 'padding-left 0.4s',
                            ...globalDensityStyles,
                            overflow: 'auto',
                        }}
                    >
                        <Warnings />
                        {pathname.includes('/spot-finder') && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Breadcrumbs size="sm" aria-label="breadcrumbs" separator={<ChevronRightRoundedIcon fontSize="small" />} sx={{ pl: 0 }}>
                                    <Link underline="none" color="neutral" href="/" aria-label="Home">
                                        <HomeRoundedIcon />
                                    </Link>
                                    {pathname.map((path, index) => {
                                        if (path === '') return null
                                        return (
                                            <Link key={index} underline="none" color="neutral" href={`${window.location.pathname.split(path)[0] + path}`}>
                                                <Typography color="primary" fontWeight={500} fontSize={12} sx={{ textTransform: 'capitalize' }}>
                                                    {path}
                                                </Typography>
                                            </Link>
                                        )
                                    })}
                                </Breadcrumbs>
                            </Box>
                        )}

                        {/*
                    <Typography level="h3" sx={{ textTransform: 'capitalize', pl: 1 }}>
                        {pathname ? pathname?.pop()?.replace(/-/g, ' ') : 'Dashboard'}
                    </Typography>
                    */}

                        {children}
                    </Box>
                </Box>
            </CssVarsProvider>
        </MaterialCssVarsProvider>
    )
}
