/*
Integration types
*/

export interface SamsaraVehicle {
    id: string
    cameraSerial?: string
    make: string
    model: string
    name: string
    vin: string
    year: string
    vehicleRegulationMode: string
}

export interface SamsaraDriverRuleSet {
    cycle: string
    shift: string
    restart: string
    break: string
}

export interface SamsaraDriver {
    id: string
    name: string
    username: string
    phone: string
    eldSettings: {
        ruleSets: SamsaraDriverRuleSet[]
    }
    staticAssignedVehicle: {
        id: string
    }
}

export interface SamsaraVehicleStatus {
    id: string
    name: string
    externalIds: {
        'samsara.vin': string
    }
    engineStates: {
        time: Date
        value: string
    }[]
    fuelPercents: {
        time: Date
        value: number
    }[]
    gps: {
        time: Date
        latitude: number
        longitude: number
        headingDegrees: number
        speedMilesPerHour: number
        reverseGeo: {
            formattedLocation: string
        }
    }[]
}

export enum LucidEldStatusCode {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export interface LucidEldVehicle {
    companyId: string
    vehicleId: string
    number: string
    VIN: string
    active: boolean
}

export interface LucidEldVehicleStatus {
    companyId: string
    vehicleId: string
    number: string
    odometer: string
    speed: number
    lat: string
    lon: string
    code: LucidEldStatusCode
    timestamp: string
}

export interface TteldUnit {
    truck_number: string
    vin: string
    coordinates: {
        lat: number
        lng: number
    }
    rotation: number
    timestamp: string
    fuelLevelPercent?: number
}

export enum NylasEmailProvider {
    MICROSOFT = 'microsoft',
    GOOGLE = 'google',
}

export interface ApplicationNylasConnectedEmail {
    email: string
    provider: NylasEmailProvider
    grantId: string
    isExpired: boolean
}

export interface TteldIntegration {
    usdot: string
    apiKey: string
}

export interface LucidEldIntegration {
    username: string
    passwordHash: string
    apiKey: string
    accessToken: string
}

export interface SamsaraIntegration {
    accessToken: string
    refreshToken: string
    expiresAt: Date
}

export interface DatIntegration {
    orgUsername?: string
    orgPassword?: string
    username: string
    orgAccessToken?: string
    userAccessToken?: string
    orgAccessTokenExpiresAt?: Date
    userAccessTokenExpiresAt?: Date
    blocked: boolean
}

export interface TruckStopIntegration {
    accessToken?: string
}

export interface RingCentralIntegration {
    accessToken: string
    refreshToken: string
    blocked: boolean
}

export interface UberIntegration {
    accessToken: string
    refreshToken?: string
    expiresAt?: Date
    scope?: string
    blocked?: boolean
}
