import { EmailRecipient, LoadStatus, OngoingLoad } from '@numeo/types'
import axios from 'axios'

interface PaginatedResponse<T> {
    data: T[]
    meta?: {
        total: number
        page: number
        pageSize: number
    }
}

type OngoingLoadDTO = Omit<OngoingLoad, '_id' | 'createdAt' | 'updatedAt'>

class OngoingLoadsApi {
    /**
     * Get all ongoing loads with pagination
     */
    async getAllOngoingLoads(page: number = 1, pageSize: number = 10, projectName: string): Promise<PaginatedResponse<OngoingLoad>> {
        const response = await axios.get('/updater/loads', {
            params: {
                page,
                pageSize,
                projectName,
            },
        })
        return response
    }

    /**
     * Create a new ongoing load
     */
    async createOngoingLoad(load: OngoingLoadDTO): Promise<OngoingLoad> {
        const response = await axios.post('/updater/loads', load)
        return response.data
    }

    /**
     * Update an existing ongoing load
     */
    async updateOngoingLoad(id: string, load: Partial<OngoingLoadDTO>): Promise<OngoingLoad> {
        const response = await axios.put(`/updater/loads/${id}`, load)
        return response.data
    }

    /**
     * Delete multiple ongoing loads
     */
    async deleteOngoingLoads(ids: string[]): Promise<void> {
        await axios.delete('/updater/loads/multiple', {
            data: {
                ids,
            },
        })
    }

    /**
     * Upload file for processing
     */
    async uploadFile(file: File) {
        // Add filename and mimetype as query parameters
        const response = await axios.post(
            `/updater/loads/upload?filename=${encodeURIComponent(file.name)}&mimetype=${encodeURIComponent(file.type)}`,
            file, // Send the file directly as the body
            {
                headers: {
                    'Content-Type': file.type,
                },
            }
        )
        return response.data
    }

    /**
     * Update load status
     */
    async updateLoadStatus(ids: string[], status: LoadStatus): Promise<void> {
        await axios.put('/updater/loads/status', { ids, status })
    }

    /**
     * Send email to broker
     */
    async sendEmail(toWho: EmailRecipient, subject: string, htmlBody: string, loadId: string, projectName: string): Promise<string | null> {
        const response = await axios.post('/updater/loads/send-email', { subject, htmlBody, loadId, projectName, toWho })
        return response.data.threadId || null
    }
}

export const ongoingLoadsApi = new OngoingLoadsApi()
