import { Box } from '@mui/joy'
import { ReactNode } from 'react'

interface TabPanelProps {
    children?: ReactNode
    index: string
    value: string
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`email-tabpanel-${index}`}
            aria-labelledby={`email-tab-${index}`}
            sx={{
                flex: 1,
                display: value === index ? 'flex' : 'none',
                flexDirection: 'column',
                overflow: 'hidden',
                borderBottom: 0,
                boxShadow: 'none',
            }}
            {...other}
        >
            {value === index && children}
        </Box>
    )
}
