import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import Person2Icon from '@mui/icons-material/Person2'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import SupportRoundedIcon from '@mui/icons-material/SupportRounded'
import Avatar from '@mui/joy/Avatar'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Chip from '@mui/joy/Chip'
import Divider from '@mui/joy/Divider'
import GlobalStyles from '@mui/joy/GlobalStyles'
import IconButton from '@mui/joy/IconButton'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton'
import ListItemContent from '@mui/joy/ListItemContent'
import Sheet from '@mui/joy/Sheet'
import { styled, useColorScheme } from '@mui/joy/styles'
import Typography from '@mui/joy/Typography'
import * as React from 'react'

import { HeadsetMic, LocalPolice, People, Sync } from '@mui/icons-material'
import { useAuth } from 'hooks/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import ColorSchemeToggle from '../../components/ColorSchemeToggle'
import { DispatcherAgentSetupModal } from './components'
import { getBaseUrl } from 'components/common/utils/getBaseUrl'

interface SidebarProps {
    isOpen: boolean
}

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    '&.Mui-disabled': {
        opacity: 0.5,
        backgroundColor: theme.vars.palette.background.level1,
        '&:hover': {
            backgroundColor: theme.vars.palette.background.level1,
        },
    },
}))

function Toggler({
    defaultExpanded = false,
    renderToggle,
    children,
    forceExpanded,
}: {
    defaultExpanded?: boolean
    children: React.ReactNode
    forceExpanded?: boolean
    renderToggle: (params: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => React.ReactNode
}) {
    const [open, setOpen] = React.useState(defaultExpanded)

    React.useEffect(() => {
        if (forceExpanded !== undefined) {
            setOpen(forceExpanded)
        }
    }, [forceExpanded])

    return (
        <React.Fragment>
            {renderToggle({ open, setOpen })}
            <Box
                sx={[
                    {
                        display: 'grid',
                        transition: '0.2s ease',
                        '& > *': {
                            overflow: 'hidden',
                        },
                    },
                    open ? { gridTemplateRows: '1fr' } : { gridTemplateRows: '0fr' },
                ]}
            >
                {children}
            </Box>
        </React.Fragment>
    )
}

export default function Sidebar({ isOpen }: SidebarProps) {
    const { user, logout, application } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const { mode } = useColorScheme()

    // Simple check if we're in the dispatcher section
    const isDispatcherActive = location.pathname.startsWith('/dispatcher') || location.pathname === '/settings'
    const isActive = (path: string) => location.pathname === path
    const [enabled, setEnabled] = React.useState(Boolean(application?.integrations?.nylas?.emails?.length) || false)
    const [wizardOpen, setWizardOpen] = React.useState(false)

    const isUpdaterAgentDisabled = getBaseUrl() === 'https://agent.numeo.ai'

    return (
        <>
            <Sheet
                className="Sidebar"
                sx={{
                    position: { xs: 'static' },
                    height: '100dvh',
                    width: isOpen ? 'var(--Sidebar-width)' : '0px',
                    p: isOpen ? 2 : 0,
                    pt: 1.2,
                    flexShrink: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRight: '1px solid',
                    borderColor: 'divider',
                    transform: {
                        xs: 'none',
                    },
                    transition: 'all 0.4s',
                    overflow: 'hidden',
                    visibility: isOpen ? 'visible' : 'hidden',
                    opacity: isOpen ? 1 : 0,
                }}
            >
                <GlobalStyles
                    styles={(theme) => ({
                        ':root': {
                            '--Sidebar-width': '280px',
                            [theme.breakpoints.up('lg')]: {
                                '--Sidebar-width': '280px',
                            },
                        },
                    })}
                />
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} onClick={() => navigate('/')}>
                        {mode === 'light' ? (
                            <img alt="Numeo Logo" src="/numeo-logo.png" width="150" height="auto" />
                        ) : (
                            <img alt="Numeo Logo" src="/numeo-logo-dark.png" width="150" height="auto" />
                        )}
                    </Box>
                    <ColorSchemeToggle sx={{ ml: 3, p: 0.5 }} />
                </Box>
                <Box
                    sx={{
                        minHeight: 0,
                        overflow: 'hidden auto',
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        [`& .${listItemButtonClasses.root}`]: {
                            gap: 1.5,
                        },
                    }}
                >
                    <List
                        size="sm"
                        sx={{
                            gap: 1,
                            '--List-nestedInsetStart': '30px',
                            '--ListItem-radius': (theme) => theme.vars.radius.sm,
                        }}
                    >
                        <ListItem nested>
                            <Typography level="title-sm" color="primary" sx={{ mb: 0.5 }}>
                                Agents
                            </Typography>
                            <Toggler
                                defaultExpanded={isDispatcherActive}
                                renderToggle={({ open, setOpen }) => (
                                    <ListItemButton
                                        onClick={() => {
                                            navigate('dispatcher')
                                            setOpen(!open)
                                        }}
                                        sx={{ display: 'flex', justifyContent: 'space-between' }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexGrow: 1 }}>
                                            <HeadsetMic />
                                            <ListItemContent sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Typography level="title-sm">Dispatcher Agent</Typography>
                                            </ListItemContent>
                                        </Box>
                                        <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
                                    </ListItemButton>
                                )}
                            >
                                <List sx={{ gap: 0.5 }}>
                                    <ListItem sx={{ mt: 0.5 }}>
                                        <ListItemButton
                                            onClick={() => navigate('/dispatcher')}
                                            sx={{
                                                backgroundColor: isActive('/dispatcher') ? 'primary.main' : 'transparent',
                                                color: isActive('/dispatcher') ? '#007FFF' : 'inherit',
                                                '&:hover': { backgroundColor: 'primary.light' },
                                                fontWeight: isActive('/dispatcher') ? 'bold' : 'normal',
                                            }}
                                        >
                                            Dashboard
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem sx={{ mt: 0.5 }}>
                                        <ListItemButton
                                            disabled={!enabled}
                                            onClick={() => navigate('/dispatcher/emails')}
                                            sx={{
                                                backgroundColor: isActive('/dispatcher/emails') ? 'primary.main' : 'transparent',
                                                color: isActive('/dispatcher/emails') ? '#007FFF' : 'inherit',
                                                '&:hover': { backgroundColor: 'primary.light' },
                                                fontWeight: isActive('/dispatcher/emails') ? 'bold' : 'normal',
                                            }}
                                        >
                                            Emails
                                            {enabled && (
                                                <Chip size="sm" variant="soft" color="success" sx={{ ml: 'auto' }}>
                                                    Active
                                                </Chip>
                                            )}
                                        </ListItemButton>
                                        {!enabled && (
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Button
                                                    size="sm"
                                                    variant="soft"
                                                    color="primary"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setWizardOpen(true)
                                                    }}
                                                    sx={{
                                                        minWidth: 'auto',
                                                        px: 1,
                                                    }}
                                                >
                                                    Enable
                                                </Button>
                                            </Box>
                                        )}
                                    </ListItem>
                                    <ListItem sx={{ mt: 0.5 }}>
                                        <ListItemButton
                                            onClick={() => navigate('/dispatcher/spot-finder')}
                                            sx={{
                                                backgroundColor: isActive('/dispatcher/spot-finder') ? 'primary.main' : 'transparent',
                                                color: isActive('/dispatcher/spot-finder') ? '#007FFF' : 'inherit',
                                                '&:hover': { backgroundColor: 'primary.light' },
                                                fontWeight: isActive('/dispatcher/spot-finder') ? 'bold' : 'normal',
                                            }}
                                        >
                                            Spot Finder
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem>
                                        <StyledListItemButton
                                            onClick={() => navigate('/dispatcher/call-center')}
                                            sx={{
                                                backgroundColor: isActive('/dispatcher/call-center') ? 'primary.main' : 'transparent',
                                                color: isActive('/dispatcher/call-center') ? '#007FFF' : 'inherit',
                                                '&:hover': { backgroundColor: 'primary.light' },
                                                fontWeight: isActive('/dispatcher/call-center') ? 'bold' : 'normal',
                                            }}
                                        >
                                            <ListItemContent>Call Center</ListItemContent>
                                        </StyledListItemButton>
                                    </ListItem>
                                    <ListItem>
                                        <StyledListItemButton
                                            onClick={() => navigate('/dispatcher/my-brokers')}
                                            sx={{
                                                backgroundColor: isActive('/dispatcher/my-brokers') ? 'primary.main' : 'transparent',
                                                color: isActive('/dispatcher/my-brokers') ? '#007FFF' : 'inherit',
                                                '&:hover': { backgroundColor: 'primary.light' },
                                                fontWeight: isActive('/dispatcher/my-brokers') ? 'bold' : 'normal',
                                            }}
                                        >
                                            <ListItemContent>My Brokers</ListItemContent>
                                        </StyledListItemButton>
                                    </ListItem>
                                </List>
                            </Toggler>
                        </ListItem>
                        <ListItem nested>
                            <Toggler
                                renderToggle={({ open, setOpen }) => (
                                    <StyledListItemButton
                                        onClick={() => {
                                            navigate('updater-agent')
                                            setOpen(!open)
                                        }}
                                        sx={{ display: 'flex', justifyContent: 'space-between' }}
                                        disabled={isUpdaterAgentDisabled}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexGrow: 1 }}>
                                            <Sync />
                                            <ListItemContent sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Typography level="title-sm">Updater Agent</Typography>
                                            </ListItemContent>
                                        </Box>
                                        <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
                                    </StyledListItemButton>
                                )}
                            >
                                <List sx={{ gap: 0.5 }}>
                                    <ListItem sx={{ mt: 0.5 }}>
                                        <ListItemButton
                                            onClick={() => navigate('/updater-agent')}
                                            sx={{
                                                backgroundColor: isActive('/updater-agent') ? 'primary.main' : 'transparent',
                                                color: isActive('/updater-agent') ? '#007FFF' : 'inherit',
                                                '&:hover': { backgroundColor: 'primary.light' },
                                                fontWeight: isActive('/updater-agent') ? 'bold' : 'normal',
                                            }}
                                        >
                                            Dashboard
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem sx={{ mt: 0.5 }}>
                                        <ListItemButton
                                            onClick={() => navigate('/updater-agent/loads')}
                                            sx={{
                                                backgroundColor: isActive('/updater-agent/loads') ? 'primary.main' : 'transparent',
                                                color: isActive('/updater-agent/loads') ? '#007FFF' : 'inherit',
                                                '&:hover': { backgroundColor: 'primary.light' },
                                                fontWeight: isActive('/updater-agent/loads') ? 'bold' : 'normal',
                                            }}
                                        >
                                            Ongoing Loads
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Toggler>
                        </ListItem>
                        <ListItem nested>
                            <StyledListItemButton disabled>
                                <LocalPolice />
                                <ListItemContent>
                                    <Typography level="title-sm">Compliance Agent</Typography>
                                </ListItemContent>
                                <Chip
                                    size="sm"
                                    variant="soft"
                                    color="warning"
                                    sx={{
                                        '--Chip-minHeight': '20px',
                                        '--Chip-paddingInline': '8px',
                                        fontSize: '11px',
                                    }}
                                >
                                    Coming Soon
                                </Chip>
                            </StyledListItemButton>
                        </ListItem>
                        <ListItem nested>
                            <Toggler
                                renderToggle={() => (
                                    <StyledListItemButton disabled>
                                        <People />
                                        <ListItemContent>
                                            <Typography level="title-sm">HR Agent</Typography>
                                        </ListItemContent>
                                        <Chip
                                            size="sm"
                                            variant="soft"
                                            color="warning"
                                            sx={{
                                                '--Chip-minHeight': '20px',
                                                '--Chip-paddingInline': '8px',
                                                fontSize: '11px',
                                            }}
                                        >
                                            Coming Soon
                                        </Chip>
                                    </StyledListItemButton>
                                )}
                            >
                                <List sx={{ gap: 0.5 }}>
                                    <ListItem sx={{ mt: 0.5 }}>
                                        <StyledListItemButton disabled>Dashboard</StyledListItemButton>
                                    </ListItem>
                                    <ListItem>
                                        <StyledListItemButton disabled>Candidates</StyledListItemButton>
                                    </ListItem>
                                    <ListItem>
                                        <StyledListItemButton disabled>Settings</StyledListItemButton>
                                    </ListItem>
                                </List>
                            </Toggler>
                        </ListItem>
                    </List>
                    <List
                        size="sm"
                        sx={{
                            mt: 'auto',
                            flexGrow: 0,
                            '--ListItem-radius': (theme) => theme.vars.radius.sm,
                            '--List-gap': '8px',
                            mb: 2,
                        }}
                    >
                        <ListItem>
                            <ListItemButton disabled>
                                <SupportRoundedIcon />
                                Support
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                onClick={() => navigate('settings')}
                                sx={{
                                    backgroundColor: isActive('/settings') ? 'primary.main' : 'transparent',
                                    color: isActive('/settings') ? '#007FFF' : 'inherit',
                                    '&:hover': { backgroundColor: 'primary.light' },
                                    fontWeight: isActive('/settings') ? 'bold' : 'normal',
                                }}
                            >
                                <SettingsRoundedIcon />
                                Settings
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Avatar variant="outlined" size="sm">
                        <Person2Icon />
                    </Avatar>
                    <Box sx={{ minWidth: 0, flex: 1 }}>
                        <Typography level="title-sm">{application?.projectName || 'Akmal P.'}</Typography>
                        <Typography level="body-xs">{user?.email}</Typography>
                    </Box>
                    <IconButton onClick={logout} size="sm" variant="plain" color="neutral">
                        <LogoutRoundedIcon />
                    </IconButton>
                </Box>
            </Sheet>
            <DispatcherAgentSetupModal open={wizardOpen} onClose={() => setWizardOpen(false)} onComplete={() => setEnabled(true)} />
        </>
    )
}
