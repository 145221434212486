import React from 'react'
import { FormControl, FormHelperText, Stack } from '@mui/joy'
import { FieldError } from 'react-hook-form'

interface FormFieldProps {
    children: React.ReactNode
    error?: FieldError
}

export const FormField: React.FC<FormFieldProps> = ({ children, error }) => {
    // Extract FormLabel and Input components from children
    const childrenArray = React.Children.toArray(children)
    const formLabel = childrenArray[0]
    const restChildren = childrenArray.slice(1)

    return (
        <FormControl error={!!error} sx={{ mb: 1 }}>
            {/* Render label and error in the same row */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 0.5 }}>
                {formLabel}
                {error && (
                    <FormHelperText
                        sx={{
                            m: 0,
                            fontSize: '0.75rem',
                            lineHeight: 1.2,
                            color: 'var(--joy-palette-danger-500)',
                        }}
                    >
                        {error.message}
                    </FormHelperText>
                )}
            </Stack>

            {/* Render the rest of the children (input, etc) */}
            {restChildren}
        </FormControl>
    )
}

export default FormField
