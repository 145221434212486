import {
    AgentState,
    BarVisualizer,
    DisconnectButton,
    LiveKitRoom,
    RoomAudioRenderer,
    useVoiceAssistant,
    VoiceAssistantControlBar,
} from '@livekit/components-react'
import { Box, Button, Typography } from '@mui/joy'
import { AnimatePresence, motion } from 'framer-motion'
import { MediaDeviceFailure } from 'livekit-client'
import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'

// Default settings for the LiveKit agent
const defaultSettings = {
    carrier: {
        rate: {
            initialSpeech:
                'Hi there! This is John from ABC Logistics, calling about your load from Chicago, Illinois to Dallas, Texas. May I know the rate for that load?',
            systemPrompt: `**Role**: Senior dispatcher specializing in asking for the rate of a load

**Core Mission**:
1. You'll be calling on the call with broker to ask for the rate of a load, just for that, no need to negotiate or close the deal
2. If broker says the load is gone (not available), politely say thanks and goodbye (we're not interested in other loads if broker suggests)

**Rules**:
- We're here to just know the rate for now`,
        },
        negotiation: {
            initialSpeech:
                'Hi there! This is John from ABC Logistics, calling about your load from Chicago, Illinois to Dallas, Texas.  May I know the rate for that load?',
            systemPrompt: `**Role**: Senior dispatcher specializing in rate negotiations

**Core Mission**:
1. You'll be talking on the call with broker to negotiate rate for load and close the deal with as high rate as possible
2. If broker says the load is gone (not available), politely say thanks and goodbye (we're not interested in other loads if broker suggests)

**Rules**:
- Deploy casual industry lingo ("skinny rate", "chicken feed")
- Broker uses casual words like 2k, 2 grand, handle those numbers correctly`,
        },
        update: {
            initialSpeech:
                "Hi there! This is John from ABC Logistics, calling about your load from Chicago, Illinois to Dallas, Texas. I'm calling to provide an update on the load status, it's currently in transit and expected to arrive in 3 days.",
            systemPrompt: `**Role**: Senior dispatcher providing updates about a load

**Core Mission**:
1. You'll be just telling the broker the current status of the load

**Rules**:
- Your only mission is to tell the broker the current status of the load`,
        },
    },
    broker: {
        rate: {
            initialSpeech:
                'Hello, this is John from ABC Logistics. I have a load from Chicago, Illinois to Dallas, Texas. How much does that load cost for you?',
            systemPrompt: `**Role**: Senior broker specializing in asking dispatcher how much the load costs for them

**Core Mission**:
1. You'll be talking on the call with dispatcher to ask how much the load costs for them, just for that, no need to negotiate or close the deal
2. If dispatcher says they can't accept the load, politely say thanks and goodbye (we don't have any other loads to offer yet)

**Rules**:
- We're here to just know how much the load costs for them`,
        },
        negotiation: {
            initialSpeech:
                'Hello, this is John from ABC Logistics. I have a load from Chicago, Illinois to Dallas, Texas. How much does that load cost for you?',
            systemPrompt: `**Role**: Senior broker specializing in asking dispatcher how much the load costs for them

**Core Mission**:
1. You'll be talking on the call with dispatcher to negotiate rate for your load and close the deal with as lowest rate as possible
2. If dispatcher says they can't accept the load, politely say thanks and goodbye (we don't have any other loads to offer yet)

**Rules**:
- Deploy casual industry lingo ("fat rate", "a hefty sum")
- Broker uses casual words like 2k, 2 grand, handle those numbers correctly`,
        },
        update: {
            initialSpeech: "Hello, I'm calling for asking for an update about the load from Chicago, Illinois to Dallas, Texas.",
            systemPrompt: `**Role**: Senior broker providing updates about a load

**Core Mission**:
1. You'll be just asking the dispatcher for the current status of the load

**Rules**:
- Your only mission is to ask the dispatcher for the current status of the load`,
        },
    },
    company: {
        name: 'ABC Logistics',
        mcNumber: 'MC-123456',
    },
    load: {
        pickup: 'Chicago, IL',
        delivery: 'Dallas, TX',
        earliestDate: '2025-03-25',
        latestDate: '2025-03-28',
    },
    priceRange: {
        min: 1800,
        max: 2500,
    },
}

// Default LiveKit configuration
const defaultLivekitConfig = {
    // Default configuration values
    useKrisp: true,
}

// Interface for connection details
interface ConnectionDetails {
    participantToken: string
    serverUrl: string
}

// Component to display a notification when no agent is available
const NoAgentNotification = ({ state }: { state: AgentState }) => {
    if (state === 'connecting') {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    inset: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'rgba(0, 0, 0, 0.7)',
                    color: 'white',
                }}
            >
                <Box sx={{ textAlign: 'center' }}>
                    <Typography level="h4" sx={{ fontWeight: 'bold' }}>
                        Connecting to agent...
                    </Typography>
                    <Typography level="body-sm" sx={{ mt: 1 }}>
                        Please wait while we connect you to an available agent.
                    </Typography>
                </Box>
            </Box>
        )
    }

    return null
}

export function DemoLivekitAgent({ talkTo, scenario, disabled }: { talkTo: string; scenario: string; disabled?: boolean }) {
    const [connectionDetails, updateConnectionDetails] = useState<ConnectionDetails | undefined>(undefined)
    const [agentState, setAgentState] = useState<AgentState>('disconnected')

    const onConnectButtonClicked = useCallback(async () => {
        const settings = defaultSettings

        const initialSpeech = settings?.[talkTo]?.[scenario]?.initialSpeech || ''
        const systemPrompt = settings?.[talkTo]?.[scenario]?.systemPrompt || ''
        const companyDetails = settings.company
        const load = settings.load
        const priceRange = settings.priceRange

        let config = localStorage.getItem('agentConfig')
        config = config ? JSON.parse(config) : defaultLivekitConfig

        const response = await axios.post(`/livekit/room-connection-details`, {
            systemPrompt,
            initialSpeech,
            config,
            companyDetails,
            load,
            priceRange,
            role: talkTo,
            scenario,
        })
        const connectionDetailsData = response.data
        updateConnectionDetails(connectionDetailsData.data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [talkTo, scenario])

    return (
        <Box sx={{ flex: 1 }}>
            <LiveKitRoom
                token={connectionDetails?.participantToken}
                serverUrl={connectionDetails?.serverUrl}
                connect={connectionDetails !== undefined}
                audio={true}
                video={false}
                onMediaDeviceFailure={onDeviceFailure}
                onDisconnected={() => {
                    updateConnectionDetails(undefined)
                }}
                className="grid grid-rows-[2fr_1fr] items-center"
            >
                <SimpleVoiceAssistant onStateChange={setAgentState} />
                <ControlBar onConnectButtonClicked={onConnectButtonClicked} agentState={agentState} disabled={disabled} />
                <RoomAudioRenderer />
                <NoAgentNotification state={agentState} />
            </LiveKitRoom>
        </Box>
    )
}

function SimpleVoiceAssistant(props: { onStateChange: (state: AgentState) => void }) {
    const { state, audioTrack } = useVoiceAssistant()
    useEffect(() => {
        props.onStateChange(state)
    }, [props, state])
    return (
        <Box sx={{ height: '300px', maxWidth: '90vw', mx: 'auto' }}>
            <BarVisualizer state={state} barCount={5} trackRef={audioTrack} className="agent-visualizer" options={{ minHeight: 24 }} />
        </Box>
    )
}

function ControlBar(props: { onConnectButtonClicked: () => void; agentState: AgentState; disabled?: boolean }) {
    return (
        <Box sx={{ position: 'relative', height: '100px' }}>
            <AnimatePresence>
                {props.agentState === 'disconnected' && (
                    <motion.div
                        initial={{ opacity: 0, top: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0, top: '-10px' }}
                        transition={{ duration: 1, ease: [0.09, 1.04, 0.245, 1.055] }}
                        style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
                    >
                        <Button
                            variant="solid"
                            color="primary"
                            disabled={props.disabled}
                            onClick={!props.disabled ? () => props.onConnectButtonClicked() : undefined}
                            sx={{
                                textTransform: 'uppercase',
                                px: 4,
                                py: 1.5,
                                borderRadius: 'md',
                                opacity: props.disabled ? 0.5 : 1,
                                cursor: props.disabled ? 'not-allowed' : 'pointer',
                            }}
                        >
                            Start a conversation
                        </Button>
                    </motion.div>
                )}
            </AnimatePresence>
            <AnimatePresence>
                {props.agentState !== 'disconnected' && props.agentState !== 'connecting' && (
                    <motion.div
                        initial={{ opacity: 0, top: '10px' }}
                        animate={{ opacity: 1, top: 0 }}
                        exit={{ opacity: 0, top: '-10px' }}
                        transition={{ duration: 0.4, ease: [0.09, 1.04, 0.245, 1.055] }}
                        style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
                    >
                        <Box sx={{ display: 'flex', height: '32px', justifyContent: 'center' }}>
                            <VoiceAssistantControlBar controls={{ leave: false }} />
                            <DisconnectButton>
                                <CloseIcon />
                            </DisconnectButton>
                        </Box>
                    </motion.div>
                )}
            </AnimatePresence>
        </Box>
    )
}

function onDeviceFailure(error?: MediaDeviceFailure) {
    console.error(error)
    alert('Error acquiring camera or microphone permissions. Please make sure you grant the necessary permissions in your browser and reload the tab')
}

function CloseIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33398 3.33334L12.6673 12.6667M12.6673 3.33334L3.33398 12.6667" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
        </svg>
    )
}
