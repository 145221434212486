import { Box } from '@mui/joy'
import { OngoingLoads } from './components/OngoingLoads'

export const OngoingLoadsPage = () => {
    return (
        <Box>
            <OngoingLoads />
        </Box>
    )
}
