/* eslint-disable @typescript-eslint/no-explicit-any */
import StopIcon from '@mui/icons-material/Stop'
import { Box, Button, Input, Option, Select, Tooltip } from '@mui/joy'
import Grid from '@mui/material/Grid2'
import { LoadFindResultState, SearchParams, SearchStatus, Truck } from '@numeo/types'
import axios, { AxiosError } from 'axios'
import { LocationSearch } from 'components/LocationSearch/LocationSearch'
import MultiLocationSelect from 'components/LocationSearch/MultiLocationSelect'
import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { format } from 'date-fns'
import * as yup from 'yup'
import { DateRangePicker } from './DateRangePicker'
import { EquipmentMultiSelect } from './EquipmentMultiSelect'
import { deepEqual } from '../../../../../../../utils'
import { fireEvent } from 'setup/Analytics'
import { useAuth } from 'hooks/auth'
import { useDispatch } from 'react-redux'
import { clearTruckLoads } from 'store/spotFinderSlice'
import { addNotification } from 'store/notificationSlice'
import { Category } from 'hooks/useNotification'

const validationSchema = yup.object({
    origin: yup.object({
        stateProv: yup.string().required('State/province is required'),
        city: yup.string().required('City is required, select one from the list'),
        longitude: yup.number().required('Longitude is required'),
        latitude: yup.number().required('Latitude is required'),
    }),
    destination: yup.object({
        stateProv: yup.string(),
        city: yup.string(),
        longitude: yup.number(),
        latitude: yup.number(),
        states: yup.array(yup.string()),
        destinationType: yup.string(),
    }),
    equipment: yup
        .object({
            type: yup.string(),
            values: yup.array(yup.string()).min(1, 'Equipment is required'),
        })
        .required('Equipment is required'),
    loadType: yup.string().required('Load type is required'),
    length: yup.number().min(0, 'Length must be positive'),
    weight: yup.number().min(0, 'Weight must be positive'),
    earliestAvailability: yup.string().required('Earliest availability is required'),
    latestAvailability: yup.string().required('Latest availability is required'),
    maxOriginDeadheadMiles: yup.number().min(1, 'Max origin deadhead miles must be positive'),
    maxDestinationDeadheadMiles: yup.number().min(1, 'Max destination deadhead miles must be positive'),
})

const getCurrentDateString = () => {
    const date = new Date()
    // Format as MM/DD/YYYY to match what DateRangePicker expects
    return format(date, 'MM/dd/yyyy')
}

const getDefaultState = (truck: Truck): SearchParams => ({
    origin: { stateProv: '', city: '', postalCode: '', longitude: 0, latitude: 0 },
    destination: {
        destinationType: 'open',
    },
    equipment: truck?.equipment,
    loadType: truck?.fullPartial || 'BOTH',
    earliestAvailability: getCurrentDateString(),
    latestAvailability: getCurrentDateString(),
    maximumLengthFeet: truck?.length ? Number(truck.length) : undefined,
    maximumWeightPounds: truck?.weight ? Number(truck.weight) : undefined,
    maxOriginDeadheadMiles: Number(truck?.deadHeadOrigin) || 150,
    maxDestinationDeadheadMiles: Number(truck?.deadHeadDestination) || 150,
})

interface Props {
    data: LoadFindResultState | null
    truck: Truck
    searchStatus: SearchStatus
    setSearchStatus: (status: SearchStatus) => void
    searchReduxStatus: SearchStatus
}

const SearchBar: React.FC<Props> = React.memo(
    ({ data, truck, searchStatus, setSearchStatus, searchReduxStatus }) => {
        const { application } = useAuth()
        const dispatch = useDispatch()
        const [loading, setLoading] = useState<boolean>(false)
        const [destinationType, setDestinationType] = useState<'open' | 'city-state' | 'states'>('open')

        const isSearching = useMemo(() => searchStatus === SearchStatus.searchStarted || searchStatus === SearchStatus.searchInitialized, [searchStatus])

        const formik = useFormik({
            initialValues: data?.searchParams || getDefaultState(truck),
            validationSchema: validationSchema,
            onSubmit: async (values) => {
                try {
                    // Clear old loads for this truck before starting new search
                    dispatch(clearTruckLoads(truck._id))

                    fireEvent('spot_search_start', searchStatus)
                    setLoading(true)

                    // Set search status to initialized before making the request
                    setSearchStatus(SearchStatus.searchInitialized)

                    await axios.post(`/trucks/spot-search/${truck._id}/start`, {
                        searchParams: {
                            ...values,
                            origin: {
                                city: values.origin.city,
                                stateProv: values.origin.stateProv,
                                latitude: values.origin.latitude,
                                longitude: values.origin.longitude,
                            },
                            destination: {
                                destinationType,
                                ...mapDestinationToSearchArgs(values.destination),
                            },
                            latestWhen: values.latestAvailability,
                            earliestWhen: values.earliestAvailability,
                        },
                    })
                } catch (error: unknown) {
                    console.error('Error starting search:', error)
                    setSearchStatus(SearchStatus.searchFinished)

                    // Add error notification with type-safe error handling
                    const errorMessage = (() => {
                        if (axios.isAxiosError(error)) {
                            // Handle Axios error with type safety
                            const axiosError = error as AxiosError<{ message?: string }>
                            return axiosError.response?.data?.message || axiosError.message
                        } else if (error instanceof Error) {
                            // Handle standard Error object
                            return error.message
                        } else {
                            // Handle unknown error type
                            return 'Unknown error occurred'
                        }
                    })()

                    console.error('Error message:', errorMessage)

                    dispatch(
                        addNotification({
                            id: '',
                            category: Category.Error,
                            message: 'Failed to start spot search',
                            secondaryMessage: errorMessage,
                            timestamp: Date.now(),
                            type: 'error',
                            topic: 'permanent',
                        })
                    )
                } finally {
                    setLoading(false)
                }
            },
        })

        useEffect(() => {
            if (data) {
                setSearchStatus(data.searchStatus)
                setDestinationType(data?.searchParams?.destination?.destinationType || 'open')
            }

            formik.resetForm({
                values: data?.searchParams || getDefaultState(truck),
            })

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [data, truck])

        const cancelSearch = async () => {
            try {
                fireEvent('spot_search_cancel', searchStatus)
                setLoading(true)
                await axios.post(`/trucks/spot-search/${truck._id}/cancel`, {})

                data && (data.searchStatus = SearchStatus.searchFinished)
                setSearchStatus(SearchStatus.searchFinished)
            } catch (error) {
                console.error('Error canceling search:', error)
            } finally {
                setLoading(false)
            }
        }

        const handleDateRangeChange = (newValue: { startDate: string; endDate: string }) => {
            formik.setFieldValue('earliestAvailability', newValue.startDate)
            formik.setFieldValue('latestAvailability', newValue.endDate)
        }

        const handleChange = (prop: string, value: any) => (eventOrValue) => {
            const val = eventOrValue && eventOrValue.target ? eventOrValue.target.value : eventOrValue
            console.log(prop, value)
            formik.setFieldValue(prop, val)
        }

        return (
            <Box
                id="search_stupid"
                sx={{
                    position: 'relative',
                    width: '100%',
                    backgroundColor: 'background.level1',
                    borderRadius: 'sm',
                    '& input[type="number"]': {
                        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                            WebkitAppearance: 'none',
                            margin: 0,
                        },
                        MozAppearance: 'textfield',
                    },
                }}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={{ xs: 1, sm: 1 }} sx={{ width: '100%', p: { xs: 1, sm: 1 }, display: 'flex', flexGrow: 1 }}>
                        {/* Origin Group */}
                        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2.5, xl: 2.5 }} sx={{ flexGrow: 1 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    gap: { xs: 0.5, sm: 0.75, md: 1 },
                                    width: '100%',
                                }}
                            >
                                <Tooltip
                                    title={formik.touched.origin && (formik.errors.origin as any)?.city ? (formik.errors.origin as any).city : ''}
                                    placement="top"
                                    color="danger"
                                    size="sm"
                                    component="span"
                                    arrow
                                    open={Boolean(formik.touched.origin && (formik.errors.origin as any)?.city)}
                                    variant="solid"
                                    sx={{ flex: 1, minWidth: 0, display: 'flex' }}
                                >
                                    <Box sx={{ display: 'flex', flex: 1, minWidth: 0 }}>
                                        <LocationSearch
                                            placeholder="Origin"
                                            isDisabled={isSearching}
                                            defaultLocation={formik.values.origin}
                                            onLocationSelect={(location) => {
                                                formik.setFieldValue('origin', {
                                                    city: location.city,
                                                    stateProv: location.stateCode,
                                                    latitude: location.latitude,
                                                    longitude: location.longitude,
                                                })
                                                formik.setFieldTouched('origin', true, false)
                                            }}
                                            onRemove={() => {
                                                formik.setFieldValue('origin', {
                                                    city: '',
                                                    stateProv: '',
                                                    latitude: 0,
                                                    longitude: 0,
                                                })
                                                formik.setFieldTouched('origin', true, false)
                                            }}
                                        />
                                    </Box>
                                </Tooltip>
                                <Tooltip
                                    title={application?.integrations?.truckStop ? 'TruckStop API supports 25-300 miles range' : ''}
                                    placement="top"
                                    variant="outlined"
                                    color="primary"
                                    sx={{ display: 'flex' }}
                                    open={application?.integrations?.truckStop ? undefined : false}
                                >
                                    <Input
                                        type="number"
                                        disabled={isSearching}
                                        name="maxOriginDeadheadMiles"
                                        value={formik.values.maxOriginDeadheadMiles}
                                        onChange={(e) => handleChange('maxOriginDeadheadMiles', Number(e.target.value))(e)}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.origin && (formik.errors.origin as any)?.maxOriginDeadheadMiles !== undefined}
                                        placeholder="DH"
                                        aria-label="Max origin deadhead miles"
                                        sx={{
                                            width: { xs: '100%', sm: '5rem' },
                                            minWidth: { xs: 'auto', sm: '5rem' },
                                        }}
                                    />
                                </Tooltip>
                            </Box>
                        </Grid>

                        {/* Destination Group */}
                        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2.5, xl: 2.5 }} sx={{ flexGrow: 1 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    gap: { xs: 0.5, sm: 0.75, md: 1 },
                                    width: '100%',
                                }}
                            >
                                <Box sx={{ flex: 1, minWidth: 0 }}>
                                    <MultiLocationSelect
                                        disabled={isSearching}
                                        defaultLocation={formik.values.destination}
                                        onLocationSelect={(location) => {
                                            setDestinationType('city-state')
                                            formik.setValues({
                                                ...formik.values,
                                                destination: {
                                                    city: location.city,
                                                    stateProv: location.stateCode,
                                                    destinationType: 'city-state',
                                                    latitude: location.latitude,
                                                    longitude: location.longitude,
                                                },
                                            })
                                        }}
                                        onStateSelect={(states) => {
                                            setDestinationType('states')
                                            formik.setValues({
                                                ...formik.values,
                                                destination: {
                                                    states: states.map((s) => s.stateCode),
                                                    destinationType: 'states',
                                                },
                                            })
                                        }}
                                        onBlur={() => {
                                            setDestinationType('open')
                                            formik.setValues({
                                                ...formik.values,
                                                destination: {
                                                    destinationType: 'open',
                                                },
                                            })
                                        }}
                                        placeholder="Destination"
                                        limit={10}
                                    />
                                </Box>
                                <Tooltip
                                    title={application?.integrations?.truckStop ? 'TruckStop API supports 25-300 miles range' : ''}
                                    placement="top"
                                    variant="outlined"
                                    color="primary"
                                    sx={{ display: 'flex' }}
                                    open={application?.integrations?.truckStop ? undefined : false}
                                >
                                    <Input
                                        type="number"
                                        name="maxDestinationDeadheadMiles"
                                        disabled={isSearching}
                                        value={formik.values.maxDestinationDeadheadMiles}
                                        onChange={(e) => handleChange('maxDestinationDeadheadMiles', Number(e.target.value))(e)}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.destination && (formik.errors.destination as any)?.maxDestinationDeadheadMiles !== undefined}
                                        placeholder="DH"
                                        aria-label="Max destination deadhead miles"
                                        sx={{
                                            width: { xs: '100%', sm: '5rem' },
                                            minWidth: { xs: 'auto', sm: '5rem' },
                                        }}
                                    />
                                </Tooltip>
                            </Box>
                        </Grid>

                        {/* Length & Weight Group */}
                        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 1.5, xl: 1.5 }} sx={{ flexGrow: 1 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    gap: { xs: 0.5, sm: 0.75, md: 1 },
                                    width: '100%',
                                }}
                            >
                                <Input
                                    type="number"
                                    name="length"
                                    disabled={isSearching}
                                    value={formik.values.maximumLengthFeet || ''}
                                    onChange={(e) => handleChange('maximumLengthFeet', Number(e.target.value))(e)}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.maximumLengthFeet && Boolean(formik.errors.maximumLengthFeet)}
                                    placeholder="Length (ft)"
                                    sx={{ flex: 1 }}
                                />
                                <Input
                                    type="number"
                                    name="weight"
                                    value={formik.values.maximumWeightPounds || ''}
                                    disabled={isSearching}
                                    onChange={(e) => handleChange('maximumWeightPounds', Number(e.target.value))(e)}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.maximumWeightPounds && Boolean(formik.errors.maximumWeightPounds)}
                                    placeholder="Weight (lbs)"
                                    sx={{ flex: 1 }}
                                />
                            </Box>
                        </Grid>

                        {/* Truck and LoadType Group */}
                        <Grid size={{ xs: 12, sm: 6, md: 5, lg: 2.5, xl: 2.5 }} sx={{ flexGrow: 1 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    gap: { xs: 0.5, sm: 0.75, md: 1 },
                                    width: '100%',
                                }}
                            >
                                <Tooltip
                                    title={formik.touched.equipment && formik.errors.equipment ? 'Equipment is required' : ''}
                                    placement="top"
                                    arrow
                                    color="danger"
                                    size="sm"
                                    variant="solid"
                                    open={Boolean(formik.touched.equipment && formik.errors.equipment)}
                                    sx={{
                                        flex: { xs: '1 1 100%', sm: '0 0 50%' },
                                        maxWidth: { xs: '100%', sm: '50%' },
                                    }}
                                >
                                    <Box sx={{ width: '100%' }}>
                                        <EquipmentMultiSelect disabled={isSearching} formik={formik} />
                                    </Box>
                                </Tooltip>

                                <Select
                                    name="loadType"
                                    value={formik.values.loadType}
                                    disabled={isSearching}
                                    onChange={(_, newValue) => {
                                        formik.setFieldValue('loadType', newValue)
                                    }}
                                    onBlur={formik.handleBlur}
                                    sx={{
                                        width: '100%',
                                        maxWidth: { xs: '100%', sm: '10rem' },
                                    }}
                                >
                                    <Option
                                        value="BOTH"
                                        sx={{
                                            fontSize: {
                                                xs: '0.9rem',
                                                sm: '0.94rem',
                                                md: '0.97rem',
                                                lg: '1rem',
                                            },
                                        }}
                                    >
                                        Full & Partial
                                    </Option>
                                    <Option
                                        value="FULL"
                                        sx={{
                                            fontSize: {
                                                xs: '0.9rem',
                                                sm: '0.94rem',
                                                md: '0.97rem',
                                                lg: '1rem',
                                            },
                                        }}
                                    >
                                        Full
                                    </Option>
                                    <Option
                                        value="PARTIAL"
                                        sx={{
                                            fontSize: {
                                                xs: '0.9rem',
                                                sm: '0.94rem',
                                                md: '0.97rem',
                                                lg: '1rem',
                                            },
                                        }}
                                    >
                                        Partial
                                    </Option>
                                </Select>
                            </Box>
                        </Grid>

                        {/* Date Range Group */}
                        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2, xl: 2 }} sx={{ flexGrow: 1 }}>
                            <Tooltip
                                title={application?.integrations?.truckStop ? 'TruckStop only supports a 7-day date range' : ''}
                                placement="top"
                                variant="outlined"
                                color="primary"
                                open={application?.integrations?.truckStop ? undefined : false}
                            >
                                <Box display="flex" width="100%">
                                    <DateRangePicker
                                        value={{
                                            startDate: formik.values.earliestAvailability,
                                            endDate: formik.values.latestAvailability,
                                        }}
                                        disabled={isSearching}
                                        onChange={handleDateRangeChange}
                                        sx={{ width: '100%' }}
                                        aria-label="Select date range"
                                    />
                                </Box>
                            </Tooltip>
                        </Grid>

                        {/* Search Button Group */}
                        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 1, xl: 1 }} sx={{ flexGrow: 1 }}>
                            <Box sx={{ width: '100%', height: '40px' }}>
                                {searchStatus && searchStatus !== SearchStatus.searchFinished ? (
                                    <Button
                                        variant="solid"
                                        color="primary"
                                        loading={loading}
                                        disabled={searchStatus === SearchStatus.searchInitialized && searchReduxStatus === SearchStatus.searchInitialized}
                                        onClick={cancelSearch}
                                        sx={{ width: '100%' }}
                                        endDecorator={
                                            <StopIcon
                                                sx={{
                                                    color: 'red',
                                                    animation: 'pulse 1.5s infinite',
                                                    '@keyframes pulse': {
                                                        '0%': {
                                                            opacity: 1,
                                                        },
                                                        '50%': {
                                                            opacity: 0.5,
                                                        },
                                                        '100%': {
                                                            opacity: 1,
                                                        },
                                                    },
                                                }}
                                            />
                                        }
                                    >
                                        Stop
                                    </Button>
                                ) : (
                                    <Button sx={{ width: '100%' }} variant="solid" color="primary" loading={loading} type="submit">
                                        Search
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        )
    },
    (prevProps, nextProps) => {
        // Only re-render if searchParams or other critical props change
        return (
            deepEqual(prevProps.data?.searchParams, nextProps.data?.searchParams) &&
            deepEqual(prevProps.truck, nextProps.truck) &&
            prevProps.searchStatus === nextProps.searchStatus &&
            prevProps.searchReduxStatus === nextProps.searchReduxStatus
        )
    }
)

export default SearchBar

const mapDestinationToSearchArgs = (destination: SearchParams['destination']) => {
    switch (destination.destinationType) {
        case 'city-state':
            return {
                city: destination.city,
                stateProv: destination.stateProv,
                longitude: destination.longitude,
                latitude: destination.latitude,
            }
        case 'states':
            return {
                states: destination.states,
            }
        case 'open':
            return {}
    }
}
