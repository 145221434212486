import { AccessTime, BarChart, Call as CallIcon, CheckCircle } from '@mui/icons-material'
import { Box, Card, CircularProgress, Grid, Stack, Typography } from '@mui/joy'
import React, { FC, useMemo } from 'react'
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { IStatistics } from '../../utils/types'

const CallStatistics: FC<IStatistics> = ({ pastCalls, loading }) => {
    const averageCallDuration = useMemo(() => {
        if (!pastCalls || pastCalls.length === 0) return '0:00'

        const completedCalls = pastCalls.filter((call) => 'endedAt' in call && call.endedAt)
        if (completedCalls.length === 0) return '0:00'

        const totalDuration = completedCalls
            .filter((call) => call.endedAt)
            .reduce((sum, call) => {
                return sum + (call.endedAt! - call.startedAt)
            }, 0)

        const avgDurationInSeconds = Math.floor(totalDuration / completedCalls.length / 1000)
        const minutes = Math.floor(avgDurationInSeconds / 60)
        const seconds = avgDurationInSeconds % 60

        return `${minutes}:${seconds.toString().padStart(2, '0')}`
    }, [pastCalls])

    const trendData = useMemo(() => {
        if (!pastCalls?.length) return []

        // Create a map to store call counts by hour
        const hourlyCallCounts = new Map<string, number>()

        // Get current date for filtering
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

        // Initialize hours from 0 to 23
        for (let i = 0; i < 24; i++) {
            const hour = i.toString().padStart(2, '0')
            hourlyCallCounts.set(`${hour}:00`, 0)
        }

        // Count calls for each hour
        pastCalls.forEach((call) => {
            const callDate = new Date(call.startedAt)
            // Only include today's calls
            if (callDate >= today) {
                const hour = callDate.getHours().toString().padStart(2, '0')
                const timeKey = `${hour}:00`
                hourlyCallCounts.set(timeKey, (hourlyCallCounts.get(timeKey) || 0) + 1)
            }
        })

        // Convert map to array and sort by time
        return Array.from(hourlyCallCounts.entries())
            .map(([time, calls]) => ({ time, calls }))
            .sort((a, b) => a.time.localeCompare(b.time))
    }, [pastCalls])

    // Calculate stats based on active tab
    const stats = React.useMemo(() => {
        return {
            total: pastCalls.length,
            avgDuration: averageCallDuration,
            completed: pastCalls.filter((call) => call.status === 'ended').length,
            error: pastCalls.filter((call) => call.status === 'error').length,
            trend: trendData,
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pastCalls])

    return (
        <Grid container spacing={2}>
            {/* Statistics Cards */}
            <Grid xs={6} sm={6} md={3}>
                <Card variant="outlined" sx={{ height: '100%' }}>
                    <Stack spacing={1}>
                        <Typography level="title-md" startDecorator={<CallIcon sx={{ color: 'primary.main' }} />}>
                            Total Calls
                        </Typography>
                        {loading ? <CircularProgress size="sm" /> : <Typography level="h2">{stats.total}</Typography>}
                    </Stack>
                </Card>
            </Grid>

            <Grid xs={6} sm={6} md={3}>
                <Card variant="outlined" sx={{ height: '100%' }}>
                    <Stack spacing={1}>
                        <Typography level="title-md" startDecorator={<AccessTime sx={{ color: 'warning.main' }} />}>
                            Avg Duration
                        </Typography>
                        {loading ? <CircularProgress size="sm" /> : <Typography level="h2">{stats.avgDuration || 'N/A'}</Typography>}
                    </Stack>
                </Card>
            </Grid>

            <Grid xs={6} sm={6} md={3}>
                <Card variant="outlined" sx={{ height: '100%' }}>
                    <Stack spacing={1}>
                        <Typography level="title-md" startDecorator={<CheckCircle sx={{ color: 'success.main' }} />}>
                            Completed
                        </Typography>
                        {loading ? <CircularProgress size="sm" /> : <Typography level="h2">{stats.completed}</Typography>}
                    </Stack>
                </Card>
            </Grid>

            <Grid xs={6} sm={6} md={3}>
                <Card variant="outlined" sx={{ height: '100%' }}>
                    <Stack spacing={1}>
                        <Typography level="title-md" startDecorator={<BarChart sx={{ color: 'info.main' }} />}>
                            Success Rate
                        </Typography>
                        {loading ? (
                            <CircularProgress size="sm" />
                        ) : (
                            <Typography level="h2">
                                {stats.completed + stats.error > 0 ? Math.round((stats.completed / (stats.completed + stats.error)) * 100) : 0}%
                            </Typography>
                        )}
                    </Stack>
                </Card>
            </Grid>

            {/* Trend Chart */}
            <Grid xs={12}>
                <Card variant="outlined">
                    <Typography level="title-md" sx={{ mb: 2 }}>
                        Call Volume Trend
                    </Typography>
                    <Box sx={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                            <AreaChart data={trendData}>
                                <XAxis dataKey="time" interval={2} tick={{ fontSize: 12 }} />
                                <YAxis allowDecimals={false} tick={{ fontSize: 12 }} />
                                <Area type="monotone" dataKey="calls" stroke="#0B6BCB" fill="#0B6BCB" fillOpacity={0.1} />
                            </AreaChart>
                        </ResponsiveContainer>
                    </Box>
                </Card>
            </Grid>
        </Grid>
    )
}

export default CallStatistics
