/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'

export const AUTH_TOKEN = 'accessToken'

const apiUrl = () => {
    const apiUrlParam = new URLSearchParams(window.location.search).get('apiUrl')
    if (apiUrlParam) {
        return apiUrlParam
    } else {
        const protocol = window.location.protocol === 'https:' ? 'https' : 'http'
        const apiUrl = `${protocol}://${process.env.REACT_APP_API_HOST}`
        return apiUrl
    }
}

export default function setupAxios(on401?: () => void) {
    console.log(`SetupAxios::API_URL :::: ${apiUrl()}`)
    axios.defaults.baseURL = `${apiUrl()}/v1`
    axios.defaults.headers.Accept = 'application/json'
    axios.interceptors.request.use(
        (config: any) => {
            const accessToken = localStorage.getItem(AUTH_TOKEN)
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`
            }

            // Add release date to headers if available
            if (process.env.REACT_APP_RELEASE_DATE) {
                config.headers['Release-Date'] = process.env.REACT_APP_RELEASE_DATE
            }

            return config
        },
        (err: any) => Promise.reject(err)
    )
    axios.interceptors.response.use(
        (res: any) => res,
        (err: any) => {
            const status = err.status || err.response.status
            const url = err.config?.url || ''

            // Skip redirect for login requests to allow proper error handling
            const isLoginRequest = url.includes('/auth/sign-in') || url.includes('/auth/sign-up')

            if (status === 401 && !isLoginRequest) {
                localStorage.removeItem(AUTH_TOKEN)
                window.location.href = '/auth/sign-in'
                on401?.()
            }
            return Promise.reject(err)
        }
    )
}
