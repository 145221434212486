import { List, ListItem, ListItemButton, ListItemText, Popover } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import React from 'react'
import { sortOptionsMap } from '../assets/sortOptions'
import { SortingPopoverProps } from './types'

export const SortingPopover: React.FC<SortingPopoverProps> = ({ open, anchorEl, activeColumn, onClose, onSortOptionChange, selectedSortKey }) => {
    const sortOptions = (activeColumn && sortOptionsMap[activeColumn]) || []

    return anchorEl ? (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={(e: React.MouseEvent) => {
                e.stopPropagation()
                onClose()
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <List>
                {sortOptions.map((option) => (
                    <ListItem key={option.key} disablePadding>
                        <ListItemButton onClick={() => onSortOptionChange(option.key)}>
                            <ListItemText primary={option.label} />
                            {activeColumn && selectedSortKey === option.key && <CheckIcon sx={{ ml: 1, color: 'primary.main' }} />}
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Popover>
    ) : null
}
