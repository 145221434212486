import { CallEnd, PhoneEnabled } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/joy'
import { CircularProgress } from '@mui/material'
import { Call } from '@numeo/types'
import { useCallCenter } from 'hooks/useCallCenter'
import React, { useEffect, useState } from 'react'
import callCenterApi from '../../../call-center/api'
import { TranscriptionTooltip } from '../TranscriptionTooltip'
import { addNotification } from '../../../../../../../../store/notificationSlice'
import { handlePhoneClick } from '../../api/api'
import { Category } from 'hooks/useNotification'
import { useDispatch } from 'react-redux'
import { AnimatedRingVolume, CallButtonProps, CallButtonStatus, callButtonState } from './shared/CallButtonUtils'

interface AICallButtonProps extends CallButtonProps {
    onLoadingChange?: (loading: boolean) => void
}

export const AICallButton: React.FC<AICallButtonProps> = ({ load, loadSearchId, phoneNumber, favorite, disabled = false, onLoadingChange }) => {
    // States for AI call
    const { calls } = useCallCenter()
    const [loading, setLoading] = useState(false)
    const [stoppingCall, setStoppingCall] = useState(false)
    const [requestingCall, setRequestingCall] = useState<Call | null>(null)
    const [callButtonStatus, setCallButtonStatus] = useState<CallButtonStatus>(callButtonState(load, calls, null, loading))
    const dispatch = useDispatch()
    const callIds = load.callHistory?.map((call) => call.callId) ?? []

    // AI Call handlers
    const onCallWithAIButtonClick = () => {
        return handlePhoneClick(phoneNumber, load, loadSearchId)
    }

    const handleAICallClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setCallButtonStatus(CallButtonStatus.CallInProgress)
        try {
            setLoading(true)
            onLoadingChange?.(true)
            favorite()
            const requestedCall = await onCallWithAIButtonClick()
            setRequestingCall(requestedCall)
            dispatch(
                addNotification({
                    id: '',
                    message: 'Call initiated successfully!',
                    type: 'info',
                    category: Category.CallCenter,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
        } catch (error) {
            console.error('Error:', error)
            dispatch(
                addNotification({
                    id: '',
                    message: 'Failed to initiate call.',
                    type: 'error',
                    category: Category.Error,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
            setRequestingCall(null)
        }
        setLoading(false)
        onLoadingChange?.(false)
    }

    const stopActiveAICall = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        if (callIds.length === 0) return
        try {
            if (callIds.length === 0) {
                console.error('No active call found')
                return
            }
            setStoppingCall(true)
            onLoadingChange?.(true)
            await callCenterApi.stopCall(callIds[callIds.length - 1])
            setRequestingCall(null)
            dispatch(
                addNotification({
                    id: '',
                    message: 'Call ended successfully!',
                    type: 'info',
                    category: Category.CallCenter,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
        } catch (error) {
            console.error('Failed to end the call')
            dispatch(
                addNotification({
                    id: '',
                    message: 'Failed to end the call.',
                    type: 'error',
                    category: Category.CallCenter,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
        } finally {
            setStoppingCall(false)
            onLoadingChange?.(false)
        }
    }

    // Update AI call button state
    useEffect(() => {
        setCallButtonStatus(callButtonState(load, calls, requestingCall, loading))
    }, [load, calls, requestingCall, loading])

    // Simple button appearance logic
    let buttonIcon: JSX.Element
    let tooltipTitle: string
    let handleButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void
    let isButtonDisabled = false

    // Loading state
    if (loading || stoppingCall) {
        buttonIcon = <CircularProgress size={20} />
        tooltipTitle = 'Processing...'
        handleButtonClick = () => {}
        isButtonDisabled = true
    } else if (callButtonStatus === CallButtonStatus.CallInProgress) {
        // Active AI call
        buttonIcon = <AnimatedRingVolume color="primary" />
        tooltipTitle = 'Talking. Click to stop the call'
        handleButtonClick = stopActiveAICall
    } else if (callButtonStatus === CallButtonStatus.AllCallsEnded) {
        // Completed AI call
        buttonIcon = <CallEnd color="success" />
        tooltipTitle = disabled ? 'Calling not available' : 'Call again'
        handleButtonClick = handleAICallClick
        isButtonDisabled = disabled
    } else {
        // No AI call yet
        buttonIcon = <PhoneEnabled sx={{ color: disabled ? 'grey.500' : 'primary.main', opacity: disabled ? 0.8 : 1 }} />
        tooltipTitle = disabled ? 'Calling not available' : 'Click to call'
        handleButtonClick = handleAICallClick
        isButtonDisabled = disabled
    }

    return (
        <Box onClick={(event) => event.stopPropagation()}>
            {callIds.length > 0 ? (
                <TranscriptionTooltip callIds={callIds} isActive={callButtonStatus === CallButtonStatus.CallInProgress}>
                    <IconButton className="main-call-button" component="button" onClick={handleButtonClick} disabled={isButtonDisabled}>
                        {buttonIcon}
                    </IconButton>
                </TranscriptionTooltip>
            ) : (
                <Tooltip title={tooltipTitle} variant="solid">
                    <IconButton
                        className="main-call-button"
                        component="button"
                        onClick={handleButtonClick}
                        disabled={isButtonDisabled}
                        sx={{
                            pointerEvents: 'auto !important',
                            backgroundColor: 'transparent',
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        {buttonIcon}
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    )
}
