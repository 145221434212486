import { WsErrorPayload, WsMessage, WsMessageCategory } from '.'
import { LoadFindResultState } from '../spot-finder'

// These types are passed through the backend, backend responds with the same action type
export enum LoadFinderActionTypes {
    // Must be sent when the application is first loaded
    LF_FetchAllSearchResults = 'loadFinder.fetchAllSearchResults',
    // Must be sent when the search is requested for the specific truck
    LF_PartialLoadUpdate = 'loadFinder.partialLoadUpdate',
}

// ==================== Interfaces ====================
export interface LoadFinderBaseMesage extends WsMessage {
    categoryType: WsMessageCategory.LoadFinder
    data: IAllLoadFinderResultsMessage | IPartialLoadUpdateMessage
}
export interface IAllLoadFinderResultsMessage {
    action: LoadFinderActionTypes.LF_FetchAllSearchResults
    searchResultMap: Record<string, LoadFindResultState>
}
export interface IPartialLoadUpdateMessage {
    action: LoadFinderActionTypes.LF_PartialLoadUpdate
    searchResult: LoadFindResultState
}
// ==================== Concreet classes ====================
export class AllLoadFinderResultsMessage implements LoadFinderBaseMesage {
    readonly categoryType = WsMessageCategory.LoadFinder
    readonly data: IAllLoadFinderResultsMessage
    readonly error?: WsErrorPayload

    constructor(searchResultMap: Record<string, LoadFindResultState>, error?: WsErrorPayload) {
        this.data = { action: LoadFinderActionTypes.LF_FetchAllSearchResults, searchResultMap }
        this.error = error
    }

    static newErrorInstance(error: WsErrorPayload): AllLoadFinderResultsMessage {
        return new AllLoadFinderResultsMessage({}, error)
    }

    toJson(): LoadFinderBaseMesage {
        return {
            categoryType: WsMessageCategory.LoadFinder,
            data: this.data,
            error: this.error,
        }
    }
}

export class PartialLoadUpdateMessage implements LoadFinderBaseMesage {
    readonly categoryType = WsMessageCategory.LoadFinder
    readonly data: IPartialLoadUpdateMessage
    readonly error?: WsErrorPayload
    constructor(searchResult: LoadFindResultState, error?: WsErrorPayload) {
        this.data = { action: LoadFinderActionTypes.LF_PartialLoadUpdate, searchResult }
        this.error = error
    }

    toJson(): LoadFinderBaseMesage {
        return {
            categoryType: WsMessageCategory.LoadFinder,
            data: this.data,
            error: this.error,
        }
    }
}
