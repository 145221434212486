import { Box, Button, Card, FormControl, FormLabel, Input, Stack, Typography, IconButton } from '@mui/joy'
import { useState } from 'react'
import { useAuth } from 'hooks/auth'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'
import { DeleteRounded, AddCircleOutline } from '@mui/icons-material'
import { TteldIntegration, TteldUnit } from '@numeo/types'
import { eldApi } from 'pages/dashboard/pages/dispatcher/pages/spot-finder/api/eld-api'
import { TruckSelectionModal, DisconnectConfirmationModal, VehicleSelectionTable } from './SharedComponents'
import { Category } from 'hooks/useNotification'

export const TTELD = () => {
    const { application, setApplication } = useAuth()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [units, setUnits] = useState<TteldUnit[]>([])
    const [selectedUnits, setSelectedUnits] = useState<string[]>([])
    const [showAddTrucksModal, setShowAddTrucksModal] = useState<boolean>(false)
    const [showDisconnectConfirmation, setShowDisconnectConfirmation] = useState<boolean>(false)
    const [formData, setFormData] = useState<TteldIntegration>({
        usdot: '',
        apiKey: '',
    })

    const isTteldConnected: boolean = application?.integrations?.tteld?.usdot ? true : false

    const handleAddSelectedTrucks = async (): Promise<void> => {
        try {
            if (selectedUnits.length === 0) {
                dispatch(
                    addNotification({
                        id: '',
                        category: Category.Error,
                        message: 'Please select at least one truck',
                        timestamp: Date.now(),
                        topic: 'temp',
                        type: 'error',
                    })
                )
                return
            }

            setIsLoading(true)
            // Filter units to only include selected ones
            const trucksToAdd = units.filter((unit) => selectedUnits.includes(unit.vin))
            const response = await eldApi.addSelectedTteldTrucks(trucksToAdd)
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Settings,
                    message: `${selectedUnits.length} trucks added successfully`,
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'info',
                })
            )
            setApplication(response)
            setShowAddTrucksModal(false)
            setSelectedUnits([])
        } catch (error) {
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: 'Failed to add trucks',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'error',
                })
            )
        } finally {
            setIsLoading(false)
        }
    }

    const handleSelectAllTrucks = () => {
        if (selectedUnits.length === units.length) {
            setSelectedUnits([])
        } else {
            setSelectedUnits(units.map((unit) => unit.vin))
        }
    }

    const handleSelectTruck = (vin: string) => {
        if (selectedUnits.includes(vin)) {
            setSelectedUnits(selectedUnits.filter((id) => id !== vin))
        } else {
            setSelectedUnits([...selectedUnits, vin])
        }
    }

    const fetchTteldUnits = async (): Promise<void> => {
        try {
            setIsLoading(true)
            const fetchedUnits = await eldApi.getAllTteldUnits()
            setUnits(fetchedUnits)
            setSelectedUnits([])
            setShowAddTrucksModal(true)
        } catch (error) {
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: 'Failed to fetch trucks',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'error',
                })
            )
        } finally {
            setIsLoading(false)
        }
    }

    const handleConnect = async (): Promise<void> => {
        try {
            setIsLoading(true)

            // First connect to TTELD
            const applicationResponse = await eldApi.connectTteld(formData.usdot, formData.apiKey)

            // Try to fetch units to validate credentials
            try {
                const units = await eldApi.getAllTteldUnits()

                // If we get here, credentials are valid
                setUnits(units)
                setApplication(applicationResponse)

                // Reset form
                setFormData({ usdot: '', apiKey: '' })

                dispatch(
                    addNotification({
                        id: '',
                        category: Category.Settings,
                        message: 'TTELD connected successfully',
                        timestamp: Date.now(),
                        topic: 'temp',
                        type: 'info',
                    })
                )
            } catch (unitsError) {
                // If units fetch fails, credentials are invalid
                // Disconnect and show error
                await eldApi.disconnectTteld()

                dispatch(
                    addNotification({
                        id: '',
                        category: Category.Error,
                        message: 'Invalid credentials.',
                        timestamp: Date.now(),
                        topic: 'temp',
                        type: 'error',
                    })
                )
            }
        } catch (error) {
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: 'Failed to connect TTELD',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'error',
                })
            )
        } finally {
            setIsLoading(false)
        }
    }

    const handleDisconnect = async (): Promise<void> => {
        try {
            setIsLoading(true)
            const applicationResponse = await eldApi.disconnectTteld()
            setApplication(applicationResponse)
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Settings,
                    message: 'TTELD disconnected successfully',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'info',
                })
            )
            setShowDisconnectConfirmation(false)
        } catch (error) {
            dispatch(
                addNotification({
                    id: '',
                    category: Category.Error,
                    message: 'Failed to disconnect TTELD',
                    timestamp: Date.now(),
                    topic: 'temp',
                    type: 'error',
                })
            )
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Card variant="outlined" sx={{ p: 2, gap: 1, width: '100%' }}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <img src="/tteld.png" alt="TTELD Logo" style={{ width: 24, height: 24 }} />
                    <Box sx={{ flex: 1 }}>
                        <Typography level="title-md">TT ELD</Typography>
                    </Box>
                    {isTteldConnected && (
                        <IconButton
                            variant="soft"
                            color="danger"
                            onClick={() => setShowDisconnectConfirmation(true)}
                            loading={isLoading}
                            disabled={isLoading}
                            title="Disconnect TTELD"
                        >
                            <DeleteRounded />
                        </IconButton>
                    )}
                </Stack>

                {!isTteldConnected ? (
                    <Stack direction="column" spacing={1}>
                        <Stack direction={'row'} spacing={1}>
                            <FormControl sx={{ flex: 1 }}>
                                <FormLabel>USDOT Number</FormLabel>
                                <Input
                                    placeholder="Enter your USDOT number"
                                    value={formData.usdot}
                                    onChange={(e) => setFormData({ ...formData, usdot: e.target.value })}
                                    disabled={isLoading}
                                />
                            </FormControl>
                            <FormControl sx={{ flex: 1 }}>
                                <FormLabel>API Key</FormLabel>
                                <Input
                                    type="password"
                                    placeholder="Enter your TTELD API key"
                                    value={formData.apiKey}
                                    onChange={(e) => setFormData({ ...formData, apiKey: e.target.value })}
                                    disabled={isLoading}
                                />
                            </FormControl>
                        </Stack>
                        <Button loading={isLoading} disabled={!formData.usdot || !formData.apiKey} onClick={handleConnect}>
                            Connect TTELD
                        </Button>
                    </Stack>
                ) : (
                    <Stack spacing={2}>
                        <Typography level="body-sm" startDecorator={<Box sx={{ width: 8, height: 8, borderRadius: 'sm', bgcolor: 'success.500' }} />}>
                            Connected with USDOT: {application?.integrations?.tteld?.usdot}
                        </Typography>
                        <Button startDecorator={<AddCircleOutline />} variant="outlined" onClick={fetchTteldUnits} loading={isLoading}>
                            Add Connected Trucks
                        </Button>
                    </Stack>
                )}
            </Card>

            <TruckSelectionModal
                open={showAddTrucksModal}
                onClose={() => setShowAddTrucksModal(false)}
                title="Add Connected Trucks"
                description={`Select connected trucks from your TTELD account to add to your trucks list:`}
                selectionTable={
                    <VehicleSelectionTable
                        vehicles={units}
                        selectedVehicles={selectedUnits}
                        onSelectVehicle={handleSelectTruck}
                        onSelectAllVehicles={handleSelectAllTrucks}
                        getVehicleData={(unit) => ({
                            id: unit.vin,
                            truckNumber: unit.truck_number,
                            vin: unit.vin,
                        })}
                    />
                }
                onConfirm={handleAddSelectedTrucks}
                isLoading={isLoading}
                selectedCount={selectedUnits.length}
            />

            <DisconnectConfirmationModal
                open={showDisconnectConfirmation}
                onClose={() => setShowDisconnectConfirmation(false)}
                onConfirm={handleDisconnect}
                isLoading={isLoading}
                serviceName="TTELD"
            />
        </>
    )
}
