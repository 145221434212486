import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto'
import AssistantPhotoOutlinedIcon from '@mui/icons-material/AssistantPhotoOutlined'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import CheckIcon from '@mui/icons-material/Check'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { Box, Button, Checkbox, Chip, IconButton, Tooltip, Typography } from '@mui/joy'
import { BrokerType, ClientLoadModel, IBroker } from '@numeo/types'
import { HorizontalDirectionIcon } from 'assets/icons/DatDirectionIcons'
import { useAuth } from 'hooks/auth'
import { brokersApi } from '../../../../my-brokers/api'
import { calculateRatePerMile, formatPhoneNumber } from '../../assets/helpers'
import { AgeDisplay } from './AgeDisplay'
import { CallButton } from '../CallButton'
import { EmailButton } from '../EmailButton'
import { PopoverBookConfirmation } from '../PopoverBookConfirmation'
import { PopoverBookConfirmationProps } from '../types'
import { deepEqual } from '../../../../../../../../../utils'
import { WarningsCell } from './WarningsCell'
import { BrokerActionModal } from '../BrokerActionModal'
import React, { memo, useCallback, useContext, useEffect, useRef } from 'react'
import { TableContext } from '../NewLoadsTable'
import RateConfirmationCell from '../RateConfirmationCell'

/**
 * LoadRow component displays a single load entry in the table.
 * It handles:
 * - Load selection
 * - Expanded view toggle
 * - Email and phone interactions
 * - Rate and distance calculations
 *
 * The component is memoized to prevent unnecessary re-renders when other rows change.
 */

// CSS styles for the LoadRow component

export const isMcNumberFavorite = (brokerList: IBroker[], mcNumber: string): boolean => {
    return brokerList.some((broker) => broker.mcNumber === mcNumber && broker.type === BrokerType.FAVORITE)
}

export const isMcNumberBlacklisted = (brokerList: IBroker[], mcNumber: string): boolean => {
    return brokerList.some((broker) => broker.mcNumber === mcNumber && broker.type === BrokerType.IN_BLACKLIST)
}

// Add global styles for the table cells
const globalStyles = `
    .loads-table td {
        padding: 0 !important;
    }
`

export const LoadRow = memo<LoadRowProps>(
    ({ load, isSelected, onCheckboxClick, loadSearchId, emailThreadIds, setEmailThreadIds, handleFavoriteToggle }) => {
        const { debugData, myBrokers, setMyBrokers } = useAuth()
        const tableContext = useContext(TableContext)
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
        const popoverRef = useRef<HTMLElement | null>(null)
        // Add near other state declarations in LoadRow component
        const [showBrokerActionModal, setShowBrokerActionModal] = React.useState(false)
        const [isBrokerActionLoading, setIsBrokerActionLoading] = React.useState({
            favorite: false,
            blackList: false,
        })

        function handleBookConfirmOpen(e: React.MouseEvent<HTMLButtonElement>): void {
            e.stopPropagation()
            setAnchorEl(e.currentTarget)
            // Store reference to the button
            popoverRef.current = e.currentTarget
            // Track popover in table context
            if (tableContext?.trackOpenUIElement) {
                tableContext.trackOpenUIElement(load._id, 'bookConfirmPopover', e.currentTarget.getBoundingClientRect())
            }
        }

        const handleConfirmationPopoverClose = (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            setAnchorEl(null)
            // Remove tracking when closed
            if (tableContext?.removeOpenUIElement) {
                tableContext.removeOpenUIElement(load._id)
            }
        }

        const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
            if (load?.bookingUrl) {
                window.open(load.bookingUrl, '_blank')
            }
            handleConfirmationPopoverClose(e)
        }

        const popoverBookConfirmationProps: PopoverBookConfirmationProps = {
            anchorEl: anchorEl,
            handleClose: handleConfirmationPopoverClose,
            handleConfirm: handleConfirm,
        }

        // Derive emailThreadId from props instead of using state
        const emailThreadId = emailThreadIds[load._id] || load.emailThreadId || null

        const handleEmailSent = useCallback(
            (threadId: string) => {
                setEmailThreadIds((prev) => ({ ...prev, [load._id]: threadId }))
            },
            [load._id, setEmailThreadIds]
        )

        const convertPickupDates = (earliestAvailability: string, latestAvailability: string): JSX.Element => {
            const formatDate = (date: Date): string => {
                const month = (date.getMonth() + 1).toString()
                const day = date.getDate().toString()
                return `${month}/${day}`
            }

            const earliestDate = formatDate(new Date(earliestAvailability))
            const latestDate = formatDate(new Date(latestAvailability))

            if (earliestDate === latestDate) {
                return <Typography sx={{ fontSize: '14px' }}>{earliestDate}</Typography>
            }

            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography sx={{ fontSize: '14px' }}>{earliestDate} -</Typography>
                    <Typography sx={{ fontSize: '14px' }}>{latestDate}</Typography>
                </Box>
            )
        }

        const brokerMcNumber = load.posterInfo?.mcNumber
        const broker = myBrokers.find((b) => b.mcNumber === brokerMcNumber)

        const brokerPhoneNumber = debugData.spotFinder.mockBrokerPhoneNumber || load.posterInfo?.contact?.phoneNumber
        const brokerEmail = load.posterInfo?.contact?.email || broker?.email
        const isLoadCancelled = load.status === 'cancelled'
        // favorite broker button
        const hasValidMC = typeof brokerMcNumber === 'string' && brokerMcNumber.trim() !== '' && /^\d{5,7}$/.test(brokerMcNumber)

        const brokerTooltipContent = broker ? (
            <div>
                {broker.companyName && (
                    <div>
                        <strong>Company Name:</strong> {broker.companyName}
                    </div>
                )}
                {broker.fullName && (
                    <div>
                        <strong>Broker Contact:</strong> {broker.fullName}
                    </div>
                )}
                {broker.mcNumber && (
                    <div>
                        <strong>MC Number:</strong> {broker.mcNumber}
                    </div>
                )}
                {broker.phone && (
                    <div>
                        <strong>Phone:</strong> {broker.phone}
                    </div>
                )}
                {broker.email && (
                    <div>
                        <strong>Email:</strong> {broker.email}
                    </div>
                )}
                {broker.comment && (
                    <div>
                        <strong>Comment:</strong> {broker.comment}
                    </div>
                )}
            </div>
        ) : null

        // we need to open modal to add new favorite broker
        const handleBrokerAction = (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            setShowBrokerActionModal(true)
            // Track modal in table context
            if (tableContext?.trackOpenUIElement) {
                tableContext.trackOpenUIElement(load._id, 'brokerActionModal', null)
            }
        }

        const handleCloseBrokerActionModal = () => {
            setShowBrokerActionModal(false)
            // Remove tracking when closed
            if (tableContext?.removeOpenUIElement) {
                tableContext.removeOpenUIElement(load._id)
            }
        }

        const handleAddBrokerConfirmation = async (brokerType: BrokerType) => {
            if (!load.posterInfo?.mcNumber) return

            if (brokerType === BrokerType.FAVORITE) {
                setIsBrokerActionLoading({
                    favorite: true,
                    blackList: false,
                })
            } else {
                setIsBrokerActionLoading({
                    favorite: false,
                    blackList: true,
                })
            }
            try {
                const response = await brokersApi.createBroker({
                    mcNumber: load.posterInfo.mcNumber,
                    companyName: load.posterInfo.companyName,
                    fullName: '',
                    email: load.posterInfo.contact?.email || '', // Required field
                    phone: load.posterInfo.contact?.phoneNumber || undefined,
                    type: brokerType,
                })

                if (response._id) {
                    setMyBrokers((prev) => [...prev, response])
                }
            } catch (error) {
                console.error('Error adding broker:', error)
            } finally {
                if (brokerType === BrokerType.FAVORITE) {
                    setIsBrokerActionLoading({
                        favorite: false,
                        blackList: false,
                    })
                } else {
                    setIsBrokerActionLoading({
                        favorite: false,
                        blackList: false,
                    })
                }
                handleCloseBrokerActionModal()
            }
        }

        const handleFavoriteClick = (e: React.MouseEvent) => {
            e.stopPropagation()
            if (!isLoadCancelled) {
                handleFavoriteToggle(load._id, !load.isFavorite)
            }
        }

        const handleFavoriteOnClick = () => {
            if (!isLoadCancelled && !load.isFavorite) {
                handleFavoriteToggle(load._id, true)
            }
        }

        // Effect to restore UI element states when data changes
        useEffect(() => {
            // Check if this load has an open UI element that needs restoration
            if (tableContext?.openUIElements && tableContext.openUIElements[load._id]) {
                const element = tableContext.openUIElements[load._id]

                // Restore appropriate UI element based on type
                if (element.type === 'bookConfirmPopover' && popoverRef.current) {
                    setAnchorEl(popoverRef.current)
                } else if (element.type === 'brokerActionModal') {
                    setShowBrokerActionModal(true)
                }
                // Add other UI element restorations as needed
            }
        }, [load._id, tableContext?.openUIElements])

        const sourceImage = useCallback(() => {
            if (!load.type) return null
            switch (load.type) {
                case 'EMAIL':
                    return <img src="/gmail-svgrepo-com.svg" alt="EMAIL" style={{ height: '20px' }} />
                case 'DAT':
                    return <img src="/dat-logo.jpg" alt="DAT" style={{ height: '20px' }} />
                case 'TRUCKSTOP':
                    return <img src="/truckstop-logo.webp" alt="TRUCKSTOP" style={{ height: '20px' }} />
                case 'UBER':
                    return <img src="/uber.png" alt="UBER" style={{ height: '20px' }} />
            }
        }, [load.type])

        // Add style tag for global styles
        return (
            <React.Fragment key={load._id}>
                <style>{globalStyles}</style>
                <td onClick={(e) => e.stopPropagation()}>
                    <Checkbox disabled={isLoadCancelled} checked={isSelected} onChange={(e) => onCheckboxClick(e, load._id)} sx={{ p: 0 }} />
                </td>

                <td>
                    <Box sx={{ display: 'flex', justifyContent: 'center', opacity: isLoadCancelled ? 0.5 : 1 }}>{sourceImage()}</Box>
                </td>
                <td>
                    <Box>
                        <AgeDisplay postedAt={load.postedAt} />
                    </Box>
                </td>
                <td>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 0.5 }}>
                        <IconButton
                            disabled={isLoadCancelled}
                            onClick={handleFavoriteClick}
                            sx={{
                                color: load.isFavorite ? 'warning.500' : 'neutral.500',
                                '--IconButton-size': '30px',
                                transition: 'transform 0.2s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.1)',
                                },
                            }}
                        >
                            {load.isFavorite ? <StarIcon /> : <StarBorderIcon />}
                        </IconButton>
                    </Box>
                </td>
                <td>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        {load.rateInfo?.rateUsd && load.rateInfo.rateUsd > 0 ? (
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                        {(load.callHistory && load.callHistory.length > 0) || load.emailHistory?.status === 'offered-new-price' ? (
                                            <Tooltip
                                                title={load.callHistory && load.callHistory.length > 0 ? 'AI-assisted rate' : 'Rate from email'}
                                                variant="soft"
                                            >
                                                <AutoAwesomeIcon color="primary" sx={{ fontSize: 16, opacity: isLoadCancelled ? 0.5 : 1 }} />
                                            </Tooltip>
                                        ) : null}
                                        <Typography sx={{ fontSize: '14px' }}>${load.rateInfo.rateUsd.toLocaleString()}</Typography>
                                    </Box>
                                    <Typography sx={{ fontSize: '12px' }}>{calculateRatePerMile(load.tripLength, load.rateInfo.rateUsd)}</Typography>
                                </Box>
                            </Box>
                        ) : (
                            '—'
                        )}
                    </Box>
                </td>
                <td>
                    <Box sx={{ display: 'flex', justifyContent: 'center', opacity: isLoadCancelled ? 0.5 : 1, flexDirection: 'column' }}>
                        {brokerEmail ? (
                            <EmailButton
                                broker={broker}
                                disabled={isLoadCancelled || isMcNumberBlacklisted(myBrokers, brokerMcNumber)}
                                load={load}
                                emailThreadId={emailThreadId}
                                handleEmailSent={handleEmailSent}
                                favorite={handleFavoriteOnClick}
                            />
                        ) : brokerPhoneNumber ? (
                            <CallButton
                                disabled={isLoadCancelled || isMcNumberBlacklisted(myBrokers, brokerMcNumber)}
                                load={load}
                                loadSearchId={loadSearchId}
                                phoneNumber={brokerPhoneNumber}
                                favorite={handleFavoriteOnClick}
                            />
                        ) : (
                            '—'
                        )}
                    </Box>
                </td>
                <td>
                    <Box sx={{ display: 'flex', justifyContent: 'center', opacity: isLoadCancelled ? 0.5 : 1, flexDirection: 'column' }}>
                        <WarningsCell emailHistory={load.emailHistory} />
                    </Box>
                </td>
                <td>
                    <Box sx={{ display: 'flex', justifyContent: 'center', opacity: isLoadCancelled ? 0.5 : 1 }}>
                        {/* TODO: Fix color */}
                        {load.isFactorable ? <CheckIcon sx={{ color: 'green' }} /> : '—'}
                    </Box>
                </td>
                <td>
                    <Box sx={{ display: 'flex', justifyContent: 'center', opacity: isLoadCancelled ? 0.5 : 1 }}>
                        <Typography
                            sx={{
                                fontSize: '14px',

                                // TODO: Fix color
                                color: 'green',
                                textAlign: 'center',
                            }}
                        >
                            {load.tripLength || '-'}
                        </Typography>
                    </Box>
                </td>
                <td>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: '14px',
                            }}
                        >
                            {load.origin.city}, {load.origin.stateProv}
                        </Typography>
                    </Box>
                </td>
                <td>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography sx={{ fontSize: '14px', textAlign: 'center' }}>{load.originDeadheadMiles?.miles}</Typography>
                    </Box>
                </td>
                <td style={{ opacity: isLoadCancelled ? 0.5 : 1 }}>
                    {/* TODO: Fix color */}
                    <HorizontalDirectionIcon svgColor="#0046E0" />
                </td>
                <td>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: '14px',
                            }}
                        >
                            {load.destination.city}, {load.destination.stateProv}
                        </Typography>
                    </Box>
                </td>
                <td>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                        }}
                    >
                        <Typography sx={{ fontSize: '14px', textAlign: 'center' }}>{load.destinationDeadheadMiles?.miles}</Typography>
                    </Box>
                </td>
                <td>{load.earliestAvailability && load.latestAvailability ? convertPickupDates(load.earliestAvailability, load.latestAvailability) : '--'}</td>
                <td>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            gap: 1,
                            width: '2rem',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                textAlign: 'center',
                            }}
                        >
                            {load.equipmentType || '—'}
                        </Typography>
                    </Box>
                </td>
                <td>
                    <Box>
                        <Typography sx={{ fontSize: '14px' }}>
                            {load.shipmentDetails?.maximumLengthFeet ? load.shipmentDetails?.maximumLengthFeet + ' ft' : '-'}
                        </Typography>
                    </Box>
                </td>
                <td>
                    <Box>
                        <Typography sx={{ fontSize: '14px' }}>
                            {load.shipmentDetails?.maximumWeightPounds ? Number(load.shipmentDetails?.maximumWeightPounds).toLocaleString() + ' lbs' : '-'}
                        </Typography>
                    </Box>
                </td>
                <td>
                    <Box>
                        <Typography sx={{ fontSize: '14px' }}>{load.shipmentDetails?.fullPartial}</Typography>
                    </Box>
                </td>
                <td>
                    <RateConfirmationCell mcNumber={load.posterInfo?.mcNumber || ''} />
                </td>
                <td>
                    <Tooltip title={brokerTooltipContent || ''}>
                        <span style={{ cursor: 'pointer' }}>
                            <IconButton
                                disabled={!hasValidMC || isMcNumberFavorite(myBrokers, brokerMcNumber) || isMcNumberBlacklisted(myBrokers, brokerMcNumber)}
                                onClick={handleBrokerAction}
                            >
                                {isMcNumberFavorite(myBrokers, brokerMcNumber) ? (
                                    <AssistantPhotoIcon color="success" />
                                ) : isMcNumberBlacklisted(myBrokers, brokerMcNumber) ? (
                                    <AssistantPhotoIcon color="error" />
                                ) : (
                                    <AssistantPhotoOutlinedIcon color="success" />
                                )}
                            </IconButton>
                        </span>
                    </Tooltip>
                </td>
                <td className="contact-td p-0">
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 3 }}>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                            }}
                        >
                            {load.posterInfo?.companyName}
                        </Typography>
                    </Box>
                </td>
                <td>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                opacity: isLoadCancelled ? 0.5 : 1,
                            }}
                        >
                            {load.posterInfo?.contact?.phoneNumber ? (
                                <a
                                    href={`tel:${load.posterInfo?.contact?.phoneNumber}`}
                                    style={{
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {formatPhoneNumber(load.posterInfo?.contact?.phoneNumber)}
                                </a>
                            ) : (
                                <a
                                    href={`mailto:${load.posterInfo?.contact?.email}`}
                                    style={{
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        wordBreak: 'break-all',
                                    }}
                                >
                                    {load.posterInfo?.contact?.email}
                                </a>
                            )}
                        </Typography>
                    </Box>
                </td>
                <td>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                        <Typography sx={{ fontSize: '14px' }}>{load.posterInfo?.credit?.creditScore || '-'} CS</Typography>
                        <Typography sx={{ fontSize: '14px' }}>{load.posterInfo?.credit?.daysToPay || '-'} DTP</Typography>
                    </Box>
                </td>
                <td>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        {isLoadCancelled ? (
                            <Chip color="danger" variant="soft">
                                Cancelled
                            </Chip>
                        ) : load.bookingUrl ? (
                            <Button
                                variant="outlined"
                                disabled={isLoadCancelled}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    e.stopPropagation()
                                    handleBookConfirmOpen(e)
                                }}
                            >
                                Book
                            </Button>
                        ) : (
                            '—'
                        )}
                    </Box>
                </td>
                <PopoverBookConfirmation {...popoverBookConfirmationProps} />
                {/* Broker Action Modal */}
                <BrokerActionModal
                    showBrokerActionModal={showBrokerActionModal}
                    setShowBrokerActionModal={setShowBrokerActionModal}
                    load={load}
                    isBrokerActionLoading={isBrokerActionLoading}
                    handleAddBrokerConfirmation={handleAddBrokerConfirmation}
                />
            </React.Fragment>
        )
    },
    (prevProps, nextProps) => {
        return prevProps.isSelected === nextProps.isSelected && deepEqual(prevProps.load, nextProps.load)
    }
)

export interface LoadRowProps {
    load: ClientLoadModel
    isSelected: boolean
    onCheckboxClick: (e: React.ChangeEvent<HTMLInputElement>, loadId: string) => void
    loadSearchId: string
    emailThreadIds: Record<string, string>
    setEmailThreadIds: (emailThreadIds: Record<string, string> | ((prev: Record<string, string>) => Record<string, string>)) => void
    handleFavoriteToggle: (loadId: string, isFavorite: boolean) => void
}
