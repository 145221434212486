import { Navigate, Route, Routes } from 'react-router-dom'
import { OngoingLoadsPage, UpdaterAgentDashboardPage } from './pages'

export default function UpdaterAgentRoutes() {
    return (
        <Routes>
            <Route index element={<UpdaterAgentDashboardPage />} />
            <Route path="loads" element={<OngoingLoadsPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}
