import { Box, Button, Input, Tooltip, Typography } from '@mui/joy'
import React from 'react'
import { FilterSectionProps } from './types'

export const FilterSection: React.FC<FilterSectionProps> = ({
    maxMiles,
    minMiles,
    minRate,
    minRatePerMile,
    onMaxMilesChange,
    onMinMilesChange,
    onMinRateChange,
    onMinRatePerMileChange,
    onReset,
}) => {
    const inputStyles = {
        width: 70,
    }

    // Set up styles for the input components
    const inputProps = {
        type: 'number',
        size: 'sm' as const,
    }

    // Define a common style object for removing spinner arrows
    const noSpinnerStyle = {
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '&': {
            MozAppearance: 'textfield',
        },
    }

    return (
        <Box
            sx={{
                width: 'fit-content',
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                backgroundColor: 'rgba(144, 202, 249, 0.08)',
                py: 1,
                px: 1.5,
                borderRadius: '8px',
                WebkitAppearance: 'none',
                MozAppearance: 'none',
            }}
        >
            <Typography level="body-sm" sx={{ color: 'text.primary' }}>
                Filters:
            </Typography>
            <Tooltip title="Enter maximum miles" arrow>
                <Input
                    {...inputProps}
                    placeholder="Max Miles"
                    value={maxMiles}
                    onChange={(e) => {
                        const value = Number(e.target.value)
                        if (value >= 0 || e.target.value === '') {
                            onMaxMilesChange(e.target.value)
                        }
                    }}
                    sx={inputStyles}
                    slotProps={{
                        input: {
                            min: 0,
                            sx: noSpinnerStyle,
                        },
                    }}
                />
            </Tooltip>
            <Tooltip title="Enter minimum miles" arrow>
                <Input
                    {...inputProps}
                    placeholder="Min Miles"
                    value={minMiles}
                    onChange={(e) => {
                        const value = Number(e.target.value)
                        if (value >= 0 || e.target.value === '') {
                            onMinMilesChange(e.target.value)
                        }
                    }}
                    sx={inputStyles}
                    slotProps={{
                        input: {
                            min: 0,
                            sx: noSpinnerStyle,
                        },
                    }}
                />
            </Tooltip>
            <Tooltip title="Enter minimum total rate" arrow>
                <Input
                    {...inputProps}
                    placeholder="Min Total Rate"
                    value={minRate}
                    onChange={(e) => {
                        const value = Number(e.target.value)
                        if (value >= 0 || e.target.value === '') {
                            onMinRateChange(e.target.value)
                        }
                    }}
                    sx={inputStyles}
                    slotProps={{
                        input: {
                            min: 0,
                            sx: noSpinnerStyle,
                        },
                    }}
                />
            </Tooltip>
            <Tooltip title="Enter minimum rate per mile" arrow>
                <Input
                    {...inputProps}
                    placeholder="Min Rate/Mile"
                    value={minRatePerMile}
                    onChange={(e) => {
                        const value = Number(e.target.value)
                        if (value >= 0 || e.target.value === '') {
                            onMinRatePerMileChange(e.target.value)
                        }
                    }}
                    sx={inputStyles}
                    slotProps={{
                        input: {
                            min: 0,
                            sx: noSpinnerStyle,
                        },
                    }}
                />
            </Tooltip>
            <Button
                size="sm"
                variant="soft"
                color="neutral"
                onClick={onReset}
                sx={{
                    backgroundColor: 'rgba(144, 202, 249, 0.08)',
                    '&:hover': {
                        backgroundColor: 'rgba(144, 202, 249, 0.12)',
                    },
                }}
            >
                Reset
            </Button>
        </Box>
    )
}
