import { Box, CircularProgress, Modal, ModalClose, ModalDialog, Stack, Tab, TabList, TabPanel, Tabs, Typography } from '@mui/joy'
import { BrokerType, IBroker } from '@numeo/types'
import { useCallback, useEffect, useState } from 'react'
import { brokersApi } from './api'
import { BrokerForm, BrokerFormData } from './components/BrokerForm'
import { EnhancedBrokersTable } from './components/BrokersTable'
import { Category } from 'hooks/useNotification'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'
import { NotifyForm } from './components/NotifyForm'
import { SearchTab } from './components/SearchTab'
import { useLocation, useNavigate } from 'react-router-dom'

/**
 * Type representing the possible tab values in the My Brokers page.
 * Includes both BrokerType enum values and the special 'notify' and 'search' tab.
 */
type TabValue = BrokerType | 'notify' | 'search'

export default function MyBrokersPage() {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [brokers, setBrokers] = useState<IBroker[]>([])
    const [selectedBroker, setSelectedBroker] = useState<IBroker | null>(null)
    const [selectedBrokerIds, setSelectedBrokerIds] = useState<string[]>([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sortConfig, setSortConfig] = useState<{ key: keyof IBroker; direction: 'asc' | 'desc' }>({
        key: 'createdAt',
        direction: 'desc',
    })

    // Initialize active tab from URL query parameter or default to FAVORITE
    const getInitialTab = (): TabValue => {
        const params = new URLSearchParams(location.search)
        const tabParam = params.get('tab')

        // Check if the tab parameter is a valid TabValue
        if (tabParam) {
            if (
                tabParam === BrokerType.FAVORITE ||
                tabParam === BrokerType.IN_BLACKLIST ||
                tabParam === BrokerType.UNCLASSIFIED ||
                tabParam === 'notify' ||
                tabParam === 'search'
            ) {
                return tabParam as TabValue
            }
        }

        return BrokerType.FAVORITE
    }

    const [activeTab, setActiveTab] = useState<TabValue>(getInitialTab())

    // Update URL when active tab changes
    const handleTabChange = (newTab: TabValue) => {
        setActiveTab(newTab)

        // Update URL query parameter
        const searchParams = new URLSearchParams(location.search)
        searchParams.set('tab', newTab)
        navigate({ search: searchParams.toString() }, { replace: true })
    }

    // Set initial tab in URL if not present
    useEffect(() => {
        const params = new URLSearchParams(location.search)
        if (!params.has('tab')) {
            // Set default tab in URL on initial mount
            params.set('tab', BrokerType.FAVORITE)
            navigate({ search: params.toString() }, { replace: true })
        }
    }, [location.search, navigate]) // Added missing dependencies

    // Check for URL changes
    useEffect(() => {
        const newTab = getInitialTab()
        if (newTab !== activeTab) {
            setActiveTab(newTab)
        }
        // eslint-disable-next-line
    }, [location.search, activeTab])

    // Fetch brokers with error handling
    const fetchBrokers = useCallback(async () => {
        if (activeTab === 'search') return
        setLoading(true)
        try {
            const response = await brokersApi.getBrokers(page, rowsPerPage, sortConfig.key, sortConfig.direction)

            // Filter brokers based on active tab
            const filteredBrokers = response.data.filter((broker: IBroker) => {
                if (activeTab === 'notify') {
                    return broker.type === BrokerType.FAVORITE // Only show favorite brokers in notify tab
                }
                return activeTab === broker.type
            })

            setBrokers(filteredBrokers)
            setTotal(response.meta.total)
        } catch (err) {
            dispatch(
                addNotification({
                    id: '',
                    message: 'Failed to fetch brokers',
                    type: 'error',
                    category: Category.Error,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
        } finally {
            setLoading(false)
        }
    }, [page, rowsPerPage, sortConfig.key, sortConfig.direction, dispatch, activeTab])

    useEffect(() => {
        // Initial data load
        fetchBrokers()

        // Refresh when pagination changes
    }, [fetchBrokers]) // Already has page/rowsPerPage in dependencies

    const handleEditBroker = useCallback(
        (broker: IBroker) => {
            if (activeTab === 'notify' || activeTab === 'search') return
            setSelectedBroker(broker)
            setOpen(true)
        },
        [activeTab]
    )

    const handleSubmitBroker = async (values: BrokerFormData) => {
        try {
            setLoading(true)
            if (selectedBroker) {
                await brokersApi.updateBroker(selectedBroker._id, values)
            } else {
                await brokersApi.createBroker(values)
            }

            // Refresh brokers list to get updated data
            await fetchBrokers()

            setOpen(false)
            setSelectedBroker(null)
            dispatch(
                addNotification({
                    id: '',
                    message: `Broker ${selectedBroker ? 'updated' : 'created'} successfully`,
                    type: 'info',
                    category: Category.Broker,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
        } catch (err) {
            dispatch(
                addNotification({
                    id: '',
                    message: `Failed to ${selectedBroker ? 'update' : 'create'} broker`,
                    type: 'error',
                    category: Category.Error,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
        } finally {
            setLoading(false)
        }
    }

    const handleSelectBroker = useCallback((id: string, checked: boolean) => {
        setSelectedBrokerIds((prev) => (checked ? [...prev, id] : prev.filter((_id) => _id !== id)))
    }, [])

    const handleSelectAll = useCallback(
        (checked: boolean) => {
            if (checked) {
                setSelectedBrokerIds(brokers.map((broker) => broker._id))
            } else {
                setSelectedBrokerIds([])
            }
        },
        [brokers]
    )

    const handleRemoveSelected = useCallback(async () => {
        if (activeTab === 'notify' || activeTab === 'search') return
        try {
            await brokersApi.deleteBrokers(selectedBrokerIds)
            setSelectedBrokerIds([])
            await fetchBrokers()
            dispatch(
                addNotification({
                    id: '',
                    message: 'Successfully deleted brokers',
                    type: 'info',
                    category: Category.Broker,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
        } catch (err) {
            dispatch(
                addNotification({
                    id: '',
                    message: 'Failed to delete brokers',
                    type: 'error',
                    category: Category.Error,
                    timestamp: Date.now(),
                    topic: 'temp',
                })
            )
        }
    }, [activeTab, dispatch, fetchBrokers, selectedBrokerIds])

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = () => {
        setSelectedBroker(null)
        setOpen(false)
    }

    return (
        <Box sx={{ p: 2, height: '100%', position: 'relative' }}>
            {loading && (
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        zIndex: 99,
                        bgcolor: 'secondary.200',
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            <Stack spacing={0} sx={{ height: '100%', borderRadius: 'md', overflow: 'hidden' }}>
                <Tabs value={activeTab} onChange={(_, value) => handleTabChange(value as TabValue)} sx={{ flex: 1, borderRadius: 'md', height: '100%' }}>
                    <Stack spacing={2} sx={{ p: 2 }}>
                        <Typography level="h4" component="h1">
                            My Brokers
                        </Typography>
                        <TabList>
                            <Tab value={BrokerType.FAVORITE}>Favorite</Tab>
                            <Tab value={BrokerType.IN_BLACKLIST}>Blacklist</Tab>
                            <Tab value={BrokerType.UNCLASSIFIED}>Unclassified</Tab>
                            <Tab value="notify">Notify</Tab>
                            <Tab value="search">Search</Tab>
                        </TabList>
                    </Stack>
                    <TabPanel value={BrokerType.FAVORITE}>
                        <Box sx={{ flex: 1 }}>
                            <Typography level="title-lg" sx={{ mb: 2 }}>
                                Favorite Brokers
                            </Typography>
                            <Typography level="body-md" sx={{ mb: 4, color: 'text.secondary' }}>
                                View and manage your favorite brokers. You can add, edit, or delete brokers from this list.
                            </Typography>
                            <EnhancedBrokersTable
                                brokers={brokers}
                                selected={selectedBrokerIds}
                                onSort={(key: keyof IBroker, direction) => setSortConfig({ key, direction })}
                                onSelectAll={handleSelectAll}
                                onSelect={handleSelectBroker}
                                onEdit={handleEditBroker}
                                onDelete={handleRemoveSelected}
                                sortConfig={sortConfig}
                                page={page}
                                total={total}
                                rowsPerPage={rowsPerPage}
                                onPageChange={setPage}
                                onRowsPerPageChange={setRowsPerPage}
                                showNewButton={activeTab !== 'notify' && activeTab !== 'search'}
                                onNewBtnClick={handleOpen}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value={BrokerType.IN_BLACKLIST}>
                        <Box sx={{ flex: 1 }}>
                            <Typography level="title-lg" sx={{ mb: 2 }}>
                                Blacklisted Brokers
                            </Typography>
                            <Typography level="body-md" sx={{ mb: 4, color: 'text.secondary' }}>
                                View and manage your blacklisted brokers. You can add, edit, or delete brokers from this list.
                            </Typography>
                            <EnhancedBrokersTable
                                brokers={brokers}
                                selected={selectedBrokerIds}
                                onSort={(key: keyof IBroker, direction) => setSortConfig({ key, direction })}
                                onSelectAll={handleSelectAll}
                                onSelect={handleSelectBroker}
                                onEdit={handleEditBroker}
                                onDelete={handleRemoveSelected}
                                sortConfig={sortConfig}
                                page={page}
                                total={total}
                                rowsPerPage={rowsPerPage}
                                onPageChange={setPage}
                                onRowsPerPageChange={setRowsPerPage}
                                showNewButton={activeTab !== 'notify' && activeTab !== 'search'}
                                onNewBtnClick={handleOpen}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value={BrokerType.UNCLASSIFIED}>
                        <Box sx={{ flex: 1 }}>
                            <Typography level="title-lg" sx={{ mb: 2 }}>
                                Unclassified Brokers
                            </Typography>
                            <Typography level="body-md" sx={{ mb: 4, color: 'text.secondary' }}>
                                View and manage your unclassified brokers. You can add, edit, or delete brokers from this list.
                            </Typography>
                            <EnhancedBrokersTable
                                brokers={brokers}
                                selected={selectedBrokerIds}
                                onSort={(key: keyof IBroker, direction) => setSortConfig({ key, direction })}
                                onSelectAll={handleSelectAll}
                                onSelect={handleSelectBroker}
                                onEdit={handleEditBroker}
                                onDelete={handleRemoveSelected}
                                sortConfig={sortConfig}
                                page={page}
                                total={total}
                                rowsPerPage={rowsPerPage}
                                onPageChange={setPage}
                                onRowsPerPageChange={setRowsPerPage}
                                showNewButton={activeTab !== 'notify' && activeTab !== 'search'}
                                onNewBtnClick={handleOpen}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value="notify" sx={{ flex: 1, overflow: 'auto' }}>
                        <NotifyForm brokers={brokers} />
                    </TabPanel>
                    <TabPanel value="search" sx={{ flex: 1, overflow: 'auto' }}>
                        <SearchTab />
                    </TabPanel>
                </Tabs>
            </Stack>

            {/* Popup for editing and adding brokers */}
            {activeTab !== 'notify' && activeTab !== 'search' && (
                <Modal open={open} onClose={handleClose}>
                    <ModalDialog sx={{ maxWidth: 800 }} aria-labelledby="broker-modal" size="lg">
                        <ModalClose />
                        <Typography id="broker-modal" level="h4">
                            {selectedBroker ? 'Edit Broker' : 'Add a New Broker'}
                        </Typography>
                        {selectedBroker ? (
                            <BrokerForm activeTab={activeTab} mode="edit" initialValues={selectedBroker} onSubmit={handleSubmitBroker} onClose={handleClose} />
                        ) : (
                            <BrokerForm activeTab={activeTab} onSubmit={handleSubmitBroker} onClose={() => setOpen(false)} />
                        )}
                    </ModalDialog>
                </Modal>
            )}
        </Box>
    )
}
