import { Box, CircularProgress, Sheet, Table, useTheme, Typography } from '@mui/joy'
import React, { useEffect, useMemo, useState, memo, useRef, LegacyRef, useCallback, createContext } from 'react'
import { TableComponents, TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso'
import { ClientLoadModel, EmailTemplateType, LoadFindResultState, SearchStatus } from '@numeo/types'
import axios from 'axios'
import { sendInfoEmail } from '../assets/helpers'
import { sortLoads } from '../assets/sortOptions'
import { ActionBar } from './ActionBar'
import { LoadRow } from './LoadRow/index'
import { TableHeader } from './TableHeader'
import { ActionBarProps, TableHeaderProps } from './types'
import { LoadDetails } from './LoadDetails'
import { Category } from 'hooks/useNotification'
import { useAuth } from 'hooks/auth'
import { useGetQuery } from 'hooks/useGetQuery'
import { useDispatch, useSelector } from 'react-redux'
import { addNotification } from 'store/notificationSlice'
import { EmailPopoverProvider } from './EmailButton'
import { RingCentralCallDetailsSidebar } from '../Integrations/ring-central/RingCentralCallDetailsSidebar'
import { RootState } from 'store'
import { toggleRingCentralSidebar } from 'store/callCenterSlice'
import { SearchBrokersTable } from './SearchBrokersTable'

interface NewLoadsTableProps {
    searchData: LoadFindResultState
}

// Define TableContext type for UI element tracking
interface TableContextType {
    handleRowClick?: (id: string) => void
    trackOpenUIElement?: (loadId: string, elementType: string, elementRect: DOMRect | null) => void
    removeOpenUIElement?: (loadId: string) => void
    openUIElements?: { [loadId: string]: { type: string; position?: DOMRect } }
}

export const TableContext = createContext<TableContextType>({})

const ScrollerSheet = ({ scrollerRef, ...props }) => (
    <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
            display: { xs: 'none', sm: 'block' },
            width: '100%',
            height: '600px',
            borderRadius: 'sm',
            flexShrink: 1,
            overflow: 'auto',
            minHeight: 0,
            '--TableCell-headBackground': (theme) => theme.vars.palette.background.level1,
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': (theme) => theme.vars.palette.background.level1,
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
        }}
        ref={scrollerRef}
        {...props}
    />
)

const ScrollerComponent = React.forwardRef<HTMLDivElement>((props, ref) => <ScrollerSheet {...props} scrollerRef={ref} />)

// Define components with the structure matching the solution example
const virtuosoComponents: TableComponents<ClientLoadModel> = {
    EmptyPlaceholder: () => (
        <tr style={{ height: '40px' }}>
            <td colSpan={25} style={{ padding: '8px', height: '40px' }}></td>
        </tr>
    ),
    Scroller: ScrollerComponent,
    Table: React.forwardRef((props) => (
        <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            component="table"
            sx={{
                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                '--TableCell-paddingY': '4px',
                '--TableCell-paddingX': '8px',
                '& tbody tr:not(.expanded):hover td': {
                    cursor: 'pointer',
                },
            }}
            {...props}
        />
    )),
    TableHead: React.forwardRef((props, ref) => <thead ref={ref} {...props} />),
    TableBody: React.forwardRef((props, ref) => <tbody ref={ref} {...props} />),
    TableRow: React.forwardRef((props, ref, ...virtuosoProps) => {
        const theme = useTheme()
        const isLoadCancelled = props.item?.status === 'cancelled'
        const rowBackgroundColor = isLoadCancelled ? theme.vars.palette.danger.solidDisabledBg : theme.vars.palette.background.surface
        const rowColor = isLoadCancelled ? theme.vars.palette.danger.plainDisabledColor : theme.vars.palette.text.primary

        // Type the context properly to include all the context properties
        const context = props.context as {
            handleRowClick?: (id: string) => void
            trackOpenUIElement?: (loadId: string, elementType: string, elementRect: DOMRect | null) => void
            removeOpenUIElement?: (loadId: string) => void
            openUIElements?: { [loadId: string]: { type: string; position?: DOMRect } }
        }

        // Check if this is the RateConDetails row (first row with index 0)
        const isRateConDetailsRow = props['data-index'] === 0

        return (
            <tr
                style={{
                    backgroundColor: rowBackgroundColor,
                    cursor: 'default',
                    pointerEvents: isLoadCancelled ? 'none' : 'auto',
                    opacity: isLoadCancelled ? 0.9 : 1,
                    textAlign: 'center',
                    color: rowColor,
                    width: '100%',
                }}
                onClick={(e: React.MouseEvent) => {
                    e.preventDefault()
                    e.stopPropagation()
                    // Don't trigger handleRowClick for RateConDetails row (index 0) or cancelled loads
                    if (!isRateConDetailsRow && !isLoadCancelled && context?.handleRowClick && props.item && props['data-index'] % 2 === 0) {
                        context.handleRowClick(props.item._id)
                    }
                }}
                ref={ref as LegacyRef<HTMLTableRowElement> | undefined}
                data-load-id={props.item?._id}
                {...props}
                {...virtuosoProps}
            />
        )
    }),
}

const NewLoadsTable: React.FC<NewLoadsTableProps> = memo(
    ({ searchData }) => {
        const { debugData, application, myBrokers } = useAuth()
        // Track which UI elements are open (tooltips, popovers, etc.)
        const [openUIElements, setOpenUIElements] = useState<{ [loadId: string]: { type: string; position?: DOMRect } }>({})
        const truckId = useGetQuery('truckId')
        const [allRows, setAllRows] = useState<ClientLoadModel[]>([])
        const [finalRows, setFinalRows] = useState<ClientLoadModel[]>([])
        const [selectedLoads, setSelectedLoads] = useState<Set<string>>(new Set())
        // Added state to track expanded rows using Set for better performance
        const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set())
        const expandedRowsRef = useRef(expandedRows)
        const [maxMiles, setMaxMiles] = useState<string>('')
        const [minMiles, setMinMiles] = useState<string>('')
        const [minRate, setMinRate] = useState<string>('')
        const [minRatePerMile, setMinRatePerMile] = useState<string>('')
        const [emailThreadIds, setEmailThreadIds] = useState<Record<string, string>>({})
        const [hiddenLoads, setHiddenLoads] = useState<Set<string>>(new Set())
        const [isRequestRateLoading, setIsRequestRateLoading] = useState(false)
        const [lastSortingOption, setLastSortingOption] = useState<string | null>(null)
        const [isSearching, setIsSearching] = useState(false)
        // Track all seen loads per truck
        const [allSeenLoadIds, setAllSeenLoadIds] = useState<Record<string, Set<string>>>({})
        // Track the last seen timestamp per truck
        const [lastLoadTimestamp, setLastLoadTimestamp] = useState<Record<string, number>>({})
        const [favoriteLoads, setFavoriteLoads] = useState<Set<string>>(() => {
            const saved = localStorage.getItem('favoriteLoads')
            return saved ? new Set(JSON.parse(saved)) : new Set()
        })
        const dispatch = useDispatch()

        // RingCentral Call handlers
        const notificationId = 'ring-central-call'
        const activeCall = useSelector((state: RootState) => state.callCenter.activeRingCentralCall)
        const openCallDetails = useSelector((state: RootState) => state.callCenter.openRingCentralSideBar)
        const activeLoad = useSelector((state: RootState) => state.callCenter.activeLoad)
        const callSession = activeCall?.session || null

        const handleCloseSidebar = () => {
            // Just toggle the sidebar visibility without removing the call session
            dispatch(toggleRingCentralSidebar(false))
        }

        useEffect(() => {
            localStorage.setItem('favoriteLoads', JSON.stringify(Array.from(favoriteLoads)))
        }, [favoriteLoads])

        useEffect(() => {
            if (searchData?.searchStatus === SearchStatus.searchInitialized) {
                const currentTruckId = searchData?.truckId
                if (currentTruckId) {
                    setAllSeenLoadIds((prev) => ({
                        ...prev,
                        [currentTruckId]: new Set(),
                    }))
                    setLastLoadTimestamp((prev) => ({
                        ...prev,
                        [currentTruckId]: Date.now(),
                    }))
                }
            }
        }, [searchData?.searchStatus, searchData?.truckId])

        useEffect(() => {
            const loads: ClientLoadModel[] = Object.values(searchData?.loads || {})
            const currentTruckId = searchData?.truckId

            // Skip if no truck ID
            if (!currentTruckId) return

            // Use favoriteLoads state to preserve favorite status
            const updatedLoads = loads.map((newLoad) => ({
                ...newLoad,
                isFavorite: favoriteLoads.has(newLoad._id),
            }))

            // Sort loads by favorites first
            const sortedLoads = sortLoads(updatedLoads, 'favoriteFirst', myBrokers)

            if (isSearching) {
                const currentTime = Date.now()

                // Initialize tracking for new truck if needed
                if (!allSeenLoadIds[currentTruckId]) {
                    setAllSeenLoadIds((prev) => ({
                        ...prev,
                        [currentTruckId]: new Set(),
                    }))
                }
                if (!lastLoadTimestamp[currentTruckId]) {
                    setLastLoadTimestamp((prev) => ({
                        ...prev,
                        [currentTruckId]: currentTime,
                    }))
                }

                // Get previously seen load IDs for current truck
                const seenLoadIds = allSeenLoadIds[currentTruckId] || new Set()

                // Find genuinely new loads for current truck but don't control snackbar here
                // This is handled by the renderingRows useEffect
                const newLoads = loads.filter((load) => {
                    const loadTimestamp = load.createdAt ? new Date(load.createdAt).getTime() : currentTime
                    return !seenLoadIds.has(load._id) && loadTimestamp > (lastLoadTimestamp[currentTruckId] || 0)
                })

                // Track all current load IDs as seen
                const currentLoadIds = new Set(loads.map((load) => load._id))
                setAllSeenLoadIds((prev) => ({
                    ...prev,
                    [currentTruckId]: new Set([...(prev[currentTruckId] || []), ...currentLoadIds]),
                }))

                // Update the last load timestamp for current truck if we have new loads
                if (newLoads.length > 0) {
                    setLastLoadTimestamp((prev) => ({
                        ...prev,
                        [currentTruckId]: currentTime,
                    }))
                }
            }

            setAllRows(sortedLoads)

            // Initialize emailThreadIds from loads that have emailThreadId
            const threadIds: Record<string, string> = {}
            loads.forEach((load) => {
                if (load.emailThreadId) {
                    threadIds[load._id] = load.emailThreadId
                }
            })
            setEmailThreadIds((prev) => ({ ...prev, ...threadIds }))

            // eslint-disable-next-line
        }, [searchData, favoriteLoads, isSearching, myBrokers])

        useEffect(() => {
            const filteredRows = allRows.filter((load) => {
                if (hiddenLoads.has(load._id)) return false

                const tripLength = Number(load.tripLength)
                const rate = Number(load.rateInfo?.rateUsd)
                const ratePerMile = tripLength ? rate / tripLength : 0

                const isWithinMinMiles = !minMiles || tripLength >= Number(minMiles)
                const isWithinMaxMiles = !maxMiles || tripLength <= Number(maxMiles)
                const isAboveMinRate = !minRate || rate >= Number(minRate)
                const isAboveMinRatePerMile = !minRatePerMile || ratePerMile >= Number(minRatePerMile)

                return isWithinMinMiles && isWithinMaxMiles && isAboveMinRate && isAboveMinRatePerMile
            })

            // Separate favorites and non-favorites
            const favorites = filteredRows.filter((load) => load.isFavorite)
            const nonFavorites = filteredRows.filter((load) => !load.isFavorite)

            // Apply sorting to each group separately
            if (lastSortingOption) {
                const sortedFavorites = sortLoads(favorites, lastSortingOption, myBrokers)
                const sortedNonFavorites = sortLoads(nonFavorites, lastSortingOption, myBrokers)
                // Combine the sorted groups with favorites first
                setFinalRows([...sortedFavorites, ...sortedNonFavorites])
            } else {
                // If no sorting option is selected, just keep favorites first
                setFinalRows([...favorites, ...nonFavorites])
            }
        }, [allRows, minMiles, maxMiles, minRate, minRatePerMile, hiddenLoads, lastSortingOption, myBrokers])

        const handleFavoriteToggle = async (loadId: string, isFavorite: boolean) => {
            try {
                // Update favoriteLoads state
                setFavoriteLoads((prev) => {
                    const newFavorites = new Set(prev)
                    if (isFavorite) {
                        newFavorites.add(loadId)
                    } else {
                        newFavorites.delete(loadId)
                    }
                    return newFavorites
                })

                // Update allRows with the new favorite status
                setAllRows((prev) => {
                    const updatedRows = prev.map((load) => (load._id === loadId ? { ...load, isFavorite } : load))
                    return sortLoads(updatedRows, 'favoriteFirst', myBrokers)
                })

                // Make API call
                await axios.put(`/trucks/loads/${loadId}/favorite`, { isFavorite })
            } catch (error) {
                console.error('Error updating favorite status:', error)
                // Revert both states on error
                setFavoriteLoads((prev) => {
                    const revertedFavorites = new Set(prev)
                    if (!isFavorite) {
                        revertedFavorites.add(loadId)
                    } else {
                        revertedFavorites.delete(loadId)
                    }
                    return revertedFavorites
                })

                setAllRows((prev) => {
                    const revertedRows = prev.map((load) => (load._id === loadId ? { ...load, isFavorite: !isFavorite } : load))
                    return sortLoads(revertedRows, 'favoriteFirst', myBrokers)
                })

                // Add notification
                dispatch(
                    addNotification({
                        id: '',
                        message: 'Failed to update favorite status',
                        type: 'error',
                        category: Category.LoadFinderTruck,
                        timestamp: Date.now(),
                        topic: 'temp',
                    })
                )
            }
        }

        const handleHideLoads = () => {
            setHiddenLoads((prev) => new Set([...prev, ...selectedLoads]))
            setSelectedLoads(new Set()) // Clear selected loads after hiding
        }

        // UseEffect re-renders:
        // When min miles, max miles, min total rate, and min rate/mile changes
        // When sorting the column is clicked
        // When certain loads are hidden
        // When loads list is updated (allRows)
        useEffect(() => {
            setIsSearching(searchData?.searchStatus === SearchStatus.searchInitialized || searchData?.searchStatus === SearchStatus.searchStarted)
        }, [searchData?.searchStatus])

        const handleSortChange = (sortOption: string) => {
            // Separate favorites and non-favorites
            const favorites = allRows.filter((load) => load.isFavorite)
            const nonFavorites = allRows.filter((load) => !load.isFavorite)

            // Apply sorting to each group separately
            const sortedFavorites = sortLoads(favorites, sortOption, myBrokers)
            const sortedNonFavorites = sortLoads(nonFavorites, sortOption, myBrokers)

            setLastSortingOption(sortOption)
            // Combine the sorted groups with favorites first
            setFinalRows([...sortedFavorites, ...sortedNonFavorites])
        }

        const handleRowClick = (loadId: string) => {
            setExpandedRows((prev) => {
                const newExpandedRows = new Set(prev)
                if (newExpandedRows.has(loadId)) {
                    // Remove from positions tracking when collapsed
                    setExpandedRowPositions((positions) => {
                        const newPositions = { ...positions }
                        delete newPositions[loadId]
                        return newPositions
                    })
                    newExpandedRows.delete(loadId) // Collapse if already expanded
                } else {
                    // Calculate and store the relative position of the row in the viewport
                    if (scrollerRef.current) {
                        const scrollElement = scrollerRef.current as HTMLElement
                        const viewportHeight = scrollElement.clientHeight

                        // Find the row element
                        setTimeout(() => {
                            const rowElement = document.querySelector(`tr[data-load-id="${loadId}"]`) as HTMLElement
                            if (rowElement) {
                                const rowRect = rowElement.getBoundingClientRect()
                                const scrollRect = scrollElement.getBoundingClientRect()
                                const relativePosition = (rowRect.top - scrollRect.top) / viewportHeight

                                // Update positions state
                                setExpandedRowPositions((prev) => ({
                                    ...prev,
                                    [loadId]: relativePosition,
                                }))
                            }
                        }, 10)
                    }

                    newExpandedRows.add(loadId) // Expand if not already expanded
                }
                return newExpandedRows
            })
        }

        const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>, loadId: string) => {
            e.stopPropagation()
            setSelectedLoads((prev) => {
                const newSet = new Set(prev)
                if (newSet.has(loadId)) {
                    newSet.delete(loadId)
                } else {
                    newSet.add(loadId)
                }
                return newSet
            })
        }

        const handleSelectAll = () => {
            if (selectedLoads.size > 0) {
                setSelectedLoads(new Set())
            } else {
                const newSelectedLoads = new Set(
                    finalRows.map((load) => {
                        if (load.status !== 'cancelled') return load._id
                        return ''
                    })
                )
                setSelectedLoads(newSelectedLoads)
            }
        }

        // Memoize row map creation
        const rowsMap = useMemo(
            () => new Map(allRows.map((row) => [row._id, row])),
            [allRows] // Only recreate when allRows changes
        )

        // Memoize favorite brokers
        const favoriteBrokerMcNumbers = useMemo(() => new Set(myBrokers.map((b) => b.mcNumber)), [myBrokers])

        // Memoize the filtered array itself
        // try to avoid recalculating
        const filteredLoads = useMemo(
            () =>
                Array.from(selectedLoads)
                    .map((loadId) => rowsMap.get(loadId))
                    .filter((load): load is ClientLoadModel => {
                        // Add type predicate
                        if (!load) return false

                        const contact = load.posterInfo?.contact
                        const isFavorite = favoriteBrokerMcNumbers.has(load.posterInfo?.mcNumber ?? '')
                        const isValidEmail = !!contact?.email
                        const canSendEmail = !load.isEmailSent && !emailThreadIds[load._id!] // Add non-null assertion

                        return (isFavorite || isValidEmail) && canSendEmail
                    }),
            [rowsMap, selectedLoads, emailThreadIds, favoriteBrokerMcNumbers]
        )

        // Track UI elements that have been opened (like tooltips and popovers)
        const trackOpenUIElement = useCallback((loadId: string, elementType: string, elementRect: DOMRect | null) => {
            setOpenUIElements((prev) => ({
                ...prev,
                [loadId]: {
                    type: elementType,
                    position: elementRect || undefined,
                },
            }))
        }, [])

        // Remove UI element tracking when closed
        const removeOpenUIElement = useCallback((loadId: string) => {
            setOpenUIElements((prev) => {
                const newState = { ...prev }
                delete newState[loadId]
                return newState
            })
        }, [])

        const handleRequestRateMultiple = async () => {
            setIsRequestRateLoading(true)

            const loadsWithEmail = filteredLoads

            if (loadsWithEmail.length === 0) {
                setIsRequestRateLoading(false)
                return
            }

            try {
                let successCount = 0
                let failureCount = 0

                // Process emails sequentially to avoid overwhelming the server
                for (const load of loadsWithEmail) {
                    try {
                        // get favorite broker for this load
                        const broker = myBrokers.find((b) => b.mcNumber === load.posterInfo?.mcNumber)
                        const template = application?.temp.emailTemplate?.find((t) => t.type === EmailTemplateType.INFO)
                        const footer = application?.temp.emailFooter?.closing
                        const driver = application?.temp.spotFinder?.trucks.find((t) => t._id === truckId)?.firstDriver
                        const { emailThreadId } = await sendInfoEmail(load, debugData.spotFinder.mockBrokerEmail, broker, footer, template, driver)
                        if (emailThreadId) {
                            successCount++
                            setEmailThreadIds((prev) => ({ ...prev, [load._id]: emailThreadId }))
                            handleFavoriteToggle(load._id, true)
                        }
                    } catch (error) {
                        console.error(`Error sending email for load ${load.externalId}:`, error)
                        failureCount++
                    }
                }

                // Clear selection after all emails are processed
                setSelectedLoads(new Set())

                // Show success/failure message
                if (successCount > 0) {
                    dispatch(
                        addNotification({
                            id: '',
                            message: `Successfully sent ${successCount} info email${successCount > 1 ? 's' : ''}${failureCount > 0 ? ` (${failureCount} failed)` : ''}`,
                            type: 'info',
                            category: Category.LoadFinderTruck,
                            timestamp: Date.now(),
                            topic: 'temp',
                        })
                    )
                } else {
                    dispatch(
                        addNotification({
                            id: '',
                            message: 'Failed to send info emails',
                            type: 'error',
                            category: Category.LoadFinderTruck,
                            timestamp: Date.now(),
                            topic: 'temp',
                        })
                    )
                }
            } catch (error) {
                console.error('Error in batch email processing:', error)
                dispatch(
                    addNotification({
                        id: '',
                        message: 'Failed to process info emails',
                        type: 'error',
                        category: Category.Error,
                        timestamp: Date.now(),
                        topic: 'temp',
                    })
                )
            } finally {
                setIsRequestRateLoading(false)
            }
        }

        const tableRef = useRef<TableVirtuosoHandle>(null)
        const scrollerRef = useRef<HTMLElement | Window | null>(null)

        const handleScrollerRef = useCallback((ref) => {
            scrollerRef.current = ref
        }, [])

        // Track expanded rows viewport positions
        const [expandedRowPositions, setExpandedRowPositions] = useState<{ [key: string]: number }>({})

        // Keep track of previous renderingRows to detect new loads
        const prevRenderingRowsRef = useRef<ClientLoadModel[]>([])

        // Track the new load IDs for scrolling to them when requested
        const newLoadIdsRef = useRef<string[]>([])

        // Track the current truck ID to detect truck changes
        const currentTruckIdRef = useRef<string | undefined>(searchData?.truckId)
        const expandedRowPositionsRef = useRef(expandedRowPositions)
        const renderingRows = useMemo(() => {
            const rows: ClientLoadModel[] = []

            for (const row of finalRows) {
                // Push the original row for the main row content
                rows.push(row)

                // Push the same row again for the expanded details
                // Both rows reference the same data, but will be rendered differently based on the index
                rows.push(row)
            }

            return rows
        }, [finalRows])

        // Effect to preserve scroll position for expanded rows when data changes
        useEffect(() => {
            // Check if we have any expanded rows that need position preservation
            const expandedIds = Array.from(expandedRowsRef.current)
            if (expandedIds.length === 0 || !tableRef.current || !scrollerRef.current) return
            // Find the first expanded row in our list
            const expandedId = expandedIds[0]
            const targetPosition = expandedRowPositionsRef.current[expandedId]

            if (targetPosition !== undefined) {
                // Find the index of the expanded row in renderingRows
                let expandedRowIndex = -1
                for (let i = 0; i < renderingRows.length; i += 2) {
                    if (renderingRows[i]._id === expandedId) {
                        expandedRowIndex = i
                        break
                    }
                }

                if (expandedRowIndex >= 0) {
                    // First scroll to the row
                    tableRef.current?.scrollToIndex({
                        index: expandedRowIndex,
                    })

                    // After scrolling to index, adjust position to maintain relative viewport position
                    if (scrollerRef.current) {
                        const scrollElement = scrollerRef.current as HTMLElement
                        const viewportHeight = scrollElement.clientHeight

                        // Find the row element after scrolling
                        const rowElement = document.querySelector(`tr[data-load-id="${expandedId}"]`) as HTMLElement
                        if (rowElement) {
                            const rowRect = rowElement.getBoundingClientRect()
                            const scrollRect = scrollElement.getBoundingClientRect()
                            const currentRelativePosition = (rowRect.top - scrollRect.top) / viewportHeight

                            // Adjust scroll to match the target relative position
                            const adjustment = (currentRelativePosition - targetPosition) * viewportHeight
                            scrollElement.scrollTop += adjustment
                        }
                    }
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [renderingRows])

        // Effect to detect truck changes and reset comparison logic
        useEffect(() => {
            const truckId = searchData?.truckId

            // If truck ID changed, reset our comparison data
            if (truckId !== currentTruckIdRef.current) {
                console.log('Truck changed, resetting load comparison data')
                prevRenderingRowsRef.current = []
                newLoadIdsRef.current = []
                currentTruckIdRef.current = truckId
            }
        }, [searchData?.truckId])

        // Effect to detect new loads by comparing current and previous renderingRows
        // This is THE ONLY place that should control the snackbar (except for handleScrollToNewLoad)
        useEffect(() => {
            // Skip if no rows to compare
            if (!renderingRows.length) {
                // Store current rows but don't show snackbar on first load
                prevRenderingRowsRef.current = [...renderingRows]
                return
            }

            // Skip if previous ref is empty (initial state)
            if (prevRenderingRowsRef.current.length === 0) {
                prevRenderingRowsRef.current = [...renderingRows]
                return
            }

            // Extract the actual load rows (every other row since we double them for expansion)
            const currentLoads = renderingRows.filter((_, index) => index % 2 === 0)
            const prevLoads = prevRenderingRowsRef.current.filter((_, index) => index % 2 === 0)

            // Get the current set of load IDs
            const currentLoadIds = new Set(currentLoads.map((load) => load._id))

            // Get the previous set of load IDs
            const prevLoadIds = new Set(prevLoads.map((load) => load._id))

            // Find new load IDs (those in current but not in previous)
            const newLoadIds = [...currentLoadIds].filter((id) => !prevLoadIds.has(id))

            // Only show snackbar if we have genuinely new loads and we had rows before
            if (newLoadIds.length > 0 && prevLoads.length > 0) {
                newLoadIdsRef.current = newLoadIds
            } else if (newLoadIds.length === 0 && currentLoads.length !== prevLoads.length) {
                // Clear the new load IDs since we're hiding the snackbar
                newLoadIdsRef.current = []
            }

            // Update the ref with current rows for next comparison
            prevRenderingRowsRef.current = [...renderingRows]
        }, [renderingRows])

        const actionBarProps: ActionBarProps = {
            self: {
                resultCount: finalRows.length,
            },
            filterSectionProps: {
                maxMiles: maxMiles,
                minMiles: minMiles,
                minRate: minRate,
                minRatePerMile: minRatePerMile,
                onMaxMilesChange: setMaxMiles,
                onMinMilesChange: setMinMiles,
                onMinRateChange: setMinRate,
                onMinRatePerMileChange: setMinRatePerMile,
                onReset: () => {
                    setMaxMiles('')
                    setMinMiles('')
                    setMinRate('')
                    setMinRatePerMile('')
                },
            },
            emailRequestButtonProps: {
                eligibleLoadsCount: filteredLoads.length,
                isLoading: isRequestRateLoading,
                onRequest: handleRequestRateMultiple,
            },
            hideSelectedLoadsButtonProps: {
                selectedLoads: selectedLoads,
                onHideLoads: handleHideLoads,
            },
        }

        const tableHeaderProps: TableHeaderProps = {
            selectedLoads: selectedLoads,
            totalLoads: finalRows.length,
            onSelectAll: handleSelectAll,
            onSortChange: handleSortChange,
        }

        return (
            <EmailPopoverProvider>
                <Box sx={{ width: '100%', maxWidth: '100%', height: '100%', mt: 1 }}>
                    <ActionBar {...actionBarProps} />
                    {renderingRows.length > 0 ? (
                        <>
                            <TableVirtuoso
                                ref={tableRef}
                                data={renderingRows}
                                style={{
                                    flexShrink: 1,
                                    height: '95%',
                                }}
                                components={virtuosoComponents}
                                fixedHeaderContent={() => <TableHeader {...tableHeaderProps} />}
                                increaseViewportBy={800}
                                defaultItemHeight={40}
                                computeItemKey={(index, load) => load._id + (index % 2 !== 0 ? '-expanded' : '')}
                                overscan={50}
                                scrollerRef={handleScrollerRef}
                                context={{
                                    handleRowClick,
                                    trackOpenUIElement,
                                    removeOpenUIElement,
                                    openUIElements,
                                }}
                                itemContent={(index, load) => {
                                    // Render broker rate confirmation details at the top of the table
                                    if (index === 0) {
                                        return searchData?.brokers && <SearchBrokersTable brokers={searchData.brokers} />
                                    }

                                    // If load is expanded, render the expanded row content
                                    if (index % 2 !== 0) {
                                        return (
                                            <LoadDetails
                                                load={load}
                                                expanded={expandedRows.has(load._id)}
                                                broker={myBrokers.find((broker) => broker.mcNumber === load.posterInfo?.mcNumber)}
                                                loadSearchId={searchData?.loadSearchId}
                                                handleEmailSent={(threadId: string) => setEmailThreadIds((prev) => ({ ...prev, [load._id]: threadId }))}
                                            />
                                        )
                                    }

                                    // For regular rows, render all cells from LoadRow
                                    return (
                                        <LoadRow
                                            load={load}
                                            isSelected={selectedLoads.has(load._id)}
                                            loadSearchId={searchData?.loadSearchId}
                                            onCheckboxClick={handleCheckboxClick}
                                            emailThreadIds={emailThreadIds}
                                            setEmailThreadIds={setEmailThreadIds}
                                            handleFavoriteToggle={handleFavoriteToggle}
                                        />
                                    )
                                }}
                            />

                            {/* RingCentral Sidebar */}
                            {activeCall && activeLoad && callSession && (
                                <RingCentralCallDetailsSidebar
                                    open={openCallDetails}
                                    close={handleCloseSidebar}
                                    load={activeLoad}
                                    callSession={callSession}
                                    notificationId={notificationId}
                                />
                            )}
                        </>
                    ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column', gap: 2 }}>
                            {searchData?.searchStatus === SearchStatus.searchInitialized || searchData?.searchStatus === SearchStatus.searchStarted ? (
                                <>
                                    <CircularProgress size="lg" />
                                    <Typography level="title-lg">Searching for your loads...</Typography>
                                </>
                            ) : (
                                <Typography level="title-lg" sx={{ color: 'text.secondary' }}>
                                    Start your first search!
                                </Typography>
                            )}
                        </Box>
                    )}
                </Box>
            </EmailPopoverProvider>
        )
    },
    (prevProps, nextProps) => {
        // Custom comparison logic
        return prevProps.searchData === nextProps.searchData
    }
)

export default NewLoadsTable
