/* eslint-disable @typescript-eslint/no-explicit-any */
import { ClickAwayListener, useSnackbar } from '@mui/base'
import Box from '@mui/joy/Box'
import IconButton from '@mui/joy/IconButton'
import Typography from '@mui/joy/Typography'

// Icons import
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { useMediaQuery } from '@mui/material'
import { useState, useEffect } from 'react'
import { EmailCard, SelectEmailText } from './components'
import EmailDetail from './components/EmailCard/components/EmailDetail'
import OfferPreview from './components/EmailCard/components/OfferPreview'
import { EmailContentData, Email } from '@numeo/types'
import dayjs from 'dayjs'

interface EmailContentProps {
    onBack?: () => void
    execution: EmailContentData | null
    refetch: () => Promise<void>
    setSelectedExecutionId: (id: string) => void
    setSelectedExecution: (execution: EmailContentData | null) => void
}

export default function EmailContent({ onBack, execution, refetch }: EmailContentProps) {
    const isMobile = useMediaQuery('(max-width:800px)')

    const [open, setOpen] = useState(false)
    const [toastMessage] = useState('')
    const [toastColor] = useState<'success' | 'danger'>('danger')

    useEffect(() => {
        if (execution?.processingResult?.executionLink) {
            const handleKeyDown = (e: KeyboardEvent) => {
                if ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === 'd') {
                    e.preventDefault()
                    window.open(execution?.processingResult?.executionLink, '_blank')
                }
            }

            document.addEventListener('keydown', handleKeyDown)
            return () => document.removeEventListener('keydown', handleKeyDown)
        }
    }, [execution])

    const handleClose = () => {
        setOpen(false)
    }

    const { getRootProps, onClickAway } = useSnackbar({
        onClose: handleClose,
        open,
        autoHideDuration: 3000,
    })

    if (!execution) {
        return <SelectEmailText />
    }

    return (
        <Box
            sx={{
                p: 2,
                pb: 0,
                position: 'relative',
                height: '100%',
            }}
        >
            {onBack && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        p: 2,
                        // borderBottom: '1px solid',
                        borderColor: 'divider',
                        width: '100%',
                        position: 'absolute',
                        top: '-60px',
                        left: '20px',
                    }}
                >
                    <IconButton onClick={onBack}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography level="title-lg">Back</Typography>
                </Box>
            )}
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        borderRadius: 'md',
                        overflow: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '8px',
                            backgroundColor: 'transparent',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'var(--joy-palette-neutral-300)',
                            borderRadius: '4px',
                            '&:hover': {
                                backgroundColor: 'var(--joy-palette-neutral-400)',
                            },
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: 'var(--joy-palette-background-level1)',
                            borderRadius: '4px',
                        },
                        scrollbarWidth: 'thin',
                        scrollbarColor: 'var(--joy-palette-neutral-300) var(--joy-palette-background-level1)',
                    }}
                >
                    <EmailCard
                        messageId={execution.email.emailData.object.id}
                        subject={execution.email.emailData.object.subject}
                        body={execution.email.emailData.object.body}
                        from={execution.email.emailData.object.from[0]}
                        to={execution.email.emailData.object.to[0]}
                        subtitle={dayjs(new Date(execution.email.emailData.object.date * 1000)!).format('MMM DD, YYYY, hh:mm A')}
                        attachments={execution.email.emailData.object.attachments}
                        isFromMainSender
                    />

                    {execution.threads?.length || execution.email.replyDraft ? (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            }}
                        >
                            {execution.threads.map((thread: Email) => {
                                // Check for the existance of the main sender and validate it
                                const isFromMainSender = Boolean(thread.emailData.object.from[0]?.email === execution.email.emailData.object.to[0]?.email)
                                return (
                                    <EmailCard
                                        key={thread._id}
                                        isReply
                                        messageId={thread.emailData.object.id}
                                        subject={thread.emailData.object.subject}
                                        body={thread.emailData.object.body}
                                        from={thread.emailData.object.from[0]}
                                        to={thread.emailData.object.to[0]}
                                        subtitle={dayjs(new Date(thread.emailData.object.date * 1000)!).format('MMM DD, YYYY, hh:mm A')}
                                        attachments={thread.emailData.object.attachments}
                                        isFromMainSender={isFromMainSender}
                                    />
                                )
                            })}
                            {execution.email.replyDraft &&
                                execution.email.emailData.object.to.length > 0 &&
                                execution.email.emailData.object.from.length > 0 && (
                                    <EmailCard
                                        messageId={execution.email.emailData.object.id}
                                        subject={execution.email.replyDraft?.subject}
                                        body={execution.email.replyDraft?.content}
                                        from={execution.email.emailData.object.from[0]}
                                        to={execution.email.emailData.object.to[0]}
                                        subtitle={`Email is on its way to ${execution.email.emailData.object.to[0].name || execution.email.emailData.object.to[0].email}!`}
                                        subtitleStyle={{ fontStyle: 'italic' }}
                                        isFromMainSender
                                        isReply
                                    />
                                )}
                        </Box>
                    ) : (
                        ''
                    )}
                    <Box>
                        <OfferPreview execution={execution} refetch={refetch} />
                    </Box>
                </Box>
                <EmailDetail execution={execution} refetch={refetch} />
            </Box>

            {open && (
                <ClickAwayListener onClickAway={onClickAway}>
                    <div
                        {...getRootProps()}
                        style={{
                            position: 'fixed',
                            bottom: 16,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: toastColor === 'danger' ? '#d32f2f' : '#2e7d32',
                            color: 'white',
                            padding: '6px 16px',
                            borderRadius: 4,
                            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                            zIndex: 1400,
                        }}
                    >
                        {toastMessage}
                        <button
                            onClick={handleClose}
                            style={{
                                marginLeft: 8,
                                background: 'none',
                                border: 'none',
                                color: 'white',
                                cursor: 'pointer',
                                padding: '4px',
                            }}
                        >
                            ✕
                        </button>
                    </div>
                </ClickAwayListener>
            )}
        </Box>
    )
}
