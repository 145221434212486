/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar, Box, Typography } from '@mui/joy'
import { EmailParticipant } from '@numeo/types'

export interface EmailHeaderProps {
    from: EmailParticipant
    subtitle: string
    isReply?: boolean
    subtitleStyle?: Record<string, any>
}

export default function EmailHeader({ from, subtitle, isReply, subtitleStyle }: EmailHeaderProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 2,
                width: '100%',
                cursor: isReply ? 'pointer' : 'default',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
                <Avatar>{(from.name || from.email || '')[0]?.toUpperCase()}</Avatar>
                <Box sx={{ ml: 2 }}>
                    <Typography level="title-sm" textColor="text.primary" mb={0.5}>
                        {from.name || from.email}
                    </Typography>

                    <Typography level="body-xs" textColor="text.tertiary" sx={subtitleStyle}>
                        {subtitle}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
