import { Application, RateConf } from '@numeo/types'
import axios from 'axios'

interface ProcessEmailsResponse {
    success: boolean
    stats: {
        processed: number
        rateConfs: number
        errors: number
    }
    results: RateConf[]
}

class RateConfAnalyticsApi {
    async getApplications(): Promise<Application[]> {
        const response = await axios.get('/control-panel/applications')
        return response.data || []
    }

    async processProjectEmails(projectName: string, limit: number = 50, receivedBefore?: number): Promise<ProcessEmailsResponse> {
        const response = await axios.post('/broker-analytics/process-project-emails', {
            projectName,
            limit,
            receivedBefore,
        })
        return response.data
    }

    async getRateConfirmations(projectName: string): Promise<RateConf[]> {
        const response = await axios.get(`/broker-analytics/rate-confirmations?projectName=${projectName}`)
        return response.data.results || []
    }
}

export const rateConfAnalyticsApi = new RateConfAnalyticsApi()
