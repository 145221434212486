import { ClientLoadModel, LoadFindResultState, SearchStatus } from '@numeo/types'
import { deepEqual } from 'utils'

export const handleLoadsUpdate = (lastSearchResult: LoadFindResultState, newResult: LoadFindResultState, currentLoads: Record<string, ClientLoadModel>) => {
    // Create a shallow copy of the last search result or use the new result as basis
    const currentResult: LoadFindResultState = lastSearchResult
        ? { ...lastSearchResult }
        : {
              ...newResult, // Use newResult as the base to get all required fields
              loads: {}, // Start with empty loads
              searchStatus: SearchStatus.searchInitialized,
          }

    // Check if loads have changed
    const newLoads = newResult.loads || {}

    // Create a new loads object instead of modifying the existing one
    // IMPORTANT: Create a brand new object, don't modify the parameter
    const updatedLoads: Record<string, ClientLoadModel> = {}

    // If not in initialization phase, start with copies of existing loads
    const isInitialSearch = currentResult.searchStatus === SearchStatus.searchInitialized
    if (!isInitialSearch && currentLoads) {
        // Copy all existing loads to our new object
        Object.entries(currentLoads).forEach(([id, load]) => {
            updatedLoads[id] = { ...load }
        })
    }

    const currentLoadsIdsMap = Object.keys(currentLoads || {})

    const newLoadsCountIds: string[] = []
    const updatedLoadsCountIds: string[] = []

    // Process new loads and track which are new/updated
    Object.entries(newLoads).forEach(([loadId, newLoad]) => {
        if (!currentLoadsIdsMap.includes(newLoad._id)) {
            newLoadsCountIds.push(loadId)
            // Add to our new object
            updatedLoads[loadId] = { ...newLoad }
        } else if (!deepEqual(currentLoads[loadId], newLoad)) {
            updatedLoadsCountIds.push(loadId)
            // Update in our new object
            updatedLoads[loadId] = { ...newLoad }
        }
    })

    console.log('New loads:', newLoadsCountIds.length, 'Updated loads:', updatedLoadsCountIds.length)
    console.log('Total loads:', Object.keys(updatedLoads).length)

    // Create a new result object combining the current result, new result, and updated loads
    const result = {
        ...currentResult,
        ...newResult,
        loads: updatedLoads, // Use our new object
    }

    return {
        result,
        newLoadsCount: newLoadsCountIds.length,
        updatedLoadsCount: updatedLoadsCountIds.length,
    }
}
