import {
    Box,
    Button,
    Card,
    Stack,
    Typography,
    IconButton,
    Modal,
    ModalDialog,
    ModalClose,
    DialogTitle,
    DialogContent,
    DialogActions,
    Chip,
    FormControl,
    FormLabel,
    Input,
} from '@mui/joy'
import { useAuth } from 'hooks/auth'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Category, useNotification } from 'hooks/useNotification'
import { CheckCircle, DeleteRounded } from '@mui/icons-material'

export const TruckStop = () => {
    const { application, setApplication } = useAuth()
    const { addNotification } = useNotification()

    const [isConnected, setIsConnected] = useState<boolean>(!!application?.integrations.truckStop)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false)
    const [integrationId, setIntegrationId] = useState<string>('')
    const [integrationIdError, setIntegrationIdError] = useState<string>('')

    useEffect(() => {
        setIsConnected(!!application?.integrations.truckStop)
    }, [application])

    // Validate that integrationId is a 6-digit number
    const validateIntegrationId = (value: string): boolean => {
        const regex = /^\d{6}$/
        return regex.test(value)
    }

    // Function to handle direct integration with integrationId
    const handleConnectTruckStop = async () => {
        if (!integrationId) {
            addNotification({
                id: '',
                category: Category.Error,
                message: 'Integration ID is required',
                timestamp: Date.now(),
                topic: 'temp',
                type: 'error',
            })
            return
        }

        if (!validateIntegrationId(integrationId)) {
            addNotification({
                id: '',
                category: Category.Error,
                message: 'Integration ID must be a 6-digit number',
                timestamp: Date.now(),
                topic: 'temp',
                type: 'error',
            })
            return
        }

        setIsLoading(true)

        try {
            // Call the API to save the integrationId
            const response = await axios.post('/integrations/truck-stop', { integrationId })

            if (application) {
                setApplication({
                    ...application,
                    integrations: {
                        ...application.integrations,
                        truckStop: response.data,
                    },
                })
            }

            setIsConnected(true)
            setIntegrationId('') // Clear the input field

            addNotification({
                id: '',
                category: Category.Settings,
                message: 'TruckStop account connected successfully',
                timestamp: Date.now(),
                topic: 'temp',
                type: 'info',
            })
        } catch (error) {
            const errorMessage = axios.isAxiosError(error)
                ? error.response?.data?.message || 'Failed to add TruckStop integration'
                : 'Failed to add TruckStop integration'

            addNotification({
                id: '',
                category: Category.Error,
                message: errorMessage,
                timestamp: Date.now(),
                topic: 'temp',
                type: 'error',
            })
        } finally {
            setIsLoading(false)
        }
    }

    const handleRemoveAccount = async () => {
        try {
            setIsLoading(true)
            await axios.delete('/integrations/truck-stop')

            if (application) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { truckStop, ...remainingIntegrations } = application.integrations

                setApplication({
                    ...application,
                    integrations: remainingIntegrations,
                })
            }

            setIsConnected(false)
            addNotification({
                id: '',
                category: Category.Settings,
                message: 'TruckStop account removed successfully',
                timestamp: Date.now(),
                topic: 'temp',
                type: 'info',
            })
        } catch (err) {
            console.error('Failed to remove TruckStop account')
            addNotification({
                id: '',
                category: Category.Error,
                message: 'Failed to remove TruckStop account',
                timestamp: Date.now(),
                topic: 'temp',
                type: 'error',
            })
        } finally {
            setIsLoading(false)
            setDeleteConfirmOpen(false)
        }
    }

    return (
        <Card variant="outlined" sx={{ p: 2, gap: 1, width: '100%' }}>
            <Stack direction="row" spacing={1} alignItems="center">
                <img src="/truckstop-logo.webp" alt="TruckStop Logo" style={{ width: 24, height: 24 }} />
                <Box sx={{ flex: 1 }}>
                    <Typography level="title-md">TruckStop Account</Typography>
                </Box>
                {isConnected && (
                    <IconButton
                        variant="soft"
                        color="danger"
                        onClick={() => setDeleteConfirmOpen(true)}
                        loading={isLoading}
                        disabled={isLoading}
                        title="Remove TruckStop Account"
                    >
                        <DeleteRounded />
                    </IconButton>
                )}
            </Stack>

            <Stack spacing={1}>
                {isConnected ? (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography level="body-md" fontWeight="bold">
                            Connected Account:
                        </Typography>
                        <Chip variant="soft" color="success" startDecorator={<CheckCircle fontSize="small" />}>
                            Connected
                        </Chip>
                    </Stack>
                ) : (
                    <Stack spacing={2}>
                        <Typography level="body-sm">
                            Connect your TruckStop account to access load boards and streamline your freight operations. Please enter your TruckStop Integration
                            ID below.
                        </Typography>

                        <FormControl error={!!integrationIdError}>
                            <FormLabel>TruckStop Integration ID</FormLabel>
                            <Input
                                placeholder="Enter your TruckStop Integration ID (6 digits)"
                                value={integrationId}
                                onChange={(e) => {
                                    const value = e.target.value.trim()
                                    // Allow only numeric input
                                    if (value === '' || /^\d+$/.test(value)) {
                                        setIntegrationId(value)

                                        // Clear error if value is valid or empty
                                        if (value === '' || validateIntegrationId(value)) {
                                            setIntegrationIdError('')
                                        } else {
                                            setIntegrationIdError('Integration ID must be a 6-digit number')
                                        }
                                    }
                                }}
                                disabled={isLoading}
                                slotProps={{
                                    input: {
                                        maxLength: 6,
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                    },
                                }}
                            />
                            {integrationIdError && (
                                <Typography level="body-xs" color="danger">
                                    {integrationIdError}
                                </Typography>
                            )}
                        </FormControl>

                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                variant="solid"
                                color="primary"
                                onClick={handleConnectTruckStop}
                                loading={isLoading}
                                disabled={isLoading || !integrationId || !validateIntegrationId(integrationId)}
                            >
                                Connect TruckStop
                            </Button>
                        </Box>
                    </Stack>
                )}
            </Stack>

            {/* Delete Confirmation Modal */}
            <Modal open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <ModalClose />
                    <DialogTitle>
                        <Typography level="title-lg">Confirm Removal</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography level="body-md">Are you sure you want to remove your TruckStop account? This action cannot be undone.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="plain" color="neutral" onClick={() => setDeleteConfirmOpen(false)} disabled={isLoading}>
                            Cancel
                        </Button>
                        <Button variant="solid" color="danger" onClick={handleRemoveAccount} loading={isLoading} disabled={isLoading}>
                            Remove Account
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </Card>
    )
}
