import { useState, useRef, DragEvent, ChangeEvent } from 'react'
import { Modal, ModalDialog, Typography, Box, Button, IconButton, Divider, Stack } from '@mui/joy'
import { Close, CloudUpload, PictureAsPdf, Delete } from '@mui/icons-material'

interface FileUploadModalProps {
    open: boolean
    onClose: () => void
    onUpload: (file: File) => Promise<void>
}

export const FileUploadModal = ({ open, onClose, onUpload }: FileUploadModalProps) => {
    const [file, setFile] = useState<File | null>(null)
    const [isDragging, setIsDragging] = useState(false)
    const [uploadError, setUploadError] = useState<string | null>(null)
    const fileInputRef = useRef<HTMLInputElement>(null)

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDragging(true)
    }

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDragging(false)
    }

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDragging(false)

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const droppedFile = e.dataTransfer.files[0]
            validateAndSetFile(droppedFile)
        }
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            validateAndSetFile(e.target.files[0])
        }
    }

    const validateAndSetFile = (file: File) => {
        setUploadError(null)
        // Check if file is PDF
        if (file.type !== 'application/pdf') {
            setUploadError('Only PDF files are allowed')
            return
        }

        // Check file size (max 10MB)
        if (file.size > 10 * 1024 * 1024) {
            setUploadError('File size exceeds 10MB limit')
            return
        }

        setFile(file)
    }

    const handleUpload = async () => {
        if (!file) return

        try {
            setUploadError(null)
            await onUpload(file)
            setFile(null)
            onClose()
        } catch (error) {
            setUploadError('Failed to upload file. Please try again.')
            console.error('Upload error:', error)
        }
    }

    const handleRemoveFile = () => {
        setFile(null)
        setUploadError(null)
        if (fileInputRef.current) {
            fileInputRef.current.value = ''
        }
    }

    const formatFileSize = (bytes: number) => {
        if (bytes < 1024) return bytes + ' bytes'
        if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + ' KB'
        return (bytes / (1024 * 1024)).toFixed(1) + ' MB'
    }

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog
                sx={{
                    maxWidth: 500,
                    minWidth: 400,
                    p: 3,
                }}
            >
                <Typography level="title-lg">Upload PDF File</Typography>
                <IconButton onClick={onClose} variant="plain" sx={{ position: 'absolute', top: 10, right: 10 }}>
                    <Close />
                </IconButton>
                <Divider sx={{ my: 2 }} />

                {!file ? (
                    <Box
                        sx={{
                            border: '2px dashed',
                            borderColor: isDragging ? 'primary.500' : 'neutral.300',
                            borderRadius: 'md',
                            p: 4,
                            textAlign: 'center',
                            transition: 'all 0.2s',
                            backgroundColor: isDragging ? 'primary.50' : 'background.surface',
                            cursor: 'pointer',
                            '&:hover': {
                                borderColor: 'primary.400',
                                backgroundColor: 'primary.50',
                            },
                        }}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        onClick={() => fileInputRef.current?.click()}
                    >
                        <input type="file" ref={fileInputRef} onChange={handleFileChange} accept=".pdf" style={{ display: 'none' }} />
                        <CloudUpload sx={{ fontSize: 48, color: 'primary.500', mb: 2 }} />
                        <Typography level="title-md" sx={{ mb: 1 }}>
                            Drag and drop your PDF file here
                        </Typography>
                        <Typography level="body-sm" sx={{ color: 'neutral.500', mb: 2 }}>
                            or click to browse files
                        </Typography>
                        <Button
                            variant="outlined"
                            size="sm"
                            startDecorator={<CloudUpload />}
                            onClick={(e) => {
                                e.stopPropagation()
                                fileInputRef.current?.click()
                            }}
                        >
                            Browse Files
                        </Button>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            border: '1px solid',
                            borderColor: 'neutral.200',
                            borderRadius: 'md',
                            p: 2,
                            backgroundColor: 'background.surface',
                        }}
                    >
                        <Stack direction="row" spacing={2} alignItems="center">
                            <PictureAsPdf sx={{ color: 'danger.500', fontSize: 40 }} />
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography level="title-sm" sx={{ wordBreak: 'break-word' }}>
                                    {file.name}
                                </Typography>
                                <Typography level="body-xs" sx={{ color: 'neutral.500' }}>
                                    {formatFileSize(file.size)}
                                </Typography>
                            </Box>
                            <IconButton variant="soft" color="danger" size="sm" onClick={handleRemoveFile}>
                                <Delete />
                            </IconButton>
                        </Stack>
                    </Box>
                )}

                {uploadError && (
                    <Typography
                        level="body-sm"
                        sx={{
                            color: 'danger.500',
                            mt: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <span>⚠️</span> {uploadError}
                    </Typography>
                )}

                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', mt: 3 }}>
                    <Button variant="plain" color="neutral" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleUpload} disabled={!file}>
                        Upload
                    </Button>
                </Box>
            </ModalDialog>
        </Modal>
    )
}
