import { Add, Backup, Delete, Search } from '@mui/icons-material'
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Input,
    Stack,
    Typography,
    Tabs,
    TabList,
    Tab,
    MenuItem,
    Dropdown,
    IconButton,
    Menu,
    MenuButton,
    Tooltip,
} from '@mui/joy'
import { useState, useEffect } from 'react'
import { OngoingLoad, LoadStatus } from '@numeo/types'
import { ongoingLoadsApi } from '../api/ongoing-loads.api'
import { LoadActionModal, ModalType } from './LoadActionModal'
import { OngoingLoadsTable } from './OngoingLoadsTable'
import { LoadDeleteConfirmationModal } from './LoadDeleteConfirmationModal'
import { FileUploadModal } from './FileUploadModal'
import { useDispatch } from 'react-redux'
import { addNotification } from 'store/notificationSlice'
import { Category } from 'hooks/useNotification'
import { useAuth } from 'hooks/auth'

export const OngoingLoads = () => {
    const { application } = useAuth()
    const dispatch = useDispatch()
    const [loads, setLoads] = useState<OngoingLoad[]>([])
    const [selectedLoads, setSelectedLoads] = useState<string[]>([])
    const [loading, setLoading] = useState(false)
    const [fileUploading, setFileUploading] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [activeTab, setActiveTab] = useState<LoadStatus>(LoadStatus.ACTIVE)
    const [modalType, setModalType] = useState<ModalType>(ModalType.NONE)
    const [currentLoad, setCurrentLoad] = useState<OngoingLoad | null>(null)
    const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false)

    // For pagination
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [total, setTotal] = useState(0)

    const showNotificationMessage = (type: 'info' | 'error' = 'info', message: string) => {
        dispatch(
            addNotification({
                id: '',
                type,
                category: Category.Updater,
                message,
                timestamp: Date.now(),
                topic: 'temp',
            })
        )
    }

    useEffect(() => {
        fetchLoads()
        // eslint-disable-next-line
    }, [page, rowsPerPage])

    // Apply filters to loads
    const filteredLoads = loads.filter((load) => {
        // First filter by status tab
        if (load.status !== activeTab) return false

        // Then filter by search query
        if (!searchQuery) return true

        const query = searchQuery.toLowerCase()
        return (
            load.companyName.toLowerCase().includes(query) ||
            load.origin.city.toLowerCase().includes(query) ||
            load.origin.state.toLowerCase().includes(query) ||
            load.destination.city.toLowerCase().includes(query) ||
            load.destination.state.toLowerCase().includes(query) ||
            load.equipmentType.toLowerCase().includes(query)
        )
    })

    const fetchLoads = async () => {
        try {
            if (!application?.projectName) {
                throw new Error('Project name not found in application')
            }
            setLoading(true)
            const response = await ongoingLoadsApi.getAllOngoingLoads(page, rowsPerPage, application.projectName)
            setLoads(response.data || [])
            if (response.meta) {
                setTotal(response.meta.total)
            }
        } catch (err) {
            console.error('Error fetching ongoing loads:', err)
            showNotificationMessage('error', 'Failed to fetch ongoing loads.')
        } finally {
            setLoading(false)
        }
    }

    const handleDelete = async () => {
        if (selectedLoads.length === 0 && !currentLoad) return

        const idsToDelete = currentLoad ? [currentLoad._id] : selectedLoads

        try {
            setLoading(true)
            await ongoingLoadsApi.deleteOngoingLoads(idsToDelete)
            setModalType(ModalType.NONE)

            // Update state locally instead of refetching
            setLoads((prevLoads) => prevLoads.filter((load) => !idsToDelete.includes(load._id)))

            setSelectedLoads([])
            setCurrentLoad(null)
            showNotificationMessage('info', 'Loads deleted successfully')
        } catch (err) {
            console.error('Error deleting ongoing loads:', err)
            showNotificationMessage('error', 'Failed to delete ongoing loads.')
        } finally {
            setLoading(false)
        }
    }

    const handleStatusChange = async (loadIds: string[], status: LoadStatus) => {
        try {
            setLoading(true)
            // Update state locally instead of refetching
            await ongoingLoadsApi.updateLoadStatus(loadIds, status)
            setLoads((prevLoads) => prevLoads.map((load) => (loadIds.includes(load._id) ? { ...load, status } : load)))
            setSelectedLoads([])
            showNotificationMessage('info', 'Load status updated successfully')
        } catch (err) {
            console.error('Error updating load status:', err)
            showNotificationMessage('error', 'Failed to update load status')
        } finally {
            setLoading(false)
        }
    }

    const handleFileUpload = async (file: File) => {
        try {
            // Close modal immediately
            setFileUploadModalOpen(false)

            // Set file upload loading state
            setFileUploading(true)

            // API endpoint for file upload with query parameters for metadata
            await ongoingLoadsApi.uploadFile(file)

            // Show success notification
            showNotificationMessage('info', `File processed successfully`)

            // Refresh loads list
            fetchLoads()
        } catch (error) {
            console.error('Error uploading file:', error)
            showNotificationMessage('error', 'Failed to upload or process file')
        } finally {
            setFileUploading(false)
        }
    }

    return (
        <Box sx={{ p: 1 }}>
            {/* Header */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, mt: 0.2 }}>
                <Typography level="h2">Confirmed Loads</Typography>
                <Stack direction="row" gap={1}>
                    <Button
                        variant="solid"
                        startDecorator={fileUploading ? <CircularProgress size="sm" /> : <Backup />}
                        onClick={() => setFileUploadModalOpen(true)}
                        disabled={fileUploading}
                    >
                        {fileUploading ? 'Uploading and processing...' : 'Upload File'}
                    </Button>
                    <Button
                        variant="outlined"
                        startDecorator={<Add />}
                        onClick={() => {
                            setCurrentLoad(null)
                            setModalType(ModalType.CREATE)
                        }}
                        sx={{ mr: 5 }}
                    >
                        New Load
                    </Button>
                </Stack>
            </Box>

            {/* Search and filters */}
            {/* Tabs */}
            <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value as LoadStatus)} sx={{ my: 1 }}>
                <TabList>
                    <Tab value={LoadStatus.ACTIVE}>Active</Tab>
                    <Tab value={LoadStatus.COMPLETED}>Completed</Tab>
                    <Tab value={LoadStatus.CANCELLED}>Cancelled</Tab>
                </TabList>
            </Tabs>

            {/* Search and filters */}
            <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
                <FormControl sx={{ flex: 1 }}>
                    <Input placeholder="Search loads..." startDecorator={<Search />} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                </FormControl>
            </Box>

            <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'flex-start' }}>
                <Button
                    color="danger"
                    variant="soft"
                    startDecorator={<Delete />}
                    onClick={() => setModalType(ModalType.DELETE)}
                    disabled={selectedLoads.length === 0}
                >
                    {`Delete Selected (${selectedLoads.length})`}
                </Button>
                <Dropdown>
                    <Tooltip title="Move to">
                        <MenuButton
                            slots={{ root: IconButton }}
                            slotProps={{
                                root: {
                                    variant: 'soft',
                                    color: 'neutral',
                                    size: 'sm',
                                    disabled: selectedLoads.length === 0 || loading,
                                    loading: loading,
                                    onClick: (e) => e.stopPropagation(),
                                },
                            }}
                            sx={{ py: 1, px: 2 }}
                        >
                            Move to
                        </MenuButton>
                    </Tooltip>
                    <Menu placement="bottom-end" size="sm" sx={{ minWidth: 120 }}>
                        <MenuItem
                            disabled={activeTab === LoadStatus.ACTIVE}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleStatusChange(selectedLoads, LoadStatus.ACTIVE)
                            }}
                        >
                            Active
                        </MenuItem>
                        <MenuItem
                            disabled={activeTab === LoadStatus.COMPLETED}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleStatusChange(selectedLoads, LoadStatus.COMPLETED)
                            }}
                        >
                            Completed
                        </MenuItem>
                        <MenuItem
                            disabled={activeTab === LoadStatus.CANCELLED}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleStatusChange(selectedLoads, LoadStatus.CANCELLED)
                            }}
                        >
                            Cancelled
                        </MenuItem>
                    </Menu>
                </Dropdown>
            </Box>

            <OngoingLoadsTable
                loads={filteredLoads}
                selectedLoads={selectedLoads}
                setSelectedLoads={setSelectedLoads}
                searchQuery={searchQuery}
                loading={loading}
                setCurrentLoad={setCurrentLoad}
                setModalType={setModalType}
                total={total}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
            />

            <LoadActionModal
                modalType={modalType}
                setModalType={setModalType}
                currentLoad={currentLoad}
                fetchLoads={fetchLoads}
                showNotificationMessage={showNotificationMessage}
                setLoads={setLoads}
            />

            {/* Delete Confirmation Modal */}
            <LoadDeleteConfirmationModal
                modalType={modalType}
                setModalType={setModalType}
                selectedLoads={selectedLoads}
                loading={loading}
                handleDelete={handleDelete}
            />

            {/* File Upload Modal */}
            <FileUploadModal open={fileUploadModalOpen} onClose={() => setFileUploadModalOpen(false)} onUpload={handleFileUpload} />
        </Box>
    )
}
