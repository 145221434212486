import { Call, ClientLoadModel } from '@numeo/types'
import axios from 'axios'
import callCenterApi from '../../call-center/api'

class spotFinder {
    async get(endpoint: string) {
        try {
            const response = await axios.get(endpoint)
            return response.data
        } catch (error) {
            console.log('Error getting data ', error)
        }
    }

    async post<T>(endpoint: string, data: T) {
        try {
            const response = await axios.post(endpoint, data)
            return response.data
        } catch (error) {
            console.log('Error getting data ', error)
        }
    }
    async delete(endpoint: string) {
        try {
            const response = await axios.delete(endpoint)
            return response.data
        } catch (error) {
            console.log('Error getting data ', error)
            throw error
        }
    }
    async put<T>(endpoint: string, data: T) {
        try {
            const response = await axios.put(endpoint, data)
            return response.data
        } catch (error) {
            console.log('Error getting data ', error)
        }
    }
}

export const handlePhoneClick = async (phoneNumber: string, load: ClientLoadModel, loadSearchId: string): Promise<Call> => {
    return await callCenterApi.makeCall(
        phoneNumber,
        load.posterInfo.mcNumber,
        load.origin?.city || '',
        load.destination.city,
        new Date(load.earliestAvailability).toDateString(),
        load.posterInfo.companyName,
        load.postersReferenceId || '',
        loadSearchId
    )
}

const spotApi = new spotFinder()
export default spotApi
